import React from 'react'
import {connect} from 'react-redux'

import {markAsViewed as view} from 'permissions/events/actions'
import delivery from 'permissions/events/delivery'

import {markAsViewed} from 'modules/events/one/actions'

import {ReactComponent as MarkViewedIcon} from 'ipmp-react-ui/icons/viewed.svg'
import {ReactComponent as IconCamera} from 'ipmp-react-ui/icons/camera.svg'

import {
    ActionCell,
    SeverityCell,
    EventAppointmentCell,
    EventDeliveranceCell,
} from 'ui/TableCells'
import Time from 'ipmp-react-ui/Time'
import {TableGrouped} from 'ipmp-react-ui/Table'

import {humanDate} from 'ipmp-react-ui/humanTime'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

const allColumns = [
    {
        maxWidth: 100,
        render: ({datetime}) => <Time datetime={datetime} withSeconds />,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        render: ({hasVideo}) => (hasVideo ? <IconCamera /> : null),
    },
    {
        width: 150,
        render: ({severity, description}) => (
            <SeverityCell {...{severity, description}} />
        ),
    },
    {
        tooltip: false,
        maxWidth: 550,
        render: ({zone, appointment, partitions, description}) => (
            <EventAppointmentCell {...{zone, appointment, partitions, description}} />
        ),
    },
    {
        maxWidth: 200,
        align: 'right',
        component: connect((state, {row}) => ({
            event: row,
            edsView: state.settings.edsView,
        }))(EventDeliveranceCell),
        permission: delivery,
    },
    {
        permission: view,
        tooltip: false,
        fixed: true,
        width: 50,
        component: connect(
            (state, {row}) => ({
                Icon: MarkViewedIcon,
                isChecked: row.isViewed,
            }),
            (dispatch, {row}) => ({
                onClick: () => dispatch(markAsViewed(row)),
            })
        )(ActionCell),
    },
]

export default function EventsTable(props) {
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <TableGrouped
            {...props}
            columns={columns}
            separatorCallback={(row) => humanDate(row.datetime)}
        />
    )
}
