import React from 'react'

import __ from 'utils/i18n'
import {BorderedDefinitionEditIcon} from 'ipmp-react-ui/BorderedDefinition'

export default function CustomerRemarks({remarks}) {
    if (!remarks?.length) {
        return null
    }

    return (
        <div className="customerRemarks">
            <h3 className="customerRemarks-title">{__('Remarks')}</h3>
            {remarks.filter(Boolean).map((remark, index) => (
                <BorderedDefinitionEditIcon key={`remark_${index}`} title={remark} />
            ))}
        </div>
    )
}
