import React from 'react'

import {ReactComponent as DeliveredIcon} from 'ipmp-react-ui/icons/events/delivered.svg'
import {ReactComponent as FailedIcon} from 'ipmp-react-ui/icons/events/failed.svg'
import {ReactComponent as NotDeliveredIcon} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as ProcessingIcon} from 'ipmp-react-ui/icons/events/processing.svg'
import {ReactComponent as QueuedIcon} from 'ipmp-react-ui/icons/events/queued.svg'

import {
    EDS_FAILED,
    EDS_PROCESSING,
    EDS_QUEUED,
    EDS_RETRY,
    EDS_SUCCESS,
} from 'constants/eventDelivery'

export default function deliveranceIcon(status) {
    switch (status) {
        case EDS_QUEUED:
            return <QueuedIcon />
        case EDS_PROCESSING:
            return <ProcessingIcon />
        case EDS_SUCCESS:
            return <DeliveredIcon />
        case EDS_RETRY:
            return (
                <div className="deliverance-centralstation-icon-retry">
                    <NotDeliveredIcon />
                </div>
            )
        case EDS_FAILED:
            return <FailedIcon />
        default:
            return <QueuedIcon />
    }
}
