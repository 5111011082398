import React from 'react'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {listSsl, getSslLabel} from 'constants/centralStation'
import {__} from 'utils/i18n'

export default function SelectSslOptions({ssl, handleSelectSsl, errorKey}) {
    return (
        <Select
            label={__('Security')}
            name="ssl"
            errorKey={errorKey}
            onChange={handleSelectSsl}
            value={ssl}
            dataTestId="ssl"
        >
            {listSsl.map((option) => {
                return <Option label={getSslLabel(option)} value={option} key={option} />
            })}
        </Select>
    )
}
