import parseDate from 'api/base/parseDate'
import dataMapper from 'utils/dataMapper'

export const keysMap = {
    users: 'usr_id',
    statuses: 'anl_status',
    activities: 'anl_activity',
    datetime: 'anl_time',
}

export const mapActionLog = dataMapper((actionLog) => ({
    id: parseInt(actionLog.id),
    userId: parseInt(actionLog.usr_id),
    time: parseDate(actionLog.anl_time),
    method: actionLog.anl_method,
    activity: actionLog.anl_activity,
    description: actionLog.anl_description || '',
    url: actionLog.anl_url,
    params: actionLog.anl_params,
    data: actionLog.anl_data,
    ip: actionLog.anl_ip,
    status: actionLog.anl_status,
    message: actionLog.anl_message,
    userName: actionLog.usr_name,
}))
