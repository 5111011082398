import React from 'react'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import Select, {Option} from 'ipmp-react-ui/Select'
import Button from 'ipmp-react-ui/Button'
import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import Portal from 'ipmp-react-ui/Portal'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import __ from 'utils/i18n'

import AddStateType from 'modals/StateType/AddStateType'
import ContentTemplate from 'modals/EventRule/Fieldset/Content/ContentTemplate'
import {selectAllStateTypes} from 'modules/stateTypes/store/selectors'

class StateType extends ContentTemplate {
    renderModal = () => (
        <Portal>
            <AddStateType hide={this.onHideModal} />
        </Portal>
    )

    render() {
        const {data} = this.props
        const {isModalOpened} = this.state

        return (
            <div className="eventRules-master-content">
                <Disclaimer className="eventRules-master-content-disclaimer">
                    {__(
                        'In the inactive state, the system provides you with visual status information, and initiates an alarm if a 24-hour zone is triggered.'
                    )}
                    <br />
                    {__(
                        'In the active state, the system initiates an alarm upon detection of disturbance in any one of the armed zones.'
                    )}
                </Disclaimer>
                <Select
                    name={this.selectName}
                    defaultValue={this.value}
                    defaultLabel={this.label}
                    label={__('State')}
                >
                    {data &&
                        data.length &&
                        data.map(({id, name}) => (
                            <Option key={id} label={name} value={id} />
                        ))}
                </Select>
                <Bar orientation={RIGHT} className="eventRules-master-content-bar">
                    <Button
                        className="eventRules-master-content-addButton"
                        Icon={IconPlus}
                        borderless
                        onClick={this.onOpenModal}
                    >
                        {__('Add State')}
                    </Button>
                </Bar>
                {isModalOpened && this.renderModal()}
            </div>
        )
    }
}

export default connect((store) => ({
    ...selectAllStateTypes(store),
    isFormSuccess: get(store, ['forms', 'addStateType', 'success'], false),
}))(StateType)
