import React from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import withForm from 'containers/withForm'
import * as PropTypes from 'prop-types'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import {eventInitiate} from 'modules/forms/handlers'
import {useSelector} from 'react-redux'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {PARTITION_ALL, PARTITION_LABEL_ALL} from 'constants/partitions'
import get from 'lodash-es/get'
import PartitionsMultiSelect from 'utils/panel/PartitionsMultiSelect'

const SetEventPartitions = ({handle, panelId, eventType, ...props}) => {
    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))
    const isAllowMultiSelect = get(
        features,
        ['emergency', 'supportsSpecifiedPartitions'],
        true
    )
    const rules = {
        partitions: {
            presence: isAllowMultiSelect
                ? {
                      message: () => __('Partition is required'),
                  }
                : false,
        },
    }

    const maxPartitions = features?.partitions?.maxPartitions || 1

    const onHandle = (data) => {
        let selectedPartitions = data?.partitions
        if (!isAllowMultiSelect) {
            selectedPartitions = [PARTITION_ALL.toString()]
        }
        handle({panelId, eventType, partitions: selectedPartitions})
    }
    const defaultValue = [{value: PARTITION_ALL, label: PARTITION_LABEL_ALL}]

    return (
        <ModalCardForm
            onSubmit={onHandle}
            submitLabel={__('Confirm')}
            confirmOnDismiss
            rules={rules}
            header={__('You are going to initiate %s alarm', eventType)}
            className="eventInitiate-modal"
            {...props}
        >
            <Disclaimer>
                <span>{__('Emergency alarm will:')}</span>
                <ul>
                    <li>{__('- be transferred to central monitoring station')}</li>
                    <li>
                        {__(
                            '- activate siren that may lead to hearing damage or other unwanted consequences, if facility is currently attended.'
                        )}
                    </li>
                </ul>
            </Disclaimer>
            {isAllowMultiSelect && (
                <PartitionsMultiSelect
                    label={__('Partitions')}
                    name="partitions"
                    panelId={panelId}
                    hasSelectAll={maxPartitions > 1}
                    maxSelectOptions={maxPartitions}
                    defaultValues={defaultValue}
                />
            )}
        </ModalCardForm>
    )
}

SetEventPartitions.propTypes = {
    handle: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
    eventType: PropTypes.string.isRequired,
}

export default withForm(eventInitiate)(SetEventPartitions)
