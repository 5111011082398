import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {assignPanel} from 'modules/installerPanels/list/actions'
import {useActions} from 'modules/higherOrder/useActions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import __ from 'utils/i18n'

export default function AssignButton({id, serial}) {
    const {onClick} = useActions({onClick: () => assignPanel(id)})

    return (
        <WithConfirmationButton
            small
            flat
            Icon={IconPlus}
            onClick={onClick}
            message={__('Do you really want to assign %s panel?', serial)}
            title={__('Assign panel')}
        />
    )
}

AssignButton.propTypes = {
    id: PropTypes.number.isRequired,
    serial: PropTypes.string.isRequired,
}
