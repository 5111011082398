import {handleActions} from 'redux-actions'

import {update} from 'modules/auth/permissions/actions'

export default handleActions(
    {
        [update](state, {payload: permissions}) {
            if (!permissions) {
                return {}
            }
            return permissions
        },
    },
    {}
)
