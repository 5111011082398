import React from 'react'
import Device from 'components/Devices/Device'
import Panel from 'components/Devices/Panel'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'

export default function ParentTab({device, panel}) {
    if (!device) {
        return null
    }

    return (
        <div className="devicesList">
            {device.deviceType === DEVICE_TYPE_CONTROL_PANEL ? (
                <Panel panel={panel} />
            ) : (
                <Device device={device} />
            )}
        </div>
    )
}
