import {get, post} from './http'
import {mapPGMLEGACY, mapPGMTypes} from './pgm.map'
import {mapProcess} from 'api/processes'

export function getPGMListLEGACY(panelId) {
    return get('pgm/list', {unt_id: panelId})
        .then((data) => data.map(mapPGMLEGACY))
        .then((data) =>
            data.reduce(
                (acc, {deviceId, deviceType, pgms}) =>
                    acc.concat(pgms.map((pgm) => ({...pgm, deviceId, deviceType}))),
                []
            )
        )
}

export function getPGMTypes(panelId, type = null, restrictions = null) {
    return get('pgm/types', {
        unt_id: panelId,
        ...(type ? {type} : {}),
        ...(restrictions ? {restrictions} : {}),
    }).then(mapPGMTypes)
}

export function editPGM(panelId, id, type, options, batchId) {
    return post('pgm/edit', {
        unt_id: panelId,
        id,
        type,
        options,
        batch_id: batchId,
    }).then(mapProcess)
}
