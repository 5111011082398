import React from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import __ from 'utils/i18n'

export default function HeaderContent({email}) {
    const details = (
        <>
            <span>(</span>
            <OverflowTextTooltip inline children={email} />
            <span>)</span>
        </>
    )

    return (
        <Definition
            className="installerBlind-addPanel-header"
            title={__('Assign panel')}
            detail={details}
        />
    )
}

HeaderContent.propTypes = {
    email: PropTypes.string.isRequired,
}
