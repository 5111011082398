import {compose} from 'redux'

import Pager from 'ipmp-react-ui/Pager'

import list from 'permissions/groups/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

export default compose(withPermission({isVisible: list}), withVisibility())(Pager)
