import React from 'react'
import {compose} from 'redux'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {remove} from 'permissions/panel/actions'

import {withVisibility} from 'containers/withVisibility'

import {__} from 'utils/i18n'
import withConfirmationModal from 'containers/withConfirmationModal'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'

const ConnectedMenuItem = compose(
    withVisibility(),
    withConfirmationModal(
        ({remove}) => [remove],
        () => ({
            title: __('Removing panel'),
            message: __('Do you really want to remove selected panel?'),
            positive: __('Remove'),
        })
    )
)(MenuItem)

export default function RemovePanelItem() {
    const {isPermitted} = usePermission(remove)
    const panel = usePanel()

    return (
        <ConnectedMenuItem
            {...panel}
            isVisible={isPermitted}
            Icon={IconRemove}
            children={__('Remove')}
        />
    )
}
