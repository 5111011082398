import React from 'react'
import PropTypes from 'prop-types'
import {Tab} from 'ipmp-react-ui/Tabs'
import RemoteInspectionResults from 'components/RemoteInspection/Results'
import {compose} from 'redux'
import withProps from 'containers/withProps'

function RemoteInspectionsTab({name, activeResult}) {
    return (
        <Tab name={name}>
            <RemoteInspectionResults remoteInspectionResult={activeResult} />
        </Tab>
    )
}

RemoteInspectionsTab.propTypes = {
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    activeResult: PropTypes.object.isRequired,
}

export default compose(
    withProps(({activeResult, state}) => ({
        activeResult: {
            ...activeResult,
            result: (activeResult?.result || []).filter(
                (foundResult) => foundResult.state === state
            ),
        },
    }))
)(RemoteInspectionsTab)
