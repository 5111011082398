import React from 'react'

import {sendEmail} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'

import ResendEmailBase from 'components/Cell/RemoteInspections/Actions/ResendEmail'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'
import usePermission from 'hooks/usePermission'

const ResendEmailVisibility = withVisibility()(ResendEmailBase)

export default function ResendEmailAction(props) {
    const {isPermitted} = usePermission(sendEmail)
    const remoteInspections = useRemoteInspections()
    const componentProps = {...props, ...remoteInspections}

    return <ResendEmailVisibility {...componentProps} isVisible={isPermitted} />
}
