import moment from 'moment'
import {humanTime} from 'ipmp-react-ui/humanTime'

import {textFormatResolve} from 'utils/keypad/logger'
import exportCsv from 'utils/exportCsv'
import __ from 'utils/i18n'

export default function saveCSV(array, fileName) {
    let csvContent = [__('DATE'), __('ACTION'), __('MSG')].join(',') + '\r\n'

    const date = moment(Date.now()).format('MM/DD/YYYY @ h:mm:ss')
    fileName = `${fileName}_${date}.csv`

    const rows = array.map((item) => [humanTime(item.time), textFormatResolve(item)])

    rows.forEach((rowArray) => {
        let row = rowArray.join(',')
        csvContent += row + '\r\n' // add carriage return
    })

    exportCsv(csvContent, fileName)
}
