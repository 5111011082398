import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import {ScrollView} from 'ipmp-react-ui/Layout'

const SidebarTabsLayout = ({nav, tab, className}) => {
    return (
        <Fragment>
            {nav}

            <ScrollView className="sidebar-content">{tab}</ScrollView>
        </Fragment>
    )
}

SidebarTabsLayout.propTypes = {
    nav: PropTypes.element,
    tab: PropTypes.element,
}

export default SidebarTabsLayout
