import React from 'react'
import {compose} from 'redux'

import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'

import selection from 'permissions/panels/selection'

import SelectionDropDown from 'components/SelectionDropDown'
import usePermission from 'hooks/usePermission'
import usePanels from 'hooks/pages/usePanels'

const ConnectedSelectionDropDown = compose(
    withVisibility(),
    withSelection()
)(SelectionDropDown)

export default function PanelsDropDown() {
    const panels = usePanels()
    const {isPermitted} = usePermission(selection)

    return <ConnectedSelectionDropDown {...panels} isVisible={isPermitted} />
}
