import {
    CODE_HEX_TYPE,
    CODE_TYPE,
    LIST_TYPE,
    MENU_TYPE,
    PHONE_TYPE,
    STRING_TYPE,
} from 'constants/panelConfiguration'

function recursionConfiguration(val, lists, charsets) {
    val.forEach((item) => {
        if (item.type === MENU_TYPE) {
            return recursionConfiguration(item.val, lists, charsets)
        }

        if (charsets && (item.type === STRING_TYPE || item.type === PHONE_TYPE)) {
            item.charset = charsets[item.charset]
            return item
        }

        if (item.type === CODE_TYPE || item.type === CODE_HEX_TYPE) {
            item.val = '*'.repeat(item.val.length)
            return item
        }

        if (lists && item.type === LIST_TYPE) {
            item.items = lists[item.items]
            return item
        }
    })

    return val
}

// change stars to non-printable dots and ... don't trust anyone, even your backend!
export default function configurationMutate(item) {
    recursionConfiguration(item.val, item.lists, item.charsets)
    return item
}
