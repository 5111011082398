import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import {connect} from 'react-redux'
import {SettingsPageBlock} from 'pages/Settings/SettingsPageBlock'
import {selectMfa} from 'modules/system/settings/selectors'

class MFAGeneral extends SettingsPageBlock {
    static propTypes = {
        mfaEnabled: PropTypes.bool.isRequired,
        messageBroker: PropTypes.object,
    }

    render() {
        const {mfaEnabled, messageBroker} = this.props

        return (
            <>
                {this.renderListItem(__('MFA Status'), mfaEnabled)}
                {this.renderListItem(
                    __('SMS Broker'),
                    messageBroker && messageBroker.name
                )}
            </>
        )
    }
}

export default connect(selectMfa)(MFAGeneral)
