import {useSelector} from 'react-redux'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch, fetchWithoutLoader} from 'modules/features/store/actions'

export default function useFeatures(panelId) {
    return {
        ...useSelector((state) => selectPanelFeatures(state, {panelId})),
        ...useActions({
            fetchFeaturesWithoutLoader: fetchWithoutLoader,
            fetchFeatures: fetch,
        }),
    }
}
