import React from 'react'
import PropTypes from 'prop-types'

import Spinner from 'ipmp-react-ui/Spinner'
import Error from 'ipmp-react-ui/Error'

import RemoteInspectionResults from 'components/RemoteInspection/Results'

import {__} from 'utils/i18n'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'

export default function RemoteInspectionsResultContent({state}) {
    let {result, inspection} = useRemoteInspectionsResult()

    if (inspection && inspection.progress !== null) {
        return <Spinner message={__('Running')} />
    }

    if (!result || !result.result.length) {
        return <Error title={__('No Results')} />
    }

    result = {
        ...result,
        result: (result?.result || []).filter(
            (foundResult) => foundResult.state === state
        ),
    }

    return <RemoteInspectionResults remoteInspectionResult={result} />
}

RemoteInspectionsResultContent.propTypes = {
    state: PropTypes.string.isRequired,
}
