import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import {edit} from 'permissions/users/actions'
import list from 'permissions/users/list'
import selection from 'permissions/users/selection'
import withUsers from 'containers/withUsers'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import {withRejection} from 'containers/withPermission'
import usePermission from 'hooks/usePermission'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import * as actions from 'modules/system/settings/actions'

import EditCell from './TableCell/EditCell'
import StatusCell from './TableCell/StatusCell'
import ActionButton from './TableCell/Actions/ActionButton'

const allColumns = [
    {
        name: () => __('Status'),
        width: 42,
        fixed: true,
        component: StatusCell,
    },
    {
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        name: () => __('Role'),
        render: ({roleName}) => roleName,
    },
    {
        name: () => __('Email'),
        render: ({email}) => email,
    },
    {
        name: () => __('Phone'),
        render: ({phone}) => phone,
    },
    {
        name: () => __('Last login'),
        render: ({lastLogin}) => <DateTime datetime={lastLogin} />,
    },
    {
        name: () => __('Created by'),
        render: ({userCreatorName}) =>
            userCreatorName || <span className="empty">&mdash;</span>,
    },
    {
        fixed: true,
        width: 113,
        component: ActionButton,
    },
    {
        tooltip: false,
        permission: edit,
        maxWidth: 50,
        fixed: true,
        component: EditCell,
    },
]

const ConnectedTable = compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                fetchMFA: actions.fetchMFA,
            },
            dispatch
        )
    ),
    withRejection(),
    withUsers(),
    withSelection(),
    withLoader(
        ({init, startPoll, fetchMFA}) => {
            init()
            startPoll()
            fetchMFA()
        },
        ({stopPoll}) => {
            stopPoll()
        }
    )
)(Table)

export default function UsersTable(props) {
    const permissions = usePermission({
        isAllowed: list,
        hasSelection: selection,
    })

    const tableProps = {...props, ...permissions}
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <ConnectedTable
            {...tableProps}
            columns={columns}
            emptyMessage={() => __('No users found')}
            fullHeight
            rowHeight={42}
            className="user-status-table"
            rowClassName="user-status-table-row"
        />
    )
}
