import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import {showMasterActivateGUIPanel} from 'modules/modals/actions'
import {selectPanel} from 'modules/panels/store/selectors'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'
import {ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED} from 'constants/panelActivationStatus'

export default compose(
    connect(
        (state, {panelId, isVisible}) => {
            const {activationStatus} = selectPanel(state, {panelId})

            return {
                isVisible:
                    activationStatus ===
                    ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED,
            }
        },
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    showActivatePanel: () => showMasterActivateGUIPanel(panelId),
                },
                dispatch
            )
    ),
    withVisibility(),
    withProps(({showActivatePanel}) => ({
        label: __('Activate'),
        onClick: showActivatePanel,
    }))
)(Button)
