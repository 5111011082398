import React from 'react'
import {compose} from 'redux'
import selection from 'permissions/centralStations/selection'

import withSelection from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'

import SelectionDropDown from 'components/SelectionDropDown'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/pages/useCentralStations'

const SelectionDropDownConnected = compose(
    withVisibility(),
    withSelection()
)(SelectionDropDown)

export default function CentralStationsDropDown() {
    const {isPermitted} = usePermission(selection)
    const centralStations = useCentralStations()

    return <SelectionDropDownConnected isVisible={isPermitted} {...centralStations} />
}
