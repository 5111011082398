import Gettext from 'node-gettext'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import {sprintf} from 'sprintf-js'
import {getLangDir} from 'rtl-detect'
import mapValues from 'lodash-es/mapValues'

import {err, warn} from './log'

const gettext = new Gettext({debug: false})

const loaded = []
const domain = 'messages'
let currentLocale = null

export const addLocale = (locale, data) => {
    const translations = mapValues(data.translations, (context) =>
        mapValues(context, (msgstr) => ({msgstr}))
    )

    gettext.addTranslations(locale, domain, {
        ...data,
        translations,
    })

    loaded.push(locale)
}

export const isLoaded = (locale) => loaded.includes(locale)

async function resolveMomentLocale(locale) {
    const momentLocale = [locale, locale.substr(0, 2)]

    let isAtLeastOneLocaleModuleLoaded = false

    for (const locale of momentLocale) {
        try {
            await import(`moment/locale/${locale.toLowerCase()}`)
            isAtLeastOneLocaleModuleLoaded = true
            break
        } catch (e) {
            warn(e.message)
        }
    }

    const resultLocale = moment.locale(momentLocale)
    momentTimezone.locale(momentLocale)

    if (!isAtLeastOneLocaleModuleLoaded) {
        err(`None of these locales are exist in Moment.js lib: ${momentLocale}
Locale '${resultLocale}' was applying`)
    }
}

export const setLocale = (locale) => {
    gettext.setLocale(locale)
    currentLocale = locale

    resolveMomentLocale(locale)

    setLangDirection(locale)
}

export function getCurrentLocale() {
    return currentLocale
}

const __ = (key, ...vars) => {
    if (!currentLocale) {
        warn(`Trying to get translation '${key}' while locale is not inited`)
    }

    if (vars.length) {
        return sprintf(gettext.gettext(key), ...vars)
    } else {
        return gettext.gettext(key)
    }
}

export default __
export {__}

export const __n = (key, pluralKey, count, vars) => {
    if (!vars) {
        vars = [count]
    }

    if (!currentLocale) {
        warn(`Trying to get translation '${key}' while locale is not inited`)
    }

    return sprintf(gettext.ngettext(key, pluralKey, count), ...vars)
}

export const months = () =>
    new Array(12).fill(0).map((_, month) => moment().month(month).format('MMMM'))

export const weekdays = (format = 'ddd') =>
    new Array(7).fill(0).map((_, index) => {
        const day = moment().weekday(index)

        return {
            day: day.isoWeekday(),
            label: day.format(format),
        }
    })

export function ordinals(count = 10) {
    return new Array(count).fill(0).map((_, number) => {
        ++number
        switch (number) {
            case 1:
                return __('1st')
            case 2:
                return __('2nd')
            case 3:
                return __('3rd')
            default:
                return number + __('th')
        }
    })
}

const setLangDirection = (locale) => {
    document.documentElement.setAttribute('dir', getLangDir(locale))
}
