import React from 'react'
import PropTypes from 'prop-types'

import {Tab} from 'ipmp-react-ui/Tabs'
import RemoteInspectionsResultContent from './RemoteInspectionsResultContent'

function RemoteInspectionsTab({name, state}) {
    return (
        <Tab name={name}>
            <RemoteInspectionsResultContent state={state} />
        </Tab>
    )
}

RemoteInspectionsTab.propTypes = {
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
}

export default RemoteInspectionsTab
