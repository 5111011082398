import React from 'react'

import Device from 'components/Devices/Device'

export default function ChildrenTab({devices, onSelect}) {
    if (!devices || !devices.length) {
        return null
    }

    return (
        <div className="devicesList">
            {devices.map((device) => (
                <Device key={device.id} {...{device}} onClick={onSelect} />
            ))}
        </div>
    )
}
