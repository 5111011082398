import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import has from 'lodash-es/has'
import {SEARCH_SUGGESTS_COUNT} from 'constants/search'

/**
 * Works only for full list of suggests
 */
export default class TranslateSuggestFilter extends SuggestFilter {
    isTypeMore = (suggest) => {
        return Object.keys(suggest.values).length > SEARCH_SUGGESTS_COUNT
    }

    constructor(key, name, translate) {
        super(key, name)
        this.translate = translate
    }

    getItemLabel(value) {
        if (this.translate instanceof Function) {
            return this.translate(value)
        }

        if (has(this.translate, value)) {
            return this.translate[value]
        }

        return value
    }
}
