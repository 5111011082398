import React from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import withForm from 'containers/withForm'
import * as PropTypes from 'prop-types'
import Input from 'ipmp-react-ui/Input'

import {setPanelUserLabel} from 'modules/forms/handlers'
import {useSelector} from 'react-redux'
import {getUserLabelFormat} from 'constants/panelUsers'
import {VENDOR_QOLSYS, VENDOR_NEO} from 'constants/panelVendorType'

const getRules = (panelId) => {
    const vendor = useSelector(({panels}) => {
        return panels.store.byIds[panelId].vendor
    })
    return {
        label: {
            presence: vendor !== VENDOR_QOLSYS,
            format: getUserLabelFormat(vendor),
            length: {
                maximum: vendor !== VENDOR_NEO ? 50 : 255,
            },
        },
    }
}

const SetPanelUserLabel = ({handle, panelId, panelUserId, userLabel, ...props}) => {
    const onHandle = ({label}) => {
        const payload = {panelId, panelUserId, label}
        handle(payload)
    }
    return (
        <ModalCardForm
            onSubmit={onHandle}
            confirmOnDismiss
            rules={getRules(panelId)}
            header={__('Set panel user label')}
            {...props}
        >
            <Input label={__('Label')} name="label" defaultValue={userLabel} />
        </ModalCardForm>
    )
}

SetPanelUserLabel.propTypes = {
    handle: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}

export default withForm(setPanelUserLabel)(SetPanelUserLabel)
