import {__} from 'utils/i18n'
import panelInfo from 'constants/panelInfo'

import {NotSetEmpty} from 'ui/Empty'
import {PANEL_TYPE_QOLSYS} from 'constants/panelType'
import get from 'lodash-es/get'

export const preparePanelInfoGridItems = (panel, fotaAotaVersions) => {
    let result = []

    const getFirmwareValue = (key, panel) => {
        return get(panel, ['firmware', key])
    }

    const firmware = panel.firmware || {}
    const isQolsys = (panel) => panel.model === PANEL_TYPE_QOLSYS

    if (panel.simNumber) {
        result.push({title: __('Sim number'), text: panel.simNumber})
    }

    result = result.concat(
        Object.keys(firmware).map((key) => ({
            title: panelInfo(key),
            text: getFirmwareValue(key, panel) || NotSetEmpty,
        }))
    )

    if (!isQolsys(panel)) {
        result = result.concat(
            Object.keys(fotaAotaVersions).map((key) => ({
                title: panelInfo(key),
                text: fotaAotaVersions[key] || NotSetEmpty,
            }))
        )
    }

    return result
}
