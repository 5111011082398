import React from 'react'
import {compose} from 'redux'

import walktest from 'permissions/panel/devices/walktest'

import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as WalkIcon} from 'ipmp-react-ui/icons/walk.svg'
import {ReactComponent as DiagnosticIcon} from 'ipmp-react-ui/icons/build.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {withFeatureRejection} from 'containers/withFeature'
import {selectWalktestFeature} from 'modules/features/store/selectors'
import Void from 'components/Void'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'

const ButtonVisibleWithFeature = compose(
    withVisibility(),
    withFeatureRejection(selectWalktestFeature, Void)
)(Button)

export default function ToggleWalktestButton() {
    const {isPermitted} = usePermission(walktest)
    const {showWalktest, toggleWalktest, ...props} = useDevices()

    return (
        <ButtonVisibleWithFeature
            {...props}
            isVisible={isPermitted}
            onClick={toggleWalktest}
            Icon={showWalktest ? DiagnosticIcon : WalkIcon}
            label={showWalktest ? __('Diagnostics') : __('Walktest')}
        />
    )
}
