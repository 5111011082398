import {editEventsProfile} from 'modules/forms/handlers'
import withForm from 'containers/withForm'
import {compose} from 'redux'
import {connect} from 'react-redux'

import EventsForm, {EVENT_PROFILE} from './EventsForm'
import {__} from 'utils/i18n'

export default compose(
    withForm(editEventsProfile),
    connect(
        ({groups}, {groupId}) => {
            const settings = groups.notifications.byIds[groupId]

            return {
                data: settings,
                header: __('Edit events profile'),
                profiles: settings.eventProfile,
                availableProfiles: settings.availableProfiles.eventProfile,
                fieldGroupKey: EVENT_PROFILE,
            }
        },
        (dispatcher, {groupId, handle}) => ({
            handle: (data) => handle(groupId, data),
        })
    )
)(EventsForm)
