import {call, put, select} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'

import {unassignInstallerFromPanel} from 'api/installers'
import {update as updateInstaller} from 'modules/installers/store/actions'
import * as actions from '../actions'

export function* watchUnassignPanel({payload: panelId}) {
    try {
        const installerId = yield select(
            (state) => state.installerPanels.list.installerId
        )
        const installer = yield select(
            (state) => state.installers.store.byIds[installerId]
        )

        yield call(unassignInstallerFromPanel, {panelId, installerId})
        yield put(actions.unassignInstallerSuccess({panelId, installerId}))
        yield put(updateInstaller({...installer}))
    } catch (error) {
        yield put(snackShow(error.message))

        yield put(actions.revertRemove(error))
    }
}
