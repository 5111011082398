import {get} from 'api/http'
import * as commands from 'modules/events/alarms/commands'
import {superVisionEventsSeverityFilter} from 'constants/eventSeverity'
import {err} from 'utils/log'
import {sendSWMessage} from 'utils/serviceWorker/serviceWorkerUtils'
import isDevMode from 'utils/isDevMode'

export default class SupervisionService {
    lastAlertId
    alertCount = 0
    isRunning = false

    confirmations = new Set()
    timeout = 1000
    confirmTimeout = 500

    start = (currentAlertId) => {
        if (!this.isRunning) {
            this.isRunning = true
            this.lastAlertId = currentAlertId
            this.getAlerts()
        }
    }

    reset = (currentAlertId) => {
        this.alertCount = 0
        this.lastAlertId = currentAlertId
    }

    getAlerts = () => {
        if (!this.isRunning) {
            return
        }

        get('/event/getallevents', {
            start: 0,
            count: 100,
            sort: '-evt_id',
            filter: `${superVisionEventsSeverityFilter} && :evt_id>${this.lastAlertId};`,
        })
            .then(({count, rows}) => {
                count = parseInt(count)
                this.alertCount += count

                if (count) {
                    this.postMessageToAllPorts({
                        command: commands.COMMAND_UPDATE,
                        count: this.alertCount,
                    })

                    this.lastAlertId = rows[0].evt_id

                    this.postMessageToAnyPortWithConfirmation({
                        command: commands.COMMAND_ALERT,
                        rows,
                    })
                }

                setTimeout(this.getAlerts, this.timeout)
            })
            .catch((error) => {
                err('error', error)
                setTimeout(this.getAlerts, this.timeout)
            })
    }

    postMessageToAllPorts = (message) => {
        sendSWMessage(message)
    }

    postMessageToAnyPortWithConfirmation = (message) => {
        message.confirmationId = Date.now()

        this.postAndWaitConfirm(message)
    }

    postAndWaitConfirm = (message) => {
        if (isDevMode()) {
            console.log('send', message.confirmationId)
        }

        sendSWMessage(message)

        setTimeout(() => {
            if (isDevMode()) {
                console.log(
                    'confirmation timeout',
                    message.confirmationId,
                    this.confirmations.has(message.confirmationId)
                )
            }

            if (!this.confirmations.has(message.confirmationId)) {
                this.postAndWaitConfirm(message)
            } else {
                this.confirmations.delete(message.confirmationId)
            }
        }, this.confirmTimeout)
    }

    receiveMessage = (event) => {
        const {command, currentAlertId, confirmationId} = event.data

        switch (command) {
            case commands.COMMAND_START:
                if (this.isRunning) {
                    event.source.postMessage({
                        command: commands.COMMAND_UPDATE,
                        count: this.alertCount,
                    })
                } else {
                    this.start(currentAlertId)
                }
                break

            case commands.COMMAND_RESET:
                this.reset(currentAlertId)

                this.postMessageToAllPorts({
                    command: commands.COMMAND_UPDATE,
                    count: 0,
                })
                break

            case commands.COMMAND_STOP:
                this.makeStop()
                break

            case commands.COMMAND_CONFIRM:
                this.confirmations.add(confirmationId)
                break

            case commands.COMMAND_UNSUBSCRIBE:
                break
        }
    }

    makeStop = () => (this.isRunning = false)
}
