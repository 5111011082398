import {selectBasicConfigurationsListRows} from 'modules/basicConfiguration/list/selectors'
import {bindActionCreators} from 'redux'
import {
    fetch,
    init,
    remove,
    reset,
    setStart,
} from 'modules/basicConfiguration/list/actions'
import {showPushBasicConfigurationModal} from 'modules/modals/actions'
import usePerPage from '../usePerPage'
import {useDispatch, useSelector} from 'react-redux'

export default function useBasicConfigurations() {
    const dispatch = useDispatch()
    const perPage = usePerPage('basicConfiguration')

    const {isLoading, error, total, start} = useSelector(
        ({basicConfiguration}) => basicConfiguration.list
    )

    return {
        ...bindActionCreators(
            {
                init,
                fetch,
                reset,
                remove,
                onPageChange: setStart,
                showPushBasicConfigurationModal,
            },
            dispatch
        ),
        ...perPage,
        rows: useSelector(selectBasicConfigurationsListRows),
        isLoading,
        error,
        total,
        start,
    }
}
