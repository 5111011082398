import React from 'react'

import {ReviewButtonBase} from 'pages/RemoteInspection/Content/Result/Footer/ReviewButton'
import {ReactComponent as ViewedIcon} from 'ipmp-react-ui/icons/viewed.svg'
import __ from 'utils/i18n'
import {usePanelRemoteInspectionsResult} from 'hooks/pages/useRemoteInspectionsResult'

export default function ReviewButton({remoteInspectionResult}) {
    const {review} = usePanelRemoteInspectionsResult(remoteInspectionResult)

    return (
        <ReviewButtonBase
            onClick={review}
            flat
            Icon={ViewedIcon}
            label={__('Reviewed')}
            disabled={!remoteInspectionResult || remoteInspectionResult.isReviewed}
        />
    )
}
