import React from 'react'
import PropTypes from 'prop-types'
import formatPartitions from 'utils/partitions'

export function Partitions({partitions}) {
    if (!partitions || !partitions.length) {
        return null
    }

    return (
        <span className="partitions">
            {formatPartitions(partitions).map((partition) => (
                <span key={partition} className="partition">
                    {partition}
                    <wbr />
                </span>
            ))}
        </span>
    )
}

Partitions.propTypes = {
    partitions: PropTypes.array,
}
