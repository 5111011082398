import {__} from 'utils/i18n'
import {VENDOR_NEO, VENDOR_QOLSYS} from './panelVendorType'
import {ROLE_USER} from './accountRoles'

export const PANEL_MASTER_USER_ID = 0

export const getEntityNumber = (userId) => {
    return parseInt(userId) + 1
}

const userFields = {
    label: null,
    email: null,
    isPinSet: false,
    partitions: '',
    createdAt: null,
    isAssign: false,
    status: null,
    interactiveUserId: null,
}

const groupById = (users) => {
    return users.reduce(
        (users, user) => ({
            ...users,
            [user.id]: {
                ...userFields,
                ...user,
                index: user.id,
                partitions: user.partitions?.length ? user.partitions.map(String) : '',
            },
        }),
        {}
    )
}

const getUsers = (maxUserCount, existsUsers) => {
    const users = groupById(existsUsers)

    for (let i = 0; i < maxUserCount; i++) {
        if (users[i]) {
            continue
        }

        users[i] = {
            id: i,
            role: ROLE_USER,
            ...userFields,
            index: i,
        }
    }

    return Object.values(users)
}

export const getPanelUsersList = (existsUsers, count) => {
    const users = getUsers(count, existsUsers)

    return {users, count}
}

export function getUserLabelFormat(vendor) {
    switch (vendor) {
        case VENDOR_NEO:
            return {
                pattern: /^[a-zA-Z 0-9,!"#$%&'()*./:;<=>?@[\]^_`{|}°×⁻←→√∞█･ｰ]+$/,
                message: () =>
                    __(
                        "Invalid characters used. Allowed only digits, letters and !#$%&'()*+,-./:;<=>?@[]^_`{|}°×⁻←→√∞█･ｰ symbols"
                    ),
            }
        case VENDOR_QOLSYS:
            return {
                pattern: /^[a-zA-Z 0-9_\-#&'|]+$/,
                message: () =>
                    __(
                        "Invalid characters used. Allowed only digits, letters and -#&' symbols"
                    ),
            }
        default:
            return {
                pattern: /^.*$/,
            }
    }
}
