import React from 'react'
import {compose} from 'redux'

import {markAsViewed as markAsViewedPermission} from 'permissions/events/actions'

import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import Button from 'ipmp-react-ui/Button'
import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useEvents from 'hooks/pages/useEvents'

const ButtonVisibilitySelection = compose(
    withVisibility(),
    withSelectionHandler()
)(Button)

export default function MarkAsViewedButton() {
    const {markAsViewed} = useEvents()
    const {isPermitted} = usePermission(markAsViewedPermission)

    return (
        <ButtonVisibilitySelection
            isVisible={isPermitted}
            onClick={markAsViewed}
            label={__('Mark As Viewed')}
        />
    )
}
