import React from 'react'
import get from 'lodash-es/get'
import {compose} from 'redux'

import {STATUS_SUSPENDED} from 'constants/interactiveUserStatus'
import {suspend} from 'permissions/interactiveUsers/actions'
import {withVisibility} from 'containers/withVisibility'
import withSelection, {withSelectionHandler} from 'containers/withSelection'
import * as actions from 'modules/interactiveUsers/store/actions'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'

const ConnectedButton = compose(
    withVisibility(),
    withSelection(),
    withSelectionHandler(
        (id, {interactiveUsers}) =>
            get(interactiveUsers, ['store', 'byIds', id, 'status']) === STATUS_SUSPENDED
    )
)(Button)

export default function InteractiveEnableButton() {
    const {isPermitted} = usePermission(suspend)
    const {enable} = useActions({enable: actions.enable})

    return (
        <ConnectedButton
            isVisible={isPermitted}
            label={__('Enable')}
            onClick={(selection) => enable(selection)}
        />
    )
}
