import React from 'react'
import {compose} from 'redux'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

import bar from 'permissions/panel/log/bar'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import NewSystemLogButton from 'components/SystemLogs/NewSystemLogButton'

export const LogBar = () => {
    return (
        <div className="configuration-header">
            <Bar orientation={RIGHT}>
                <NewSystemLogButton />
            </Bar>
        </div>
    )
}

export default compose(withPermission({isVisible: bar}), withVisibility())(LogBar)
