import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {remove} from 'modules/eventRules/list/actions'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'

import __ from 'utils/i18n'

export default compose(
    connect(null, (dispatcher, {row: {id}}) =>
        bindActionCreators(
            {
                onRemove: () => remove([id]),
            },
            dispatcher
        )
    ),
    withProps(({onRemove, row: {name}}) => ({
        onClick: onRemove,
        flat: true,
        small: true,
        Icon: IconRemove,
        message: __('Do you really want to remove event rule?'),
        title: __('Remove Event Rule'),
    })),
    withConfirmation()
)(Button)
