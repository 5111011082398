import React from 'react'

import {create} from 'permissions/roles/actions'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import {ReactComponent as IconAdd} from 'ipmp-react-ui/icons/plus.svg'
import usePermission from 'hooks/usePermission'
import useRoles from 'hooks/pages/useRoles'

const ButtonVisibility = withVisibility()(Button)

export default function AddRoleButton() {
    const {showAddRole} = useRoles()
    const {isPermitted} = usePermission(create)

    return (
        <ButtonVisibility
            isVisible={isPermitted}
            Icon={IconAdd}
            onClick={showAddRole}
            label={__('Add Role')}
        />
    )
}
