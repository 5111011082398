import {withSearchScope} from 'containers/withSearchScopeLoader'
import React, {useCallback, useState} from 'react'
import {compose} from 'redux'

import list from 'permissions/events/list'
import selection from 'permissions/events/selection'

import withSelection from 'containers/withSelection'
import {withRejection} from 'containers/withPermission'

import EventContext from './EventContext'
import EventsListPage from './EventsListPage'
import useEvents from 'hooks/pages/useEvents'
import usePermission from 'hooks/usePermission'
import EventsBlindModal from './EventsBlindModal'

const EventsPageBase = (props) => {
    if (props.match.params.scope && props.match.params.serial && !props.match.params.id) {
        props.match.params = {
            ...props.match.params,
            id: props.match.params.serial,
            serial: props.match.params.scope,
            scope: undefined,
        }
    }

    return <EventsListPage {...props} />
}

const ConnectedEventsPage = compose(
    withRejection(),
    withSelection(),
    withSearchScope()
)(EventsPageBase)

export default function EventsPage(props) {
    const eventsProps = useEvents(props)
    const permissions = usePermission({isAllowed: list, hasSelection: selection})

    const eventsBySerialProps = {...props, ...eventsProps, ...permissions}
    const [selectedEventId, setEventId] = useState(null)

    const handleEventSelect = (event) => {
        setEventId(event?.id)
    }
    const handleEventDeliverance = useCallback(handleEventSelect)

    return (
        <EventContext.Provider
            value={{
                handleEventSelect: handleEventDeliverance,
                selectedEventId,
            }}
        >
            <ConnectedEventsPage {...eventsBySerialProps} />
            <EventsBlindModal
                {...eventsBySerialProps}
                selectedEventId={selectedEventId}
            />
        </EventContext.Provider>
    )
}
