import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import withNamedError from 'ipmp-react-ui/withNamedError'
import formatError from 'ipmp-react-ui/_utils/formatError'
import classnames from 'classnames'

import __ from 'utils/i18n'
import {hadIcon, hadTitle, hadTypes} from 'constants/had'

function SelectPgmTypeItem({onClick, activeType, pgmType}) {
    const onClickHandler = () => {
        onClick(pgmType)
    }

    return (
        <div
            className={classnames('selectPgmType-item', {
                'selectPgmType-item--active': activeType === pgmType,
            })}
            onClick={onClickHandler}
        >
            <div className="selectPgmType-item-iconContainer">{hadIcon(pgmType)}</div>
            {hadTitle(pgmType)}
        </div>
    )
}

SelectPgmTypeItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    activeType: PropTypes.string.isRequired,
    pgmType: PropTypes.string.isRequired,
}

function SelectPgmType({
    name,
    onChange,
    defaultValue = hadTypes[0],
    error,
    className,
    label = __('Icon'),
}) {
    const [selectedPgmType, setSelectedPgmType] = useState(defaultValue)

    function onChangeHandler(event) {
        onChange(event, selectedPgmType)
    }

    function onClickHandle(pgmType) {
        setSelectedPgmType(pgmType)
    }

    return (
        <div
            className={classnames('selectPgmType', className, {
                'form-field--error': error,
            })}
        >
            <h3 className="selectPgmType-title">{label}</h3>
            {hadTypes.map((pgmType) => (
                <SelectPgmTypeItem
                    key={pgmType}
                    onClick={useCallback(onClickHandle, hadTypes)}
                    activeType={selectedPgmType}
                    pgmType={pgmType}
                />
            ))}
            <input
                hidden
                name={name}
                onChange={onChangeHandler}
                value={selectedPgmType}
            />
            {error && <span className="form-field-error">{formatError(error)}</span>}
        </div>
    )
}

export default withNamedError(SelectPgmType)
