import {createSelector} from 'reselect'

export const selectInstallerPanelsList = createSelector(
    (state) => state.installerPanels.list,
    (state) => state.installerPanels.store.byIds,
    ({page, isLoading}, store) => ({
        rows: page.map((id) => store[id] || {}),
        isLoading,
    })
)

export const selectIsPanelAssigned = createSelector(
    (state) => state.installerPanels.list,
    (state, id) => id,
    (list, id) => {
        return {
            isAssigned: list.page.includes(id),
        }
    }
)
