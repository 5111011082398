import React from 'react'
import PropTypes from 'prop-types'

import Disclaimer from 'ipmp-react-ui/Disclaimer'

export default function FormFieldWithDisclaimer({disclaimer, children}) {
    return (
        <div className="form-field-withDisclaimer">
            {children}
            <Disclaimer>{disclaimer}</Disclaimer>
        </div>
    )
}

FormFieldWithDisclaimer.propTypes = {
    disclaimer: PropTypes.node.isRequired,
}
