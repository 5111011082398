import get from 'lodash-es/get'

import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {fetch} from 'modules/panels/panelUsers/actions'
import withSelectLoader from 'containers/withSelectLoader'
import {getUserRoleTitle, isAllowManipulateUser} from 'constants/accountRoles'
import {panelUserSelectComponent} from './PanelUserSelectComponent'
import {isNull} from 'lodash-es'
import {getEntityNumber} from 'constants/panelUsers'

export const PanelUserSelect = withSelectLoader(
    (prefix, maxOptionsToShow, {panelId}) => fetch(panelId),
    ({panels}) => panels.panelUsers.isLoading,
    ({
        modals: {
            args: {panelId},
        },
        panels: {
            panelUsers: {byIds},
        },
    }) =>
        get(byIds, panelId, [])
            .filter(({role}) => isAllowManipulateUser(role))
            .map(({id, role, label}) => {
                const existedLabel = isNull(label) ? '' : label
                return {
                    value: id,
                    label: `${getEntityNumber(id)} – ${getUserRoleTitle(role)} ${
                        existedLabel && '–'
                    } ${existedLabel}`,
                    component: {
                        render: () => panelUserSelectComponent(id, role, label),
                    },
                }
            }),
    10,
    true
)(Autocomplete)
