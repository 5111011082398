import React from 'react'

import Select, {Option} from 'ipmp-react-ui/Select'

import __ from 'utils/i18n'
import {dlsNetworkProtocols, getDlsNetworkProtocolLabel} from 'constants/protocols'

export const SelectDlsNetworkProtocols = () => {
    const options = dlsNetworkProtocols.map((protocol) => (
        <Option
            value={protocol}
            label={getDlsNetworkProtocolLabel(protocol)}
            key={protocol}
        />
    ))
    return (
        <Select name="networkProtocol" label={__('Network protocol')}>
            {options}
        </Select>
    )
}
