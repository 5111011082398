import MasterUserToRemember from 'modules/panels/recent/utils/MasterUserToRemember'
import get from 'lodash-es/get'
import find from 'lodash-es/find'
import findIndex from 'lodash-es/findIndex'

function checkIfSomePanelWasUnassigned(interactiveUsers) {
    return (historyItem) => {
        const interactiveUserForPanel = find(interactiveUsers, {
            panelId: Number(get(historyItem, ['panel', 'id'], null)),
        })

        if (!interactiveUserForPanel) {
            return {
                ...historyItem,
                interactiveUser: new MasterUserToRemember({
                    panelId: null,
                    interactiveUserId: null,
                    panelUserId: null,
                    role: null,
                    email: null,
                    status: null,
                    createdAt: null,
                }),
            }
        }
        return {...historyItem, interactiveUser: interactiveUserForPanel}
    }
}

export default function updateHistoryItems(historyItems: Array, interactiveUsers: Array) {
    // Set null for interactive user for unassigned panels
    let newHistoryItems = historyItems.map(
        checkIfSomePanelWasUnassigned(interactiveUsers)
    )

    interactiveUsers.forEach((interactiveUser) => {
        const indexItemWithSamePanelId = findIndex(
            newHistoryItems,
            ({panel: {id}}) => id === interactiveUser.panelId
        )

        // check if interactive user has changed for the current panels in the history
        const historyItemAccordingToCurrentInteractiveUser =
            newHistoryItems[indexItemWithSamePanelId]

        if (
            get(historyItemAccordingToCurrentInteractiveUser, [
                'interactiveUser',
                'interactiveUserId',
                false,
            ])
        ) {
            newHistoryItems[indexItemWithSamePanelId] = {
                ...newHistoryItems[indexItemWithSamePanelId],
                interactiveUser: new MasterUserToRemember(interactiveUser),
            }
        }
    })

    // If in the story as a result there are several panels with one interactive user, we leave the last one.
    for (let i = newHistoryItems.length - 1; i >= 0; i--) {
        const currentHistoryElement = newHistoryItems[i]
        const isInteractiveUser = get(
            currentHistoryElement,
            ['interactiveUser', 'interactiveUserId'],
            false
        )

        if (isInteractiveUser) {
            const indexHistoryItemWithSameInteractiveUser = findIndex(
                newHistoryItems,
                ({interactiveUser}) =>
                    interactiveUser.interactiveUserId ===
                    currentHistoryElement.interactiveUser.interactiveUserId
            )
            const isExist = indexHistoryItemWithSameInteractiveUser !== -1
            const isLaterThanCurrentHistoryItem =
                indexHistoryItemWithSameInteractiveUser < i
            if (isExist && isLaterThanCurrentHistoryItem) {
                newHistoryItems.splice(indexHistoryItemWithSameInteractiveUser, 1)
            }
        }
    }

    return newHistoryItems
}
