import React, {Component} from 'react'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'

export class PinCell extends Component {
    static propTypes = {
        isPinSet: PropTypes.bool.isRequired,
    }

    render() {
        if (this.props.isPinSet) {
            return <IconCheck className="success" />
        }

        return <span className="empty">{__('Not Set')}</span>
    }
}
