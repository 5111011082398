import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {setEnabled} from 'modules/users/store/actions'
import {suspend} from 'permissions/users/selection'
import {useActions} from 'modules/higherOrder/useActions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import {withVisibility} from 'containers/withVisibility'

const ConnectedButton = compose(withVisibility())(WithConfirmationButton)

export default function SuspendUserButton({id, name}) {
    const {isPermitted} = usePermission(suspend)
    const setSuspend = useActions(setEnabled)
    const onClick = () => setSuspend([id], false)

    return (
        <ConnectedButton
            flat
            isVisible={isPermitted}
            onClick={onClick}
            Icon={PauseIcon}
            title={__('Suspend user')}
            message={__('Do you really want to suspend the user: %s?', name)}
            label={__('Suspend')}
            className="user-status-button-suspend"
        />
    )
}

SuspendUserButton.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
}
