import {takeEvery, all, put} from 'redux-saga/effects'

import * as api from 'api/panel/systemLogs'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import generateProcess from 'modules/processes/manager/generateProcess'
import {PROCESS_TYPE_SYSTEM_LOG_FETCH} from 'constants/processTypes'
import {fetch} from 'modules/panels/systemLogs/list/actions'
import {snackShow} from 'modules/snacks'

import * as actions from './actions'

export default function* () {
    yield all([
        takeEvery(actions.refresh, watchRefresh),
        takeEveryProcessComplete([PROCESS_TYPE_SYSTEM_LOG_FETCH], watchProcessComplete),
    ])
}

function* watchRefresh({payload: {panelId}}) {
    const {batchId} = yield generateBatchForOneProcess(
        PROCESS_TYPE_SYSTEM_LOG_FETCH,
        panelId
    )
    const {execute, process} = yield generateProcess(
        PROCESS_TYPE_SYSTEM_LOG_FETCH,
        panelId
    )

    yield put(actions.updateRefreshProcess(panelId, process))

    try {
        yield execute(api.refresh, panelId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.updateRefreshProcess(panelId, null))
    }
}

function* watchProcessComplete({isFailed, panelId, errorMessage}) {
    if (isFailed) {
        yield put(snackShow(errorMessage))
    } else {
        yield put(fetch(panelId))
    }

    yield put(actions.updateRefreshProcess(panelId, null))
}
