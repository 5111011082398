import * as t from 'constants/processTypes'
import {__} from 'utils/i18n'
import {err} from 'utils/log'

const processTypeMap = new Map([
    [t.PROCESS_TYPE_FEATURES, () => __('Features')],
    [t.PROCESS_TYPE_PMAXSOFTWAREUPGRADE, () => __('Upgrade Software')],
    [t.PROCESS_TYPE_PLINKSOFTWAREUPGRADE, () => __('Upgrade Plink')],
    [t.PROCESS_TYPE_PMAXANONYMOUS, () => __('Anonymous')],
    [t.PROCESS_TYPE_PMAXSETSOAKZONE, () => __('Set soak')],
    [t.PROCESS_TYPE_PMAXCANCELSOAKZONE, () => __('Cancel soak')],
    [t.PROCESS_TYPE_PMAXSETBYPASSZONE, () => __('Enable bypass')],
    [t.PROCESS_TYPE_PMAXCLEARBYPASSZONE, () => __('Disable bypass')],
    [t.PROCESS_TYPE_PMAXCONFIGBACKUP, () => __('Backup configuration')],
    [t.PROCESS_TYPE_PMAXCONFIGBASICUPLOAD, () => __('Push basic configuration')],
    [t.PROCESS_TYPE_PMAXCONFIGDOWNLOAD, () => __('Download configuration')],
    [t.PROCESS_TYPE_PMAXCONFIGUPLOAD, () => __('Upload configuration')],
    [t.PROCESS_TYPE_PMAXFILMONDEMAND, () => __('Record video')],
    [t.PROCESS_TYPE_PMAXLOGLEGACY, () => __('Download legacy log')],
    [t.PROCESS_TYPE_PMAXLOGSTANDARD, () => __('Download standard log')],
    [t.PROCESS_TYPE_NEOLIVELOG, () => __('Download neo log')],
    [t.PROCESS_TYPE_PMAXPYTHONUPGRADE, () => __('Python Upgrade')],
    [t.PROCESS_TYPE_PMAXSETCUSTOMZONES, () => __('Set custom zones')],
    [t.PROCESS_TYPE_PMAXSTATEGET, () => __('Refresh state')],
    [t.PROCESS_TYPE_PMAXSTATESET, () => __('Set state')],
    [t.PROCESS_TYPE_PMAXZONEADD, () => __('Add device')],
    [t.PROCESS_TYPE_PMAXZONEREMOVE, () => __('Remove device')],
    [t.PROCESS_TYPE_PMAXACTIVATESIREN, () => __('Activate siren')],
    [t.PROCESS_TYPE_PMAXDISABLESIREN, () => __('Disable Siren')],
    [t.PROCESS_TYPE_PMAXZONERSSI, () => __('Refresh RSSI')],
    [t.PROCESS_TYPE_PMAXZONEWALKTEST, () => __('Walktest')],
    [t.PROCESS_TYPE_RESETPASSWORD, () => __('Set User Code')],
    [t.PROCESS_TYPE_REMOTEINSPECTION, () => __('Remote Inspection')],
    [t.PROCESS_TYPE_SOFTWAREREPOSYNC, () => __('Sync software repository')],
    [t.PROCESS_TYPE_DISABLEX10, () => __('Disable X10')],
    [t.PROCESS_TYPE_ENABLEX10, () => __('Enable X10')],
    [t.PROCESS_TYPE_DIMMERX10, () => __('Dimmer X10')],
    [t.PROCESS_TYPE_BRIGHTERX10, () => __('Brighter X10')],
    [t.PROCESS_TYPE_REPORT, () => __('Report')],
    [t.PROCESS_TYPE_PMAXSETVODMODE, () => __('Set VOD mode')],
    [t.PROCESS_TYPE_PMAXRESETRECEIVERIP, () => __('Remove panel')],
    [t.PROCESS_TYPE_PMAXASSIGNPARTITIONS, () => __('Assign Partitions')],
    [t.PROCESS_TYPE_NEOACTIVATION, () => __('Activation')],
    [t.PROCESS_TYPE_USER_ACTIVATION, () => __('User Activation')],
    [t.PROCESS_TYPE_FIRMWARE_UPGRADE, () => __('Firmware upgrade')],
    [t.PROCESS_TYPE_NEO_UPGRADE, () => __('Upgrade Software')],
    [t.PROCESS_TYPE_LTE_UPGRADE, () => __('LTE upgrade')],
    [t.PROCESS_TYPE_SET_ICON, () => __('Set icon')],
    [t.PROCESS_TYPE_SET_LABEL, () => __('Set label')],
    [t.PROCESS_TYPE_EDITPGM, () => __('Set PGM')],
    [t.PROCESS_TYPE_PANEL_DISCOVERY, () => __('Panel Discovery')],
    [t.PROCESS_TYPE_NEO_DIAGNOSTIC, () => __('Read diagnostic info')],
    [t.PROCESS_TYPE_REFRESH_GSM, () => __('Refresh GSM')],
    [t.PROCESS_TYPE_HAD_COMMAND, () => __('Output command')],
    [t.PROCESS_TYPE_CHANGEINSTALLERPIN, () => __('Change installer code')],
    [t.PROCESS_TYPE_SETPANELUSERCODE, () => __('Set User Code')],
    [t.PROCESS_TYPE_SETUSERPARTITIONS, () => __('Assign Partitions')],
    [t.PROCESS_TYPE_FIRE_ALARM, () => __('Fire Alarm')],
    [t.PROCESS_TYPE_PANIC_ALARM, () => __('Panic Alarm')],
    [t.PROCESS_TYPE_EMERGENCY_ALARM, () => __('Emergency Alarm')],
    [t.PROCESS_TYPE_ACTIVATE_SIREN, () => __('Activate Siren')],
    [t.PROCESS_TYPE_EVENT_INITIATE, () => __('Initiate Emergency')],
    [t.PROCESS_TYPE_CONNECT_DLS, () => __('Connect DLS')],
    [t.PROCESS_TYPE_DISCONNECT_DLS, () => __('Disconnect DLS')],
    [t.PROCESS_TYPE_SYSTEM_LOG_FETCH, () => __('Download system log')],
])

export default function processType(type) {
    if (processTypeMap.has(type)) {
        return processTypeMap.get(type)()
    }

    err(`Unknown process type ${type}`)

    return type || __('Unknown')
}

const links = new Map([
    [t.PROCESS_TYPE_FEATURES, ''],
    [t.PROCESS_TYPE_PMAXSOFTWAREUPGRADE, 'panel.firmware'],
    [t.PROCESS_TYPE_PLINKSOFTWAREUPGRADE, 'panel.firmware'],
    [t.PROCESS_TYPE_FIRMWARE_UPGRADE, 'panel.firmware'],
    [t.PROCESS_TYPE_NEO_UPGRADE, 'panel.firmware'],
    [t.PROCESS_TYPE_PMAXANONYMOUS, ''],
    [t.PROCESS_TYPE_PMAXSETSOAKZONE, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXCANCELSOAKZONE, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXSETBYPASSZONE, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXCLEARBYPASSZONE, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXCONFIGBACKUP, 'panel.configuration'],
    [t.PROCESS_TYPE_PMAXCONFIGBASICUPLOAD, 'panel.configuration'],
    [t.PROCESS_TYPE_PMAXCONFIGDOWNLOAD, 'panel.configuration'],
    [t.PROCESS_TYPE_PMAXCONFIGUPLOAD, 'panel.configuration'],
    [t.PROCESS_TYPE_PMAXFILMONDEMAND, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXLOGLEGACY, 'panel.log'],
    [t.PROCESS_TYPE_PMAXLOGSTANDARD, 'panel.log'],
    [t.PROCESS_TYPE_NEOLIVELOG, 'panel.log'],
    [t.PROCESS_TYPE_PMAXPYTHONUPGRADE, ''],
    [t.PROCESS_TYPE_PMAXSETCUSTOMZONES, 'panel.locations'],
    [t.PROCESS_TYPE_PMAXSTATEGET, 'panel.state'],
    [t.PROCESS_TYPE_PMAXSTATESET, 'panel.state'],
    [t.PROCESS_TYPE_PMAXZONEADD, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXZONEREMOVE, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXACTIVATESIREN, ''],
    [t.PROCESS_TYPE_PMAXDISABLESIREN, ''],
    [t.PROCESS_TYPE_PMAXZONERSSI, 'panel.devices'],
    [t.PROCESS_TYPE_REFRESH_GSM, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXZONEWALKTEST, 'panel.devices'],
    [t.PROCESS_TYPE_RESETPASSWORD, 'panel.interactiveUsers'],
    [t.PROCESS_TYPE_REMOTEINSPECTION, 'panel.remoteInspections'],
    [t.PROCESS_TYPE_SOFTWAREREPOSYNC, ''],
    [t.PROCESS_TYPE_DISABLEX10, ''],
    [t.PROCESS_TYPE_ENABLEX10, ''],
    [t.PROCESS_TYPE_DIMMERX10, ''],
    [t.PROCESS_TYPE_BRIGHTERX10, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL0, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL1, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL2, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL3, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE0, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE1, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE2, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE3, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER0, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER1, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER2, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER3, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER0, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER1, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER2, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER3, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER0, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER1, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER2, ''],
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER3, ''],
    [t.PROCESS_TYPE_REPORT, 'panel.reports'],
    [t.PROCESS_TYPE_PMAXSETVODMODE, 'panel.devices'],
    [t.PROCESS_TYPE_PMAXRESETRECEIVERIP, ''],
    [t.PROCESS_TYPE_PMAXASSIGNPARTITIONS, 'panel.interactiveUsers'],
    [t.PROCESS_TYPE_NEOACTIVATION, 'panel'],
    [t.PROCESS_TYPE_USER_ACTIVATION, 'panel'],
    [t.PROCESS_TYPE_SET_LABEL, 'panel.devices'],
    [t.PROCESS_TYPE_PANEL_DISCOVERY, 'panel.devices'],
    [t.PROCESS_TYPE_NEO_DIAGNOSTIC, 'panel.devices'],
    [t.PROCESS_TYPE_CHANGEINSTALLERPIN, 'panel.installers'],
    [t.PROCESS_TYPE_SETPANELUSERCODE, 'panel.interactiveUsers'],
    [t.PROCESS_TYPE_SETUSERPARTITIONS, 'panel.interactiveUsers'],
    [t.PROCESS_TYPE_FIRE_ALARM, 'panel.events'],
    [t.PROCESS_TYPE_PANIC_ALARM, 'panel.events'],
    [t.PROCESS_TYPE_EMERGENCY_ALARM, 'panel.events'],
    [t.PROCESS_TYPE_ACTIVATE_SIREN, 'panel.events'],
    [t.PROCESS_TYPE_HAD_COMMAND, 'panel.had'],
    [t.PROCESS_TYPE_EDITPGM, 'panel.had'],
    [t.PROCESS_TYPE_DISABLEPGM, 'panel.had'],
    [t.PROCESS_TYPE_EVENT_INITIATE, 'panel.events'],
    [t.PROCESS_TYPE_SET_ICON, 'panel.had'],
    [t.PROCESS_TYPE_SET_LABEL, 'panel.devices'],
    [t.PROCESS_TYPE_CONNECT_DLS, 'panel.devices'],
    [t.PROCESS_TYPE_DISCONNECT_DLS, 'panel.devices'],
    [t.PROCESS_TYPE_SYSTEM_LOG_FETCH, 'panel.log'],
])

export function getProcessLinkByType(type) {
    if (links.has(type)) {
        return links.get(type)
    }

    err(`Undeclared link for process type ${type}`)

    return null
}
