import React from 'react'
import __ from 'utils/i18n'
import Select, {Option} from 'ipmp-react-ui/Select'

export const speeds = ['9600', '19200', '38400', '57600', '115200']

export const SelectConnectionSpeed = () => {
    const options = speeds.map((speed, key) => (
        <Option key={key} value={speed} label={speed} />
    ))

    return (
        <Select name="connectionSpeed" label={__('Connection Speed')}>
            {options}
        </Select>
    )
}
