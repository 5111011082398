export class ThermostatModeCommand {
    constructor({command, mode}) {
        this.command = command
        this.mode = mode
    }
}

export class ThermostatTargetCommand {
    constructor({command, target, mode}) {
        this.command = command
        this.mode = mode
        this.target = target
    }
}
