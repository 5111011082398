import {useSelector} from 'react-redux'
import {createPermissionsSelector} from 'modules/auth/permissions/selectors'

export default function usePermission(permissions, propName = 'isPermitted') {
    if (typeof permissions === 'function') {
        permissions = {
            [propName]: permissions,
        }
    }

    return useSelector(createPermissionsSelector(permissions))
}
