import React from 'react'
import {compose} from 'redux'

import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {ReactComponent as ViewIcon} from 'ipmp-react-ui/icons/viewed.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {markAsViewed as markAsViewedPermission} from 'permissions/remoteInspections/selection'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ButtonVisibility = compose(withVisibility(), withSelectionHandler())(Button)

export default function MarkAsViewedRemoteInspectionButton() {
    const {isPermitted} = usePermission(markAsViewedPermission)
    const {markAsViewed} = useRemoteInspections()

    return (
        <ButtonVisibility
            isVisible={isPermitted}
            onClick={markAsViewed}
            Icon={ViewIcon}
            label={__('Mark as viewed')}
        />
    )
}
