import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip/tooltip'
import classnames from 'classnames'

import {__} from 'utils/i18n'
import {
    CHANNEL_USB,
    CHANNEL_BBA,
    CHANNEL_GPRS,
    CHANNEL_BBA_OVER_GPRS,
    CHANNEL_WIFI,
    ENCRYPTION_DEFAULT,
    ENCRYPTION_NO,
    ENCRYPTION_ITV2_V1,
    ENCRYPTION_ITV2_V2,
    ENCRYPTION_DTLS,
    STATUS_NOT_CONFIGURED,
    STATUS_DISCONNECTED,
    STATUS_KA_DISABLED,
    STATUS_OFFLINE,
    STATUS_ONLINE,
    STATUS_CONNECTED,
    STATUS_CONFIGURED_TO_SLAVE,
    STATUS_CONFIGURED_OUTSIDE_PM,
    PROTOCOL_LWM2M_BOOTSTRAP,
} from 'constants/connections'
import {ConnectionButtons} from 'pages/Panel/Devices/Sidebar/Tabs/Buttons/ConnectionButtons'

const ConnectionStatusTab = ({connectionStatus: {status}, panelId}) => {
    const getConnectionTypeTitle = (name) => {
        switch (name) {
            case PROTOCOL_LWM2M_BOOTSTRAP:
                return __('LWM2M BOOTSTRAP')
            default:
                return name
        }
    }

    const renderChannelCell = (connection) => {
        switch (connection.channel_type) {
            case CHANNEL_USB:
                return __('USB') + ' ' + connection.number
            case CHANNEL_BBA:
                return __('BBA') + ' ' + connection.number
            case CHANNEL_GPRS:
            case CHANNEL_BBA_OVER_GPRS:
                return __('GPRS') + ' ' + connection.number
            case CHANNEL_WIFI:
                return __('WI-FI') + ' ' + connection.number
            default:
                return <span className="empty">-</span>
        }
    }

    const renderEncryptionCell = (connection) => {
        switch (connection.encryption) {
            case ENCRYPTION_DEFAULT:
                return __('Encrypted')
            case ENCRYPTION_ITV2_V1:
                return __('ITv2 type 1')
            case ENCRYPTION_ITV2_V2:
                return __('ITv2 type 2')
            case ENCRYPTION_DTLS:
                return __('DTLS')
            case ENCRYPTION_NO:
                return <span className="empty">{__('Unencrypted')}</span>
            default:
                return <span className="empty">-</span>
        }
    }

    const renderStatusCell = (connection) => {
        switch (connection.value) {
            case STATUS_NOT_CONFIGURED:
                return <span className="empty">{__('Not Configured')}</span>
            case STATUS_DISCONNECTED:
                return <span className="danger">{__('Not Connected')}</span>
            case STATUS_KA_DISABLED:
                return <span className="danger">{__('Keep-Alive disabled')}</span>
            case STATUS_OFFLINE:
                return <span className="danger">{__('Offline')}</span>
            case STATUS_ONLINE:
                return <span className="success">{__('Online')}</span>
            case STATUS_CONNECTED:
                return <span className="success">{__('Connected')}</span>
            case STATUS_CONFIGURED_TO_SLAVE:
                return <span className="primary">{__('Configured to Slave')}</span>
            case STATUS_CONFIGURED_OUTSIDE_PM:
                return <span className="primary">{__('Configured outside PM')}</span>
            default:
                return <span className="empty">-</span>
        }
    }

    const renderConnection = (name, connection) => {
        return (
            <Fragment key={connection.number}>
                <td className="channel-cell">{renderChannelCell(connection)}</td>
                <td className="status-cell">{renderStatusCell(connection)}</td>
                <td className="encryption-cell">{renderEncryptionCell(connection)}</td>
                <td className="buttons-container">
                    <ConnectionButtons
                        name={name}
                        connection={connection}
                        panelId={panelId}
                    />
                </td>
            </Fragment>
        )
    }

    const renderRow = (name, connections, key) => {
        return (
            <Fragment key={name + key}>
                <tr className="connection-block">
                    <td
                        className="connection-block-title-cell"
                        rowSpan={connections.length}
                    >
                        <OverflowTextTooltip
                            className={classnames('connection-block-title', {
                                'connection-block-title-singleline':
                                    connections.length < 2,
                            })}
                            tooltip={getConnectionTypeTitle(name)}
                        >
                            {getConnectionTypeTitle(name)}
                        </OverflowTextTooltip>
                    </td>
                    {renderConnection(name, connections[0])}
                </tr>
                {connections.slice(1).map((connection, idx) => (
                    <tr key={name + key + idx}>{renderConnection(name, connection)}</tr>
                ))}
            </Fragment>
        )
    }

    return (
        <div className="connection-status">
            <table>
                <thead>
                    <tr>
                        <th>{__('Protocol')}</th>
                        <th className="channel-cell">{__('Channel')}</th>
                        <th className="status-cell">{__('Status')}</th>
                        <th className="encryption-cell">{__('Encryption')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(status).map((name, idx) =>
                        renderRow(name, status[name], idx)
                    )}
                </tbody>
            </table>
        </div>
    )
}

ConnectionStatusTab.propTypes = {
    connectionStatus: PropTypes.shape({
        status: PropTypes.object.isRequired,
    }).isRequired,
    panelId: PropTypes.number.isRequired,
}

export default ConnectionStatusTab
