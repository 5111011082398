import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {ReactComponent as IconOpen} from 'ipmp-react-ui/icons/disarm.svg'
import {ReactComponent as IconClose} from 'ipmp-react-ui/icons/away.svg'

import SetHadOffButton from 'pages/Panel/Had/List/SetHadOffButton'
import SetHadOnButton from 'pages/Panel/Had/List/SetHadOnButton'
import {isOff, isOn} from 'constants/had/hadState'

const HadDeviceDoorlockControl = ({had: {id, state}, disabled}) => {
    return (
        <div
            className={classnames(
                'device-had-controls-stateButtons',
                'device-had-controls-stateButtons--doorlock',
                {
                    'device-had-controls-stateButtons--disabled': Boolean(disabled),
                }
            )}
        >
            <SetHadOffButton Icon={IconClose} id={id} disabled={isOn(state?.pgm)} />
            <SetHadOnButton Icon={IconOpen} id={id} disabled={isOff(state?.pgm)} />
        </div>
    )
}

HadDeviceDoorlockControl.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.shape({pgm: PropTypes.number}),
    }).isRequired,
    disabled: PropTypes.bool,
}

export default HadDeviceDoorlockControl
