import React, {useCallback, useState} from 'react'
import HadList from './HadList'

import Layout from 'ipmp-react-ui/Layout'
import HadBar from 'pages/Panel/Had/HadBar'
import HadContext from 'pages/Panel/Had/HadContext'
import HadBlindModal from 'pages/Panel/Had/HadBlindModal'

const Had = () => {
    const [selectedHadId, setHadId] = useState(null)

    const handleHadSelect = (hadId) => {
        setHadId(hadId)
    }

    return (
        <Layout vertical className="devices devices--had">
            <HadBar />
            <HadContext.Provider
                value={{
                    handleHadSelect: useCallback(handleHadSelect),
                    selectedHadId,
                }}
            >
                <HadList />
                <HadBlindModal selectedHadId={selectedHadId} />
            </HadContext.Provider>
        </Layout>
    )
}
export default Had
