import React from 'react'

import {Chip} from 'ipmp-react-ui/Chips'

import {getRoleClassname, getUserRoleTitle} from 'constants/accountRoles'
import {getEntityNumber} from 'constants/panelUsers'

export const panelUserSelectComponent = (id, role, label) => (
    <span>
        {getEntityNumber(id)} –{' '}
        <Chip className={getRoleClassname(role)}>{getUserRoleTitle(role)}</Chip>{' '}
        {label && '–'} {label && label}
    </span>
)
