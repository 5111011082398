import * as moment from 'moment'
import {err} from 'utils/log'
import {
    TODAY,
    YESTERDAY,
    WEEK_AGO,
    TOMORROW,
    NEXT_WEEK,
    LAST_MONTH,
    NEXT_MONTH,
} from 'constants/dateFilter'

export function getFilterDate(needle) {
    switch (needle) {
        case TODAY:
            return {
                from: moment().startOf('day'),
                to: moment().endOf('day'),
            }
        case YESTERDAY:
            return {
                from: moment().subtract(1, 'day').startOf('day'),
                to: moment().subtract(1, 'day').endOf('day'),
            }
        case WEEK_AGO:
            return {
                from: moment().subtract(7, 'day').startOf('day'),
            }
        case LAST_MONTH:
            return {
                from: moment().subtract(1, 'month').startOf('day'),
            }
        case TOMORROW:
            return {
                from: moment().add(1, 'day').startOf('day'),
                to: moment().add(1, 'day').endOf('day'),
            }
        case NEXT_WEEK:
            return {
                from: moment().add(7, 'day').startOf('day'),
            }
        case NEXT_MONTH:
            return {
                from: moment().add(1, 'month').startOf('day'),
            }
        default:
            break
    }

    if (needle.from && needle.to) {
        return {
            to: moment(needle.to).endOf('day'),
            from: moment(needle.from).startOf('day'),
        }
    }

    const date = moment(needle)

    if (date.isValid()) {
        return {
            from: date.startOf('day'),
        }
    }

    err('Invalid date')
}
