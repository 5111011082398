import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import SettingsCard from 'components/Settings/SettingsCard'
import RemoteInspection from './RemoteInspection'
import Rotation from './Rotation'
import MFAParameters from './MFAParameters'
import MFAGeneral from './MFAGeneral'
import GoogleSettings from './GoogleSettings'
import PgmActivationTimeSlot from './PgmActivationTimeSlot'
import {
    showSystemSettingsMFAGeneralModal,
    showSystemSettingsMFAParametersModal,
    showSystemSettingsRemoteInspectionModal,
    showSystemSettingsRotationModal,
    showSystemSettingsGoogleModal,
    showSystemSettingsPgmActivationTimeSlotModal,
} from 'modules/modals/actions'
import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'
import __ from 'utils/i18n'
import Layout from 'ipmp-react-ui/Layout'

function Resolve({
    remoteInspectionModal,
    rotationModal,
    showSystemSettingsMFAParametersModal,
    showSystemSettingsMFAGeneralModal,
    showSystemSettingsGoogleModal,
    showSystemSettingsPgmActivationTimeSlotModal,
}) {
    return (
        <Layout>
            <ScrollView className="settings-grid settings-grid-resolve">
                <SettingsCard
                    title={__('Remote Inspection')}
                    onEditAction={remoteInspectionModal}
                >
                    <RemoteInspection />
                </SettingsCard>
                <SettingsCard title={__('Rotation')} onEditAction={rotationModal}>
                    <Rotation />
                </SettingsCard>

                <SettingsCard
                    title={__('Multi-Factor Authentication General')}
                    onEditAction={showSystemSettingsMFAGeneralModal}
                >
                    <MFAGeneral />
                </SettingsCard>

                <SettingsCard
                    title={__('PGM Activation settings')}
                    disclaimer={__(
                        'Enable this feature to make possible an activation of Outputs or PGMs on a panel during set time window when alarm is received. It allows to avoid false activation of security equipment.'
                    )}
                    onEditAction={showSystemSettingsPgmActivationTimeSlotModal}
                >
                    <PgmActivationTimeSlot />
                </SettingsCard>

                <SettingsCard
                    title={__('Google Settings')}
                    disclaimer={__(
                        'When Google API key is set you can use location features as panel location displaying'
                    )}
                    onEditAction={showSystemSettingsGoogleModal}
                >
                    <GoogleSettings />
                </SettingsCard>

                <SettingsCard
                    title={__('Multi-Factor Authentication Parameters')}
                    onEditAction={showSystemSettingsMFAParametersModal}
                >
                    <MFAParameters />
                </SettingsCard>
            </ScrollView>
        </Layout>
    )
}

Resolve.propTypes = {
    rotationModal: PropTypes.func.isRequired,
    remoteInspectionModal: PropTypes.func.isRequired,
    showSystemSettingsGoogleModal: PropTypes.func.isRequired,
    showSystemSettingsPgmActivationTimeSlotModal: PropTypes.func.isRequired,
    showSystemSettingsMFAGeneralModal: PropTypes.func.isRequired,
    showSystemSettingsMFAParametersModal: PropTypes.func.isRequired,
}

export default compose(
    connect(
        ({
            system: {
                settings: {
                    remoteInspection,
                    rotation,
                    mfa,
                    messageBrokers,
                    googleSettings,
                    pgmActivationTimeSlot,
                },
            },
        }) => ({
            remoteInspection,
            rotation,
            googleSettings,
            pgmActivationTimeSlot,
            isLoading:
                remoteInspection.isLoading ||
                rotation.isLoading ||
                mfa.isLoading ||
                messageBrokers.isLoading ||
                googleSettings.isLoading ||
                pgmActivationTimeSlot.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    remoteInspection: actions.fetchRemoteInspection,
                    rotation: actions.fetchRotation,
                    googleSettings: actions.fetchGoogleSettings,
                    pgmActivationTimeSlot: actions.fetchPgmActivationTimeSlot,
                    showSystemSettingsRemoteInspectionModal,
                    showSystemSettingsRotationModal,
                    fetchMFA: actions.fetchMFA,
                    fetchMessageBrokers: actions.fetchMessageBrokers,
                    showSystemSettingsMFAGeneralModal,
                    showSystemSettingsMFAParametersModal,
                    showSystemSettingsGoogleModal,
                    showSystemSettingsPgmActivationTimeSlotModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                remoteInspectionModal: () =>
                    dispatchProps.showSystemSettingsRemoteInspectionModal(
                        stateProps.remoteInspection
                    ),
                rotationModal: () =>
                    dispatchProps.showSystemSettingsRotationModal(stateProps.rotation),
                showSystemSettingsGoogleModal: () =>
                    dispatchProps.showSystemSettingsGoogleModal(
                        stateProps.googleSettings
                    ),
                showSystemSettingsPgmActivationTimeSlotModal: () =>
                    dispatchProps.showSystemSettingsPgmActivationTimeSlotModal(
                        stateProps.pgmActivationTimeSlot
                    ),
            }
        }
    ),
    withLoader(
        ({
            remoteInspection,
            googleSettings,
            pgmActivationTimeSlot,
            rotation,
            fetchMessageBrokers,
            fetchMFA,
        }) => {
            remoteInspection()
            googleSettings()
            pgmActivationTimeSlot()
            rotation()
            fetchMessageBrokers()
            fetchMFA()
        }
    )
)(Resolve)
