import React, {useEffect} from 'react'
import {compose} from 'redux'

import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'
import Table from 'ipmp-react-ui/Table'

import {list} from 'permissions/panel/customers/actions'
import {disconnectOnList} from 'permissions/interactiveUsers/actions'
import interactiveUsersList from 'permissions/interactiveUsers/list'
import withLoader from 'containers/withLoader'
import {withRejection} from 'containers/withPermission'
import usePermission from 'hooks/usePermission'
import {usePanelInteractiveUsers} from 'hooks/pages/useInteractiveUsers'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import {normalizePanelUserIndex} from 'utils/panelUser'
import {__} from 'utils/i18n'
import {Partitions} from 'components/Partitions'

import DisconnectUserCell from './TableCell/DisconnectUserCell'
import PanelInteractiveUserStatusChanger from './TableCell/PanelInteractiveUserStatusChanger'
import {PinCell} from './TableCell/PinCell'
import {RoleCell} from './TableCell/RoleCell'

const allColumns = [
    {
        name: () => __('№'),
        render: ({index}) => normalizePanelUserIndex(index),
        width: 16,
    },
    {
        name: () => __('Role'),
        render: ({role}) => <RoleCell role={role} />,
    },
    {
        name: () => __('Label'),
        render: ({label}) => label || <span className="empty">{__('No Label')}</span>,
    },
    {
        name: () => __('Code'),
        render: ({isPinSet}) => <PinCell isPinSet={isPinSet} />,
    },
    {
        name: () => __('Partitions'),
        render: ({partitions}) =>
            partitions ? (
                <Partitions partitions={partitions} />
            ) : (
                <span className="empty">{__('No partitions')}</span>
            ),
    },
    {
        name: () => __('Email'),
        permission: interactiveUsersList,
        render: ({email, isAssign}) =>
            isAssign ? (
                <Definition title={email} />
            ) : (
                <span className="empty">{__('No application user')}</span>
            ),
    },
    {
        name: () => __('Created'),
        permission: interactiveUsersList,
        render: ({createdAt}) =>
            createdAt ? (
                <DateTime datetime={createdAt} />
            ) : (
                <span className="empty">{__('No creation date')}</span>
            ),
    },
    {
        name: () => __('Status'),
        tooltip: false,
        permission: interactiveUsersList,
        component: PanelInteractiveUserStatusChanger,
    },
    {
        tooltip: false,
        permission: disconnectOnList,
        fixed: true,
        width: 56,
        component: DisconnectUserCell,
    },
]

const ConnectedTable = compose(withRejection(), withLoader())(Table)

export default function PanelInteractiveUsersTable({isShowUnassignedUsers, ...props}) {
    const {isPermitted} = usePermission(list)
    const panelInteractiveUsersProps = usePanelInteractiveUsers()

    useEffect(() => {
        panelInteractiveUsersProps.init()
        panelInteractiveUsersProps.startPoll()

        return () => panelInteractiveUsersProps.stopPoll()
    }, [])

    const tableProps = {...props, ...panelInteractiveUsersProps}
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <ConnectedTable
            {...tableProps}
            isAllowed={isPermitted}
            fullHeight
            className="panelUsers-table"
            columns={columns}
            emptyMessage={() => __('No interactive users found')}
        />
    )
}
