import {createSelector} from 'reselect'

export const oneCentralStationSelect = createSelector(
    (state) => state.centralStations.one,
    (one) => one
)

export const oneCentralStationDataSelect = createSelector(
    (state, {id}) => state?.centralStations?.store?.byIds[id],
    (centralStation) => centralStation
)
