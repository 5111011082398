import React, {PureComponent} from 'react'
import {compose} from 'redux'
import timezones from 'utils/timezones'
import keysAvailable from 'utils/keysAvailable'
import {humanTime} from 'ipmp-react-ui/humanTime'
import {rriFaultsDescription} from 'constants/rriFaults'
import __ from 'utils/i18n'
import withProps from 'containers/withProps'

class TimeFaults extends PureComponent {
    renderTime(dt) {
        const {date, time} = humanTime(timezones.server(dt, 'YYYY-MM-DD hh:mm:ss'))

        return (
            <span>
                <span className="rri-result-details-date">{date}</span>{' '}
                <span className="rri-result-details-time">{time}</span>
            </span>
        )
    }

    renderRow = ({device, type, state, user, dt}, index) => {
        return (
            <tr key={index}>
                {type && (
                    <td className="rri-result-details-row">
                        {rriFaultsDescription(type)}
                    </td>
                )}
                {device && <td className="rri-result-details-row">{device}</td>}
                {state && <td className="rri-result-details-row">{state}</td>}
                {user && <td className="rri-result-details-row">{user}</td>}
                {dt && <td className="rri-result-details-row">{this.renderTime(dt)}</td>}
            </tr>
        )
    }

    render() {
        const {rows, availableKeys} = this.props

        return (
            <table className="rri-result-details">
                <thead>
                    <tr>
                        {availableKeys.includes('type') && <th>{__('Type')}</th>}
                        {availableKeys.includes('device') && <th>{__('Device')}</th>}
                        {availableKeys.includes('state') && <th>{__('State')}</th>}
                        {availableKeys.includes('user') && <th>{__('User')}</th>}
                        {availableKeys.includes('dt') && <th>{__('Date and time')}</th>}
                    </tr>
                </thead>
                <tbody>{rows.map(this.renderRow)}</tbody>
            </table>
        )
    }
}

export default compose(
    withProps(({rows}) => {
        return {
            availableKeys: keysAvailable(rows, ['device', 'type', 'state', 'user', 'dt']),
        }
    })
)(TimeFaults)
