import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {fetch, setLoading, update, clear} from './actions'

const state = {
    isLoading: false,
    byIds: {},
}

export default handleActions(
    {
        [fetch]: (state) => set(state, 'isLoading', true),

        [update](state, {payload}) {
            const byIds = payload.reduce((acc, row) => {
                const old = acc[row.id]

                return {
                    ...acc,
                    [row.id]: {
                        ...old,
                        ...row,
                    },
                }
            }, state.byIds || {})

            return {
                ...state,
                byIds,
            }
        },

        [clear]() {
            return state
        },

        [setLoading]: (state, {payload: {isLoading}}) =>
            set(state, 'isLoading', isLoading),
    },
    state
)
