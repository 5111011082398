import React from 'react'

import {compose} from 'redux'

import {remove} from 'permissions/reports/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as RemoveIcon} from 'ipmp-react-ui/icons/remove.svg'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useReports from 'hooks/pages/useReports'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export default function RemoveReportsButton() {
    const {isPermitted} = usePermission(remove)
    const reportsProps = useReports()

    return (
        <ConnectedButton
            {...reportsProps}
            isVisible={isPermitted}
            onClick={reportsProps.remove}
            Icon={RemoveIcon}
            label={__('Remove')}
            title={__('Remove reports')}
            message={__('Do you want to remove selected reports?')}
        />
    )
}
