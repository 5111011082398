import React from 'react'

import list from 'permissions/reports/list'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import usePermission from 'hooks/usePermission'
import useReports from 'hooks/pages/useReports'

const ConnectedPager = withVisibility()(Pager)

export default function ReportsPager() {
    const {isPermitted} = usePermission(list)
    const reportsProps = useReports()

    return <ConnectedPager {...reportsProps} isVisible={isPermitted} />
}
