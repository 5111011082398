import timezones from 'utils/timezones'

const serverFormat = 'YYYY-MM-DD hh:mm:ss'

export function formatDate(date) {
    const m = timezones.server(date)

    if (!m.isValid()) {
        return date
    }

    return date.format(serverFormat)
}

export default function parseDate(date) {
    if (isEmptyDate(date)) {
        return null
    }
    const m = timezones.server(date, serverFormat)

    if (!m.isValid()) {
        return null
    }

    return m.toDate()
}

export function parseServerDate(date) {
    if (isEmptyDate(date)) {
        return null
    }

    const m = timezones.serverDate(date, serverFormat)

    return m.isValid() ? m.toDate() : null
}

function isEmptyDate(date) {
    return date === '0000-00-00 00:00:00' || date === null
}

export function timestampToDate(date) {
    if (isEmptyDate(date)) {
        return null
    }
    return parseServerDate(new Date(date * 1000))
}
