import React, {Component} from 'react'

import Radiobox from 'ipmp-react-ui/Radiobox'
import Input from 'ipmp-react-ui/Input'

import {__, __n} from 'utils/i18n'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'

const RECURRENCE_SELECTABLE = 'selectable'
const RECURRENCE_EVERY_DAY = 'everyDay'

export default class extends Component {
    state = {
        recurrence: RECURRENCE_SELECTABLE,
        recurrencePeriod: 1,
    }

    setRecurrence = (recurrence) => {
        this.setState({recurrence})
    }

    setRecurrencePeriod = ({target}) => {
        this.setState({
            recurrencePeriod: parseInt(target.value),
        })
    }

    render() {
        const {recurrence, recurrencePeriod} = this.state

        return (
            <FormSection>
                <FormRow>
                    <Radiobox
                        {...{
                            onChange: () => this.setRecurrence(RECURRENCE_SELECTABLE),
                            checked: recurrence === RECURRENCE_SELECTABLE,
                            label: __('Recur every'),
                            name: '_recurEvery',
                        }}
                    />

                    <Input
                        {...{
                            type: 'number',
                            onFocus: () => this.setRecurrence(RECURRENCE_SELECTABLE),
                            onChange: this.setRecurrencePeriod,
                            name: 'settings.every',
                            min: recurrence === RECURRENCE_SELECTABLE ? 1 : undefined,
                            max: recurrence === RECURRENCE_SELECTABLE ? 30 : undefined,
                            defaultValue: 1,
                            dataTestId: 'recur-every-input',
                        }}
                    />

                    <span>{__n('day', 'days', recurrencePeriod)}</span>
                </FormRow>

                <FormRow>
                    <Radiobox
                        {...{
                            onChange: () => this.setRecurrence(RECURRENCE_EVERY_DAY),
                            checked: recurrence === RECURRENCE_EVERY_DAY,
                            label: __('Recur every weekday'),
                            name: 'settings.everyWeekDay',
                        }}
                    />
                </FormRow>
            </FormSection>
        )
    }
}
