import React from 'react'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'
import {getRoleClassname, getUserRoleTitle} from 'constants/accountRoles'
import {Chip} from 'ipmp-react-ui/Chips'

export function RoleCell({role}) {
    if (role) {
        return <Chip className={getRoleClassname(role)}>{getUserRoleTitle(role)}</Chip>
    }

    return <span className="empty">{__('No Role')}</span>
}

RoleCell.propTypes = {
    role: PropTypes.string.isRequired,
}
