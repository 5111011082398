import {PANEL_FAMILY_SMART_COMMUNICATOR} from 'constants/panelFamily'
import {PANEL_TYPE_VIRTUAL_PANEL} from 'constants/panelType'

const panelPMaxSectionName = 'PANEL DEFINITION'
const panelSmartCommunicatorSectionName = 'Panel'
const panelVirtualPanelSectionName = 'Panel options'

export function useSectionName({isNeo, family, model}) {
    switch (true) {
        case family === PANEL_FAMILY_SMART_COMMUNICATOR:
            return panelSmartCommunicatorSectionName
        case model === PANEL_TYPE_VIRTUAL_PANEL:
            return panelVirtualPanelSectionName
        case !isNeo:
            return panelPMaxSectionName
        default:
            return null
    }
}
