import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import HadDeviceHint from 'components/Had/HadDevice/HadDeviceHint'
import HadDevicePartitions from 'components/Had/HadDevice/HadDevicePartitions'

import {hadConfigurableIcon, hadIcon} from 'constants/had'
import HadDeviceState from 'components/Had/HadDevice/HadDeviceState'
import HadContext from 'pages/Panel/Had/HadContext'

const HadDeviceGeneric = ({
    name,
    type,
    userType,
    id,
    idWithDeviceType,
    location,
    partitions,
    state,
    RightCornerComponent,
    onClick,
}) => {
    const onClickHandler = () => {
        onClick && onClick(idWithDeviceType)
    }
    const {selectedHadId} = useContext(HadContext)

    return (
        <div
            className={classes('device deviceHad', {
                'device--active': selectedHadId === idWithDeviceType,
            })}
            onClick={onClickHandler}
        >
            <div className="device-image">
                {userType ? hadConfigurableIcon(userType) : hadIcon(type)}
            </div>

            <div className="device-content">
                <HadDeviceHint id={id} location={location} type={type} />

                <div className="device-title">
                    {name}
                    {partitions && partitions.numbers && (
                        <HadDevicePartitions partitions={partitions} />
                    )}
                </div>

                <HadDeviceState state={state} type={type} />

                <div className="device-had-controls">{RightCornerComponent}</div>
            </div>
        </div>
    )
}

HadDeviceGeneric.propTypes = {
    type: PropTypes.number,
    userType: PropTypes.string,
    name: PropTypes.string,
    idWithDeviceType: PropTypes.string,
    state: PropTypes.object,
    location: PropTypes.shape({
        name: PropTypes.string,
    }),
    partitions: PropTypes.shape({
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        numbers: PropTypes.arrayOf(PropTypes.number),
    }),
    id: PropTypes.number,
    RightCornerComponent: PropTypes.element,
    onClick: PropTypes.func,
}

export default HadDeviceGeneric
