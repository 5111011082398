import React from 'react'
import withTrackProcess from 'containers/withTrackProcess'
import {compose} from 'redux'

import {refreshRssi as refreshRssiPermission} from 'permissions/panel/devices/actions'

import {withVisibility} from 'containers/withVisibility'

import RefreshButton from 'ipmp-react-ui/RefreshButton'

import {__} from 'utils/i18n'
import Void from 'components/Void'
import {selectRSSIDevices} from 'modules/features/store/selectors'
import {withFeatureRejection} from 'containers/withFeature'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'
import withProps from 'containers/withProps'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useSelector} from 'react-redux'
import {VENDOR_GENERIC} from 'constants/panelVendorType'

const ConnectedButton = compose(
    withVisibility(),
    withTrackProcess(({panel}) => panel.rssiRefreshProcess),
    withProps(({process}) => ({
        isRefreshing: !!process,
    })),
    withFeatureRejection(selectRSSIDevices, Void)
)(RefreshButton)

export default function RefreshRssiButton(props) {
    const {isPermitted} = usePermission(refreshRssiPermission)
    const devicesProps = useDevices()
    const panelId = useRouterPanelId()
    const panel = useSelector(({panels}) => panels.store.byIds[panelId])
    const isGeneric = panel.vendor === VENDOR_GENERIC
    const buttonProps = {...props, ...devicesProps}
    if (!isGeneric) {
        return (
            <ConnectedButton
                {...buttonProps}
                isVisible={isPermitted}
                label={__('Refresh RSSI')}
                onClick={devicesProps.refreshRssi}
            />
        )
    }
    return null
}
