import React from 'react'
import {useSelector} from 'react-redux'

import {ReactComponent as CheckIcon} from 'ipmp-react-ui/icons/circle-check.svg'
import {ReactComponent as LockIcon} from 'ipmp-react-ui/icons/locker.svg'
import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/circle-pause.svg'
import {ReactComponent as MinusIcon} from 'ipmp-react-ui/icons/circle-minus.svg'
import {ReactComponent as QuestionIcon} from 'ipmp-react-ui/icons/question.svg'

import {__} from 'utils/i18n'
import {selectMfaStatus} from 'modules/system/settings/selectors'

const getMfaStatus = () => useSelector(selectMfaStatus)

export const isUserBlocked = (isBlocked) => {
    const {mfaEnabled} = getMfaStatus()
    return isBlocked && mfaEnabled
}

export const getUserStatus = (isEnabled, isBlocked, isOnline) => {
    const userBlocked = isUserBlocked(isBlocked)
    switch (true) {
        case userBlocked:
            return {title: __('Blocked'), icon: <LockIcon />}
        case isEnabled && isOnline:
            return {title: __('Online'), icon: <CheckIcon />}
        case isEnabled && !isOnline:
            return {title: __('Offline'), icon: <MinusIcon />}
        case !isEnabled:
            return {title: __('Suspended'), icon: <PauseIcon />}
        default:
            return {title: __('Unknown status'), icon: <QuestionIcon />}
    }
}
