import {createAction} from 'redux-actions'

export const sendStartMessage = createAction(
    'EVENTS/ALARMS/SEND_START_MESSAGE',
    (lastId) => ({lastId})
)
export const setLastEventId = createAction(
    'EVENTS/ALARMS/SET_LAST_EVENT_ID',
    (lastId) => ({lastId})
)
export const setCount = createAction('EVENTS/ALARMS/SET_COUNT', (count) => ({count}))
