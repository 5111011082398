import createListActions from 'modules/higherOrder/createListActions'
import {createAction} from 'redux-actions'

export const {
    init,
    receive,
    reset,
    setStart,
    fetch,
    startPoll,
    stopPoll,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
    purgeList,
    receiveSuggests,
} = createListActions('PANEL/INTERACTIVE_USERS')

export const applyFilter = createAction('PANEL/INTERACTIVE_USERS/APPLY_FILTER')
