import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import * as api from 'api/had'
import * as actions from './actions'
import {getPanelId} from 'utils/panelIdMatchSelector'
import {snackShow} from 'modules/snacks'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_HAD_COMMAND} from 'constants/processTypes'
import generateProcess from 'modules/processes/manager/generateProcess'
import watchSubmitHadDeviceControlForm from 'modules/panels/had/watchers/watchSubmitHadDeviceControlForm'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {POLL_HAD} from 'configs/pollers'
import pollData, {pollDataSwitchable} from 'modules/panels/had/watchers/pollData'
import watchOnWithTimer from 'modules/panels/had/watchers/watchOnWithTimer'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.fetchSwitchable, watchFetchSwitchable),
        takeEvery(actions.on, watchOn),
        takeEvery(actions.onWithTimer, watchOnWithTimer),
        takeEvery(actions.off, watchOff),
        takeEvery(submitHadDeviceControlForm.SUCCESS, watchSubmitHadDeviceControlForm),
        createPollerSaga(actions.startPoll, actions.stopPoll, POLL_HAD, pollData),
        createPollerSaga(
            actions.startPollSwitchable,
            actions.stopPollSwitchable,
            POLL_HAD,
            pollDataSwitchable
        ),
    ])
}

function* watchFetch() {
    try {
        const panelId = yield select(getPanelId)
        const hadList = yield call(api.getHadList, panelId)
        yield put(actions.receive({hadList}))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchFetchSwitchable() {
    try {
        const panelId = yield select(getPanelId)

        const {isSwitchable, timeSlot, isTimeSlotEnabled} = yield call(
            api.isPanelsHadSwitchable,
            panelId
        )
        yield put(actions.receiveSwitchable({isSwitchable, timeSlot, isTimeSlotEnabled}))
    } catch (error) {
        yield put(actions.receiveSwitchable(error))
    }
}

function* watchOn({payload: {id, deviceType}}) {
    try {
        const {panelId, timestamp} = yield select((state) => ({
            panelId: getPanelId(state),
        }))
        const {batchId} = yield generateBatchForOneProcess(
            PROCESS_TYPE_HAD_COMMAND,
            panelId
        )

        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.setHadOn, {id, panelId, timestamp, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchOff({payload: {id, deviceType}}) {
    try {
        const panelId = yield select((state) => getPanelId(state))
        const {batchId} = yield generateBatchForOneProcess(
            PROCESS_TYPE_HAD_COMMAND,
            panelId
        )
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)

        yield execute(api.setHadOff, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
