import React from 'react'
import {useSelector} from 'react-redux'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import {useActions} from 'modules/higherOrder/useActions'
import {
    init as initCommunication,
    startPoll as startPollCommunication,
    stopPoll as stopPollCommunication,
} from 'modules/centralStations/groupsList/actions'
import {fetch as fetchProfiles} from 'modules/centralStations/profiles/actions'
import {groupsList} from 'modules/centralStations/groupsList/selectors'
import GroupCard, {GroupCardWrapper} from 'pages/CentralStation/Communication/GroupCard'
import {centralStationProfilesSelector} from 'modules/centralStations/profiles/selectors'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import groupList from 'permissions/groups/list'
import {withRejection} from 'containers/withPermission'

const WithRejectionLayout = withRejection()(Layout)

const GroupCardWrapperWithLoader = withLoader(
    ({init, fetch, startPoll}) => {
        fetch()
        init()
        startPoll()
    },
    ({stopPoll}) => {
        stopPoll()
    }
)(GroupCardWrapper)

const Communication = () => {
    const {init, startPoll, stopPoll, fetch} = useActions({
        init: initCommunication,
        startPoll: startPollCommunication,
        stopPoll: stopPollCommunication,
        fetch: fetchProfiles,
    })
    const {
        rows,
        isLoading: groupListIsLoading,
        error: groupListError,
    } = useSelector(groupsList)
    const {
        isLoading: profilesIsLoading,
        error: profilesError,
        byId,
    } = useSelector(centralStationProfilesSelector)
    const isLoading = profilesIsLoading || groupListIsLoading
    const error = groupListError ?? profilesError

    const {isPermitted} = usePermission(groupList)

    return (
        <WithRejectionLayout
            className="centralStation-communication"
            isAllowed={isPermitted}
        >
            <ScrollView>
                <GroupCardWrapperWithLoader
                    init={init}
                    fetch={fetch}
                    startPoll={startPoll}
                    stopPoll={stopPoll}
                    isLoading={isLoading}
                    error={error}
                >
                    {rows.map(({id, name, links}) => (
                        <GroupCard
                            key={id}
                            title={name}
                            links={links.map(
                                ({notificationProfileId}) => notificationProfileId
                            )}
                            id={id}
                            profiles={byId}
                        />
                    ))}
                </GroupCardWrapperWithLoader>
            </ScrollView>
        </WithRejectionLayout>
    )
}

export default Communication
