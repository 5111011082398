import {PANEL_TAB} from 'pages/Panel/PanelPageRouting'

import {default as devices} from './devices/page'
import {default as info} from './info/page'
import {default as state} from './state/page'
import {default as configuration} from './configuration/page'
import {default as locations} from './locations/page'
import {default as processes} from '../processes/page'
import {list as panelUsers} from './customers/actions'
import {default as reports} from './reports/page'
import {default as log} from './log/page'
import {default as remoteInspections} from './remoteInspections/page'
import {default as firmware} from './firmware/page'
import {default as events} from './events/page'
import {default as keypad} from './keypad/page'
import {default as had} from './output/page'
import {default as installers} from './panelInstallers/actions'

export default {
    [PANEL_TAB.DEVICES]: devices,
    [PANEL_TAB.INFO]: info,
    [PANEL_TAB.STATE]: state,
    [PANEL_TAB.CONFIGURATION]: configuration,
    [PANEL_TAB.LOCATIONS]: locations,
    [PANEL_TAB.PROCESSES]: processes,
    [PANEL_TAB.INTERACTIVE_USERS]: panelUsers,
    [PANEL_TAB.INSTALLERS]: installers,
    [PANEL_TAB.REPORTS]: reports,
    [PANEL_TAB.LOG]: log,
    [PANEL_TAB.REMOTE_INSPECTIONS]: remoteInspections,
    [PANEL_TAB.FIRMWARE]: firmware,
    [PANEL_TAB.EVENTS]: events,
    [PANEL_TAB.KEYPAD]: keypad,
    [PANEL_TAB.HAD]: had,
}
