import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'

import Supervision from './Supervision'
import {showSystemSettingsSupervisionModal} from 'modules/modals/actions'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'
import __ from 'utils/i18n'
import SettingsCard from 'components/Settings/SettingsCard'

export class Receiver extends Component {
    render() {
        const {supervisionModal} = this.props
        return (
            <ScrollView>
                <SettingsCard title={__('Supervision')} onEditAction={supervisionModal}>
                    <Supervision />
                </SettingsCard>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {supervision},
            },
        }) => ({
            supervision,
            isLoading: supervision.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    supervision: actions.fetchSupervision,
                    showSystemSettingsSupervisionModal,
                },
                dispatch
            ),
        ({supervision}, dispatchProps) => {
            return {
                ...dispatchProps,
                supervisionModal: () => {
                    dispatchProps.showSystemSettingsSupervisionModal(supervision)
                },
            }
        }
    ),
    withLoader(({supervision}) => {
        supervision()
    })
)(Receiver)
