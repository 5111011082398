import React from 'react'
import {compose} from 'redux'

import {remove} from 'permissions/basicConfiguration/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as RemoveIcon} from 'ipmp-react-ui/icons/remove.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useBasicConfigurations from 'hooks/pages/useBasicConfigurations'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export default function BasicConfigurationsRemoveButton() {
    const {isPermitted} = usePermission(remove)
    const basicConfigurationProps = useBasicConfigurations()

    return (
        <ConnectedButton
            {...basicConfigurationProps}
            isVisible={isPermitted}
            onClick={basicConfigurationProps.remove}
            message={__('Do you really want to remove selected basic configurations?')}
            title={__('Remove basic configurations')}
            label={__('Remove')}
            Icon={RemoveIcon}
        />
    )
}
