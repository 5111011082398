import React from 'react'
import {compose} from 'redux'

import withForm from 'containers/withForm'
import ModalCardForm from 'ui/ModalCardForm'

import __ from 'utils/i18n'
import {setSystemSettings} from 'modules/forms/handlers'
import {connect} from 'react-redux'
import {selectGoogleSettings} from 'modules/system/settings/selectors'
import Input from 'ipmp-react-ui/Input'
import FormFieldWithDisclaimer from 'ui/FormFieldWithDisclaimer'

const rules = {
    token: {
        length: {maximum: 300},
    },
}

function GoogleSettings({handle, token, ...props}) {
    return (
        <ModalCardForm
            className="googleSettings-form"
            onSubmit={(data) => handle(data)}
            rules={rules}
            header={__('Google settings')}
            confirmOnDismiss
            {...props}
        >
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'When Google API key is set you can use location features as panel location displaying'
                )}
            >
                <Input defaultValue={token} label={__('Google API key')} name="token" />
            </FormFieldWithDisclaimer>
        </ModalCardForm>
    )
}

export default compose(
    connect(selectGoogleSettings),
    withForm(setSystemSettings.GoogleSettings)
)(GoogleSettings)
