import React from 'react'
import {compose} from 'redux'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'
import {withFeatureRejection} from 'containers/withFeature'
import Void from 'components/Void'
import {selectMakeBasicConfigurationFeature} from 'modules/features/store/selectors'
import usePermission from 'hooks/usePermission'
import {
    useConfigurationActions,
    useCurrentConfiguration,
} from 'hooks/pages/useConfiguration'

const ConnectedButton = compose(
    withVisibility(),
    withConfirmation(),
    withFeatureRejection(selectMakeBasicConfigurationFeature, Void)
)(Button)

export function MakeBasicConfigurationButton({panelId}) {
    const {setMakeBasic} = useConfigurationActions(panelId)
    const {changes} = useCurrentConfiguration(panelId)
    const {isPermitted} = usePermission(makeBasic)

    return (
        <ConnectedButton
            panelId={panelId}
            isVisible={isPermitted}
            onClick={() => setMakeBasic(true)}
            label={__('Make Basic')}
            title={__('Not submitted changes')}
            message={__(
                'By switching to create basic configuration mode you will lose all configuration changes'
            )}
            isConfirmationEnabled={!!changes && Object.keys(changes).length > 0}
        />
    )
}
