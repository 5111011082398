import React from 'react'
import classnames from 'classnames'

import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import {ReactComponent as DropDownIcon} from 'ipmp-react-ui/icons/drop-down.svg'
import Time from 'ipmp-react-ui/Time'
import {InteractiveUserEmail} from './RecentHistoryItemEmail'

export const RenderRecentHistoryItem = ({
    isPanelListOpened,
    togglePanelList,
    panelSerial,
    account,
    visitTime,
    interactiveUserEmail,
    renderPanelList,
}) => {
    const visitDate = new Date(visitTime)

    return (
        <div
            className={classnames('recentHistory-item', {
                'recentHistory-item--active': isPanelListOpened,
            })}
        >
            <header className="recentHistory-item-header" onClick={togglePanelList}>
                <div className="recentHistory-item-infoWrapper">
                    <div className="recentHistory-item-info-iconWrapper">
                        <IconPanel />
                    </div>
                    <div className="recentHistory-info-wrapper">
                        <div className="recentHistory-item-info recentHistory-item-info--panelSerial">
                            {panelSerial}
                            {account && (
                                <span className="recentHistory-account">{account}</span>
                            )}
                        </div>
                        <InteractiveUserEmail
                            interactiveUserEmail={interactiveUserEmail}
                        />
                        <div className="recentHistory-visitTime">
                            <Time datetime={visitDate} />
                        </div>
                    </div>
                </div>
                {togglePanelList && (
                    <DropDownIcon className="recentHistory-item-header-dropDownIcon" />
                )}
            </header>
            <div
                className={classnames('recentHistory-item-panelList', {
                    'recentHistory-item-panelList-closed': !isPanelListOpened,
                    'recentHistory-item-panelList-opened': isPanelListOpened,
                })}
            >
                {renderPanelList && renderPanelList()}
            </div>
        </div>
    )
}
