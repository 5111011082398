import {takeEvery, all, put, call} from 'redux-saga/effects'

import * as api from 'api/panel/systemLogs'
import ensureProcess from 'modules/processes/manager/ensureProcess'

import * as actions from './actions'
import {snackShow} from 'modules/snacks'
import {updateRefreshProcess} from '../activity'

export default function* () {
    yield all([takeEvery([actions.fetch], watchFetch)])
    yield all([takeEvery(actions.remove, watchRemove)])
}

function* watchFetch({payload: {panelId}}) {
    try {
        const {rows, refreshProcess} = yield call(api.fetch, panelId)

        yield put(actions.receive(rows, panelId))

        yield put(updateRefreshProcess(panelId, yield ensureProcess(refreshProcess)))
    } catch (error) {
        yield put(actions.receive(error, panelId))
    }
}

function* watchRemove({payload: {panelId, id}}) {
    try {
        yield call(api.remove, panelId, id)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
