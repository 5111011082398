import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import __ from 'utils/i18n'
import {humanDate} from 'ipmp-react-ui/humanTime'
import {TableGrouped} from 'ipmp-react-ui/Table'

import RecentHistoryItem from 'components/RecentHistory/RecentHistoryItem'
import RecentHistoryPanelList from './RecentHistory/RecentHistoryPanelList'

import {selectRecentHistory} from 'modules/panels/recent/selectors'

const RecentHistory = ({recentHistory, hide}) => {
    const onClose = () => {
        hide()
    }
    const separatorCallback = (data) => {
        const visitDate = data?.props.visitTime && new Date(data.props.visitTime)
        return visitDate && humanDate(visitDate)
    }

    const columns = [
        {
            render: (props) => props,
        },
    ]

    const rows = recentHistory
        .map(({interactiveUser, panel, path}, index) => {
            const interactiveUserId = get(interactiveUser, 'interactiveUserId', null)
            const {lastVisit} = panel
            if (lastVisit) {
                return (
                    <RecentHistoryItem
                        key={'' + index + panel.id}
                        interactiveUserEmail={
                            get(interactiveUser, 'email', __('Unassigned')) ||
                            __('Unassigned')
                        }
                        panelSerial={panel.serial}
                        account={panel.account}
                        visitTime={lastVisit}
                        interactiveUserId={Number(interactiveUserId)}
                        path={path}
                        onClose={onClose}
                    >
                        <RecentHistoryPanelList
                            onClose={onClose}
                            interactiveUserId={Number(interactiveUserId)}
                            panel={panel}
                        />
                    </RecentHistoryItem>
                )
            }
            return null
        })
        .filter((row) => row !== null)
    return (
        <TableGrouped
            separatorCallback={separatorCallback}
            rows={rows}
            columns={columns}
        />
    )
}

RecentHistory.propTypes = {
    recentHistory: PropTypes.array.isRequired,
}

export default compose(connect(selectRecentHistory))(RecentHistory)
