import React from 'react'
import PropTypes from 'prop-types'

import {hadTitle} from 'constants/had'

const HadDeviceHint = ({id, location, type}) => {
    return (
        <div className="hint device-hint">
            <div className="device-zone">{id}</div>
            {location?.name && (
                <div className="device-hint-content device-hint-content--primary">
                    {location?.name}
                </div>
            )}
            <div className="device-hint-content">{hadTitle(type)}</div>
        </div>
    )
}

HadDeviceHint.propTypes = {
    id: PropTypes.number.isRequired,
    location: PropTypes.shape({name: PropTypes.string}),
    type: PropTypes.number,
}

export default HadDeviceHint
