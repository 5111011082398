import {put, call} from 'redux-saga/effects'
import {getPgmActivationTimeSlot} from 'api/system/settings'

import * as actions from 'modules/system/settings/actions'
import {snackShow} from 'modules/snacks'

export default function* watchPgmActivationTimeSlot() {
    try {
        const pgmActivationTimeSlot = yield call(getPgmActivationTimeSlot)

        yield put(actions.updatePgmActivationTimeSlot(pgmActivationTimeSlot))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
