import React from 'react'

import {InitiateButtonBase} from 'pages/RemoteInspection/Content/Result/Footer/InitiateButton'
import __ from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'
import usePanelRemoteInspection from 'hooks/pages/usePanelRemoteInspection'

const ButtonConfirmation = withConfirmation()(InitiateButtonBase)

export default function InitiateButton() {
    const {initiateNow, remoteInspection} = usePanelRemoteInspection()

    return (
        <ButtonConfirmation
            disabled={remoteInspection && remoteInspection.progress !== null}
            onClick={initiateNow}
            label={
                remoteInspection &&
                remoteInspection.result === 'progress' &&
                remoteInspection.progress === 0
                    ? __('Pending...')
                    : __('Run')
            }
            title={__('Initiate Inspection')}
            message={__('Do you want to initiate remote inspection now?')}
            positive={__('Begin now')}
            primary
        />
    )
}
