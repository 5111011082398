import {whoami, logout} from 'api/auth'
import * as commands from 'modules/auth/commands'
import {err} from 'utils/log'
import isDevMode from 'utils/isDevMode'
import {sendSWMessage} from 'utils/serviceWorker/serviceWorkerUtils'
import {TRUE} from 'constants/boolean'

export default class AuthService {
    authData
    poller
    autologout

    constructor({poller, autologout}) {
        this.poller = poller
        this.autologout = autologout
    }

    doLogout = () => {
        this.authData = null
        logout()
        this.poller.stop()
        this.autologout.stop()
        localStorage.removeItem('isLoggedIn')
    }

    doLogin = (data) => {
        this.authData = data
        this.poller.start()
        this.autologout.continue()
        localStorage.setItem('isLoggedIn', TRUE)
    }

    retrieve = (time) => {
        const options = time ? {headers: {'If-Modified-Since': time.toUTCString()}} : null

        return whoami(options)
    }

    sendStatus = () => {
        if (this.authData) {
            sendSWMessage({
                command: commands.COMMAND_LOGGED_IN,
                data: this.authData,
            })
        } else {
            sendSWMessage({
                command: commands.COMMAND_NO_AUTH,
            })
        }
    }

    async check() {
        try {
            const data = await this.retrieve()
            this.authData = null
            data && this.doLogin(data)
        } catch (error) {
            err(error)
        }

        this.sendStatus()

        return this.authData
    }

    receiveMessage = (event) => {
        if (!event.data || !event.data.command) {
            err('Unknown message', event.data)
            return
        }

        const {data, command} = event.data

        if (command === commands.COMMAND_CHECK) {
            this.check()
            if (isDevMode()) {
                console.log('Check')
            }
            return
        }

        if (command === commands.COMMAND_INTERACT) {
            this.authData && this.autologout.continue()
            if (isDevMode()) {
                console.log('Interact')
            }
            return
        }

        switch (command) {
            case commands.COMMAND_LOGGED_IN:
                this.doLogin(data)
                break

            case commands.COMMAND_LOGGED_OUT:
                this.doLogout()
                break

            case commands.COMMAND_UPDATED:
                this.authData = {
                    ...this.authData,
                    ...data,
                }
                this.autologout.continue()
                break
        }

        if (isDevMode()) {
            console.log('Broadcast', command, data)
        }
    }
}
