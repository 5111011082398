import React from 'react'
import {useSelector} from 'react-redux'

import Page from 'ipmp-react-ui/Page'

import CentralStationTop from 'pages/CentralStation/CentralStationTop'
import CentralStationContent from 'pages/CentralStation/CentralStationContent'
import withLoader from 'containers/withLoader'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch as fetchCentralStation} from 'modules/centralStations/one/actions'
import {oneCentralStationSelect} from 'modules/centralStations/one/selectors'
import {selectProtocols} from 'modules/centralStations/protocols/selectors'
import {compose} from 'redux'
import {withRejection} from 'containers/withPermission'
import usePermission from 'hooks/usePermission'
import one from 'permissions/centralStations/one'

const PageWithHOCs = compose(
    withRejection(),
    withLoader(({fetch}) => fetch())
)(Page)

const CentralStationPage = (props) => {
    const {fetchCentralStation: fetch} = useActions({fetchCentralStation})
    const {isPermitted} = usePermission(one)
    const {error: centralStationError, isLoading: centralStationIsLoading} = useSelector(
        oneCentralStationSelect
    )
    const {error: protocolError, isLoading: protocolIsLoading} =
        useSelector(selectProtocols)

    const error = centralStationError || protocolError
    const isLoading = centralStationIsLoading || protocolIsLoading

    return (
        <PageWithHOCs
            isAllowed={isPermitted}
            fetch={() => fetch(props?.match?.params?.id)}
            error={error}
            isLoading={isLoading}
            Content={CentralStationContent}
            Top={CentralStationTop}
            {...props}
        />
    )
}

export default CentralStationPage
