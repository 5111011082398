import React from 'react'
import {compose} from 'redux'

import {enroll} from 'permissions/panel/devices/actions'

import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import Void from 'components/Void'
import {selectAddDeviceFeature} from 'modules/features/store/selectors'
import {withFeatureRejection} from 'containers/withFeature'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'

const ButtonVisibleWithFeature = compose(
    withVisibility(),
    withFeatureRejection(selectAddDeviceFeature, Void)
)(Button)

export default function AddDeviceButton() {
    const {isPermitted} = usePermission(enroll)
    const devicesProps = useDevices()

    return (
        <ButtonVisibleWithFeature
            {...devicesProps}
            isVisible={isPermitted}
            Icon={PlusIcon}
            label={__('Add device')}
            onClick={devicesProps.showAddDevice}
        />
    )
}
