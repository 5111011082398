import {warn} from 'utils/log'

export const DSC_NETWORK_PROTOCOL_TCP = 'NP_TCP'
export const DSC_NETWORK_PROTOCOL_UDP = 'NP_UDP'

export const DSC_NETWORK_PROTOCOL_TCP_LABEL = 'TCP'
export const DSC_NETWORK_PROTOCOL_UDP_LABEL = 'UDP'

export const DSC_APPLICATION_PROTOCOL_ITV2 = 'AP_ITV2'
export const DSC_APPLICATION_PROTOCOL_DLS = 'AP_DLS'

export const DSC_APPLICATION_PROTOCOL_ITV2_LABEL = 'ITV2'
export const DSC_APPLICATION_PROTOCOL_DLS_LABEL = 'DLS'

export const dlsNetworkProtocols = [DSC_NETWORK_PROTOCOL_TCP, DSC_NETWORK_PROTOCOL_UDP]

export const dlsApplicationProtocols = [
    DSC_APPLICATION_PROTOCOL_ITV2,
    DSC_APPLICATION_PROTOCOL_DLS,
]

export const getDlsNetworkProtocolLabel = (protocol) => {
    switch (protocol) {
        case DSC_NETWORK_PROTOCOL_TCP:
            return DSC_NETWORK_PROTOCOL_TCP_LABEL
        case DSC_NETWORK_PROTOCOL_UDP:
            return DSC_NETWORK_PROTOCOL_UDP_LABEL
    }

    warn(`Unknown protocol ${protocol}`)

    return null
}

export const getDlsApplicationProtocolLabel = (protocol) => {
    switch (protocol) {
        case DSC_APPLICATION_PROTOCOL_ITV2:
            return DSC_APPLICATION_PROTOCOL_ITV2_LABEL
        case DSC_APPLICATION_PROTOCOL_DLS:
            return DSC_APPLICATION_PROTOCOL_DLS_LABEL
    }

    warn(`Unknown protocol ${protocol}`)

    return null
}
