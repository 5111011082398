import usePerPage from '../usePerPage'
import {useSelector} from 'react-redux'
import {selectCentralStationsList} from 'modules/centralStations/store/selectors'
import {init, remove, reset, setStart} from 'modules/centralStations/list/actions'
import {showCentralStationCreateModal} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'

export default function useCentralStations() {
    const perPage = usePerPage('centralStations')

    return {
        ...perPage,
        ...useSelector(selectCentralStationsList),
        ...useActions({
            init,
            reset,
            remove,
            onPageChange: setStart,
            showCentralStationCreateModal,
        }),
    }
}
