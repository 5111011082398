import React from 'react'

import withLoader from 'containers/withLoader'

import Table from 'ipmp-react-ui/Table'
import {__} from 'utils/i18n'

import RemoveEventRulesButton from './TableCell/RemoveEventRulesButton'
import EditEventRuleCell from './TableCell/EditEventRuleCell'
import ZoneTypeCell from './TableCell/ZoneTypeCell'
import NotificationTypeCell from './TableCell/NotificationTypeCell'
import StateTypeCell from './TableCell/StateTypeCell'
import EventTypeCell from './TableCell/EventTypeCell'
import useEventRules from 'hooks/pages/useEventRules'

const columns = [
    {
        name: () => __('Zone Type'),
        width: 218,
        fixed: true,
        render: () => __('When IoT Device with zone type'),
    },
    {
        width: 160,
        fixed: true,
        component: ZoneTypeCell,
    },
    {
        name: () => __('Notification'),
        width: 126,
        fixed: true,
        render: () => __('sends notification'),
    },
    {
        width: 160,
        fixed: true,
        component: NotificationTypeCell,
    },
    {
        name: () => __('State'),
        fixed: true,
        width: 108,
        render: () => __('and VP in state'),
    },
    {
        width: 160,
        fixed: true,
        component: StateTypeCell,
    },
    {
        name: () => __('Event'),
        width: 114,
        fixed: true,
        render: () => __('then send event'),
    },
    {
        width: 160,
        fixed: true,
        component: EventTypeCell,
    },
    {
        tooltip: false,
        width: 40,
        fixed: true,
        component: EditEventRuleCell,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveEventRulesButton,
    },
]

const ConnectedTable = withLoader(
    ({fetch, init, startPoll}) => {
        init()
        fetch()
        startPoll()
    },
    ({stopPoll}) => stopPoll()
)(Table)

export default function EventRulesTable() {
    const eventRulesProps = useEventRules()

    return (
        <ConnectedTable
            {...eventRulesProps}
            columns={columns}
            fullHeight
            emptyMessage={() => __('No event rules found')}
            className="eventRule-table"
        />
    )
}
