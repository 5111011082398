import React from 'react'
import {compose} from 'redux'

import {remove} from 'permissions/roles/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withSelection from 'containers/withSelection'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import usePermission from 'hooks/usePermission'
import useRoles from 'hooks/pages/useRoles'

const ButtonSelection = compose(withSelection(), withSelectionHandler())(Button)

export default function RemoveRolesButton() {
    const {showRemoveRole} = useRoles()
    const {isPermitted} = usePermission(remove)

    return (
        <ButtonSelection
            isVisible={isPermitted}
            Icon={IconRemove}
            onClick={showRemoveRole}
            title={__('Remove roles')}
            message={__('')}
            positive={__('Remove')}
            label={__('Remove')}
        />
    )
}
