import {createSelector} from 'reselect'
import last from 'lodash-es/last'
import get from 'lodash-es/get'

export const selectRecentPanel = createSelector(
    (state) => last(state.panels.recent.historyItems),
    (recentPanel) => recentPanel
)

export const selectRecentHistory = createSelector(
    (state) => state.panels.recent,
    ({historyItems, isLoading, error}) => {
        const newRecent = [...historyItems]
        return {recentHistory: newRecent.reverse(), isLoading, error}
    }
)

export const selectRecentInteractiveUserPanels = createSelector(
    (state, {interactiveUserId}) =>
        get(
            state,
            ['panels', 'recent', 'interactiveUserPanelsByIds', interactiveUserId],
            {}
        ),
    (recentInteractiveUserPanels) => {
        const {error, isLoading, panelIds, panels} = recentInteractiveUserPanels
        return {error, isLoading, panelIds, panels}
    }
)
