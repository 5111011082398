import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {create as createPanelUser} from 'permissions/panel/customers/actions'
import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import {showSetUserPinModal} from 'modules/modals/actions'
import {selectUserEditableFeature} from 'modules/features/store/selectors'

import {USER_FEATURE_EDITABLE_CODE} from 'constants/features'

export default compose(
    withPermission({
        isVisible: createPanelUser,
    }),
    withVisibility(),
    connect(
        (state, {panelId, panelUserId}) => {
            const isAllowedSetCode = selectUserEditableFeature(state, {
                panelId,
                item: USER_FEATURE_EDITABLE_CODE,
            })

            return {isAllowedSetCode}
        },
        (dispatch, {panelId, panelUserId}) =>
            bindActionCreators(
                {
                    showSetUserPinModal: () =>
                        showSetUserPinModal({panelId, panelUserId}),
                },
                dispatch
            )
    ),
    withProps(({showSetUserPinModal, isAllowedSetCode}) => ({
        Icon: IconEdit,
        flat: true,
        small: true,
        onClick: showSetUserPinModal,
        disabled: !isAllowedSetCode,
    }))
)(Button)
