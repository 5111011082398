import React from 'react'
import {useEffect} from 'react'

import Table from 'ipmp-react-ui/Table'

import withLoader from 'containers/withLoader'
import {__} from 'utils/i18n'
import RemoveEventType from './TableCell/RemoveEventType'
import EditEventType from './TableCell/EditEventType'
import useEventTypes from 'hooks/pages/useEventTypes'

const allColumns = [
    {
        name: () => __('Event Type'),
        render: ({description}) => description,
    },
    {
        tooltip: false,
        width: 40,
        fixed: true,
        component: EditEventType,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveEventType,
    },
]

const ConnectedTable = withLoader()(Table)

export default function EventTypesTable() {
    const eventTypes = useEventTypes()

    useEffect(() => {
        eventTypes.init()
        eventTypes.startPoll()

        return eventTypes.stopPoll
    }, [])

    return (
        <ConnectedTable
            {...eventTypes}
            columns={allColumns}
            fullHeight
            emptyMessage={() => __('No event types found')}
        />
    )
}
