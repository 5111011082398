import React from 'react'
import {MenuItem} from 'ipmp-react-ui/Menu'
import {showEventInitiate} from 'modules/modals/actions'
import {alarmTitle, icons} from 'constants/alarmType'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

export default function EventItem({eventType}) {
    const panelId = useRouterPanelId()
    const {onInit} = useActions({onInit: () => showEventInitiate(panelId, eventType)})
    return (
        <MenuItem
            onClick={onInit}
            small={true}
            flat={true}
            Icon={icons[eventType]}
            children={alarmTitle(eventType)}
        />
    )
}
