import React, {useState} from 'react'

import Button from 'ipmp-react-ui/Button'
import Layout, {SideBar} from 'ipmp-react-ui/Layout'

import usePanelLogs from 'hooks/panel/logs/usePanelLogs'
import {__} from 'utils/i18n'

import Log from './Log'

const STANDARD_MODE = 'standard'
const LEGACY_MODE = 'legacy'

export default function Logs({panel}) {
    const [mode, setMode] = useState(STANDARD_MODE)
    const panelLogsProps = usePanelLogs(panel.id, mode)

    const handleShowStandard = () => setMode(STANDARD_MODE)

    const handleShowLegacy = () => setMode(LEGACY_MODE)

    if (panel.isNeo) {
        return <Log {...panelLogsProps} key={mode} mode={mode} panel={panel} />
    }

    return (
        <Layout>
            <Log {...panelLogsProps} key={mode} mode={mode} panel={panel} />

            <SideBar className="sidebar--bookmark">
                <Button
                    toggler
                    onClick={handleShowStandard}
                    active={mode === STANDARD_MODE}
                >
                    {__('Standard Log')}
                </Button>

                <Button toggler onClick={handleShowLegacy} active={mode === LEGACY_MODE}>
                    {__('Legacy Log')}
                </Button>
            </SideBar>
        </Layout>
    )
}
