import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {unassignInstaller} from 'modules/installerPanels/list/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {unassign} from 'permissions/installers/actions'
import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: unassign}),
    withVisibility(),
    connect(null, (dispatcher, {row}) =>
        bindActionCreators(
            {
                onUnassign: () => unassignInstaller(row.id),
            },
            dispatcher
        )
    ),
    withProps(({onUnassign, row: {email}}) => ({
        onClick: onUnassign,
        small: true,
        flat: true,
        Icon: IconMinus,
        message: __('Do you really want to unassign installer with email %s?', email),
        title: __('Unassign Installer'),
    })),
    withConfirmation()
)(Button)
