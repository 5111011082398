import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import InteractiveBar from 'ipmp-react-ui/InteractiveBar'

import {__, __n} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'

class MakeBasicConfigurationInteractiveBarBase extends Component {
    static propTypes = {
        pendingExport: PropTypes.number,
        commitBasic: PropTypes.func,
        resetChanges: PropTypes.func,
    }

    render() {
        const {makeBasic, pendingExport, commitBasic, resetChanges} = this.props

        return (
            <InteractiveBar active={makeBasic || false}>
                {__n(
                    '1 configuration element selected for creating new basic configuration',
                    '%d configuration elements selected for creating new basic configuration',
                    pendingExport
                )}
                <Button small onClick={commitBasic} disabled={!pendingExport}>
                    {__('Create')}
                </Button>
                <Button small borderless onClick={resetChanges}>
                    {__('Dismiss')}
                </Button>
            </InteractiveBar>
        )
    }
}

const MakeBasicConfigurationInteractiveBarVisibility = withVisibility()(
    MakeBasicConfigurationInteractiveBarBase
)

export default function MakeBasicConfigurationInteractiveBar(props) {
    const {isPermitted} = usePermission(makeBasic)
    const panel = usePanel(props)
    const barProps = {...props, ...panel}

    return (
        <MakeBasicConfigurationInteractiveBarVisibility
            {...barProps}
            isVisible={isPermitted}
        />
    )
}
