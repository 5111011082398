import React, {Component} from 'react'

import getHocName from 'utils/getHocName'
import Spinner from 'ipmp-react-ui/Spinner'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import Modal from 'ipmp-react-ui/Modal'
import {__} from 'utils/i18n'

export default function withModalLoader(
    onMount: ?(Object) => any,
    onUnmount: ?(Object) => any
) {
    return (WrappedComponent) =>
        class extends Component {
            static displayName = getHocName('WithModalLoader', WrappedComponent)

            componentDidMount() {
                onMount && onMount(this.props)
            }

            componentWillUnmount() {
                onUnmount && onUnmount(this.props)
            }

            handleClick = () => {
                onMount && onMount(this.props)
            }

            render() {
                const {isLoading, error, ...props} = this.props

                if (isLoading) {
                    return (
                        <Modal onClose={() => {}}>
                            <Spinner dataTestId="modal-loading-spinner" />
                        </Modal>
                    )
                }

                if (error) {
                    return (
                        <Error error={error}>
                            {onMount && (
                                <Button onClick={this.handleClick}>{__('Retry')}</Button>
                            )}
                        </Error>
                    )
                }

                return <WrappedComponent {...props} />
            }
        }
}
