import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'

import path from 'utils/path'
import __ from 'utils/i18n'

import NavLink from './NavLink'
import NavLinkScopesGroup from './NavLinkScopesGroup'
import NewEventsCounter from 'components/Nav/NewEventsCounter'
import {NavLinkGroup} from './NavLinkGroup'
import useWindowDimensions from 'hooks/useWindowDimensions'

import {ReactComponent as IconPanels} from 'ipmp-react-ui/icons/menu/panels.svg'
import {ReactComponent as IconDashboard} from 'ipmp-react-ui/icons/menu/dashboard.svg'
import {ReactComponent as IconInspection} from 'ipmp-react-ui/icons/menu/remote-inspection.svg'
import {ReactComponent as IconEvents} from 'ipmp-react-ui/icons/menu/events.svg'
import {ReactComponent as IconReports} from 'ipmp-react-ui/icons/menu/reports.svg'
import {ReactComponent as IconAccounts} from 'ipmp-react-ui/icons/menu/accounts.svg'
import {ReactComponent as IconMyJobs} from 'ipmp-react-ui/icons/menu/my-jobs.svg'
import {ReactComponent as IconGroups} from 'ipmp-react-ui/icons/menu/groups.svg'
import {ReactComponent as IconProcesses} from 'ipmp-react-ui/icons/menu/processes.svg'
import {ReactComponent as IconRoles} from 'ipmp-react-ui/icons/menu/roles.svg'
import {ReactComponent as IconUsers} from 'ipmp-react-ui/icons/menu/users.svg'
import {ReactComponent as IconActionLog} from 'ipmp-react-ui/icons/menu/action-log.svg'
import {ReactComponent as IconCentralStations} from 'ipmp-react-ui/icons/menu/central-stations.svg'
import {ReactComponent as IconBasicConfiguration} from 'ipmp-react-ui/icons/menu/basic-configuration.svg'
import {ReactComponent as IconEventRules} from 'ipmp-react-ui/icons/menu/event-rules.svg'
import {ReactComponent as IconMore} from 'ipmp-react-ui/icons/menu/more.svg'
import {ReactComponent as IconFirmware} from 'ipmp-react-ui/icons/menu/firmware.svg'
import {ReactComponent as IconFirmwareUpgrade} from 'ipmp-react-ui/icons/menu/upgrade-status.svg'

function NavItemsResizable({
    permissions,
    handleSearchScopeRemove,
    isVirtualPanelEnabled,
    scopes,
}) {
    const {height} = useWindowDimensions()
    const location = useLocation()

    const items = [
        <NavLink
            to={path('dashboard', {})}
            Icon={IconDashboard}
            name={__('Dashboard')}
            isPermitted={permissions.dashboard}
            key="dashboard"
            order={1}
        />,
        <NavLink
            to={path('accounts', {})}
            Icon={IconAccounts}
            name={__('Accounts')}
            isPermitted={permissions.accounts}
            key="accounts"
            order={2}
        />,
        <NavLinkScopesGroup
            Icon={IconPanels}
            link={'panels'}
            to={path('panels', {})}
            name={__('Equipment')}
            isPermitted={permissions.panels}
            onRemove={handleSearchScopeRemove}
            scopes={scopes.panels}
            key="panels"
            order={3}
        />,
        <NavLink
            to={path('batches', {})}
            Icon={IconMyJobs}
            name={__('My Jobs')}
            isPermitted
            key="batches"
            order={4}
        />,
        <NavLinkScopesGroup
            Icon={IconEvents}
            link={'events'}
            to={path('events', {})}
            name={__('Events')}
            isPermitted={permissions.events}
            onRemove={handleSearchScopeRemove}
            Coin={NewEventsCounter}
            scopes={scopes.events}
            key="events"
            order={5}
        />,
        <NavLinkScopesGroup
            Icon={IconInspection}
            link={'remoteInspections'}
            to={path('remoteInspections', {})}
            name={__('Remote Inspection')}
            isPermitted={permissions.remoteInspections}
            onRemove={handleSearchScopeRemove}
            scopes={scopes.remoteInspections}
            key="remoteInspections"
            order={6}
        />,
        <NavLinkScopesGroup
            Icon={IconReports}
            link={'reports'}
            to={path('reports', {})}
            name={__('Reports')}
            isPermitted={permissions.reports}
            onRemove={handleSearchScopeRemove}
            scopes={scopes.reports}
            key="reports"
            order={7}
        />,
        <NavLink
            Icon={IconFirmware}
            to={path('firmware', {})}
            name={__('Firmware')}
            isPermitted={permissions.firmware}
            key="firmware"
            order={8}
        />,
        <NavLink
            Icon={IconFirmwareUpgrade}
            to={path('firmwareUpgradeStatus', {})}
            name={__('Upgrade Status')}
            isPermitted={permissions.firmwareUpgradeStatus}
            key="firmwareUpgradeStatus"
            order={9}
        />,
        <NavLink
            Icon={IconGroups}
            to={path('system.groups', {})}
            name={__('Groups')}
            isPermitted={permissions.groups}
            key="systemGroups"
            order={10}
        />,
        <NavLink
            Icon={IconUsers}
            to={path('system.users', {})}
            name={__('Users')}
            isPermitted={permissions.users}
            key="systemUsers"
            order={11}
        />,
        <NavLink
            Icon={IconRoles}
            to={path('system.roles', {})}
            name={__('Roles')}
            isPermitted={permissions.roles}
            key="systemRoles"
            order={12}
        />,
        <NavLink
            Icon={IconBasicConfiguration}
            to={path('system.basicConfigurations', {})}
            name={__('Basic Configurations')}
            isPermitted={permissions.basicConfiguration}
            key="systemBasicConfigurations"
            order={13}
        />,
        <NavLink
            Icon={IconCentralStations}
            to={path('system.centralStations', {})}
            name={__('Central Stations')}
            isPermitted={permissions.centralStations}
            key="systemCentralStations"
            order={14}
        />,
        <NavLink
            Icon={IconProcesses}
            to={path('system.processes', {})}
            name={__('Processes')}
            isPermitted={permissions.processes}
            key="systemProcesses"
            order={15}
        />,
        <NavLink
            Icon={IconActionLog}
            to={path('system.actionLog', {})}
            name={__('Action Log')}
            isPermitted={permissions.actionLog}
            key="systemActionLog"
            order={16}
        />,
        <NavLink
            Icon={IconEventRules}
            to={path('system.eventRules', {})}
            name={__('Event Rules')}
            isPermitted={isVirtualPanelEnabled}
            key="systemEventRules"
            order={17}
        />,
    ].filter((link) => link.props.isPermitted)

    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
        setMenuItems(items)
    }, [scopes, permissions, height])

    const maxShownItems = Math.trunc(height / 96) - 1
    let hiddenMenuItems = menuItems
        .slice(maxShownItems, menuItems.length)
        .sort((a, b) => a.props.order - b.props.order)
    const MoreMenu = (
        <NavLinkGroup
            Icon={IconMore}
            path={'#'}
            name={__('More')}
            isPermitted={true}
            key="More"
        >
            {hiddenMenuItems}
        </NavLinkGroup>
    )
    let shownMenuItems = menuItems.slice(0, maxShownItems)

    const currentLink = menuItems.find((item) => {
        return item.props.to === location.pathname
    })

    const currentLinkIndex = hiddenMenuItems.indexOf(currentLink)

    if (currentLinkIndex !== -1) {
        hiddenMenuItems[currentLinkIndex] = shownMenuItems[shownMenuItems.length - 1]
        shownMenuItems[shownMenuItems.length - 1] = currentLink
        setMenuItems(shownMenuItems.concat(hiddenMenuItems))
    }

    if (hiddenMenuItems.length > 0) {
        shownMenuItems.push(MoreMenu)
    }

    return shownMenuItems
}

export default NavItemsResizable
