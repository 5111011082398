import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {hadConfigurableIcon, hadConfigurableIconTitle} from 'constants/had'

function EditIconListItem({icon, isActive, onClick}) {
    return (
        <div
            className={classnames('modalFormEditHadIcon-list-item', {
                'modalFormEditHadIcon-list-item--active': isActive,
            })}
            onClick={() => onClick(icon)}
        >
            <div className="modalFormEditHadIcon-list-item-iconContainer">
                {hadConfigurableIcon(icon)}
            </div>

            <div className="modalFormEditHadIcon-list-item-iconTitle">
                {hadConfigurableIconTitle(icon)}
            </div>
        </div>
    )
}

EditIconListItem.propTypes = {
    icon: PropTypes.string,
    iaActive: PropTypes.bool,
    onClick: PropTypes.func,
}

export default EditIconListItem
