import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {CardActions, CardContent, CardHeader, CardClose} from 'ipmp-react-ui/Card'
import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Modal from 'ipmp-react-ui/Modal'
import Portal from 'ipmp-react-ui/Portal'
import CardMessage from 'ipmp-react-ui/CardMessage'

import {__} from 'utils/i18n'
import stopPropagation from 'utils/stopPropagation'

const commonPropTypes = {
    wide: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([2, 3, 4])]),
    className: PropTypes.string,
    header: PropTypes.node,
    isLoading: PropTypes.bool,
    isSubmitDisable: PropTypes.bool,
    editable: PropTypes.bool,
    showDismiss: PropTypes.bool,
    submitLabel: PropTypes.string,
    dissmissLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    confirmOnDismiss: PropTypes.bool,
    confirmOnSubmit: PropTypes.bool,
    hide: PropTypes.func,
    onRef: PropTypes.func,
}

export default class ModalCardForm extends Component {
    static propTypes = {
        ...commonPropTypes,
    }

    static defaultProps = {
        editable: true,
        showDismiss: true,
        confirmOnDismiss: false,
    }

    state = {
        changed: false,
        showDismissConfirmation: false,
        showSubmitConfirmation: false,
        submiteData: null,
    }

    dismiss = (e) => {
        e && e.preventDefault()

        if (this.isNeedDismissConfirmation()) {
            this.setState({showDismissConfirmation: true})
        } else {
            this.props.hide && this.props.hide(e)
        }
    }

    onChange = (...args) => {
        this.setState({
            changed: true,
        })

        this.props.onChange && this.props.onChange(...args)
    }

    isNeedDismissConfirmation() {
        const {confirmOnDismiss} = this.props
        return confirmOnDismiss && this.state.changed
    }

    isNeedSubmitConfirmation() {
        const {confirmOnSubmit} = this.props
        return confirmOnSubmit
    }

    agreeDismiss = () => this.props.hide && this.props.hide()

    cancelDismiss = () => {
        this.setState({showDismissConfirmation: false})
    }

    submit = (data) => {
        if (this.isNeedSubmitConfirmation()) {
            this.setState({
                showSubmitConfirmation: true,
                submiteData: data,
            })
        } else {
            this.props.onSubmit && this.props.onSubmit(data)
        }
    }

    agreeSubmit = () => {
        this.setState({showSubmitConfirmation: false})
        this.props.onSubmit && this.props.onSubmit(this.state.submiteData)
    }

    cancelSubmit = () => {
        this.setState({showSubmitConfirmation: false})
    }

    renderCardActions() {
        const {
            hide,
            submitLabel,
            dissmissLabel,
            showDismiss,
            editable,
            isLoading,
            isSubmitDisable,
        } = this.props

        return (
            <CardActions>
                {hide && showDismiss && (
                    <Button borderless shortcut="esc" onClick={this.dismiss}>
                        {dissmissLabel || __('Dismiss')}
                    </Button>
                )}

                {editable && (
                    <Button primary disabled={isLoading || isSubmitDisable} type="submit">
                        {submitLabel || __('Save')}
                    </Button>
                )}
            </CardActions>
        )
    }

    render() {
        const {header, hide, onRef, className, children, wide, ...props} = this.props

        const {showDismissConfirmation, showSubmitConfirmation} = this.state

        delete props.confirmOnDismiss

        return (
            <Modal onClose={this.dismiss}>
                <Form
                    {...props}
                    ref={onRef}
                    onSubmit={this.submit}
                    onChange={this.onChange}
                    className={classes('card', className, {
                        'card--wide': wide === true,
                        'card--wide-2': wide === 2,
                        'card--wide-3': wide === 3,
                        'card--wide-4': wide === 4,
                    })}
                >
                    {hide && <CardClose onClick={this.dismiss} />}
                    {header && <CardHeader>{header}</CardHeader>}

                    <CardContent>{children}</CardContent>

                    {this.renderCardActions()}
                </Form>

                {showDismissConfirmation && (
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={this.dismiss}>
                            <CardMessage
                                className="card--narrow"
                                title={__('Closing form')}
                                message={__(
                                    "All data will be lost. Do you wan't to proceed?"
                                )}
                                positive={__('Confirm')}
                                negative={__('Cancel')}
                                onPositive={this.agreeDismiss}
                                onNegative={this.cancelDismiss}
                                onClose={this.cancelDismiss}
                            />
                        </Modal>
                    </Portal>
                )}
                {showSubmitConfirmation && (
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={this.dismiss}>
                            <CardMessage
                                className="card--narrow"
                                title={__('Submit form')}
                                message={__(
                                    'All data will be submitted. Do you want to proceed?'
                                )}
                                positive={__('Confirm')}
                                negative={__('Cancel')}
                                onPositive={this.agreeSubmit}
                                onNegative={this.cancelSubmit}
                                onClose={this.cancelSubmit}
                            />
                        </Modal>
                    </Portal>
                )}
            </Modal>
        )
    }
}

export class ModalCardFormWithCustomActions extends ModalCardForm {
    static propTypes = {
        ...commonPropTypes,
        CustomActions: PropTypes.func,
    }
    renderCardActions() {
        const {CustomActions, editable, isLoading} = this.props
        return (
            <CustomActions
                onDismiss={this.dismiss}
                isLoading={isLoading}
                editable={editable}
            />
        )
    }
}
