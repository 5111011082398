import {connect} from 'react-redux'
import {compose} from 'redux'

import {selectDeviceByZoneAndType} from 'modules/devices/list/selectors'

import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withPermission} from 'containers/withPermission'
import withFeature from 'containers/withFeature'

import {exports, request} from 'permissions/panel/devices/video/actions'
import {videoOnDemand} from 'permissions/events/actions'

import VideoTabsComponent from 'components/Sidebar/VideoTabs'
import {POLL_EVENTS} from 'configs/pollers'

export default compose(
    withPermission({
        hasLive: videoOnDemand,
        isRequestAllowed: request,
        isExportAllowed: exports,
    }),
    connect((state, {event: {zone, deviceType, panelId}, hasLive}) => {
        const device = selectDeviceByZoneAndType(state, {panelId, deviceType, zone})

        return {
            device: device,
            hasLive: hasLive,
        }
    }),
    withFeature(),
    withLoader(({fetch, fetchFeaturesWithoutLoader, panelId}) => {
        fetch()
        fetchFeaturesWithoutLoader(panelId)
    }),
    withPoller(POLL_EVENTS, ({fetch}) => fetch())
)(VideoTabsComponent)
