import {createSelector} from 'reselect'

export const selectInteractiveUserAccessiblePanelsList = createSelector(
    (state) => state.interactiveUserPanels.accessiblePanels.list,
    (state) => state.interactiveUserPanels.accessiblePanels.store.byIds,
    ({page, error, isLoading}, store) => {
        const rows = page.map((id) => store[id] || {})

        return {
            rows,
            isLoading,
            error,
        }
    }
)

export const selectInteractiveUserAccessiblePanelsPager = createSelector(
    (state) => state.interactiveUserPanels.accessiblePanels.list,
    ({start, total}) => ({start, total})
)
