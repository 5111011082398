import React, {useState} from 'react'
import {compose} from 'redux'

import withForm from 'containers/withForm'
import ModalCardForm from 'ui/ModalCardForm'

import __ from 'utils/i18n'
import {setSystemSettings} from 'modules/forms/handlers'
import {connect} from 'react-redux'
import {selectPgmActivationTimeSlot} from 'modules/system/settings/selectors'
import Input from 'ipmp-react-ui/Input'
import ToggleBox from 'ipmp-react-ui/ToggleBox'

const DEFAULT_TIME_WINDOW_VALUE = 300

const rules = {
    time_window: {
        presence: (value, attributes) => attributes.enabled,
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 600,
            greaterThanOrEqualTo: 60,
        },
    },
}

const toggleLabel = (status) => (
    <span className="pgmActivationTimeSlot-form-enable-toggle">
        {__('Activation')}
        <em>({status})</em>
    </span>
)

function PgmActivationTimeSlot({handle, timeWindow, enabled, ...props}) {
    const [isLocalEnabled, setIsLocalEnabled] = useState(enabled)

    return (
        <ModalCardForm
            className="pgmActivationTimeSlot-form"
            onSubmit={(data) => handle(data)}
            rules={rules}
            header={__('PGM Activation settings')}
            confirmOnDismiss
            {...props}
        >
            <ToggleBox
                name="enabled"
                label={toggleLabel(__('Enabled'))}
                labelOff={toggleLabel(__('Disabled'))}
                onChange={(e) => setIsLocalEnabled(e.target.checked)}
                defaultChecked={enabled}
            />
            {isLocalEnabled && (
                <Input
                    defaultValue={timeWindow || DEFAULT_TIME_WINDOW_VALUE}
                    label={__('Time Window after Alarm [SEC]')}
                    name="time_window"
                />
            )}
        </ModalCardForm>
    )
}

export default compose(
    connect(selectPgmActivationTimeSlot),
    withForm(setSystemSettings.PgmActivationTimeSlot)
)(PgmActivationTimeSlot)
