import {bindActionCreators} from 'redux'
import {init, reset, startPoll, stopPoll} from 'modules/stateTypes/list/actions'
import {selectStateTypesListRows} from 'modules/stateTypes/list/selectors'
import {useDispatch, useSelector} from 'react-redux'

export default function useStateTypes() {
    const dispatch = useDispatch()

    return {
        ...useSelector(selectStateTypesListRows),
        ...bindActionCreators(
            {
                init,
                reset,
                startPoll,
                stopPoll,
            },
            dispatch
        ),
    }
}
