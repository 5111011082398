import React from 'react'
import {Bar} from 'react-chartjs-2'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'

import DeliveranceTable from '../DeliveranceTable/DeliveranceTable'

export default function DeliveryStatus({
    options,
    data,
    deliveranceByCS,
    centralStations,
}) {
    return (
        <div>
            <h3 className="deliverance-heading">{__('Overall status')}</h3>
            <div className="deliverance-line-chart">
                <Bar options={options} data={data} />
            </div>
            <div className="centralstations-events">
                {Object.keys(deliveranceByCS).map((key) => (
                    <DeliveranceTable
                        key={key}
                        {...{
                            deliverance: deliveranceByCS[key],
                            centralStation: centralStations[key],
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

DeliveryStatus.propTypes = {
    options: PropTypes.object,
    data: PropTypes.object,
    deliveranceByCS: PropTypes.object,
    centralStations: PropTypes.object,
}
