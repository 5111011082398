import React, {useState} from 'react'

import Select, {Option} from 'ipmp-react-ui/Select'
import Input from 'ipmp-react-ui/Input'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import __ from 'utils/i18n'

import {
    HAD_STATE_PARAMETER_CUSTOM,
    HAD_STATE_PARAMETER_NEVER_TURN,
    hadStateChangeParams,
    pgmStateParamsTitle,
} from 'constants/had'

const PgmStateParametersControl = ({
    parametersName,
    customTimeName,
    onSelectChange,
    parametersLabel,
}) => {
    const [selectedParameter, setSelectedParameter] = useState(
        HAD_STATE_PARAMETER_NEVER_TURN
    )

    const onChangeHandler = (e, selectedParameter) => {
        setSelectedParameter(selectedParameter)
        onSelectChange && onSelectChange(e, selectedParameter)
    }

    return (
        <div className="pgmStateParametersControl">
            <Select
                label={parametersLabel}
                name={parametersName || 'parameters'}
                onChange={onChangeHandler}
                defaultValue={selectedParameter}
            >
                {hadStateChangeParams.map((param) => (
                    <Option
                        key={param}
                        label={pgmStateParamsTitle(param)}
                        value={param}
                    />
                ))}
            </Select>
            {selectedParameter === HAD_STATE_PARAMETER_CUSTOM && (
                <Input
                    label={__('Minutes')}
                    type="integer"
                    name={customTimeName || 'customTime'}
                />
            )}
            <Disclaimer>
                {__(
                    'This option allows programming the time length of the PGM state is ON'
                )}
            </Disclaimer>
        </div>
    )
}

export default PgmStateParametersControl
