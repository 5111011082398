import React from 'react'
import PropTypes from 'prop-types'

import Blind from 'components/InteractiveUser/Blind'
import {usePanelInteractiveUsers} from 'hooks/pages/useInteractiveUsers'

export default function PanelInteractiveUsersBlind({row, setRow, onClose}) {
    const {rows} = usePanelInteractiveUsers()
    const current = rows && rows.find(({id}) => row && id === row.id)

    return (
        <>
            {current && (
                <Blind
                    rows={rows}
                    current={current}
                    onChange={setRow}
                    onClose={onClose}
                    isPanelMode
                />
            )}
        </>
    )
}

PanelInteractiveUsersBlind.propTypes = {
    row: PropTypes.object,
    setRow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
