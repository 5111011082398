import usePerPage from '../usePerPage'
import {selectBatchesListRows} from 'modules/batches/list/selectors'
import {bindActionCreators} from 'redux'
import {
    fetch,
    init,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/batches/list/actions'
import {useDispatch, useSelector} from 'react-redux'

export default function useBatches() {
    const dispatch = useDispatch()
    const perPage = usePerPage('batches')
    const {isLoading, error, total, start} = useSelector(({batches}) => batches.list)

    return {
        ...perPage,
        ...bindActionCreators(
            {
                init,
                reset,
                setStart,
                fetch,
                onPageChange: setStart,
                startPoll,
                stopPoll,
            },
            dispatch
        ),
        rows: useSelector(selectBatchesListRows),
        isLoading,
        error,
        total,
        start,
    }
}
