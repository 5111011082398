import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'

import WalktestStatus from './WalktestStatus'
import StopWalktestButton from './Buttons/StopWalktestButton'
import StartWalktestButton from './Buttons/StartWalktestButton'
import ToggleWalktestButton from './Buttons/ToggleWalktestButton'
import useDevices from 'hooks/pages/useDevices'

export default function WalktestDevicesBar() {
    const {
        walktest: {isRunning},
    } = useDevices()

    return (
        <Bar>
            <WalktestStatus />

            <BarSpace />

            <Buttons>
                {isRunning ? <StopWalktestButton /> : <StartWalktestButton />}

                <ToggleWalktestButton />
            </Buttons>
        </Bar>
    )
}
