import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'

import ToggleBox from 'ipmp-react-ui/ToggleBox'
import Select, {Option} from 'ipmp-react-ui/Select'

import withForm from 'containers/withForm'
import ModalCardForm from 'ui/ModalCardForm'

import __ from 'utils/i18n'
import {setSystemSettings} from 'modules/forms/handlers'
import {connect} from 'react-redux'
import {selectMfa} from 'modules/system/settings/selectors'
import {showConfirmationModal} from 'modules/modals/actions'

class MfaGeneral extends Component {
    rules = {
        smsBroker: {
            presence(value, attributes) {
                return !!attributes.mfaEnabled
            },
        },
    }

    onSubmit = (data) => {
        const {handle, showConfirmationModal, ...rest} = this.props
        const normalizedData = {
            smsBroker: parseInt(data.smsBroker),
            mfaEnabled: data.mfaEnabled === 'on',
        }
        const handleData = {...rest, ...normalizedData}

        if (!normalizedData.mfaEnabled) {
            handle(handleData)
            return
        }

        showConfirmationModal(() => handle(handleData), {
            message: this.confirmOnEnableMfaText(),
            title: __('Submit form'),
        })
    }

    confirmOnEnableMfaText = () => (
        <>
            <p>
                <b>{__('Warning:')}</b>&nbsp;
                {__(
                    'By continuing with enabling MFA, all logged-in users will be logged out.'
                )}
            </p>
            <br />
            <p>
                {__(
                    'Note: for using MFA phone number should be relevant for getting access code. User will be blocked if incorrect code is entered few times. Do you want to proceed?'
                )}
            </p>
        </>
    )

    toggleLabel = (status) => (
        <span className="mfaGeneral-form-enable-toggle">
            {__('MFA Status')}
            <em>({status})</em>
        </span>
    )

    render() {
        const {mfaEnabled, smsBroker, messageBrokers, ...props} = this.props

        return (
            <ModalCardForm
                className="mfaGeneral-form"
                onSubmit={this.onSubmit}
                rules={this.rules}
                header={__('General')}
                confirmOnDismiss
                {...props}
            >
                <ToggleBox
                    class
                    name="mfaEnabled"
                    label={this.toggleLabel(__('Enabled'))}
                    labelOff={this.toggleLabel(__('Disabled'))}
                    defaultChecked={mfaEnabled}
                />
                <Select
                    name="smsBroker"
                    optional={__('Without message broker')}
                    defaultValue={smsBroker}
                    label={__('SMS-broker')}
                >
                    {messageBrokers.map(({id, name}) => (
                        <Option key={id} label={name} value={id} />
                    ))}
                </Select>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(selectMfa, (dispatcher) =>
        bindActionCreators(
            {
                showConfirmationModal,
            },
            dispatcher
        )
    ),
    withForm(setSystemSettings.MFAGeneral)
)(MfaGeneral)
