import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import AssignPanels from './AssignPanels'
import HeaderContent from './HeaderContent'

export default function SecondBlindContent({row, setHeaderContent, setFooterContent}) {
    const {email} = row
    useEffect(() => {
        if (email) {
            setHeaderContent(<HeaderContent email={email} />)
        }
    }, [email])

    return <AssignPanels installer={row} setFooterContent={setFooterContent} />
}

SecondBlindContent.propTypes = {
    row: PropTypes.object.isRequired,
    setHeaderContent: PropTypes.func.isRequired,
    setFooterContent: PropTypes.func.isRequired,
}
