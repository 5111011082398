import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {ReactComponent as PowerIcon} from 'ipmp-react-ui/icons/unblock.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {unblock} from 'permissions/users/selection'
import {unblockUser} from 'modules/users/store/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {withVisibility} from 'containers/withVisibility'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'

const ConnectedButton = compose(withVisibility())(WithConfirmationButton)

export default function UnblockUserButton({id, name}) {
    const {isPermitted} = usePermission(unblock)
    const setUnblock = useActions(unblockUser)
    const onClick = () => setUnblock(id)

    return (
        <ConnectedButton
            flat
            isVisible={isPermitted}
            onClick={onClick}
            Icon={PowerIcon}
            title={__('Unblock user')}
            message={__('Do you really want to unblock the user: %s?', name)}
            label={__('Unblock')}
            className="user-status-button-unblock"
        />
    )
}

UnblockUserButton.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
}
