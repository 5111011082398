import React from 'react'
import {SMART_COMMUNICATOR} from 'constants/groups'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import _get from 'lodash-es/get'
import {TabContentWrapper} from './TabContentWrapper'
import classes from 'classnames'

import Togglebox from 'ipmp-react-ui/ToggleBox'

const SmartCommunicator = ({
    activeTab,
    handleFieldChanged,
    smartCommunications,
    errors,
}) => {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={SMART_COMMUNICATOR}
            styleName={'communications-content'}
        >
            <FormSection
                className={classes('col', {
                    'group-form-section-with-error':
                        errors?.smartCommAnySupervision || errors?.smartCommAnyTimeout,
                })}
                title={__('Connection settings')}
            >
                <Togglebox
                    name="smartCommunicationIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="smart-comm-is-enabled"
                    defaultChecked={_get(smartCommunications, 'any.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.smart_comm.any.isEnabled',
                            e.target.checked
                        )
                    }}
                />

                <FormRow>
                    <Input
                        name="smartCommAnySupervision"
                        label={__('Supervision Period')}
                        dataTestId="smart-comm-any-supervision"
                        type="number"
                        defaultValue={_get(smartCommunications, 'any.supervision', false)}
                        disabled={!_get(smartCommunications, 'any.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.smart_comm.any.supervision',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
        </TabContentWrapper>
    )
}

export default SmartCommunicator
