import moment from 'moment-timezone'

let tz = moment.tz.guess()

export default {
    server: (...args) => moment.tz(...args, tz),
    serverDate: (...args) => moment(...args),
}

export function init(timezone) {
    tz = timezone
}

export function getTz() {
    return tz
}
