import React from 'react'

import PanelsGridBar from './PanelsGridBar'
import PanelsTable from './PanelsTable'
import PanelsSearch from './PanelsSearch'

import Page from 'ipmp-react-ui/Page'
import usePanels from 'hooks/pages/usePanels'
import {useEffect} from 'react'

export default function PanelsPage(props) {
    const panels = usePanels()

    useEffect(() => {
        panels.startPoll()

        return panels.stopPoll
    }, [])

    const pageProps = {...props, ...panels}

    return (
        <Page
            {...pageProps}
            Top={PanelsSearch}
            Bar={PanelsGridBar}
            Content={PanelsTable}
        />
    )
}
