import React from 'react'

import {create} from 'permissions/reports/bar'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useReports from 'hooks/pages/useReports'

const ConnectedButton = withVisibility()(Button)

export default function CreateReportButton() {
    const {isPermitted} = usePermission(create)
    const reportsProps = useReports()

    return (
        <ConnectedButton
            {...reportsProps}
            isVisible={isPermitted}
            onClick={() => reportsProps.showCreateReportModal(null)}
            Icon={PlusIcon}
            label={__('Add report')}
        />
    )
}
