import React, {PureComponent} from 'react'
import Definition from 'ipmp-react-ui/Definition'
import {__} from 'utils/i18n'

export default class Soak extends PureComponent {
    renderRow({name, number}, index) {
        const zoneName = `${name} ${number}`

        return (
            <tr key={index}>
                <td className="rri-result-details-row">
                    <Definition title={zoneName} />
                </td>
            </tr>
        )
    }

    render() {
        return (
            <table className="rri-result-details">
                <thead>
                    <tr>
                        <th>{__('Zone')}</th>
                    </tr>
                </thead>
                <tbody>{this.props.rows.map(this.renderRow)}</tbody>
            </table>
        )
    }
}
