import exportCsv, {wrapValue} from 'utils/exportCsv'
import {
    MENU_TYPE,
    CODE_TYPE,
    LIST_TYPE,
    CODE_HEX_TYPE,
} from 'constants/panelConfiguration'

const renderNode = (node, sep, rows = [], depth = 0) => {
    for (const val of node.val) {
        if (val.type === MENU_TYPE) {
            rows.push(`""${sep}`.repeat(depth) + wrapValue(val.name))

            renderNode(val, sep, rows, depth + 1)
        } else {
            rows.push(
                `""${sep}`.repeat(depth) +
                    wrapValue(val.name) +
                    sep +
                    wrapValue(renderVal(val))
            )
        }
    }

    return rows
}

const renderVal = (value) => {
    const {type, val} = value

    if ([CODE_HEX_TYPE, CODE_TYPE].includes(type)) {
        return '****'
    }

    if (type === LIST_TYPE) {
        const foundValue = value.items.find(([v]) => v === val)

        return foundValue ? foundValue[1] : val
    }

    return val
}

export default function saveCSV(configuration, fileName, separator) {
    const csvContent = renderNode(configuration, separator).join('\r\n')

    exportCsv(csvContent, fileName)
}
