import React from 'react'
import {useSelector} from 'react-redux'

import {humanDuration} from 'ipmp-react-ui/humanTime'

import __, {__n} from 'utils/i18n'
import {selectCategorizedList, selectHadIsSwitchable} from 'modules/panels/had/selector'

export function IsCanActivatedDisclaimerMessage() {
    const categorizedList = useSelector(selectCategorizedList)

    return (
        <>
            {__n(
                'Output can be activated',
                'Outputs can be activated',
                categorizedList.size
            )}
        </>
    )
}

export function IsOnlyAlarmActivatedDisclaimerMessage() {
    const categorizedList = useSelector(selectCategorizedList)
    const {timeSlot} = useSelector(selectHadIsSwitchable)

    return (
        <>
            {__n(
                'Output can be only activated during %(time)s since alarm received',
                'Outputs can be only activated during %(time)s since alarm received',
                categorizedList.size,
                [{time: humanDuration(timeSlot, 'seconds', true)}]
            )}
        </>
    )
}

export function IsDisabledDisclaimerMessage() {
    return <>{__('Output activation is disabled in settings')}</>
}
