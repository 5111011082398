import React from 'react'
import {compose} from 'redux'

import {withVisibility} from 'containers/withVisibility'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import MarkForServiceItem from './FaultsItems/MarkForServiceItem'
import ReassignItem from './FaultsItems/ReassignItem'
import ResolveFaultsItem from './FaultsItems/ResolveFaultsItem'
import SuspendFaultsItem from './FaultsItems/SuspendFaultsItem'
import ResumeFaultsItem from './FaultsItems/ResumeFaultsItem'

import {services} from 'permissions/panels/bar'

import {__} from 'utils/i18n'
import {withFeatureRejection} from 'containers/withFeature'
import {selectFaults} from 'modules/features/store/selectors'
import Void from 'components/Void'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'

const FaultsDropDownBase = () => (
    <DropDownButton label={__('Faults')} dataTestId="faults">
        <Menu>
            <MarkForServiceItem />
            <ReassignItem />
            <MenuDelimiter />
            <ResolveFaultsItem />
            <SuspendFaultsItem />
            <ResumeFaultsItem />
        </Menu>
    </DropDownButton>
)

const FaultsDropDownVisibility = compose(
    withVisibility(),
    withFeatureRejection(selectFaults, Void)
)(FaultsDropDownBase)

export default function FaultsDropDown() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(services)

    return <FaultsDropDownVisibility panelId={panelId} isVisible={isPermitted} />
}
