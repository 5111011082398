import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Line} from 'react-chartjs-2'

import __ from 'utils/i18n'
import {timeXAxes, timeTitleCallback} from 'utils/chartsOption'

export default class OfflineFaultyPanelsChart extends Component {
    static propTypes = {
        faultyData: PropTypes.array,
        offlaneData: PropTypes.array,
        totalData: PropTypes.array,
        from: PropTypes.instanceOf(Date),
        to: PropTypes.instanceOf(Date),
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    get data() {
        const {
            faultyData,
            totalData,
            /* disabled by PMN-6778
            offlineData
            */
        } = this.props

        return {
            datasets: [
                {
                    label: __('Faulty'),
                    data: faultyData,
                    borderColor: 'rgba(202,35,57,1)',
                    backgroundColor: 'rgba(202,35,57,0.65)',
                    spanGaps: true,
                    fill: true,
                },
                {
                    label: __('Total'),
                    data: totalData,
                    borderColor: 'rgba(45,114,186,1)',
                    backgroundColor: 'rgba(45,114,186,.65)',
                    spanGaps: true,
                    fill: true,
                },
                /* disabled by PMN-6778
                {
                    label: __('Offline'),
                    borderColor: 'rgba(202,35,57,1)',
                    backgroundColor: 'rgba(202,35,57,0.65)',
                    data: offlineData,
                }
                */
            ],
        }
    }

    get options() {
        const {from, to} = this.props

        return {
            animation: false,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: timeTitleCallback,
                },
            },
            scales: {
                y: {
                    min: 0,
                },
                x: timeXAxes(from, to),
            },
        }
    }

    render() {
        return (
            <div className="card dashboard-secondary">
                {/* disabled by PMN-6778
                <h3 className="dashboard-chartTitle">{__('Offline/Faulty Panels')}</h3>
            */}
                <h3 className="dashboard-chartTitle">{__('Total/Faulty Panels')}</h3>
                <Line data={this.data} options={this.options} />
            </div>
        )
    }
}
