import React from 'react'
import PropTypes from 'prop-types'

import ButtonWithOptions from 'ipmp-react-ui/ButtonWithOptions'

import {on, onWithTimer} from 'modules/panels/had/actions'
import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import {hadStateChangeParams, isParameterCustom, pgmStateParamsTitle} from 'constants/had'
import {showChangePgmStateParametersModal} from 'modules/modals/actions'

const SetHadPGMOnButton = ({id, deviceType, disabled}) => {
    const {onClick, onOptionClick, onShowModal} = useActions({
        onClick: on,
        onOptionClick: onWithTimer,
        onShowModal: showChangePgmStateParametersModal,
    })

    const onClickHandler = (event) => {
        event.stopPropagation()
        onClick({id, deviceType})
    }

    const onOptionClickHandler = (parameters) => {
        if (isParameterCustom(parameters)) {
            onShowModal({id, deviceType})
            return
        }
        onOptionClick({id, parameters, deviceType})
    }

    return (
        <ButtonWithOptions
            onClick={onClickHandler}
            flat
            label={__('Turn On')}
            onOptionClick={onOptionClickHandler}
            options={hadStateChangeParams}
            optionLabelResolver={pgmStateParamsTitle}
            uppercaseMenu={false}
            disabled={disabled}
        />
    )
}

SetHadPGMOnButton.propTypes = {
    id: PropTypes.number,
    disabled: PropTypes.bool,
}

export default SetHadPGMOnButton
