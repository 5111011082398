import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {
    applyFilter,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/panelInteractiveUsers/list/actions'

import withProps from 'containers/withProps'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import DateFilter from 'components/Search/Filters/DateFilter'
import {TRUE, FALSE} from 'constants/boolean'
import __ from 'utils/i18n'
import {
    CREATED_AT_FILTER,
    IS_ASSIGN_FILTER,
    IS_CODE_SET_FILTER,
    LABEL_FILTER,
    PARTITIONS_FILTER,
    ROLE_FILTER,
    STATUS_FILTER,
} from 'constants/panelUsersSearch'

export default compose(
    withProps(({isAllowedInteractiveUsers}) => {
        let filters = [
            new SuggestFilter(ROLE_FILTER, __('Role')),
            new SuggestFilter(LABEL_FILTER, __('Label')),
            new ValuesFilter(IS_CODE_SET_FILTER, __('Code'), {
                [TRUE]: __('Set'),
                [FALSE]: __('Not set'),
            }),
            new SuggestFilter(PARTITIONS_FILTER, __('Partitions')),
        ]

        if (isAllowedInteractiveUsers) {
            filters = [
                ...filters,
                new ValuesFilter(IS_ASSIGN_FILTER, __('Customer email'), {
                    [TRUE]: __('Assigned'),
                    [FALSE]: __('Not assigned'),
                }),
                new DateFilter(CREATED_AT_FILTER, __('Customer registered')),
                new SuggestFilter(STATUS_FILTER, __('Customer state')),
            ]
        }

        return {
            filters: filters.sort((item, next) => item.title.localeCompare(next.title)),
        }
    }),
    connect(
        ({panelInteractiveUsers: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: applyFilter,
                    onSelect: addFilters,
                    onQuery: setQuery,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onSuggest: fetchSuggests,
                },
                dispatch
            )
    )
)(Search)
