import {useDispatch, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {
    addFilters,
    clearFilters,
    fetch,
    init,
    purgeList,
    receive,
    remove,
    removeFilters,
    reset,
    setFilters,
    setFrontEndStart,
    setQuery,
    startPoll,
    stopPoll,
} from 'modules/eventRules/list/actions'
import {showAddEventRuleModal} from 'modules/modals/actions'
import usePerPage from '../usePerPage'

export default function useEventRules() {
    const perPageProps = usePerPage('eventRules')
    const dispatch = useDispatch()
    const eventRules = useSelector(({eventRules}) => eventRules)
    const {start, total, error, page} = eventRules.list
    const {perPage} = perPageProps

    const pages = page.filter((_, index) => index >= start && index < start + perPage)

    return {
        ...perPageProps,
        error,
        start,
        total,
        rows: pages.map((id) => ({
            className: 'eventRule-row',
            ...eventRules.store.byIds[id],
        })),
        ...bindActionCreators(
            {
                init,
                receive,
                reset,
                setFrontEndStart,
                setQuery,
                setFilters,
                clearFilters,
                addFilters,
                removeFilters,
                fetch,
                remove,
                startPoll,
                stopPoll,
                purgeList,
                showAddEventRuleModal,
            },
            dispatch
        ),
    }
}
