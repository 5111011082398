import React from 'react'
import PropTypes from 'prop-types'
import {__n} from 'utils/i18n'
import {ReactComponent as CalendarCheckIcon} from 'ipmp-react-ui/icons/calendar-check.svg'
import {ReactComponent as CalendarDotsIcon} from 'ipmp-react-ui/icons/calendar-dots.svg'
import {ReactComponent as RepeatableIcon} from 'ipmp-react-ui/icons/repeatable.svg'
import DateTime from 'ipmp-react-ui/DateTime'

const renderNext = (next) => {
    if (!next) {
        return null
    }

    return (
        <div className="rri-next-ri-section">
            <DateTime Icon={CalendarDotsIcon} datetime={next} />
        </div>
    )
}

const renderRepeat = (next, repetition) => {
    if (!next || !repetition) {
        return null
    }

    return (
        <div className="rri-next-ri-section">
            <RepeatableIcon />
            {__n('Every %d month', 'Every %d months', repetition)}
        </div>
    )
}

function RemoteInspectionsNextRI({created, next, repetition}) {
    return (
        <div className="rri-next-ri">
            <div className="rri-next-ri-section rri-next-ri-section--current">
                <DateTime Icon={CalendarCheckIcon} datetime={created} />
            </div>
            <div className="rri-next-ri-date">
                {renderNext(next)}
                {renderRepeat(next, repetition)}
            </div>
        </div>
    )
}

RemoteInspectionsNextRI.propTypes = {
    next: PropTypes.objectOf(Date),
    created: PropTypes.objectOf(Date).isRequired,
    repetition: PropTypes.number,
}

export default RemoteInspectionsNextRI
