import React, {Component} from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import Select from 'ipmp-react-ui/Select'
import {ReactComponent as RunIcon} from 'ipmp-react-ui/icons/run.svg'

class SoundPlaySelect extends Component {
    static propTypes = {
        defaultLabel: PropTypes.string,
        label: PropTypes.string,
        defaultValue: PropTypes.string,
        playSound: PropTypes.func.isRequired,
        isPlaySound: PropTypes.bool.isRequired,
    }

    handleOnChange = (...args) => this.props.onChange(...args)

    handleOnClick = () => {
        this.props.playSound()
    }

    get className() {
        const {className, isPlaySound} = this.props

        return classnames('soundPlaySelect', className, {
            soundPlaySelect__isPlay: isPlaySound,
        })
    }

    render() {
        const {children, defaultLabel, label, defaultValue, isPlaySound} = this.props

        return (
            <div className={this.className} data-testid={`sound-player-${label}`}>
                {defaultValue && isPlaySound && (
                    <span className="soundPlaySelect-play" onClick={this.handleOnClick}>
                        <RunIcon className="soundPlaySelect-play-icon" />
                    </span>
                )}
                <Select
                    label={label}
                    onChange={this.handleOnChange}
                    defaultValue={defaultValue}
                    defaultLabel={defaultLabel}
                >
                    {children}
                </Select>
            </div>
        )
    }
}

export default SoundPlaySelect
