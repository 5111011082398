import {handleActions} from 'redux-actions'

import {clear, update, purge} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    byIds: {},
}

export default handleActions(
    {
        [update](state, {payload}) {
            const byIds = {}
            payload.forEach((item) => {
                byIds[item.id] = item
            })

            return {
                ...state,
                byIds,
                isLoading: false,
            }
        },

        [clear]() {
            return defaultState
        },
        ...createPurgeStoreHandler(purge),
    },
    defaultState
)
