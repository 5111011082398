import React from 'react'
import {compose} from 'redux'

import {list, retry} from 'permissions/firmware/upgradeStatus'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import withUpgradeStatus from 'containers/withUpgradeStatus'
import {withPermission, withRejection} from 'containers/withPermission'

import {TableGrouped} from 'ipmp-react-ui/Table'
import Duration from 'ipmp-react-ui/Duration'
import Time from 'ipmp-react-ui/Time'
import {humanDate} from 'ipmp-react-ui/humanTime'

import processType from 'constants/processType'

import ProcessCounters from 'components/Processes/ProcessCounters'
import Empty from 'ui/Empty'
import BatchStatusCell from 'pages/Batches/BatchesCells/BatchStatusCell'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'
import {withAutoFilters} from 'containers/withSearchScopeLoader'
import BatchPackages, {ConnectedTrigger} from './TableCell/BatchPackages'
import {HISTORY_PUSH} from 'constants/reactRouterHistoryActions'

const columns = [
    {
        maxWidth: 100,
        render: ({started}) => <Time datetime={started} />,
    },
    {
        width: 150,
        render: ({type, id}) => (
            <BatchPackages batchId={id}>
                <ConnectedTrigger batchId={id} title={processType(type)} />
            </BatchPackages>
        ),
    },
    {
        width: 100,
        render: ({panels, panelSerial}) => {
            if (panels > 1) {
                return __n('%d panel', '%d panels', panels)
            }

            return panelSerial || <Empty />
        },
    },
    {
        width: 200,
        render: (row) => (
            <BatchStatusCell
                row={row}
                pathToRedirection="firmwareUpgradeStatus"
                idMapper={(id) => ({batchId: id})}
            />
        ),
    },
    {
        width: 200,
        maxWidth: 150,
        render: ({started, finished}) => <Duration from={started} to={finished} />,
    },
    {
        width: 300,
        render: ({stats}) => (
            <div style={{maxWidth: 300, marginLeft: 'auto'}}>
                <ProcessCounters withLabels stats={stats} />
            </div>
        ),
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: retry,
    }),
    withRejection(),
    withUpgradeStatus(),
    withProps(({history}) => ({
        columns,
        emptyMessage: () => __('No upgrade processes'),
        separatorCallback(row) {
            let group = humanDate(row.started)
            return group || __('No user processes')
        },
        selectionBar: false,
        onRowClick({id}) {
            history.push(path('firmwareUpgradeStatus', {batchId: id}))
        },
    })),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withAutoFilters(),
    withLoader(({init, filters}) => {
        return init(null, HISTORY_PUSH, filters)
    })
)(TableGrouped)
