import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconGetLog} from 'ipmp-react-ui/icons/system-log-download.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {refresh, selectRefreshProcessByPanelId} from 'modules/panels/systemLogs'
import useRouterPanelId from 'hooks/useRouterPanelId'
import usePermission from 'hooks/usePermission'
import {refresh as refreshPermission} from 'permissions/panel/log/bar'
import {withVisibility} from 'containers/withVisibility'
import withTrackProcess from 'containers/withTrackProcess'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

const RefreshButton = compose(
    withVisibility(),
    connect((state, {panelId}) => ({
        process: selectRefreshProcessByPanelId(state, panelId),
    })),
    withTrackProcess(),
    withProps(({process}) => ({
        Icon: IconGetLog,
        className: 'btn--primary smartCommunicator-new-log',
        label: __('New System Log'),
        disabled: !!process,
    }))
)(Button)

export default function NewSystemLogButton() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(refreshPermission)

    const {refresh: action} = useActions({refresh})
    const onClick = () => action(panelId)

    return (
        <RefreshButton flat onClick={onClick} panelId={panelId} isVisible={isPermitted} />
    )
}
