import {bindActionCreators, compose} from 'redux'
import RecentHistoryPanelList from 'components/RecentHistory/RecentHistoryPanelList'
import withLoader from 'containers/withLoader'
import {connect} from 'react-redux'
import {selectRecentInteractiveUserPanels} from 'modules/panels/recent/selectors'
import {fetchPanelsInteractiveUser} from 'modules/panels/recent/actions'

export default compose(
    connect(
        (state, {interactiveUserId, panel}) => {
            if (!interactiveUserId) {
                return {
                    isLoading: false,
                    panels: [panel],
                }
            }

            const {isLoading, error, panels} = selectRecentInteractiveUserPanels(state, {
                interactiveUserId,
            })

            return {
                isLoading,
                error,
                panels,
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    fetchPanelsInteractiveUser,
                },
                dispatch
            )
    ),
    withLoader(({fetchPanelsInteractiveUser}) => fetchPanelsInteractiveUser())
)(RecentHistoryPanelList)
