import React from 'react'
import {useSelector} from 'react-redux'

import Pager from 'ipmp-react-ui/Pager'

import usePerPage from 'hooks/usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {setStart} from 'modules/interactiveUsers/list/actions'
import {selectInteractiveUsersByPage} from 'modules/interactiveUsers/store/selectors'

export default function InteractiveUsersPager() {
    const {onPerPageChange, perPage} = usePerPage('interactiveUsers')
    const {onPageChange} = useActions({onPageChange: setStart})
    const {total, start} = useSelector(selectInteractiveUsersByPage)

    return (
        <Pager
            start={start}
            total={total}
            onPageChange={onPageChange}
            perPage={perPage}
            onPerPageChange={onPerPageChange}
        />
    )
}
