import {bindActionCreators} from 'redux'
import {useDispatch, useSelector} from 'react-redux'

import {download, fetch, more, refresh} from 'modules/panels/logs/actions'
import {selectPanelLogs} from 'modules/panels/logs/selectors'

export default function usePanelLogs(panelId, mode) {
    const dispatch = useDispatch()
    const logs = useSelector((state) => selectPanelLogs(state, panelId))

    return {
        ...logs,
        ...bindActionCreators(
            {
                fetch: () => fetch(panelId, mode),
                refresh: () => refresh(panelId, mode),
                download: () => download(panelId, mode),
                more: () => more(panelId, mode),
            },
            dispatch
        ),
    }
}
