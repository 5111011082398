import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as UserIcon} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as EmailIcon} from 'ipmp-react-ui/icons/email.svg'
import {ReactComponent as PhoneIcon} from 'ipmp-react-ui/icons/phone.svg'
import Definition from 'ipmp-react-ui/Definition'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

function Info({installer: {name, email, telephone}}) {
    return (
        <div className="installerBlind-info">
            <section className="installerBlind-info-section">
                <OverflowTextTooltip className="installerBlind-info-item">
                    <Definition
                        title={name}
                        Icon={UserIcon}
                        className="installerBlind-info-name"
                    />
                </OverflowTextTooltip>
            </section>
            <section className="installerBlind-info-section">
                <OverflowTextTooltip className="installerBlind-info-item">
                    <Definition
                        title={email}
                        Icon={EmailIcon}
                        className="installerBlind-info-link"
                    />
                </OverflowTextTooltip>
                <OverflowTextTooltip className="installerBlind-info-item">
                    <Definition
                        title={telephone}
                        Icon={PhoneIcon}
                        className="installerBlind-info-phone"
                    />
                </OverflowTextTooltip>
            </section>
        </div>
    )
}

Info.propTypes = {
    installer: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        telephone: PropTypes.string,
    }),
}

export default Info
