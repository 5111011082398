import React from 'react'

import {sendEmail as sendEmailPermission} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'
import {ReactComponent as EmailIcon} from 'ipmp-react-ui/icons/email.svg'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'
import usePermission from 'hooks/usePermission'

const SendEmailButtonVisible = withVisibility()(Button)

export function SendEmailButtonBase(props) {
    const {isPermitted} = usePermission(sendEmailPermission)

    return <SendEmailButtonVisible isVisible={isPermitted} {...props} />
}

export default function SendEmailButton() {
    const {result, sendEmail} = useRemoteInspectionsResult()

    return (
        <SendEmailButtonBase
            onClick={sendEmail}
            disabled={!result}
            Icon={EmailIcon}
            flat
            label={result && result.isEmailSent ? __('Resend email') : __('Send email')}
        />
    )
}
