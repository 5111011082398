import {LIST_TYPE, MENU_TYPE} from 'constants/panelConfiguration'

export const createDependencyFilter = (values) => {
    const finder = ({key, val}) => values[key] === val

    return ({dependency}) => {
        if (!dependency) {
            return true
        }

        return dependency.some(finder)
    }
}

export const createSearchFilter = (term, strict = false) => {
    term = term.trim().toLowerCase()

    const finder = (row) => row[1].toLowerCase().indexOf(term) > -1

    return (node) => {
        if (node.name.toLowerCase().indexOf(term) > -1) {
            return true
        }

        if (node.type === LIST_TYPE) {
            if (strict) {
                const active = node.items.find((row) => row[0] === node.val)
                return active && finder(active)
            }

            return node.items.some(finder)
        }

        return node.val && node.val.toString().toLowerCase().indexOf(term) > -1
    }
}

export default function filterConfiguration(configuration, filterFn, inclusive = false) {
    const val = configuration.val.reduce((acc, node) => {
        if (node.type === MENU_TYPE) {
            if (inclusive && filterFn(node)) {
                acc.push(node)
                return acc
            }

            node = filterConfiguration(node, filterFn, inclusive)

            if (node.val.length === 0) {
                return acc
            } else {
                acc.push(node)
                return acc
            }
        }

        if (!filterFn(node)) {
            return acc
        }

        acc.push(node)
        return acc
    }, [])

    return {...configuration, val}
}
