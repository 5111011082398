import {bindActionCreators} from 'redux'
import {cleanKey, cleanLog, enable, send} from 'modules/panels/keypad/actions'
import {selectKeypadFeature} from 'modules/features/store/selectors'
import {useDispatch, useSelector} from 'react-redux'

export default function useKeypad(panelId) {
    const dispatch = useDispatch()
    const keypadFeature = useSelector((state) => selectKeypadFeature(state, {panelId}))

    const panels = useSelector(({panels}) => panels)
    const panel = panels.store.byIds[panelId] || {}
    const keypad = panels.keypad[panelId] || {}

    return {
        ...keypadFeature,
        panel,
        keypad,
        softwareVersion: panel.info.panelSwVersionNumber,
        ...bindActionCreators(
            {
                enable: () => enable(panelId),
                send: (...arg) => send(...arg, panelId),
                cleanLog: () => cleanLog(panelId),
                cleanKey: () => cleanKey(panelId),
            },
            dispatch
        ),
    }
}
