import React from 'react'
import isRtl from 'ipmp-react-ui/_utils/isRtl'
import {ReactComponent as IconGoBack} from 'ipmp-react-ui/icons/go-back.svg'
import {ReactComponent as IconGoBackRtl} from 'ipmp-react-ui/icons/go-back-rtl.svg'

export const getIconBack = () => (isRtl() ? IconGoBackRtl : IconGoBack)

export default function IconBack() {
    const IconBack = getIconBack()
    return <IconBack />
}
