import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import parseDate from 'api/base/parseDate'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from './base/mapSuggests'

import dataMapper from 'utils/dataMapper'
import {get, post} from 'api/http'

const keysMap = {
    name: 'usr_name',
    phone: 'usr_phone',
    roleName: 'roe_name',
    roleCreatorName: 'roe_creator_name',
    usrCreatorName: 'usr_usr_name',
    lastLogin: 'usr_login_timestamp',
    online: 'usr_state',
}

export const mapUser = dataMapper((user) => ({
    id: parseInt(user.usr_id),
    name: user.usr_name,
    email: user.usr_email,
    phone: user.usr_phone,
    roleName: user.roe_name,
    roleCreatorName: user.roe_creator_name,
    userCreatorName: user.usr_usr_name,
    lastLogin: parseDate(user.usr_login_timestamp),
    isOnline: user.usr_state === 'online',
    isEnabled: user.usr_status === 'enabled',
    isBlocked: user.is_blocked,
    countryId: parseInt(user.coy_id),
    countryName: user.coy_name,
    roleId: parseInt(user.roe_id),
    parentId: parseInt(user.usr_usr_id),
    relatedUsers: parseInt(user.related_users),
    relatedRoles: parseInt(user.related_roles),
    isRemovable: parseInt(user.related_users) === 0 && parseInt(user.related_roles) === 0,
}))

export function fetch({start = 0, perPage = 15, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('user/getallusers', {start, count: perPage, filter, query}).then(
        createRowsResponseParser(mapUser)
    )
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('user/suggestusers', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}

export function remove(userIds) {
    return post('/user/remove', {usr_id: userIds})
}

export function suspend(userIds) {
    return post('/user/suspend', {usr_id: userIds})
}

export function enable(userIds) {
    return post('/user/enable', {usr_id: userIds})
}

export function unblock(userId) {
    return post(`/user/${userId}/unblock`)
}
