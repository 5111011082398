import React from 'react'
import rebootPermission from 'permissions/panel/devices/plink/reboot'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import usePermission from 'hooks/usePermission'
import {usePlinkDebugCommands} from 'hooks/pages/useDevices'

const ButtonVisible = withVisibility()(Button)

export default function RebootButton({panelId, ...props}) {
    const {isPermitted} = usePermission(rebootPermission)
    const {reboot} = usePlinkDebugCommands(panelId)

    return <ButtonVisible isVisible={isPermitted} onClick={reboot} {...props} />
}
