import {bindActionCreators} from 'redux'
import {useDispatch, useSelector} from 'react-redux'

import {find, isNull, reduce} from 'lodash-es'

import {ROLE_MASTER_USER} from 'constants/accountRoles'
import {filterPanelTabsWithFeature} from 'containers/withFeature'
import {isActivationOk, isContentHidden} from 'constants/panelActivationStatus'
import {forgetPanel} from 'modules/panels/recent/actions'
import {selectPanelInteractiveUsers} from 'modules/panelInteractiveUsers/store/selectors'
import {getItemsByKeys, getMenuItems, PANEL_TAB} from 'pages/Panel/PanelPageRouting'

export default function usePanelPage({
    allowedItems,
    panel,
    match,
    isLoading,
    isFeatureLoading,
    panelId,
    features,
    isPanelUsersPagePermitted,
    error,
}) {
    const state = useSelector((state) => state)
    const dispatch = useDispatch()

    let panelInteractiveUsers, isPanelInteractiveUsers, isLoadingBeforeFetch
    const isPanelFeatures = Boolean(features)

    if (isPanelUsersPagePermitted) {
        panelInteractiveUsers = selectPanelInteractiveUsers(state, {panelId})
        isPanelInteractiveUsers = isNull(panelInteractiveUsers)
        isLoadingBeforeFetch = !panel || !isPanelFeatures || isPanelInteractiveUsers
    } else {
        isLoadingBeforeFetch = !panel || !isPanelFeatures
    }

    if (isLoadingBeforeFetch) {
        return {
            isLoading: !error ? isLoadingBeforeFetch : false,
            isPanelFeatures,
        }
    }

    if (!panel.activationStatus) {
        allowedItems = {}
    }

    if (panel.activationStatus && isContentHidden(panel.activationStatus)) {
        allowedItems = {
            [PANEL_TAB.INFO]: allowedItems.info,
        }
    }

    const vendorItems = getMenuItems(panel.vendor)
    const mergedItems = reduce(
        allowedItems,
        (res, isAllowed, item) => {
            if (isAllowed && vendorItems.has(item)) {
                res.push(item)
            }
            return res
        },
        []
    )

    const items = filterPanelTabsWithFeature(
        state,
        getItemsByKeys(mergedItems),
        match.params.id
    )

    const events = bindActionCreators(
        {
            forgetPanel,
        },
        dispatch
    )

    const masterUserToRemember = find(panelInteractiveUsers, {
        role: ROLE_MASTER_USER,
    })

    return {
        items,
        key: match.params.id,
        isLoading: isLoading || isFeatureLoading,
        isPanelFeatures,
        masterUserToRemember,
        forgetPanel: () => events.forgetPanel({panelId}),
        isContentHidden: isContentHidden(panel.activationStatus),
        isActivationOk: isActivationOk(panel.activationStatus),
    }
}
