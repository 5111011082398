import React from 'react'
import {compose} from 'redux'

import {pushBasic} from 'permissions/panels/selection'

import {withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as IconUpload} from 'ipmp-react-ui/icons/upload.svg'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {withSelectionFeature} from 'containers/withFeature'
import {CONFIGURATION_UPLOAD} from 'constants/features'
import usePermission from 'hooks/usePermission'
import useBasicConfigurations from 'hooks/pages/useBasicConfigurations'

const PushBasicConfigurationItemBase = compose(
    withVisibility(),
    withSelectionHandler()
)(MenuItem)

export function PushBasicConfigurationItem(props) {
    const {isPermitted} = usePermission(pushBasic)
    const basicConfigurationProps = useBasicConfigurations()
    const buttonProps = {...props, ...basicConfigurationProps}

    return (
        <PushBasicConfigurationItemBase
            {...buttonProps}
            isVisible={isPermitted}
            message={__(
                'Do you really want to push basic configurations for selected panels?'
            )}
            title={__('Push basic configurations')}
            children={__('Push Basic Configuration')}
            Icon={IconUpload}
        />
    )
}

const PushBasicConfigurationItemWithUpload = withSelectionFeature(CONFIGURATION_UPLOAD)(
    PushBasicConfigurationItem
)

export default function () {
    const {showPushBasicConfigurationModal} = useBasicConfigurations()

    return (
        <PushBasicConfigurationItemWithUpload onClick={showPushBasicConfigurationModal} />
    )
}
