import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Tabs from 'ipmp-react-ui/Tabs'

import BlindModalWithPagination from 'ui/BlindModalWithPagination'
import {selectHadBlindModal, selectHadIsSwitchable} from 'modules/panels/had/selector'
import HadContext from 'pages/Panel/Had/HadContext'
import SidebarTabsLayout from 'ui/SidebarTabsLayout'
import __ from 'utils/i18n'
import HadDeviceGeneric from 'components/Had/HadDeviceGeneric'
import {hadDeviceBlindModalControlResolve} from './List/HadDeviceControl'
import DeviceControlTab from './BlindModal/DeviceControlTab'
import ConfigurationTab from './BlindModal/ConfigurationTab'
import SaveButton from 'pages/Panel/Had/BlindModal/SaveButton'
import {useActions} from 'modules/higherOrder/useActions'
import {clear} from 'modules/forms/actions'
import usePermission from 'hooks/usePermission'
import {
    dimmerLevel,
    thermostatMode,
    thermostatTarget,
} from 'permissions/panel/output/actions'
import {
    isThermostatModeChangeSupported,
    isThermostatTargetChangeSupported,
} from 'constants/had/command'
import {isHadConfigurable, isHadDisabled} from 'constants/had'
import {HAD_TYPE_PGM, HAD_TYPE_DIMMER, HAD_TYPE_THERMOSTAT} from 'constants/had'
import {isStateless} from 'constants/had/hadState'

const isAllowButtonSave = (had, {isAllowedMode, isAllowedTarget, isAllowedDimmer}) => {
    if (isStateless(had?.state?.pgm)) {
        return false
    }

    const type = had?.type
    const options = had?.state?.thermostat?.modes || []

    const isThermostatModeChoose =
        isThermostatModeChangeSupported(had?.supportedCommands) && Boolean(options.length)
    const isThermostatTarget = isThermostatTargetChangeSupported(had?.supportedCommands)

    switch (type) {
        case HAD_TYPE_THERMOSTAT:
            return (
                (isAllowedMode && isThermostatModeChoose) ||
                (isAllowedTarget && isThermostatTarget)
            )
        case HAD_TYPE_DIMMER:
            return isAllowedDimmer
        case HAD_TYPE_PGM:
            return (had?.supportedCommands || []).length !== 0
        default:
            return true
    }
}

const HadBlindModal = ({selectedHadId}) => {
    const {
        hadBlindModalPrevNext: [prevId, nextId],
        had,
    } = useSelector((state) => selectHadBlindModal(state, selectedHadId))
    const {isSwitchable} = useSelector(selectHadIsSwitchable)
    const controlFormSuccess = useSelector(
        (store) => store.forms?.submitHadDeviceControlForm?.success
    )
    const {clear: clearForm} = useActions({clear})

    useEffect(() => {
        if (controlFormSuccess) {
            handleHadSelect(null)
            clearForm()
        }
    }, [controlFormSuccess])

    const isOpened = Boolean(selectedHadId)
    const isDisabled = isHadDisabled({isSwitchable, had})

    const {handleHadSelect} = useContext(HadContext)
    const isLast = nextId === null
    const isFirst = prevId === null

    const HadDeviceControl = hadDeviceBlindModalControlResolve(had?.type)

    const permissions = usePermission({
        isAllowedDimmer: dimmerLevel,
        isAllowedMode: thermostatMode,
        isAllowedTarget: thermostatTarget,
    })

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const isConfigurable = isHadConfigurable(had)

    const cardActions = [
        !isDisabled && isAllowButtonSave(had, permissions) ? <SaveButton /> : null,
    ]

    return (
        <BlindModalWithPagination
            prev={prevId}
            next={nextId}
            onNextClick={(next) => handleHadSelect(next)}
            onClose={() => handleHadSelect(null)}
            opened={isOpened}
            onPrevClick={(prev) => handleHadSelect(prev)}
            isNextDisabled={isLast}
            isPrevDisabled={isFirst}
            className="hadBlindModal"
            cardActions={cardActions[activeTabIndex]}
        >
            {had && (
                <>
                    <HadDeviceGeneric
                        name={had?.name}
                        type={had?.type}
                        userType={had?.userType}
                        id={had?.id}
                        location={had?.location}
                        partitions={had?.partitions}
                        state={had?.state}
                        RightCornerComponent={
                            <HadDeviceControl had={had} disabled={isDisabled} />
                        }
                    />

                    <hr />

                    <Tabs
                        Layout={SidebarTabsLayout}
                        onChangeTab={(i) => setActiveTabIndex(i)}
                    >
                        <DeviceControlTab
                            name={__('Device Control')}
                            had={had}
                            disabled={isDisabled}
                        />

                        {isConfigurable && (
                            <ConfigurationTab name={__('Configuration')} had={had} />
                        )}
                    </Tabs>
                </>
            )}
        </BlindModalWithPagination>
    )
}

export default HadBlindModal
