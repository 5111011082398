import React, {Fragment} from 'react'
import {__} from 'utils/i18n'
import {
    RESULT_RRI_FAIL,
    RESULT_RRI_NOT_AVAILABLE,
    RESULT_RRI_PASS,
} from 'constants/remoteInspection'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Tabs from 'ipmp-react-ui/Tabs'
import RemoteInspectionsTab from './RemoteInspectionsTab'

const TabsLayout = ({nav, tab}) => {
    return (
        <Fragment>
            {nav}

            <ScrollView>{tab}</ScrollView>
        </Fragment>
    )
}

export default function () {
    return (
        <Tabs Layout={TabsLayout} className="rri-tabs">
            <RemoteInspectionsTab name={__('Failed tests')} state={RESULT_RRI_FAIL} />
            <RemoteInspectionsTab
                name={__('Not completed tests')}
                state={RESULT_RRI_NOT_AVAILABLE}
            />
            <RemoteInspectionsTab name={__('PASSED TESTS')} state={RESULT_RRI_PASS} />
        </Tabs>
    )
}
