import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import SimpleGrid, {SimpleGridItem} from 'ipmp-react-ui/SimpleGrid'

import EditCSCommunicatingProfilesButton from 'pages/Group/Stations/EditCSCommunicatingProfilesButton'
import csProfileType, {getCsProfileStatusTitle} from 'constants/csProfileType'
import PageCard from 'ipmp-react-ui/PageCard'

export default class CentralStationCard extends Component {
    static propTypes = {
        station: PropTypes.object.isRequired,
        links: PropTypes.array,
        onMouseLeave: PropTypes.func,
        onMouseEnter: PropTypes.func,
    }

    handleMouseLeave = () => {
        const {station, onMouseLeave} = this.props
        onMouseLeave && onMouseLeave(station)
    }

    handleMouseEnter = () => {
        const {station, onMouseEnter} = this.props
        onMouseEnter && onMouseEnter(station)
    }

    render() {
        const {
            links,
            station,
            profiles,
            group: {id: groupId},
        } = this.props
        const {name, protocol, id: stationId} = station

        return (
            <PageCard
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={this.handleMouseEnter}
                title={<Definition title={name} detail={protocol} />}
                HeaderAction={
                    <EditCSCommunicatingProfilesButton
                        groupId={groupId}
                        stationId={stationId}
                    />
                }
            >
                <SimpleGrid cols={4}>
                    {Object.values(profiles).map((profile, index) => {
                        const isEnabled = links.includes(profile.id)

                        return (
                            <SimpleGridItem
                                key={profile.name}
                                title={csProfileType(profile.name)}
                                className={
                                    isEnabled
                                        ? 'notification--enabled'
                                        : 'notification--disabled'
                                }
                            >
                                {getCsProfileStatusTitle(isEnabled)}
                            </SimpleGridItem>
                        )
                    })}
                </SimpleGrid>
            </PageCard>
        )
    }
}
