import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import PageCard from 'ipmp-react-ui/PageCard'
import VerticalDelimiter from 'ipmp-react-ui/VerticalDelimiter'
import SimpleGrid, {SimpleGridItem} from 'ipmp-react-ui/SimpleGrid'

import CentralStationStatus from 'components/CentralStations/CentralStationStatus'
import {oneCentralStationDataSelect} from 'modules/centralStations/one/selectors'
import __ from 'utils/i18n'
import {selectProtocols} from 'modules/centralStations/protocols/selectors'
import {
    connectionTypeTitle,
    CS_CONNECTION_SSL_NONE,
    CS_CONNECTION_TYPE_SERIAL,
    CS_STATUS_NOT_APPLICABLE,
    getSslLabel,
    isHttpConnectionType,
} from 'constants/centralStation'
import Empty from 'ui/Empty'
import CentralStationEditButton from 'pages/CentralStation/General/CentralStationEditButton'
import usePermission from 'hooks/usePermission'
import one from 'permissions/centralStations/one'
import {withRejection} from 'containers/withPermission'

const WithRejectionLayout = withRejection()(Layout)

const General = (props) => {
    const centralStationId = props?.match?.params?.id
    const oneCentralStationData = useSelector((state) =>
        oneCentralStationDataSelect(state, {id: centralStationId})
    )
    const protocols = useSelector(selectProtocols)
    const protocol = protocols?.data?.find(
        ({id}) => Number(id) === Number(oneCentralStationData?.protocolId)
    )
    const security =
        oneCentralStationData?.ssl === CS_CONNECTION_SSL_NONE ? (
            <Empty />
        ) : (
            getSslLabel(oneCentralStationData?.ssl)
        )
    const {isPermitted} = usePermission(one)
    const isHttp = isHttpConnectionType(oneCentralStationData?.connectionType)
    const status = oneCentralStationData?.status
    const applicableStatus = status && status !== CS_STATUS_NOT_APPLICABLE

    return (
        <WithRejectionLayout isAllowed={isPermitted} className="centralStation-general">
            <ScrollView>
                <PageCard
                    title={
                        <section>
                            {oneCentralStationData?.name}
                            {applicableStatus && (
                                <>
                                    <VerticalDelimiter />
                                    <CentralStationStatus status={status} />
                                </>
                            )}
                        </section>
                    }
                    HeaderAction={
                        <CentralStationEditButton centralStationId={centralStationId} />
                    }
                >
                    <SimpleGrid cols={2}>
                        <SimpleGridItem title={__('Name')}>
                            {oneCentralStationData?.name || <Empty />}
                        </SimpleGridItem>
                        <SimpleGridItem title={__('Protocol')}>
                            {protocol?.name || <Empty />}
                        </SimpleGridItem>
                        <SimpleGridItem title={__('Heart Beat')}>
                            {oneCentralStationData?.heartBeat || <Empty />}
                        </SimpleGridItem>
                        <SimpleGridItem title={__('Retry Time')}>
                            {oneCentralStationData?.retryTime || <Empty />}
                        </SimpleGridItem>
                        <SimpleGridItem title={__('Retry Count')}>
                            {oneCentralStationData?.retryCount || <Empty />}
                        </SimpleGridItem>
                        <SimpleGridItem title={__('Connection Type')}>
                            {oneCentralStationData?.connectionType ? (
                                connectionTypeTitle(oneCentralStationData?.connectionType)
                            ) : (
                                <Empty />
                            )}
                        </SimpleGridItem>
                        {oneCentralStationData?.connectionType !==
                            CS_CONNECTION_TYPE_SERIAL && (
                            <>
                                <SimpleGridItem title={__('Host')}>
                                    {oneCentralStationData?.host || <Empty />}
                                </SimpleGridItem>
                                <SimpleGridItem title={__('Port')}>
                                    {oneCentralStationData?.port || <Empty />}
                                </SimpleGridItem>
                                <SimpleGridItem title={__('Security')}>
                                    {security}
                                </SimpleGridItem>
                            </>
                        )}
                        {oneCentralStationData?.connectionType ===
                            CS_CONNECTION_TYPE_SERIAL && (
                            <>
                                <SimpleGridItem title={__('Serial Port')}>
                                    {oneCentralStationData?.serialPortName ||
                                        oneCentralStationData?.device || <Empty />}
                                </SimpleGridItem>
                            </>
                        )}
                        {isHttp && (
                            <SimpleGridItem title={__('Path')}>
                                {oneCentralStationData?.path}
                            </SimpleGridItem>
                        )}
                        {isHttp && (
                            <SimpleGridItem title={__('User')}>
                                {oneCentralStationData?.user}
                            </SimpleGridItem>
                        )}
                    </SimpleGrid>
                </PageCard>
            </ScrollView>
        </WithRejectionLayout>
    )
}

General.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
}

export default General
