import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import __ from 'utils/i18n'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {remove} from 'modules/installers/list/actions'
import {remove as removePermission} from 'permissions/installers/actions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'

const RemoveInstallerButton = ({installer: {id, email}, onClose}) => {
    const {removeInstaller} = useActions({removeInstaller: remove})

    function onClick() {
        removeInstaller([id])
        onClose && onClose()
    }

    return (
        <WithConfirmationButton
            Icon={IconRemove}
            flat
            onClick={onClick}
            message={__('Do you want to remove installer %s?', email)}
            title={__('Remove Installer')}
            label={__('Remove Installer')}
        />
    )
}

RemoveInstallerButton.propTypes = {
    installer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func,
}

export default compose(
    withPermission({isVisible: removePermission}),
    withVisibility()
)(RemoveInstallerButton)
