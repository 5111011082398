import React from 'react'
import PropTypes from 'prop-types'

import {getProcessLinkByType} from 'constants/processType'
import path from 'utils/path'
import BatchRunnerItem from 'components/Processes/BatchRunnerItem'
import BatchRunnerStatus from 'components/Processes/BatchRunnerStatus'
import __ from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'
import Spinner from 'ipmp-react-ui/Spinner'
import NavBlind, {NavBlindList, NavBlindListMore} from 'components/Nav/NavBlind'

import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {stop as stopPermission} from 'permissions/processes/actions'

import {selectRunnersRowsByBatchId} from 'modules/batches/batchRunners/selectors'
import {selectBatchIsTemporary} from 'modules/batches/widget/selectors'
import {stop} from 'modules/runners/store/actions'
import {withPermission} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import withProps from 'containers/withProps'

import {reset, startPoll, stopPoll} from 'modules/batches/batchRunners/actions'
import {activeElementClass} from 'components/Processes/ProcessBatch'

const BatchInfo = ({
    hide,
    stop,
    history,
    batch,
    isStopPermitted,
    runners,
    isLoading,
    error,
    stoppingIds,
    hasMore,
    navBlindRefHandler,
    hideSidebar,
}) => {
    const handleClick = (panelId) => {
        const link = getProcessLinkByType(batch.type)

        if (!link) {
            return
        }

        history.push(path(link, {id: panelId}))
        hide()
    }

    const onMoreClick = () => {
        history.push(path('batches.runners', {id: batch.id}))
        hide()
        hideSidebar()
    }

    const renderBatchItem = (runner, isStopping) => (
        <BatchRunnerItem {...runner} key={runner.id} onRowClick={handleClick}>
            <BatchRunnerStatus
                row={{
                    ...runner,
                }}
                isStopping={isStopping}
                isStopPermitted={isStopPermitted}
                onStop={stop}
            />
        </BatchRunnerItem>
    )

    const renderList = () => {
        if (isLoading) {
            return <Spinner />
        }

        if (error) {
            return <Error error={error} />
        }

        if (!runners.length) {
            return <Error message={__('No processes')} />
        }
        return runners.map((runner) => {
            return runner && renderBatchItem(runner, stoppingIds.includes(runner.id))
        })
    }

    const elementTopPosition = () => {
        const activeBatchItemPosition = document
            .querySelector('.' + activeElementClass)
            .getBoundingClientRect()
        const navBatchItem = parseInt(activeBatchItemPosition.top - 68)
        const navBlindHeight = batch.processCount * 88 + navBatchItem + 68
        const calculatedPadding = navBatchItem - (navBlindHeight - window.innerHeight)

        if (window.innerHeight < navBlindHeight && calculatedPadding > 0) {
            return {
                marginTop: calculatedPadding + 'px',
                height: 'initial',
            }
        }
        if (window.innerHeight < navBlindHeight && calculatedPadding < 0) {
            return {
                marginTop: 0,
                height: '100%',
            }
        }
        return {
            marginTop: navBatchItem + 'px',
            height: 'initial',
        }
    }

    return (
        <NavBlind style={elementTopPosition()} refHandler={navBlindRefHandler}>
            <NavBlindList>
                {renderList()}

                {hasMore && <NavBlindListMore onClick={onMoreClick} />}
            </NavBlindList>
        </NavBlind>
    )
}

BatchInfo.propTypes = {
    batch: PropTypes.shape({
        type: PropTypes.string,
    }),
    stoppingIds: PropTypes.array,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            panelId: PropTypes.number.isRequired,
        })
    ),
    stop: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    navBlindRefHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    hasMore: PropTypes.bool,
}

export default compose(
    withRouter,
    withPermission({
        isStopPermitted: stopPermission,
    }),
    connect(selectRunnersRowsByBatchId, (dispatch, {batchId}) =>
        bindActionCreators(
            {
                stop,
                reset: () => reset(batchId),
                startPoll,
                stopPoll,
            },
            dispatch
        )
    ),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withProps(({batchId}) => ({key: batchId})),
    withLifeCycle({
        onMount({reset}) {
            reset()
        },
    }),
    connect((state, {isLoading, batchId}) => ({
        isLoading: isLoading || selectBatchIsTemporary(state, batchId),
    }))
)(BatchInfo)
