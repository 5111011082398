import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import {
    showSystemSettingsInteractiveSessionModal,
    showSystemSettingsUserNotificationsModal,
    showSystemSettingsAdvertisementModal,
    showSystemSettingsAuthorizationSettingsModal,
} from 'modules/modals/actions'

import InteractiveSession from './InteractiveSession'
import Advertisement from './Advertisement'
import UserNotifications from './UserNotifications'
import AuthorizationSettings from './AuthorizationSettings'
import SettingsCard from 'components/Settings/SettingsCard'
import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'
import __ from 'utils/i18n'
import Layout from 'ipmp-react-ui/Layout'

export class Interactive extends Component {
    render() {
        const {
            interactiveSessionModal,
            userNotificationsModal,
            advertisementModal,
            authorizationSettingsModal,
        } = this.props
        return (
            <Layout>
                <ScrollView className="settings-grid settings-grid-interactive">
                    <SettingsCard
                        title={__('Authorization Settings')}
                        onEditAction={authorizationSettingsModal}
                    >
                        <AuthorizationSettings />
                    </SettingsCard>
                    <SettingsCard
                        title={__('Interactive Session')}
                        onEditAction={interactiveSessionModal}
                    >
                        <InteractiveSession />
                    </SettingsCard>
                    <SettingsCard
                        title={__('Advertisement')}
                        onEditAction={advertisementModal}
                    >
                        <Advertisement />
                    </SettingsCard>
                    <SettingsCard
                        title={__('Customer Notifications')}
                        onEditAction={userNotificationsModal}
                    >
                        <UserNotifications />
                    </SettingsCard>
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {
                    interactiveSession,
                    userNotifications,
                    advertisement,
                    authorizationSettings,
                    messageBrokers,
                },
            },
        }) => ({
            interactiveSession,
            userNotifications,
            advertisement,
            authorizationSettings,
            messageBrokers,
            isLoading:
                interactiveSession.isLoading ||
                userNotifications.isLoading ||
                advertisement.isLoading ||
                messageBrokers.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    interactiveSession: actions.fetchInteractiveSession,
                    userNotification: actions.fetchUserNotifications,
                    advertisement: actions.fetchAdvertisement,
                    messageBrokers: actions.fetchMessageBrokers,
                    authorizationSettings: actions.fetchAuthorizationSettings,
                    showSystemSettingsInteractiveSessionModal,
                    showSystemSettingsUserNotificationsModal,
                    showSystemSettingsAdvertisementModal,
                    showSystemSettingsAuthorizationSettingsModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                interactiveSessionModal: () =>
                    dispatchProps.showSystemSettingsInteractiveSessionModal({
                        ...stateProps.interactiveSession,
                    }),
                userNotificationsModal: () =>
                    dispatchProps.showSystemSettingsUserNotificationsModal({
                        ...stateProps.userNotifications,
                    }),
                advertisementModal: () =>
                    dispatchProps.showSystemSettingsAdvertisementModal(
                        stateProps.advertisement
                    ),
                authorizationSettingsModal: () =>
                    dispatchProps.showSystemSettingsAuthorizationSettingsModal(
                        stateProps.authorizationSettings
                    ),
            }
        }
    ),
    withLoader(
        ({
            interactiveSession,
            userNotification,
            advertisement,
            messageBrokers,
            authorizationSettings,
        }) => {
            messageBrokers()
            interactiveSession()
            userNotification()
            advertisement()
            authorizationSettings()
        }
    )
)(Interactive)
