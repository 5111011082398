import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import page from 'permissions/panel/log/page'
import {withPermission, withRejection} from 'containers/withPermission'
import {PANEL_FAMILY_SMART_COMMUNICATOR} from 'constants/panelFamily'

import GenericLogs from './GenericLogs'
import SystemLogs from './SystemLogs'

function Logs({panel}) {
    if (panel.smartCommPanel || panel.model === PANEL_FAMILY_SMART_COMMUNICATOR) {
        return <SystemLogs panel={panel} />
    }

    return <GenericLogs panel={panel} />
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    connect((state, {match}) => ({
        panel: state.panels.store.byIds[match.params.id],
    }))
)(Logs)
