import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {general} from 'permissions/group/navigation'

import {ScrollView} from 'ipmp-react-ui/Layout'
import List, {ListHintItem} from 'ipmp-react-ui/List'

import PowerMaster from './Communications/PowerMaster'
import Quazar from './Communications/Quazar'
import Quazar53 from './Communications/Quazar53'
import SmartCommunicator from './Communications/SmartCommunicator'

import {withPermission, withRejection} from 'containers/withPermission'

import {__} from 'utils/i18n'
import EditGroupButton from 'pages/Group/GroupBar/EditGroupButton'
import {
    GENERAL,
    MAIN_INFORMATION,
    POWER_MAX,
    QUAZAR,
    QUAZAR_5_3,
    SMART_COMMUNICATOR,
} from 'constants/groups'

const General = ({group}) => {
    const renderPowerMaster = () => {
        if (group.communications.pmaster) {
            return (
                <div className="card groupForm-general-card">
                    <div className="card-header">
                        <h3>{__('PowerMaster/PowerMax')}</h3>
                        <EditGroupButton group={group} activeTab={POWER_MAX} />
                    </div>
                    <PowerMaster
                        settings={group.communications.pmaster}
                        isEncryptionEnabled={group.BBAEncryption}
                        pscConnection={group.pscConnection}
                        enableTemperatureAndLight={group.enableTemperatureAndLight}
                        upgradeMethod={group.upgradeMethod}
                    />
                </div>
            )
        }
        return null
    }

    const renderQuazar = () => {
        if (group.communications.quazar) {
            return (
                <div className="card groupForm-general-card">
                    <div className="card-header">
                        <h3>{__('PowerSeries NEO/Pro, Quazar <5.3')}</h3>
                        <EditGroupButton group={group} activeTab={QUAZAR} />
                    </div>
                    <Quazar settings={group.communications.quazar} />
                </div>
            )
        }
        return null
    }

    const renderQuazar53 = () => {
        if (group.communications.quazar) {
            return (
                <div className="card groupForm-general-card">
                    <div className="card-header">
                        <h3>{__('PowerSeries NEO, Quazar 5.3 and higher')}</h3>
                        <EditGroupButton group={group} activeTab={QUAZAR_5_3} />
                    </div>
                    <Quazar53 settings={group.communications.quazar53} />
                </div>
            )
        }
        return null
    }

    const renderSmartCommunicator = () => {
        if (group.communications.smart_comm) {
            return (
                <div className="card groupForm-general-card">
                    <div className="card-header">
                        <h3>{__('Smart Communicator')}</h3>
                        <EditGroupButton group={group} activeTab={SMART_COMMUNICATOR} />
                    </div>
                    <SmartCommunicator settings={group.communications.smart_comm.any} />
                </div>
            )
        }
        return null
    }

    const empty = <span className="empty">{__('not set')}</span>
    return (
        <ScrollView>
            <div className="groupInfo">
                <div className={'card groupForm-general-card'}>
                    <div className="card-header">
                        <h3>{__('Main information')}</h3>
                        <EditGroupButton group={group} activeTab={MAIN_INFORMATION} />
                    </div>
                    <List className="card-content groupForm-info">
                        <ListHintItem name={__('Group Name')} text={group.name} />
                        <ListHintItem
                            name={__('Description')}
                            text={group.description || empty}
                        />
                    </List>
                </div>
                <div className={'card groupForm-general-card'}>
                    <div className="card-header">
                        <h3>{__('General information')}</h3>
                        <EditGroupButton group={group} activeTab={GENERAL} />
                    </div>
                    <List className="card-content groupForm-info">
                        <ListHintItem
                            name={__('Local Wake up')}
                            text={group.localWakeUp ? __('Enabled') : __('Disabled')}
                        />
                        <ListHintItem
                            name={__('Server Messaging Language')}
                            text={group.serverMessagingLanguage}
                        />
                        <hr className={'notice-item-border'} />
                        <ListHintItem
                            name={__('VOD history log size')}
                            text={group.vodHistorySize}
                        />
                        <ListHintItem
                            name={__('Time Synchronization')}
                            text={
                                group.timeSynchronization ? __('Enabled') : __('Disabled')
                            }
                        />
                        <hr className={'notice-item-border'} />
                        <ListHintItem
                            name={__('Allow upgrade over Gprs DSC panels')}
                            text={
                                group.allowUpgradeOverGprsDSCPanels
                                    ? __('Enabled')
                                    : __('Disabled')
                            }
                        />
                        <ListHintItem
                            name={__('Temperature/Light statistic')}
                            text={
                                group.enableTemperatureAndLight
                                    ? __('Enabled')
                                    : __('Disabled')
                            }
                        />
                    </List>
                </div>
                <div>{renderPowerMaster()}</div>
                <div>{renderQuazar()}</div>
                <div>{renderQuazar53()}</div>
                <div>{renderSmartCommunicator()}</div>
            </div>
        </ScrollView>
    )
}

General.propTypes = {
    group: PropTypes.object.isRequired,
    enableTemperatureAndLight: PropTypes.bool,
}

export default compose(withPermission({isAllowed: general}), withRejection())(General)
