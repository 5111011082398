import {bindActionCreators} from 'redux'
import {changeValue, fetch, setExport, undo} from 'modules/basicConfiguration/one/actions'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'

export default function useBasicConfiguration() {
    const dispatch = useDispatch()
    const {one} = useSelector(({basicConfiguration}) => basicConfiguration)
    const {id} = useParams()

    const configuration = one[id]

    return {
        ...(configuration || {isLoading: true}),
        ...bindActionCreators(
            {
                fetch: (...arg) => fetch({basicConfigId: id}, ...arg),
                onChange: (...arg) => changeValue(id, ...arg),
                onSetExport: (...arg) => setExport(id, ...arg),
                onUndo: (...arg) => undo(id, ...arg),
            },
            dispatch
        ),
        basicConfigId: id,
    }
}
