import __ from 'utils/i18n'

export const PROCESS_SUCCEEDED_STATUS = 'succeeded'
export const PROCESS_FAILED_STATUS = 'failed'
export const PROCESS_STARTED_STATUS = 'started'
export const PROCESS_START_STATUS = 'start'
export const PROCESS_HANDLED_STATUS = 'handled'

export function getProcessStatusTitle(processStatus) {
    switch (processStatus) {
        case PROCESS_SUCCEEDED_STATUS:
            return __('Succeeded')
        case PROCESS_FAILED_STATUS:
            return __('Failed')
        case PROCESS_START_STATUS:
        case PROCESS_STARTED_STATUS:
            return __('Started')
        case PROCESS_HANDLED_STATUS:
            return __('Handled')
        default:
            console.warn(`Unknown process status: ${processStatus}`)
            return processStatus
    }
}
