import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import InteractiveBar from 'ipmp-react-ui/InteractiveBar'

import ActivateButton from './Buttons/ActivateButton'
import MasterActivateGUIButton from './Buttons/MasterActivateGUIButton'
import GetActivatePanelMessage from './GetActivatePanelMessage'
import {isActivationOk} from 'constants/panelActivationStatus'
import {selectPanel} from 'modules/panels/store/selectors'

export default function ActivatePanelInteractiveBar({id, isFailed}) {
    const {activationStatus} = useSelector((state) => selectPanel(state, {panelId: id}))

    const isActiveBar = activationStatus && !isActivationOk(activationStatus)

    return (
        <InteractiveBar warning danger={isFailed} active={isActiveBar}>
            <GetActivatePanelMessage activationStatus={activationStatus} panelId={id} />
            <ActivateButton panelId={id} />
            <MasterActivateGUIButton panelId={id} />
        </InteractiveBar>
    )
}

ActivatePanelInteractiveBar.propTypes = {
    id: PropTypes.number.isRequired,
    isFailed: PropTypes.bool,
}
