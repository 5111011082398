import React from 'react'
import {compose} from 'redux'

import {MAIN_GROUP_ID} from 'constants/groups'

import list from 'permissions/groups/list'
import group from 'permissions/group/page'
import selection from 'permissions/groups/selection'
import {roles} from 'permissions/groups/actions'

import {withRejection} from 'containers/withPermission'

import withSelection from 'containers/withSelection'
import withGroups from 'containers/withGroups'
import withLoader from 'containers/withLoader'

import Empty from 'ui/Empty'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'
import GroupRoles from './TableCell/GroupRoles'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'
import usePermission from 'hooks/usePermission'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

const allColumns = [
    {
        name: () => __('Name'),
        width: 150,
        render: ({name}) => name,
    },
    {
        name: () => __('Description'),
        width: 350,
        render: ({description}) => description || <Empty />,
    },
    {
        name: () => __('Panels'),
        tooltip: false,
        width: 100,
        render: ({total}) => {
            if (total === 0) {
                return <span className="empty">{__('No panels')}</span>
            }
            return <Definition title={total} detail={__n('panel', 'panels', total)} />
        },
    },
    {
        tooltip: false,
        width: 100,
        render: ({online, total}) => {
            if (total === 0) {
                return <Empty />
            }

            return <Definition title={online} detail={__('online')} />
        },
    },
    {
        tooltip: false,
        width: 100,
        render: ({connected, total}) => {
            if (total === 0) {
                return <Empty />
            }

            return <Definition title={connected} detail={__('connected')} />
        },
    },
    {
        tooltip: false,
        permission: roles,
        fixed: true,
        width: 70,
        render: ({id}) => <GroupRoles groupId={id} />,
    },
]

const ConnectedTable = compose(
    withRejection(),
    withGroups(),
    withSelection(),
    withLoader(({init}) => init())
)(Table)

export default function GroupsTable(props) {
    const permissions = usePermission({
        isAllowed: list,
        hasSelection: selection,
        hasGroupLink: group,
    })

    const tableProps = {...props, ...permissions}
    const {columns} = useTableColumnsPermission(allColumns)
    const {hasGroupLink, history} = tableProps

    return (
        <ConnectedTable
            {...tableProps}
            fullHeight
            columns={columns}
            disabledRowsIds={[MAIN_GROUP_ID]}
            emptyMessage={() => __('No groups found')}
            onRowClick={
                hasGroupLink && (({id}) => history.push(path('system.group', {id})))
            }
            className="groups-table"
        />
    )
}
