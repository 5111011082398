import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import Textarea from 'ipmp-react-ui/Textarea'

import MaintainerSelect from './MaintainerSelect'

export default class MaintainForm extends PureComponent {
    static propTypes = {
        panelIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        handle: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        isLoading: PropTypes.bool,
    }

    rules = {
        comment: {
            presence: true,
            length: {
                minimum: 3,
                maximum: 1000,
            },
        },
    }

    handle = ({userId, comment}) => {
        const {handle, panelIds} = this.props

        if (userId) {
            handle(panelIds, comment, parseInt(userId), this.userName)
        } else {
            handle(panelIds, comment)
        }
    }

    handleMaintainerChange = (e, userId, userName) => {
        this.userName = userName
    }

    render() {
        const {panelIds, header, ...props} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={header}
                rules={this.rules}
                {...props}
            >
                <MaintainerSelect
                    panelIds={panelIds}
                    name="userId"
                    label={__('To')}
                    onChange={this.handleMaintainerChange}
                    defaultValue={0}
                />

                <Textarea
                    name="comment"
                    type="text"
                    label={__('Comment')}
                    dataTestId="comment"
                />
            </ModalCardForm>
        )
    }
}
