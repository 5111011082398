import dataMapper from 'utils/dataMapper'
import {
    EVENT_RULE_STATE_TYPE_WILD_CARD,
    EVENT_RULE_ZONE_TYPE_WILD_CARD,
    EVENT_TYPE_NAME,
    EVENT_TYPE_NAME_RESTORE,
    NOTIFICATION_TYPE_NAME,
    STATE_TYPE_NAME,
    ZONE_TYPE_NAME,
} from 'constants/eventRules'

const parseZoneTypeId = (id) =>
    id === null ? EVENT_RULE_ZONE_TYPE_WILD_CARD : parseInt(id)
const parseStateId = (id) =>
    id === null ? EVENT_RULE_STATE_TYPE_WILD_CARD : parseInt(id)

export const mapEventRule = dataMapper((data) => ({
    id: parseInt(data.id),
    notificationId: parseInt(data.notif_type_id),
    zoneTypeId: parseZoneTypeId(data.zone_type_id),
    isRestore: data.is_restore,
    stateId: parseStateId(data.state_id),
    eventId: parseInt(data.evt_type_id),
}))

export const keysMap = {
    id: 'id',
    notificationId: 'notif_type_id',
    zoneTypeId: 'zone_type_id',
    isRestore: 'is_restore',
    stateId: 'state_id',
    eventId: 'evt_type_id',
}

export const mapFormData = (data) => ({
    zone_type_id: Number(data[ZONE_TYPE_NAME]),
    notif_type_id: Number(data[NOTIFICATION_TYPE_NAME]),
    state_id: Number(data[STATE_TYPE_NAME]),
    evt_type_id: Number(data[EVENT_TYPE_NAME]),
    is_restore: data[EVENT_TYPE_NAME_RESTORE] === 'on',
})
