import __ from 'utils/i18n'

export const ACCOUNT_TAB = {
    INSTALLERS: 'installers',
    INTERACTIVE_USERS: 'interactiveUsers',
}

export const tabsMap = new Map([
    [
        ACCOUNT_TAB.INTERACTIVE_USERS,
        {
            label: () => __('Customers'),
            path: 'accounts.interactiveUsers',
            exact: true,
        },
    ],
    [
        ACCOUNT_TAB.INSTALLERS,
        {
            label: () => __('Installers'),
            path: 'accounts.installers',
        },
    ],
])
