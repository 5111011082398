import {createSelector} from 'reselect'
import get from 'lodash-es/get'
import {getFilterDate} from 'utils/dateFilter'
import getInteractiveUserStatusTitle from 'constants/interactiveUserStatus'
import {TRUE} from 'constants/boolean'
import {
    CREATED_AT_FILTER,
    IS_ASSIGN_FILTER,
    IS_CODE_SET_FILTER,
    LABEL_FILTER,
    PARTITIONS_FILTER,
    QUERY_FILTER,
    ROLE_FILTER,
    STATUS_FILTER,
} from 'constants/panelUsersSearch'
import {getUserRoleTitle} from 'constants/accountRoles'

const SEARCH_FIELDS = ['email', 'role', 'label']

export const selectPanelInteractiveUsers = createSelector(
    (state, {panelId}) =>
        get(state, ['panelInteractiveUsers', 'store', 'byIds', panelId], null),
    (store) => store
)

export const selectPanelInteractiveUsersAsArray = createSelector(
    selectPanelInteractiveUsers,
    (store) => Object.values(store)
)

export const selectPanelInteractiveUsersFiltered = createSelector(
    (state) => state.panelInteractiveUsers.list,
    selectPanelInteractiveUsers,
    (state) => state,
    (_, {panelId}) => panelId,
    ({filters, query}, allUsersByIds, state, panelId) => {
        const allUsers = Object.values(allUsersByIds || {})

        if (filters.length === 0 && !query) {
            return allUsers
        }

        let filteredIds = allUsers.map((user) => user.id)

        const formattedFilters = query
            ? [...filters, {name: QUERY_FILTER, value: query}]
            : filters

        const groupsIds = {}

        for (const {name, value} of formattedFilters) {
            const selector = FILTER_SELECTORS[name]

            groupsIds[name] = [
                ...selector(state, {panelId, query: value, name}),
                ...(groupsIds[name] || []),
            ]
        }

        for (const ids of Object.values(groupsIds)) {
            filteredIds = filteredIds.filter((id) => ids.includes(id))
        }

        return allUsers.filter((user) => filteredIds.includes(user.id))
    }
)

export const selectPanelInteractiveUsersFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query, name}) => ({query, name}),
    (users, {query, name}) =>
        Object.values(users)
            .filter((user) => (user[name] || '') === query)
            .map((item) => item.id)
)

export const selectPanelInteractiveUsersRoleFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query, name}) => ({query, name}),
    (users, {query, name}) =>
        Object.values(users)
            .filter((user) => (user.role ? getUserRoleTitle(user.role) : '') === query)
            .map((item) => item.id)
)

export const selectPanelInteractiveUsersStatusFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query}) => query,
    (users, query) =>
        Object.values(users)
            .filter((user) => getInteractiveUserStatusTitle(user.status) === query)
            .map((item) => item.id)
)

export const selectPanelInteractiveUsersCreatedAtFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query}) => getFilterDate(query),
    (users, {from, to}) =>
        Object.values(users)
            .filter((user) => {
                if (!user.createdAt) {
                    return false
                }

                if (from && from.isAfter(user.createdAt)) {
                    return false
                }

                return !(to && to.isBefore(user.createdAt))
            })
            .map((item) => item.id)
)

export const selectPanelInteractiveUsersBoolFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query, name}) => ({query, name}),
    (users, {query, name}) =>
        Object.values(users)
            .filter((user) => user[name] === (query === TRUE))
            .map((item) => item.id)
)

export const selectPanelInteractiveUsersPartitionsFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query}) => ({query}),
    (users, {query}) =>
        Object.values(users)
            .filter(
                (user) =>
                    (!query && !user.partitions?.length) ||
                    (user.partitions && user.partitions.includes(query))
            )
            .map((item) => item.id)
)

export const selectPartitionsSuggests = createSelector(
    selectPanelInteractiveUsers,
    (users) => {
        const partitions = Object.values(users)
            .reduce((acc, user) => acc.concat(user.partitions), [])
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()

        return {
            count: partitions.length,
            values: [...partitions],
        }
    }
)

export const selectStatusSuggests = createSelector(
    selectPanelInteractiveUsers,
    (users) => {
        const data = new Set(
            Object.values(users).map((item) => getInteractiveUserStatusTitle(item.status))
        )

        return {
            count: data.size,
            values: [...data],
        }
    }
)

export const selectSuggests = createSelector(
    selectPanelInteractiveUsers,
    (_, {field}) => field,
    (users, field) => {
        const data = new Set(
            Object.values(users).map((item) =>
                item[field] ? getUserRoleTitle(item[field]) : ''
            )
        )

        return {
            count: data.size,
            values: [...data],
        }
    }
)

export const selectPanelInteractiveUsersSearchFilter = createSelector(
    selectPanelInteractiveUsers,
    (_, {query}) => query.toLowerCase(),
    (users, query) =>
        Object.values(users)
            .filter((user) => {
                for (const field of SEARCH_FIELDS) {
                    if (user[field] && user[field].toLowerCase().includes(query)) {
                        return true
                    }
                }

                return false
            })
            .map((item) => item.id)
)

const FILTER_SELECTORS = {
    [ROLE_FILTER]: selectPanelInteractiveUsersRoleFilter,
    [LABEL_FILTER]: selectPanelInteractiveUsersFilter,
    [IS_CODE_SET_FILTER]: selectPanelInteractiveUsersBoolFilter,
    [CREATED_AT_FILTER]: selectPanelInteractiveUsersCreatedAtFilter,
    [IS_ASSIGN_FILTER]: selectPanelInteractiveUsersBoolFilter,
    [PARTITIONS_FILTER]: selectPanelInteractiveUsersPartitionsFilter,
    [STATUS_FILTER]: selectPanelInteractiveUsersStatusFilter,
    [QUERY_FILTER]: selectPanelInteractiveUsersSearchFilter,
}
