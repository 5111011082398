import createPermission, {any} from 'permissions/createPermission'

import processes from './processes/page'
import users from './users/page'
import groups from './groups/page'
import roles from './roles/page'
import actionLog from './actionLog/page'
import accounts from './accounts/page'
import centralStations from './centralStations/page'
import basicConfiguration from './basicConfiguration/page'
import dashboard from './dashboard/page'
import settings from './settings/page'

export {default as panels} from './panels/page'
export {default as remoteInspections} from './remoteInspections/page'
export {default as events} from './events/page'
export {default as reports} from './reports/page'
export {default as panel} from './panel/page'
export {default as firmware} from './firmware/page'
export {default as firmwareUpgradeStatus} from './firmware/status'

export {
    settings,
    processes,
    groups,
    users,
    roles,
    actionLog,
    accounts,
    centralStations,
    basicConfiguration,
    dashboard,
}

export const system = createPermission(
    any(
        settings,
        processes,
        groups,
        users,
        roles,
        actionLog,
        centralStations,
        basicConfiguration,
        dashboard
    )
)
