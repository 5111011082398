import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Input from 'ipmp-react-ui/Input'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import ModalCardForm from 'ui/ModalCardForm'

import __ from 'utils/i18n'
import {onWithTimer} from 'modules/panels/had/actions'
import {
    HAD_STATE_PARAMETER_CUSTOM,
    HAD_STATE_PARAMETER_CUSTOM_MAXIMUM_TIMEOUT,
} from 'constants/had'

const rules = {
    customTime: {
        numericality: {
            lessThanOrEqualTo: HAD_STATE_PARAMETER_CUSTOM_MAXIMUM_TIMEOUT,
            greaterThanOrEqualTo: 1,
        },
        presence: true,
    },
}

const ChangePgmStateParameters = ({hide, onWithTimer, id, deviceType, ...props}) => {
    const onSubmit = ({customTime}) => {
        onWithTimer({
            id,
            deviceType,
            parameters: HAD_STATE_PARAMETER_CUSTOM,
            customTime,
        })
        hide()
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={onSubmit}
            header={__('Parameters')}
            hide={hide}
            rules={rules}
            {...props}
        >
            <div className="pgmStateParametersControl">
                <Input label={__('Minutes')} type="integer" name="customTime" />
                <Disclaimer>
                    {__(
                        'This option allows programming the time length of the PGM state is ON'
                    )}
                </Disclaimer>
            </div>
        </ModalCardForm>
    )
}

export default compose(
    connect(null, (dispatcher) =>
        bindActionCreators(
            {
                onWithTimer,
            },
            dispatcher
        )
    )
)(ChangePgmStateParameters)
