import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import {withVisibility} from 'containers/withVisibility'
import {actions} from 'permissions/panel/actions'
import {MODULE_TYPE_BBA, MODULE_TYPE_GPRS} from 'constants/moduleTypes'
import {__} from 'utils/i18n'
import EditPanelItem from './ActionsItems/EditPanelItem'
import RemovePanelItem from './ActionsItems/RemovePanelItem'
import ToggleUserAppItem from './ActionsItems/ToggleUserAppItem'
import ToggleInstallerAppItem from './ActionsItems/ToggleInstallerAppItem'
import ToggleEncryptionItem from './ActionsItems/ToggleEncryptionItem'
import TriggerDiscoveryItem from './ActionsItems/TriggerDiscoveryItem'
import ReadDiagnosticItem from './ActionsItems/ReadDiagnosticItem'
import usePermission from 'hooks/usePermission'

const ActionsDropDownBase = ({connected, isContentHidden}) => {
    const [isPreventHideOnOuterClick, setIsPreventHideOnOuterClick] = useState(false)
    const preventHideOnOuterClick = () => {
        return isPreventHideOnOuterClick
    }

    const preventOuterClick = (value) => {
        setIsPreventHideOnOuterClick(value)
    }

    return (
        <DropDownButton
            label={__('Actions')}
            preventHideOnOuterClick={preventHideOnOuterClick}
            dataTestId="actions"
        >
            <Menu>
                <ToggleUserAppItem />
                <ToggleInstallerAppItem />
                <MenuDelimiter />
                {!isContentHidden && <TriggerDiscoveryItem />}
                {!isContentHidden && <ReadDiagnosticItem />}
                <EditPanelItem />
                <RemovePanelItem />
                <MenuDelimiter />
                <ToggleEncryptionItem
                    setPreventHideOnOuterClick={preventOuterClick}
                    connected={connected}
                    type={MODULE_TYPE_BBA}
                />
                <ToggleEncryptionItem
                    setPreventHideOnOuterClick={preventOuterClick}
                    connected={connected}
                    type={MODULE_TYPE_GPRS}
                />
            </Menu>
        </DropDownButton>
    )
}

ActionsDropDownBase.propTypes = {
    connected: PropTypes.bool,
    isContentHidden: PropTypes.bool,
}

const ActionsDropDownVisibility = withVisibility()(ActionsDropDownBase)

export default function ActionsDropDown({connected, isContentHidden}) {
    const {isPermitted} = usePermission(actions)

    return (
        <ActionsDropDownVisibility
            connected={connected}
            isContentHidden={isContentHidden}
            isVisible={isPermitted}
        />
    )
}
