import React from 'react'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import list from 'permissions/panels/list'
import usePermission from 'hooks/usePermission'
import usePanels from 'hooks/pages/usePanels'

const PagerVisibility = withVisibility()(Pager)

export default function PanelsPager() {
    const panels = usePanels()
    const {isPermitted} = usePermission(list)

    return (
        <PagerVisibility {...panels} isVisible={isPermitted} dataTestId="panels-pager" />
    )
}
