import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import Button from 'ipmp-react-ui/Button'
import {showEditUserModal} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'

export default function EditCell({row: {id}}) {
    const editUser = useActions(showEditUserModal)
    const onClick = () => editUser(id)

    return <Button onClick={onClick} flat Icon={IconEdit} className="user-button-edit" />
}

EditCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
}
