export const DEFAULT_THEME = 'sanMarino'
export const DARK_THEME = 'valencia'
export const MARGIN = 16

export const isDarkTheme = (theme: string) => theme === DARK_THEME

export default {
    sanMarino: {
        title: 'SanMarino',
        classList: ['theme--sanMarino'],
    },
    valencia: {
        title: 'Valencia',
        classList: ['theme--dark', 'theme--valencia'],
    },
}
