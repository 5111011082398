import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Detector from './ChartsTab/Detector'
import {TEMPERATURE, BRIGHTNESS, GSM_RSSI} from 'constants/meteoTypes'

export default class ChartsTab extends Component {
    static propTypes = {
        panelId: PropTypes.number.isRequired,
        device: PropTypes.object,
        hasBrightness: PropTypes.bool,
        hasTemperature: PropTypes.bool,
        hasGsmRssi: PropTypes.bool,
    }

    range = {
        from: moment().subtract(1, 'day'),
        to: moment(),
    }

    render() {
        const {panelId, device, hasTemperature, hasBrightness, hasGsmRssi, isGeneric} =
            this.props

        return (
            <div className="blocks charts">
                {hasTemperature && (
                    <Detector
                        type={TEMPERATURE}
                        range={this.range}
                        panelId={panelId}
                        deviceId={device.id}
                    />
                )}

                {hasBrightness && (
                    <Detector
                        type={BRIGHTNESS}
                        range={this.range}
                        panelId={panelId}
                        deviceId={device.id}
                    />
                )}

                {hasGsmRssi && !isGeneric && (
                    <Detector
                        type={GSM_RSSI}
                        range={this.range}
                        panelId={panelId}
                        deviceId={device.id}
                    />
                )}
            </div>
        )
    }
}
