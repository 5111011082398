import React from 'react'
import {compose} from 'redux'

import bar, {refresh, download} from 'permissions/panel/log/bar'

import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRefresh} from 'ipmp-react-ui/icons/refresh.svg'

import {__} from 'utils/i18n'

export const LogBar = ({hasRefresh, hasDownload, refresh, download}) => {
    return (
        <div className="configuration-header">
            <Bar orientation={RIGHT}>
                {hasRefresh && (
                    <Button onClick={refresh} label={__('Refresh')} Icon={IconRefresh} />
                )}
                {hasDownload && <Button onClick={download} label={__('Download CSV')} />}
            </Bar>
        </div>
    )
}

export default compose(
    withPermission({
        isVisible: bar,
        hasRefresh: refresh,
        hasDownload: download,
    }),
    withVisibility()
)(LogBar)
