import {createAction} from 'redux-actions'

export const reset = createAction('PANELS/REPORTS/RESULT/RESET', null, (_, panelId) => ({
    panelId,
}))
export const fetch = createAction('PANELS/REPORTS/RESULT/FETCH', null, (_, panelId) => ({
    panelId,
}))
export const receive = createAction(
    'PANELS/REPORTS/RESULT/RECEIVE',
    null,
    (_, panelId) => ({panelId})
)
export const review = createAction(
    'PANELS/REPORTS/RESULT/REVIEW',
    (id) => ({id, isReviewed: true}),
    (_, panelId) => ({panelId})
)
export const unreview = createAction(
    'PANELS/REPORTS/RESULT/UNREVIEW',
    (id) => ({id, isReviewed: false}),
    (_, panelId) => ({panelId})
)

export const startPoll = createAction(
    'PANELS/REPORTS/RESULT/START_POLL',
    null,
    (_, panelId) => ({
        panelId,
    })
)
export const stopPoll = createAction('PANELS/REPORTS/RESULT/STOP_POLL')
