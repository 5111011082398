import React from 'react'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import path from 'utils/path'

const AccountsBar = ({tabs}) => {
    return (
        <NavTabs className="accounts-tabs">
            {[...tabs].map(([key, {label, path: pathName, exact}]) => (
                <NavTabLink key={key} to={path(pathName)} exact={exact}>
                    {label()}
                </NavTabLink>
            ))}
        </NavTabs>
    )
}

export default AccountsBar
