import BoardFilter from 'components/Search/Board/BoardFilter'
import Filter from 'components/Search/Filters/Filter'
import has from 'lodash-es/has'

export default class ValuesFilter extends Filter {
    Component = BoardFilter

    constructor(name, title, values: Object) {
        super(name, title)
        this.values = values
    }

    isFilterExistMoreValues = ({selected}) => {
        if (!selected || !selected.length) {
            return true
        }

        const filterValues = Object.keys(this.values)

        const selectedFilterValues = selected
            .filter((selectedFilter) => selectedFilter.name === this.name)
            .map((selectedFilter) => selectedFilter.value)

        return (
            filterValues.filter((value) => {
                return !selectedFilterValues.includes(value)
            }).length > 0
        )
    }

    getItems() {
        if (!this.values) {
            return []
        }

        return Object.keys(this.values).map(this.createItem.bind(this))
    }

    getItemLabel(value) {
        if (has(this.values, value)) {
            return this.values[value]
        }

        return value
    }
}
