import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {withRejection} from 'containers/withPermission'

import page from 'permissions/panel/devices/page'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import DevicesContent from './DevicesContent'

import DefaultDevicesBar from './Bars/DefaultDevicesBar'
import WalktestDevicesBar from './Bars/WalktestDevicesBar'
import useDevices from 'hooks/pages/useDevices'
import usePermission from 'hooks/usePermission'

export class DevicesBase extends Component {
    static propTypes = {
        refreshRssi: PropTypes.func.isRequired,
        showAddDevice: PropTypes.func.isRequired,
        toggleWalktest: PropTypes.func,
        fetchWalktest: PropTypes.func,
        startWalktest: PropTypes.func,
        stopWalktest: PropTypes.func,
        showWalktest: PropTypes.bool,
        panel: PropTypes.object,
        configuration: PropTypes.object,
        changes: PropTypes.object,
        devices: PropTypes.object,
    }

    state = {
        filterTroubles: false,
        hideNullZones: true,
    }

    handleTroublesCheckboxChange = (e) =>
        this.setState({filterTroubles: e.target.checked})
    handleNullZoneCheckboxChange = (e) => this.setState({hideNullZones: e.target.checked})

    render() {
        const {showWalktest, panel} = this.props
        const {filterTroubles, hideNullZones} = this.state

        return (
            <Layout vertical className="devices">
                {showWalktest ? (
                    <WalktestDevicesBar />
                ) : (
                    <DefaultDevicesBar
                        onToggleFaults={this.handleTroublesCheckboxChange}
                        filterTroubles={filterTroubles}
                        onToggleNullZones={this.handleNullZoneCheckboxChange}
                        hideNullZones={hideNullZones}
                    />
                )}

                <ScrollView className="devices-content">
                    <DevicesContent
                        {...{
                            filterTroubles,
                            hideNullZones,
                            showWalktest,
                            panel,
                        }}
                    />
                </ScrollView>
            </Layout>
        )
    }
}

const DevicesRejection = withRejection()(DevicesBase)

export default function Devices() {
    const {isPermitted} = usePermission(page)
    const devicesProps = useDevices()

    return <DevicesRejection isAllowed={isPermitted} {...devicesProps} />
}
