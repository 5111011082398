import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import isNil from 'lodash-es/isNil'
import get from 'lodash-es/get'

import Autocomplete from 'ipmp-react-ui/Autocomplete'

import ModalCardForm from 'ui/ModalCardForm'

import withForm from 'containers/withForm'
import {assignInstallerToPanel} from 'modules/forms/handlers'
import {fetch as installersFetch} from 'modules/installers/autocomplete/actions'
import withSelectLoader from 'containers/withSelectLoader'
import __ from 'utils/i18n'

const InstallersSelect = withSelectLoader(
    installersFetch,
    ({installers}) => get(installers, 'autocomplete.isLoading', false),
    ({
        modals: {
            args: {panelId},
        },
        installers: {
            store: {byIds},
        },
        panelInstallers: {store},
    }) => {
        const panelInstallersByIds = get(store, [panelId], {})
        const panelInstallersIds = Object.keys(panelInstallersByIds).map((id) =>
            Number(id)
        )

        return Object.values(byIds)
            .filter(({id}) => {
                return !panelInstallersIds.includes(id)
            })
            .map(({id, email}) => ({value: id, label: email}))
    },
    10,
    true
)(Autocomplete)

class AssignInstallerToPanel extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        panel: PropTypes.shape({
            id: PropTypes.number.isRequired,
            serial: PropTypes.string.isRequired,
        }).isRequired,
        installer: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),

        installers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ),
    }

    rules = {
        installerId: {
            presence: true,
        },
        pin: {
            numericality: true,
            length: {
                minimum: 4,
                maximum: 8,
            },
        },
    }

    getInstallerId = (installerIdForm) => {
        const {installer} = this.props

        if (installer) {
            return installer.id
        }

        return installerIdForm
    }

    handle = ({installerId}) => {
        const {panelId, handle} = this.props

        handle({
            panelId,
            installerId: this.getInstallerId(installerId),
        })
    }

    get header() {
        const {panel} = this.props

        return __('Assign installer to panel %s', panel.serial)
    }

    renderSelectedInteractiveUser = () => {
        const {installer} = this.props

        return (
            <Autocomplete
                label={__('Installer')}
                name="installerId"
                disabled
                defaultValue={installer.id}
                defaultLabel={installer.email}
            />
        )
    }

    renderInteractiveUsersAutocomplete = () => {
        const {installer} = this.props

        if (!isNil(installer)) {
            return this.renderSelectedInteractiveUser()
        }

        return <InstallersSelect label={__('Installer')} name="installerId" />
    }

    render() {
        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={this.header}
                rules={this.rules}
                confirmOnSubmit
                {...this.props}
            >
                {this.renderInteractiveUsersAutocomplete()}
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(assignInstallerToPanel),
    connect(
        (
            {
                installers: {
                    store: {byIds},
                },
                installerPanels: {list},
                panels,
                panelInstallers: {store},
            },
            {panelId}
        ) => ({
            installer: byIds[list.installerId],
            panel: panels.store.byIds[panelId],
        })
    )
)(AssignInstallerToPanel)
