import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash-es/isUndefined'
import has from 'lodash-es/has'

import Input from 'ipmp-react-ui/Input'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {ReactComponent as LockIcon} from 'ipmp-react-ui/icons/away.svg'

import {__} from 'utils/i18n'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {
    CS_CONNECTION_TYPE_HTTP,
    CS_CONNECTION_TYPE_TCP,
    CS_CONNECTION_TYPE_SERIAL,
    parseHttpFields,
    CS_CONNECTION_SSL_TLS_1_3,
    CS_CONNECTION_SSL_TLS_1_2,
    CS_CONNECTION_SSL_NONE,
    isHttpConnectionType,
    isTcpConnectionType,
    isSerialConnectionType,
    isFibroConnectionType,
    isFibroProtocol,
    connectionTypes,
    connectionTypeTitle,
    CS_CONNECTION_TYPE_FIBRO,
} from 'constants/centralStation'
import classes from 'classnames'
import SelectSslOptions from 'modals/CentralStations/SelectSslOptions'
import {clear} from 'modules/forms/actions'
import {warn} from 'utils/log'
import {useActions} from 'modules/higherOrder/useActions'

function connectionFieldsPresenceResolve(value, attributes) {
    return (
        attributes.connectionType === CS_CONNECTION_TYPE_TCP ||
        attributes.connectionType === CS_CONNECTION_TYPE_HTTP ||
        !has(attributes, 'connectionType')
    )
}

function serialIdPresenceResolve(value, attributes) {
    return attributes.connectionType === CS_CONNECTION_TYPE_SERIAL
}

export const rules = {
    name: {
        presence: true,
        format: {
            pattern: /^([-.\w\s]){1,50}$/,
            flags: 'i',
            message: () => __('CS name is not in valid format'),
        },
        length: {
            minimum: 1,
            maximum: 50,
        },
    },
    protocolId: {
        numericality: true,
    },
    heartBeat: {
        numericality: {
            greaterThanOrEqualTo: 10,
            lessThanOrEqualTo: 255,
        },
    },
    retryTime: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 5,
            lessThanOrEqualTo: 60,
        },
    },
    retryCount: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 5,
        },
    },
    serialPortId: {
        presence: serialIdPresenceResolve,
    },
    host: {
        presence: connectionFieldsPresenceResolve,
        host: true,
    },
    port: {
        presence: connectionFieldsPresenceResolve,
        numericality: {
            greaterThanOrEqualTo: 80,
            lessThanOrEqualTo: 65535,
        },
    },
    ssl: {
        inclusion: [
            CS_CONNECTION_SSL_NONE,
            CS_CONNECTION_SSL_TLS_1_2,
            CS_CONNECTION_SSL_TLS_1_3,
        ],
    },
    receiver: {
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 99,
        },
    },
    line: {
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9,
        },
    },
    path: {
        format: {
            pattern: /^\/[a-zA-Z0-9/\-.,:;?=&%+_#]*$/i,
            flags: 'i',
            message: () => __('URL path is not in valid format'),
        },
    },
    user: {
        presence: (value, attributes) => {
            return attributes.password
        },
        userName: true,
    },
    password: {
        length: {
            minimum: 3,
            maximum: 100,
        },
    },
}

const CentralStationFormFields = ({protocols, serialPorts, data, isNew}) => {
    const [connectionType, setConnectionType] = useState(data.connectionType)
    const [isAdvanced, setIsAdvanced] = useState(false)
    const [isHeartBeat, setIsHeartBeat] = useState(data.isHeartBeat)
    const [selectedProtocolId, setSelectedProtocolId] = useState(data.protocolId)
    const [host, setHost] = useState(data.host || '')
    const [path, setPath] = useState(data.path || '')
    const [port, setPort] = useState(data.port || '')
    const [isSecurity, setIsSecurity] = useState(data.ssl === CS_CONNECTION_SSL_TLS_1_3)
    const [ssl, setSsl] = useState(data.ssl || CS_CONNECTION_SSL_NONE)

    const {clear: clearForm} = useActions({clear})
    useEffect(() => {
        return () => clearForm()
    }, [])

    const handleHostBlur = (e) => {
        const oldValues = {
            host: host || '',
            port: port || '',
            path: path || '',
            ssl: ssl || CS_CONNECTION_SSL_NONE,
            isSecurity: isSecurity || false,
        }
        const parsedData = parseHttpFields(e.target.value, oldValues)
        setHost(parsedData.host)
        setPort(parsedData.port)
        setPath(parsedData.path)
        setSsl(parsedData.ssl)
        setIsSecurity(parsedData.isSecurity)
    }

    const handleHostChange = (e) => {
        setHost(e.target.value.trim())
        clearForm()
    }

    const handlePathChange = (e) => {
        setPath(e.target.value)
    }

    const handlePortChange = (e) => {
        setPort(e.target.value)
        clearForm()
    }

    const handleProtocolChange = (e, value) => {
        if (isFibroProtocol(value)) {
            setConnectionType(CS_CONNECTION_TYPE_FIBRO)
        } else if (isFibroConnectionType(connectionType)) {
            setConnectionType(CS_CONNECTION_TYPE_TCP)
        }
        setSelectedProtocolId(parseInt(value))
    }

    const handleConnectionTypeChange = (e, value) => {
        setConnectionType(value)
    }

    const handleAdvancedCheckboxChange = (e) => {
        setIsAdvanced(e.target.checked)
    }

    const toggleHeartBeat = (e) => {
        setIsHeartBeat(e.target.checked)
    }

    const handleSelectSsl = (e, value) => {
        let isSecurity = false
        if (value === CS_CONNECTION_SSL_TLS_1_3) {
            isSecurity = true
        }
        setIsSecurity(isSecurity)
        setSsl(value)
    }

    const isAdvancedToggler = () => {
        if (isUndefined(selectedProtocolId)) {
            return false
        }

        const protocolName = protocols.find(
            ({id}) => id === Number(selectedProtocolId)
        ).name

        // Only MLR protocols has Advanced options
        return /MLR/.test(protocolName)
    }

    const errorKey = (attr) => {
        switch (true) {
            case isHttpConnectionType(connectionType):
                return `http_settings.tcp_settings.${attr}`
            case isTcpConnectionType(connectionType):
                return `tcp_settings.${attr}`
            case isFibroConnectionType(connectionType):
                return `fibro_settings.${attr}`
            default:
                warn('Unknown connection type')
        }
    }

    const isHttp = isHttpConnectionType(connectionType)
    const isTcp = isTcpConnectionType(connectionType)
    const isSerial = isSerialConnectionType(connectionType)
    const isFibro = isFibroConnectionType(connectionType)

    return (
        <div>
            <Input
                autoFocus
                readOnly={!isNew}
                label={__('Name')}
                name="name"
                defaultValue={data.name}
                dataTestId="name"
            />

            <Select
                name="protocolId"
                defaultValue={data.protocolId}
                label={__('Protocol')}
                onChange={handleProtocolChange}
                dataTestId="protocol-id"
            >
                {protocols.map(({id, name}) => (
                    <Option key={id} label={name} value={id} />
                ))}
            </Select>

            {!isFibro ? (
                <>
                    <Checkbox
                        name="isHeartBeat"
                        onChange={toggleHeartBeat}
                        label={__('Heart beat')}
                        checked={isHeartBeat}
                        dataTestId="heartbeat-checkbox"
                    />

                    {isHeartBeat && (
                        <Input
                            type="number"
                            label={__('Heart beat')}
                            name="heartBeat"
                            defaultValue={data.heartBeat}
                            dataTestId="heartbeat"
                        />
                    )}
                </>
            ) : null}

            <Input
                type="number"
                label={__('Retry time')}
                name="retryTime"
                defaultValue={data.retryTime}
                dataTestId="retry-time"
            />

            <Input
                type="number"
                label={__('Retry count')}
                name="retryCount"
                defaultValue={data.retryCount}
                dataTestId="retry-count"
            />

            <Select
                name="connectionType"
                defaultValue={connectionType}
                onChange={handleConnectionTypeChange}
                label={__('Connection Type')}
                value={isFibro ? CS_CONNECTION_TYPE_FIBRO : connectionType}
                dataTestId="connection-type"
            >
                {isFibro ? (
                    <Option
                        label={connectionTypeTitle(CS_CONNECTION_TYPE_FIBRO)}
                        value={connectionType}
                    />
                ) : (
                    connectionTypes.map((connectionType, index) => (
                        <Option
                            label={connectionTypeTitle(connectionType)}
                            value={connectionType}
                            key={index}
                        />
                    ))
                )}
            </Select>

            {(isHttp || isTcp || isFibro) && (
                <div className={'connection-type-host-wrapper'}>
                    <Input
                        label={__('Host')}
                        name="host"
                        errorkey={errorKey('host')}
                        value={host}
                        onBlur={handleHostBlur}
                        onChange={handleHostChange}
                        className={classes({
                            'connection-type-host': isSecurity,
                        })}
                        dataTestId="host"
                    />
                    {isSecurity && <LockIcon className={'input-lock-icon'} />}
                </div>
            )}
            {isHttp && (
                <Input
                    label={__('Path')}
                    type="string"
                    name="path"
                    errorkey="http_settings.path"
                    onChange={handlePathChange}
                    value={path ? path : ''}
                    dataTestId="path"
                />
            )}
            {(isHttp || isTcp || isFibro) && (
                <Input
                    label={__('Port')}
                    type="number"
                    name="port"
                    errorkey={errorKey('port')}
                    onChange={handlePortChange}
                    value={port ? port : ''}
                    dataTestId="port"
                />
            )}
            {isSerial && (
                <Select
                    label={__('Serial Port')}
                    name="serialPortId"
                    errorkey="serial_settings.dev_id"
                    defaultValue={parseInt(data.serialPortId)}
                    dataTestId="serial-port-id"
                >
                    {serialPorts.length > 0 &&
                        serialPorts.map(({id, name}) => (
                            <Option key={id} label={name} value={id} />
                        ))}
                    {serialPorts.length === 0 && (
                        <Option disabled label={__('No ports configured on server')} />
                    )}
                </Select>
            )}
            {isHttp && (
                <Input
                    label={__('User')}
                    name="user"
                    errorkey="http_settings.auth.user"
                    type={'string'}
                    defaultValue={data.user ? data.user : ''}
                    dataTestId="user"
                />
            )}

            {isHttp && (
                <Input
                    label={isNew ? __('Password') : __('New password')}
                    name="password"
                    errorkey="http_settings.auth.password"
                    type={'password'}
                    autoComplete="new-password"
                    dataTestId="password"
                />
            )}
            {(isHttp || isTcp) && (
                <SelectSslOptions
                    ssl={ssl}
                    errorkey={
                        isHttp
                            ? 'http_settings.tcp_settings.encryption_mode'
                            : 'tcp_settings.encryption_mode'
                    }
                    isSecurity={isSecurity}
                    handleSelectSsl={handleSelectSsl}
                />
            )}
            {isAdvancedToggler() && (
                <Checkbox
                    onChange={handleAdvancedCheckboxChange}
                    label={__('Advanced options')}
                    dataTestId="advanced-options"
                />
            )}

            {isAdvancedToggler() && isAdvanced && (
                <Input
                    type="number"
                    key="receiver"
                    label={__('Receiver')}
                    name="receiver"
                    defaultValue={data.receiver}
                    dataTestId="receiver"
                />
            )}

            {isAdvancedToggler() && isAdvanced && (
                <Input
                    type="number"
                    key="line"
                    label={__('Line')}
                    name="line"
                    defaultValue={data.line}
                    dataTestId="line"
                />
            )}
        </div>
    )
}

CentralStationFormFields.propTypes = {
    data: PropTypes.object,
    serialPorts: PropTypes.array,
    isNew: PropTypes.bool,
    protocols: PropTypes.array,
}

export default CentralStationFormFields
