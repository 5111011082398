import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Bar from 'ipmp-react-ui/Bar'

import Search from 'components/Search/Search'
import {useInteractiveUserNonAssignedPanels} from 'hooks/interactiveUser'

import Table from './Table'
import FooterContent from './FooterContent'

export default function AssignPanels({user: {id}, setFooterContent}) {
    const {rows, isLoading, fetch, setQuery, reset} =
        useInteractiveUserNonAssignedPanels(id)

    useEffect(() => {
        if (id) {
            setFooterContent(<FooterContent />)
        }

        return reset
    }, [id])

    return (
        <>
            <Bar divider className="blindModal-card-content-search">
                <Search
                    apply={fetch}
                    onQuery={setQuery}
                    filters={[]}
                    selected={[]}
                    hideAutoCompleteAfterQuery={true}
                />
            </Bar>
            <Table
                fetch={fetch}
                isLoading={isLoading}
                rows={rows}
                interactiveUserId={id}
            />
        </>
    )
}

AssignPanels.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    setFooterContent: PropTypes.func.isRequired,
}
