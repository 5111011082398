import findIndex from 'lodash-es/findIndex'
import has from 'lodash-es/has'

export default function getPrevNext(
    val,
    categorizedCollection,
    categoryOrder,
    field = 'id'
) {
    return [
        getPrev(val, categorizedCollection, categoryOrder, field),
        getNext(val, categorizedCollection, categoryOrder, field),
    ]
}

const getPrev = (val, categorizedCollection, categoryOrder, field) => {
    const itemInCategory = findInCategory(val, categorizedCollection, field)

    if (!itemInCategory) {
        return null
    }

    const {categoryName, index} = itemInCategory
    const isFirstItemInCategory = index === 0

    if (isFirstItemInCategory) {
        return prevCategoryLastItemId(
            categoryName,
            categorizedCollection,
            categoryOrder,
            field
        )
    }

    return getCategoryByKey(categorizedCollection, categoryName)[index - 1][field]
}

const findInCategory = (val, categorizedCollection, field) => {
    const result = getKeys(categorizedCollection).reduce(
        (acc, categoryName) => {
            const itemIndex = findIndex(
                getCategoryByKey(categorizedCollection, categoryName),
                {[field]: val}
            )

            if (itemIndex === -1) {
                return acc
            }

            acc.categoryName = categoryName
            acc.index = itemIndex
            return acc
        },
        {categoryName: null, index: null}
    )

    if (result.categoryName === null || result.index === null) {
        return null
    }

    return result
}
const prevCategoryLastItemId = (
    currentCategory,
    categorizedCollection,
    categoryOrder,
    field
) => {
    const categories = categoryOrder.filter((group) =>
        hasCategorizedCollectionKey(categorizedCollection, group)
    )
    const currentCategoryIndex = categories.indexOf(currentCategory)

    if (currentCategoryIndex === 0) {
        return null
    }

    const prevCategoryName = categories[currentCategoryIndex - 1]

    const prevCategoryLastDeviceIndex =
        getCategoryByKey(categorizedCollection, prevCategoryName).length - 1

    return getCategoryByKey(categorizedCollection, prevCategoryName)[
        prevCategoryLastDeviceIndex
    ][field]
}

const getNext = (id, categorizedCollection, categoryOrder, field) => {
    if (id === null) {
        return getFirstItemId(categorizedCollection, categoryOrder, field)
    }

    const deviceInCategory = findInCategory(id, categorizedCollection, field)

    if (!deviceInCategory) {
        return null
    }

    const {categoryName, index} = deviceInCategory
    const isLastDeviceInCategory =
        index === getCategoryByKey(categorizedCollection, categoryName).length - 1

    if (isLastDeviceInCategory) {
        return nextCategoryFirstItemId(
            categoryName,
            categorizedCollection,
            categoryOrder,
            field
        )
    }

    return getCategoryByKey(categorizedCollection, categoryName)[index + 1][field]
}

const getFirstItemId = (categorizedCollection, categoryOrder, field) => {
    const category = categoryOrder.find((category) => {
        return (
            getCategoryByKey(categorizedCollection, category) &&
            getCategoryByKey(categorizedCollection, category).length
        )
    })
    return category ? getCategoryByKey(categorizedCollection, category)[0][field] : null
}

const nextCategoryFirstItemId = (
    currentCategory,
    categorizedCollection,
    categoryOrder,
    field
) => {
    const categories = categoryOrder.filter((group) =>
        hasCategorizedCollectionKey(categorizedCollection, group)
    )
    const currentCategoryIndex = categories.indexOf(currentCategory)

    if (currentCategoryIndex === categories.length - 1) {
        return null
    }

    const nextCategoryName = categories[currentCategoryIndex + 1]

    return getCategoryByKey(categorizedCollection, nextCategoryName)[0][field]
}

const getKeys = (categorizedCollection) => {
    if (categorizedCollection instanceof Map) {
        return Array.from(categorizedCollection.keys())
    }

    return Object.keys(categorizedCollection)
}
const getCategoryByKey = (categorizedCollection, key) => {
    if (categorizedCollection instanceof Map) {
        return categorizedCollection.get(key)
    }

    return categorizedCollection[key]
}
const hasCategorizedCollectionKey = (categorizedCollection, key) => {
    if (categorizedCollection instanceof Map) {
        return categorizedCollection.has(key)
    }

    return has(categorizedCollection, key)
}
