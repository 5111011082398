import React from 'react'
import __ from 'utils/i18n'
import Select, {Option} from 'ipmp-react-ui/Select'

const PANEL_TYPE_PSP_CLASSIC = 'PT_POWER_SERIES_CLASSIC'
const PANEL_TYPE_PSP_NEO = 'PT_POWER_SERIES_NEO'
const PANEL_TYPE_PSP_CLASSIC_LABEL = 'Power Series Classic'
const PANEL_TYPE_PSP_NEO_LABEL = 'Power Series Neo'

export const panelTypes = [PANEL_TYPE_PSP_CLASSIC, PANEL_TYPE_PSP_NEO]

const getDlsPanelTypeLabel = (panelType) => {
    switch (panelType) {
        case PANEL_TYPE_PSP_CLASSIC:
            return PANEL_TYPE_PSP_CLASSIC_LABEL
        case PANEL_TYPE_PSP_NEO:
            return PANEL_TYPE_PSP_NEO_LABEL
        default:
            return null
    }
}

export const SelectPanelTypes = () => {
    const options = panelTypes.map((panelType, key) => (
        <Option key={key} value={panelType} label={getDlsPanelTypeLabel(panelType)} />
    ))
    return (
        <Select name="panelType" label={__('Panel type')}>
            {options}
        </Select>
    )
}
