import React from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import CustomerFieldSet, {validation} from './Fieldset/Customer'
import {connect} from 'react-redux'
import {compose} from 'redux'
import withForm from 'containers/withForm'
import {editPanelCustomerInfo} from 'modules/forms/handlers'

const EditPanelCustomerInfo = ({
    panel,
    panelId,
    handle,
    googleApiError,
    googleToken,
    ...props
}) => {
    const handleForm = (data) => {
        handle(panelId, data)
    }

    return (
        <ModalCardForm
            onSubmit={handleForm}
            rules={validation}
            header={__('Edit Panel Owner Info')}
            confirmOnDismiss
            {...props}
        >
            <CustomerFieldSet
                data={panel ? panel.contact : {}}
                googleToken={googleToken}
                googleApiError={googleApiError}
            />
        </ModalCardForm>
    )
}

export default compose(
    connect(({panels, serverSettings}, {panelId}) => ({
        panel: panels.store.byIds[panelId] || {},
        googleToken: serverSettings.google.token,
    })),
    withForm(editPanelCustomerInfo)
)(EditPanelCustomerInfo)
