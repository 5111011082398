import _get from 'lodash-es/get'
import _forEach from 'lodash-es/forEach'
import _camelCase from 'lodash-es/camelCase'

import dataMapper from 'utils/dataMapper'

export const mapValidatorCommunicatorError = (errors) => {
    if (errors.details && errors.details.communications) {
        _forEach(errors.details.communications, (val, key) => {
            errors.details[_camelCase(key)] = val
        })
        delete errors.details.communications
    }
    return errors
}

export const mapReceiveGroup = (group) => ({
    ...mapGroup(group),
    videoFormatId: group.vof_id,
})

export const keysMap = {}

export const mapGroupCSLink = dataMapper((link) => ({
    profileId: parseInt(link.etp_id),
    centralStationId: parseInt(link.cls_id),
}))

export const mapLanguage = dataMapper((data) => ({
    id: parseInt(data.ugl_id),
    name: data.ugl_name,
    short: data.ugl_short,
    locale: data.ugl_locale,
    isDefault: data.ugl_default === 'y',
}))

export const mapGroup = dataMapper((group) => ({
    ...group,
    communications: _get(group, 'communications', {}),
    links: group?.links?.map((link) => mapGroupCSLink(link)) || [],
}))

export const mapCustomization = dataMapper(
    ({
        apc_id: id,
        apc_name: name,
        apc_is_default: isDefault,
        apc_created: created,
        apc_changed: changed,
        apc_data: data,
    }) => ({
        id,
        name,
        isDefault,
        created,
        changed,
        data,
    })
)
