import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class CommonConnected extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            systemIdRegex: PropTypes.string,
            isPanelRemoveForever: PropTypes.bool,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <>
                {this.renderListItem(__("System ID's Mask"), data.systemIdRegex)}
                {this.renderListItem(
                    __('Remove Panel by Resetting the Receiver IP'),
                    data.isPanelRemoveForever
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {commonConnected},
            },
        }) => ({...commonConnected})
    )
)(CommonConnected)
