import React from 'react'
import PropTypes from 'prop-types'

import Bar from 'ipmp-react-ui/Bar'

import Panels from './Panels'
import Top from './Top'

export default function FirstBlindContent({
    row,
    openSecondBlind,
    setFooterContent,
    closeBlind,
}) {
    return (
        <>
            <Top user={row} />
            <Bar divider />
            <Panels
                user={row}
                addPanel={openSecondBlind}
                setFooterContent={setFooterContent}
                closeBlind={closeBlind}
            />
        </>
    )
}

FirstBlindContent.propTypes = {
    row: PropTypes.object.isRequired,
    openSecondBlind: PropTypes.func.isRequired,
    setFooterContent: PropTypes.func.isRequired,
    closeBlind: PropTypes.func.isRequired,
}
