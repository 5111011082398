import {all, takeEvery, call, put} from 'redux-saga/effects'
import * as api from 'api/group'
import * as actions from 'modules/groups/customizations/actions'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.fetchAll, watchFetchAll),
        takeEvery(actions.fetchOne, watchFetchOne),
        takeEvery(actions.persistOne, watchPersistOne),
    ])
}

function* watchFetchAll() {
    try {
        const availableCustomizations = yield call(api.receiveAllGroupCustomizations)
        yield put(actions.receiveAll(availableCustomizations))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchFetchOne({payload: groupId}) {
    try {
        const groupCustomization = yield call(api.receiveGroupCustomization, groupId)
        yield put(actions.receiveOne(groupId, groupCustomization))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchPersistOne({payload: {groupId, customizationId}}) {
    try {
        yield call(api.persistGroupCustomization, groupId, customizationId)
        yield put(actions.updatePersisted(groupId, customizationId))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
