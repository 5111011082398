import React from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import ModalCardForm from 'ui/ModalCardForm'
import CentralStationFormFields, {rules} from './CentralStationFormFields'
import AddCentralStationLimitExceeded from './AddCentralStationLimitExceeded'

import withInterruption from 'containers/withInterruption'
import withForm from 'containers/withForm'
import {addCentralStation} from 'modules/forms/handlers'
import {__} from 'utils/i18n'

import {fetchCanAdd} from 'modules/centralStations/licensing/actions'
import {fetch as fetchSerialPorts} from 'modules/centralStations/serialPorts/actions'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'

import withModalLoader from 'containers/withModalLoader'
import {selectAddCentralStation} from 'modules/centralStations/store/selectors'

const defaultData = {
    isHeartBeat: true,
    heartBeat: 25,
    retryTime: 10,
    retryCount: 4,
    connectionType: 'tcp',
    ssl: '',
    receiver: 33,
    line: 2,
}

const AddCentralStation = ({
    handle,
    hide,
    isLoading,
    serialPorts,
    protocols,
    ...props
}) => {
    const onSubmit = (data) => {
        if (!data.isHeartBeat) {
            data.heartBeat = 0
        }

        handle(data)
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            rules={rules}
            header={__('Create Central Station')}
            hide={hide}
            isLoading={isLoading}
            onSubmit={onSubmit}
            {...props}
        >
            <CentralStationFormFields
                isNew
                data={defaultData}
                serialPorts={serialPorts}
                protocols={protocols}
            />
        </ModalCardForm>
    )
}

AddCentralStation.propTypes = {
    hide: PropTypes.func.isRequired,
    handle: PropTypes.any,
    serials: PropTypes.any,
    protocols: PropTypes.any,
    isLoading: PropTypes.bool,
    serialPorts: PropTypes.array,
}

export default compose(
    connect(selectAddCentralStation, (dispatch) =>
        bindActionCreators(
            {
                fetchSerialPorts,
                fetchProtocols,
                fetchCanAdd,
            },
            dispatch
        )
    ),
    withModalLoader(({fetchSerialPorts, fetchProtocols, fetchCanAdd}) => {
        fetchSerialPorts()
        fetchProtocols()
        fetchCanAdd()
    }),
    withInterruption(
        ({creatingAllowed}) => !creatingAllowed,
        AddCentralStationLimitExceeded
    ),
    withForm(addCentralStation)
)(AddCentralStation)
