import {bindActionCreators} from 'redux'
import {changePerPage} from 'modules/settings/actions'
import {selectPerPage} from 'modules/settings/selectors'
import {useDispatch, useSelector} from 'react-redux'

export default function usePerPage(name) {
    const dispatch = useDispatch()

    return {
        ...bindActionCreators(
            {
                onPerPageChange: (perPage) => changePerPage({[name]: perPage}),
            },
            dispatch
        ),
        perPage: useSelector((state) => selectPerPage(state, name)),
    }
}
