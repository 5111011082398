import {createAction} from 'redux-actions'

import {fetchEventProfiles as doFetch} from 'api/centralStations'

export const setLoading = createAction(
    'CENTRAL_STATIONS/PROFILES/SET_LOADING',
    (isLoading = true) => ({isLoading})
)
export const setError = createAction(
    'CENTRAL_STATIONS/PROFILES/SET_ERROR',
    (isError = true, message) => ({isError, message})
)
export const clear = createAction('CENTRAL_STATIONS/PROFILES/CLEAR')
export const update = createAction('CENTRAL_STATIONS/PROFILES/UPDATE', (profiles) => ({
    profiles,
}))

export function fetch() {
    return async (dispatch, getState) => {
        const {byId} = getState().centralStations.profiles

        byId.length === 0 && dispatch(setLoading())

        try {
            const profiles = await doFetch()
            dispatch(update(profiles))
            dispatch(setError(false))
            dispatch(setLoading(false))
        } catch ({message}) {
            dispatch(setLoading(false))
            dispatch(setError(true, message))
        }
    }
}
