import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'

import Info from './Info'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'
import SettingsCard from 'components/Settings/SettingsCard'
import {showSystemSettingsAdministratorInfoModal} from 'modules/modals/actions'
import __ from 'utils/i18n'
import Layout from 'ipmp-react-ui/Layout'

export class AdministratorInfo extends Component {
    render() {
        const {administratorInfoModal} = this.props
        return (
            <Layout>
                <ScrollView>
                    <SettingsCard
                        title={__('Administrator info')}
                        onEditAction={administratorInfoModal}
                    >
                        <Info />
                    </SettingsCard>
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {administratorInfo},
            },
        }) => ({
            administratorInfo,
            isLoading: administratorInfo.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    fetchAdministratorInfo: actions.fetchAdministratorInfo,
                    showSystemSettingsAdministratorInfoModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                administratorInfoModal: () =>
                    dispatchProps.showSystemSettingsAdministratorInfoModal(
                        stateProps.administratorInfo
                    ),
            }
        }
    ),
    withLoader(({fetchAdministratorInfo}) => {
        fetchAdministratorInfo()
    })
)(AdministratorInfo)
