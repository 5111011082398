import Input from 'ipmp-react-ui/Input'
import PropTypes from 'prop-types'
import React from 'react'
import {__} from 'utils/i18n'

export default class Serial extends React.Component {
    static propTypes = {
        isNew: PropTypes.bool,
        serial: PropTypes.string,
    }

    get label() {
        return this.props.label ?? __('Panel Id')
    }

    render() {
        const {isNew, serial} = this.props

        return (
            <Input
                readOnly={!isNew}
                label={this.label}
                name="serial"
                defaultValue={serial}
                dataTestId="serial"
            />
        )
    }
}
