import {get, post} from './http'
import {mapProcess} from 'api/processes'
import {keysMap, mapHad, mapIsSwitchable} from 'api/had.map'
import {
    HAD_COMMAND_OUTPUT,
    HAD_COMMAND_SET_DIM,
    HAD_COMMAND_SET_THERMOSTAT_MODE,
    HAD_COMMAND_SET_THERMOSTAT_TARGET,
} from 'constants/had/command'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {
    resolveDimmer,
    resolveHadOutput,
    resolveThermostatMode,
    resolveThermostatTarget,
} from 'api/had/resolve'

export function getHadList(panelId) {
    return get(`unit/${panelId}/had`).then(mapHad)
}

export function setHadOn({panelId, id: pgmId, batchId, deviceType, timestamp}) {
    return post(`unit/${panelId}/had/${pgmId}/on`, {
        timestamp,
        batch_id: batchId,
        device_type: deviceType,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function setHadOff({panelId, id: pgmId, deviceType, batchId}) {
    return post(`unit/${panelId}/had/${pgmId}/off`, {
        batch_id: batchId,
        device_type: deviceType,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function setDimmerLevel({panelId, hadId, dimmerLevel, deviceType, batchId}) {
    return post(`unit/${panelId}/had/${hadId}/dimmer`, {
        value: dimmerLevel,
        batch_id: batchId,
        device_type: deviceType,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function setThermostatTarget({
    panelId,
    hadId,
    mode,
    temperature,
    deviceType,
    batchId,
}) {
    return post(`unit/${panelId}/had/${hadId}/thermostat/target`, {
        mode,
        temperature,
        batch_id: batchId,
        device_type: deviceType,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function setThermostatMode({panelId, hadId, mode, deviceType, batchId}) {
    return post(`unit/${panelId}/had/${hadId}/thermostat/mode`, {
        mode,
        batch_id: batchId,
        device_type: deviceType,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function isPanelsHadSwitchable(panelId) {
    return get(`unit/${panelId}/isSwitchable`).then(mapIsSwitchable)
}

export function setLabel({panelId, hadId, deviceType, label}, batchId) {
    return post(`unit/${panelId}/had/${hadId}/label`, {
        label: label || '',
        device_type: deviceType,
        batch_id: batchId,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function setIcon({panelId, hadId, deviceType, icon}, batchId) {
    return post(`unit/${panelId}/had/${hadId}/icon`, {
        icon,
        device_type: deviceType,
        batch_id: batchId,
    })
        .then(mapProcess)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function setHadCommand({commands, params}, dispatch, getState) {
    return Promise.all(
        commands.map(async (hadCommand) => {
            switch (hadCommand.command) {
                case HAD_COMMAND_OUTPUT:
                    return resolveHadOutput({
                        command: hadCommand,
                        params,
                        dispatch,
                        getState,
                    })
                case HAD_COMMAND_SET_THERMOSTAT_TARGET:
                    return resolveThermostatTarget({
                        command: hadCommand,
                        params,
                        dispatch,
                        getState,
                    })
                case HAD_COMMAND_SET_THERMOSTAT_MODE:
                    return resolveThermostatMode({
                        command: hadCommand,
                        params,
                        dispatch,
                        getState,
                    })
                case HAD_COMMAND_SET_DIM:
                    return await resolveDimmer({
                        command: hadCommand,
                        params,
                        dispatch,
                        getState,
                    })
            }
        })
    ).then((result) => {
        for (const resultKey in result) {
            const resultValue = result[resultKey]

            if (resultValue instanceof Error) {
                throw resultValue
            }
        }

        return result
    })
}
