import React from 'react'

import {SendEmailButtonBase} from 'pages/RemoteInspection/Content/Result/Footer/SendEmailButton'
import {ReactComponent as EmailIcon} from 'ipmp-react-ui/icons/email.svg'
import __ from 'utils/i18n'
import {usePanelRemoteInspectionsResult} from 'hooks/pages/useRemoteInspectionsResult'

export default function ({remoteInspectionResult}) {
    const {sendEmail, resendEmail} =
        usePanelRemoteInspectionsResult(remoteInspectionResult)

    return (
        <SendEmailButtonBase
            Icon={EmailIcon}
            flat
            onClick={
                remoteInspectionResult && remoteInspectionResult.isEmailSent
                    ? resendEmail
                    : sendEmail
            }
            disabled={!remoteInspectionResult}
            label={
                remoteInspectionResult && remoteInspectionResult.isEmailSent
                    ? __('Resend email')
                    : __('Send email')
            }
        />
    )
}
