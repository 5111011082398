import React from 'react'
import {Route} from 'react-router'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import tabs, {CENTRAL_STATION_TAB_COMMUNICATION} from './CentralStationRouting'
import path from 'utils/path'
import {useSelector} from 'react-redux'
import {oneCentralStationDataSelect} from 'modules/centralStations/one/selectors'
import CommunicationBar from 'pages/CentralStation/Communication/CommunicationBar'
import usePermission from 'hooks/usePermission'

const filtrateProhibition = (key) => {
    const {isVisiblePermission} = tabs.get(key)
    const {isPermitted} = usePermission(isVisiblePermission)

    return isPermitted
}

const CentralStationTop = ({match: {params}}) => {
    const oneCentralStationData = useSelector((state) =>
        oneCentralStationDataSelect(state, {id: params?.id})
    )

    const links = [...tabs.keys()]

    return (
        <NavTabs
            className="central-station-tabs"
            title={oneCentralStationData?.name}
            goBack={path('system.centralStations')}
        >
            {links.filter(filtrateProhibition).map((key) => {
                const {label, path: pathName, exact} = tabs.get(key)

                return (
                    <NavTabLink key={key} to={path(pathName, params)} exact={exact}>
                        {label()}
                    </NavTabLink>
                )
            })}
            <Route
                path={path(tabs?.get(CENTRAL_STATION_TAB_COMMUNICATION)?.path)}
                component={CommunicationBar}
            />
        </NavTabs>
    )
}

export default CentralStationTop
