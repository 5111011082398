import React from 'react'
import PropTypes from 'prop-types'

import ResetPasswordButton from './ResetPasswordButton'
import RemoveInstallerButton from './RemoveInstallerButton'

const CardActions = ({installer, onClose}) => {
    return (
        <div className="blindModal-card-actions-buttons">
            <RemoveInstallerButton installer={installer} onClose={onClose} />
            <ResetPasswordButton installer={installer} />
        </div>
    )
}

CardActions.propTypes = {
    installer: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default CardActions
