import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {
    BlindModalCard,
    BlindModalCardActions,
    BlindModalCardContent,
    CardHeaderBlindModal,
    CardHeaderClose,
} from 'ipmp-react-ui/Card'

import Paginator from './Paginator'

export default function FirstBlind({
    rows,
    current,
    onChange,
    closeBlind,
    openSecondBlind,
    Content,
}) {
    const [footerContent, setFooterContent] = useState(null)

    return (
        <BlindModalCard>
            <CardHeaderBlindModal>
                <Paginator rows={rows} current={current} onChange={onChange} />
                <CardHeaderClose onClick={closeBlind} />
            </CardHeaderBlindModal>
            <BlindModalCardContent>
                <Content
                    row={current}
                    openSecondBlind={openSecondBlind}
                    setFooterContent={setFooterContent}
                    closeBlind={closeBlind}
                />
            </BlindModalCardContent>
            {footerContent && (
                <BlindModalCardActions>{footerContent}</BlindModalCardActions>
            )}
        </BlindModalCard>
    )
}

FirstBlind.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object.isRequired),
    current: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    closeBlind: PropTypes.func,
    openSecondBlind: PropTypes.func,
    Content: PropTypes.func.isRequired,
}
