import React from 'react'
import PropTypes from 'prop-types'
import {Route, Switch} from 'react-router-dom'

import path from 'utils/path'

import General from './General/General'
import Stations from './Stations/Stations'
import Notifications from './Profiles/Notifications'
import Customizations from 'pages/Group/Customizations/Customizations'

export default function GroupContent({group}) {
    return (
        <Switch>
            <Route
                path={path('system.group.general')}
                exact
                component={() => <General {...{group}} />}
            />
            <Route
                path={path('system.group.stations')}
                exact
                component={() => <Stations {...{group}} />}
            />
            <Route
                path={path('system.group.notifications')}
                exact
                component={() => <Notifications {...{group}} />}
            />
            <Route
                path={path('system.group.customizations')}
                exact
                component={() => <Customizations {...{group}} />}
            />
        </Switch>
    )
}

GroupContent.propTypes = {
    group: PropTypes.object,
}
