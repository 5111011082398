import React, {useEffect} from 'react'
import {compose} from 'redux'

import list from 'permissions/roles/list'
import selection from 'permissions/roles/selection'
import {edit as editable, showPermissions} from 'permissions/roles/actions'

import withSelection from 'containers/withSelection'
import withLoader from 'containers/withLoader'
import {withRejection} from 'containers/withPermission'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'
import {__} from 'utils/i18n'

import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'

import RoleGroupCell from './TableCell/RoleGroupCell'
import RolePermissionsCell from './TableCell/RolePermissionsCell'
import EditRoleCell from './TableCell/EditRoleCell'
import usePermission from 'hooks/usePermission'
import useRoles from 'hooks/pages/useRoles'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

const allColumns = [
    {
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        name: () => __('Parent Role'),
        render: ({parentName}) => parentName,
    },
    {
        name: () => __('Created by'),
        render: ({userName}) => (
            <Definition
                Icon={IconUser}
                title={userName}
                className="roles-table-created-by"
            />
        ),
    },
    {
        tooltip: false,
        permission: showPermissions,
        width: 50,
        fixed: true,
        component: RolePermissionsCell,
    },
    {
        name: () => __('Groups'),
        tooltip: false,
        width: 200,
        component: RoleGroupCell,
    },
    {
        tooltip: false,
        permission: editable,
        width: 50,
        fixed: true,
        component: EditRoleCell,
    },
]

const ConnectedTable = compose(withRejection(), withSelection(), withLoader())(Table)

export default function () {
    const roles = useRoles()
    const permissions = usePermission({
        isAllowed: list,
        hasSelection: selection,
    })

    useEffect(() => {
        roles.init()
        roles.startPoll()

        return roles.stopPoll
    }, [])

    const props = {...permissions, ...roles}
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <ConnectedTable
            {...props}
            columns={columns}
            fullHeight
            emptyMessage={() => __('No roles found')}
        />
    )
}
