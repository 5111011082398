import {buffers, channel} from 'redux-saga'
import {take, spawn, fork, call} from 'redux-saga/effects'
import {receive, observe} from './actions'
import toArray from 'utils/toArray'

export function* createChannel(buffer = buffers.expanding()) {
    const chan = channel(buffer)

    const task = yield spawn(function* takeProcesses() {
        while (true) {
            const {payload} = yield take([receive, observe])

            payload.forEach((process) => {
                if (process.id) {
                    chan.put(process)
                }
            })
        }
    })

    return {
        take: chan.take,
        flush: chan.flush,
        close() {
            chan.close()
            setImmediate(() => task.cancel())
        },
    }
}

function matcher(fn) {
    fn.withPanelId = (panelIds) => {
        panelIds = toArray(panelIds)
        return fn.and((process) => panelIds.includes(process.panelId))
    }

    fn.withType = (types) => {
        types = toArray(types)
        return fn.and((process) => types.includes(process.type))
    }

    fn.isRunning = () => fn.and((process) => process.isRunning)

    fn.isCompleted = () => fn.and((process) => !process.isRunning)

    fn.isSuccessful = () => fn.and((process) => process.isSuccessful)

    fn.isFailed = () => fn.and((process) => process.isFailed)

    fn.and = (condition) => matcher((process) => condition(process) && fn(process))

    return fn
}

const MATCH = matcher(() => true)

const createMatcher = (intake, panelId) => {
    const panelMatcher = panelId ? MATCH.withPanelId(panelId) : MATCH

    switch (true) {
        case intake === null:
            return panelMatcher

        case typeof intake === 'function':
            return panelMatcher.and(intake)

        default:
            return panelMatcher.withType(intake)
    }
}

export function* takeProcess(matcher, channel = null) {
    if (!channel) {
        const channel = yield call(createChannel)

        try {
            return yield call(takeProcess, matcher, channel)
        } finally {
            channel.close()
        }
    }

    while (true) {
        const process = yield take(channel)

        if (matcher(process)) {
            return process
        }
    }
}

function* takeEveryProcess(matcher, worker) {
    const channel = yield call(createChannel)

    try {
        while (true) {
            const process = yield call(takeProcess, matcher, channel)
            yield fork(worker, process)
        }
    } finally {
        channel.close()
    }
}

export function* takeProcessComplete(types = null, panelId = null, channel = null) {
    const matcher = createMatcher(types, panelId).isCompleted()
    return yield takeProcess(matcher, channel)
}

export function* takeProcessCompleteSuccessful(
    types = null,
    panelId = null,
    channel = null
) {
    const matcher = createMatcher(types, panelId).isFailed()
    return yield takeProcess(matcher, channel)
}

export function* takeProcessCompleteFailed(types = null, panelId = null, channel = null) {
    const matcher = createMatcher(types, panelId).isFailed()
    return yield takeProcess(matcher, channel)
}

export function* takeEveryProcessProgress(types, worker) {
    const matcher = createMatcher(types)
    yield call(takeEveryProcess, matcher, worker)
}

export function* takeEveryProcessComplete(types, worker) {
    const matcher = createMatcher(types).isCompleted()
    yield call(takeEveryProcess, matcher, worker)
}

export function* takeEveryProcessCompleteSuccessful(types, worker) {
    const matcher = createMatcher(types).isSuccessful()
    yield call(takeEveryProcess, matcher, worker)
}
