import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {fetch as interactiveUsersFetch} from 'modules/interactiveUsers/autocomplete/actions'
import withSelectLoader from 'containers/withSelectLoader'

export const InteractiveUsersSelect = withSelectLoader(
    interactiveUsersFetch,
    ({interactiveUsers}) => interactiveUsers.autocomplete.isLoading,
    ({
        interactiveUsers: {
            autocomplete: {byIds},
        },
    }) => Object.values(byIds).map(({email}) => ({value: email, label: email})),
    10,
    true
)(Autocomplete)
