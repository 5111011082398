import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {compose} from 'redux'

import {ReactComponent as IconPower} from 'ipmp-react-ui/icons/power.svg'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Range from 'ipmp-react-ui/Range'

import formatError from 'ipmp-react-ui/_utils/formatError'

import __ from 'utils/i18n'
import {
    dimmerLevelToPercent,
    dimmerPercentToLevel,
    resolveDimmerButtonLabel,
} from 'constants/had/dimmer'
import {isOff, isOn, reverseHadStateMap} from 'constants/had/hadState'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {resolveDimmerCommands} from 'constants/had/command'
import {withPermission, withRejection} from 'containers/withPermission'
import {dimmerLevel} from 'permissions/panel/output/actions'

const DimmerControlForm = ({errors, isLoading, handle, had, panelId}) => {
    const [dimmerMode, setDimmerMode] = useState(had?.state?.pgm)
    const onSubmitHandler = (formData) => {
        handle &&
            handle(
                new HadControlForm({
                    commands: resolveDimmerCommands({
                        dimmerMode:
                            dimmerMode === had?.state?.pgm ? undefined : dimmerMode,
                        dimmerLevel:
                            formData?.dimmerLevel &&
                            dimmerPercentToLevel(Number(formData?.dimmerLevel)),
                        supportedCommands: had?.supportedCommands,
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }
    const onClickButtonHandler = () => {
        setDimmerMode(reverseHadStateMap.get(dimmerMode))
    }

    const label = resolveDimmerButtonLabel(dimmerMode)

    return (
        <Form
            className="dimmerControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
        >
            <div
                className={classnames('form-field', {
                    'form-field--error': errors?.mode,
                })}
            >
                <h3 className="form-field-label">{__('Modes')}</h3>
                <Button
                    className={classnames('dimmerControlForm-stateButton', {
                        'dimmerControlForm-stateButton--stateOn': isOff(dimmerMode),
                        'dimmerControlForm-stateButton--stateOff': isOn(dimmerMode),
                    })}
                    onClick={onClickButtonHandler}
                    Icon={IconPower}
                    label={label}
                />
                {errors?.mode && (
                    <span className="form-field-error">{formatError(errors?.mode)}</span>
                )}
            </div>
            {isOn(dimmerMode) && (
                <Range
                    className="dimmerControlForm-range"
                    label={__('Dimmer Control')}
                    name="dimmerLevel"
                    min={0}
                    max={100}
                    minmaxSuffix="%"
                    defaultValue={dimmerLevelToPercent(had?.state?.dimmer?.level)}
                />
            )}
        </Form>
    )
}

DimmerControlForm.propTypes = {
    had: PropTypes.shape({type: PropTypes.number}),
    panelId: PropTypes.number,
}

export default compose(
    withPermission({
        isAllowed: dimmerLevel,
    }),
    withRejection()
)(DimmerControlForm)
