import React from 'react'

import values, {edit} from 'permissions/remoteInspections/values'

import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/settings.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ButtonVisibility = withVisibility()(Button)

export default function ConfigureRemoteInspectionOptionsButton() {
    const {isVisible, editable} = usePermission({
        isVisible: values,
        editable: edit,
    })

    const {showRemoteInspectionValuesModal} = useRemoteInspections()

    return (
        <ButtonVisibility
            isVisible={isVisible}
            label={__('Settings')}
            Icon={IconSettings}
            onClick={() => showRemoteInspectionValuesModal({editable})}
        />
    )
}
