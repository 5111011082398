import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import BlindModal from 'ipmp-react-ui/BlindModal'
import BlindModalWithPaginationCard from './BlindModalWithPaginationCard'

class BlindModalWithPagination extends BlindModal {
    get classnames() {
        return classnames(
            super.classnames,
            'blindModalWithPagination',
            this.props.className
        )
    }

    renderChildren = () => {
        const {
            onClose,
            children,
            isPrevDisabled = false,
            isNextDisabled = false,
            onPrevClick,
            onNextClick,
            prev,
            next,
            cardActions,
        } = this.props

        return (
            <BlindModalWithPaginationCard
                onClose={onClose}
                isPrevDisabled={isPrevDisabled}
                isNextDisabled={isNextDisabled}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                prev={prev}
                next={next}
                cardActions={cardActions}
            >
                {children}
            </BlindModalWithPaginationCard>
        )
    }
}

BlindModalWithPagination.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    isPrevDisabled: PropTypes.bool,
    isNextDisabled: PropTypes.bool,
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    prev: PropTypes.any,
    next: PropTypes.any,
}

export default BlindModalWithPagination
