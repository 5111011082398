import {warn} from 'utils/log'
import __ from 'utils/i18n'
import {HAD_STATE_OFF, HAD_STATE_ON} from 'constants/had/hadState'

const doorlockTypeStateTitleMap = new Map([
    [HAD_STATE_ON, () => __('Locked')],
    [HAD_STATE_OFF, () => __('Unlocked')],
])

const doorlockTypeStateTitle = (state) => {
    let result = doorlockTypeStateTitleMap.get(state?.pgm)

    if (!result) {
        warn(`No home automation device (doorlock) state title for ${state?.pgm}`)
        result = () => __('Undefined')
    }

    return result()
}

export default doorlockTypeStateTitle
