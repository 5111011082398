import React, {Fragment} from 'react'

import get from 'lodash/get'
import __ from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import {SelectPanelTypes} from 'modals/Panel/DlsConnection/SmartCommunicator/SelectPanelTypes'
import {SelectConnectionSpeed} from 'modals/Panel/DlsConnection/SmartCommunicator/SelectConnectionSpeed'
import ToggleBox from 'ipmp-react-ui/ToggleBox'

const toggleLabel = (status) => (
    <span className="connect-smartcomm-form-toggle-box-label">
        {__('Encryption')}
        <em>({status})</em>
    </span>
)

const ConnectSmartCommunicatorFormFields = ({errors}) => {
    const errorHost = get(errors, 'host[0]', '')
    const errorPort = get(errors, 'port[0]', '')
    const errorPassword = get(errors, 'password[0]', '')
    const errorTimeout = get(errors, 'timeout[0]', '')

    return (
        <Fragment>
            <SelectPanelTypes />
            <div className="form-field cols cols--stretch">
                <div className="col">
                    <Input
                        label={__('Hostname/IP Address')}
                        error={errorHost}
                        name="host"
                    />
                </div>
                <div className="col">
                    <Input
                        type="number"
                        label={__('Port')}
                        error={errorPort}
                        name="port"
                    />
                </div>
            </div>

            <Input
                label={__('Password')}
                error={errorPassword}
                name="password"
                type="password"
                className="form-field password-field"
                autocomplete="new-password"
            />
            <ToggleBox
                defaultChecked={true}
                name="isEncrypted"
                label={toggleLabel(__('Enabled'))}
                labelOff={toggleLabel(__('Disabled'))}
                className="connect-smartcomm-form-toggle-box form-field"
            />
            <div className="form-field cols cols--stretch">
                <div className="col">
                    <Input
                        label={__('Timeout [sec]')}
                        error={errorTimeout}
                        name="timeout"
                        type="number"
                    />
                </div>
                <div className="col">
                    <SelectConnectionSpeed />
                </div>
            </div>
        </Fragment>
    )
}

export default ConnectSmartCommunicatorFormFields
