import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction('USERS/STORE/UPDATE', toArray)
export const setEnabled = createAction('USERS/LIST/SET_ENABLED', (ids, isEnabled) => ({
    ids,
    isEnabled,
}))
export const purge = createAction('USERS/STORE/PURGE')

export const unblockUser = createAction('USERS/LIST/SET_UNBLOCK', (id) => ({
    id,
}))
