import React from 'react'
import PropTypes from 'prop-types'

import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'

export default function Paginator({
    onPrevClick,
    onNextClick,
    isPrevDisabled,
    isNextDisabled,
}) {
    return (
        <Buttons className="blindModalWithPagination-paginator" flat>
            <Button
                disabled={isPrevDisabled}
                className="blindModalWithPagination-paginator-prev"
                onClick={onPrevClick}
                Icon={IconPrev}
            />
            <Button
                disabled={isNextDisabled}
                className="blindModalWithPagination-paginator-next"
                onClick={onNextClick}
                Icon={IconNext}
            />
        </Buttons>
    )
}

Paginator.propTypes = {
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
    isPrevDisabled: PropTypes.bool,
    isNextDisabled: PropTypes.bool,
}
