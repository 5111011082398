import React from 'react'
import {compose} from 'redux'

import withLoader from 'containers/withLoader'
import {withRejection} from 'containers/withPermission'
import withSelection from 'containers/withSelection'

import Table from 'ipmp-react-ui/Table'
import {__} from 'utils/i18n'

import {pushBasic} from 'permissions/panels/selection'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import usePermission from 'hooks/usePermission'
import {useParams} from 'react-router'
import usePushBasicConfigurations from 'hooks/pages/usePushBasicConfigurations'

const columns = [
    {
        maxWidth: 180,
        name: () => __('Panel'),
        render: ({id, serial, account}) => <PanelCell {...{id, serial, account}} />,
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        width: 250,
        render: ({isSupported}) =>
            isSupported ? null : __('Push configuration is not supported'),
    },
    {
        name: () => __('Model'),
        render: ({model}) => model || <span className="empty">&mdash;</span>,
    },
    {
        name: () => __('Configuration type'),
        render: ({family}) => family || <span className="empty">&mdash;</span>,
    },
]

const ConnectedTable = compose(
    withRejection(),
    withSelection(),
    withLoader(
        ({init}) => {
            init()
        },
        ({reset}) => {
            reset()
        }
    )
)(Table)

export default function PushBasicConfigurationTable() {
    const {isPermitted} = usePermission(pushBasic)
    const {id} = useParams()
    const pushBasicConfigurations = usePushBasicConfigurations(parseInt(id))

    return (
        <ConnectedTable
            {...pushBasicConfigurations}
            isAllowed={isPermitted}
            fullHeight
            columns={columns}
            emptyMessage={() => __('No applicable units found')}
        />
    )
}
