import React from 'react'

import Table from 'ipmp-react-ui/Table'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'

import withLoader from 'containers/withLoader'
import {__} from 'utils/i18n'
import RemoveZoneType from 'pages/EventRules/ZoneTypes/TableCell/RemoveZoneType'
import EditZoneType from 'pages/EventRules/ZoneTypes/TableCell/EditZoneType'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import useZoneTypes from 'hooks/pages/useZoneTypes'

const allColumns = [
    {
        name: () => __('Zone Type'),
        render: ({name}) => name,
    },
    {
        name: () => __('Entry\\Exit delay'),
        render: ({isDelayEnabled}) => isDelayEnabled && <IconCheck className="success" />,
    },
    {
        tooltip: false,
        width: 40,
        fixed: true,
        component: EditZoneType,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveZoneType,
    },
]

const TableWithLoader = withLoader(
    ({init, startPoll}) => {
        init()
        startPoll()
    },
    ({stopPoll}) => stopPoll()
)(Table)

export default function ZoneTypesTable() {
    const zoneTypes = useZoneTypes()
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <TableWithLoader
            {...zoneTypes}
            columns={columns}
            fullHeight
            emptyMessage={() => __('No zone types found')}
        />
    )
}
