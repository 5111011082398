import {STATUS_CONNECTED, STATUS_DISCONNECTED} from 'constants/connections'

const dlsConnectionMap = {
    [STATUS_CONNECTED]: true,
    [STATUS_DISCONNECTED]: false,
}

export const isConnectButtonAllowed = (connection, dlsPathThrough) => {
    const {isEnabled, connectButton, connectionStatus} = dlsPathThrough

    if (!isEnabled || !connectButton) {
        return false
    }

    const dlsConnection = dlsConnectionMap[connection.value] ?? null

    if (!connectionStatus) {
        return true
    }

    if (connectionStatus && !dlsConnection) {
        return true
    }

    return false
}

export const isDisconnectButtonAllowed = (connection, dlsPathThrough) => {
    const {isEnabled, connectButton, disconnectButton, connectionStatus} = dlsPathThrough

    if (!isEnabled) {
        return false
    }

    const dlsConnection = dlsConnectionMap[connection.value] ?? null

    if (disconnectButton && !connectionStatus) {
        return true
    }

    if (connectButton && connectionStatus && dlsConnection === true) {
        return disconnectButton
    }

    return false
}
