import React from 'react'
import {compose} from 'redux'
import {withPermission} from 'containers/withPermission'

import assignedUnitUsers from 'permissions/interactiveUsers/recentHistoryAssignedUnitUsersList'
import {withVisibility} from 'containers/withVisibility'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

const InteractiveUserEmailComponent = ({interactiveUserEmail}) => {
    return (
        <div className="recentHistory-item-info recentHistory-item-info--userEmail">
            <OverflowTextTooltip tooltip={interactiveUserEmail}>
                {interactiveUserEmail}
            </OverflowTextTooltip>
        </div>
    )
}

export const InteractiveUserEmail = compose(
    withPermission({isVisible: assignedUnitUsers}),
    withVisibility()
)(InteractiveUserEmailComponent)
