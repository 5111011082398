import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {SEARCH_SUGGESTS_COUNT} from 'constants/search'

/**
 * Workaround for another type of suggesting api in ActionLog
 * Add's key to filter, that will be sended to endpoint
 */
export class MapSuggestFilter extends SuggestFilter {
    isTypeMore = (suggest) => {
        return Object.keys(suggest.values).length > SEARCH_SUGGESTS_COUNT
    }

    createItem(value) {
        return {
            $: this.digest(value.key),
            key: value.key,
            value: value.value,
            name: this.name,
            label: value.value,
        }
    }
}
