import React, {Component} from 'react'
import {Provider} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import Error from 'ipmp-react-ui/Error'

import {__} from 'utils/i18n'
import Layout from 'pages/App/Layout'

class App extends Component {
    state = {}

    componentDidCatch(error) {
        this.setState({hasError: true})
    }

    handleReload = () => (window.location = '/')

    render() {
        if (this.state.hasError) {
            const message = (
                <div>
                    <p className="title">{__('Tab just crashed.')}</p>
                    {__('To continue, click button below.')}
                </div>
            )

            return (
                <Error title={__('We are sorry')} message={message}>
                    <Button primary onClick={this.handleReload}>
                        {__('Reload tab')}
                    </Button>
                </Error>
            )
        }

        const {store, history} = this.props

        return (
            <Provider store={store}>
                <Layout history={history} />
            </Provider>
        )
    }
}

export default App
