import React from 'react'
import PropTypes from 'prop-types'

import {NextButton, PreviousButton} from './Header/Navigate'
import Buttons from 'ipmp-react-ui/Buttons'

function RemoteInspectionsResultHeader({inspection: {serial, account}}) {
    return (
        <div className="rri-header">
            <Buttons flat>
                <PreviousButton />
                <NextButton />
            </Buttons>
            <span className="rri-header-serial">{serial}</span>
            <span className="rri-header-account">{account}</span>
        </div>
    )
}

RemoteInspectionsResultHeader.propTypes = {
    inspection: PropTypes.shape({
        serial: PropTypes.string.isRequired,
        account: PropTypes.string.isRequired,
    }),
}

export default RemoteInspectionsResultHeader
