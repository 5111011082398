import React from 'react'

import {uploadSchedule} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as IconCalendar} from 'ipmp-react-ui/icons/calendar.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ButtonVisibility = withVisibility()(Button)

export default function ScheduleRemoteInspectionsByCsvButton() {
    const {isPermitted} = usePermission(uploadSchedule)
    const {showScheduleRriByCsvModal} = useRemoteInspections()

    return (
        <ButtonVisibility
            onClick={showScheduleRriByCsvModal}
            isVisible={isPermitted}
            label={__('Upload schedule')}
            Icon={IconCalendar}
        />
    )
}
