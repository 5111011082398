import {takeEvery, all, put, select, call, fork} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/interactiveUsers'
import {snackShow} from 'modules/snacks'
import updateRecentWidget from 'modules/panelInteractiveUsers/list/watchers/updateRecentWidget'
import {getPanelId} from 'utils/panelIdMatchSelector'
import * as listActions from '../list/actions'
import {selectPartitionsSuggests, selectStatusSuggests, selectSuggests} from './selectors'
import {
    LABEL_FILTER,
    PARTITIONS_FILTER,
    ROLE_FILTER,
    STATUS_FILTER,
} from 'constants/panelUsersSearch'
import watchRemoveInteractiveUser from 'modules/panelInteractiveUsers/store/watchers/watchRemoveInteractiveUser'
import {fetchPanelsInteractiveUser} from 'modules/panels/recent/actions'

const selector = (state) => {
    const panelId = getPanelId(state)

    return {
        ...state.panelInteractiveUsers.list,
        allRows: state.panelInteractiveUsers.store.byIds[panelId],
        panelId,
    }
}

export default function* () {
    yield all([
        takeEvery(actions.disconnectInteractiveUser, watchDisconnectInteractiveUser),
        takeEvery(actions.enable, watchEnable),
        takeEvery(actions.suspend, watchSuspend),
        takeEvery(listActions.fetchSuggests, watchFetchSuggests),
        takeEvery(actions.removeInteractiveUser, watchRemoveInteractiveUser),
    ])
}

function* watchFetchSuggests({payload: {fields, prefix}}) {
    const {panelId} = yield select(selector)

    const selectors = {
        [ROLE_FILTER]: selectSuggests,
        [LABEL_FILTER]: selectSuggests,
        [PARTITIONS_FILTER]: selectPartitionsSuggests,
        [STATUS_FILTER]: selectStatusSuggests,
    }

    const results = {}

    for (const field of fields) {
        const selector = selectors[field]

        results[field] = yield select((state) => selector(state, {panelId, field}))
    }

    yield put(listActions.receiveSuggests(results, prefix))
}

function* watchDisconnectInteractiveUser({payload: id, meta: {panelId}}) {
    const interactiveUserId = yield select(
        (state) => state.panelInteractiveUsers.store.byIds[panelId][id].interactiveUserId
    )

    try {
        yield call(api.disconnectPanel, {
            interactiveUserId,
            panelId,
        })
        yield put(fetchPanelsInteractiveUser())
        yield fork(updateRecentWidget)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.revertDisconnectInteractiveUser(id, panelId))
    }
}

function* watchEnable({payload: interactiveUserId, meta: {panelId}}) {
    const user = yield select(
        (state) => state.panelInteractiveUsers.store.byIds[panelId][interactiveUserId]
    )

    try {
        const {status} = yield call(api.enable, user.interactiveUserId)
        yield put(actions.updateUser({...user, status}, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.revert(interactiveUserId, panelId))
    }
}

function* watchSuspend({payload: interactiveUserId, meta: {panelId}}) {
    const user = yield select(
        (state) => state.panelInteractiveUsers.store.byIds[panelId][interactiveUserId]
    )

    try {
        const {status} = yield call(api.suspend, user.interactiveUserId)
        yield put(actions.updateUser({...user, status}, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.revert(interactiveUserId, panelId))
    }
}
