import React from 'react'
import {compose} from 'redux'

import {refresh} from 'permissions/panel/actions'

import withTrackProcess from 'containers/withTrackProcess'
import withProps from 'containers/withProps'
import {withVisibility} from 'containers/withVisibility'

import RefreshButton from 'ipmp-react-ui/RefreshButton'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'
import useRouterPanelId from 'hooks/useRouterPanelId'
import Void from 'components/Void'
import {selectRefreshState} from 'modules/features/store/selectors'
import {withFeatureRejection} from 'containers/withFeature'

const ConnectedRefreshButton = compose(
    withVisibility(),
    withTrackProcess(({panel}) => panel.refreshProcess),
    withProps(({process}) => ({
        isRefreshing: !!process,
    })),
    withFeatureRejection(selectRefreshState, Void)
)(RefreshButton)

export default function RefreshPanelButton(props) {
    const {isPermitted} = usePermission(refresh)
    const panel = usePanel(props)
    const panelId = useRouterPanelId()
    const buttonProps = {...props, ...panel}

    return (
        <ConnectedRefreshButton
            {...buttonProps}
            panelId={panelId}
            isVisible={isPermitted}
            onClick={panel.refreshState}
        />
    )
}
