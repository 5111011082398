import {useSelector} from 'react-redux'
import {selectFirmwareListRows} from 'modules/firmware/list/selectors'
import {fetch, init, reset, setStart, upgrade} from 'modules/firmware/list/actions'
import {useParams} from 'react-router'
import usePerPage from '../usePerPage'
import {useActions} from 'modules/higherOrder/useActions'

export default function useUpgradablePanels() {
    const {start, total, error, isLoading} = useSelector((state) => state.firmware.list)
    const {applianceId, upgradePackageId} = useParams()
    const perPage = usePerPage('firmware')

    return {
        ...perPage,
        start,
        total,
        error,
        isLoading,
        rows: useSelector(selectFirmwareListRows),
        ...useActions({
            setStart,
            reset,
            init,
            fetch: () => fetch(applianceId, upgradePackageId),
            upgrade: (panels) => upgrade(panels, applianceId, upgradePackageId),
        }),
    }
}
