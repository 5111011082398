import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'

import __ from 'utils/i18n'

import AssignPanels from './AssignPanels'

export default function SecondBlindContentInPanel({
    row: {interactiveUserId, email},
    setHeaderContent,
    setFooterContent,
    closeBlind,
}) {
    if (!interactiveUserId) {
        closeBlind()
    }

    useEffect(() => {
        if (email) {
            setHeaderContent(() => (
                <Definition title={__('Assign panel')} detail={__('(%s)', email)} />
            ))
        }
    }, [email])

    return (
        <AssignPanels
            user={{id: +interactiveUserId}}
            setFooterContent={setFooterContent}
        />
    )
}

SecondBlindContentInPanel.propTypes = {
    row: PropTypes.shape({
        interactiveUserId: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    setHeaderContent: PropTypes.func.isRequired,
    setFooterContent: PropTypes.func.isRequired,
    closeBlind: PropTypes.func.isRequired,
}
