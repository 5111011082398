export const CHANNEL_USB = 'CHANNEL_USB'
export const CHANNEL_BBA = 'CHANNEL_BBA'
export const CHANNEL_GPRS = 'CHANNEL_GPRS'
export const CHANNEL_WIFI = 'CHANNEL_WIFI'
export const CHANNEL_BBA_OVER_GPRS = 'CHANNEL_BBA_OVER_GPRS'

export const ENCRYPTION_NO = 'NO_ENCRYPTION'
export const ENCRYPTION_DEFAULT = 'ENCRYPTED'
export const ENCRYPTION_ITV2_V1 = 'ITv2_V1'
export const ENCRYPTION_ITV2_V2 = 'ITv2_V2'
export const ENCRYPTION_DTLS = 'DTLS'

export const STATUS_NOT_CONFIGURED = 'NOT_CONFIGURED'
export const STATUS_DISCONNECTED = 'DISCONNECTED'
export const STATUS_KA_DISABLED = 'KA_DISABLED'
export const STATUS_OFFLINE = 'OFFLINE'
export const STATUS_ONLINE = 'ONLINE'
export const STATUS_CONNECTED = 'CONNECTED'
export const STATUS_CONFIGURED_TO_SLAVE = 'CONFIGURED_TO_SLAVE'
export const STATUS_CONFIGURED_OUTSIDE_PM = 'CONFIGURED_OUTSIDE_PM'

export const PROTOCOL_LWM2M_BOOTSTRAP = 'LWM2M_BOOTSTRAP'
export const PROTOCOL_DLS = 'DLS'
