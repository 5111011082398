import PropTypes from 'prop-types'
import React from 'react'

import {__} from 'utils/i18n'

import EditEventsButton from './EditEventsButton'
import EventProfileList from './EventProfileList'
import usePermission from 'hooks/usePermission'
import {
    notificationsEdit,
    notificationsListEventsProfiles,
} from 'permissions/group/navigation'

export default function EventProfile({settings, groupId}) {
    const editPermitted = usePermission(notificationsEdit)
    const {isPermitted} = usePermission(notificationsListEventsProfiles)
    if (!settings.eventProfile) {
        return null
    }
    if (isPermitted) {
        return (
            <>
                <div className="card-header">
                    <h3>{__('Events Profiles settings')}</h3>
                    {editPermitted.isPermitted && <EditEventsButton groupId={groupId} />}
                </div>
                <EventProfileList
                    profiles={settings.eventProfile}
                    availableProfiles={settings.availableProfiles.eventProfile}
                />
            </>
        )
    }
    return null
}

EventProfile.propTypes = {
    settings: PropTypes.shape({
        eventProfile: PropTypes.object,
        availableProfiles: PropTypes.shape({
            eventProfile: PropTypes.object.isRequired,
        }),
    }),
    groupId: PropTypes.number.isRequired,
}
