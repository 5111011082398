import React from 'react'
import PropTypes from 'prop-types'

import ModalCardForm from 'ui/ModalCardForm'

import __ from 'utils/i18n'

import {getUserRoleTitle} from 'constants/accountRoles'
import {PanelUserSelect} from './PanelUserSelect'
import {InteractiveUsersAutocomplete} from './InteractiveUsersAutocomplete'

const rules = {
    panelUserId: {
        presence: true,
    },
    email: {
        presence: true,
        email: true,
    },
}

export const AssignUserToPanelForm = ({
    panel,
    panelId,
    handle,
    interactiveUser,
    interactiveUsers,
    isRegisterNew,
    panelUserId,
    panelUser,
    ...props
}) => {
    const getEmail = (emailFromForm) => {
        if (interactiveUser) {
            return interactiveUser.email
        }

        return emailFromForm
    }

    const handleForm = ({panelUserId, email}) => {
        handle({
            panelId,
            email: getEmail(email),
            panelUserId: Number(panelUserId),
        })
    }
    return (
        <ModalCardForm
            onSubmit={handleForm}
            header={__('Assign customer to panel %s', panel.serial)}
            rules={rules}
            confirmOnSubmit
            {...props}
        >
            <InteractiveUsersAutocomplete
                interactiveUser={interactiveUser}
                isRegisterNew={isRegisterNew}
            />
            <PanelUserSelect
                label={__('Panel User')}
                name="panelUserId"
                defaultValue={panelUserId ?? undefined}
                defaultLabel={
                    panelUser?.role ? getUserRoleTitle(panelUser.role, panelUser.id) : ''
                }
                disabled={Boolean(panelUserId)}
                panelId={panel.id}
            />
        </ModalCardForm>
    )
}

AssignUserToPanelForm.propTypes = {
    handle: PropTypes.func.isRequired,
    panel: PropTypes.shape({
        id: PropTypes.number.isRequired,
        serial: PropTypes.string.isRequired,
    }).isRequired,
    interactiveUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),

    interactiveUsers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ),
}
