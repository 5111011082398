import React from 'react'
import get from 'lodash/get'

import Input from 'ipmp-react-ui/Input'
import __ from 'utils/i18n'

import {SelectDlsNetworkProtocols} from './SelectDlsNetworkProtocols'
import {SelectDlsApplicationProtocols} from './SelectDlsApplicationProtocols'

const ConnectDscFormFields = ({errors}) => {
    const errorHost = get(errors, 'host[0]', '')
    const errorPort = get(errors, 'port[0]', '')
    return (
        <div className="dscConnection-form">
            <h3 className="form-section-header">{__('Host information')}</h3>
            <div className="cols cols--stretch">
                <div className="col">
                    <Input
                        autoFocus
                        readOnly={false}
                        label={__('Hostname/IP Address ')}
                        name="host"
                        error={errorHost}
                    />
                </div>
                <div className="col">
                    <Input
                        readOnly={false}
                        label={__('Port')}
                        name="port"
                        error={errorPort}
                    />
                </div>
            </div>
            <h3 className="form-section-header dls-protocols-header">{__('Protocol')}</h3>
            <div className="cols cols--stretch">
                <div className="col">
                    <SelectDlsNetworkProtocols />
                </div>
                <div className="col">
                    <SelectDlsApplicationProtocols />
                </div>
            </div>
        </div>
    )
}

export default ConnectDscFormFields
