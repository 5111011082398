import {all, takeEvery, call, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

import * as api from 'api/group'
import * as actions from './actions'
import {editEventsProfile} from 'modules/forms/handlers'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(editEventsProfile.SUCCESS, watchEditProfiles),
    ])
}

function* watchFetch({payload: id}) {
    try {
        const notifications = yield call(api.receiveGroupNotifications, id)
        yield put(actions.receive({notifications, id}))
    } catch (error) {
        yield put(actions.receive(error))
        yield put(snackShow(error.message))
    }
}

function* watchEditProfiles({meta}) {
    try {
        yield put(actions.update({...meta}))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
