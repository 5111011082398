import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import Table from 'ipmp-react-ui/Table'

import {showAlert, showSystemSettingsEditMessageBrokerModal} from 'modules/modals/actions'
import {removeMessageBroker} from 'modules/system/settings/actions'
import {messageBrokersColumns} from './BrokersTableColumns'

const BrokersTable = () => {
    const {messageBrokers} = useSelector(({system}) => system.settings)
    const modals = useSelector(({modals}) => modals)
    const [activeRow, setActiveRow] = useState(null)
    const [brokers, setBrokers] = useState(messageBrokers)

    const {
        remove,
        edit,
        showAlert: showAlertModal,
    } = useActions({
        edit: (broker) =>
            showSystemSettingsEditMessageBrokerModal({
                data: broker,
            }),
        remove: (id) => removeMessageBroker(id),
        showAlert,
    })
    const handleRowClick = (broker) => {
        setActiveRow({id: broker.id})
        edit(broker)
    }
    const handleRemove = ({id, isUsedByMfa}) => {
        if (!isUsedByMfa) {
            return remove(id)
        }

        showAlertModal(
            __('Warning'),
            __(
                'Selected message broker is used for MFA authentication and can not be removed. Change message broker firstly then remove.'
            )
        )
    }

    useEffect(() => {
        setBrokers(messageBrokers)
        if (!modals?.type) {
            setActiveRow(null)
        }
    }, [modals, brokers])

    return (
        <Table
            fullHeight
            emptyMessage={__('No message brokers found')}
            rows={messageBrokers.data}
            columns={messageBrokersColumns}
            onRowClick={handleRowClick}
            onRemove={handleRemove}
            active={activeRow}
        />
    )
}

export default BrokersTable
