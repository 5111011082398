import {createSelector} from 'reselect'

export const selectCentralStationsList = createSelector(
    (state) => state.groups.centralStations,
    ({list: {page, isLoading, start, total}, store: {byIds}}) => ({
        isLoading,
        start,
        total,
        rows: page.map((id) => byIds[id]).filter((n) => n),
    })
)
