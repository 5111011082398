import React from 'react'
import Logo from 'ui/Logo'
import {isDarkTheme} from 'constants/themes'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {
    showChangePasswordModal,
    showEditProfileInfoModal,
    showEditSettingsModal,
} from 'modules/modals/actions'
import {logout} from 'modules/auth/sign/actions'
import UserInfoComponent from 'components/UserInfo'
import RecentWidget from 'components/Nav/RecentWidget'
import {ReactComponent as IconSidebar} from 'ipmp-react-ui/icons/menu/sidebar.svg'
import classnames from 'classnames'

const UserInfo = connect(
    ({
        auth: {
            sign: {user},
        },
        system: {
            settings: {serverVersion},
        },
    }) => ({user, serverVersion}),
    (dispatch) =>
        bindActionCreators(
            {
                showEditProfileInfoModal,
                showChangePasswordModal,
                showEditSettingsModal,
                logout: () => logout(),
            },
            dispatch
        )
)(UserInfoComponent)

const NavbarTop = ({theme, handleClick, sidebarShowed, showAboutModal}) => {
    const sideBarClassname = classnames({
        'sidebar-opener': true,
        active: sidebarShowed,
    })
    return (
        <nav className="navbar-top">
            <Logo dark={isDarkTheme(theme)} onClick={showAboutModal} />
            <div className="right-menu">
                <RecentWidget />
                <div className={sideBarClassname} onClick={handleClick}>
                    <IconSidebar />
                </div>
                <UserInfo />
            </div>
        </nav>
    )
}

export default NavbarTop
