import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class BroadbandConnected extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            broadbandConnected: PropTypes.shape({
                isAutoEnrollmentEnabled: PropTypes.bool,
            }),
        }),
    }

    render() {
        const {data} = this.props

        return (
            <>
                {this.renderListItem(
                    __('Enable Auto-Enroll'),
                    data.isAutoEnrollmentEnabled
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {broadbandConnected},
            },
        }) => ({...broadbandConnected})
    )
)(BroadbandConnected)
