import React from 'react'
import PropTypes from 'prop-types'

import list from 'permissions/actionLog/list'

import {withVisibility} from 'containers/withVisibility'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import Pager from 'ipmp-react-ui/Pager'
import useActionLog from 'hooks/pages/useActionLog'
import usePermission from 'hooks/usePermission'

function ActionLogBar({start, total, perPage, setStart, onPerPageChange}) {
    return (
        <Bar orientation={RIGHT}>
            <Pager
                start={start}
                total={total}
                perPage={perPage}
                onPageChange={setStart}
                onPerPageChange={onPerPageChange}
            />
        </Bar>
    )
}

ActionLogBar.propTypes = {
    start: PropTypes.number,
    total: PropTypes.number,
    perPage: PropTypes.number,
    setStart: PropTypes.func,
    onPerPageChange: PropTypes.func,
}

const ActionLogBarVisible = withVisibility()(ActionLogBar)

export default function ActionLogGridBar() {
    const actionLogProps = useActionLog()
    const {isPermitted} = usePermission(list)

    return <ActionLogBarVisible isVisible={isPermitted} {...actionLogProps} />
}
