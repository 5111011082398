import React from 'react'

import usePanelSystemLogs from 'hooks/panel/logs/usePanelSystemLogs'
import Log from './Log'

export default function Logs({panel}) {
    const panelLogsProps = usePanelSystemLogs(panel.id)

    return <Log {...panelLogsProps} panel={panel} />
}
