import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'
import {set} from 'immutable-modify'

const defaultState = {
    isLoading: false,
    error: null,
}
export default handleActions(
    {
        [fetch](state) {
            return set(state, 'isLoading', true)
        },
        [receive](state, {error}) {
            if (error) {
                return {...state, error, isLoading: false}
            }

            return {
                ...state,
                error: null,
                isLoading: false,
            }
        },
    },
    defaultState
)
