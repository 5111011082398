import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import Bar, {CENTER} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'

import usePermission from 'hooks/usePermission'
import {assign} from 'permissions/installers/actions'
import __ from 'utils/i18n'

function AddPanelButton({onClick}) {
    const {isPermitted: isAllowedAssignInstallers} = usePermission(assign)

    return (
        isAllowedAssignInstallers && (
            <Bar orientation={CENTER}>
                <Button
                    flat
                    primary
                    Icon={IconPlus}
                    className="installerBlind-addPanel"
                    label={__('Assign Panel')}
                    onClick={onClick}
                />
            </Bar>
        )
    )
}

AddPanelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default AddPanelButton
