import createPermission, {any} from 'permissions/createPermission'
import {configurator, user} from 'permissions/panels/apps'

export const status = createPermission('unit.panelInfo')
export const refresh = createPermission('unit.state.get')
export const remove = createPermission('units.remove')
export const edit = createPermission('unit.panelInfo')
export const activate = createPermission('unit.activate')
export const encryption = createPermission('unit.encryption')
export const eventsInitiate = createPermission('unit.initiateEmergency')

export const actions = createPermission(any(edit, remove, configurator, user))
