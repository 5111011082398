import {warn} from 'utils/log'

const mapFormData = (data, keysMap) => {
    return Object.keys(keysMap).reduce((acc, frontEndKey) => {
        const backendKey = keysMap[frontEndKey]

        if (!backendKey) {
            warn(`No backend key for: ${frontEndKey}`)
            return acc
        }

        if (frontEndKey in data) {
            acc[backendKey] = data[frontEndKey]
        }
        return acc
    }, {})
}

export default mapFormData
