import usePerPage from '../usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch, init, reset, setStart} from 'modules/basicConfiguration/push/actions'
import {useSelector} from 'react-redux'
import {selectUnitListForPushBasicConfigurationRows} from 'modules/basicConfiguration/push/selectors'

const pushBasicConfigurationsSelector = (state) => {
    const {isLoading, error, total, start} = state.basicConfiguration.push

    if (isLoading) {
        return {
            isLoading,
            error,
            total,
            start,
        }
    }

    const rows = selectUnitListForPushBasicConfigurationRows(state)
    const disabledRowsIds = rows.filter(({isSupported}) => !isSupported).map(({id}) => id)

    return {
        rows,
        disabledRowsIds,
        isLoading,
        error,
        total,
        start,
    }
}

export default function usePushBasicConfigurations(basicConfigId = null) {
    const perPage = usePerPage('pushBasicConfiguration')

    return {
        ...perPage,
        ...useSelector(pushBasicConfigurationsSelector),
        ...useActions({
            init,
            fetch: () => fetch(basicConfigId),
            reset,
            onPageChange: setStart,
        }),
    }
}
