export const EDS_QUEUED = 'Queued'
export const EDS_PROCESSING = 'Processing'
export const EDS_SUCCESS = 'Success'
export const EDS_RETRY = 'Retry'
export const EDS_FAILED = 'Failed'
export const EDS_VIEW_SHORT = 'Short'
export const EDS_VIEW_LONG = 'Long'

export const CS_STATUS_ONLINE = 'ONLINE'
export const CS_STATUS_OFFLINE = 'OFFLINE'
export const CS_STATUS_HEARTBEAT = 'HEARTBEAT'
export const CS_STATUS_NOT_APPLICABLE = 'NOT_APPLICABLE'
