import React from 'react'
import PropTypes from 'prop-types'

import SuspendUsersButton from './SuspendUserButton'
import EnableUsersButton from './EnableUserButton'
import UnblockUsersButton from './UnblockUserButton'
import {isUserBlocked} from 'constants/userStatuses'

const ActionButton = ({row: {isEnabled, isBlocked, name}, id}) => {
    const userBlocked = isUserBlocked(isBlocked)
    switch (true) {
        case userBlocked:
            return <UnblockUsersButton id={id} name={name} />
        case isEnabled:
            return <SuspendUsersButton id={id} name={name} />
        case !isEnabled:
            return <EnableUsersButton id={id} name={name} />
    }
}

ActionButton.propTypes = {
    row: PropTypes.shape({
        isEnabled: PropTypes.bool,
        isBlocked: PropTypes.bool,
        isOnline: PropTypes.bool,
    }).isRequired,
    id: PropTypes.number.isRequired,
}

export default ActionButton
