import React from 'react'

import {markAsViewed} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'
import {ReactComponent as ViewedIcon} from 'ipmp-react-ui/icons/viewed.svg'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'
import usePermission from 'hooks/usePermission'

const ReviewButtonVisible = withVisibility()(Button)

export function ReviewButtonBase(props) {
    const {isPermitted} = usePermission(markAsViewed)

    return <ReviewButtonVisible isVisible={isPermitted} {...props} />
}

export default function ReviewButton() {
    const {review, result} = useRemoteInspectionsResult()

    return (
        <ReviewButtonBase
            onClick={review}
            disabled={!result || result.isReviewed}
            flat
            Icon={ViewedIcon}
            label={__('Reviewed')}
        />
    )
}
