import React from 'react'

import {compose} from 'redux'

import {stop} from 'permissions/reports/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useReports from 'hooks/pages/useReports'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(
        (id, {reports}) =>
            reports.store.byIds[id]?.isActive && !reports.store.byIds[id]?.isFinished
    ),
    withConfirmation()
)(Button)

export default function StopReportsButton() {
    const {isPermitted} = usePermission(stop)
    const reportProps = useReports()

    return (
        <ConnectedButton
            {...reportProps}
            isVisible={isPermitted}
            onClick={reportProps.stop}
            Icon={PauseIcon}
            label={__('Stop')}
            title={__('Stop Reports')}
            message={__('Do you want to stop selected reports?')}
        />
    )
}
