import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {assign} from 'permissions/installers/actions'
import __ from 'utils/i18n'
import {showAssignInstallerToPanelModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

const ButtonVisibility = withVisibility()(Button)

export default function AssignInstallerButton() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(assign)
    const {showAssignInstallerToPanelModal: showAssignInstallerToPanel} = useActions({
        showAssignInstallerToPanelModal,
    })
    const onClick = () =>
        showAssignInstallerToPanel({
            panelId,
        })

    return (
        <ButtonVisibility
            Icon={IconPlus}
            label={__('Assign Installer')}
            isVisible={isPermitted}
            onClick={onClick}
            className="assignInstallerButton"
        />
    )
}
