import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'

import SetCodeButton from './SetCodeButton'
import Row from '../Row'

export default function CodeRow({user: {id, isPinSet}}) {
    const panelId = useRouterPanelId()

    return (
        <Row
            value={isPinSet ? '****' : ''}
            title={__('Code')}
            missingTitle={__('No code')}
            EditButton={<SetCodeButton panelId={panelId} panelUserId={id} />}
        />
    )
}

CodeRow.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isPinSet: PropTypes.bool,
    }).isRequired,
}
