import get from 'lodash-es/get'
import has from 'lodash-es/has'
import {send, receive} from 'modules/forms/actions'

import {observeBatch} from 'modules/batches/manager/actions'
import makeBatch from 'modules/batches/manager/helpers/makeBatch'

export const mergeCommonAction = (fn, name) => {
    const actions = {
        toString: () => name,
        REQUEST: ({type, payload}) => type === send.toString() && payload === name,
        FAILURE: ({type, payload}) =>
            type === receive.toString() &&
            payload &&
            payload.name === name &&
            !payload.success,
        SUCCESS: ({type, payload}) =>
            type === receive.toString() &&
            payload &&
            payload.name === name &&
            payload.success,
    }

    return Object.assign(fn, actions)
}

export default function createFormHandler(name, promiseCreator, metaCreator) {
    const fn =
        (...args) =>
        (dispatch) => {
            const meta = metaCreator && metaCreator(...args)

            dispatch(send(name))

            const promise = promiseCreator(...args)

            dispatch(receive(name, promise, meta))

            return promise
        }

    return mergeCommonAction(fn, name)
}

export const makeBatchAndObserveIt = ({dispatch, panelId, getState, processType}) => {
    const userId = get(getState(), 'auth.sign.user.id', null)
    const panelSerial = get(getState(), `panels.store.byIds.${panelId}.serial`, undefined)

    const batch = makeBatch({
        type: processType,
        panelIds: [panelId],
        userId,
        panelSerial,
    })

    dispatch(observeBatch(batch))

    return batch
}

export function createFormHandlerGeneratedProcess(
    name,
    promiseCreator,
    metaCreator,
    processType
) {
    const fn =
        (...args) =>
        (dispatch, getState) => {
            const meta = metaCreator && metaCreator(...args)

            if (!meta || !has(meta, 'panelId')) {
                throw new Error(`No panelId for handler:${name}`)
            }

            dispatch(send(name))

            const batch = makeBatchAndObserveIt({
                dispatch,
                panelId: meta.panelId,
                getState,
                processType,
            })

            const promise = promiseCreator(...args, batch.id)

            dispatch(receive(name, promise, meta))

            return promise
        }

    return mergeCommonAction(fn, name)
}

export function createFormHandlerHadCommand(name, promiseCreator, metaCreator) {
    const fn =
        (...args) =>
        (dispatch, getState) => {
            const meta = metaCreator && metaCreator(...args)

            dispatch(send(name))

            const promise = promiseCreator(...args, dispatch, getState)

            dispatch(receive(name, promise, meta))

            return promise
        }

    return mergeCommonAction(fn, name)
}
