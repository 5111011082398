import {makeBatchAndObserveIt} from 'modules/forms/helpers/createFormHandler'
import {
    setDimmerLevel,
    setHadOff,
    setHadOn,
    setThermostatMode,
    setThermostatTarget,
} from 'api/had'
import {HAD_STATE_OFF, HAD_STATE_ON} from 'constants/had/hadState'
import {PROCESS_TYPE_HAD_COMMAND} from 'constants/processTypes'

export const resolveHadState = (mode) => {
    switch (mode) {
        case HAD_STATE_ON:
            return setHadOn
        case HAD_STATE_OFF:
            return setHadOff
    }
}

export const resolveHadOutput = async ({command, params, dispatch, getState}) => {
    const batch = makeBatchAndObserveIt({
        dispatch,
        getState,
        panelId: params.panelId,
        processType: PROCESS_TYPE_HAD_COMMAND,
    })

    try {
        return await resolveHadState(command.mode)({
            panelId: params.panelId,
            id: params.hadId,
            batchId: batch.id,
            deviceType: params.deviceType,
            timestamp: command?.timestamp,
        })
    } catch (error) {
        return error
    }
}

export const resolveThermostatTarget = async ({command, params, dispatch, getState}) => {
    const batch = makeBatchAndObserveIt({
        dispatch,
        getState,
        panelId: params.panelId,
        processType: PROCESS_TYPE_HAD_COMMAND,
    })

    try {
        return await setThermostatTarget({
            panelId: params.panelId,
            deviceType: params.deviceType,
            hadId: params.hadId,
            mode: command.mode,
            temperature: command.target,
            batchId: batch.id,
        })
    } catch (error) {
        return error
    }
}

export const resolveThermostatMode = async ({command, params, dispatch, getState}) => {
    const batch = makeBatchAndObserveIt({
        dispatch,
        getState,
        panelId: params.panelId,
        processType: PROCESS_TYPE_HAD_COMMAND,
    })

    try {
        return await setThermostatMode({
            panelId: params.panelId,
            hadId: params.hadId,
            mode: command.mode,
            batchId: batch.id,
            deviceType: params.deviceType,
        })
    } catch (error) {
        return error
    }
}

export const resolveDimmer = async ({command, params, dispatch, getState}) => {
    const batch = makeBatchAndObserveIt({
        dispatch,
        getState,
        panelId: params.panelId,
        processType: PROCESS_TYPE_HAD_COMMAND,
    })

    try {
        return await setDimmerLevel({
            panelId: params.panelId,
            hadId: params.hadId,
            dimmerLevel: Number(command.level),
            batchId: batch.id,
            deviceType: params.deviceType,
        })
    } catch (error) {
        return error
    }
}
