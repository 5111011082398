import React, {useEffect, useState} from 'react'
import {Autocomplete, useLoadScript} from '@react-google-maps/api'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import {
    GOOGLE_MAPS_LIBRARY_DRAWING,
    GOOGLE_MAPS_LIBRARY_GEOMETRY,
    GOOGLE_MAPS_LIBRARY_PLACES,
} from 'components/Panel/CustomerMap'

export const AddressAutocomplete = ({googleToken, googleApiError, defaultValue}) => {
    const [isAuthFailure, setIsAuthFailure] = useState(false)
    const [addressInput, setAddressInput] = useState(defaultValue)
    const [isAddressInputChanged, setIsAddressInputChanged] = useState(false)
    const [libraries] = useState([
        [
            GOOGLE_MAPS_LIBRARY_PLACES,
            GOOGLE_MAPS_LIBRARY_DRAWING,
            GOOGLE_MAPS_LIBRARY_GEOMETRY,
        ],
    ])
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: googleToken,
        libraries: libraries,
    })

    const onChange = (e) => {
        setAddressInput(e.target.value)
        setIsAddressInputChanged(true)
    }

    useEffect(() => {
        window.gm_authFailure = () => {
            setIsAuthFailure(true)
        }
    }, [])

    if (isLoaded && !googleApiError && googleToken && !isAuthFailure) {
        return (
            isLoaded && (
                <Autocomplete className="form-field address-autoComplete">
                    <Input
                        label={__('Address or coordinates')}
                        name="address"
                        maxLength="200"
                        placeholder={''}
                        defaultValue={addressInput}
                        dataTestId="customer-address"
                        onChange={onChange}
                    />
                </Autocomplete>
            )
        )
    }

    return (
        <Input
            label={__('Address or coordinates')}
            name="address"
            maxLength="200"
            placeholder=""
            defaultValue={addressInput}
            dataTestId="customer-address"
            autoFocus={isAddressInputChanged}
        />
    )
}
