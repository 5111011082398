import SuggestFilter from 'components/Search/Filters/SuggestFilter'

export default class SuggestFilterNotEmpty extends SuggestFilter {
    getItems(values) {
        if (!Array.isArray(values)) {
            return []
        }

        return super.getItems(values.filter((value) => !!value))
    }
}
