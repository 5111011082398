import React, {useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Error from 'ipmp-react-ui/Error'
import {ScrollView} from 'ipmp-react-ui/Layout'

import __ from 'utils/i18n'
import {selectHadIsSwitchable, selectPanelHadList} from 'modules/panels/had/selector'
import {
    fetch,
    fetchSwitchable,
    startPoll,
    startPollSwitchable,
    stopPoll,
    stopPollSwitchable,
} from 'modules/panels/had/actions'
import withLoader from 'containers/withLoader'
import {hadCategory, isHadDisabled} from 'constants/had'

import HadContext from 'pages/Panel/Had/HadContext'
import hadDeviceControlResolve from 'pages/Panel/Had/List/HadDeviceControl'
import HadDeviceGeneric from 'components/Had/HadDeviceGeneric'
import HadListDisclaimer from 'pages/Panel/Had/List/HadListDisclaimer'
import {useActions} from 'modules/higherOrder/useActions'
import usePermission from 'hooks/usePermission'
import {pgmControl} from 'permissions/panel/output/actions'

const HadSection = ({category, categorizedList}) => {
    const {handleHadSelect} = useContext(HadContext)
    const {isSwitchable} = useSelector(selectHadIsSwitchable)

    return (
        <div className="devicesList-section">
            <h3 className="devicesList-section-title">{hadCategory(category)}</h3>
            <div className="devicesList-section-content">
                {categorizedList.get(category).map((had) => {
                    const HadDeviceControl = hadDeviceControlResolve(had.type)

                    return (
                        <HadDeviceGeneric
                            key={had.idWithDeviceType}
                            had={had}
                            name={had.name}
                            type={had.type}
                            userType={had.userType}
                            idWithDeviceType={had.idWithDeviceType}
                            id={had.id}
                            location={had.location}
                            partitions={had.partitions}
                            state={had.state}
                            RightCornerComponent={
                                <HadDeviceControl
                                    had={had}
                                    disabled={isHadDisabled({isSwitchable, had})}
                                />
                            }
                            onClick={handleHadSelect}
                        />
                    )
                })}
            </div>
        </div>
    )
}

HadSection.propTypes = {
    category: PropTypes.string.isRequired,
    categorizedList: PropTypes.object.isRequired,
}

const List = ({categorizedList}) => {
    return (
        <div className="devicesList devicesList--twoCols pgmList">
            {Array.from(categorizedList?.keys()).map((category) => (
                <HadSection
                    key={category}
                    category={category}
                    categorizedList={categorizedList}
                />
            ))}
        </div>
    )
}

List.propTypes = {
    categorizedList: PropTypes.object,
}

const HadListBase = ({
    categorizedList,
    startPoll,
    stopPoll,
    isAllowSwitchable,
    startPollSwitchable,
    stopPollSwitchable,
}) => {
    useEffect(() => {
        startPoll()

        if (isAllowSwitchable) {
            startPollSwitchable()
        }

        return () => {
            stopPoll()
            stopPollSwitchable()
        }
    }, [])

    return (
        <ScrollView>
            <HadListDisclaimer />
            {categorizedList.size !== 0 ? (
                <List categorizedList={categorizedList} />
            ) : null}
            {!categorizedList.size && <Error title={__('No Outputs')} />}
        </ScrollView>
    )
}

HadListBase.propTypes = {
    categorizedList: PropTypes.object,
}

const HadListWithLoader = withLoader(({fetch, fetchSwitchable, isAllowSwitchable}) => {
    fetch()
    if (isAllowSwitchable) {
        fetchSwitchable()
    }
})(HadListBase)

export default function HadList(props) {
    const actions = useActions({
        fetch,
        fetchSwitchable,
        startPoll,
        startPollSwitchable,
        stopPoll,
        stopPollSwitchable,
    })

    const {isPermitted} = usePermission(pgmControl)
    const hadList = useSelector(selectPanelHadList)
    const hadListProps = {...props, ...hadList, ...actions}

    return <HadListWithLoader isAllowSwitchable={isPermitted} {...hadListProps} />
}
