import React from 'react'
import {bindActionCreators} from 'redux'
import {useDispatch} from 'react-redux'

import {setStart} from 'modules/batches/list/actions'

import Pager from 'ipmp-react-ui/Pager'
import useBatches from 'hooks/pages/useBatches'

export default function BatchesPager() {
    const dispatch = useDispatch()
    const batchesProps = useBatches()

    const pagerProps = {
        ...batchesProps,
        ...bindActionCreators(
            {
                setStart,
                onPageChange: setStart,
            },
            dispatch
        ),
    }

    return <Pager {...pagerProps} />
}
