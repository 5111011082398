import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {withVisibility} from 'containers/withVisibility'
import {showEditHadIconModal} from 'modules/modals/actions'

import {genericEdit} from 'permissions/panel/output/actions'
import usePermission from 'hooks/usePermission'

const ButtonContainer = compose(
    withVisibility(),
    connect(null, (dispatch, {panelId, hadId, deviceType, defaultValue}) =>
        bindActionCreators(
            {
                onClick: () =>
                    showEditHadIconModal({panelId, hadId, deviceType, defaultValue}),
            },
            dispatch
        )
    )
)(Button)

export default function SetHadIconButton(props) {
    const {isVisible} = usePermission(genericEdit, 'isVisible')

    return <ButtonContainer isVisible={isVisible} Icon={IconEdit} flat small {...props} />
}

SetHadIconButton.propTypes = {
    panelId: PropTypes.number.isRequired,
    hadId: PropTypes.number.isRequired,
    deviceType: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
}
