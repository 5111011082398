import React from 'react'
import {useSelector} from 'react-redux'
import get from 'lodash-es/get'
import {compose} from 'redux'

import {ReactComponent as EnableIcon} from 'ipmp-react-ui/icons/run.svg'

import {setEnabled} from 'modules/users/store/actions'
import {enable as enablePermission} from 'permissions/users/selection'
import withSelection, {ifSelection} from 'containers/withSelection'
import {__} from 'utils/i18n'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {selectUsersListRows} from 'modules/users/list/selectors'

const selectedUsers = (selection) => {
    const {rows} = useSelector(selectUsersListRows)
    const selected = selection.toJS()

    return rows.filter((user) => {
        return selected.includes(user.id) && !user.isEnabled
    })
}

function EnableUserButton({selection}) {
    const {isPermitted} = usePermission(enablePermission)
    const enable = useActions(setEnabled)
    const selected = selectedUsers(selection)

    if (selected.length === 0) {
        return null
    }

    const isMassiveAction = selected.length > 1
    const userSelected = !isMassiveAction && selected[0]

    const getMessage = () => {
        return isMassiveAction
            ? __('Do you really want to enable %d selected users?', selected.length)
            : __('Do you really want to enable the user: %s?', userSelected.name)
    }

    const onEnabled = () => {
        enable(selection.toJS(), true)
    }

    if (!isPermitted) {
        return null
    }

    return (
        <WithConfirmationButton
            onClick={onEnabled}
            message={getMessage()}
            title={__('Enable users')}
            Icon={EnableIcon}
            label={__('Enable')}
            className="user-bar-button-enable"
        />
    )
}

export default compose(
    ifSelection((id, {users}) => !get(users, ['store', 'byIds', id, 'isEnabled'], false)),
    withSelection()
)(EnableUserButton)
