import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {selectGoogleSettings} from 'modules/system/settings/selectors'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class GoogleSettings extends SettingsPageBlock {
    static propTypes = {
        token: PropTypes.string,
    }

    render() {
        const {token} = this.props

        return <>{this.renderListItem(__('Google API key'), !!token)}</>
    }
}

export default compose(connect(selectGoogleSettings))(GoogleSettings)
