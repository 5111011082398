export const ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED =
    'INSTALLER_ACTIVATION_REQUIRED'
export const ACTIVATION_STATUS_MASTER_USER_ACTIVATION_REQUIRED =
    'MASTER_USER_ACTIVATION_REQUIRED'
export const ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED =
    'MASTER_USER_ACTIVATION_GUI_REQUIRED'
export const ACTIVATION_STATUS_NOT_PROVISIONED = 'NOT_PROVISIONED'
export const ACTIVATION_STATUS_NOT_DISCOVERED = 'NOT_DISCOVERED'
export const ACTIVATION_STATUS_OK = 'OK'

const SHOW_CONTENT_STATUSES = [ACTIVATION_STATUS_NOT_PROVISIONED, ACTIVATION_STATUS_OK]

export const isShowContent = (activationStatus) =>
    SHOW_CONTENT_STATUSES.includes(activationStatus)

export const isContentHidden = (activationStatus) => !isShowContent(activationStatus)
export const isActivationOk = (activationStatus) =>
    ACTIVATION_STATUS_OK === activationStatus
