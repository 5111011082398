import React from 'react'
import {compose} from 'redux'

import Page from 'ipmp-react-ui/Page'

import withProps from 'containers/withProps'
import TableWithBlind from 'components/Table/TableWithBlind'

import InteractiveUsersBar from './InteractiveUsersBar'
import InteractiveUsersSearch from './InteractiveUsersSearch'
import InteractiveUsersBlind from './InteractiveUsersBlind'
import InteractiveUsersTable from './InteractiveUsersTable'

const InteractiveUsersContent = () => {
    return (
        <TableWithBlind>
            {(props) => <InteractiveUsersBlind {...props} />}
            {(props) => <InteractiveUsersTable {...props} />}
        </TableWithBlind>
    )
}

export default compose(
    withProps({
        Bar: InteractiveUsersBar,
        Top: InteractiveUsersSearch,
        Content: InteractiveUsersContent,
    })
)(Page)
