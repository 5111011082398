import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Range from 'ipmp-react-ui/Range'
import thermostatTypeStateTitle, {
    getThermostatTemperature,
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
    resolveTemperatureKeyAccordingToMode,
} from 'constants/had/thermostat'

import __ from 'utils/i18n'

const HadThermostatControlFormTargetTemperature = ({
    state,
    userTemperatureScale,
    mode,
    name = 'target',
}) => {
    const possibleTargets = state?.thermostat?.modes[mode]

    return possibleTargets?.length
        ? possibleTargets?.map((targetMode) => {
              const min = state?.thermostat?.limits[targetMode]?.min
              const max = state?.thermostat?.limits[targetMode]?.max
              const label =
                  possibleTargets.length === 1
                      ? __('Thermostat control')
                      : thermostatTypeStateTitle(targetMode)
              const className = classnames('thermostatControlForm-range', {
                  'thermostatControlForm-range--heat':
                      targetMode === HAD_DEVICE_THERMOSTAT_MODE_HEAT,
                  'thermostatControlForm-range--cool':
                      targetMode === HAD_DEVICE_THERMOSTAT_MODE_COOL,
              })

              return (
                  <Range
                      key={`target_${targetMode}`}
                      name={`${name}_${targetMode}`}
                      className={className}
                      label={label}
                      min={min}
                      max={max}
                      defaultValue={getThermostatTemperature({
                          state,
                          userTemperatureScale,
                          tempKey: resolveTemperatureKeyAccordingToMode(targetMode),
                      })}
                  />
              )
          })
        : null
}

HadThermostatControlFormTargetTemperature.propTypes = {
    state: PropTypes.shape({
        thermostat: PropTypes.shape({
            limits: PropTypes.object,
        }),
    }).isRequired,
    mode: PropTypes.number.isRequired,
    userTemperatureScale: PropTypes.string.isRequired,
    name: PropTypes.string,
}

export default HadThermostatControlFormTargetTemperature
