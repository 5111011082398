import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {stations} from 'permissions/group/navigation'

import {reset as resetCentralStations} from 'modules/groups/centralStations/list/actions'
import {startPoll, stopPoll} from 'modules/groups/centralStations/list/actions'

import {fetch as fetchEventProfiles} from 'modules/centralStations/profiles/actions'

import {showEditCentralStationLinksModal} from 'modules/modals/actions'

import CentralStationCard from 'components/CentralStations/Card'

import {withRejection, withPermission} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Error from 'ipmp-react-ui/Error'

import {__} from 'utils/i18n'

class Stations extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        links: PropTypes.object.isRequired,
        stations: PropTypes.object.isRequired,
        profiles: PropTypes.object.isRequired,
    }

    renderList() {
        const {stations, links, profiles, edit, group} = this.props

        return Object.values(stations).map((station) => (
            <CentralStationCard
                {...{
                    key: station.id,
                    edit,
                    station,
                    profiles,
                    links: links[station.id] || [],
                    group,
                }}
            />
        ))
    }

    renderEmpty() {
        return <Error title={__('No central stations')} />
    }

    render() {
        const {stations} = this.props

        return (
            <ScrollView className="central-stations">
                <section className="group-central-stations">
                    {Object.values(stations).length
                        ? this.renderList()
                        : this.renderEmpty()}
                </section>
            </ScrollView>
        )
    }
}

export default compose(
    withPermission({isAllowed: stations}),
    withRejection(),
    connect(
        ({centralStations, groups}, {group}) => ({
            isLoading:
                groups.centralStations.list.isLoading ||
                centralStations.profiles.isLoading,
            error: groups.centralStations.list.error || centralStations.profiles.error,

            stations: groups.centralStations.store.byIds,
            links: groups.links[group.id],
            profiles: centralStations.profiles.byId,
        }),

        (dispatch, {group}) =>
            bindActionCreators(
                {
                    edit: (station) =>
                        showEditCentralStationLinksModal(group.id, station.id),
                    resetCentralStations,
                    fetchEventProfiles,
                    startPoll,
                    stopPoll,
                },
                dispatch
            )
    ),
    withLoader(
        ({resetCentralStations, fetchEventProfiles, startPoll}) => {
            resetCentralStations()
            fetchEventProfiles()
            startPoll()
        },
        ({stopPoll}) => {
            stopPoll()
        }
    )
)(Stations)
