import {selectPanelProcessesList} from 'modules/panels/processes/selectors'
import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import page from 'permissions/processes/page'

import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'

import {more, reset} from 'modules/panels/processes/actions'
import {stop} from 'modules/processes/store/actions'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'
import ProcessesTable from './ProcessesTable'
import {__} from 'utils/i18n'
import Layout from 'ipmp-react-ui/Layout'

const LoadingButton = withLoader()(Button)

class Process extends Component {
    renderMoreBtn = () => {
        const {hasMore, more, isLoadingMore} = this.props

        if (!hasMore) {
            return null
        }

        return (
            <div className="btnHolder">
                {
                    <LoadingButton onClick={more} isLoading={isLoadingMore}>
                        {__('More')}
                    </LoadingButton>
                }
            </div>
        )
    }

    render() {
        const {rows, stop} = this.props

        return (
            <Layout className="panel-page-processes-tab">
                <ScrollView>
                    <ProcessesTable rows={rows} stop={stop} />
                    {this.renderMoreBtn()}
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    connect(
        (state) => ({
            ...state.panels.processes,
            rows: selectPanelProcessesList(state),
        }),
        (dispatch, {match}) => {
            const id = parseInt(match.params.id)

            return bindActionCreators(
                {
                    reset: () => reset(id),
                    more,
                    stop,
                },
                dispatch
            )
        }
    ),
    withLoader(({reset}) => reset())
)(Process)
