import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class CellularConnected extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            isAutoEnrollmentEnabled: PropTypes.bool,
            messageBroker: PropTypes.number,
            retriesMax: PropTypes.number,
            retryInterval: PropTypes.number,
            wakeupOnUserDiscovery: PropTypes.bool,
        }),
        messageBroker: PropTypes.object,
    }

    render() {
        const {data, messageBroker} = this.props
        return (
            <>
                {this.renderListItem(
                    __('Enable Auto-Enroll'),
                    data.isAutoEnrollmentEnabled,
                    true
                )}
                {this.renderListItem(
                    __('Message Broker for WakeUp'),
                    messageBroker && messageBroker.name,
                    true
                )}
                {this.renderListItem(__('Number of Retries'), data.retriesMax, true)}
                {this.renderListItem(
                    __('Retries Interval [seconds]'),
                    data.retryInterval,
                    true
                )}
                {this.renderListItem(
                    __('WakeUp panel on user initiated discovery'),
                    data.wakeupOnUserDiscovery
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {cellularConnected, messageBrokers},
            },
        }) => {
            return {
                messageBroker: messageBrokers.data.find(
                    (broker) => broker.id == cellularConnected.data.messageBroker
                ),
                ...cellularConnected,
            }
        }
    )
)(CellularConnected)
