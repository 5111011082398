import {PROCESS_TYPE_PMAXANONYMOUS, PROCESS_TYPE_SET_LABEL} from 'constants/processTypes'

import {get, post} from 'api/http'
import {mapProcess} from 'api/processes'
import mapValidationErrors from 'api/base/mapValidationErrors'

import * as devicesMap from './devices.map'

import {TEMPERATURE} from 'constants/meteoTypes'

export function retrieveDevices(unitId) {
    return get('/unit_diagnostic/list', {unt_id: unitId}).then(devicesMap.map)
}

export function setBypassZones(panelId, zoneIds, enabled, batchId) {
    return post('/unit_diagnostic/setbypasszones', {
        unt_id: panelId,
        utz_zone_id: zoneIds,
        enabled,
        batch_id: batchId,
    })
}

export function setSoakZone(deviceId, enabled, batchId) {
    return post('/unit_diagnostic/setsoakzone', {
        utz_id: deviceId,
        enabled,
        batch_id: batchId,
    }).then(mapProcess)
}

export function removeDevice(deviceId, batchId) {
    return post('/unit_diagnostic/zoneremove', {
        utz_id: deviceId,
        batch_id: batchId,
    }).then(mapProcess)
}

export function setRarelyUsedZone(panelId, deviceId, state) {
    return post('/unit_diagnostic/rarelyused', {
        unt_id: panelId,
        utz_id: deviceId,
        utz_rarely_used: state,
    })
}

export function getWalktestStatus(unitId) {
    return get('/unit_diagnostic/walktest', {unt_id: unitId}).then(
        ({process, ...data}) => ({
            process: process && mapProcess(process),
            ...data,
        })
    )
}

export function startWalktest(unitId, batchId) {
    return post('/unit_diagnostic/walkteststart', {
        unt_id: unitId,
        batch_id: batchId,
    }).then(mapProcess)
}

export function stopWalktest(unitId) {
    return post('/unit_diagnostic/walkteststop', {unt_id: unitId})
}

export function addDevice(panelId, zoneId, deviceId, partitions, userId, batchId) {
    const form = {
        deviceId,
        zoneId,
    }
    if (partitions) {
        form.partitions = partitions
    }
    if (userId) {
        form.userId = userId
    }

    return post('/unit_diagnostic/zoneadd', {
        unt_id: panelId,
        form,
        batch_id: batchId,
    })
        .then(({process, device}) => ({
            process: mapProcess(process),
            device: devicesMap.mapDevice(device),
        }))
        .catch(mapValidationErrors)
}

export function refreshZoneRssi(panelId, batchId) {
    return post('/unit_diagnostic/zonerssi', {
        unt_id: panelId,
        batch_id: batchId,
    }).then(mapProcess)
}

export function refreshGSM(panelId, batchId) {
    return post('/unit_diagnostic/refreshgsm', {
        unt_id: panelId,
        batch_id: batchId,
    }).then(mapProcess)
}

export function fetchMeteo(panelId, deviceId, type = TEMPERATURE) {
    return get(`/unit_diagnostic/${type}`, {
        unt_id: panelId,
        utz_id: deviceId,
        period: 'all',
    })
}

export function renameEntity(panelId, entityClass, entityId, name, batchId) {
    return post('/unit_diagnostic/setlabel', {
        unitId: panelId,
        entityClass,
        entityId,
        name: name === null ? '' : name,
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .then((process) => {
            // workaround
            if (process.type === PROCESS_TYPE_PMAXANONYMOUS) {
                process.type = PROCESS_TYPE_SET_LABEL
            }

            return process
        })
        .catch(mapValidationErrors)
}

export function renamePartition(panelId, partitionId, name, batchId) {
    return renameEntity(panelId, 'partition', partitionId, name, batchId)
}

export function fetchDeviceReferences(vendor) {
    return get('/unit_diagnostic/devicereferences', {
        vendor: devicesMap.mapVendor(vendor),
    }).then((references) => references.map(devicesMap.mapReference))
}

export function dlsConnectSmartCommunicator({
    panelId,
    host,
    port,
    password,
    isEncrypted,
    timeout,
    panelType,
    connectionSpeed,
    batchId,
}) {
    return post(`/unit/${panelId}/dls/connect/smart_communicator`, {
        host,
        port: port,
        password,
        encrypted: isEncrypted === 'on',
        timeout,
        panel_type: panelType,
        serial_speed: connectionSpeed,
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch(mapValidationErrors)
}

export function dlsConnectDsc(
    panelId,
    host,
    port,
    networkProtocol,
    applicationProtocol,
    batchId
) {
    return post(`/unit/${panelId}/dls/connect/dsc`, {
        host,
        port: Number(port),
        network_protocol: networkProtocol,
        application_protocol: applicationProtocol,
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch(mapValidationErrors)
}

export function dlsDisconnect(panelId, batchId) {
    return post(`/unit/${panelId}/dls/disconnect`, {batch_id: batchId})
        .then((process) => mapProcess(process))
        .catch(mapValidationErrors)
}
