import Filter from 'components/Search/Filters/Filter'
import BoardSuggestFilter from 'components/Search/Board/BoardSuggestFilter'

export default class SuggestFilter extends Filter {
    hasSuggests = true

    Component = BoardSuggestFilter

    getItems(values) {
        if (!values) {
            return []
        }

        return values.map(this.createItem.bind(this))
    }

    isFilterExistMoreValues = ({selected, suggests}) => {
        if (!suggests || !selected || !selected.length) {
            return true
        }

        const suggest = suggests[this.name]

        if (!suggest) {
            return true
        }

        const selectedFilterValues = selected
            .filter((selectedFilter) => selectedFilter.name === this.name)
            .map((selectedFilter) => selectedFilter.value)

        const isAllValuesSelected =
            suggest.values.filter((value) => {
                return !selectedFilterValues.includes(value)
            }).length > 0

        return isAllValuesSelected || suggest.hasMore
    }
}
