import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import ModalCardForm from 'ui/ModalCardForm'
import {set} from 'immutable-modify'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import {__} from 'utils/i18n'
import _get from 'lodash-es/get'

export const EVENT_PROFILE = 'eventProfile'
export const NOTIFICATION_PROFILE = 'notificationProfile'

export default class EventsFieldset extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        header: PropTypes.string.isRequired,
        fieldGroupKey: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        profiles: PropTypes.object.isRequired,
        availableProfiles: PropTypes.object.isRequired,
    }

    constructor(props, context) {
        super(props, context)
        this.state = {
            data: props.data,
            enabledAll: this.checkSelectAll(),
        }
    }

    handleFieldChanged = (path, value) => {
        const newState = set(this.state, `data.${path}`, value)

        this.setState({...newState, enabledAll: this.checkSelectAll(newState)})
    }

    checkSelectAll = (state = null) => {
        const profiles = (state || this.props).data[this.props.fieldGroupKey]
        const availableProfilesKeys = Object.keys(this.props.availableProfiles)

        return !availableProfilesKeys.some((key) => !profiles[key])
    }

    handleSelectAll = (e) => {
        const {fieldGroupKey} = this.props
        const profilesData = {...this.state.data[fieldGroupKey]}
        const value = e.target.checked

        Object.keys(this.props.availableProfiles).map(
            (profile) => (profilesData[profile] = value)
        )

        this.setState((state) => ({
            ...state,
            data: {
                ...state.data,
                [fieldGroupKey]: profilesData,
            },
            enabledAll: value,
        }))
    }

    handleSubmit = () => {
        this.props.handle(this.state.data)
    }

    renderItems() {
        const {availableProfiles, fieldGroupKey} = this.props
        const profiles = this.state.data[fieldGroupKey]

        return Object.keys(availableProfiles).map((profile) => (
            <Togglebox
                key={profile}
                name="event"
                label={availableProfiles[profile]}
                checked={_get(profiles, profile, false)}
                defaultChecked={_get(profiles, profile, false)}
                onChange={(e) => {
                    this.handleFieldChanged(
                        `${fieldGroupKey}.${profile}`,
                        e.target.checked
                    )
                }}
            />
        ))
    }

    render() {
        const {enabledAll} = this.state

        return (
            <ModalCardForm
                wide
                confirmOnDismiss
                onSubmit={this.handleSubmit}
                {...this.props}
            >
                <div className={'notifications-profile-header'}>
                    <Togglebox
                        name="allNotification"
                        label={__('All-Notification')}
                        checked={enabledAll}
                        defaultChecked={enabledAll}
                        onChange={this.handleSelectAll}
                    />
                </div>
                <div className="cols--stretch groupForm-grid-notifications">
                    {this.renderItems()}
                </div>
            </ModalCardForm>
        )
    }
}
