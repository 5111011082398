import React from 'react'

import {ReactComponent as PgmIcon} from 'assets/had/Generic.svg'
import {ReactComponent as DoorlockIcon} from 'assets/had/Doorlock.svg'
import {ReactComponent as SwitcherIcon} from 'assets/had/Switcher.svg'
import {ReactComponent as DimmerIcon} from 'assets/had/Dimmer.svg'
import {ReactComponent as ThermostatIcon} from 'assets/had/Thermostat.svg'
import {ReactComponent as SocketIcon} from 'assets/had/Smart_Socket.svg'
import {ReactComponent as WaterValveIcon} from 'assets/had/Water_valve.svg'
import {ReactComponent as GarageDoorIcon} from 'assets/had/Garage_Door.svg'
import {ReactComponent as LightIcon} from 'assets/had/Light.svg'
import {ReactComponent as GenericIcon} from 'assets/had/Generic.svg'

import thermostatTypeStateTitle from 'constants/had/thermostat'
import doorlockTypeStateTitle from 'constants/had/doorlock'
import switcherTypeStateTitle from 'constants/had/switcher'
import {warn} from 'utils/log'
import __, {__n} from 'utils/i18n'
import resolveMapTranslation from 'constants/utils/resolveMapTranslation'

export const HAD_OPTION_TYPE_BOOLEAN = 'boolean'
export const HAD_OPTION_TYPE_NUMBER = 'number'
export const HAD_OPTION_TYPE_UNSIGNED_8 = 'unsigned [8]'
export const HAD_OPTION_TYPE_NUMBER_ARRAY = 'number []'
export const HAD_OPTION_TYPE_BOOLEAN_8 = 'boolean [8]'

export const HAD_CATEGORY_PGM = 'PGM'
export const HAD_CATEGORY_OUTPUT = 'OUTPUT'
export const HAD_CATEGORY_INPUT = 'INPUT'
export const HAD_CATEGORY_Z_WAVE = 'Z_WAVE'

export const HAD_TYPE_PGM = null
export const HAD_TYPE_SWITCHER = 0
export const HAD_TYPE_STATELESS_SWITCHER = 1
export const HAD_TYPE_DIMMER = 2
export const HAD_TYPE_DOORLOCK = 3
export const HAD_TYPE_THERMOSTAT = 4
export const HAD_TYPE_SOCKET = 5

export const HAD_STATE_PARAMETER_NEVER_TURN = 'NEVER_TURN'
export const HAD_STATE_PARAMETER_CUSTOM = 'CUSTOM'
export const HAD_STATE_PARAMETER_CUSTOM_MAXIMUM_TIMEOUT = 1092
export const HAD_STATE_PARAMETER_ON_1 = 1
export const HAD_STATE_PARAMETER_ON_5 = 5
export const HAD_STATE_PARAMETER_ON_10 = 10
export const HAD_STATE_PARAMETER_ON_30 = 30
export const HAD_STATE_PARAMETER_ON_60 = 60

export const SUPPORTED_COMMAND_HAD_OUTPUT = 0

export const HAD_CONFIGURABLE_ICON_LIGHT = 'light'
export const HAD_CONFIGURABLE_ICON_SWITCHER = 'switch'
export const HAD_CONFIGURABLE_ICON_WATER_VALVE = 'water'
export const HAD_CONFIGURABLE_ICON_GARAGE_DOOR = 'gate'
export const HAD_CONFIGURABLE_ICON_GENERIC = 'pgm'

export const hadTypes = [
    HAD_TYPE_PGM,
    HAD_TYPE_SWITCHER,
    HAD_TYPE_STATELESS_SWITCHER,
    HAD_TYPE_DIMMER,
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_THERMOSTAT,
    HAD_TYPE_SOCKET,
]

export const categorySections = [
    HAD_CATEGORY_PGM,
    HAD_CATEGORY_OUTPUT,
    HAD_CATEGORY_INPUT,
    HAD_CATEGORY_Z_WAVE,
]

export const canDisableSwitchCategories = [
    HAD_CATEGORY_PGM,
    HAD_CATEGORY_OUTPUT,
    HAD_CATEGORY_INPUT,
]

export const configurableCategories = [
    HAD_CATEGORY_PGM,
    HAD_CATEGORY_OUTPUT,
    HAD_CATEGORY_INPUT,
]

const hadCategoryMap = new Map([
    [HAD_CATEGORY_PGM, () => __('Pgm')],
    [HAD_CATEGORY_OUTPUT, () => __('Output')],
    [HAD_CATEGORY_INPUT, () => __('Input')],
    [HAD_CATEGORY_Z_WAVE, () => __('Z-Waves')],
])

export const hadCategory = (category) => {
    let result = hadCategoryMap.get(category)

    if (!result) {
        warn(`No home automation category title for: ${category}`)
        result = () => category
    }

    return result()
}

const hadTitleMap = new Map([
    [HAD_TYPE_PGM, () => __('Generic')],
    [HAD_TYPE_SWITCHER, () => __('Switcher')],
    [HAD_TYPE_STATELESS_SWITCHER, () => __('Stateless Switcher')],
    [HAD_TYPE_DIMMER, () => __('Light')],
    [HAD_TYPE_DOORLOCK, () => __('Doorlock')],
    [HAD_TYPE_THERMOSTAT, () => __('Thermostat')],
    [HAD_TYPE_SOCKET, () => __('Socket')],
])

export const hadTitle = (hadType) => {
    let result = hadTitleMap.get(hadType)

    if (!result) {
        warn(`No title for home automation device type: ${hadType}`)
        result = hadTitleMap.get(HAD_TYPE_PGM)
    }

    return result()
}

const hadIconMap = new Map([
    [HAD_TYPE_PGM, <PgmIcon />],
    [HAD_TYPE_SWITCHER, <SwitcherIcon />],
    [HAD_TYPE_STATELESS_SWITCHER, <SwitcherIcon />],
    [HAD_TYPE_DIMMER, <DimmerIcon />],
    [HAD_TYPE_DOORLOCK, <DoorlockIcon />],
    [HAD_TYPE_THERMOSTAT, <ThermostatIcon />],
    [HAD_TYPE_SOCKET, <SocketIcon />],
])

export const hadIcon = (hadType) => {
    let result = hadIconMap.get(hadType)

    if (!result) {
        warn(`No icon for home automation device type: ${hadType}`)
        result = hadIconMap.get(HAD_TYPE_PGM)
    }

    return result
}

export const hadConfigurableIcons = {
    [HAD_CONFIGURABLE_ICON_LIGHT]: <LightIcon />,
    [HAD_CONFIGURABLE_ICON_SWITCHER]: <SwitcherIcon />,
    [HAD_CONFIGURABLE_ICON_WATER_VALVE]: <WaterValveIcon />,
    [HAD_CONFIGURABLE_ICON_GARAGE_DOOR]: <GarageDoorIcon />,
    [HAD_CONFIGURABLE_ICON_GENERIC]: <GenericIcon />,
}

export const hadConfigurableIconValues = [
    HAD_CONFIGURABLE_ICON_LIGHT,
    HAD_CONFIGURABLE_ICON_SWITCHER,
    HAD_CONFIGURABLE_ICON_WATER_VALVE,
    HAD_CONFIGURABLE_ICON_GARAGE_DOOR,
    HAD_CONFIGURABLE_ICON_GENERIC,
]

export const hadConfigurableIconTitles = {
    [HAD_CONFIGURABLE_ICON_LIGHT]: __('Light'),
    [HAD_CONFIGURABLE_ICON_SWITCHER]: __('Switcher'),
    [HAD_CONFIGURABLE_ICON_WATER_VALVE]: __('Water Valve'),
    [HAD_CONFIGURABLE_ICON_GARAGE_DOOR]: __('Garage Door'),
    [HAD_CONFIGURABLE_ICON_GENERIC]: __('Generic'),
}

export const isHadConfigurable = (had) =>
    configurableCategories.includes(had?.homeAutomationDeviceClass)

export const hadConfigurableIcon = (key) => {
    let result = hadConfigurableIcons[key]

    if (!result) {
        warn(`No icon for home automation device: ${key}`)
        result = hadConfigurableIcons[HAD_CONFIGURABLE_ICON_GENERIC]
    }

    return result
}

export const validConfigurableIconValue = (value) =>
    hadConfigurableIconValues.includes(value) ? value : HAD_CONFIGURABLE_ICON_GENERIC

export const hadConfigurableIconTitle = (key) => {
    let result = hadConfigurableIconTitles[key]

    if (!result) {
        warn(`No title for home automation device user type: ${key}`)
        result = hadConfigurableIconTitles[HAD_CONFIGURABLE_ICON_GENERIC]
    }

    return result
}

export const hadStateChangeParams = [
    HAD_STATE_PARAMETER_NEVER_TURN,
    HAD_STATE_PARAMETER_ON_1,
    HAD_STATE_PARAMETER_ON_5,
    HAD_STATE_PARAMETER_ON_10,
    HAD_STATE_PARAMETER_ON_30,
    HAD_STATE_PARAMETER_ON_60,
    HAD_STATE_PARAMETER_CUSTOM,
]

export const isParameterCustom = (parameter) => HAD_STATE_PARAMETER_CUSTOM === parameter

const forNMinutes = (n) => __n('On for %d minute', 'On for %d minutes', n)

export const pgmStateParamsTitleMap = new Map([
    [HAD_STATE_PARAMETER_NEVER_TURN, () => __('Never Turn Off')],
    [HAD_STATE_PARAMETER_ON_1, () => forNMinutes(HAD_STATE_PARAMETER_ON_1)],
    [HAD_STATE_PARAMETER_ON_5, () => forNMinutes(HAD_STATE_PARAMETER_ON_5)],
    [HAD_STATE_PARAMETER_ON_10, () => forNMinutes(HAD_STATE_PARAMETER_ON_10)],
    [HAD_STATE_PARAMETER_ON_30, () => forNMinutes(HAD_STATE_PARAMETER_ON_30)],
    [HAD_STATE_PARAMETER_ON_60, () => forNMinutes(HAD_STATE_PARAMETER_ON_60)],
    [HAD_STATE_PARAMETER_CUSTOM, () => __('Custom')],
])

export const pgmStateParamsTitle = resolveMapTranslation(
    pgmStateParamsTitleMap,
    (key) => {
        warn(`No had PGM state params title for key: ${key}`)
    }
)

const convertMinutesToSeconds = (minutes) => Number(minutes) * 60

export const getPgmStateParamTimestamp = ({parameters, customTime}) => {
    switch (parameters) {
        case HAD_STATE_PARAMETER_CUSTOM:
            return convertMinutesToSeconds(customTime)
        case HAD_STATE_PARAMETER_ON_1:
        case HAD_STATE_PARAMETER_ON_5:
        case HAD_STATE_PARAMETER_ON_10:
        case HAD_STATE_PARAMETER_ON_30:
        case HAD_STATE_PARAMETER_ON_60:
            return convertMinutesToSeconds(parameters)
        case HAD_STATE_PARAMETER_NEVER_TURN:
        default:
            return null
    }
}

const hadStateTitleByTypeMap = new Map([
    [HAD_TYPE_PGM, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_SWITCHER, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_DIMMER, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_DOORLOCK, (state) => doorlockTypeStateTitle(state)],
    [HAD_TYPE_THERMOSTAT, (state) => thermostatTypeStateTitle(state?.thermostat?.mode)],
])

export const hadStateTitleByType = ({hadType, state}) => {
    let result = hadStateTitleByTypeMap.get(hadType)

    if (!result) {
        warn(`No had state title for home automation device type: ${hadType}`)
        result = (state) => switcherTypeStateTitle(state)
    }

    return result(state)
}

export const isSwitchableCategories = (categories: Array) =>
    categories.some((category) => canDisableSwitchCategories.includes(category))

export const isHadDisabled = ({isSwitchable, had}) => {
    return (
        canDisableSwitchCategories.includes(had?.homeAutomationDeviceClass) &&
        !isSwitchable
    )
}
