import * as api from 'api/had'
import {put, select} from 'redux-saga/effects'
import {PROCESS_TYPE_HAD_COMMAND} from 'constants/processTypes'
import {getPanelId} from 'utils/panelIdMatchSelector'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import generateProcess from 'modules/processes/manager/generateProcess'
import {snackShow} from 'modules/snacks'
import {getPgmStateParamTimestamp} from 'constants/had'

export default function* watchOnWithTimer({
    payload: {id, parameters, customTime, deviceType},
}) {
    try {
        const {panelId} = yield select((state) => ({
            panelId: getPanelId(state),
        }))
        const {batchId} = yield generateBatchForOneProcess(
            PROCESS_TYPE_HAD_COMMAND,
            panelId
        )

        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)

        yield execute(api.setHadOn, {
            id,
            panelId,
            deviceType,
            timestamp: getPgmStateParamTimestamp({parameters, customTime}),
            batchId,
        })
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
