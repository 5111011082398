import React from 'react'
import PropTypes from 'prop-types'

import {getCentralStationStatusDefinitionParams} from 'constants/centralStation'

import Definition from 'ipmp-react-ui/Definition'

const CentralStationStatus = ({status}) => {
    const params = getCentralStationStatusDefinitionParams(status)

    if (!params) {
        return status ?? null
    }

    return (
        <Definition
            inline
            title={params.title()}
            Icon={params.Icon}
            iconClassName={params.iconClassName}
            className="central-stations-table-definition-icon"
        />
    )
}

CentralStationStatus.propTypes = {
    status: PropTypes.string,
}

export default CentralStationStatus
