import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import list from 'permissions/installers/list'
import selection from 'permissions/installers/selection'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import withInstallers from 'containers/withInstallers'
import {withPermission, withRejection} from 'containers/withPermission'
import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'

import InstallerStatusChanger from './TableCell/InstallerStatusChanger'

import Table from 'ipmp-react-ui/Table'

import {__, __n} from 'utils/i18n'
import withLifeCycle from 'containers/withLifeCycle'

const columns = [
    {
        name: () => __('Installer'),
        render: ({name, email}) => <Definition title={name} detail={email} />,
    },
    {
        name: () => __('Telephone'),
        render: ({telephone}) => telephone,
    },
    {
        name: () => __('Created'),
        render: ({createdAt}) => <DateTime datetime={createdAt} />,
    },
    {
        name: () => __('Last Login'),
        render: ({lastLogin}) => <DateTime datetime={lastLogin} />,
    },
    {
        name: () => __('Panels'),
        render: ({panels}) => {
            if (!panels) {
                return <span className="empty">{__('No panels')}</span>
            }

            return (
                <Definition
                    title={panels.length}
                    detail={__n('panel', 'panels', panels.length)}
                />
            )
        },
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: InstallerStatusChanger,
    },
]

const InstallersTable = compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withInstallers(),
    withSelection(),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(({init}) => init()),
    withProps(() => ({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No installers found'),
        className: 'accounts-page-installers-table',
    }))
)(Table)

InstallersTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
}

export default InstallersTable
