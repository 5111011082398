import get from 'lodash-es/get'
import {warn} from 'utils/log'

const createMatcher = (permissions) => (restriction) => {
    if (typeof restriction === 'function') {
        return restriction(permissions)
    }

    const result = get(permissions, restriction)

    if (typeof result === 'boolean') {
        return result
    }

    if (typeof result === 'undefined') {
        let key = restriction
        let tree

        while (key && tree === undefined) {
            key = key.replace(/\.?([^.]+)$/, '')
            tree = get(permissions, key)
        }

        if (!tree) {
            key = key.replace(/\..*$/, '')
            warn(`${restriction} permission doesn't exists. first key '${key}' not found`)
        } else {
            warn(
                `${restriction} permission doesn't exists. unresolved tree was:\n${key}: ${JSON.stringify(
                    tree,
                    null,
                    2
                )}`
            )
        }

        return false
    }

    if (typeof result === 'object') {
        warn(
            `${restriction} permission is not leaf. Found:\n${restriction}: ${JSON.stringify(
                result,
                null,
                2
            )}`
        )
    }
}

const createChecker =
    (executor, join) =>
    (...restrictions) => {
        let cache = null

        const fn = (permissions) => {
            if (cache && cache.permissions === permissions) {
                return cache.result
            }

            const result = executor.call(restrictions, createMatcher(permissions))

            cache = {
                permissions,
                result,
            }

            return result
        }

        fn.toString = stringify(restrictions, join)

        return fn
    }

export const every = createChecker(Array.prototype.every, ' && ')
export const any = createChecker(Array.prototype.some, ' || ')

function stringify(restrictions, operation) {
    return () => {
        if (restrictions.length > 1) {
            return '(' + restrictions.join(operation) + ')'
        } else {
            return restrictions.join()
        }
    }
}

export default function createPermission(...restrictions) {
    return every(...restrictions)
}
