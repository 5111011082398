import React from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import {InitiateRemoteInspectionButton as InitiateRemoteInspectionButtonBase} from 'pages/RemoteInspection/Bar/InitiateRemoteInspectionButton'
import {ifSelection} from 'containers/withSelection'
import {__} from 'utils/i18n'
import {RESULT_RRI_PROGRESS} from 'constants/remoteInspection'
import {withSelectionFeature} from 'containers/withFeature'
import {REMOTE_INSPECTION} from 'constants/features'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const InitiateRemoteInspectionButtonSelection = compose(
    ifSelection(
        (id, {panels}) =>
            get(panels, ['store', 'byIds', id, 'rri']) !== RESULT_RRI_PROGRESS
    ),
    withSelectionFeature(REMOTE_INSPECTION)
)(InitiateRemoteInspectionButtonBase)

export default function InitiateRemoteInspectionButton() {
    const {initiateNowBatch} = useRemoteInspections()

    return (
        <InitiateRemoteInspectionButtonSelection
            onClick={initiateNowBatch}
            label={__('Run inspection')}
        />
    )
}
