import React from 'react'

import {showSuspendFaultsModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {suspendFaults} from 'permissions/panels/selection'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

const MenuItemVisible = withVisibility()(MenuItem)

export default function SuspendFaultsItem() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(suspendFaults)
    const {onClick} = useActions({
        onClick: () => showSuspendFaultsModal([panelId]),
    })

    return (
        <MenuItemVisible
            onClick={onClick}
            isVisible={isPermitted}
            children={__('Suspend Faults')}
        />
    )
}
