import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class Advertisement extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            url: PropTypes.string,
        }),
    }

    render() {
        const {data} = this.props
        return <>{this.renderListItem(__('URL'), data.url)}</>
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {advertisement},
            },
        }) => ({...advertisement})
    )
)(Advertisement)
