import React from 'react'

import {schedule} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'

import ScheduleOrCancelRRIBase from 'components/Cell/RemoteInspections/Actions/ScheduleOrCancelRRI'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ScheduleOrCancelRRIVisibility = withVisibility()(ScheduleOrCancelRRIBase)

export default function Schedule(props) {
    const {isPermitted} = usePermission(schedule)
    const remoteInspections = useRemoteInspections()
    const componentProps = {...remoteInspections, ...props}

    return <ScheduleOrCancelRRIVisibility {...componentProps} isVisible={isPermitted} />
}
