import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {hadConfigurableIcon, hadConfigurableIconTitle} from 'constants/had'

import HadConfigurationTabTableRow from './HadConfigurationTabTableRow'
import SetHadLabelButton from './SetHadLabelButton'
import SetHadIconButton from './SetHadIconButton'

const HadConfigurationTabTable = ({had}) => {
    const panelId = useRouterPanelId()

    const iconValue = (
        <>
            <div className="hadConfigurationTabTable-iconValue">
                {hadConfigurableIcon(had.userType)}
            </div>
            {hadConfigurableIconTitle(had.userType)}
        </>
    )

    return (
        <div className="hadConfigurationTabTable table">
            <HadConfigurationTabTableRow
                value={iconValue}
                title={__('Icon')}
                EditButton={
                    <SetHadIconButton
                        panelId={panelId}
                        hadId={had?.id}
                        deviceType={had?.deviceType}
                        defaultValue={had?.userType}
                    />
                }
            />
            <HadConfigurationTabTableRow
                value={had?.name}
                title={__('Label')}
                missingTitle={__('No Label')}
                EditButton={
                    <SetHadLabelButton
                        panelId={panelId}
                        hadId={had?.id}
                        deviceType={had?.deviceType}
                        defaultValue={had?.name}
                        disabled={!had?.isLabelEditable}
                    />
                }
            />
        </div>
    )
}

HadConfigurationTabTable.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        deviceType: PropTypes.string,
        userType: PropTypes.string,
    }).isRequired,
}

export default HadConfigurationTabTable
