import React from 'react'
import PropTypes from 'prop-types'

import ResetPasswordButton from './ResetPasswordButton'
import RemoveCustomerButton from './RemoveCustomerButton'

const CardActions = ({user, onClose}) => {
    return (
        <div className="blindModal-card-actions-buttons">
            <RemoveCustomerButton user={user} onClose={onClose} />
            <ResetPasswordButton user={user} />
        </div>
    )
}

CardActions.propTypes = {
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default CardActions
