import React from 'react'

import Menu from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import {eventsInitiate} from 'permissions/panel/actions'
import {get} from 'permissions/panel/state/actions'
import EventItem from './EmergenciesItems/EventItem'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {ALARM_EMERGENCY, ALARM_FIRE, ALARM_PANIC} from 'constants/alarmType'
import {withVisibility} from 'containers/withVisibility'

const DropDownButtonVisibility = withVisibility()(DropDownButton)

export default function EmergenciesDropDown() {
    const {isPermitted: eventInitiatePermitted} = usePermission(eventsInitiate)
    const {isPermitted: getPartitions} = usePermission(get)
    return (
        <DropDownButtonVisibility
            label={__('Emergencies')}
            isVisible={eventInitiatePermitted && getPartitions}
        >
            <Menu>
                <EventItem eventType={ALARM_FIRE} />
                <EventItem eventType={ALARM_PANIC} />
                <EventItem eventType={ALARM_EMERGENCY} />
            </Menu>
        </DropDownButtonVisibility>
    )
}
