import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const INSTALLER_TYPE_MASTER = 0
export const INSTALLER_TYPE_INSTALLER = 1
export const INSTALLER_TYPE_DEALER = 2

export const MASTER_INSTALLER = 'Master'
export const INSTALLER = 'Installer'
export const DEALER = 'Dealer'

export const EDITABLE_FEATURES_IEF_CODE = 'code'
export const EDITABLE_FEATURES_IEF_TYPE = 'type'

export const hasIefCode = (editableFeatures) => {
    return editableFeatures.includes(EDITABLE_FEATURES_IEF_CODE)
}

export const hasIefType = (editableFeatures) => {
    return editableFeatures.includes(EDITABLE_FEATURES_IEF_TYPE)
}

const installerRoleMap = new Map([
    [MASTER_INSTALLER, () => __('Master')],
    [INSTALLER, () => __('Installer')],
    [DEALER, () => __('Dealer')],
])

export const getInstallerRoleTitle = (role) => {
    if (installerRoleMap.has(role)) {
        return installerRoleMap.get(role)()
    }

    warn(`No role title for role: ${role}`)
    return role
}
