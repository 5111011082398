import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {get, post} from 'api/http'
import {mapGroup, mapValidatorCommunicatorError, mapLanguage, keysMap} from './groups.map'

export function fetch({start = 0, perPage: count = 10, query, exclude = []}) {
    return get('/group/getallgroups', {start, count, query, 'exclude[]': exclude}).then(
        createRowsResponseParser(mapGroup)
    )
}

export function remove(groupIds) {
    return post('/group/remove', {groupIds})
}

export function add(data) {
    return post('/group/add', {form: data}).catch((e) =>
        mapValidationErrors(mapValidatorCommunicatorError(e), keysMap)
    )
}

export function edit(id, data) {
    return post('/group/edit', {
        form: {id, ...data},
    }).catch((e) => mapValidationErrors(mapValidatorCommunicatorError(e), keysMap))
}

export function fetchGroupMessagingLanguages() {
    return get('/group/languages').then(({count, rows}) => ({
        count,
        rows: rows.map(mapLanguage),
    }))
}
