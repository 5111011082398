import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class AuthorizationSettings extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            first: PropTypes.shape({
                maxAttemptsCount: PropTypes.number,
                timeout: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            second: PropTypes.shape({
                maxAttemptsCount: PropTypes.number,
                timeout: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            email: PropTypes.shape({
                codeLength: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            userLifetime: PropTypes.number,
            installerAllowedToRemovePanels: PropTypes.bool,
        }),
    }

    render() {
        const {data} = this.props
        return (
            <>
                {this.renderListItem(
                    __('First factor expiration period [Seconds]'),
                    data.first.expirationPeriod,
                    true
                )}
                {this.renderListItem(
                    __('Email code expiration period for Installer App [seconds]'),
                    data.email.expirationPeriod,
                    true
                )}
                {this.renderListItem(
                    __('Email code length for Installer App'),
                    data.email.codeLength,
                    true
                )}
                {this.renderListItem(
                    __('Account login attempts limit'),
                    data.first.maxAttemptsCount,
                    true
                )}
                {this.renderListItem(
                    __('Account login lock timeout [seconds]'),
                    data.first.timeout,
                    true
                )}
                {this.renderListItem(
                    __('Panel login attempts limit'),
                    data.second.maxAttemptsCount,
                    true
                )}
                {this.renderListItem(
                    __('Panel login lock timeout [Seconds]'),
                    data.second.timeout,
                    true
                )}
                {this.renderListItem(
                    __('Second factor expiration period [Seconds]'),
                    data.second.expirationPeriod,
                    true
                )}
                {this.renderListItem(
                    __('Permanent user lifetime [Days]'),
                    data.userLifetime
                )}
                {this.renderListItem(
                    __('Allow Installer Remove Panels'),
                    data.installerAllowedToRemovePanels
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {authorizationSettings},
            },
        }) => ({
            ...authorizationSettings,
        })
    )
)(AuthorizationSettings)
