import React, {PureComponent, useEffect} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {resolveFaults as resolveFaultsHandler} from 'modules/forms/handlers'

import withForm from 'containers/withForm'
import withModalLoader from 'containers/withModalLoader'

import FaultChecklist from 'components/List/FaultChecklist'

import ModalCardForm from 'ui/ModalCardForm'
import CardMessage from 'ipmp-react-ui/CardMessage'
import {FormValidationGroup} from 'ipmp-react-ui/Form'

import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'
import {usePanelsRefresh} from 'hooks/pages/usePanels'

class ResolveFaults extends PureComponent {
    static propTypes = {
        panels: PropTypes.arrayOf(
            PropTypes.shape({
                serial: PropTypes.string,
                account: PropTypes.string,
                faults: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        zone: PropTypes.number,
                        comment: PropTypes.string,
                        isSuspended: PropTypes.bool,
                    })
                ),
            })
        ),
        isLoading: PropTypes.bool,
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func.isRequired,
    }

    rules = {
        faultId: {
            presence: {message: __('You must select at least one fault to resolve')},
        },
    }

    handle = ({faultId}) => {
        if (!Array.isArray(faultId)) {
            faultId = [faultId]
        }

        const {handle} = this.props
        handle(faultId.map((id) => parseInt(id)))
    }

    filterFault = (fault) => fault.isMarkForService

    render() {
        const {panels, isLoading, hide} = this.props

        if (
            !panels.some((panel) => panel.faults && panel.faults.find(this.filterFault))
        ) {
            return (
                <Modal onClose={hide}>
                    <CardMessage
                        title={__('Resolve Faults')}
                        message={__('No resolvable faults found')}
                        onClose={hide}
                        onPositive={hide}
                    />
                </Modal>
            )
        }

        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={__('Resolve Faults')}
                rules={this.rules}
                isLoading={isLoading}
                hide={hide}
            >
                <FormValidationGroup name="faultId">
                    <FaultChecklist
                        panels={panels}
                        filter={this.filterFault}
                        emptyMessage={__('There are no resolvable faults')}
                    />
                </FormValidationGroup>
            </ModalCardForm>
        )
    }
}

const ResolveFaultsWithForm = compose(
    withForm(resolveFaultsHandler),
    withModalLoader()
)(ResolveFaults)

export default function Resolve(props) {
    const {panelIds} = props
    const panelsRefresh = usePanelsRefresh(panelIds)

    useEffect(() => {
        panelsRefresh.refresh(panelIds)
    }, [])

    const modalProps = {...props, ...panelsRefresh}

    return <ResolveFaultsWithForm {...modalProps} />
}
