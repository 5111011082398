import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import CardMessage from 'ipmp-react-ui/CardMessage'
import Modal from 'ipmp-react-ui/Modal'
import isFunction from 'lodash-es/isFunction'

const Confirmation = (props) => {
    const {messages, hide, action, dispatch, outClick} = props
    const handle = () => {
        hide()

        action.forEach((actionItem) => {
            isFunction(actionItem) ? actionItem() : dispatch(actionItem)
        })

        if (outClick) {
            outClick(false)
        }
    }

    if (outClick) {
        useEffect(() => {
            outClick(true)
            return () => {
                outClick(false)
            }
        }, [])
    }

    return (
        <Modal onClose={hide}>
            <CardMessage
                title={messages.title}
                message={messages.message}
                positive={messages.positive}
                negative={messages.negative}
                onClose={hide}
                onNegative={hide}
                onPositive={handle}
            />
        </Modal>
    )
}

Confirmation.propTypes = {
    action: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                type: PropTypes.string.isRequired,
            }),
            PropTypes.func,
        ])
    ),
    dispatch: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,

    messages: PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.node.isRequired,
        positive: PropTypes.string,
        negative: PropTypes.string,
    }).isRequired,
}

export default connect()(Confirmation)
