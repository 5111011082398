import React from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import {initiate} from 'permissions/remoteInspections/selection'

import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {ifSelection, withSelectionHandler} from 'containers/withSelection'

import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const InitiateRemoteInspectionButtonBase = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export function InitiateRemoteInspectionButton(props) {
    const {isPermitted} = usePermission(initiate)

    return (
        <InitiateRemoteInspectionButtonBase
            isVisible={isPermitted}
            message={__('Do you want to initiate remote inspection now?')}
            positive={__('Begin Now')}
            title={__('Initiate inspection')}
            Icon={IconRun}
            {...props}
        />
    )
}

const InitiateRemoteInspectionButtonIfSelected = ifSelection(
    (id, {remoteInspections}) =>
        get(remoteInspections, ['store', id, 'progress']) === null
)(InitiateRemoteInspectionButton)

export default function () {
    const {initiateNow} = useRemoteInspections()

    return (
        <InitiateRemoteInspectionButtonIfSelected
            onClick={initiateNow}
            label={__('Run')}
        />
    )
}
