import React, {Component, Fragment} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'
import Definition from 'ipmp-react-ui/Definition'

import FirmwarePager from './GridBar/FirmwarePager'
import FirmwareDropDown from './GridBar/FirmwareDropDown'

import {selectApplianceTypeAndPackageName} from 'modules/firmware/store/selectors'

import path from 'utils/path'
import UpgradeByFilters from './GridBar/Buttons/UpgradeByFilters'
import IconBack from 'ui/IconBack'

class FirmwareUpgradeGridBar extends Component {
    handleGoBack = () => {
        this.props.history.push(path('firmware'))
    }

    renderTitle = () => {
        const {applianceType, packageName} = this.props

        return (
            <Definition
                className="title"
                title={applianceType}
                detail={packageName}
                multiLine
            />
        )
    }

    render() {
        const {applianceType} = this.props

        return (
            <Fragment>
                <Bar>
                    <Button onClick={this.handleGoBack} className="btn--goBack">
                        <IconBack />
                    </Button>
                    {this.renderTitle()}

                    <BarSpace />

                    <FirmwarePager />
                </Bar>

                <Bar>
                    <FirmwareDropDown />
                    <Buttons>
                        <UpgradeByFilters applianceType={applianceType} />
                    </Buttons>
                </Bar>
            </Fragment>
        )
    }
}

export default compose(connect(selectApplianceTypeAndPackageName))(FirmwareUpgradeGridBar)
