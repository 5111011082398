import {__} from 'utils/i18n'
import {warn, err} from 'utils/log'
import isFunction from 'lodash-es/isFunction'

export const COMMA_SEPARATOR = 'comma'
export const SEMICOLON_SEPARATOR = 'semicolon'
export const TABULATION_SEPARATOR = 'tabulation'

export const SEPARATORS = {
    [COMMA_SEPARATOR]: () => __('Comma'),
    [SEMICOLON_SEPARATOR]: () => __('Semicolon'),
    [TABULATION_SEPARATOR]: () => __('Tabulation'),
}

export function getSeparatorTitle(separator) {
    const title = SEPARATORS[separator]

    if (isFunction(title)) {
        return title()
    }

    warn(`No separator title for ${separator}`)
    return separator
}

export function getSeparator(separator) {
    switch (separator) {
        case COMMA_SEPARATOR:
            return ','
        case SEMICOLON_SEPARATOR:
            return ';'
        case TABULATION_SEPARATOR:
            return '\t'
        default:
            err(`No separator for ${separator}`)
            return ','
    }
}
