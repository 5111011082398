import {
    HAD_TYPE_DIMMER,
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_PGM,
    HAD_TYPE_THERMOSTAT,
} from 'constants/had'

import HadDeviceThermostatValues from 'pages/Panel/Had/List/HadDeviceThermostatValues'
import HadDeviceDimmerValues from 'pages/Panel/Had/List/HadDeviceDimmerValues'
import HadDeviceDoorlockControl from 'pages/Panel/Had/List/HadDeviceDoorlockControl'
import HadDevicePgmControl from 'pages/Panel/Had/List/HadDevicePgmControl'
import Void from 'components/Void'

const hadDeviceListMapControl = new Map([
    [HAD_TYPE_DIMMER, HadDeviceDimmerValues],
    [HAD_TYPE_THERMOSTAT, HadDeviceThermostatValues],
    [HAD_TYPE_DOORLOCK, HadDeviceDoorlockControl],
    [HAD_TYPE_PGM, HadDevicePgmControl],
])

const hadDeviceBlindModalMapControl = new Map([
    [HAD_TYPE_DIMMER, HadDeviceDimmerValues],
    [HAD_TYPE_THERMOSTAT, HadDeviceThermostatValues],
])

const hadDeviceControlResolve = (type) => {
    if (hadDeviceListMapControl.has(type)) {
        return hadDeviceListMapControl.get(type)
    }

    return hadDeviceListMapControl.get(HAD_TYPE_PGM)
}

export const hadDeviceBlindModalControlResolve = (type) => {
    if (hadDeviceBlindModalMapControl.has(type)) {
        return hadDeviceBlindModalMapControl.get(type)
    }

    return Void
}

export default hadDeviceControlResolve
