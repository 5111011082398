import {compose} from 'redux'
import {withPermission, withRejection} from 'containers/withPermission'
import withProps from 'containers/withProps'

import Page from 'ipmp-react-ui/Page'

import page from 'permissions/accounts/page'
import tabs from 'permissions/accounts/navigation'
import resolveTabsPermissions from 'permissions/resolveTabsPermissions'
import {tabsMap} from './AccountsRouting'
import AccountsContent from './AccountsContent'
import AccountsBar from 'pages/Accounts/AccountsBar'

export default compose(
    withPermission({isAllowed: page, allowedTabs: tabs}),
    withRejection(),
    withProps(({allowedTabs}) => {
        return {
            tabs: resolveTabsPermissions(allowedTabs, tabsMap),
            Bar: AccountsBar,
            Content: AccountsContent,
            className: 'accounts-page',
        }
    })
)(Page)
