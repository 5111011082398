import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import withConfirmation from 'containers/withConfirmation'
import {useActions} from 'modules/higherOrder/useActions'
import {remove} from 'modules/panels/systemLogs/list/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {__} from 'utils/i18n'

const ConfirmationButton = withConfirmation()(IconRemove)

export default function RemoveButton({id}) {
    const panelId = useRouterPanelId()
    const {onClick} = useActions(
        {
            onClick: () => remove({panelId, id}),
        },
        [panelId, id]
    )

    return (
        <ConfirmationButton
            flat
            title={__('Removing system log')}
            positive={__('Remove')}
            onClick={onClick}
            message={__('Do you really want to remove system log?')}
            className="smartCommunicator-log-remove-icon"
        />
    )
}

RemoveButton.propTypes = {
    id: PropTypes.number.isRequired,
}
