import React, {useEffect} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Layout from 'ipmp-react-ui/Layout'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import AssignUserButton from 'pages/Panel/PanelInteractiveUsers/GridBar/AssignUserButton'
import TableWithBlind from 'components/Table/TableWithBlind'
import usePermission from 'hooks/usePermission'
import useInteractiveUsers from 'hooks/pages/useInteractiveUsers'
import interactiveUsersList from 'permissions/interactiveUsers/list'
import {list as panelUsersList} from 'permissions/panel/customers/actions'

import PanelInteractiveUsersBlind from './PanelInteractiveUsersBlind'
import PanelInteractiveUsersTable from './PanelInteractiveUsersTable'
import PanelInteractiveUsersSearch from './PanelInteractiveUsersSearch'

const AssignUserButtonBar = () => {
    return (
        <Bar className="panel-page-interactive-users-tad-bar">
            <BarSpace />
            <AssignUserButton />
        </Bar>
    )
}

const PanelInteractiveUsersBase = ({isAllowedInteractiveUsers}) => {
    return (
        <Layout vertical className="panelPage-content">
            <PanelInteractiveUsersSearch
                isAllowedInteractiveUsers={isAllowedInteractiveUsers}
                className={classNames('panelInteractiveUsersSearch', {
                    'panelInteractiveUsersSearch--empty-bar': !isAllowedInteractiveUsers,
                })}
            />
            {isAllowedInteractiveUsers && <AssignUserButtonBar />}
            <TableWithBlind className="panelInteractiveUsersTable">
                {(props) => (
                    <PanelInteractiveUsersBlind {...props} panelUser={props?.row} />
                )}
                {(props) => <PanelInteractiveUsersTable {...props} />}
            </TableWithBlind>
        </Layout>
    )
}

PanelInteractiveUsersBase.propTypes = {
    match: PropTypes.shape({params: PropTypes.shape({id: PropTypes.string})}),
}

export default function PanelInteractiveUsers() {
    const {isAllowed, isAllowedInteractiveUsers} = usePermission({
        isAllowed: panelUsersList,
        isAllowedInteractiveUsers: interactiveUsersList,
    })
    const interactiveUsersProps = useInteractiveUsers()

    useEffect(() => {
        if (isAllowedInteractiveUsers) {
            interactiveUsersProps.startPoll()
            interactiveUsersProps.fetch()
        }

        return () => {
            interactiveUsersProps.stopPoll()
        }
    }, [])

    return (
        <PanelInteractiveUsersBase
            isAllowedInteractiveUsers={isAllowedInteractiveUsers}
            isAllowed={isAllowed}
            {...interactiveUsersProps}
        />
    )
}
