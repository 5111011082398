import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import Search from 'components/Search/Search'
import __ from 'utils/i18n'

import list from 'permissions/events/list'
import delivery from 'permissions/events/delivery'
import centralStations from 'permissions/centralStations/list'

import {
    addFilters,
    clearFilters,
    fetch,
    removeFilters,
    fetchSuggests,
    setQuery,
} from 'modules/events/list/actions'
import {showSaveSearchModal} from 'modules/modals/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'
import {
    EDS_FAILED,
    EDS_PROCESSING,
    EDS_QUEUED,
    EDS_RETRY,
    EDS_SUCCESS,
} from 'constants/eventDelivery'

export default compose(
    withPermission({
        isVisible: list,
        isDeliveryPermitted: delivery,
        isCentralStationsPermitted: centralStations,
    }),
    withVisibility(),
    withProps(({isDeliveryPermitted, isCentralStationsPermitted}) => {
        let filters = [
            new TranslateSuggestFilter('severity', __('Severity'), {
                alarm: __('Alarm'),
                alert: __('Alert'),
                offline: __('Offline'),
                online: __('Online'),
                notice: __('Notice'),
                restore: __('Restore'),
                duress: __('Duress'),
                securityopenclose: __('Security open/close'),
            }),

            new SuggestFilter('serial', __('Panel Id')),

            new SuggestFilter('account', __('Account')),

            new ValuesFilter('camera', __('Has Video'), {
                '{>=1}': __('Yes'),
                0: __('No'),
            }),

            new DateFilter('datetime', __('Date')),

            new SuggestFilter('description', __('Event Description')),
        ]

        if (isDeliveryPermitted) {
            filters = [
                ...filters,
                new ValuesFilter('eds_status', __('Delivery status'), {
                    [EDS_QUEUED]: __('Queued'),
                    [EDS_PROCESSING]: __('Processing'),
                    [EDS_SUCCESS]: __('Success'),
                    [EDS_RETRY]: __('Retry'),
                    [EDS_FAILED]: __('Failed'),
                }),

                new DateFilter('eds_changed_timestamp', __('Delivery status changed')),
            ]
        }

        if (isCentralStationsPermitted) {
            filters.push(new SuggestFilter('cls_name', __('Central Station')))
        }

        return {
            filters: filters.sort((item, next) => item.title.localeCompare(next.title)),
        }
    }),
    connect(
        ({events: {list}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            suggests: list.suggests,
            query: list.query,
        }),

        (dispatch, {apply}) =>
            bindActionCreators(
                {
                    apply: (...args) => {
                        apply && apply(...args)
                        return fetch(...args)
                    },
                    onSelect: addFilters,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onQuery: setQuery,
                    onSuggest: fetchSuggests,
                    onSaveSearch: (filters) => showSaveSearchModal('events', filters),
                },
                dispatch
            )
    )
)(Search)
