import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const REPORT_ONE_TIME = 'onetime'
export const REPORT_DAILY = 'daily'
export const REPORT_WEEKLY = 'weekly'
export const REPORT_MONTHLY = 'monthly'
export const REPORT_YEARLY = 'yearly'

export const REPORT_DAILY_EVERY_WEEK_DAY = 'every-week-day'

export const EXPORT_TYPE_PDF = 'pdf'
export const EXPORT_TYPE_CSV = 'csv'
export const EXPORT_TYPE_CSV_EXTENDED = 'csv_extended'
export const EXPORT_TYPE_PDF_ID = 1
export const EXPORT_TYPE_CSV_ID = 2
export const EXPORT_TYPE_CSV_EXTENDED_ID = 3

export const EXPORT_TYPES = [EXPORT_TYPE_PDF, EXPORT_TYPE_CSV, EXPORT_TYPE_CSV_EXTENDED]

export const getTypeLabel = (type) => {
    switch (type) {
        case EXPORT_TYPE_PDF:
            return __('PDF')
        case EXPORT_TYPE_CSV:
            return __('CSV')
        case EXPORT_TYPE_CSV_EXTENDED:
            return __('Extended CSV')
        default:
            warn(`Unknown report export type ${type}`)
            return type
    }
}

export const getPatternLabel = (pattern) => {
    switch (pattern) {
        case REPORT_ONE_TIME:
            return __('One Time')
        case REPORT_DAILY:
            return __('Daily')
        case REPORT_WEEKLY:
            return __('Weekly')
        case REPORT_MONTHLY:
            return __('Monthly')
        case REPORT_YEARLY:
            return __('Yearly')
        default:
            warn(`Unknown report pattern ${pattern}`)
            return pattern
    }
}
