import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class Info extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            name: PropTypes.string,
            address: PropTypes.string,
            person_email: PropTypes.string,
            person_name: PropTypes.string,
            person_phone: PropTypes.string,
            web_admin_email: PropTypes.string,
        }),
    }

    render() {
        const {data} = this.props
        return (
            <>
                {this.renderListItem(__('Name'), data.name, true)}
                {this.renderListItem(__('Person Email'), data.person_email, true)}
                {this.renderListItem(__('Address'), data.address, true)}
                {this.renderListItem(__('Person Name'), data.person_name, true)}
                {this.renderListItem(__('Web Admin Email'), data.web_admin_email)}
                {this.renderListItem(__('Person Phone'), data.person_phone)}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {administratorInfo},
            },
        }) => ({...administratorInfo})
    )
)(Info)
