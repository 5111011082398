import {POLL_SESSION} from 'configs/pollers'
import * as commands from 'modules/auth/commands'
import isDevMode from 'utils/isDevMode'
import {sendSWMessage} from 'utils/serviceWorker/serviceWorkerUtils'

// LoginContoroller.php whoami can be send 200 and send instead 304
const NOT_MODIFIED = 'Not Modified'

export default class Poller {
    tId = null

    running = true

    time = new Date()

    constructor(services) {
        this.services = services
    }

    start() {
        this.running = true
        this.run()
    }

    run() {
        if (this.tId || !this.running) {
            return
        }

        this.tId = setTimeout(() => {
            this.tId = null
            this.tick()
        }, POLL_SESSION)
    }

    stop() {
        this.running = false

        if (this.tId) {
            clearTimeout(this.tId)
            this.tId = null
        }
    }

    tick() {
        this.services.authService
            .retrieve(this.time)
            .then(
                (data) => {
                    if (isDevMode()) {
                        console.log('new auth data received')
                    }

                    if (data === NOT_MODIFIED) {
                        return
                    }

                    this.services.authService.authData = data

                    sendSWMessage({
                        command: commands.COMMAND_UPDATED,
                        data,
                    })

                    this.time = new Date()
                },
                (error) => {
                    if (error.status === 304) {
                        // not modified status returned
                        return
                    }

                    console.error(error)
                }
            )
            .finally(() => this.run())
    }
}
