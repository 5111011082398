import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as EditIcon} from 'ipmp-react-ui/icons/edit.svg'
import Button from 'ipmp-react-ui/Button'

import {stations} from 'permissions/group/navigation'
import {showEditCentralStationLinksModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'

const ButtonWithVisibility = withVisibility()(Button)

const EditCommunicationButton = ({groupId, stationId, links}) => {
    const {isVisible} = usePermission({isVisible: stations})
    const {onClick} = useActions(
        {
            onClick: () => showEditCentralStationLinksModal(groupId, stationId, links),
        },
        [links]
    )

    return (
        <ButtonWithVisibility
            onClick={onClick}
            flat
            borderless
            isVisible={isVisible}
            Icon={EditIcon}
        />
    )
}

EditCommunicationButton.propTypes = {
    groupId: PropTypes.number.isRequired,
    stationId: PropTypes.number.isRequired,
}

export default EditCommunicationButton
