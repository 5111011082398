import {handleActions, combineActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'
import {merge} from 'immutable-modify'

import CacheFields from 'utils/cacheFieldsForOptimisticUa'

import * as actions from './actions'
import {STATUS_SUSPENDED, STATUS_VERIFIED} from 'constants/interactiveUserStatus'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const state = {
    byIds: {},
}

const emptyPanelInteractiveUser = {
    email: null,
    isAssign: false,
    status: null,
    oldStatus: null,
    createdAt: null,
}

const cacheFields = new CacheFields([
    'role',
    'email',
    'isAssign',
    'status',
    'oldStatus',
    'createdAt',
])

export default handleActions(
    {
        [actions.update](state, {payload, meta: {panelId}}) {
            return {
                ...state,
                byIds: {
                    [panelId]: keyBy(payload, 'id'),
                },
            }
        },
        [actions.updateUser](state, {payload, meta: {panelId}}) {
            return merge(state, ['byIds', panelId, payload.id], {
                ...payload,
            })
        },
        [combineActions(
            actions.disconnectInteractiveUser,
            actions.disconnectInteractiveUserWithoutFetch
        )](state, {payload: id, meta: {panelId}}) {
            return merge(state, ['byIds', panelId, id], {
                ...{...state.byIds[panelId][id], ...emptyPanelInteractiveUser},
                ...cacheFields.createCachedFields(state.byIds[panelId][id]),
            })
        },
        [actions.revertDisconnectInteractiveUser](state, {payload: id, meta: {panelId}}) {
            return merge(state, ['byIds', panelId, id], {
                ...cacheFields.revertFromCache(state.byIds[panelId][id]),
            })
        },

        [actions.enable](state, {payload: interactiveUserId, meta: {panelId}}) {
            return merge(state, ['byIds', panelId, interactiveUserId], {
                _status: state.byIds[panelId][interactiveUserId].status,
                status: STATUS_VERIFIED,
            })
        },

        [actions.suspend](state, {payload: interactiveUserId, meta: {panelId}}) {
            return merge(state, ['byIds', panelId, interactiveUserId], {
                _status: state.byIds[panelId][interactiveUserId].status,
                status: STATUS_SUSPENDED,
            })
        },

        [actions.revert](state, {payload: interactiveUserId, meta: {panelId}}) {
            return merge(state, ['byIds', panelId, interactiveUserId], {
                status: state.byIds[panelId][interactiveUserId]._status,
            })
        },
        ...createPurgeStoreHandler(actions.purge),
    },
    state
)
