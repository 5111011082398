import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import {ReactComponent as IconInfo} from 'ipmp-react-ui/icons/info-inversed.svg'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'
import __ from 'utils/i18n'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {
    setLoginScreenForm,
    sendCode,
    clearErrors,
    setPhone,
} from 'modules/auth/sign/actions'

import {LOGIN_SCREEN, CONFIRMATION_CODE_SCREEN} from 'constants/loginScreen'

const rules = {
    phone: {
        presence: true,
        phone: true,
        numericality: true,
    },
}

class PhoneNumberConfirmationForm extends Component {
    static propTypes = {
        sendCode: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    state = {
        errors: false,
        isSubmitted: false,
        phoneNumber: this.props.phone,
    }

    handleSubmit = ({phone}) => {
        this.props.sendCode(phone)
        this.props.clearErrors()
        this.props.setLoginScreenForm(CONFIRMATION_CODE_SCREEN)
        this.props.setPhone(phone)
    }

    handleBack = () => {
        this.props.setLoginScreenForm(LOGIN_SCREEN)
    }

    onChangePhoneNumber = (e) => {
        this.setState({phoneNumber: e.target.value})
    }

    render() {
        const {isLoading, error} = this.props
        const {phoneNumber} = this.state
        return (
            <Form
                className="card login-form"
                isLoading={isLoading}
                onSubmit={this.handleSubmit}
                errors={error && error.errors}
                rules={rules}
            >
                <CardHeader className={'login-form-header'}>
                    {__('Phone number confirmation')}
                </CardHeader>
                <CardContent>
                    <span className={'login-form-content-label'}>
                        {__('Enter the phone number')}
                    </span>
                    <Input
                        name="phone"
                        label={__('Phone')}
                        autoComplete={'off'}
                        defaultValue={phoneNumber}
                        onChange={this.onChangePhoneNumber}
                        dataTestId="phone-number-confirmation-input"
                    />
                    <div className={'login-form-warning-area'}>
                        <div className={'login-form-icon-wrap'}>
                            <IconInfo className={'login-form-icon-info'} />
                        </div>
                        <div className={'login-form-warning-text'}>
                            {__(
                                'Please be sure that your phone number is correct. You can change it during first authentication'
                            )}
                        </div>
                    </div>
                </CardContent>

                <CardActions>
                    <Button
                        primary
                        className={'login-form-button'}
                        disabled={isLoading}
                        type="submit"
                    >
                        {__('Verify')}
                    </Button>

                    <Button
                        borderless
                        className={'login-form-back'}
                        onClick={this.handleBack}
                    >
                        <IconPrev />
                        {__('Back to Sign In')}
                    </Button>
                </CardActions>
            </Form>
        )
    }
}

export default connect(
    ({auth: {sign}}) => ({...sign}),
    (dispatch) =>
        bindActionCreators(
            {
                sendCode,
                setLoginScreenForm,
                clearErrors,
                setPhone,
            },
            dispatch
        )
)(PhoneNumberConfirmationForm)
