import {ReactComponent as IconHeart} from 'ipmp-react-ui/icons/heart.svg'
import {ReactComponent as IconOnline} from 'ipmp-react-ui/icons/online.svg'
import {ReactComponent as IconOffline} from 'ipmp-react-ui/icons/offline.svg'
import {ReactComponent as NotDeliveredIcon} from 'ipmp-react-ui/icons/alert.svg'

import __ from 'utils/i18n'
import noop from 'lodash-es/noop'
import resolveMapTranslation from 'constants/utils/resolveMapTranslation'

export const CS_PROTOCOL_FIBRO_CS_CID = 13
export const CS_PROTOCOL_FIBRO_CS_SIA = 14

export const defaultHeartbeatValue = 0
export const defaultRetryTimeValue = null
export const defaultRetryCountValue = 0
export const defaultClsIdValue = null
export const defaultReceiverValue = 33
export const defaultLineValue = 2
export const defaultHostValue = null

export const notSecuredPort = 80
export const securedPort = 443

export const CS_STATUS_ID_ONLINE = 0
export const CS_STATUS_ID_OFFLINE = 1
export const CS_STATUS_ID_HEARTBEAT = 2
export const CS_STATUS_ID_NOT_APPLICABLE = 3

export const CS_STATUS_ONLINE = 'ONLINE'
export const CS_STATUS_OFFLINE = 'OFFLINE'
export const CS_STATUS_HEARTBEAT = 'HEARTBEAT'
export const CS_STATUS_NOT_APPLICABLE = 'NOT_APPLICABLE'

export const list = [CS_STATUS_ONLINE, CS_STATUS_OFFLINE, CS_STATUS_HEARTBEAT]

export const CS_TRANSPORT_CONNECTION_TYPE_SERIAL = 0
export const CS_TRANSPORT_CONNECTION_TYPE_TCP = 1
export const CS_TRANSPORT_CONNECTION_TYPE_HTTP = 2
export const CS_TRANSPORT_CONNECTION_TYPE_FIBRO = 3
export const CS_CONNECTION_TYPE_HTTP = 'http'
export const CS_CONNECTION_TYPE_TCP = 'tcp'
export const CS_CONNECTION_TYPE_SERIAL = 'serial'
export const CS_CONNECTION_TYPE_FIBRO = 'fibro'

export const CS_CONNECTION_SSL_ID_NONE = 2
export const CS_CONNECTION_SSL_ID_TLS_1_2 = 0
export const CS_CONNECTION_SSL_ID_TLS_1_3 = 1
export const CS_CONNECTION_SSL_NONE = 'none'
export const CS_CONNECTION_SSL_TLS_1_2 = 'tls1.2'
export const CS_CONNECTION_SSL_TLS_1_3 = 'tls1.3'

export const CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS = [8, 16, 32]

export const listSsl = [
    CS_CONNECTION_SSL_NONE,
    CS_CONNECTION_SSL_TLS_1_2,
    CS_CONNECTION_SSL_TLS_1_3,
]

export function getSslLabel(ssl) {
    switch (ssl) {
        case CS_CONNECTION_SSL_NONE:
            return __('None')
        case CS_CONNECTION_SSL_TLS_1_2:
            return __('TLS 1.2')
        case CS_CONNECTION_SSL_TLS_1_3:
            return __('TLS 1.3')
    }
}

const regexProtocol = '^(http|https)://(.*)$'
const regexHost = '^(.*?)/(.*)$'
const regexPort = '^(.*):(.*)$'

export const parseHttpFields = (hostField, oldValues) => {
    if (!hostField || !validURL(hostField)) {
        return oldValues
    }

    let ssl =
        oldValues.ssl !== CS_CONNECTION_SSL_NONE ? oldValues.ssl : CS_CONNECTION_SSL_NONE
    let isSecurity = oldValues.isSecurity
    let path = oldValues.path
    let port = oldValues.port

    // process protocol and isSecurity flag
    const protocolMatches = hostField.match(regexProtocol)
    if (protocolMatches) {
        const protocol = protocolMatches[1]
        if (protocol === 'https') {
            ssl = CS_CONNECTION_SSL_TLS_1_3
            isSecurity = true
        } else {
            ssl = CS_CONNECTION_SSL_NONE
            isSecurity = false
        }
        // remove protocol from hostField
        hostField = protocolMatches[2]
    }

    // split host and path
    const hostPathMatches = hostField.match(regexHost)
    if (hostPathMatches) {
        hostField = hostPathMatches[1]
        path = hostPathMatches[2].length ? hostPathMatches[2] : path
        // leading slash
        if (path.indexOf('/') !== 0) {
            path = '/' + path
        }
    } else if (!path) {
        path = '/'
    }

    // process port if exists and set default if not (and old value absent)
    const hostPortMatches = hostField.match(regexPort)
    if (hostPortMatches) {
        hostField = hostPortMatches[1]
        port = parseInt(hostPortMatches[2])
    } else if (!port) {
        port = isSecurity ? securedPort : notSecuredPort
    }

    return {
        host: hostField,
        port: port,
        path: path,
        ssl: ssl,
        isSecurity: isSecurity,
    }
}

function validURL(str) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ) // fragment locator
    return !!pattern.test(str)
}

class StatusDefinitionParams {
    constructor(title = noop(), Icon = null, iconClassName = '') {
        this.title = title
        this.iconClassName = iconClassName
        this.Icon = Icon
    }
}

const statusDefinitionParamsMap = new Map([
    [CS_STATUS_ONLINE, new StatusDefinitionParams(() => __('Online'), IconOnline)],
    [CS_STATUS_OFFLINE, new StatusDefinitionParams(() => __('Offline'), IconOffline)],
    [
        CS_STATUS_NOT_APPLICABLE,
        new StatusDefinitionParams(() => __('Not applicable'), NotDeliveredIcon),
    ],
    [
        CS_STATUS_HEARTBEAT,
        new StatusDefinitionParams(
            () => __('Heartbeat'),
            IconHeart,
            'primary icon-heartbeat'
        ),
    ],
])

export const getCentralStationStatusDefinitionParams = (status) => {
    if (!statusDefinitionParamsMap.has(status)) {
        console.warn(`Unknown central station status: ${status}`)
        return
    }

    return statusDefinitionParamsMap.get(status)
}

export const connectionTypes = [
    CS_CONNECTION_TYPE_TCP,
    CS_CONNECTION_TYPE_SERIAL,
    CS_CONNECTION_TYPE_HTTP,
]

const connectionTypeTitleMap = new Map([
    [CS_CONNECTION_TYPE_TCP, () => __('TCP/IP')],
    [CS_CONNECTION_TYPE_SERIAL, () => __('Serial')],
    [CS_CONNECTION_TYPE_HTTP, () => __('HTTP')],
    [CS_CONNECTION_TYPE_FIBRO, () => __('Fibro')],
])

export const connectionTypeTitle = resolveMapTranslation(
    connectionTypeTitleMap,
    (connectionType) => `No connection type title: ${connectionType}`
)

export const isHttpConnectionType = (connectionType) =>
    connectionType === CS_CONNECTION_TYPE_HTTP
export const isTcpConnectionType = (connectionType) =>
    connectionType === CS_CONNECTION_TYPE_TCP
export const isSerialConnectionType = (connectionType) =>
    connectionType === CS_CONNECTION_TYPE_SERIAL
export const isFibroConnectionType = (connectionType) =>
    connectionType === CS_CONNECTION_TYPE_FIBRO

export const isFibroProtocol = (protocolId) =>
    protocolId === CS_PROTOCOL_FIBRO_CS_CID || protocolId === CS_PROTOCOL_FIBRO_CS_SIA
