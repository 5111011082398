import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ModalCardForm from 'ui/ModalCardForm'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import withForm from 'containers/withForm'
import {compose} from 'redux'
import {activatePanel} from 'modules/forms/handlers'

export class ActivatePanel extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        unitId: PropTypes.number.isRequired,
    }

    rules = {
        installerCode: {
            presence: true,
            format: {
                pattern: /^(\d{4}|\d{6}|\d{8})$/,
                flags: 'i',
                message: __('The activation code should be 4/6/8 digits long'),
            },
        },
    }

    handle = ({installerCode}) => {
        const {unitId, handle} = this.props
        handle(unitId, installerCode)
    }

    render() {
        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={__('Activate Panel')}
                rules={this.rules}
                submitLabel={__('Activate')}
                {...this.props}
            >
                <Input
                    autoFocus
                    name="installerCode"
                    label={__('Installer Code')}
                    maxLength="8"
                    minLength="4"
                    dataTestId="installer-code"
                />
            </ModalCardForm>
        )
    }
}

export default compose(withForm(activatePanel))(ActivatePanel)
