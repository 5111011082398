import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const groupsPagerSearchSelect = createSelector(
    (state) => get(state, 'centralStations.groupsList', {}),
    ({total, start, query}) => ({total, start, query})
)

export const groupsList = createSelector(
    (state) => get(state, 'centralStations.groupsList', {}),
    (state) => get(state, 'centralStations.groupsStore.byIds', {}),
    ({page, error, isLoading}, byIds) => ({
        rows: page.map((id) => byIds[id]).filter(Boolean),
        isLoading,
        error,
    })
)
