import React from 'react'

import enable from 'permissions/panel/devices/plink/log/enable'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import {usePlinkDebugCommands} from 'hooks/pages/useDevices'
import usePermission from 'hooks/usePermission'

const ButtonVisible = withVisibility()(Button)

export default function EnableLogButton({panelId, ...props}) {
    const {isPermitted} = usePermission(enable)
    const {enableLogging} = usePlinkDebugCommands(panelId)

    return <ButtonVisible isVisible={isPermitted} onClick={enableLogging} {...props} />
}
