import {TIMEOUT_AUTOLOGOUT_DEFAULT} from 'configs/timeouts'
import * as commands from 'modules/auth/commands'
import {err} from 'utils/log'
import isDevMode from 'utils/isDevMode'
import {sendSWMessage} from 'utils/serviceWorker/serviceWorkerUtils'

export default class Autologout {
    tId = null

    timeout = TIMEOUT_AUTOLOGOUT_DEFAULT

    constructor(services) {
        this.services = services
    }

    continue = () => {
        if (!this.services.authService.authData) {
            err("Can't continue auth timer because no AuthData")
            this.stop()
            return
        }

        const newTimeout =
            this.services.authService?.authData?.settings?.autologout ||
            TIMEOUT_AUTOLOGOUT_DEFAULT

        if (newTimeout && this.timeout !== newTimeout) {
            this.timeout = newTimeout
            if (isDevMode()) {
                console.log(`Set autologout timer to ${this.timeout} minutes`)
            }
        }

        if (this.tId) {
            clearTimeout(this.tId)
        } else {
            if (isDevMode()) {
                console.log(`Start autologout timer to ${this.timeout} minutes`)
            }
        }

        if (this.timeout > 0) {
            this.tId = setTimeout(this.completed, this.timeout * 60 * 1000)
        } else {
            this.tId = null
        }
    }

    stop() {
        if (this.tId) {
            if (isDevMode()) {
                console.log('Stop autologout')
            }
            clearTimeout(this.tId)
            this.tId = null
        }
    }

    completed = () => {
        if (isDevMode()) {
            console.log('Logged by autologout timer')
        }
        this.tId = null

        this.services.authService.doLogout()

        sendSWMessage({
            command: commands.COMMAND_LOGGED_OUT,
        })
    }
}
