import React, {useEffect} from 'react'

import Duration from 'ipmp-react-ui/Duration'
import Time from 'ipmp-react-ui/Time'
import {humanDate} from 'ipmp-react-ui/humanTime'
import {TableGrouped} from 'ipmp-react-ui/Table'

import processType from 'constants/processType'
import withLoader from 'containers/withLoader'

import ProcessCounters from 'components/Processes/ProcessCounters'
import BatchStatusCell from 'pages/Batches/BatchesCells/BatchStatusCell'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'
import Empty from 'ui/Empty'
import useBatches from 'hooks/pages/useBatches'
import {useHistory} from 'react-router-dom'

/**
 * PMN-5919
 * widht values were set, according to minimal table viewport
 * 794px = 1000px - 16px(pzdding) - 190px(asside)
 */
export const columns = [
    {
        maxWidth: 100,
        render: ({started}) => <Time datetime={started} />,
    },
    {
        width: 100,
        render: ({type}) => processType(type),
    },
    {
        width: 100,
        render: ({panels, panelSerial}) => {
            if (panels > 1) {
                return __n('%d panel', '%d panels', panels)
            }

            return panelSerial || <Empty />
        },
    },
    {
        width: 200,
        render: BatchStatusCell,
    },
    {
        width: 150,
        maxWidth: 150,
        render: ({started, finished}) => <Duration from={started} to={finished} />,
    },
    {
        width: 156,
        render: ({stats}) => <ProcessCounters withLabels stats={stats} />,
    },
]

const ConnectedTable = withLoader(({init}) => init())(TableGrouped)

const separatorCallback = (row) => {
    const group = humanDate(row.started)

    return group || __('No user processes')
}

export default function BatchesTable() {
    const history = useHistory()
    const batchesProps = useBatches()

    useEffect(() => {
        batchesProps.startPoll()

        return () => {
            batchesProps.stopPoll()
        }
    }, [])

    const onRowClick = ({id}) => {
        history.push(path('batches.runners', {id}))
    }

    return (
        <ConnectedTable
            columns={columns}
            emptyMessage={() => __('No batches')}
            separatorCallback={separatorCallback}
            onRowClick={onRowClick}
            selectionBar={false}
            {...batchesProps}
        />
    )
}
