import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/centralStations/list'

import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/centralStations/list/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

import __ from 'utils/i18n'
import {
    CS_CONNECTION_TYPE_FIBRO,
    CS_CONNECTION_TYPE_HTTP,
    CS_CONNECTION_TYPE_SERIAL,
    CS_CONNECTION_TYPE_TCP,
} from 'constants/centralStation'
import csProfileType, {
    CS_PROFILE_ALARM,
    CS_PROFILE_ALERT,
    CS_PROFILE_CAMERABEINGVIEWED,
    CS_PROFILE_CAMERATROUBLE,
    CS_PROFILE_DURESS,
    CS_PROFILE_HDONOFF,
    CS_PROFILE_HDTROUBLE,
    CS_PROFILE_ILLUMINANCE,
    CS_PROFILE_NOTICE,
    CS_PROFILE_OFFLINE,
    CS_PROFILE_ONLINE,
    CS_PROFILE_OPENCLOSE,
    CS_PROFILE_RESTORE,
    CS_PROFILE_RRI,
    CS_PROFILE_SECURITYOPENCLOSE,
    CS_PROFILE_SMART_SENSORS,
    CS_PROFILE_TEMPERATURE,
} from 'constants/csProfileType'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('cls_name', __('Name')),
            new SuggestFilter('protocol', __('Protocol')),
            new SuggestFilter('hpa_host', __('Host')),
            new SuggestFilter('hpa_port', __('Port')),
            new SuggestFilter('spa_device', __('Device')),
            new TranslateSuggestFilter('etp_name', __('Enabled profile'), {
                [CS_PROFILE_ALERT]: csProfileType(CS_PROFILE_ALERT),
                [CS_PROFILE_ALARM]: csProfileType(CS_PROFILE_ALARM),
                [CS_PROFILE_RESTORE]: csProfileType(CS_PROFILE_RESTORE),
                [CS_PROFILE_SECURITYOPENCLOSE]: csProfileType(
                    CS_PROFILE_SECURITYOPENCLOSE
                ),
                [CS_PROFILE_CAMERABEINGVIEWED]: csProfileType(
                    CS_PROFILE_CAMERABEINGVIEWED
                ),
                [CS_PROFILE_CAMERATROUBLE]: csProfileType(CS_PROFILE_CAMERATROUBLE),
                [CS_PROFILE_HDONOFF]: csProfileType(CS_PROFILE_HDONOFF),
                [CS_PROFILE_HDTROUBLE]: csProfileType(CS_PROFILE_HDTROUBLE),
                [CS_PROFILE_ONLINE]: csProfileType(CS_PROFILE_ONLINE),
                [CS_PROFILE_OFFLINE]: csProfileType(CS_PROFILE_OFFLINE),
                [CS_PROFILE_NOTICE]: csProfileType(CS_PROFILE_NOTICE),
                [CS_PROFILE_RRI]: csProfileType(CS_PROFILE_RRI),
                [CS_PROFILE_SMART_SENSORS]: csProfileType(CS_PROFILE_SMART_SENSORS),
                [CS_PROFILE_OPENCLOSE]: csProfileType(CS_PROFILE_OPENCLOSE),
                [CS_PROFILE_TEMPERATURE]: csProfileType(CS_PROFILE_TEMPERATURE),
                [CS_PROFILE_ILLUMINANCE]: csProfileType(CS_PROFILE_ILLUMINANCE),
                [CS_PROFILE_DURESS]: csProfileType(CS_PROFILE_DURESS),
            }),
            new SuggestFilter('utg_name', __('Group')),
            new ValuesFilter('cls_transport', __('Connection type'), {
                [CS_CONNECTION_TYPE_HTTP]: __('HTTP'),
                [CS_CONNECTION_TYPE_TCP]: __('TCP/IP'),
                [CS_CONNECTION_TYPE_SERIAL]: __('Serial'),
                [CS_CONNECTION_TYPE_FIBRO]: __('Fibro'),
            }),
        ].sort((item, next) => item.title.localeCompare(next.title)),
    })),
    connect(
        ({centralStations: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSelect: addFilters,
                    onQuery: setQuery,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onSuggest: fetchSuggests,
                },
                dispatch
            )
    )
)(Search)
