import {createSelector} from 'reselect'

import {selectInteractiveUsersList} from '../list/selectors'

export const selectInteractiveUsers = createSelector(
    (state) => state.interactiveUsers.store.byIds,
    (store) => store
)

export const selectInteractiveUser = createSelector(
    selectInteractiveUsers,
    (state, {interactiveUserId}) => interactiveUserId,
    (list, id) => ({interactiveUser: list[id]})
)

export const selectInteractiveUsersByPage = createSelector(
    selectInteractiveUsersList,
    selectInteractiveUsers,
    ({isLoading, page, error, total, start}, store) => ({
        rows: page.reduce((acc, id) => (id in store ? [...acc, store[id]] : acc), []),
        isLoading,
        error,
        total,
        start,
    })
)
