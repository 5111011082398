import React, {useEffect, useRef, useState} from 'react'
import __ from 'utils/i18n'
import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api'
import get from 'lodash-es/get'
import Geocode from 'react-geocode'
import {ReactComponent as IconLocation} from 'ipmp-react-ui/icons/location.svg'
import {Button} from 'ipmp-react-ui'
import {ReactComponent as IconMinimize} from 'ipmp-react-ui/icons/minimize.svg'
import {setGoogleAuthFailed} from 'modules/system/settings/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {useSelector} from 'react-redux'
import {isDarkTheme} from 'constants/themes'
import {googleApiErrorMessage} from 'constants/googleApiErrors'

const DEFAULT_ZOOM = 15
const DEFAULT_HEIGHT = 150
const COEFFICIENT_FOR_RESOLUTION = 0.5
export const GOOGLE_MAPS_LIBRARY_PLACES = 'places'
export const GOOGLE_MAPS_LIBRARY_DRAWING = 'drawing'
export const GOOGLE_MAPS_LIBRARY_GEOMETRY = 'geometry'

const ZERO_RESULTS = 'ZERO_RESULTS'

const REQUEST_ERROR = [ZERO_RESULTS]

const GOOGLE_MAPS_AUTHENTICATION_ERROR = () =>
    __(
        'There is a problem with the Google API key. Please contact to PowerManage administrator.'
    )

const darkThemeStyles = [
    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{color: '#d59563'}],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{color: '#d59563'}],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{color: '#263c3f'}],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{color: '#6b9a76'}],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{color: '#38414e'}],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{color: '#212a37'}],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{color: '#9ca5b3'}],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{color: '#746855'}],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{color: '#1f2835'}],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{color: '#f3d19c'}],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{color: '#2f3948'}],
    },
    {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{color: '#d59563'}],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{color: '#17263c'}],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{color: '#515c6d'}],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{color: '#17263c'}],
    },
]

export default function CustomerMap({
    address,
    googleToken: googleMapsApiKey,
    hasGoogleApiError,
    theme,
}) {
    if (!address || !googleMapsApiKey) {
        return null
    }

    const [libraries] = useState([
        [
            GOOGLE_MAPS_LIBRARY_PLACES,
            GOOGLE_MAPS_LIBRARY_DRAWING,
            GOOGLE_MAPS_LIBRARY_GEOMETRY,
        ],
    ])
    const [addressLocation, setAddressLocation] = useState(null)
    const [error, setError] = useState(null)
    const [partialMatch, setPartialMatch] = useState(false)
    const [formattedAddress, setFormattedAddress] = useState(null)
    const [containerHeight, setContainerHeight] = useState(DEFAULT_HEIGHT)
    const mapElement = useRef()

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries: libraries,
    })

    const getErrorMessage = (error) => {
        const errorCode = REQUEST_ERROR.find((message) => error.includes(message))
        switch (errorCode) {
            case ZERO_RESULTS:
                return ZERO_RESULTS
            default: {
                const [rawErrorMessage] = error.split('.', 1)
                const errorMessage = googleApiErrorMessage(rawErrorMessage)
                hasGoogleApiError(errorMessage)

                return errorMessage
            }
        }
    }
    const setFetchedAuthError = useActions(setGoogleAuthFailed)

    const {googleAuthFailed} = useSelector(
        ({
            system: {
                settings: {googleAuthFailed},
            },
        }) => ({
            googleAuthFailed,
        })
    )

    useEffect(() => {
        setError(null)
        window.gm_authFailure = () => {
            setFetchedAuthError()
            setError(GOOGLE_MAPS_AUTHENTICATION_ERROR)
        }
        Geocode.setApiKey(googleMapsApiKey)
        Geocode.fromAddress(address)
            .then((response) => {
                const location = get(response, 'results.0.geometry.location', null)

                if (!location) {
                    return
                }
                setPartialMatch(get(response, 'results.0.partial_match', false))
                setFormattedAddress(get(response, 'results.0.formatted_address', null))
                setAddressLocation(location)

                if (googleAuthFailed) {
                    setError(GOOGLE_MAPS_AUTHENTICATION_ERROR)
                }
            })
            .catch((error) => {
                setError(getErrorMessage(error.message))
            })

        return () => {}
    }, [address, googleMapsApiKey])

    const onClick = () => {
        setContainerHeight(
            mapElement.current.mapRef.clientWidth * COEFFICIENT_FOR_RESOLUTION
        )
    }

    const setDefaultHeight = () => {
        setContainerHeight(DEFAULT_HEIGHT)
    }

    const mapOptions = {
        fullscreenControl: false,
        styles: isDarkTheme(theme) ? darkThemeStyles : [],
        backgroundColor: () => (isDarkTheme(theme) ? '#242f3e' : null),
    }

    if (isLoaded) {
        return (
            <>
                <hr />
                <div className="customerRemarks">
                    <h3 className="customerRemarks-title">{__('Map')}</h3>

                    {partialMatch && !error && (
                        <div className="google-maps-message-block">
                            <IconLocation className="google-maps-message-icon" />
                            <span className="google-maps-message-text">
                                {__(
                                    'Showing results for %s. Search instead of %s.',
                                    formattedAddress,
                                    address
                                )}
                            </span>
                        </div>
                    )}
                    {error && (
                        <div className="google-maps-message-block">
                            <IconLocation className="google-maps-message-icon" />
                            {error === ZERO_RESULTS && (
                                <span>
                                    {__(
                                        'Unsuccessful search. Address is not related to any places'
                                    )}
                                </span>
                            )}
                            {error !== ZERO_RESULTS && <span>{error}</span>}
                        </div>
                    )}
                    {!error && (
                        <GoogleMap
                            id="map"
                            ref={mapElement}
                            mapContainerStyle={{
                                width: '100%',
                                height: `${containerHeight}px`,
                            }}
                            center={addressLocation}
                            onClick={onClick}
                            zoom={DEFAULT_ZOOM}
                            options={mapOptions}
                        >
                            {addressLocation && <Marker position={addressLocation} />}
                            {containerHeight > DEFAULT_HEIGHT && (
                                <Button
                                    className="minimizeMap"
                                    onClick={setDefaultHeight}
                                >
                                    <IconMinimize />
                                </Button>
                            )}
                        </GoogleMap>
                    )}
                </div>
            </>
        )
    } else {
        return null
    }
}
