import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import {assignUserToPanel} from 'modules/forms/handlers'
import isNil from 'lodash-es/isNil'
import withInterruption from 'containers/withInterruption'

import NoPanelUsersError from '../NoPanelUsersError'
import {withPermission} from 'containers/withPermission'
import {register} from 'permissions/interactiveUsers/actions'
import {AssignUserToPanelForm} from './AssignUserToPanelForm'

export default compose(
    withForm(assignUserToPanel),
    withPermission({isRegisterNew: register}),
    connect(
        (
            {
                interactiveUsers: {
                    store: {byIds},
                },
                panels,
                interactiveUserPanels: {
                    accessiblePanels: {
                        store: {byIds: accessiblePanels},
                    },
                },
                panelInteractiveUsers: {store},
                modals,
            },
            {panelId}
        ) => ({
            interactiveUser: byIds[modals?.args?.interactiveUserId],
            panel: panels.store.byIds[panelId] || accessiblePanels[panelId],
            panelUserId: modals?.args?.panelUserId,
        })
    ),
    withInterruption(
        ({hasPanelUser}) => (isNil(hasPanelUser) ? false : !hasPanelUser),
        NoPanelUsersError
    )
)(AssignUserToPanelForm)
