import React from 'react'

import list from 'permissions/centralStations/list'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/pages/useCentralStations'

const PagerVisibility = withVisibility()(Pager)

export default function CentralStationsPager() {
    const {isPermitted} = usePermission(list)
    const centralStations = useCentralStations()

    return <PagerVisibility isVisible={isPermitted} {...centralStations} />
}
