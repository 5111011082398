import {useSelector} from 'react-redux'
import {selectRunnersGridBar} from 'modules/runners/list/selectors'
import {useHistory, useParams} from 'react-router'

export default function useRunnerGridBar() {
    const {id} = useParams()
    const history = useHistory()

    const runnersGridBar = useSelector((state) => selectRunnersGridBar(state, {id}))

    return {
        ...runnersGridBar,
        history,
    }
}
