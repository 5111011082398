import parseDate from 'api/base/parseDate'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import moment from 'moment'
import has from 'lodash-es/has'

import {markAsViewed} from 'permissions/panel/reports/actions'
import page from 'permissions/panel/reports/page'

import Calendar, {YEAR, MONTH} from 'ipmp-react-ui/Calendar'
import {CalendarItem} from 'ipmp-react-ui/Calendar'
import Bar from 'ipmp-react-ui/Bar'
import rainbow from 'utils/rainbow'

import {ReactComponent as IconView} from 'ipmp-react-ui/icons/viewed.svg'
import {ReactComponent as IconChecked} from 'ipmp-react-ui/icons/check.svg'

import Report from './Report'
import CreateReportButton from './ReportBar/CreateReportButton'

import {fetch, reset, review, stopPoll} from 'modules/panels/reports/result/actions'
import {
    fetch as fetchExpected,
    reset as resetExpected,
} from 'modules/panels/reports/expected/actions'
import {showCreateReportModal} from 'modules/modals/actions'
import {withPermission, withRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'

export class Reports extends Component {
    static propTypes = {
        isReviewActive: PropTypes.bool,
        fetch: PropTypes.func.isRequired,
        review: PropTypes.func.isRequired,
        create: PropTypes.func.isRequired,
        reports: PropTypes.object,
        data: PropTypes.array,
        expected: PropTypes.array,
        fetchExpected: PropTypes.func.isRequired,
    }

    state = {
        view: YEAR,
        date: moment(),
    }

    getResultProps = (data) => {
        const {view} = this.state

        switch (view) {
            case MONTH:
                return this.prepareMonthProps(data)
            case YEAR:
            default:
                return this.prepareYearProps(data)
        }
    }

    prepareMonthProps = (data) => {
        const {review, isReviewActive} = this.props
        const result = data.map((report, index) => {
            const {reportId, title, date, isReviewed} = report

            const props = {
                key: `result-${index}`,
                title,
                date: new Date(date),
                reportId,
                color: rainbow(reportId),
                children: <Report report={report} />,
            }

            if (isReviewActive) {
                props.icons = [
                    {
                        Icon: isReviewed ? IconChecked : IconView,
                        onIconClick: () => review(report.id),
                    },
                ]
            }

            return props
        })

        return result
    }

    prepareYearProps = (data) => {
        const {expected, isReviewActive, review} = this.props
        const preparedData = data.reduce((acc, report, index) => {
            const {reportId, title, date, isReviewed} = report
            const indexOfSearchReport = acc.findIndex(
                (props) => props.reportId === reportId
            )

            // Not collected
            if (indexOfSearchReport === -1) {
                // Render only last report
                const lastReport = data.find((dataReport) => {
                    const isNeededReportId = dataReport.reportId === reportId
                    const isLastReport = dataReport.isLast

                    return isNeededReportId && isLastReport
                })

                // Base count report by expected
                const expectedById = expected.find(
                    (dataReport) => dataReport.id === reportId
                )
                const filteredByDate = expectedById
                    ? expectedById.dates.filter(
                          (expectedDate) => !moment(expectedDate).isSame(date, 'day')
                      )
                    : []

                const props = {
                    id: report.id,
                    key: `result-${index}`,
                    title,
                    date: new Date(date),
                    reportId,
                    color: rainbow(reportId),
                    children: <Report report={lastReport} />,
                    collectiveNums: filteredByDate.length,
                }

                if (isReviewActive) {
                    props.icons = [
                        {
                            Icon: isReviewed ? IconChecked : IconView,
                            onIconClick: () => review(report.id),
                        },
                    ]
                }

                acc.push(props)
            } else if (acc[indexOfSearchReport].id !== report.id) {
                // Additional count reports by results
                acc[indexOfSearchReport].collectiveNums =
                    acc[indexOfSearchReport].collectiveNums + 1
            }

            return acc
        }, [])

        return preparedData
    }

    getExpectedProps = (expected) => {
        const {view} = this.state
        switch (view) {
            case MONTH:
                return this.prepareExpectedMonthProps(expected)
            case YEAR:
            default:
                return this.prepareExpectedYearProps(expected)
        }
    }

    prepareExpectedMonthProps = (expected) => {
        const {data: results} = this.props
        const result = expected.map((report, index) => {
            const {id, name: title, dates} = report

            const options = {
                key: `expected-${index}`,
                title,
                reportId: id,
                color: rainbow(id, 0.25),
            }

            return dates
                .filter(
                    (date) =>
                        !results.some((result) => {
                            const resultDate = parseDate(result.date).setHours(0, 0, 0, 0)
                            const expectedDate = new Date(date).setHours(0, 0, 0, 0)

                            return result.reportId === id && expectedDate === resultDate
                        })
                )
                .map((date) => ({...options, date}))
        })

        return result
    }

    prepareExpectedYearProps = (expected) => {
        const {data: results} = this.props
        const result = expected.map((report, index) => {
            const {id, name: title, dates} = report

            const options = {
                key: `expected-${index}`,
                title,
                reportId: id,
                color: rainbow(id, 0.25),
                collectiveNums: 0,
            }

            const filteredByMonth = dates.filter(
                (date) =>
                    !results.some((resultReport) => {
                        const resultDate = moment(resultReport.date)

                        return (
                            resultReport.reportId === id &&
                            resultDate.isSame(date, 'month')
                        )
                    })
            )

            return filteredByMonth.length
                ? filteredByMonth.reduce((acc, date) => {
                      const indexWithSameMonth = acc.findIndex(
                          (props) =>
                              has(props, 'date') &&
                              moment(props.date).isSame(date, 'month')
                      )

                      if (indexWithSameMonth == -1) {
                          acc.push({
                              ...options,
                              date,
                          })
                      } else {
                          acc[indexWithSameMonth].collectiveNums++
                      }

                      return acc
                  }, [])
                : filteredByMonth
        })

        return result
    }

    renderItemsExpected = (report, key) => {
        const {data: results} = this.props
        const {id, name: title, dates} = report

        const options = {
            key,
            title,
            reportId: id,
            color: rainbow(id, 0.25),
        }

        return dates
            .filter(
                (date) =>
                    !results.some((result) => {
                        const resultDate = parseDate(result.date).setHours(0, 0, 0, 0)
                        const expectedDate = new Date(date).setHours(0, 0, 0, 0)

                        return result.reportId === id && expectedDate === resultDate
                    })
            )
            .map((date) => {
                const props = {...options, date}

                return <CalendarItem {...props} />
            })
    }

    renderChildren = () => {
        const {data, expected} = this.props
        if (!data || !expected) {
            return null
        }

        const resultProps = this.getResultProps(data)
        const expectedProps = this.getExpectedProps(expected)

        const result = [
            resultProps.map((props) => <CalendarItem {...props} />),
            expectedProps.flat().map((props) => <CalendarItem {...props} />),
        ]

        return result
    }

    onSelectDate = (date, view) => {
        const {fetch, fetchExpected} = this.props
        this.setState({date, view})
        fetch({date, view})
        fetchExpected({date, view})
    }

    onCreateClick = () => {
        const {create} = this.props
        create(this.onCreated)
    }

    onCreated = () => {
        const {date, view} = this.state
        this.onSelectDate(date, view)
    }

    get additionToBar() {
        return (
            <Bar className="remoteInspection-btns">
                <CreateReportButton onClick={this.onCreateClick}></CreateReportButton>
            </Bar>
        )
    }

    render() {
        const {reports, isLoading} = this.props

        return (
            <Calendar
                {...reports}
                onSelectDate={this.onSelectDate}
                isLoading={isLoading}
                defaultView={YEAR}
                additionToBar={this.additionToBar}
            >
                {this.renderChildren()}
            </Calendar>
        )
    }
}

export default compose(
    withPermission({isAllowed: page, isReviewActive: markAsViewed}),
    withRejection(),
    connect(
        (
            {panels},
            {
                match: {
                    params: {id},
                },
            }
        ) => {
            const state = panels.reports.result[id]
            const {error, data, ...reports} = state || {}

            const stateExpected = panels.reports.expected[id]
            const {data: expected} = stateExpected || {}

            return {
                isLoading:
                    !state ||
                    state.isLoading ||
                    !stateExpected ||
                    stateExpected.isLoading,
                error,
                reports,
                data,
                expected,
            }
        },
        (
            dispatch,
            {
                match: {
                    params: {id},
                },
            }
        ) =>
            bindActionCreators(
                {
                    reset: () => reset({date: moment(), view: YEAR}, parseInt(id)),
                    fetch: (...arg) => fetch(...arg, parseInt(id)),
                    review: (...arg) => review(...arg, parseInt(id)),
                    create: (onSuccess) =>
                        showCreateReportModal([parseInt(id)], onSuccess),
                    resetExpected: () =>
                        resetExpected({date: moment(), view: YEAR}, parseInt(id)),
                    fetchExpected: (...arg) => fetchExpected(...arg, parseInt(id)),
                    stopPoll,
                },
                dispatch
            )
    ),
    withLifeCycle({
        onMount: ({reset, resetExpected}) => {
            reset()
            resetExpected()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    })
)(Reports)
