import React, {useEffect} from 'react'
import {useActions} from 'modules/higherOrder/useActions'
import {compose} from 'redux'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import {clear} from 'modules/forms/actions'
import withForm from 'containers/withForm'
import {dlsConnectDsc} from 'modules/forms/handlers'
import ConnectDscFormFields from './ConnectDlsFormFields'

const rules = {
    host: {
        presence: true,
        host: true,
    },
    port: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 80,
            lessThanOrEqualTo: 65535,
        },
    },
    applicationProtocol: {
        presence: true,
    },
    networkProtocol: {
        presence: true,
    },
}

const DlsConnectDsc = ({panelId, hide, errors}) => {
    const connectDsc = useActions(dlsConnectDsc)
    const clearForm = useActions(clear)

    useEffect(() => {
        return () => clearForm()
    }, [])

    const onConnect = ({host, port, networkProtocol, applicationProtocol}) => {
        connectDsc(panelId, host, port, networkProtocol, applicationProtocol)
    }
    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={onConnect}
            header={__('Dsc connect')}
            rules={rules}
            hide={hide}
            className="dls-dsc-main-form"
        >
            <ConnectDscFormFields errors={errors} />
        </ModalCardForm>
    )
}

export default compose(withForm(dlsConnectDsc))(DlsConnectDsc)
