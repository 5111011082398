import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Form from 'ipmp-react-ui/Form'
import Error from 'ipmp-react-ui/Error'

import ThermostatModeChoose from 'ui/ThermostatModeChoose'

import {selectTemperatureScale} from 'modules/settings/selectors'
import HadThermostatControlFormTargetTemperature from 'components/Had/HadThermostatControlFormTargetTemperature'
import {
    isThermostatModeChangeSupported,
    isThermostatTargetChangeSupported,
    resolveThermostatCommands,
} from 'constants/had/command'
import __ from 'utils/i18n'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
} from 'constants/had/thermostat'
import {
    thermostatControl,
    thermostatMode,
    thermostatTarget,
} from 'permissions/panel/output/actions'
import {withPermission, withRejection} from 'containers/withPermission'

const ThermostatControlForm = ({
    errors,
    isLoading,
    handle,
    had,
    panelId,
    isAllowedMode,
    isAllowedTarget,
}) => {
    const [currentMode, setCurrentMode] = useState(had?.state?.thermostat?.mode)

    const userTemperatureScale = useSelector(selectTemperatureScale)
    const onSubmitHandler = (formData) => {
        handle &&
            formData &&
            handle(
                new HadControlForm({
                    commands: resolveThermostatCommands({
                        thermostatMode:
                            had?.state?.thermostat?.mode === currentMode
                                ? undefined
                                : currentMode,
                        supportedCommands: had?.supportedCommands,
                        targetHeat: Number(
                            formData[`target_${HAD_DEVICE_THERMOSTAT_MODE_HEAT}`]
                        ),
                        targetCool: Number(
                            formData[`target_${HAD_DEVICE_THERMOSTAT_MODE_COOL}`]
                        ),
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }

    const onChangeModeHandler = (mode) => {
        setCurrentMode(Number(mode))
    }

    const options = had?.state?.thermostat?.modes || []
    const isThermostatModeChoose =
        isThermostatModeChangeSupported(had?.supportedCommands) && Boolean(options.length)
    const isThermostatTarget = isThermostatTargetChangeSupported(had?.supportedCommands)
    const isControlSupported = isThermostatModeChoose && isThermostatTarget

    return (
        <Form
            className="thermostatControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
        >
            {!isControlSupported && <Error message={__('Not supported')} />}
            {isAllowedMode && isThermostatModeChoose && (
                <ThermostatModeChoose
                    name="mode"
                    defaultValue={currentMode}
                    onChange={onChangeModeHandler}
                    options={Object.keys(options).map(Number)}
                />
            )}
            {isAllowedTarget && isThermostatTarget && (
                <HadThermostatControlFormTargetTemperature
                    name="target"
                    state={had.state}
                    mode={currentMode}
                    userTemperatureScale={userTemperatureScale}
                />
            )}
        </Form>
    )
}

ThermostatControlForm.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.object,
        type: PropTypes.number,
    }),
}

export default compose(
    withPermission({
        isAllowed: thermostatControl,
        isAllowedMode: thermostatMode,
        isAllowedTarget: thermostatTarget,
    }),
    withRejection()
)(ThermostatControlForm)
