import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class InteractiveSession extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            doAutoEnableUserApp: PropTypes.bool,
            doAutoEnableConfiguratorApp: PropTypes.bool,
            isDlsServiceEnabled: PropTypes.bool,
            globalServerApptype: PropTypes.string,
            maxInteractiveConnections: PropTypes.number,
            maxInteractiveConnectionsPerPanel: PropTypes.number,
            doRequireUserInstallerApproval: PropTypes.bool,
            requireUserInstallerApprovalTimeout: PropTypes.number,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <>
                {this.renderListItem(
                    __('Enable Customer App for New Incoming Panels'),
                    data.doAutoEnableUserApp,
                    true
                )}
                {this.renderListItem(
                    __('Enable Installer App for New Incoming Panels'),
                    data.doAutoEnableConfiguratorApp,
                    true
                )}
                {this.renderListItem(
                    __('Enable DLS service'),
                    data.isDlsServiceEnabled,
                    true
                )}
                {this.renderListItem(
                    __('Application Type'),
                    data.globalServerApptype,
                    true
                )}
                {this.renderListItem(
                    __('Limit of Concurrent Interactive Connections per Server'),
                    data.maxInteractiveConnections,
                    true
                )}
                {this.renderListItem(
                    __('Limit of Concurrent Interactive Sessions per Panel'),
                    data.maxInteractiveConnectionsPerPanel,
                    true
                )}
                {this.renderListItem(
                    __('Approve Installer Access by Customer'),
                    data.doRequireUserInstallerApproval
                )}
                {data.doRequireUserInstallerApproval &&
                    this.renderListItem(
                        __('Installer Access Timeout [Minutes]'),
                        data.requireUserInstallerApprovalTimeout
                    )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {interactiveSession},
            },
        }) => ({...interactiveSession})
    )
)(InteractiveSession)
