import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {HAD_TYPE_DIMMER, HAD_TYPE_PGM, HAD_TYPE_THERMOSTAT} from 'constants/had'
import withForm from 'containers/withForm'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'
import {warn} from 'utils/log'
import {getPanelId} from 'utils/panelIdMatchSelector'

import PgmControlForm from './DeviceControlForm/PgmControlForm'
import ThermostatControlForm from './DeviceControlForm/ThermostatControlForm'
import DimmerControlForm from './DeviceControlForm/DimmerControlForm'
import HadDisclaimer from './HadDisclaimer'

const deviceControlFormMap = new Map([
    [HAD_TYPE_PGM, withForm(submitHadDeviceControlForm)(PgmControlForm)],
    [HAD_TYPE_DIMMER, withForm(submitHadDeviceControlForm)(DimmerControlForm)],
    [HAD_TYPE_THERMOSTAT, withForm(submitHadDeviceControlForm)(ThermostatControlForm)],
])

const deviceControlResolve = (hadType) => {
    if (deviceControlFormMap.has(hadType)) {
        return deviceControlFormMap.get(hadType)
    }

    warn(`No home automation device settings for type:${hadType}`)

    return deviceControlFormMap.get(HAD_TYPE_PGM)
}

const DeviceControlTab = ({had, disabled}) => {
    if (disabled) {
        return <HadDisclaimer />
    }

    const panelId = useSelector((state) => getPanelId(state))
    const DeviceControlForm = deviceControlResolve(had.type)

    return <DeviceControlForm had={had} panelId={Number(panelId)} />
}

DeviceControlTab.propTypes = {
    had: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
}

export default DeviceControlTab
