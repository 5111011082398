import React from 'react'
import PropTypes from 'prop-types'

import {Button} from 'ipmp-react-ui'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'
import {ReactComponent as IconDlsConnect} from 'ipmp-react-ui/icons/union.svg'
import {ReactComponent as IconDlsDisconnect} from 'ipmp-react-ui/icons/union-broken.svg'
import {ReactComponent as IconDlsManage} from 'ipmp-react-ui/icons/union-disabled.svg'

import ButtonWithConfirmation from 'components/Button/ButtonWithConfirmation'
import {ConfirmationDisconnectMenuItem} from './Dls'
import {useDlsConnection} from 'hooks/panel/devices'
import {__} from 'utils/i18n'

export const ConnectionButtons = ({name, connection, panelId}) => {
    const {isConnectAllow, isDisconnectAllow, connectHandler, disconnectHandler} =
        useDlsConnection(name, connection, panelId)

    if (isConnectAllow && isDisconnectAllow) {
        return (
            <DropDownButton
                flat
                label={__('Manage')}
                Icon={IconDlsManage}
                slideDownClassName="dls-connection-buttons-drop-down-slide"
                className="dls-connection-buttons"
            >
                <Menu>
                    <MenuItem
                        Icon={IconDlsConnect}
                        children={__('Connect')}
                        onClick={connectHandler}
                    />
                    <ConfirmationDisconnectMenuItem disconnect={disconnectHandler} />
                </Menu>
            </DropDownButton>
        )
    }

    if (isConnectAllow) {
        return (
            <Button
                Icon={IconDlsConnect}
                flat
                label={__('Connect')}
                onClick={connectHandler}
                className="dls-connection-buttons"
            />
        )
    }

    if (isDisconnectAllow) {
        return (
            <ButtonWithConfirmation
                Icon={IconDlsDisconnect}
                flat
                label={__('Disconnect')}
                title={__('Disconnect')}
                message={__('Do you really want to disconnect DLS session?')}
                onClick={disconnectHandler}
                className="dls-connection-buttons"
            />
        )
    }

    return null
}

ConnectionButtons.propTypes = {
    name: PropTypes.string.isRequired,
    connection: PropTypes.object.isRequired,
    panelId: PropTypes.number.isRequired,
}
