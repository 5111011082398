import __ from './i18n'

export function convertBytesToMb(bytes) {
    return Math.round((bytes / 1024 / 1024) * 10) / 10
}
export function convertBytesToKb(bytes) {
    return Math.round((bytes / 1024) * 10) / 10
}
export function convertBytes(bytes) {
    return convertBytesToMb(bytes) > 0 ? convertBytesToMb(bytes) : convertBytesToKb(bytes)
}
export function fileSizeUnit(bytes) {
    return convertBytesToMb(bytes) > 0 ? __('MB') : __('KB')
}
