import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {ReactComponent as DefaultLogo} from 'assets/logo.svg'
import {ReactComponent as DarkThemeLogo} from 'assets/logo_dark_theme.svg'

const Logo = ({onClick, dark = false}) => {
    const [content, setContent] = useState(null)

    useEffect(() => {
        const getCustomLogo = async () => {
            await axios.get('/assets/custom-logo.svg').then((resp) => {
                if (resp.data.search(/doctype html/) === -1) {
                    setContent(resp.data.replace(/<!--[\s\S]*?-->/g, '').trim())
                } else {
                    setContent(null)
                }
            })
        }

        getCustomLogo()
    }, [])

    const handleClick = () => onClick && onClick()

    if (!content) {
        return (
            <div className="logo" onClick={handleClick}>
                {dark ? (
                    <DarkThemeLogo className="logo-icon" />
                ) : (
                    <DefaultLogo className="logo-icon" />
                )}
            </div>
        )
    }

    return (
        <div
            className="logo logo--custom"
            dangerouslySetInnerHTML={{__html: content}}
            onClick={handleClick}
        />
    )
}

Logo.propTypes = {
    dark: PropTypes.bool,
    light: PropTypes.bool,
}

export default Logo
