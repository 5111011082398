import {takeEvery, all, call, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

import {fetch, receive} from './actions'
import {update} from 'modules/centralStations/store/actions'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'

import {getCentralStation} from 'api/centralStations'

export default function* () {
    yield all([takeEvery(fetch, watchFetch)])
}

function* watchFetch({payload: centralStationId}) {
    try {
        const data = yield call(getCentralStation, centralStationId)
        yield put(fetchProtocols())

        yield put(update([data]))
        yield put(receive())
    } catch (error) {
        yield put(receive(error))
        yield put(snackShow(error.message))
    }
}
