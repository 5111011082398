import {useSelector} from 'react-redux'
import {
    changeValue,
    clearFilters,
    dismissChanges,
    fetch,
    fetchOne,
    refresh,
    refreshRunner,
    restoreBackup,
    setExport,
    setMakeBasic,
    setQuery,
    setShowChanges,
    setShowExportable,
} from 'modules/panels/configuration/actions'
import {startPollRunnerData, stopPollRunnerData} from 'modules/runners/actions'
import {
    selectBackupConfiguration,
    selectBackupConfigurationValues,
    selectCurrentConfiguration,
} from 'modules/panels/configuration/selectors'
import {useActions} from 'modules/higherOrder/useActions'

export function useConfigurationActions(panelId) {
    return useActions({
        refresh: (...arg) => refresh(panelId, ...arg),
        onChange: (...arg) => changeValue(panelId, ...arg),
        onSetExport: (...arg) => setExport(panelId, ...arg),
        onSearch: (...arg) => setQuery(panelId, ...arg),
        setMakeBasic: (...arg) => setMakeBasic(panelId, ...arg),
        setShowExportable: (...arg) => setShowExportable(panelId, ...arg),
        setShowChanges: (...arg) => setShowChanges(panelId, ...arg),
        dismissChanges: () => dismissChanges(panelId),
        clearFilters: () => clearFilters(panelId),
        restoreBackup: (...arg) => restoreBackup(panelId, ...arg),
        startPollRunnerData,
        stopPollRunnerData,
        refreshRunner,
    })
}

export function useCurrentConfiguration(props) {
    const current = useSelector((state) => selectCurrentConfiguration(state, props))

    return {
        ...current,
        ...useActions({
            fetch: () => fetch(props.panelId),
        }),
    }
}

export function useCompareConfiguration(props) {
    const configuration = useSelector((state) => selectCurrentConfiguration(state, props))
    const backup = useSelector((state) => selectBackupConfigurationValues(state, props))

    const actions = useActions({
        fetch: () => fetchOne(props.panelId, props.configId),
    })

    if (!backup) {
        return actions
    }

    return {
        ...configuration,
        ...actions,
        backup,
    }
}

export function useBackupConfiguration(props) {
    const backup = useSelector((state) => selectBackupConfiguration(state, props))

    return {
        ...backup,
        ...useActions({
            fetch: () => fetchOne(props.panelId, props.configId),
        }),
    }
}
