import React from 'react'

import {ReactComponent as ArrowPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'
import {ReactComponent as ArrowNext} from 'ipmp-react-ui/icons/arrow-next.svg'

import Button from 'ipmp-react-ui/Button'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'

export const PreviousButton = () => {
    const {selectPrevious, hasPrev} = useRemoteInspectionsResult()

    return (
        <Button
            onClick={() => hasPrev && selectPrevious()}
            disabled={!hasPrev}
            borderless
            className="blindModalDevices-paginator-prev"
            children={<ArrowPrev />}
        />
    )
}

export const NextButton = () => {
    const {selectNext, hasNext} = useRemoteInspectionsResult()

    return (
        <Button
            onClick={() => hasNext && selectNext()}
            disabled={!hasNext}
            borderless
            className="blindModalDevices-paginator-next"
            children={<ArrowNext />}
        />
    )
}
