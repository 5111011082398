import {warn} from 'utils/log'
import {__} from 'utils/i18n'
import {DEVICE_TYPE_GSM, DEVICE_TYPE_LTE_MODULE} from 'constants/deviceType'

export const PANEL_NAME = 'panel_name'
export const PANEL_ID = 'panel_id'
export const PANEL_TYPE = 'panel_type'
export const PANEL_BOOT_VERSION = 'panel_boot_version'
export const PANEL_DEFAULT_VERSION = 'panel_default_version'
export const PANEL_EEPROM_VERSION = 'panel_eeprom_version'
export const PANEL_HW_VERSION = 'panel_hw_version'
export const PANEL_RSU_VERSION = 'panel_rsu_version'
export const PANEL_SW_LAST_UPGRADED = 'panel_sw_last_upgraded'
export const PANEL_SW_VERSION = 'panel_sw_version'
export const POWERLINK_CONFIGURATION_VARIANT = 'powerlink_configuration_variant'
export const POWERLINK_HW_VERSION = 'powerlink_hw_version'
export const POWERLINK_SW_LAST_UPGRADED = 'powerlink_sw_last_upgraded'
export const POWERLINK_SW_VERSION = 'powerlink_sw_version'
export const COMMUNICATION_SW_VERSION = 'communicator_sw_version'
export const COMMUNICATION_HW_VERSION = 'communicator_hw_version'
export const GSM_VERSION = `${DEVICE_TYPE_GSM}_version`
export const GSM_SW_VERSION = 'gsm_sw_version'
export const GSM_HW_VERSION = 'gsm_hw_version'
export const VIRTUAL_KEYPAD_SW_VERSION = 'virtual_keypad_sw_version'
export const VIRTUAL_KEYPAD_HW_VERSION = 'virtual_keypad_hw_version'
export const LTE_MODULE_VERSION = `${DEVICE_TYPE_LTE_MODULE}_version`

export default function panelInfo(type) {
    switch (type) {
        case PANEL_NAME:
            return __('Panel Name')
        case PANEL_ID:
            return __('Panel Id')
        case PANEL_TYPE:
            return __('Panel Type')
        case PANEL_BOOT_VERSION:
            return __('Panel Boot Version')
        case PANEL_DEFAULT_VERSION:
            return __('Panel Default Version')
        case PANEL_EEPROM_VERSION:
            return __('Panel Eeprom Version')
        case PANEL_HW_VERSION:
            return __('Panel Hardware Version')
        case PANEL_RSU_VERSION:
            return __('Panel RSU Version')
        case PANEL_SW_LAST_UPGRADED:
            return __('Panel Software Last Upgraded')
        case PANEL_SW_VERSION:
            return __('Panel Software Version')
        case POWERLINK_CONFIGURATION_VARIANT:
            return __('Panel Configuration Variant')
        case POWERLINK_HW_VERSION:
            return __('Powerlink Hardware Version')
        case POWERLINK_SW_LAST_UPGRADED:
            return __('Powerlink Software Last Upgraded')
        case POWERLINK_SW_VERSION:
            return __('Powerlink Software Version')
        case COMMUNICATION_SW_VERSION:
            return __('Communication Software Version')
        case COMMUNICATION_HW_VERSION:
            return __('Communication Hardware Version')
        case GSM_VERSION:
            return __('GSM Version')
        case GSM_SW_VERSION:
            return __('GSM Software Version')
        case GSM_HW_VERSION:
            return __('GSM Hardware Version')
        case VIRTUAL_KEYPAD_SW_VERSION:
            return __('Virtual Keyboard Software Version')
        case VIRTUAL_KEYPAD_HW_VERSION:
            return __('Virtual Keyboard Hardware Version')
        case LTE_MODULE_VERSION:
            return __('LTE software Version')
        default:
            warn(`Unknown info type ${type}`)
            return __('Unknown info')
    }
}
