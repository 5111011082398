export const MENU_TYPE = 'menu'
export const LIST_TYPE = 'list'
export const CODE_TYPE = 'code'
export const CODE_HEX_TYPE = 'code_hex'
export const STRING_TYPE = 'string'
export const PHONE_TYPE = 'phone'
export const BITSET_TYPE = 'bitset'

export const CONFIGURATION_SEPARATOR = '$$'

const USER_CODE = 'USER_CODE'

export const isAllowedEmptyValue = (key) => {
    return key && key.includes(USER_CODE)
}
