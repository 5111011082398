import React, {PureComponent, useEffect} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import moment from 'moment'

import {suspendFaults as suspendFaultsHandler} from 'modules/forms/handlers'

import withForm from 'containers/withForm'
import withModalLoader from 'containers/withModalLoader'

import FaultChecklist from 'components/List/FaultChecklist'

import ModalCardForm from 'ui/ModalCardForm'
import CardMessage from 'ipmp-react-ui/CardMessage'
import {FormValidationGroup} from 'ipmp-react-ui/Form'
import InputDatePicker from 'ipmp-react-ui/InputDatePicker'

import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'
import {usePanelsRefresh} from 'hooks/pages/usePanels'

class SuspendFaults extends PureComponent {
    static propTypes = {
        panels: PropTypes.arrayOf(
            PropTypes.shape({
                serial: PropTypes.string,
                account: PropTypes.string,
                faults: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        zone: PropTypes.number,
                        comment: PropTypes.string,
                        isSuspended: PropTypes.bool,
                    })
                ),
            })
        ),
        isLoading: PropTypes.bool,
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func.isRequired,
    }

    rules = {
        faultId: {
            presence: {
                message: __('You must select at least one fault to suspend'),
            },
        },

        until: {
            presence: true,
            datetime: true,
        },
    }

    handle = ({faultId, until}) => {
        const {handle} = this.props
        const date = moment(until, moment.localeData().longDateFormat('L'))

        if (!Array.isArray(faultId)) {
            faultId = [faultId]
        }

        handle(
            faultId.map((id) => parseInt(id)),
            date.startOf('day')
        )
    }

    filterFault = (fault) => !fault.isSuspended

    render() {
        const {panels, isLoading, hide} = this.props

        if (
            !panels.some((panel) => panel.faults && panel.faults.find(this.filterFault))
        ) {
            return (
                <Modal onClose={hide}>
                    <CardMessage
                        title={__('Suspend Faults')}
                        message={__('No suspendable faults found')}
                        onClose={hide}
                        onPositive={hide}
                    />
                </Modal>
            )
        }

        return (
            <ModalCardForm
                hide={hide}
                onSubmit={this.handle}
                header={__('Suspend Faults')}
                rules={this.rules}
                isLoading={isLoading}
            >
                <FormValidationGroup name="faultId">
                    <FaultChecklist
                        panels={panels}
                        filter={this.filterFault}
                        emptyMessage={__('There are no suspendable faults')}
                    />
                </FormValidationGroup>

                <InputDatePicker
                    {...{
                        name: 'until',
                        label: __('Suspend until'),
                        defaultValue: moment().add(1, 'week'),
                        range: false,
                        min: moment(),
                        dataTestId: 'until',
                    }}
                />
            </ModalCardForm>
        )
    }
}

const SuspendFaultsWithForm = compose(
    withForm(suspendFaultsHandler),
    withModalLoader()
)(SuspendFaults)

export default function Suspend(props) {
    const {panelIds} = props
    const panelsRefresh = usePanelsRefresh(panelIds)

    useEffect(() => {
        panelsRefresh.refresh(panelIds)
    }, [])

    const modalProps = {...props, ...panelsRefresh}

    return <SuspendFaultsWithForm {...modalProps} />
}
