import React, {Component} from 'react'
import {Route, Switch} from 'react-router'
import {compose} from 'redux'
import {useSelector} from 'react-redux'

import page from 'permissions/panel/configuration/page'
import {upload} from 'permissions/panel/configuration/actions'

import {withConfigurationPreloader} from 'containers/withConfiguration'
import withConfigurationsList from 'containers/withConfigurationsList'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withProps from 'containers/withProps'

import path from 'utils/path'

import PanelConfigurationsList from './List/PanelConfigurationsList'
import PanelViewConfiguration from './View/PanelViewConfiguration'
import {selectUploadConfigurationFeature} from 'modules/features/store/selectors'
import withRunnerPoll from 'containers/withRunnerPoll'
import {
    useBackupConfiguration,
    useCompareConfiguration,
    useCurrentConfiguration,
} from 'hooks/pages/useConfiguration'

const CurrentConfiguration = (props) => {
    const feature = useSelector((state) => selectUploadConfigurationFeature(state, props))
    const current = useCurrentConfiguration(props)

    const viewProps = {...props, ...feature, ...current}

    return <PanelViewConfiguration {...viewProps} isEditable={viewProps.isAvailable} />
}

const CompareConfiguration = (props) => {
    const compare = useCompareConfiguration(props)
    const viewProps = {...props, ...compare}

    return <PanelViewConfiguration {...viewProps} isCompareWithBackup />
}

const BackupConfiguration = (props) => {
    const backup = useBackupConfiguration(props)
    const viewProps = {...props, ...backup}

    return <PanelViewConfiguration {...viewProps} isEditable={false} />
}

class ConfigurationsPage extends Component {
    renderList = () => <PanelConfigurationsList {...this.props} />

    renderCurrent = () => {
        return (
            <CurrentConfiguration
                panelId={this.props.panelId}
                isEditable={this.props.isEditable}
            />
        )
    }

    renderView = ({match}) => {
        const id = parseInt(match.params.configId)

        return <BackupConfiguration panelId={this.props.panelId} configId={id} />
    }

    renderCompare = ({match}) => {
        const id = parseInt(match.params.configId)
        return <CompareConfiguration panelId={this.props.panelId} configId={id} />
    }

    componentDidMount() {
        const {panelId, pendingChanges, history} = this.props

        if (pendingChanges) {
            history.push(path('panel.configuration.current', {id: panelId}))
        }
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={path('panel.configuration')}
                    component={this.renderList}
                />
                <Route
                    exact
                    path={path('panel.configuration.current')}
                    component={this.renderCurrent}
                />
                <Route
                    exact
                    path={path('panel.configuration.view')}
                    component={this.renderView}
                />
                <Route
                    exact
                    path={path('panel.configuration.compare')}
                    component={this.renderCompare}
                />
            </Switch>
        )
    }
}

export default compose(
    withPermission({
        isAllowed: page,
        isEditable: upload,
    }),
    withRejection(),
    withProps(({match}) => ({
        panelId: parseInt(match.params.id),
    })),
    withConfigurationsList(),
    withLoader(({fetch}) => fetch()),
    withRunnerPoll(),
    withConfigurationPreloader({
        isErrorCallback: ({rows}) => !rows || !rows.length,
    })
)(ConfigurationsPage)
