import moment from 'moment/moment'
import timezones from 'utils/timezones'

const smartDateR = /{([-+]?\d+)?(day|month|year)(?::([-+]?\d+)(day|month|year))?}/

function parseMoment(key, value) {
    if (moment.isMoment(value)) {
        return [
            filterField(key, value.format('YYYY-MM-DD 00:00:00'), '>='),
            filterField(key, value.format('YYYY-MM-DD 23:59:59'), '<='),
        ].join('&&')
    }
}

function parseDateObject(key, value) {
    if (value instanceof Object) {
        let result = []

        if (value.from) {
            result.push(
                filterField(
                    key,
                    timezones.server(value.from).format('YYYY-MM-DD 00:00:00'),
                    '>='
                )
            )
        }

        if (value.to) {
            result.push(
                filterField(
                    key,
                    timezones.server(value.to).format('YYYY-MM-DD 23:59:59'),
                    '<='
                )
            )
        }

        return result.join('&&')
    }
}

function parseArray(key, value, comparator = '=') {
    if (Array.isArray(value)) {
        return value.map((v) => filterField(key, v, comparator)).join('||')
    }
}

function parseSmartDateMatches(matches) {
    const from = timezones.server().add(matches[1], matches[2])
    const to = timezones.server().add(matches[3], matches[4])

    return {
        from: moment.min(from, to),
        to: moment.max(from, to),
    }
}

function parseSmartDate(key, value) {
    const matches = smartDateR.exec(value)

    if (matches) {
        return filterField(key, parseSmartDateMatches(matches))
    }
}

function parseSmart(key, value) {
    const matches = /^{([!><=]+|:)(.*)}$/.exec(value)

    if (matches) {
        return filterField(key, matches[2], matches[1])
    }
}

function parseNull(key, value) {
    if (value === null || value === undefined) {
        return ` :${key}!!; `
    }
}

function filterField(key, value, comparator = '=') {
    return (
        parseArray(key, value, comparator) ||
        parseMoment(key, value, comparator) ||
        parseDateObject(key, value, comparator) ||
        parseSmart(key, value, comparator) ||
        parseSmartDate(key, value, comparator) ||
        parseNull(key, value, comparator) ||
        ` :${key}${comparator}${value}; `
    )
}

const isSmartDate = (value) => !!smartDateR.exec(value)
// const isDateObject = (value) => value instanceof Object && (value.from || value.to)

const isDateFilter = (value) =>
    isSmartDate(value) || moment.isMoment(value) /* || isDateObject(value)*/

function sortDataFilters(filters: Array) {
    const dateFilters = []
    const restFilters = []

    filters.forEach((filter) => {
        if (isDateFilter(filter.value)) {
            dateFilters.push(filter)
        } else {
            restFilters.push(filter)
        }
    })

    dateFilters.sort((leftFilter, rightFilter) => {
        const normalLeftFilterValue = isSmartDate(leftFilter.value)
            ? parseSmartDateMatches(smartDateR.exec(leftFilter.value)).from
            : leftFilter.value
        const normalRightFilterValue = isSmartDate(rightFilter.value)
            ? parseSmartDateMatches(smartDateR.exec(rightFilter.value)).from
            : rightFilter.value

        return moment(normalLeftFilterValue).diff(normalRightFilterValue)
    })

    return [...restFilters, ...dateFilters]
}

export default function generateFilterString(obj, keysmap = {}, valuesMap = {}) {
    if (!obj) {
        return
    }

    if (Array.isArray(obj)) {
        obj = sortDataFilters(obj).reduce((acc, {name, value}) => {
            acc[name] = [...(acc[name] || []), value]
            return acc
        }, {})
    }

    const result = Object.keys(obj)
        .map((key) => {
            const value = valuesMap[key] ? valuesMap[key][obj[key]] : obj[key]
            return filterField(keysmap[key] || key, value)
        })
        .join('&&')

    return result.trim()
}
