import {createSelector} from 'reselect'

export const selectPanelProcessesList = createSelector(
    (state) => state.panels.processes.page,
    (state) => state.processes.store.byIds,
    (page, store) =>
        page.reduce(
            (acc, id) =>
                store[id] !== undefined && store[id] !== null ? [...acc, store[id]] : acc,
            []
        )
)
