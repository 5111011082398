import {createSelector} from 'reselect'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'

export const selectOnlineIsLoading = (state) => state.dashboard.online.isLoading
export const selectOnlineData = (state) => state.dashboard.online.data
export const selectOnlineError = (state) => state.dashboard.online.error

export const selectOnline = createSelector(
    [selectOnlineIsLoading, selectOnlineData, selectOnlineError],
    (isLoading, data, error) => {
        const to = data.length ? maxBy(data, ({time}) => time).time : new Date()
        const from = data.length ? minBy(data, ({time}) => time).time : new Date()

        const {total, faulty, online} = data.reduce(
            (acc, {time, total, faulty, online}) => {
                acc.total.push({x: time, y: total})
                acc.faulty.push({x: time, y: faulty})
                acc.online.push({x: time, y: online})

                return acc
            },
            {total: [], faulty: [], online: []}
        )

        return {
            isLoading,
            from,
            to,
            faultyData: faulty,
            offlaneData: [],
            onlineData: online,
            totalData: total,
            error,
        }
    }
)
