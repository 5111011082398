import {bindActionCreators} from 'redux'
import {useDispatch, useSelector} from 'react-redux'

import {fetch} from 'modules/panels/systemLogs/list/actions'
import {selectPanelSystemLogs} from 'modules/panels/systemLogs/list/selectors'

export default function usePanelSystemLogs(panelId) {
    const dispatch = useDispatch()
    const logs = useSelector((state) => selectPanelSystemLogs(state, panelId))

    return {
        ...logs,
        ...bindActionCreators(
            {
                fetch: () => fetch(panelId),
            },
            dispatch
        ),
    }
}
