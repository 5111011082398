import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'

import {setPartitions} from 'permissions/panel/customers/actions'

import {showSetUserPartitionsModal} from 'modules/modals/actions'
import {selectUserEditableFeature} from 'modules/features/store/selectors'

import {withVisibility} from 'containers/withVisibility'
import {PANEL_MASTER_USER_ID} from 'constants/panelUsers'
import {isAllowManipulateUser} from 'constants/accountRoles'
import {USER_FEATURE_EDITABLE_PARTITIONS} from 'constants/features'

export default compose(
    withPermission({
        isVisible: setPartitions,
    }),
    withVisibility(),
    connect(
        (state, {panelId, isPinSet}) => {
            const isFeatureEnabled = selectUserEditableFeature(state, {
                panelId,
                item: USER_FEATURE_EDITABLE_PARTITIONS,
            })

            return {isAllowedSetPartitions: isFeatureEnabled && isPinSet}
        },
        (dispatch, {panelId, panelUserId, partitions}) =>
            bindActionCreators(
                {
                    setUserPartitionsModal: () =>
                        showSetUserPartitionsModal({panelId, panelUserId, partitions}),
                },
                dispatch
            )
    ),
    withProps(({setUserPartitionsModal, isAllowedSetPartitions, panelUserId, role}) => ({
        Icon: IconEdit,
        flat: true,
        small: true,
        onClick: setUserPartitionsModal,
        disabled:
            !isAllowedSetPartitions ||
            panelUserId === PANEL_MASTER_USER_ID ||
            !isAllowManipulateUser(role),
    }))
)(Button)
