import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Input from 'ipmp-react-ui/Input'

import ModalCardForm from 'ui/ModalCardForm'

import withForm from 'containers/withForm'
import {registerInteractiveUser} from 'modules/forms/handlers'
import __ from 'utils/i18n'

class RegisterInteractiveUser extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
    }

    rules = {
        email: {
            presence: true,
            email: true,
        },
    }

    handle = ({email}) => {
        const {handle} = this.props

        handle({
            email,
        })
    }

    render() {
        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={__('Register New Customer')}
                rules={this.rules}
                {...this.props}
            >
                <Input label={__('Email')} name="email" />
            </ModalCardForm>
        )
    }
}

export default compose(withForm(registerInteractiveUser))(RegisterInteractiveUser)
