import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'

export default function Notification({
    notification: {hash, title, description, Slot, onClick},
    onClose,
}) {
    const close = () => {
        onClose && onClose(hash)
    }

    const click = (e) => {
        if (!e.target.closest('.c-notification__close')) {
            onClick && onClick()
        }
    }

    return (
        <div className="c-notification" onClick={click}>
            <h5 className="c-notification__title">{title}</h5>
            <div className="c-notification__description-wrap">{description}</div>
            <Button
                className="c-notification__close"
                Icon={IconCross}
                borderless
                onClick={close}
            />
            {Slot}
        </div>
    )
}

Notification.propTypes = {
    notification: PropTypes.shape({
        hash: PropTypes.string.isRequired,
        title: PropTypes.node.isRequired,
        description: PropTypes.node.isRequired,
        Slot: PropTypes.node,
        onClick: PropTypes.func,
    }),
    onClose: PropTypes.func,
}
