import React, {Component} from 'react'
import {compose} from 'redux'

import InteractiveUsersPager from './GridBar/InteractiveUsersPager'
import InteractiveSuspendButton from './GridBar/InteractiveSuspendButton'
import InteractiveEnableButton from './GridBar/InteractiveEnableButton'
import InteractiveUsersSelectionDropDown from './GridBar/InteractiveUsersSelectionDropDown'
import AddInteractiveUser from './GridBar/AddInteractiveUser'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import InteractiveUserRemoveButton from 'pages/InteractiveUsers/GridBar/InteractiveUserRemoveButton'

export class InteractiveUsersBar extends Component {
    render() {
        return (
            <Bar>
                <InteractiveUsersSelectionDropDown />
                <InteractiveSuspendButton />
                <InteractiveEnableButton />
                <InteractiveUserRemoveButton />

                <BarSpace />

                <InteractiveUsersPager />
                <AddInteractiveUser />
            </Bar>
        )
    }
}

export default compose()(InteractiveUsersBar)
