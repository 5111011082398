import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import __, {__n} from 'utils/i18n'

const PanelStateSidebarBypassAll = ({
    onBypassChange,
    progress,
    isBypassAllowed,
    deviceBypassStates,
    devices,
    selectedToEnableCount,
    selectedToDisableCount,
    label = __('Bypass All'),
}) => {
    const enabledDevices = devices.filter((device) => !device?.traits?.bypass?.enabled)
    const isChecked =
        enabledDevices.length &&
        enabledDevices.every(({id}) => deviceBypassStates.has(id))
    const isNoSelected = selectedToEnableCount === 0 && selectedToDisableCount === 0

    return (
        <div className="partitionDevices-sidebar-bypassAll-container">
            <div className="partitionDevices-sidebar-bypassAll-title">
                {isNoSelected && <p>{__('Not selected to bypass')}</p>}
                {selectedToEnableCount > 0 && (
                    <p>
                        {__n(
                            'Enable for %d devices',
                            'Enable for %d devices',
                            selectedToEnableCount
                        )}
                    </p>
                )}
                {selectedToDisableCount > 0 && (
                    <p>
                        {__n(
                            'Disable for %d devices',
                            'Disable for %d devices',
                            selectedToDisableCount
                        )}
                    </p>
                )}
            </div>
            {enabledDevices.length > 0 && (
                <Checkbox
                    onChange={onBypassChange}
                    label={label}
                    progress={progress}
                    checked={isChecked}
                    disabled={!isBypassAllowed}
                />
            )}
        </div>
    )
}

PanelStateSidebarBypassAll.propTypes = {
    onBypassChange: PropTypes.func.isRequired,
    isBypassAllowed: PropTypes.bool,
    isAllSelected: PropTypes.bool,
    deviceBypassStates: PropTypes.instanceOf(Map).isRequired,
    selectedToEnableCount: PropTypes.number.isRequired,
    selectedToDisableCount: PropTypes.number.isRequired,
}

export default PanelStateSidebarBypassAll
