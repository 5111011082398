import {connect} from 'react-redux'

import {stateTitle} from 'constants/state'

import PanelStatusLine from 'components/PanelStatusLine'
import {troubleTitle} from 'constants/troubleType'
import {alarmTitle} from 'constants/alarmType'
import {SEVERITY_ALARM, SEVERITY_TROUBLE} from 'constants/severityType'

import {selectWarningsByPanelId} from 'modules/devices/list/selectors'
import {selectPartitionStatesById} from 'modules/panels/state/selectors'

function formatStateLines(states) {
    if (states.length) {
        const filtered = states
            .map(({state}) => state)
            .filter((state, index, self) => self.indexOf(state) === index)

        if (filtered.length === 1) {
            return filtered.map(stateTitle)
        } else {
            return states.map(
                ({partition, state}) => `${partition}: ${stateTitle(state)}`
            )
        }
    }

    return []
}

function formatWarningLines(warnings) {
    return warnings.map((warning) => {
        switch (warning.severity) {
            case SEVERITY_ALARM:
                return alarmTitle(warning.type)
            case SEVERITY_TROUBLE:
            default:
                return troubleTitle(warning.type)
        }
    })
}

export default connect((state, {panel: {id}}) => {
    const states = selectPartitionStatesById(state, id)
    const warningLines = formatWarningLines(selectWarningsByPanelId(state, id))
    const uniqueLines = [...new Set([...warningLines, ...formatStateLines(states)])]

    return {
        lines: uniqueLines,
        hasTroubles: !!warningLines.length,
    }
})(PanelStatusLine)
