import {POLL_EVENTS} from 'configs/pollers'
import createListSaga from 'modules/higherOrder/createListSaga'
import {updateRows} from 'modules/events/store/saga'
import {purge, setFirstPageStreamable} from 'modules/events/store/actions'
import {selectPerPage} from 'modules/settings/selectors'
import {
    takeEvery,
    race,
    cancel,
    fork,
    take,
    call,
    put,
    select,
    all,
    delay,
} from 'redux-saga/effects'
import toIds from 'utils/toIds'
import {setLastEventId} from '../alarms/actions'
import * as api from 'api/events'
import * as actions from './actions'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        createListSaga(api, actions, updateRows, selector, purge),
        eventsPoll(),
        takeEvery(actions.reset, watchReset),
        takeEvery(actions.fetchNewer, watchFetchNewer),
        takeEvery(setFirstPageStreamable, watchSetFirstPageStreamable),
        takeEvery(
            [actions.fetchOverbound, actions.nextOverbound, actions.prevOverbound],
            watchFetchOverbound
        ),
    ])
}

function selector(state) {
    let {maxId, filters, ...list} = state.events.list

    if (maxId) {
        filters = [
            ...filters,
            {
                name: 'id',
                value: `{<${maxId + 1}}`,
            },
        ]
    }

    return {
        ...list,
        filters,
        scopes: state.settings.scopes.events,
        perPage: selectPerPage(state, 'events'),
        listRoute: 'events',
    }
}

function* watchReset() {
    try {
        const {rows, count} = yield call(api.fetch, {
            start: 0,
            perPage: 1,
            sort: '-id',
        })

        if (count === 0) {
            return
        }

        yield put(setLastEventId(rows[0].id))
        yield put(actions.setMaxId(rows[0].id))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchFetchNewer() {
    yield put(actions.fetch())
}

function* eventsPoll() {
    while (yield take(actions.startPoll)) {
        const invalidateTask = yield fork(eventsInvalidatePoller)
        const watchNewTask = yield fork(eventsWatchNewPoller)
        yield take(actions.stopPoll)
        yield cancel(invalidateTask)
        yield cancel(watchNewTask)
    }
}

function* watchFetchOverbound() {
    const {perPage, start, total, overbound, ...state} = yield select(selector)

    try {
        const calls = {}

        if (!overbound.next && start + perPage < total) {
            calls.next = call(api.fetch, {
                ...state,
                perPage,
                start: start + perPage,
            })
        }

        if (!overbound.prev && start > 0) {
            calls.prev = call(api.fetch, {
                ...state,
                perPage,
                start: start - perPage,
            })
        }

        if (Object.keys(calls).length === 0) {
            return
        }

        const result = yield all(calls)

        yield updateRows(
            Object.values(result).reduce((acc, {rows}) => acc.concat(rows), [])
        )

        yield put(
            actions.receiveOverbound(
                Object.keys(result).reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: toIds(result[key].rows),
                    }),
                    {}
                )
            )
        )
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* eventsInvalidatePoller() {
    while (true) {
        yield race({
            delay: delay(POLL_EVENTS),
        })

        const {page} = yield select((state) => state.events.list)
        const perPage = yield select((state) => selectPerPage(state, 'events'))

        if (page.length === 0) {
            continue
        }

        try {
            const {rows} = yield call(api.fetch, {
                // Fix incrementing count which leads URI request too large
                perPage: Math.min(page.length, perPage),
                filters: {id: page},
            })
            yield updateRows(rows)
        } catch (error) {
            yield put(snackShow(error.message))
        }
    }
}

function* eventsWatchNewPoller() {
    while (true) {
        yield delay(POLL_EVENTS)

        let {maxId, filters} = yield select((state) => state.events.list)
        const perPage = yield select((state) => state.settings.perPage.events)

        if (maxId) {
            filters = [...filters, {name: 'id', value: `{>${maxId}}`}]
        }

        try {
            const {rows, count} = yield call(api.fetch, {
                perPage,
                filters,
            })

            const {page, total, start, newerCount} = yield select(
                (state) => state.events.list
            )

            const {isFirstPageStreamable} = yield select((state) => state.events.store)

            if (!count) {
                continue
            }

            if (isFirstPageStreamable?.state && start === 0) {
                yield updateRows(rows)
                yield put(
                    actions.receive(
                        toIds(rows).concat(page).slice(0, perPage),
                        total + count
                    )
                )
                yield put(setLastEventId(rows[0].id))
                continue
            }

            if (newerCount !== count) {
                yield put(actions.setNewerCount(count))
            }
        } catch (error) {
            yield put(snackShow(error.message))
        }
    }
}

function* watchSetFirstPageStreamable({payload: {isFirstPageStreamable}}) {
    const {newerCount} = yield select(selector)

    if (newerCount && isFirstPageStreamable) {
        yield put(actions.fetchNewer())
    }
}
