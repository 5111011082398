import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import StationsPager from './Bar/StationsPager'

export default function StationsGridBar() {
    return (
        <Bar>
            <BarSpace />

            <StationsPager />
        </Bar>
    )
}
