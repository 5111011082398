import React from 'react'
import PropTypes from 'prop-types'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ACCOUNT_TAB} from 'pages/Accounts/AccountsRouting'

import path from 'utils/path'
import InteractiveUsersPage from 'pages/InteractiveUsers/InteractiveUsersPage'
import InstallersPage from 'pages/Installers/InstallersPage'

const componentsMap = new Map([
    [ACCOUNT_TAB.INTERACTIVE_USERS, InteractiveUsersPage],
    [ACCOUNT_TAB.INSTALLERS, InstallersPage],
])

const AccountsContent = ({tabs}) => {
    const firstPathAvailablePath = tabs.entries().next()?.value[1]?.path
    return (
        <section className="accountsPage-content">
            <Switch>
                {[...tabs].map(([key, {path: pathName, exact}]) => (
                    <Route
                        exact={exact}
                        path={path(pathName)}
                        component={componentsMap.get(key)}
                        key={key}
                    />
                ))}
                <Redirect to={path(firstPathAvailablePath)} />
            </Switch>
        </section>
    )
}

AccountsContent.propTypes = {
    tabs: PropTypes.instanceOf(Map).isRequired,
}

export default AccountsContent
