import React from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import withSelectLoader from 'containers/withSelectLoader'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import {fetch as fetchGroups} from 'modules/groups/select/actions'

const GroupSelect = withSelectLoader(
    fetchGroups,
    (store) => store.groups.select.isLoading,
    ({groups}) =>
        Object.values(groups.store.byIds).map(({id, name}) => ({label: name, value: id})),
    10
)(Autocomplete)

export default class Group extends React.Component {
    static propTypes = {
        groupName: PropTypes.string,
        groupId: PropTypes.number,
    }

    render() {
        const {groupId, groupName} = this.props

        return (
            <GroupSelect
                label={__('Group')}
                name="groupId"
                defaultValue={groupId}
                defaultLabel={groupName}
                dataTestId="group-id"
            />
        )
    }
}
