import React from 'react'
import {compose} from 'redux'

import list from 'permissions/remoteInspections/list'

import withSelection from 'containers/withSelection'
import {withRejection} from 'containers/withPermission'

import {TableSelection} from 'ipmp-react-ui/Table'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import RemoteInspectionsTable from './Content/RemoteInspectionsTable'
import withSearchScope from 'containers/withSearchScopeLoader'
import RemoteInspectionsResultSidebar from './Content/Result/RemoteInspectionsResultSideBar'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

function RemoteInspectionContentBase({selection}) {
    return (
        <TableSelection selection={selection} className="rri">
            <Layout>
                <ScrollView className="rri-list">
                    <RemoteInspectionsTable />
                </ScrollView>

                <RemoteInspectionsResultSidebar />
            </Layout>
        </TableSelection>
    )
}

const ConnectedRemoteInspectionContent = compose(
    withRejection(),
    withSelection(),
    withSearchScope()
)(RemoteInspectionContentBase)

export default function RemoteInspectionContent(props) {
    const {isPermitted} = usePermission(list)
    const RemoteInspections = useRemoteInspections()

    const componentProps = {...props, ...RemoteInspections}

    return (
        <ConnectedRemoteInspectionContent {...componentProps} isAllowed={isPermitted} />
    )
}
