import has from 'lodash-es/has'

import {err} from 'utils/log'

export default class CacheFields {
    constructor(fields: Array, cacheDelimeter = '_') {
        this.fields = fields
        this.cacheDelimeter = cacheDelimeter
    }

    createCachedFields = (store) => {
        return this.fields.reduce((acc, field) => {
            if (!has(store, field)) {
                err(`No field in store for cached:${field}`)
                return acc
            }

            const cacheField = `${this.cacheDelimeter}${field}`
            acc[cacheField] = store[field]
            return acc
        }, {})
    }

    revertFromCache = (store) => {
        return this.fields.reduce((acc, field) => {
            if (!has(store, field)) {
                err(`No field in store to revert it:${field}`)
                return acc
            }

            const cacheField = `${this.cacheDelimeter}${field}`
            acc[field] = store[cacheField]
            return acc
        }, {})
    }
}
