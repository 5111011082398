import Poller from './Poller'
import Autologout from './Autologout'
import AuthService from './AuthService'

const services = {
    poller: null,
    autologout: null,
    authService: null,
}

export default function initWithDependency() {
    services.poller = new Poller(services)
    services.autologout = new Autologout(services)
    services.authService = new AuthService(services)

    return services
}
