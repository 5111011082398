import React from 'react'
import PropTypes from 'prop-types'

import Table from 'ipmp-react-ui/Table'

const BlindModalCardTable = ({columns, rows, title, tableProps, className}) => (
    <section className="blindModal-card-tableWrapper">
        <h3 className="blindModal-card-tableWrapper-title">
            {title}
            <span className="blindModal-card-tableWrapper-title-count">
                ({rows.length})
            </span>
        </h3>
        <Table columns={columns} rows={rows} {...tableProps} className={className} />
    </section>
)

BlindModalCardTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.node,
    tableProps: PropTypes.object,
}

export default BlindModalCardTable
