import React from 'react'
import {useSelector} from 'react-redux'

import {BarSpace} from 'ipmp-react-ui/Bar'

import CommunicationPager from './Bar/CommunicationPager'
import CommunicationSearch from './Bar/CommunicationSearch'

import {groupsPagerSearchSelect} from 'modules/centralStations/groupsList/selectors'
import {setStart} from 'modules/centralStations/groupsList/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch as groupListFetch,
    setQuery as groupListSetQuery,
    clearFilters as groupListClearFilters,
} from 'modules/centralStations/groupsList/actions'
import usePerPage from 'hooks/usePerPage'
import {CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS} from 'constants/centralStation'

const CommunicationBar = () => {
    const {total, start, query} = useSelector(groupsPagerSearchSelect)
    const {setStart: onPageChange} = useActions({setStart})
    const {fetch, setQuery, clearFilters} = useActions({
        fetch: groupListFetch,
        setQuery: groupListSetQuery,
        clearFilters: groupListClearFilters,
    })
    const {onPerPageChange, perPage} = usePerPage('centralStationCommunication')

    return (
        <>
            <BarSpace />
            <CommunicationPager
                total={total}
                start={start}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                className="centralStation-communication-pager"
                customPerPageRows={CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS}
            />
            <CommunicationSearch
                apply={fetch}
                onQuery={setQuery}
                filters={[]}
                selected={[]}
                query={query}
                onClear={clearFilters}
                className="centralStation-communication-search"
            />
        </>
    )
}

export default CommunicationBar
