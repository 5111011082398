import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class UserNotifications extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            isEmailWithVideoEnabled: PropTypes.bool,
            isEmailWithoutVideoEnabled: PropTypes.bool,
            messageBroker: PropTypes.number,
        }),
        messageBroker: PropTypes.object,
    }

    render() {
        const {data, messageBroker} = this.props

        return (
            <>
                {this.renderListItem(
                    __('Enable Emails with Attached Video'),
                    data.isEmailWithVideoEnabled,
                    true
                )}
                {this.renderListItem(
                    __('Enable Emails without Attached Video'),
                    data.isEmailWithoutVideoEnabled,
                    true
                )}
                {this.renderListItem(
                    __('Message Broker for SMS by server'),
                    messageBroker && messageBroker.name
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {userNotifications, messageBrokers},
            },
        }) => {
            return {
                messageBroker: messageBrokers.data.find(
                    (broker) => broker.id == userNotifications.data.messageBroker
                ),
                ...userNotifications,
            }
        }
    )
)(UserNotifications)
