import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import {ErrorWithIcon} from 'ipmp-react-ui/Error'
import Spinner from 'ipmp-react-ui/Spinner'

import useInteractiveUserPanelsTable from 'hooks/pages/useInteractiveUserPanelsTable'
import __ from 'utils/i18n'

import CardActions from './CardActions'
import AddPanelButton from './AddPanelButton'
import Table from './Table'

export default function Panels({user, addPanel, setFooterContent, closeBlind}) {
    const {id} = user
    const {rows, isLoading, fetch, resetWithOutFetch} = useInteractiveUserPanelsTable(id)

    useEffect(() => {
        if (id) {
            fetch(id)
            setFooterContent(() => <CardActions user={user} onClose={closeBlind} />)
        }
        return resetWithOutFetch
    }, [id])

    if (isLoading) {
        return <Spinner />
    }

    if (!rows?.length) {
        return (
            <ErrorWithIcon errorTitle={__('No panels')} Icon={IconPanel}>
                <AddPanelButton onClick={addPanel} />
            </ErrorWithIcon>
        )
    }

    return (
        <>
            <Table rows={rows} interactiveUserId={id} />
            <AddPanelButton onClick={addPanel} />
        </>
    )
}

Panels.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    addPanel: PropTypes.func.isRequired,
    setFooterContent: PropTypes.func.isRequired,
    closeBlind: PropTypes.func.isRequired,
}
