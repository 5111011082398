import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import {ErrorWithIcon} from 'ipmp-react-ui/Error'
import Spinner from 'ipmp-react-ui/Spinner'

import useInstallerAssignedPanels from 'hooks/installer/useInstallerAssignedPanels'
import __ from 'utils/i18n'

import AddPanelButton from './AddPanelButton'
import Table from './Table'

export default function Panels({installer: {id}, addPanel}) {
    const {rows, isLoading, fetch, resetWithOutFetch} = useInstallerAssignedPanels(id)

    useEffect(() => {
        fetch(id)
        return resetWithOutFetch
    }, [id])

    if (isLoading) {
        return <Spinner dataTestId="loading-spinner" />
    }

    if (!rows?.length) {
        return (
            <ErrorWithIcon errorTitle={__('No panels')} Icon={IconPanel}>
                <AddPanelButton onClick={addPanel} />
            </ErrorWithIcon>
        )
    }

    return (
        <>
            <Table rows={rows} />
            <AddPanelButton onClick={addPanel} />
        </>
    )
}

Panels.propTypes = {
    installer: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    addPanel: PropTypes.func.isRequired,
}
