import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import {isAllowManipulateUser} from 'constants/accountRoles'

import {Code, Label, Partitions} from './Rows'
import NotAllowRoleDisclaimer from './NotAllowRoleDisclaimer'

export default function PanelUserTable({user, setFooterContent}) {
    useEffect(() => {
        setFooterContent(null)
    }, [user])

    const {role} = user

    return (
        <div className="panelUserInfoTable table">
            <Code user={user} />
            <Label user={user} />
            <Partitions user={user} />

            {!isAllowManipulateUser(role) && <NotAllowRoleDisclaimer role={role} />}
        </div>
    )
}

PanelUserTable.propTypes = {
    user: PropTypes.shape({
        role: PropTypes.string.isRequired,
    }).isRequired,
    setFooterContent: PropTypes.func.isRequired,
}
