import React from 'react'
import PropTypes from 'prop-types'

import Card, {CardContent, CardHeader, CardActions, CardClose} from 'ipmp-react-ui/Card'

import Paginator from './Paginator'

const BlindModalWithPaginationCard = ({
    onClose,
    isPrevDisabled,
    isNextDisabled,
    onPrevClick,
    onNextClick,
    prev,
    next,
    cardActions,
    children,
}) => {
    const selectPrevItem = isPrevDisabled ? null : () => onPrevClick(prev)
    const selectNextItem = isNextDisabled ? null : () => onNextClick(next)

    return (
        <Card>
            <CardHeader>
                <Paginator
                    onPrevClick={selectPrevItem}
                    onNextClick={selectNextItem}
                    isPrevDisabled={isPrevDisabled}
                    isNextDisabled={isNextDisabled}
                />
                <CardClose onClick={onClose} />
            </CardHeader>
            <CardContent>{children}</CardContent>
            {cardActions && <CardActions>{cardActions}</CardActions>}
        </Card>
    )
}

BlindModalWithPaginationCard.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    isPrevDisabled: PropTypes.bool,
    isNextDisabled: PropTypes.bool,
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    prev: PropTypes.any,
    next: PropTypes.any,
    cardActions: PropTypes.node,
}

export default BlindModalWithPaginationCard
