import {warn} from 'utils/log'

import * as DeviceTypes from 'constants/deviceType'
import {__} from 'utils/i18n'

// Based on device_type
export const DEVICE_CATEGORY_ZONES = 'ZONES'
export const DEVICE_CATEGORY_MODULES = 'MODULES'
export const DEVICE_CATEGORY_ACCESSORIES = 'ACCESSORIES'
export const DEVICE_CATEGORY_SMART = 'SMART'
export const DEVICE_CATEGORY_EXTENDERS = 'EXTENDERS'
export const DEVICE_CATEGORY_SIRENS = 'SIRENS'
export const DEVICE_CATEGORY_OUTPUTS = 'OUTPUTS'
export const DEVICE_CATEGORY_UNKNOWN = 'UNKNOWN'
export const DEVICE_CATEGORY_GSM = 'GSM'
export const DEVICE_CATEGORY_DAUGHTER_CARDS = 'DAUGHTER_CARDS'
export const DEVICE_CATEGORY_BT = 'BT'
export const DEVICE_CATEGORY_ZWAVE_DEVICE = 'ZWAVE_DEVICE'

const deviceCategoryMap = new Map([
    [DEVICE_CATEGORY_ZONES, () => __('Zones')],
    [DEVICE_CATEGORY_MODULES, () => __('Modules')],
    [DEVICE_CATEGORY_ACCESSORIES, () => __('Accessories')],
    [DEVICE_CATEGORY_SMART, () => __('Smart devices')],
    [DEVICE_CATEGORY_EXTENDERS, () => __('Extenders')],
    [DEVICE_CATEGORY_SIRENS, () => __('Sirens')],
    [DEVICE_CATEGORY_OUTPUTS, () => __('Outputs')],
    [DEVICE_CATEGORY_UNKNOWN, () => __('Other')],
    [DEVICE_CATEGORY_GSM, () => __('GSM')],
    [DEVICE_CATEGORY_DAUGHTER_CARDS, () => __('Daughter cards')],
    [DEVICE_CATEGORY_BT, () => __('Bluetooth')],
    [DEVICE_CATEGORY_ZWAVE_DEVICE, () => __('Z-wave devices')],
])

export default function deviceCategory(category) {
    if (deviceCategoryMap.has(category)) {
        return deviceCategoryMap.get(category)()
    }

    warn(`Unknown category ${category}`)

    return deviceCategory(DEVICE_CATEGORY_UNKNOWN)
}

const categoriesMap = new Map([
    [DeviceTypes.DEVICE_TYPE_UNKNOWN, DEVICE_CATEGORY_UNKNOWN],

    [DeviceTypes.DEVICE_TYPE_ZONE, DEVICE_CATEGORY_ZONES],
    [DeviceTypes.DEVICE_TYPE_CAMERA, DEVICE_CATEGORY_ZONES],

    [DeviceTypes.DEVICE_TYPE_POWER_LINK, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_COMMUNICATOR, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_PGH, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_AUDIO_VERIFICATION, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_IO_EXPANDER_HSM3408, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_BUS_REPEATER_HSM3204CX, DEVICE_CATEGORY_MODULES],
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY_3A, DEVICE_CATEGORY_MODULES],

    [DeviceTypes.DEVICE_TYPE_KEYFOB, DEVICE_CATEGORY_ACCESSORIES],
    [DeviceTypes.DEVICE_TYPE_GUARD_KEY, DEVICE_CATEGORY_ACCESSORIES],
    [DeviceTypes.DEVICE_TYPE_WIRELESS_COMMANDER, DEVICE_CATEGORY_ACCESSORIES],
    [DeviceTypes.DEVICE_TYPE_WIRED_KEYPAD, DEVICE_CATEGORY_ACCESSORIES],
    [DeviceTypes.DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD, DEVICE_CATEGORY_ACCESSORIES],
    [DeviceTypes.DEVICE_TYPE_PENDANT, DEVICE_CATEGORY_ACCESSORIES],
    [DeviceTypes.DEVICE_TYPE_PROXY_TAG, DEVICE_CATEGORY_ACCESSORIES],

    [DeviceTypes.DEVICE_TYPE_X10, DEVICE_CATEGORY_SMART],

    [DeviceTypes.DEVICE_TYPE_REPEATER, DEVICE_CATEGORY_EXTENDERS],
    [DeviceTypes.DEVICE_TYPE_EXPANDER_33, DEVICE_CATEGORY_EXTENDERS],
    [DeviceTypes.DEVICE_TYPE_OUTPUT_EXPANDER, DEVICE_CATEGORY_EXTENDERS],
    [DeviceTypes.DEVICE_TYPE_ZONE_EXPANDER, DEVICE_CATEGORY_EXTENDERS],

    [DeviceTypes.DEVICE_TYPE_WL_SIREN, DEVICE_CATEGORY_SIRENS],

    [DeviceTypes.DEVICE_TYPE_PGM, DEVICE_CATEGORY_OUTPUTS],

    [DeviceTypes.DEVICE_TYPE_GSM, DEVICE_CATEGORY_GSM],
    [DeviceTypes.DEVICE_TYPE_LTE_MODULE, DEVICE_CATEGORY_GSM],
    [DeviceTypes.DEVICE_TYPE_DAUGHTER_CARD, DEVICE_CATEGORY_DAUGHTER_CARDS],
    [DeviceTypes.DEVICE_TYPE_BT, DEVICE_CATEGORY_BT],
    [DeviceTypes.ZWAVE_DEVICE, DEVICE_CATEGORY_ZWAVE_DEVICE],
])

export function categoryByDeviceType(deviceType) {
    if (categoriesMap.has(deviceType)) {
        return categoriesMap.get(deviceType)
    }

    warn(`Unknown category for deviceType ${deviceType}`)

    return DEVICE_CATEGORY_UNKNOWN
}

const deviceCategoryNameLengthMap = new Map([[DEVICE_CATEGORY_ZWAVE_DEVICE, 24]])

export const DEFAULT_DEVICE_CATEGORY_NAME_LENGTH = 28

export function getDeviceNameLength(category) {
    if (deviceCategoryNameLengthMap.has(category)) {
        return deviceCategoryNameLengthMap.get(category)
    }

    return DEFAULT_DEVICE_CATEGORY_NAME_LENGTH
}
