import {useSelector} from 'react-redux'

import {selectCentralStationsList} from 'modules/groups/centralStations/store/selectors'
import {init, remove, reset, setStart} from 'modules/groups/centralStations/list/actions'
import {showCentralStationCreateModal} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'
import usePerPage from 'hooks/usePerPage'

export default function useCentralStations() {
    const {perPage, onPerPageChange} = usePerPage('groupCentralStations')
    const {isLoading, start, total, rows} = useSelector(selectCentralStationsList)

    return {
        perPage,
        onPerPageChange,
        isLoading,
        start,
        total,
        rows,
        ...useActions({
            init,
            reset,
            remove,
            onPageChange: setStart,
            showCentralStationCreateModal,
        }),
    }
}
