import {createSelector} from 'reselect'
import {selectFotaAotaVersions} from 'modules/devices/list/selectors'

export const selectPanelCustomerInfo = createSelector(
    (state, panelId) => state?.panels?.store?.byIds[panelId],
    (panel) => panel
)

export const selectPanelInfo = createSelector(
    (state, panelId) => selectFotaAotaVersions(state, panelId),
    (state, panelId) => state?.panels?.store?.byIds[panelId],
    (fotaAotaVersions, panel) => ({fotaAotaVersions, panel})
)
