import React, {useEffect} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import list from 'permissions/interactiveUsers/list'
import selection from 'permissions/interactiveUsers/selection'

import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import {withRejection} from 'containers/withPermission'
import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'

import InteractiveUserStatusChanger from './TableCell/InteractiveUserStatusChanger'

import Table from 'ipmp-react-ui/Table'

import {__, __n} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useInteractiveUsers from 'hooks/pages/useInteractiveUsers'

const columns = [
    {
        name: () => __('Email'),
        render: ({email}) => <Definition title={email} />,
    },
    {
        name: () => __('Created'),
        render: ({createdAt}) => <DateTime datetime={createdAt} />,
    },
    {
        name: () => __('Panels'),
        render: ({panels}) => {
            if (panels === 0) {
                return <span className="empty">{__('No panels')}</span>
            }

            return <Definition title={panels} detail={__n('panel', 'panels', panels)} />
        },
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: InteractiveUserStatusChanger,
    },
]

const ConnectedTable = compose(
    withRejection(),
    withSelection(),
    withLoader(({init}) => init())
)(Table)

export default function InteractiveUsersTable({onRowClick, active}) {
    const permissions = usePermission({
        isAllowed: list,
        hasSelection: selection,
    })

    const {rows, isLoading, error, init, startPoll, stopPoll} = useInteractiveUsers()

    useEffect(() => {
        permissions.isAllowed ? startPoll() : stopPoll()

        return () => permissions.isAllowed && stopPoll()
    })

    return (
        <ConnectedTable
            {...permissions}
            rows={rows}
            isLoading={isLoading}
            error={error}
            init={init}
            active={active}
            fullHeight
            columns={columns}
            emptyMessage={__('No customers found')}
            onRowClick={onRowClick}
            className={'accounts-page-customers-table'}
        />
    )
}

InteractiveUsersTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
}
