import React from 'react'
import PropTypes from 'prop-types'

import GroupBarNav from './GroupBar/GroupBarNav'

export default function GroupTop({group}) {
    return <GroupBarNav group={group} />
}

GroupTop.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
}
