import moment from 'moment'
import get from 'lodash/get'

import {humanTime} from 'ipmp-react-ui/humanTime'

export function timeTitleCallback(tooltipItem) {
    const date = humanTime(
        moment(get(tooltipItem, ['0', 'xLabel'], new Date().toString()), [
            'MMM D, YYYY, HH:mm:ss A',
        ])
    )
    return `${date.date}, ${date.time}`
}

export function timeXAxes(from, to) {
    const period = {}

    if (from) {
        period.min = moment(from).time
    }
    if (to) {
        period.max = moment(to).time
    }

    return {
        type: 'time',
        time: {
            displayFormats: {
                hour: 'MMM D',
            },
        },
        ...period,
    }
}
