import React from 'react'
import {compose} from 'redux'

import {schedule} from 'permissions/remoteInspections/selection'

import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {ReactComponent as Icon} from 'ipmp-react-ui/icons/calendar.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ButtonVisibility = compose(withVisibility(), withSelectionHandler())(Button)

export default function ScheduleRemoteInspectionButton() {
    const {isPermitted} = usePermission(schedule)
    const {showScheduleRri} = useRemoteInspections()

    return (
        <ButtonVisibility
            isVisible={isPermitted}
            onClick={showScheduleRri}
            label={__('Schedule')}
            Icon={Icon}
        />
    )
}
