import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {unassignPanel} from 'modules/installerPanels/list/actions'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {unassign} from 'permissions/installers/actions'
import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: unassign}),
    withVisibility(),
    connect(null, (dispatcher, {row: {id}}) =>
        bindActionCreators(
            {
                onUnassign: () => unassignPanel(id),
            },
            dispatcher
        )
    ),
    withProps(({onUnassign, row: {serial}}) => ({
        onClick: onUnassign,
        small: true,
        flat: true,
        Icon: IconMinus,
        message: __('Do you really want to unassign %s panel?', serial),
        title: __('Unassign panel'),
    })),
    withConfirmation()
)(Button)
