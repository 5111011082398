import React from 'react'
import PropTypes from 'prop-types'

import {CardActions} from 'ipmp-react-ui/Card'

import SetBypassButton from 'pages/Panel/PanelState/Buttons/SetBypassButton'
import __ from 'utils/i18n'

const ByPassButtonBlock = ({panelId, deviceBypassStates, resetSelection}) => {
    return (
        <CardActions>
            <SetBypassButton
                primary
                className="partitionDevices-sidebar-button"
                deviceBypassStates={deviceBypassStates}
                panelId={panelId}
                resetSelection={resetSelection}
            >
                {__('Set Bypass')}
            </SetBypassButton>
        </CardActions>
    )
}

ByPassButtonBlock.propTypes = {
    panelId: PropTypes.number.isRequired,
    deviceBypassStates: PropTypes.instanceOf(Map),
}

export default ByPassButtonBlock
