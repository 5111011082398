import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class Rotation extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            maxEventAge: PropTypes.number,
            maxProcessAge: PropTypes.number,
            maxReportsAge: PropTypes.number,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <>
                {this.renderListItem(__('Events Age [days]'), data.maxEventAge, true)}
                {this.renderListItem(__('Process Age [days]'), data.maxProcessAge, true)}
                {this.renderListItem(__('Report Age [days]'), data.maxReportsAge)}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {rotation},
            },
        }) => ({...rotation})
    )
)(Rotation)
