import React from 'react'
import {compose} from 'redux'

import selection from 'permissions/events/selection'

import withSelection from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'

import SelectionDropDown from 'components/SelectionDropDown'
import usePermission from 'hooks/usePermission'
import useEvents from 'hooks/pages/useEvents'

const SelectionDropDownVisible = compose(
    withVisibility(),
    withSelection()
)(SelectionDropDown)

export default function EventDropDown() {
    const eventsProps = useEvents()
    const {isPermitted} = usePermission(selection)

    return <SelectionDropDownVisible isVisible={isPermitted} {...eventsProps} />
}
