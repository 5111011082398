import React from 'react'

import Empty from 'ui/Empty'
import __ from 'utils/i18n'

import AssignButton from './AssignButton'
import DisconnectButton from './DisconnectButton'

const ApplicationUser = ({userEmail, label, panelUserId, role}) => {
    return (
        <>
            <span className="panelUserCardInfo-userEmail">
                {userEmail || <Empty>{__('No application user')}</Empty>}
            </span>
            {userEmail ? (
                <DisconnectButton label={label || userEmail} panelUserId={panelUserId} />
            ) : (
                <AssignButton panelUserId={panelUserId} role={role} />
            )}
        </>
    )
}

export default ApplicationUser
