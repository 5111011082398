import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {dimmerLevelToPercent} from 'constants/had/dimmer'
import Empty from 'ui/Empty'

const HadDeviceDimmerValues = ({had, disabled}) => {
    const value = dimmerLevelToPercent(had?.state?.dimmer?.level)
    return (
        <span
            className={classnames('device-had-controls-dimmer', {
                'device-had-controls-dimmer--disabled': Boolean(disabled),
            })}
        >
            {!!value && (
                <>
                    <strong className="device-had-controls-dimmer-value">{value}</strong>%
                </>
            )}
            {!value && <Empty />}
        </span>
    )
}

HadDeviceDimmerValues.propTypes = {
    had: PropTypes.object,
    disabled: PropTypes.bool,
}

export default HadDeviceDimmerValues
