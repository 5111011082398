import React from 'react'

import {SideBar} from 'ipmp-react-ui/Layout'
import RemoteInspectionsResultHeader from './RemoteInspectionsResultHeader'
import RemoteInspectionsResultFooter from './RemoteInspectionsResultFooter'
import RemoteInspectionsResultTabs from './RemoteInspectionsResultTabs'
import Error from 'ipmp-react-ui/Error'
import {__} from 'utils/i18n'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'

const renderContent = (props) => {
    if (!props.inspection) {
        return <Error title={__('Select Remote inspection')} />
    }

    return (
        <>
            <RemoteInspectionsResultHeader {...props} />
            <RemoteInspectionsResultTabs {...props} />
            <RemoteInspectionsResultFooter {...props} />
        </>
    )
}

export default function RemoteInspectionsResultSideBar() {
    const remoteInspectionsResult = useRemoteInspectionsResult()

    return (
        <SideBar filled className="rri-sidebar">
            {renderContent(remoteInspectionsResult)}
        </SideBar>
    )
}
