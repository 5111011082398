import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {selectPgmActivationTimeSlot} from 'modules/system/settings/selectors'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class PgmActivationTimeSlot extends SettingsPageBlock {
    static propTypes = {
        enabled: PropTypes.bool,
        timeWindow: PropTypes.number,
    }

    render() {
        const {enabled, timeWindow} = this.props

        return (
            <>
                {this.renderListItem(__('Activation'), !!enabled)}
                {enabled &&
                    this.renderListItem(__('Time Window after Alarm [SEC]'), timeWindow)}
            </>
        )
    }
}

export default compose(connect(selectPgmActivationTimeSlot))(PgmActivationTimeSlot)
