import React, {Component} from 'react'
import {compose} from 'redux'
import has from 'lodash-es/has'

import withLoader from 'containers/withLoader'

import {edit} from 'permissions/basicConfiguration/actions'
import page from 'permissions/basicConfiguration/page'

import ConfigurationLayout from 'components/Configuration/ConfigurationLayout'
import BasicConfigurationQuickSearch from './Filters/BasicConfigurationQuickSearch'
import {HEIGHT_OFFSET_BEFORE_BASIC_CONFIGURATION_LIST} from 'components/Configuration/measure'
import usePermission from 'hooks/usePermission'
import useBasicConfiguration from 'hooks/pages/useBasicConfiguration'
import useConfigurationFilter from 'hooks/pages/useConfigurationFilter'

class BasicConfiguration extends Component {
    handleIsChange = (item) => {
        const {exporting, changes, basicValues} = this.props

        if (changes && has(changes, item.key)) {
            return true
        }

        const toExport = exporting && has(exporting, item.key)
        const wasExport = basicValues && has(basicValues, item.key)

        return toExport !== wasExport
    }

    render() {
        const filters = <BasicConfigurationQuickSearch />

        return (
            <ConfigurationLayout
                {...this.props}
                isShowExportCheckbox
                filters={filters}
                isChanged={this.handleIsChange}
                heightOffsetBeforeConfiguration={
                    HEIGHT_OFFSET_BEFORE_BASIC_CONFIGURATION_LIST
                }
            />
        )
    }
}

const ConnectedContent = compose(withLoader(({fetch}) => fetch()))(BasicConfiguration)

export default function BasicConfigurationContent() {
    const permissions = usePermission({
        isAllowed: page,
        isEditable: edit,
    })

    const basicConfiguration = useBasicConfiguration()

    const props = {...permissions, ...basicConfiguration}
    const {sections} = useConfigurationFilter(props)

    return <ConnectedContent {...props} sections={sections} />
}
