import {bindActionCreators} from 'redux'
import {select, selectNext, selectPrevious} from 'modules/remoteInspections/list/actions'
import {
    markAsViewed,
    resendEmail,
    sendEmail,
} from 'modules/remoteInspections/results/actions'
import {initiateNow} from 'modules/remoteInspections/store/actions'
import useRouterPanelId from '../useRouterPanelId'
import {useDispatch, useSelector} from 'react-redux'

export default function useRemoteInspectionsResult() {
    const dispatch = useDispatch()
    const {store, results, list} = useSelector(({remoteInspections}) => remoteInspections)

    const {selectedId, hasNext, hasPrev} = list
    const inspection = selectedId ? store[selectedId] : null
    const result = inspection && inspection.latestId ? results[inspection.latestId] : null
    const events = bindActionCreators(
        {
            select,
            selectNext,
            selectPrevious,
            markAsViewed,
            sendEmail,
            resendEmail,
            initiateNow,
        },
        dispatch
    )

    return {
        ...events,
        inspection,
        result,
        hasNext,
        hasPrev,
        selectedId,
        review: () => result && events.markAsViewed([result.id]),
        sendEmail: () =>
            result && result.isEmailSent
                ? events.resendEmail([result.id])
                : events.sendEmail([result.id]),
        initiate: () => inspection && events.initiateNow([inspection.id]),
    }
}

export function usePanelRemoteInspectionsResult(remoteInspectionResult) {
    const panelId = useRouterPanelId()
    const dispatch = useDispatch()

    const {store} = useSelector(({remoteInspections}) => remoteInspections)

    const inspection = panelId ? store[panelId] : null

    const events = bindActionCreators(
        {
            markAsViewed,
            sendEmail,
            resendEmail,
            initiateNow,
        },
        dispatch
    )

    return {
        inspection,
        remoteInspectionResult,
        review: () =>
            remoteInspectionResult && events.markAsViewed([remoteInspectionResult.id]),
        sendEmail: () =>
            remoteInspectionResult && events.sendEmail([remoteInspectionResult.id]),
        resendEmail: () =>
            remoteInspectionResult && events.resendEmail([remoteInspectionResult.id]),
        initiate: () => inspection && events.initiateNow([inspection.id]),
    }
}
