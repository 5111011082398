import {createSelector} from 'reselect'

export const selectWidgetBatches = createSelector(
    (state) => state.batches.store.byIds,
    (state) => state.batches.widget.ids,
    (state) => state.batches.widget.tempIds,
    (byIds, widgetIds, tempIds) => tempIds.concat(widgetIds).map((id) => byIds[id])
)

export const selectBatchIsTemporary = createSelector(
    (state) => state.batches.widget.tempIds,
    (_, batchId) => batchId,
    (tempIds, batchId) => tempIds.includes(batchId)
)

export const selectBatchInProgress = createSelector(
    selectBatchIsTemporary,
    (state) => state.batches.store.byIds,
    (_, batchId) => batchId,
    (isTemporary, byIds, batchId) => {
        const {stats} = byIds[batchId] || {}

        if (!stats) {
            return false
        }

        const {start, handled, pending} = stats

        return isTemporary || start > 0 || handled > 0 || pending > 0
    }
)
