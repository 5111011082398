import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'

import {FormRow} from 'ipmp-react-ui/Form'
import Input from 'ipmp-react-ui/Input'

export default function UnixTimestampReadonly({value}) {
    let date, time

    if (value) {
        date = moment.unix(value).format('L')
        time = moment.unix(value).format('LT')
    }

    return (
        <FormRow>
            <Input value={date} readOnly />
            {':'}
            <Input value={time} readOnly />
        </FormRow>
    )
}

UnixTimestampReadonly.propTypes = {
    value: PropTypes.number.isRequired,
}
