import React from 'react'

import disable from 'permissions/panel/devices/plink/log/disable'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import usePermission from 'hooks/usePermission'
import {usePlinkDebugCommands} from 'hooks/pages/useDevices'

const ButtonVisible = withVisibility()(Button)

export default function DisableLogButton({panelId, ...props}) {
    const {isPermitted} = usePermission(disable)
    const {disableLogging} = usePlinkDebugCommands(panelId)

    return <ButtonVisible isVisible={isPermitted} onClick={disableLogging} {...props} />
}
