import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {showExportConfigurationModal} from 'modules/modals/actions'

import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconDownloadOutline} from 'ipmp-react-ui/icons/download.svg'

import {__} from 'utils/i18n'

class ExportCell extends Component {
    handleClick = (e) => {
        e.stopPropagation()
        this.props.showModal()
    }

    render() {
        return (
            <Tooltip tooltip={__('Export')}>
                <IconDownloadOutline
                    onClick={this.handleClick}
                    className="configurations-icon"
                />
            </Tooltip>
        )
    }
}

export default compose(
    connect(null, (dispatch, {row, panelId}) =>
        bindActionCreators(
            {
                showModal: () => showExportConfigurationModal(row.id, panelId),
            },
            dispatch
        )
    )
)(ExportCell)
