import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'
import Table from 'ipmp-react-ui/Table'

import withLoader from 'containers/withLoader'
import {withRejection} from 'containers/withPermission'
import InstallerStatus from 'components/Installer/InstallerStatus'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {usePanelInstallers} from 'hooks/installer'
import {list} from 'permissions/panel/panelInstallers/actions'
import {__} from 'utils/i18n'

import UnassignButton from './GridBar/UnassignInstallerButton'

const columns = [
    {
        name: () => __('Installer'),
        render: ({name, email}) => <Definition title={name} detail={email} />,
    },
    {
        name: () => __('Telephone'),
        render: ({telephone}) => telephone,
    },
    {
        name: () => __('Created'),
        render: ({createdAt}) => <DateTime datetime={createdAt} />,
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: InstallerStatus,
    },
    {
        tooltip: false,
        fixed: true,
        width: 56,
        render: (row) => <UnassignButton row={row} />,
    },
]

const TableContainer = compose(
    withRejection(),
    withLoader(
        ({init, startPoll}) => {
            init()
            startPoll()
        },
        ({purgeList, stopPoll}) => {
            purgeList()
            stopPoll()
        }
    )
)(Table)

export default function PanelInstallersTable({onRowClick}) {
    const {isPermitted} = usePermission(list)
    const panelId = useRouterPanelId()
    const {rows, isLoading, init, startPoll, stopPoll, purgeList} =
        usePanelInstallers(panelId)

    return (
        <TableContainer
            rows={rows}
            isLoading={isLoading}
            panelId={panelId}
            onRowClick={onRowClick}
            isAllowed={isPermitted}
            fullHeight
            className="panelInstallers-table"
            columns={columns}
            emptyMessage={() => __('No installers found')}
            init={init}
            startPoll={startPoll}
            stopPoll={stopPoll}
            purgeList={purgeList}
        />
    )
}

PanelInstallersTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
}
