import React from 'react'
import PropTypes from 'prop-types'

import DoubleBlindWithPagination from 'ui/DoubleBlindModalWithPagination'

import FirstBlindContent from './FirstBlindContent'
import SecondBlindContent from './SecondBlindContent'

export default function Blind({rows, current, onChange, onClose}) {
    return (
        <DoubleBlindWithPagination
            rows={rows}
            current={current}
            onChange={onChange}
            closeBlind={onClose}
            FirstBlindContent={FirstBlindContent}
            SecondBlindContent={SecondBlindContent}
        />
    )
}

Blind.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    current: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
