import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'

import Button from 'ipmp-react-ui/Button'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'
import {buildDownloadReportHref} from 'api/reports'
import {getTypeLabel} from 'constants/reportTypes'

const DownloadCell = ({exportTypes, id, disabled, isGroupButtons}) => {
    const handleClick = (url) => {
        window.open(url, '_top', 'noreferrer')
    }

    const getGroupedButtons = () => {
        return (
            <DropDownButton
                Icon={IconDownload}
                className="report-download-drop-down-button"
                small
                disabled={disabled}
            >
                <Menu className="report-download-items">
                    {exportTypes.map((exportType, key) => (
                        <MenuItem
                            className="report-download-nav-link"
                            onClick={() =>
                                handleClick(buildDownloadReportHref(id, exportType))
                            }
                            key={key}
                        >
                            <IconDownload />
                            <span>{getTypeLabel(exportType)}</span>
                        </MenuItem>
                    ))}
                </Menu>
            </DropDownButton>
        )
    }

    if (isGroupButtons) {
        return getGroupedButtons()
    }

    return (
        <div className="report-download">
            {exportTypes.map((exportType) => (
                <Button
                    small
                    flat
                    Icon={IconDownload}
                    disabled={disabled}
                    key={exportType}
                    href={buildDownloadReportHref(id, exportType)}
                    children={getTypeLabel(exportType)}
                />
            ))}
        </div>
    )
}

DownloadCell.propTypes = {
    id: PropTypes.number.isRequired,
    exportTypes: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    isGroupButtons: PropTypes.bool,
}

export default DownloadCell
