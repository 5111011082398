import React from 'react'
import Pager from 'ipmp-react-ui/Pager'

import list from 'permissions/basicConfiguration/selection'

import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import useBasicConfigurations from 'hooks/pages/useBasicConfigurations'

const ConnectedPager = withVisibility()(Pager)

export default function BasicConfigurationsPager() {
    const {isPermitted} = usePermission(list)
    const basicConfigurationProps = useBasicConfigurations()

    return <ConnectedPager {...basicConfigurationProps} isVisible={isPermitted} />
}
