import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ProcessBatch from 'components/Processes/ProcessBatch'
import {__} from 'utils/i18n'

import {ReactComponent as IconDoneAll} from 'ipmp-react-ui/icons/cross.svg'
import {ReactComponent as IconGoTo} from 'ipmp-react-ui/icons/go-to-icon.svg'
import Button from 'ipmp-react-ui/Button'
import path from 'utils/path'
import {humanDate} from 'ipmp-react-ui/humanTime'
import {TableGrouped} from 'ipmp-react-ui/Table'
import {withRouter} from 'react-router-dom'

export const scrollingElementClass = 'processes-content'

const ProcessBatches = ({
    batches,
    showBatchInfoNavBlind,
    processRefHandler,
    isNavBlindOpened,
    history,
    hideSidebar,
    untrack,
    untrackFinished,
    navBlindHide,
    selectedBatchId,
}) => {
    const [openedBatch, setOpenedBatch] = useState()

    const onUntrack = (id) => {
        navBlindHide()
        untrack(id)
    }

    const onUntrackFinished = () => {
        navBlindHide()
        untrackFinished()
    }

    if (batches.length === 0) {
        return null
    }
    const goToJobs = () => {
        history.push(path('batches', {}))
        hideSidebar && hideSidebar()
    }

    const separatorCallback = (row) => {
        const group = humanDate(row.props.batch.started)
        return group || __('No user processes')
    }

    const columns = [
        {
            render: (props) => props,
        },
    ]

    const rows = batches.map((batch) => (
        <ProcessBatch
            key={batch.id}
            batch={batch}
            untrack={onUntrack}
            showInfo={showBatchInfoNavBlind}
            openedBatchId={openedBatch}
            selectedBatchId={selectedBatchId}
            isNavBlindOpened={isNavBlindOpened}
            setOpenedBatch={setOpenedBatch}
        />
    ))

    return (
        <div className="processes" ref={processRefHandler}>
            <div className={scrollingElementClass}>
                <TableGrouped
                    separatorCallback={separatorCallback}
                    rows={rows}
                    columns={columns}
                />
            </div>
            <div className="processes-action">
                {batches.length > 0 && (
                    <Button
                        onClick={onUntrackFinished}
                        Icon={IconDoneAll}
                        label={__('Clear Completed')}
                        className="processes-clear-all"
                    />
                )}
                <Button
                    primary
                    label={__('Go to Jobs')}
                    Icon={IconGoTo}
                    onClick={goToJobs}
                    className="close-sidebar"
                />
            </div>
        </div>
    )
}

ProcessBatches.propTypes = {
    batches: PropTypes.array.isRequired,
    untrack: PropTypes.func.isRequired,
    untrackFinished: PropTypes.func.isRequired,
    processRefHandler: PropTypes.func.isRequired,
    showBatchInfoNavBlind: PropTypes.func.isRequired,
    selectedBatchId: PropTypes.shape({
        value: PropTypes.string,
        clear: PropTypes.func,
    }),
}

export default withRouter(ProcessBatches)
