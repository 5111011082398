import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {get} from 'lodash-es'

import Tabs from 'ipmp-react-ui/Tabs'

import {useActions} from 'modules/higherOrder/useActions'
import {fetchVideo, recordVideo} from 'modules/events/one/actions'
import delivery from 'permissions/events/delivery'
import {videoAttached} from 'permissions/events/actions'
import usePermission from 'hooks/usePermission'
import SidebarTabsLayout from 'ui/SidebarTabsLayout'
import {__} from 'utils/i18n'

import VideoTabs from './VideoTabs'
import {StatusTab} from './StatusTab'

export default function EventDeliveranceContent({eventId, panelId, ...props}) {
    const {isPermitted: isDeliveryAllowed} = usePermission(delivery)
    const {isPermitted: isVideoAttached} = usePermission(videoAttached)

    const {event} = useSelector(({events}) => ({
        event: get(events, ['store', 'byIds', eventId], {}),
    }))

    const {fetch, startRecordVideo} = useActions({
        fetch: () => fetchVideo(eventId),
        startRecordVideo: (zoneId) => recordVideo(eventId, panelId, zoneId),
    })

    return (
        <Fragment>
            {event.hasVideo && isVideoAttached ? (
                <VideoTabs
                    video={event.video}
                    isLoading={!event.video}
                    event={event}
                    fetch={fetch}
                    startRecordVideo={startRecordVideo}
                    statusTab={
                        isDeliveryAllowed && (
                            <StatusTab
                                {...props}
                                event={event}
                                name={__('Delivery status')}
                            />
                        )
                    }
                    panelId={event.panelId}
                    {...props}
                />
            ) : (
                <Tabs Layout={SidebarTabsLayout}>
                    {isDeliveryAllowed && (
                        <StatusTab
                            {...props}
                            event={event}
                            name={__('Delivery status')}
                        />
                    )}
                </Tabs>
            )}
        </Fragment>
    )
}

EventDeliveranceContent.propTypes = {
    event: PropTypes.object,
    device: PropTypes.object,
    panel: PropTypes.object,
    hasDevice: PropTypes.bool,
    hasPanel: PropTypes.bool,
    fetch: PropTypes.func,
    startRecordVideo: PropTypes.func,
    fetchDevices: PropTypes.func,
    fetchPanel: PropTypes.func,
}
