import React from 'react'

import Pager from 'ipmp-react-ui/Pager'

import list from 'permissions/centralStations/list'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/group/useCentralStations'

const PagerVisibility = withVisibility()(Pager)

export default function StationsPager() {
    const {isPermitted} = usePermission(list)
    const {perPage, start, total, onPageChange, onPerPageChange} = useCentralStations()

    return (
        <PagerVisibility
            isVisible={isPermitted}
            perPage={perPage}
            start={start}
            total={total}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
        />
    )
}
