import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {DisclaimerError} from 'ipmp-react-ui/Disclaimer'

import {isSwitchableCategories} from 'constants/had'
import {selectCategorizedList, selectHadIsSwitchable} from 'modules/panels/had/selector'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import {pgmControl} from 'permissions/panel/output/actions'
import {
    IsCanActivatedDisclaimerMessage,
    IsDisabledDisclaimerMessage,
    IsOnlyAlarmActivatedDisclaimerMessage,
} from '../HadDisclaimerMessages'

const HadDisclaimerBase = () => {
    const categorizedList = useSelector(selectCategorizedList)
    const {isSwitchable, isTimeSlotEnabled} = useSelector(selectHadIsSwitchable)
    const isSwitchableCategoriesPresent = isSwitchableCategories(
        Array.from(categorizedList.keys())
    )

    const [isShowSwitchablePane, setShowPane] = useState(!isSwitchable)

    const isCanBeActivatedShow =
        isSwitchableCategoriesPresent && isTimeSlotEnabled && !isShowSwitchablePane
    const isCanNotBeActivatedShow =
        isSwitchableCategoriesPresent && isTimeSlotEnabled && isShowSwitchablePane
    const isNotEnabledShow = isSwitchableCategoriesPresent && !isTimeSlotEnabled

    useEffect(() => {
        setShowPane(!isSwitchable)
    }, [isSwitchable, categorizedList])

    return (
        <>
            {isCanBeActivatedShow && (
                <DisclaimerError>
                    <IsCanActivatedDisclaimerMessage />
                </DisclaimerError>
            )}
            {isCanNotBeActivatedShow && (
                <DisclaimerError>
                    <IsOnlyAlarmActivatedDisclaimerMessage />
                </DisclaimerError>
            )}
            {isNotEnabledShow && (
                <DisclaimerError>
                    <IsDisabledDisclaimerMessage />
                </DisclaimerError>
            )}
        </>
    )
}

const HadDisclaimerWithVisibility = withVisibility()(HadDisclaimerBase)

export default function HadDisclaimer(props) {
    const {isPermitted} = usePermission(pgmControl)

    return <HadDisclaimerWithVisibility isVisible={isPermitted} {...props} />
}
