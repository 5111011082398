import React from 'react'
import {compose} from 'redux'
import {__} from 'utils/i18n'
import Button from 'ipmp-react-ui/Button'
import get from 'lodash-es/get'

import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'

import {remove} from 'permissions/panel/devices/actions'
import usePermission from 'hooks/usePermission'
import {useDeviceActions} from 'hooks/pages/useDevices'

const ButtonVisibleConfirmation = compose(withVisibility(), withConfirmation())(Button)

export default function RemoveButton({panelId, device}) {
    const {isPermitted} = usePermission(remove)
    const {removeDevice} = useDeviceActions(panelId, device)

    return (
        <ButtonVisibleConfirmation
            onClick={removeDevice}
            title={__('Remove device')}
            message={__('Do you really want to remove device?')}
            positive={__('Remove')}
            label={__('Remove')}
            className="devices-sidebar-button btn--flat"
            isVisible={get(device, ['isRemovable'], false) && isPermitted}
        />
    )
}
