import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {ScrollView} from 'ipmp-react-ui/Layout'

import BroadbandConnected from './BroadbandConnected'
import CellularConnected from './CellularConnected'
import CommonConnected from './CommonConnected'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'
import __ from 'utils/i18n'
import SettingsCard from 'components/Settings/SettingsCard'
import {
    showSystemSettingsCellularConnectedModal,
    showSystemSettingsBroadbandConnectedModal,
    showSystemSettingsCommonConnectedModal,
} from 'modules/modals/actions'
import PropTypes from 'prop-types'

export class General extends Component {
    static propTypes = {
        cellularConnectedModal: PropTypes.func.isRequired,
        broadbandConnectedModal: PropTypes.func.isRequired,
        commonConnectedModal: PropTypes.func.isRequired,
    }
    render() {
        const {cellularConnectedModal, broadbandConnectedModal, commonConnectedModal} =
            this.props
        return (
            <ScrollView className={'settings-grid settings-grid-general'}>
                <SettingsCard
                    title={__('Cellular Connected')}
                    onEditAction={cellularConnectedModal}
                >
                    <CellularConnected />
                </SettingsCard>
                <SettingsCard
                    title={__('Broadband Connected')}
                    onEditAction={broadbandConnectedModal}
                >
                    <BroadbandConnected />
                </SettingsCard>
                <SettingsCard
                    title={__('Common Connected')}
                    onEditAction={commonConnectedModal}
                >
                    <CommonConnected />
                </SettingsCard>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {
                    cellularConnected,
                    broadbandConnected,
                    commonConnected,
                    messageBrokers,
                },
            },
        }) => ({
            cellularConnected,
            broadbandConnected,
            commonConnected,
            messageBrokers,
            isLoading:
                cellularConnected.isLoading ||
                broadbandConnected.isLoading ||
                commonConnected.isLoading ||
                messageBrokers.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    fetchCellular: actions.fetchCellularConnected,
                    fetchBroadband: actions.fetchBroadbandConnected,
                    fetchCommon: actions.fetchCommonConnected,
                    fetchMessageBrokers: actions.fetchMessageBrokers,
                    showSystemSettingsCellularConnectedModal,
                    showSystemSettingsBroadbandConnectedModal,
                    showSystemSettingsCommonConnectedModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                cellularConnectedModal: () =>
                    dispatchProps.showSystemSettingsCellularConnectedModal({
                        ...stateProps.cellularConnected,
                    }),
                broadbandConnectedModal: () =>
                    dispatchProps.showSystemSettingsBroadbandConnectedModal({
                        ...stateProps.broadbandConnected,
                    }),
                commonConnectedModal: () =>
                    dispatchProps.showSystemSettingsCommonConnectedModal(
                        stateProps.commonConnected
                    ),
            }
        }
    ),
    withLoader(({fetchCellular, fetchBroadband, fetchCommon, fetchMessageBrokers}) => {
        fetchMessageBrokers()
        fetchCellular()
        fetchBroadband()
        fetchCommon()
    })
)(General)
