import React from 'react'
import PropTypes from 'prop-types'

import HadConfigurationTabTable from './ConfigurationTab/HadConfigurationTabTable'

const ConfigurationTab = ({had}) => {
    return <HadConfigurationTabTable had={had} />
}

ConfigurationTab.propTypes = {
    had: PropTypes.object.isRequired,
}

export default ConfigurationTab
