import {handleActions} from 'redux-actions'

import get from 'lodash-es/get'
import {
    fetchAll,
    receiveAll,
    fetchOne,
    receiveOne,
    selectOne,
    updatePersisted,
    persistOne,
    clearUncommitted,
} from './actions'

const defaultState = {
    available: [],
    uncommitted: {
        byIds: {},
    },
    selected: {
        byIds: {},
    },
    isLoadingAll: false,
    isLoadingOne: false,
    isLoadingPersist: false,
    error: null,
}

export default handleActions(
    {
        [fetchAll](state) {
            return {
                ...state,
                isLoadingAll: true,
            }
        },
        [receiveAll](state, {payload}) {
            return {
                ...state,
                isLoadingAll: false,
                available: payload,
            }
        },
        [fetchOne](state) {
            return {
                ...state,
                isLoadingOne: true,
            }
        },
        [receiveOne](state, {payload: {groupId, customization}}) {
            let byIds = state.selected.byIds

            if (customization) {
                byIds = {
                    ...byIds,
                    [groupId]: customization.id,
                }
            }

            return {
                ...state,
                isLoadingOne: false,
                selected: {
                    byIds: byIds,
                },
            }
        },
        [selectOne](state, {payload: {groupId, customizationId}}) {
            const isChanged =
                get(state.uncommitted.byIds, [groupId], null) !== customizationId
            const isOldCustomization =
                get(state.selected.byIds, [groupId], null) === customizationId

            let byIds = {...state.uncommitted.byIds}

            if (isOldCustomization) {
                delete byIds[groupId]
            } else if (isChanged) {
                byIds = {
                    ...byIds,
                    [groupId]: customizationId,
                }
            }

            return {
                ...state,
                uncommitted: {
                    byIds: byIds,
                },
            }
        },
        [persistOne](state) {
            return {
                ...state,
                isLoadingPersist: true,
            }
        },
        [updatePersisted](state, {payload: {groupId, customizationId}}) {
            let selected = state.selected
            let uncommitted = state.uncommitted
            if (get(uncommitted, ['byIds', groupId], null) !== null) {
                selected = {
                    ...selected,
                    byIds: {
                        ...selected.byIds,
                        [groupId]: customizationId,
                    },
                }
                const byIds = {...state.uncommitted.byIds}
                delete byIds[groupId]
                uncommitted = {
                    ...uncommitted,
                    byIds: byIds,
                }
            }
            return {
                ...state,
                isLoadingPersist: false,
                selected,
                uncommitted,
            }
        },
        [clearUncommitted](state, {payload: groupId}) {
            const byIds = {...state.uncommitted.byIds}
            delete byIds[groupId]

            return {
                ...state,
                uncommitted: {
                    ...state.uncommitted,
                    byIds: byIds,
                },
            }
        },
    },
    defaultState
)
