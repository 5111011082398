import React from 'react'
import PropTypes from 'prop-types'

const HadDevicePartitions = ({partitions}) => {
    return (
        <span className="partitions">
            {partitions.numbers.map((partition) => (
                <span key={partition} className="partition">
                    {partition}
                </span>
            ))}
        </span>
    )
}

HadDevicePartitions.propTypes = {
    partitions: PropTypes.shape({numbers: PropTypes.array}),
}

export default HadDevicePartitions
