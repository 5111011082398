import registerServiceWorker, {
    ServiceWorkerNoSupportError,
} from 'service-worker-loader!utils/serviceWorker'
import {info, err} from 'utils/log'

registerServiceWorker()
    .then((registration) => {
        info('SW registered: ', registration.scope)
        if (navigator?.serviceWorker?.controller === null) {
            // we get here after a ctrl+f5 OR if there was no previous service worker.
            window.location.reload()
        }
    })
    .catch((error) => {
        if (error instanceof ServiceWorkerNoSupportError) {
            err('Service worker is not supported.')
        } else {
            err('Error due [IPMP service worker] registration!', error)
        }
    })
