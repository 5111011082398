import React, {useEffect, useState} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'

import {Link} from 'react-router-dom'
import {fetchInteractiveUserPanels} from 'modules/panels/recent/actions'
import {RenderRecentHistoryItem} from './RenderRecentHistoryItem'
import {selectRecentInteractiveUserPanels} from 'modules/panels/recent/selectors'

const RecentHistoryItem = ({
    account,
    panelSerial,
    interactiveUserEmail,
    visitTime,
    panels,
    path,
    children,
    onClose,
    interactiveUserId,
}) => {
    const [isPanelListOpened, setIsPanelListOpened] = useState(false)
    const openPanelList = () => setIsPanelListOpened(true)

    const closePanelList = () => setIsPanelListOpened(false)

    const togglePanelList = () => {
        if (isPanelListOpened) {
            closePanelList()
        } else {
            openPanelList()
        }
    }
    const fetchUsersPanels = useActions(fetchInteractiveUserPanels)

    useEffect(() => {
        interactiveUserId && fetchUsersPanels(interactiveUserId)
    }, [interactiveUserId])

    const renderPanelList = () => {
        return children
    }
    const manyVisitedPanels = panels && panels.length > 1
    if (!panels || !manyVisitedPanels) {
        return (
            <Link to={path} onClick={onClose}>
                <RenderRecentHistoryItem
                    account={account}
                    panelSerial={panelSerial}
                    interactiveUserEmail={interactiveUserEmail}
                    visitTime={visitTime}
                />
            </Link>
        )
    }
    return (
        <RenderRecentHistoryItem
            isPanelListOpened={isPanelListOpened}
            account={account}
            panelSerial={panelSerial}
            interactiveUserEmail={interactiveUserEmail}
            visitTime={visitTime}
            togglePanelList={togglePanelList}
            renderPanelList={renderPanelList}
        />
    )
}

RecentHistoryItem.propTypes = {
    interactiveUserEmail: PropTypes.string,
    panelSerial: PropTypes.string,
    children: PropTypes.object,
}

export default compose(connect(selectRecentInteractiveUserPanels))(RecentHistoryItem)
