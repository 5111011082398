import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import Checkbox from 'ipmp-react-ui/Checkbox'
import SelectBroker from './SelectBroker'

class UserNotificationsSettings extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            isEmailWithVideoEnabled: PropTypes.bool,
            isEmailWithoutVideoEnabled: PropTypes.bool,
            messageBroker: PropTypes.number,
        }),
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    get rules() {
        return {
            messageBroker: {},
        }
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Customer Notifications Settings')}
                confirmOnDismiss
                {...props}
            >
                <SelectBroker
                    label={__('Message Broker for SMS by server')}
                    name="messageBroker"
                    defaultValue={data.messageBroker}
                />

                <Checkbox
                    label={__('Enable Emails with Attached Video')}
                    name="isEmailWithVideoEnabled"
                    defaultChecked={data.isEmailWithVideoEnabled}
                />

                <Checkbox
                    label={__('Enable Emails without Attached Video')}
                    name="isEmailWithoutVideoEnabled"
                    defaultChecked={data.isEmailWithoutVideoEnabled}
                />
            </ModalCardForm>
        )
    }
}

export default compose(withForm(setSystemSettings.UserNotification))(
    UserNotificationsSettings
)
