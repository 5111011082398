import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import has from 'lodash-es/has'

import * as DeviceCategory from 'constants/deviceCategory'
import deviceCategory from 'constants/deviceCategory'

import {selectChangedDeviceSections} from 'modules/panels/configuration/selectors'

import DevicesList from 'components/Devices/DevicesList'
import deviceSectionName from 'utils/configuration/deviceSectionName'
import usePanel from 'hooks/pages/usePanel'

export const groups = [
    DeviceCategory.DEVICE_CATEGORY_GSM,
    DeviceCategory.DEVICE_CATEGORY_DAUGHTER_CARDS,
    DeviceCategory.DEVICE_CATEGORY_MODULES,
    DeviceCategory.DEVICE_CATEGORY_ZONES,
    DeviceCategory.DEVICE_CATEGORY_BT,
    DeviceCategory.DEVICE_CATEGORY_ACCESSORIES,
    DeviceCategory.DEVICE_CATEGORY_SIRENS,
    DeviceCategory.DEVICE_CATEGORY_SMART,
    DeviceCategory.DEVICE_CATEGORY_EXTENDERS,
    DeviceCategory.DEVICE_CATEGORY_OUTPUTS,
    DeviceCategory.DEVICE_CATEGORY_ZWAVE_DEVICE,
    DeviceCategory.DEVICE_CATEGORY_UNKNOWN,
]

export class Diagnostic extends Component {
    static propTypes = {
        devices: PropTypes.object.isRequired,
        panel: PropTypes.shape({
            isNeo: PropTypes.bool,
        }),
        selectedDevice: PropTypes.number,
        onSelectPanel: PropTypes.func,
        configuration: PropTypes.object,
        changes: PropTypes.object,
    }

    constructor(props, context) {
        super(props, context)

        this.groups = groups.reduce(
            (acc, category) => ({
                ...acc,
                [category]: deviceCategory(category),
            }),
            {}
        )
    }

    isChanged = (device) => {
        const {changes} = this.props
        const sectionName = deviceSectionName(device)
        return sectionName && has(changes, sectionName)
    }

    render() {
        return (
            <DevicesList
                groups={this.groups}
                isChanged={this.isChanged}
                {...this.props}
            />
        )
    }
}

export default function DiagnosticMain(props) {
    const panel = usePanel(props)
    const changes = useSelector((state) =>
        selectChangedDeviceSections(state, {panelId: panel.id})
    )

    const diagnosticProps = {...props, ...panel, changes}
    return <Diagnostic {...diagnosticProps} />
}
