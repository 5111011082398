import {handleActions} from 'redux-actions'

import {fetch, receive, remove} from './actions'

const defaultState = {
    perPage: 30,
    rows: [],
    isLoading: false,
}

export default handleActions(
    {
        [fetch](state, {payload: {panelId}}) {
            return {
                [panelId]: {
                    ...(state[panelId] || defaultState),
                    isLoading: true,
                },
            }
        },

        [receive](state, {payload, error, meta: {panelId}}) {
            const old = state[panelId]

            if (error) {
                return {
                    ...state,
                    [panelId]: {
                        ...old,
                        isLoading: false,
                        error: payload,
                    },
                }
            }

            return {
                ...state,
                [panelId]: {
                    ...old,
                    isLoading: false,
                    rows: payload,
                },
            }
        },

        [remove](state, {payload: {panelId, id}}) {
            const old = state[panelId]
            if (!old?.rows?.length) {
                return state
            }

            return {
                ...state,
                [panelId]: {
                    ...old,
                    rows: old.rows.filter((log) => log.id !== id),
                },
            }
        },
    },
    {}
)
