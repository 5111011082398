import React from 'react'
import PropTypes from 'prop-types'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__, __n} from 'utils/i18n'

export default function Quazar({settings: {bba, gprs}}) {
    const supervision = bba.isEnabled || gprs.isEnabled

    return (
        <List className="card-content" twoCols>
            {supervision && (
                <>
                    <ListHintItem
                        name={__('Supervision period')}
                        text={__n('%d second', '%d seconds', bba.supervision)}
                    />
                    <hr className={'notice-item-border'} />
                </>
            )}
            {gprs.isEnabled && (
                <ListHintItem
                    name={__('Cellular Offline Timer')}
                    text={__n('%d second', '%d seconds', gprs.timeout)}
                />
            )}
            {!gprs.isEnabled && (
                <ListHintItem name={__('Cellular Supervision')} text={__('Disabled')} />
            )}

            {bba.isEnabled && (
                <ListHintItem
                    name={__('Broadband Offline Timer')}
                    text={__n('%d second', '%d seconds', bba.timeout)}
                />
            )}
            {!bba.isEnabled && (
                <ListHintItem name={__('Broadband Supervision')} text={__('Disabled')} />
            )}
        </List>
    )
}

Quazar.propTypes = {
    settings: PropTypes.objectOf(
        PropTypes.shape({
            isEnabled: PropTypes.bool,
            supervision: PropTypes.number,
            timeout: PropTypes.number,
        })
    ),
}
