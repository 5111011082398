import React, {Component} from 'react'
import {compose} from 'redux'

import {service} from 'permissions/panels/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {ifSelection} from 'containers/withSelection'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import ChangeGroupItem from './Items/ChangeGroupItem'
import RefreshStateItem from './Items/RefreshStateItem'
import DownloadConfigurationItem from './Items/DownloadConfigurationItem'
import PushBasicConfigurationItem from './Items/PushBasicConfigurationItem'
import RemovePanelsItem from './Items/RemovePanelsItem'
import TriggerDiscoveryItem from './Items/TriggerDiscoveryItem'

import {__} from 'utils/i18n'

class ServiceDropDown extends Component {
    render() {
        return (
            <DropDownButton label={__('Service')} dataTestId="panels-service-drop-down">
                <Menu>
                    <ChangeGroupItem />

                    <MenuDelimiter />

                    <RefreshStateItem />
                    <DownloadConfigurationItem />
                    <PushBasicConfigurationItem />

                    <MenuDelimiter />

                    <TriggerDiscoveryItem />

                    <MenuDelimiter />

                    <RemovePanelsItem />
                </Menu>
            </DropDownButton>
        )
    }
}

export default compose(
    withPermission({isVisible: service}),
    withVisibility(),
    ifSelection()
)(ServiceDropDown)
