import React, {useContext, useEffect} from 'react'

import {markAsViewed} from 'permissions/events/actions'
import delivery from 'permissions/events/delivery'

import withLoader from 'containers/withLoader'

import Table from 'ipmp-react-ui/Table'
import DateTime from 'ipmp-react-ui/DateTime'
import {SeverityCell, EventAppointmentCell, EventDeliveranceCell} from 'ui/TableCells'
import Tooltip from 'ipmp-react-ui/Tooltip'

import PanelCell from 'components/TableCells/PanelCell'
import {ReactComponent as CameraIcon} from 'ipmp-react-ui/icons/camera.svg'
import {ReactComponent as InfoIcon} from 'ipmp-react-ui/icons/info.svg'
import __ from 'utils/i18n'

import MarkAsViewed from './TableCell/MarkAsViewed'
import EventContext from '../EventContext'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import {connect, useSelector} from 'react-redux'
import {EDS_VIEW_LONG} from 'constants/eventDelivery'

const allColumns = [
    {
        name: () => __('Date'),
        maxWidth: 200,
        render: ({datetime}) => <DateTime datetime={datetime} timeFirst />,
    },
    {
        name: () => __('Panel'),
        maxWidth: 180,
        render: ({panelId, serial, account}) => (
            <PanelCell {...{id: panelId, serial, account}} />
        ),
    },
    {
        width: 50,
        fixed: true,
        render: ({hasVideo}) => (hasVideo ? <CameraIcon /> : null),
    },
    {
        name: () => __('Event Description'),
        maxWidth: 550,
        render: ({severity, description}) => (
            <SeverityCell {...{severity, description}} />
        ),
    },
    {
        tooltip: false,
        name: () => __('Appointment'),
        render: ({zone, appointment, partitions, description}) => (
            <EventAppointmentCell {...{zone, appointment, partitions, description}} />
        ),
    },
    {
        align: 'right',
        alias: 'deliveryStatus',
        maxWidth: 200,
        component: connect((state, {row}) => ({
            event: row,
            edsView: state.settings.edsView,
        }))(EventDeliveranceCell),
        permission: delivery,
    },
    {
        width: 50,
        fixed: true,
        render: ({binMessage}) =>
            binMessage ? (
                <Tooltip tooltip={binMessage}>
                    <InfoIcon />
                </Tooltip>
            ) : null,
    },
    {
        tooltip: false,
        permission: markAsViewed,
        fixed: true,
        width: 50,
        component: MarkAsViewed,
    },
]

const ConnectedTable = withLoader()(Table)

export default function EventsTable(props) {
    useEffect(() => {
        props.startPoll()
        return props.stopPoll
    }, [])

    const {edsView} = useSelector((state) => state.settings)
    const {handleEventSelect, selectedEventId} = useContext(EventContext)
    const columnsFinal = allColumns.map((column) =>
        column.alias === 'deliveryStatus'
            ? {
                  ...column,
                  name: () => (edsView === EDS_VIEW_LONG ? __('Delivery Status') : null),
              }
            : column
    )
    const {columns} = useTableColumnsPermission(columnsFinal)

    return (
        <ConnectedTable
            {...props}
            columns={columns}
            fullHeight
            emptyMessage={() => __('No events found')}
            onRowClick={(id) => handleEventSelect(id)}
            active={{id: selectedEventId}}
        />
    )
}
