import {
    VENDOR_FIBRO_TRANSMITTER,
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
    VENDOR_QOLSYS,
    VENDOR_SMART_COMMUNICATOR,
} from 'constants/panelVendorType'
import {__} from 'utils/i18n'
import * as selectors from 'modules/features/store/selectors'

export const PANEL_TAB = {
    DEVICES: 'devices',
    HAD: 'had',
    CONFIGURATION: 'configuration',
    KEYPAD: 'keypad',
    STATE: 'state',
    EVENTS: 'events',
    INTERACTIVE_USERS: 'interactiveUsers',
    INSTALLERS: 'installers',
    LOG: 'log',
    LOCATIONS: 'locations',
    REMOTE_INSPECTIONS: 'remoteInspections',
    REPORTS: 'reports',
    PROCESSES: 'processes',
    FIRMWARE: 'firmware',
    INFO: 'info',
}

const tabs = new Map([
    [
        PANEL_TAB.DEVICES,
        {
            label: () => __('Devices'),
            path: 'panel.devices',
            exact: true,
            selector: selectors.selectDevicesFeature,
        },
    ],
    [
        PANEL_TAB.HAD,
        {
            label: () => __('Outputs'),
            path: 'panel.had',
            selector: selectors.selectHadFeature,
        },
    ],
    [
        PANEL_TAB.CONFIGURATION,
        {
            label: () => __('Configuration'),
            path: 'panel.configuration',
            selector: selectors.selectConfigutionFeature,
        },
    ],
    [
        PANEL_TAB.KEYPAD,
        {
            label: () => __('Keypad'),
            path: 'panel.keypad',
            selector: selectors.selectKeypadFeature,
        },
    ],
    [
        PANEL_TAB.STATE,
        {
            label: () => __('State'),
            path: 'panel.state',
            selector: selectors.selectStateFeature,
        },
    ],
    [
        PANEL_TAB.EVENTS,
        {
            label: () => __('Events'),
            path: 'panel.events',
            selector: selectors.selectEventsFeature,
        },
    ],
    [
        PANEL_TAB.INTERACTIVE_USERS,
        {
            label: () => __('Customers'),
            path: 'panel.interactiveUsers',
        },
    ],
    [
        PANEL_TAB.INSTALLERS,
        {
            label: () => __('Installers'),
            path: 'panel.installers',
            selector: selectors.selectAppConfigurator,
        },
    ],
    [
        PANEL_TAB.LOG,
        {
            label: () => __('Logs'),
            path: 'panel.log',
            selector: selectors.selectLogsFeature,
        },
    ],
    [
        PANEL_TAB.LOCATIONS,
        {
            label: () => __('Locations'),
            path: 'panel.locations',
            selector: selectors.selectLocationsFeature,
        },
    ],
    [
        PANEL_TAB.REMOTE_INSPECTIONS,
        {
            label: () => __('Remote Inspections'),
            path: 'panel.remoteInspections',
            selector: selectors.selectRemoteInspectionsFeature,
        },
    ],
    [
        PANEL_TAB.REPORTS,
        {
            label: () => __('Reports'),
            path: 'panel.reports',
            selector: selectors.selectReportsFeature,
        },
    ],
    [
        PANEL_TAB.PROCESSES,
        {
            label: () => __('Processes'),
            path: 'panel.processes',
            selector: selectors.selectProcessesFeature,
        },
    ],
    [
        PANEL_TAB.FIRMWARE,
        {
            label: () => __('Firmware'),
            path: 'panel.firmware',
            selector: selectors.selectFirmwareFeature,
        },
    ],
    [
        PANEL_TAB.INFO,
        {
            label: () => __('Info'),
            path: 'panel.info',
        },
    ],
])

const allMenuItems = new Set([
    PANEL_TAB.DEVICES,
    PANEL_TAB.INFO,
    PANEL_TAB.STATE,
    PANEL_TAB.CONFIGURATION,
    PANEL_TAB.LOCATIONS,
    PANEL_TAB.PROCESSES,
    PANEL_TAB.INTERACTIVE_USERS,
    PANEL_TAB.INSTALLERS,
    PANEL_TAB.REPORTS,
    PANEL_TAB.LOG,
    PANEL_TAB.REMOTE_INSPECTIONS,
    PANEL_TAB.FIRMWARE,
    PANEL_TAB.EVENTS,
    PANEL_TAB.KEYPAD,
    PANEL_TAB.HAD,
])

const neoMenuItems = new Set([
    PANEL_TAB.DEVICES,
    PANEL_TAB.INFO,
    PANEL_TAB.STATE,
    PANEL_TAB.CONFIGURATION,
    PANEL_TAB.PROCESSES,
    PANEL_TAB.INTERACTIVE_USERS,
    PANEL_TAB.INSTALLERS,
    PANEL_TAB.REPORTS,
    PANEL_TAB.LOG,
    PANEL_TAB.REMOTE_INSPECTIONS,
    PANEL_TAB.FIRMWARE,
    PANEL_TAB.EVENTS,
    PANEL_TAB.KEYPAD,
    PANEL_TAB.HAD,
])

const fibroMenuItems = new Set([PANEL_TAB.INFO, PANEL_TAB.EVENTS])

const dualPathMenuItem = new Set([
    PANEL_TAB.DEVICES,
    PANEL_TAB.INFO,
    PANEL_TAB.STATE,
    PANEL_TAB.CONFIGURATION,
    PANEL_TAB.LOCATIONS,
    PANEL_TAB.PROCESSES,
    PANEL_TAB.INTERACTIVE_USERS,
    PANEL_TAB.INSTALLERS,
    PANEL_TAB.REPORTS,
    PANEL_TAB.LOG,
    PANEL_TAB.REMOTE_INSPECTIONS,
    PANEL_TAB.FIRMWARE,
    PANEL_TAB.EVENTS,
    PANEL_TAB.KEYPAD,
    PANEL_TAB.HAD,
])

const qolsysMenuItem = new Set([
    PANEL_TAB.DEVICES,
    PANEL_TAB.INFO,
    PANEL_TAB.STATE,
    PANEL_TAB.CONFIGURATION,
    PANEL_TAB.LOCATIONS,
    PANEL_TAB.PROCESSES,
    PANEL_TAB.INTERACTIVE_USERS,
    PANEL_TAB.INSTALLERS,
    PANEL_TAB.REPORTS,
    PANEL_TAB.LOG,
    PANEL_TAB.REMOTE_INSPECTIONS,
    PANEL_TAB.FIRMWARE,
    PANEL_TAB.EVENTS,
    PANEL_TAB.KEYPAD,
    PANEL_TAB.HAD,
])

const smartCommunicatorMenuItems = new Set([
    PANEL_TAB.DEVICES,
    PANEL_TAB.INFO,
    PANEL_TAB.STATE,
    PANEL_TAB.CONFIGURATION,
    PANEL_TAB.LOCATIONS,
    PANEL_TAB.PROCESSES,
    PANEL_TAB.INTERACTIVE_USERS,
    PANEL_TAB.REPORTS,
    PANEL_TAB.LOG,
    PANEL_TAB.REMOTE_INSPECTIONS,
    PANEL_TAB.FIRMWARE,
    PANEL_TAB.EVENTS,
    PANEL_TAB.KEYPAD,
    PANEL_TAB.HAD,
])

export function getMenuItems(vendor) {
    switch (vendor) {
        case VENDOR_NEO:
            return neoMenuItems
        case VENDOR_FIBRO_TRANSMITTER:
            return fibroMenuItems
        case VENDOR_DUAL_PATH:
            return dualPathMenuItem
        case VENDOR_QOLSYS:
            return qolsysMenuItem
        case VENDOR_SMART_COMMUNICATOR:
            return smartCommunicatorMenuItems
        case VENDOR_POWER_MASTER:
        default:
            return allMenuItems
    }
}

export function getItemsByKeys(keys) {
    return Array.from(tabs.keys()).reduce((acc, key) => {
        if (keys.includes(key)) {
            acc[key] = tabs.get(key)
        }
        return acc
    }, {})
}
