import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {
    remember,
    forget,
    fetchInteractiveUserPanels,
    receiveInteractiveUserPanels,
    receivePanelsInteractiveUser,
    fetchPanelsInteractiveUser,
    forgetPanel,
} from './actions'
import InteractiveUserPanelsById from './utils/InteractiveUserPanelsById'
import MasterUserToRemember from './utils/MasterUserToRemember'
import nameSpaceResolve, {CURRENT_STORE_NAME} from './utils/nameSpaceResolve'
import updateHistoryItems from './utils/updateHistoryItems'
import resolveRememberPath from './utils/resolveRememberPath'

const RECENT_INTERACTIVE_USERS_MAX = 50

const defaultState = {
    [CURRENT_STORE_NAME]: [], // {panel, path, interactiveUser}
    interactiveUserPanelsByIds: {}, // {interactiveUserId: InteractiveUserPanelsById}
    isLoading: false,
    error: null,
}

export default handleActions(
    {
        [forget]() {
            return defaultState
        },

        [forgetPanel](state, {payload: {panelId}}) {
            return set(
                state,
                CURRENT_STORE_NAME,
                state[CURRENT_STORE_NAME].filter((item) => item.panel.id !== panelId)
            )
        },

        [remember](state, {payload: {panel, path, masterUserToRemember}}) {
            let newHistoryItems
            const isAssignedUser =
                masterUserToRemember && masterUserToRemember.interactiveUserId !== null
            if (isAssignedUser) {
                newHistoryItems = nameSpaceResolve(state).filter(
                    ({interactiveUser, panel: existingPanel}) => {
                        if (interactiveUser) {
                            return (
                                Number(interactiveUser.interactiveUserId) !==
                                    Number(masterUserToRemember.interactiveUserId) &&
                                existingPanel.id !== panel.id
                            )
                        }

                        return true
                    }
                )
            } else {
                // uniqueness based on panel id
                newHistoryItems = nameSpaceResolve(state).filter(
                    ({panel: existPanel}) => existPanel.id !== panel.id
                )
            }
            if (newHistoryItems.length < RECENT_INTERACTIVE_USERS_MAX) {
                const lastVisit = Date.now()
                newHistoryItems.push({
                    panel: {...panel, lastVisit},
                    path: resolveRememberPath(path),
                    interactiveUser:
                        masterUserToRemember &&
                        new MasterUserToRemember({
                            ...masterUserToRemember,
                            panelUserId: masterUserToRemember.id,
                        }),
                })
            } else {
                newHistoryItems.shift()
            }
            return set(state, [CURRENT_STORE_NAME], newHistoryItems)
        },

        [fetchInteractiveUserPanels](state, {payload: interactiveUserId}) {
            return set(
                state,
                ['interactiveUserPanelsByIds', interactiveUserId],
                new InteractiveUserPanelsById({isLoading: true})
            )
        },

        [receiveInteractiveUserPanels](
            state,
            {payload: {errorObj, interactiveUserId, panels}, error}
        ) {
            if (error) {
                return set(
                    state,
                    ['interactiveUserPanelsByIds', interactiveUserId],
                    new InteractiveUserPanelsById({isLoading: false, error: errorObj})
                )
            }
            return set(
                state,
                ['interactiveUserPanelsByIds', interactiveUserId],
                new InteractiveUserPanelsById({isLoading: false, panels})
            )
        },
        [fetchPanelsInteractiveUser](state) {
            return set(state, 'isLoading', true)
        },
        [receivePanelsInteractiveUser](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoading: false,
                }
            }

            return {
                ...state,
                [CURRENT_STORE_NAME]: updateHistoryItems(
                    state[CURRENT_STORE_NAME],
                    payload
                ),
                isLoading: false,
            }
        },
    },
    defaultState
)
