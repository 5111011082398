import React from 'react'
import PropTypes from 'prop-types'

import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'

export default function Paginator({rows, current, onChange}) {
    const currentIndex = rows.findIndex(({id}) => id === current.id)
    const back = () => onChange(rows[currentIndex - 1])
    const forward = () => onChange(rows[currentIndex + 1])

    return (
        <Buttons className="blindModalWithPagination-paginator" flat>
            <Button
                disabled={currentIndex === 0}
                className="blindModalWithPagination-paginator-prev"
                onClick={back}
                Icon={IconPrev}
            />
            <Button
                disabled={currentIndex === rows.length - 1}
                className="blindModalWithPagination-paginator-next"
                onClick={forward}
                Icon={IconNext}
            />
        </Buttons>
    )
}

Paginator.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        })
    ),
    current: PropTypes.shape({
        id: PropTypes.number,
    }),
    onChange: PropTypes.func,
}
