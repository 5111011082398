import React from 'react'

import list from 'permissions/remoteInspections/list'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const PagerVisibility = withVisibility()(Pager)

export default function RemoteInspectionsPager() {
    const {isPermitted} = usePermission(list)
    const remoteInspections = useRemoteInspections()

    return <PagerVisibility {...remoteInspections} isVisible={isPermitted} />
}
