import React from 'react'
import PropTypes from 'prop-types'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import {BlindModalCardTable} from 'ui/BlindModal'
import Empty from 'ui/Empty'
import __ from 'utils/i18n'

import UnassignPanelCell from './UnassignPanelCell'
import {RoleCell} from './RoleCell'

export const columns = [
    {
        name: () => __('Panel'),
        width: 200,
        render: ({id, serial, account}) => (
            <PanelCell to="interactiveUsers" {...{id, serial, account}} multiLine />
        ),
    },
    {
        name: () => __('Group'),
        maxWidth: 125,
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        maxWidth: 125,
        name: () => __('Label'),
        render: ({label}) =>
            label ? <strong>{label}</strong> : <Empty>{__('No Label')}</Empty>,
    },
    {
        name: () => __('Role'),
        maxWidth: 115,
        render: ({role}) => <RoleCell role={role} />,
    },
    {
        tooltip: false,
        fixed: true,
        width: 66,
        component: UnassignPanelCell,
    },
]

export const Table = ({rows, interactiveUserId}) => (
    <BlindModalCardTable
        columns={columns}
        title={__('Panels')}
        rows={rows}
        tableProps={{interactiveUserId}}
        className="customer-page-blind-table"
    />
)

Table.propTypes = {
    rows: PropTypes.array.isRequired,
    interactiveUserId: PropTypes.number.isRequired,
}

export default Table
