import React from 'react'
import PropTypes from 'prop-types'

import Tooltip, {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import {useDeliveranceIcon} from 'hooks/events/deliverance'
import {useCentralStationStatus} from 'hooks/events/deliverance'
import {useCentralStationHost} from 'hooks/events/deliverance'
import {useDeliveranceStatus} from 'hooks/events/deliverance'

export default function DeliveranceTableRow({centralStation, delivery}) {
    const {eds_status, eds_id} = delivery
    const status = useCentralStationStatus(centralStation?.status)
    const host = useCentralStationHost(centralStation)
    const deliveranceStatus = useDeliveranceStatus(eds_status)
    const deliveranceIcon = useDeliveranceIcon(eds_status)

    return (
        <div className="row">
            <div className="col">{eds_id}</div>
            <div className="col">
                <OverflowTextTooltip
                    className="deliverance-centralstation-protocol"
                    tooltip={centralStation?.protocol}
                    children={centralStation?.protocol}
                />
            </div>
            <div className="col">
                <OverflowTextTooltip
                    className="deliverance-centralstation-host"
                    tooltip={host}
                    children={host}
                />
            </div>
            {status}
            <div className="col">
                <Tooltip
                    className="deliverance-centralstation-icon-tooltip"
                    tooltip={deliveranceStatus}
                >
                    {deliveranceIcon}
                </Tooltip>
            </div>
        </div>
    )
}

DeliveranceTableRow.propTypes = {
    centralStation: PropTypes.object,
    delivery: PropTypes.object,
}
