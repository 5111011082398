import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import page from 'permissions/group/page'
import {withPermission, withRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'
import withGroup from 'containers/withGroup'

import Page from 'ipmp-react-ui/Page'
import Layout from 'ipmp-react-ui/Layout'

import GroupTop from './GroupTop'
import GroupContent from './GroupContent'
import GroupBar from './GroupBar'

class GroupPage extends Page {
    static propTypes = {
        group: PropTypes.object,
    }

    renderTop() {
        const {group} = this.props

        return <GroupTop group={group} />
    }

    renderBar() {
        return <GroupBar />
    }

    renderContent() {
        const {group} = this.props

        return (
            <Layout>
                <GroupContent group={group} />
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withGroup(),
    withLoader(({fetch}) => fetch())
)(GroupPage)
