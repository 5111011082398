import React from 'react'
import classnames from 'classnames'

import Empty from 'ui/Empty'

const HadConfigurationTabTableRow = ({
    title,
    value,
    missingTitle,
    isDisabled,
    EditButton,
    RemoveButton,
}) => {
    return (
        <div
            className={classnames('table-row', {
                'table-row--disabled': isDisabled,
            })}
        >
            <div className="table-cell table-cell--fixed hadConfigurationTabTable-row-labelCell">
                {title.toUpperCase()}
            </div>
            <div className="table-cell hadConfigurationTabTable-row-valueCell">
                {value || <Empty>{missingTitle}</Empty>}
            </div>
            <div className="table-cell table-cell--fixed hadConfigurationTabTable-row-buttonsCell">
                {EditButton}
                {value && RemoveButton}
            </div>
        </div>
    )
}

export default HadConfigurationTabTableRow
