import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withLoader from 'containers/withLoader'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import {BlindModalCardTable} from 'ui/BlindModal'
import __ from 'utils/i18n'

import AssignPanelCell from './AssignPanelCell'

const columns = [
    {
        name: () => __('Panel'),
        maxWidth: 284,
        render: ({id, serial, account}) => (
            <PanelCell to="interactiveUsers" {...{id, serial, account}} />
        ),
    },
    {
        name: () => __('Group'),
        maxWidth: 250,
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        tooltip: false,
        fixed: true,
        width: 66,
        component: AssignPanelCell,
    },
]

const TableComponent = ({rows, interactiveUserId}) => (
    <BlindModalCardTable
        columns={columns}
        title={__('Panels')}
        rows={rows}
        tableProps={{interactiveUserId}}
    />
)

TableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    interactiveUserId: PropTypes.number.isRequired,
}

const TableContainer = compose(withLoader(({fetch}) => fetch()))(TableComponent)

TableContainer.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            serial: PropTypes.string,
            account: PropTypes.string,
            group: PropTypes.string,
        })
    ).isRequired,
    fetch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    interactiveUserId: PropTypes.number.isRequired,
}

export default TableContainer
