import {put, call} from 'redux-saga/effects'
import {getMfa} from 'api/system/settings'

import * as actions from 'modules/system/settings/actions'
import {snackShow} from 'modules/snacks'

export default function* watchMfa() {
    try {
        const settings = yield call(getMfa)

        yield put(actions.updateMFA(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
