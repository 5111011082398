import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as EmailIcon} from 'ipmp-react-ui/icons/email.svg'
import BlindModalCardContentHeaderWrapper from 'ipmp-react-ui/BlindModalCardContentHeaderWrapper'

function Info({user: {email}}) {
    return (
        <BlindModalCardContentHeaderWrapper>
            <div className="interactiveUserBlind-info">
                <section className="interactiveUserBlind-info-section">
                    <div className="interactiveUserBlind-info-item">
                        <EmailIcon className="interactiveUserBlind-info-icon" />
                        <span className="interactiveUserBlind-info-link">{email}</span>
                    </div>
                </section>
            </div>
        </BlindModalCardContentHeaderWrapper>
    )
}

Info.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string,
    }),
}

export default Info
