import {useSelector} from 'react-redux'

export default function useTableColumnsPermission(columns) {
    const permissions = useSelector((state) => state.auth.permissions)

    return {
        columns: columns.filter(
            (column) => !column.permission || column.permission(permissions)
        ),
    }
}
