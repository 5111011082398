import {warn} from 'utils/log'
import isFunction from 'lodash-es/isFunction'

export default function resolveMapValues(
    map: Map,
    warningMessageCreator: Function,
    defaultResultCreator: Function
) {
    return (key) => {
        if (map.has(key)) {
            return map.get(key)
        }

        warn(warningMessageCreator(key))

        return defaultResultCreator && isFunction(defaultResultCreator)
            ? defaultResultCreator(map, key)
            : key
    }
}
