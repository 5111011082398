import React, {useRef, useState} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {content} from 'permissions/panel/page'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Menu, {MenuLink} from 'ipmp-react-ui/Menu'
import NavTabs, {NavTabLink, NavTabPopup} from 'ipmp-react-ui/NavTabs'

import path from 'utils/path'
import {__} from 'utils/i18n'
import useResizeObserver from 'hooks/useResizeObserver'

const TAB_PADDING = 20
const NAV_TAB_PADDING = 32
const CORRECTION_COEFFICIENT_AVG_SYMBOL_LENGTH = 1.2
const OTHER_TAB_WIDTH_WITH_ICON = 16

const PanelPageTabs = ({menu = {}, match: {params}}) => {
    const [items, setItems] = useState(Object.values(menu).length)
    const elementRef = useRef(null)

    const renderTabs = (key) => {
        const {label, path: pathName, exact} = menu[key]

        return (
            <NavTabLink key={key} to={path(pathName, params)} exact={exact}>
                {label()}
            </NavTabLink>
        )
    }

    const recalculate = (el) => {
        const oneCharWidth =
            ((el[0].target.children[0].firstChild.offsetWidth - TAB_PADDING) /
                menu[Object.keys(menu)[0]].label().length) *
            CORRECTION_COEFFICIENT_AVG_SYMBOL_LENGTH

        const otherTabWidth =
            (__('Other').length + 1) * oneCharWidth + OTHER_TAB_WIDTH_WITH_ICON
        const navWidth = el[0].target.clientWidth

        let neededWidth = Object.keys(menu).length * TAB_PADDING + NAV_TAB_PADDING
        Object.values(menu).forEach((val) => {
            neededWidth += val.label().length * oneCharWidth
        })
        neededWidth += otherTabWidth

        const tabs = Object.keys(menu)

        if (navWidth < neededWidth) {
            for (
                let currentWidth = 0, i = 0;
                i < tabs.length &&
                currentWidth < navWidth - otherTabWidth &&
                i < tabs.length &&
                navWidth < neededWidth;
                ++i
            ) {
                currentWidth += menu[tabs[i]].label().length * oneCharWidth + TAB_PADDING
                setItems(i)

                if (
                    i === tabs.length - 1 &&
                    menu[tabs[i]].label().length * oneCharWidth + TAB_PADDING <=
                        otherTabWidth
                ) {
                    setItems(tabs.length)
                }

                if (currentWidth > navWidth - otherTabWidth) {
                    setItems(i - 1)
                }
            }
        }

        if (navWidth >= neededWidth) {
            setItems(tabs.length)
        }
    }

    useResizeObserver(recalculate, elementRef, [menu])

    const getLinks = () => {
        return [Object.keys(menu).slice(0, items), Object.keys(menu).slice(items)]
    }

    const [directLinks, popupLinks] = getLinks()

    return (
        <div ref={elementRef}>
            <NavTabs className="panelPage-tabs">
                {directLinks.map(renderTabs)}

                {popupLinks.length > 0 && (
                    <NavTabPopup label={__('Other')}>
                        <Menu>
                            {popupLinks.map((key) => {
                                const {label, path: pathName} = menu[key]

                                return (
                                    <MenuLink key={key} to={path(pathName, params)}>
                                        {label()}
                                    </MenuLink>
                                )
                            })}
                        </Menu>
                    </NavTabPopup>
                )}
            </NavTabs>
        </div>
    )
}

PanelPageTabs.propTypes = {
    menu: PropTypes.object,
    match: PropTypes.object,
}

export default compose(
    withRouter,
    withPermission({
        isVisible: content,
    }),
    withVisibility()
)(PanelPageTabs)
