import {useSelector} from 'react-redux'
import {
    fetchAll,
    fetchOne,
    selectOne,
    persistOne,
    clearUncommitted,
} from 'modules/groups/customizations/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {selectAll} from 'modules/groups/customizations/selectors'

export default function useCustomizations(groupId) {
    const customizations = useSelector(selectAll)

    return {
        ...customizations,
        isLoading: customizations.isLoadingAll || customizations.isLoadingOne,
        ...useActions({
            fetchAll,
            fetchOne,
            save: (customizationId) => persistOne(groupId, customizationId),
            dismiss: () => clearUncommitted(groupId),
            handle: (customizationId) => selectOne(groupId, customizationId),
        }),
    }
}
