import withLoader from 'containers/withLoader'
import React, {useEffect} from 'react'
import {compose} from 'redux'

import list from 'permissions/actionLog/list'

import {withRejection} from 'containers/withPermission'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'

import ActionLogDescriptionCell from './Cell/ActionLogDescriptionCell'
import ActionLogStatusCell from './Cell/ActionLogStatusCell'
import useActionLog from 'hooks/pages/useActionLog'
import usePermission from 'hooks/usePermission'

const columns = [
    {
        name: () => __('Time'),
        maxWidth: 140,
        render: ({time}) => <DateTime datetime={time} timeFirst />,
    },
    {
        name: () => __('User'),
        width: 30,
        maxWidth: 200,
        render: ({userName}) => userName || <span className="empty">—</span>,
    },
    {
        name: () => __('Description'),
        width: 350,
        component: ActionLogDescriptionCell,
    },
    {
        name: () => __('Status'),
        maxWidth: 150,
        component: ActionLogStatusCell,
    },
]

const ConnectedTable = compose(withRejection(), withLoader())(Table)

export default function ActionLogTable() {
    const actionLogProps = useActionLog()
    const {isPermitted} = usePermission(list)

    useEffect(() => {
        if (isPermitted) {
            actionLogProps.init()
            actionLogProps.startPoll()
        }

        return () => {
            isPermitted && actionLogProps.stopPoll()
        }
    }, [])

    return (
        <ConnectedTable
            isAllowed={isPermitted}
            className="actionLog-page-table"
            columns={columns}
            emptyMessage={() => __('No action logs found')}
            fullHeight
            {...actionLogProps}
        />
    )
}
