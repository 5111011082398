import initValidateJS from 'configs/validate'
import initSpikes from 'configs/spikes'
import initInterceptors from 'configs/interceptors'
import initIpmpReactUI from 'configs/ipmpReactUI'
import 'configs/initServiceWorker'

initValidateJS()
initSpikes()
initInterceptors()
initIpmpReactUI()
