import React from 'react'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDlsDisconnect} from 'ipmp-react-ui/icons/union-broken.svg'

import withConfirmationModal from 'containers/withConfirmationModal'
import {__} from 'utils/i18n'

const ConnectedMenuItem = withConfirmationModal(
    ({disconnect}) => [disconnect],
    () => ({
        title: __('Disconnect'),
        message: __('Do you really want to disconnect DLS session?'),
        positive: __('Confirm'),
    })
)(MenuItem)

export function ConfirmationDisconnectMenuItem({disconnect}) {
    return (
        <ConnectedMenuItem
            disconnect={disconnect}
            Icon={IconDlsDisconnect}
            children={__('Disconnect')}
        />
    )
}
