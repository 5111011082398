import React from 'react'
import {__} from 'utils/i18n'
import Button from 'ipmp-react-ui/Button'
import get from 'lodash-es/get'

import {withVisibility} from 'containers/withVisibility'

import {setLabel as rename} from 'permissions/panel/labels'
import {useDeviceActions} from 'hooks/pages/useDevices'
import usePermission from 'hooks/usePermission'

const ButtonVisible = withVisibility()(Button)

export default function RenameButton({panelId, device}) {
    const {isPermitted} = usePermission(rename)
    const {renameDevice} = useDeviceActions(panelId, device)

    return (
        <ButtonVisible
            onClick={renameDevice}
            label={__('Rename')}
            className="devices-sidebar-button btn--flat"
            isVisible={get(device, ['isRenameable'], false) && isPermitted}
        />
    )
}
