export default function (rows, keys) {
    return rows.reduce((available, row) => {
        for (const key of keys) {
            if (row[key] && !available.includes(key)) {
                available.push(key)
            }
        }

        return available
    }, [])
}
