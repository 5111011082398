import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import path from 'utils/path'

class SettingsPageTabs extends Component {
    static propTypes = {
        menu: PropTypes.object,
    }

    static defaultProps = {
        menu: {},
    }

    renderTabs = (key) => {
        const {
            match: {params},
            menu,
        } = this.props
        const {label, path: pathName, exact} = menu[key]

        return (
            <NavTabLink key={key} to={path(pathName, params)} exact={exact}>
                {label()}
            </NavTabLink>
        )
    }

    render() {
        const {menu} = this.props

        const directLinks = Object.keys(menu)

        return (
            <NavTabs className="settings-page-tabs">
                {directLinks.map(this.renderTabs)}
            </NavTabs>
        )
    }
}

export default compose(withRouter)(SettingsPageTabs)
