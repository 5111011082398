import React from 'react'

import {setStart} from 'modules/firmware/list/actions'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import list from 'permissions/firmware/list'
import usePermission from 'hooks/usePermission'
import useUpgradablePanels from 'hooks/pages/useUpgradablePanels'
import {useActions} from 'modules/higherOrder/useActions'

const PagerVisibility = withVisibility()(Pager)

export default function FirmwarePager() {
    const {isPermitted} = usePermission(list)
    const upgrade = useUpgradablePanels()
    const actions = useActions({
        setStart,
        onPageChange: setStart,
    })

    const pagerProps = {...upgrade, ...actions}

    return <PagerVisibility {...pagerProps} isVisible={isPermitted} />
}
