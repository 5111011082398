import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardClose, CardContent} from 'ipmp-react-ui/Card'

import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'

import LicenseFeaturesTable from './ui/LicenseFeaturesTable'

import {LICENSE_VENDORS, licenseLimitsName} from 'constants/license'
import {useSelector} from 'react-redux'
import {selectLicense} from 'modules/system/settings/selectors'

export default function AboutLicenseFeatures({hide}) {
    const {
        license: {limits, valid, features},
    } = useSelector(selectLicense)

    return (
        <Modal onClose={hide}>
            <Card className="card--wide">
                <CardClose onClick={hide} />
                <CardContent className="card-about-license">
                    <div className="block">
                        <h3 className="title title--margin">
                            {__('License valid dates').toUpperCase()}
                        </h3>

                        <div className="about-info">
                            <p className="hint hint--gray">{__('From')}</p>
                            {moment.unix(valid.not_before).format('LL – LT')}
                        </div>

                        <div className="about-info">
                            <p className="hint hint--gray">{__('Until')}</p>
                            {moment.unix(valid.not_after).format('LL – LT')}
                        </div>
                    </div>
                    <div className="block">
                        <h3 className="title title--margin">
                            {__('License activated systems').toUpperCase()}
                        </h3>
                        {LICENSE_VENDORS.map((key) => {
                            if (limits[key] >= 0) {
                                return (
                                    <div className="about-info" key={key}>
                                        <p className="hint hint--gray">
                                            {licenseLimitsName(key)}
                                        </p>
                                        {limits[key]}
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className="block">
                        <h3 className="title title--margin">
                            {__('License features').toUpperCase()}
                        </h3>
                    </div>
                    <LicenseFeaturesTable
                        className="modal-table"
                        rows={Object.values(features).filter((row) => row.feature)}
                    />
                </CardContent>
                <CardActions>
                    <Button primary onClick={hide}>
                        {__('Ok')}
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

AboutLicenseFeatures.propTypes = {
    hide: PropTypes.func,
}
