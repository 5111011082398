import DiagnosticInfo from 'components/Diagnostic/DiagnosticInfo'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import List, {ListHintItem} from 'ipmp-react-ui/List'
import Definition from 'ipmp-react-ui/Definition'
import {humanTime} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'

import {deviceSignals} from 'constants/signalLevelType'
import {signalLevelTitle} from 'constants/signalLevelType'
import {DEVICE_TYPE_POWER_LINK} from 'constants/deviceType'

import {withPermission} from 'containers/withPermission'
import plink from 'permissions/panel/devices/plink'
import diagnostic from 'permissions/panel/devices/diagnostic'

import PlinkDebug from './PlinkDebug'
import PGMForm from './PGM/EditPGM'
import RefreshRssiButton from 'pages/Panel/Devices/Bars/Buttons/RefreshRssiButton'
import GeneralInfoTabBlock from './GeneralInfoTabBlock'

export class GeneralInfoTab extends Component {
    static propTypes = {
        device: PropTypes.shape({
            traits: PropTypes.shape({
                rarely_used: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                bypass: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                soak: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                rssi: PropTypes.shape({
                    average: PropTypes.oneOf(deviceSignals),
                    current: PropTypes.oneOf(deviceSignals),
                    channel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    repeater: PropTypes.number,
                    triggeredAt: PropTypes.string,
                }),
            }),
        }).isRequired,
    }

    renderRssi({average, current, channel, repeater, last_updated: updated}) {
        const empty = <span className="empty">{__('not set')}</span>

        const info = (
            <List twoCols>
                <ListHintItem
                    name={__('Current')}
                    text={current ? signalLevelTitle(current) : empty}
                />
                <ListHintItem
                    name={__('Average')}
                    text={average ? signalLevelTitle(average) : empty}
                />
                <ListHintItem name={__('Channels')} text={channel || empty} />
                <ListHintItem
                    name={__('Repeater')}
                    text={repeater !== null ? repeater : empty}
                />
            </List>
        )

        const noInfo = (
            <div className="rssi-noMeasurements">
                <h6 className="title title--medium">{__('No measurements')}</h6>
                <p className="rssi-noMeasurements-content">
                    {__('Click Refresh RSSI button to reveal device rssi info')}
                </p>
                <RefreshRssiButton flat />
            </div>
        )

        return (
            <div className="block rssi">
                <Definition
                    className="title"
                    multiLine
                    title={__('RSSI')}
                    detail={updated && humanTime(updated)}
                />

                {updated ? info : noInfo}
            </div>
        )
    }

    renderDiagnostic(diagnostic) {
        return (
            <div className="block">
                <h4 className="title">{__('Diagnostic')}</h4>

                <DiagnosticInfo {...diagnostic} />
            </div>
        )
    }

    renderPGM() {
        const {panelId, device} = this.props

        return (
            <div className="block">
                <PGMForm
                    panelId={panelId}
                    id={device.id}
                    partitions={device.partitions}
                />
            </div>
        )
    }

    render() {
        const {panelId, isPlinkAllowed, isDiagnosticAllowed, device, isGeneric} =
            this.props
        const {traits, deviceType} = device || {}
        const {rssi, diagnostic} = traits || {}
        return (
            <div className="blocks">
                <GeneralInfoTabBlock device={device} panelId={panelId} />
                {rssi !== undefined && !isGeneric && this.renderRssi(rssi || {})}
                {isDiagnosticAllowed && diagnostic && this.renderDiagnostic(diagnostic)}
                {isPlinkAllowed && deviceType === DEVICE_TYPE_POWER_LINK && (
                    <PlinkDebug panelId={panelId} />
                )}

                {/* PMN-15905 - {deviceType === DEVICE_TYPE_PGM && this.renderPGM()}*/}
            </div>
        )
    }
}

export default compose(
    withPermission({
        isPlinkAllowed: plink,
        isDiagnosticAllowed: diagnostic,
    })
)(GeneralInfoTab)
