import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ConnectedRouter as Router} from 'connected-react-router'
import {connect} from 'react-redux'
import SnackBarComponent from 'ipmp-react-ui/SnackBar'
import {hide} from 'modules/snacks'
import {hide as navBlindHide} from 'modules/navBlind/actions'
import path from 'utils/path'
import withLoader from 'containers/withLoader'
import pages from 'pages/routes'
import Login from 'pages/App/Login'
import NavMain from 'components/Nav/NavMain'
import ModalsContainer from 'modals/ModalsContainer'
import NavBlindContainer from 'components/Nav/NavBlindContainer'
import NavbarTop from './NavbarTop'
import Sidebar from './Sidebar'
import {showAboutModal} from 'modules/modals/actions'
import classes from 'classnames'
import JobNotifications from './Notifications/JobNotifications'

const SnackBar = connect(
    ({snacks}) => ({snacks}),
    (dispatch) =>
        bindActionCreators(
            {
                hide,
                dispatch,
            },
            dispatch
        )
)(SnackBarComponent)

class Layout extends PureComponent {
    static propTypes = {
        locale: PropTypes.string.isRequired,
        isLogged: PropTypes.bool.isRequired,
        history: PropTypes.object,
        navBlindHide: PropTypes.func.isRequired,
    }

    state = {
        sidebarShowed: Boolean(localStorage.getItem('PMSidebar')),
        sidebarPinned: Boolean(localStorage.getItem('PMSidebar')),
        selectedBatchId: null,
    }

    closeNavBlind = () => this.props.navBlindHide()

    handleAppRef = (appElement) => {
        this.appElement = appElement

        if (this.appElement) {
            this.appElement.addEventListener('click', this.handleAppClick, true)
        }
    }

    navRefHandler = (navElement) => (this.navElement = navElement)

    processRefHandler = (processElement) => (this.processElement = processElement)

    navBlindRefHandler = (navBlindElement) => (this.navBlindElement = navBlindElement)

    sidebarRef = React.createRef()

    handleClickOutside = (e) => {
        if (
            this.sidebarRef.current &&
            !this.sidebarRef.current.contains(e.target) &&
            this.state.sidebarShowed &&
            !e.target.closest('.sidebar-opener.active') &&
            !e.target.closest('.modal') &&
            !this.state.sidebarPinned
        ) {
            this.setState({
                sidebarShowed: false,
            })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        if (this.appElement) {
            this.appElement.removeEventListener('click', this.handleAppClick, true)
        }
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleAppClick = (event) => {
        if (!this.navElement) {
            return
        }

        const {isNavBlindOpened} = this.props

        const isClickOnBatchInfo =
            this.navBlindElement &&
            (this.navBlindElement.contains(event.target) ||
                this.navBlindElement === event.target)
        const isRecentHistoryWidget = Boolean(
            event.target.closest('.recentHistoryWidgetController')
        )
        const isClickOnNavigation = this.navElement.contains(event.target)
        const isClickOnMyProcesses =
            this.processElement && this.processElement.contains(event.target)

        switch (true) {
            case isClickOnBatchInfo:
                break
            case isRecentHistoryWidget && isNavBlindOpened:
                event.stopPropagation()
                this.closeNavBlind()
                break
            case isClickOnNavigation:
                this.closeNavBlind()
                break
            case isClickOnMyProcesses:
                break
            default:
                this.closeNavBlind()
                break
        }
    }

    toggleSidebar = () => {
        if (!this.state.sidebarPinned) {
            this.setState({
                sidebarShowed: !this.state.sidebarShowed,
            })
        }
    }

    selectBatchId = (batchId) => {
        this.setState({
            sidebarShowed: true,
            selectedBatchId: batchId,
        })
    }

    toggleSidebarPin = () => {
        if (!this.state.sidebarPinned) {
            localStorage.setItem('PMSidebar', 'true')
        } else {
            localStorage.removeItem('PMSidebar')
        }

        this.setState({
            sidebarPinned: !this.state.sidebarPinned,
        })
    }

    render() {
        const {showAboutModal, locale, isLogged, history, theme} = this.props

        if (!isLogged) {
            return (
                <Login>
                    <SnackBar />
                </Login>
            )
        }

        return (
            <Router history={history} key={locale}>
                <div className="app" ref={this.handleAppRef}>
                    <aside className="app-aside">
                        <div className="app-aside-content">
                            <NavMain navRefHandler={this.navRefHandler} />
                        </div>
                    </aside>
                    <div
                        className={classes('main-content', {
                            'sidebar-pinned': this.state.sidebarPinned,
                        })}
                    >
                        <NavbarTop
                            theme={theme}
                            handleClick={this.toggleSidebar}
                            sidebarShowed={this.state.sidebarShowed}
                            showAboutModal={showAboutModal}
                        />

                        <Switch>
                            <Route
                                exact
                                path={path('/')}
                                render={() => <Redirect to={path('panels', {})} />}
                            />
                            {pages.map((page, i) => (
                                <Route key={i} {...page} />
                            ))}
                        </Switch>
                        {this.state.sidebarShowed && (
                            <Sidebar
                                sidebarRef={this.sidebarRef}
                                hide={this.toggleSidebar}
                                pin={this.toggleSidebarPin}
                                isPinned={this.state.sidebarPinned}
                                processRefHandler={this.processRefHandler}
                                selectedBatchId={{
                                    value: this.state.selectedBatchId,
                                    clear: () => this.setState({selectedBatchId: null}),
                                }}
                            >
                                <NavBlindContainer
                                    navBlindRefHandler={this.navBlindRefHandler}
                                    hideSidebar={this.toggleSidebar}
                                />
                            </Sidebar>
                        )}
                        {!this.state.sidebarShowed && (
                            <JobNotifications onClick={this.selectBatchId} />
                        )}
                        <SnackBar />
                    </div>
                    <ModalsContainer />
                </div>
            </Router>
        )
    }
}

export default compose(
    connect(
        ({auth: {sign}, locales, navBlind: {type}, settings: {theme}}) => ({
            locale: locales.locale,
            isLogged: !!sign.user,
            isLoading: !sign.checked,
            isNavBlindOpened: !!type,
            theme,
        }),

        (dispatch) => bindActionCreators({showAboutModal, navBlindHide}, dispatch)
    ),
    withLoader()
)(Layout)
