import {createSelector} from 'reselect'
import {
    eventRuleWildCardTitle,
    EVENT_RULE_ZONE_TYPE_WILD_CARD,
    getZoneTypeName,
    EVENT_RULE_ZONE_TYPE_FEATURE_NULL,
} from 'constants/eventRules'

export const selectAllZoneTypesForEventRule = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (zoneTypesByIds) => ({
        data: [
            {
                id: EVENT_RULE_ZONE_TYPE_WILD_CARD,
                name: eventRuleWildCardTitle(),
            },
            ...Object.values(zoneTypesByIds).filter(
                ({features}) => !features[EVENT_RULE_ZONE_TYPE_FEATURE_NULL]
            ),
        ],
    })
)

export const selectZoneTypeNameById = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (_, {id}) => id,
    (storeByIds, id) => ({name: getZoneTypeName(storeByIds, id)})
)

export const selectZoneTypeSuggests = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (storeByIds) => {
        const data = new Set(Object.values(storeByIds).map((item) => item.name))
        return {
            count: data.size,
            values: [...data],
        }
    }
)

export const selectZoneTypeFilter = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (_, query) => query,
    (storeByIds, query) =>
        Object.values(storeByIds)
            .filter((item) => item.name === query)
            .map((item) => item.id)
)
