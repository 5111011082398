import {createSelector} from 'reselect'

export const selectInstallerAccessiblePanelsList = createSelector(
    (state) => state.installerPanels.accessiblePanels.list,
    (state) => state.installerPanels.accessiblePanels.store.byIds,
    ({page, error, isLoading}, store) => {
        const rows = page.map((id) => store[id] || {})

        return {
            rows,
            isLoading,
            error,
        }
    }
)

export const selectInstallerAccessiblePanelsPager = createSelector(
    (state) => state.installerPanels.accessiblePanels.list,
    ({start, total}) => ({start, total})
)

export const selectInstallerAccessiblePanelsFetch = createSelector(
    (state) => state.installerPanels.accessiblePanels.list,
    ({start, filters, query}) => ({start, filters, query})
)
