import React from 'react'

import withSelection from 'containers/withSelection'

import SelectionDropDown from 'components/SelectionDropDown'
import useInteractiveUsers from 'hooks/pages/useInteractiveUsers'

const ConnectedSelectionDropDown = withSelection()(SelectionDropDown)

export default function InteractiveUsersSelectionDropDown() {
    const interactiveUsersProps = useInteractiveUsers()

    return <ConnectedSelectionDropDown {...interactiveUsersProps} />
}
