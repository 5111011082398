import React, {useEffect, useState} from 'react'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import PanelFieldSet, {validation as panelValidation} from './Fieldset/Panel'
import CustomerFieldSet, {validation as customerValidation} from './Fieldset/Customer'
import withForm from 'containers/withForm'
import {addPanel} from 'modules/forms/handlers'
import panelVendorType from 'constants/panelVendorType'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {clear} from 'modules/forms/actions'
import PanelVendorsListSelect from './PanelVendorListSelect'

const AddPanel = ({handle, googleToken, clear, ...props}) => {
    const [vendor, setVendor] = useState(null)

    const changePanelType = (e, value) => {
        setVendor(value)
    }

    useEffect(() => {
        return () => clear()
    }, [])

    const header = vendor ? __('Add %s Panel', panelVendorType(vendor)) : __('Add Panel')
    const rules = {
        ...customerValidation,
        ...panelValidation(vendor),
    }

    return (
        <ModalCardForm
            wide
            confirmOnDismiss
            onSubmit={handle}
            header={header}
            rules={rules}
            {...props}
        >
            <div className="cols cols--stretch">
                <div className="col">
                    <h3 className="form-section-header">{__('Panel Info')}</h3>

                    <PanelVendorsListSelect changePanelParent={changePanelType} />

                    <PanelFieldSet isNew vendor={vendor} />
                </div>

                <div className="col">
                    <h3 className="form-section-header">{__('Owner Info')}</h3>
                    <CustomerFieldSet googleToken={googleToken} isNew={true} />
                </div>
            </div>
        </ModalCardForm>
    )
}

export default compose(
    withForm(addPanel),
    connect(
        ({serverSettings}) => ({googleToken: serverSettings.google.token}),
        (dispatch) => bindActionCreators({clear}, dispatch)
    )
)(AddPanel)
