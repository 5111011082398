import React, {useState} from 'react'
import PropTypes from 'prop-types'

import DoubleBlindModal, {DoubleBlindModalEntry} from 'ipmp-react-ui/DoubleBlindModal'

import FirstBlind from './FirstBlind'
import SecondBlind from './SecondBlind'

export default function DoubleBlindWithPagination({
    rows,
    current,
    onChange,
    closeBlind,
    FirstBlindContent,
    SecondBlindContent,
}) {
    const [isSecondBlindOpened, setSecondBlindOpened] = useState(false)
    const openSecondBlind = () => setSecondBlindOpened(true)
    const closeSecondBlind = () => setSecondBlindOpened(false)

    return (
        <DoubleBlindModal>
            <DoubleBlindModalEntry
                className="blindModalWithPagination"
                opened={Boolean(current)}
                onClose={closeBlind}
            >
                <FirstBlind
                    rows={rows}
                    current={current}
                    onChange={onChange}
                    closeBlind={closeBlind}
                    openSecondBlind={openSecondBlind}
                    Content={FirstBlindContent}
                />
            </DoubleBlindModalEntry>
            <DoubleBlindModalEntry
                opened={isSecondBlindOpened}
                onClose={closeSecondBlind}
            >
                <SecondBlind
                    row={current}
                    closeBlind={closeSecondBlind}
                    Content={SecondBlindContent}
                />
            </DoubleBlindModalEntry>
        </DoubleBlindModal>
    )
}

DoubleBlindWithPagination.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    current: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    closeBlind: PropTypes.func.isRequired,
    FirstBlindContent: PropTypes.func,
    SecondBlindContent: PropTypes.func,
}
