import {__} from 'utils/i18n'

export const INVALID_GOOGLE_MAP_API_KEY = 'The provided API key is invalid'

export function googleApiErrorMessage(error) {
    if (error === INVALID_GOOGLE_MAP_API_KEY) {
        return __('The provided Google API key is invalid')
    }

    return error
}
