import {useDispatch, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'

import * as actions from 'modules/installerPanels/list/actions'
import {selectInstallerPanelsList} from 'modules/installerPanels/list/selectors'

export default function useInstallerAssignedPanels(id) {
    const dispatch = useDispatch()

    const {rows, isLoading} = useSelector((state) => selectInstallerPanelsList(state, id))
    const {fetch, resetWithOutFetch} = bindActionCreators(
        {
            fetch: actions.fetch,
            resetWithOutFetch: actions.resetWithOutFetch,
        },
        dispatch
    )

    return {
        rows,
        isLoading,
        fetch,
        resetWithOutFetch,
    }
}
