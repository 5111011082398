import has from 'lodash-es/has'
import __ from 'utils/i18n'
import {warn} from 'utils/log'

import iconDualPath from 'assets/panels/dualpath.svg'
import iconPowerMaxPro from 'assets/panels/powermax-pro.svg'
import iconPowerMaster30 from 'assets/panels/powermaster-30.svg'
import iconPowerMaster10 from 'assets/panels/powermaster-10.svg'
import iconPowerMaster33e from 'assets/panels/powermaster-33E.svg'
import iconPowerMaster360 from 'assets/panels/powermaster-360.svg'
import iconPowerMaster360R from 'assets/panels/powermaster-360R.svg'
import iconNeo1 from 'assets/panels/neo-1.svg'
import iconUnknown from 'assets/panels/unknown.svg'
import iconVirtualPanel from 'assets/panels/virtual-panel.svg'
import iconIQ2 from 'assets/panels/IQ2.svg'
import iconIQ4 from 'assets/panels/IQ4.svg'
import iconSmartCommunicatorGeneric from 'assets/panels/SmartCommunicator-connected-to-generic-panel.svg'
import iconSmartCommunicatorDsc from 'assets/panels/SmartCommunicator-connected-to-DSC-Panel.svg'

export const PANEL_TYPE_UNKNOWN = 'Unknown'
export const PANEL_TYPE_PRO = 'Pro'
export const PANEL_TYPE_COMPLETE = 'Complete'
export const PANEL_TYPE_EXPRESS = 'Express'
export const PANEL_TYPE_POWER_MASTER_10 = 'PowerMaster 10'
export const PANEL_TYPE_POWER_MASTER_10_TRIPLE = 'PowerMaster 10 Triple'
export const PANEL_TYPE_POWER_MASTER_30 = 'PowerMaster 30'
export const PANEL_TYPE_POWER_G_PRO = 'PwrG-Pro'
export const PANEL_TYPE_POWER_MASTER_33 = 'PowerMaster 33'
export const PANEL_TYPE_MSP_2 = 'MSP-2'
export const PANEL_TYPE_POWER_MASTER_360 = 'PowerMaster 360'
export const PANEL_TYPE_POWER_MASTER_365 = 'PowerMaster 365'
export const PANEL_TYPE_POWER_MASTER_360R = 'PowerMaster 360R'
export const PANEL_TYPE_POWER_MASTER_33E = 'PowerMaster-33E'
export const PANEL_TYPE_NEO_1 = 'Neo 1'
export const PANEL_TYPE_HS3128 = 'HS3128'
export const PANEL_TYPE_HS2128 = 'HS2128'
export const PANEL_TYPE_HS2032 = 'HS2032'
export const PANEL_TYPE_HS2016 = 'HS2016'
export const PANEL_TYPE_HS2016_4 = 'HS2016_4'
export const PANEL_TYPE_HS2064 = 'HS2064'
export const PANEL_TYPE_HS3032 = 'HS3032'
export const PANEL_TYPE_HS2064E = 'HS2064E'
export const PANEL_TYPE_HS2128E = 'HS2128E'
export const PANEL_TYPE_HS3248 = 'HS3248'
export const PANEL_TYPE_FIBRO_TRANSMITTER = 'Fibro transmitter'
export const PANEL_TYPE_BGS220KIT = 'BGS220KIT' // DualPath
export const PANEL_TYPE_BGS220BOX = 'BGS220BOX' // DualPath
export const PANEL_TYPE_BLE320 = 'BLE320' // DualPath
export const PANEL_TYPE_BGS210 = 'BGS210' // DualPath
export const PANEL_TYPE_PG_BOX = 'PGBox'
export const PANEL_TYPE_IQ2 = 'IQ2'
export const PANEL_TYPE_IQ4 = 'IQ4'
export const PANEL_TYPE_QOLSYS = 'Qolsys'
export const PANEL_TYPE_VIRTUAL_PANEL = 'Virtual Panel'
export const PANEL_TYPE_SMART_COMMUNICATOR = 'SMARTCOMM_PANEL'
export const PANEL_TYPE_SMART_COMMUNICATOR_WITH_DSC = 'DSC_SMARTCOMM_PANEL'

const images = {
    [PANEL_TYPE_PRO]: iconPowerMaxPro,
    [PANEL_TYPE_POWER_G_PRO]: iconPowerMaxPro,
    [PANEL_TYPE_COMPLETE]: iconPowerMaster30,
    [PANEL_TYPE_POWER_MASTER_10]: iconPowerMaster10,
    [PANEL_TYPE_POWER_MASTER_10_TRIPLE]: iconPowerMaster10,
    [PANEL_TYPE_EXPRESS]: iconPowerMaster10,
    [PANEL_TYPE_POWER_MASTER_30]: iconPowerMaster30,
    [PANEL_TYPE_POWER_MASTER_33]: iconPowerMaster33e,
    [PANEL_TYPE_POWER_MASTER_33E]: iconPowerMaster33e,
    [PANEL_TYPE_POWER_MASTER_360]: iconPowerMaster360,
    [PANEL_TYPE_POWER_MASTER_360R]: iconPowerMaster360R,
    [PANEL_TYPE_NEO_1]: iconNeo1,
    [PANEL_TYPE_HS3128]: iconNeo1,
    [PANEL_TYPE_HS2128]: iconNeo1,
    [PANEL_TYPE_HS2032]: iconNeo1,
    [PANEL_TYPE_HS2016]: iconNeo1,
    [PANEL_TYPE_HS2064]: iconNeo1,
    [PANEL_TYPE_HS3032]: iconNeo1,
    [PANEL_TYPE_HS2064E]: iconNeo1,
    [PANEL_TYPE_HS2128E]: iconNeo1,
    [PANEL_TYPE_HS3248]: iconNeo1,
    [PANEL_TYPE_UNKNOWN]: iconUnknown,
    [PANEL_TYPE_MSP_2]: iconUnknown,
    [PANEL_TYPE_BGS220KIT]: iconDualPath,
    [PANEL_TYPE_BGS220BOX]: iconDualPath,
    [PANEL_TYPE_BLE320]: iconDualPath,
    [PANEL_TYPE_BGS210]: iconDualPath,
    [PANEL_TYPE_PG_BOX]: iconVirtualPanel,
    [PANEL_TYPE_QOLSYS]: iconIQ2,
    [PANEL_TYPE_IQ2]: iconIQ2,
    [PANEL_TYPE_IQ4]: iconIQ4,
    [PANEL_TYPE_VIRTUAL_PANEL]: iconVirtualPanel,
    [PANEL_TYPE_SMART_COMMUNICATOR]: iconSmartCommunicatorGeneric,
    [PANEL_TYPE_SMART_COMMUNICATOR_WITH_DSC]: iconSmartCommunicatorDsc,
}

export function panelName(model) {
    switch (model) {
        case PANEL_TYPE_PG_BOX:
            return __('Virtual Panel')
        case PANEL_TYPE_SMART_COMMUNICATOR:
            return __('Smart Communicator Panel')
        case PANEL_TYPE_SMART_COMMUNICATOR_WITH_DSC:
            return __('DSC Smart Communicator Panel')
        default:
            return model
    }
}

export function panelImage(model) {
    if (has(images, model)) {
        return images[model]
    }

    warn(`Unknown panel model ${model}`)

    return iconUnknown
}
