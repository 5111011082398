import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'

import __ from 'utils/i18n'

import AssignPanels from './AssignPanels'

export default function SecondBlindContent({row, setHeaderContent, setFooterContent}) {
    const {email} = row
    useEffect(() => {
        if (email) {
            setHeaderContent(() => (
                <Definition title={__('Assign panel')} detail={__('(%s)', email)} />
            ))
        }
    }, [email])

    return <AssignPanels user={row} setFooterContent={setFooterContent} />
}

SecondBlindContent.propTypes = {
    row: PropTypes.shape({
        email: PropTypes.string.isRequired,
    }).isRequired,
    setHeaderContent: PropTypes.func.isRequired,
    setFooterContent: PropTypes.func.isRequired,
}
