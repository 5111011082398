import React from 'react'
import {compose} from 'redux'

import selection from 'permissions/roles/selection'

import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'

import SelectionDropDown from 'components/SelectionDropDown'
import usePermission from 'hooks/usePermission'
import useRoles from 'hooks/pages/useRoles'

const SelectionDropDownVisibility = compose(
    withSelection(),
    withVisibility()
)(SelectionDropDown)

export default function RolesSelectionDropDown() {
    const roles = useRoles()
    const {isPermitted} = usePermission(selection)

    return <SelectionDropDownVisibility isVisible={isPermitted} {...roles} />
}
