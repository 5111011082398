import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withLoader from 'containers/withLoader'
import * as actions from 'modules/system/settings/actions'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card, {CardContent} from 'ipmp-react-ui/Card'

import BrokersTable from './BrokersTable'
import AddBrokerButton from './AddBrokerButton'
import Layout from 'ipmp-react-ui/Layout'

export class Brokers extends Component {
    render() {
        return (
            <Layout>
                <ScrollView>
                    <AddBrokerButton />
                    <Card className="brokers-settings-card">
                        <CardContent>
                            <BrokersTable />
                        </CardContent>
                    </Card>
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {messageBrokers},
            },
        }) => ({
            isLoading: messageBrokers.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    fetchMessageBrokers: actions.fetchMessageBrokers,
                },
                dispatch
            )
    ),
    withLoader(({fetchMessageBrokers}) => fetchMessageBrokers())
)(Brokers)
