import React, {Component} from 'react'
import PropTypes from 'prop-types'

import momentPropType from 'utils/momentPropType'
import {__} from 'utils/i18n'

import FullscreenButton from './FullscreenButton'
import Chart from './Chart'
import getTitle, {TEMPERATURE, BRIGHTNESS, GSM_RSSI} from 'constants/meteoTypes'

export default class Detector extends Component {
    static propTypes = {
        range: PropTypes.shape({
            from: momentPropType,
            to: momentPropType,
        }).isRequired,
        panelId: PropTypes.number.isRequired,
        deviceId: PropTypes.number.isRequired,
        type: PropTypes.oneOf([TEMPERATURE, BRIGHTNESS, GSM_RSSI]).isRequired,
    }

    getLabel() {
        const {type} = this.props

        return getTitle(type)
    }

    render() {
        const {range, panelId, deviceId, type} = this.props
        const label = this.getLabel()

        return (
            <div className="block">
                <h4 className="title">
                    {label}

                    <FullscreenButton
                        small
                        flat
                        label={__('Show')}
                        panelId={panelId}
                        deviceId={deviceId}
                        type={type}
                    />
                </h4>

                <div className="chart">
                    <Chart
                        label={label}
                        range={range}
                        panelId={panelId}
                        deviceId={deviceId}
                        type={type}
                    />
                </div>
            </div>
        )
    }
}
