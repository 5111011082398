import React, {useEffect} from 'react'

import PGMForm from 'components/PGM/PGMForm'
import usePGMForm from 'hooks/pages/usePGMForm'

export default function EditPGM(props) {
    const pgmForm = usePGMForm(props)
    const pgmProps = {...props, ...pgmForm}

    useEffect(() => {
        pgmProps.fetchPGMList()
        pgmProps.fetchPGMTypes()
    }, [])

    return <PGMForm {...pgmProps} />
}
