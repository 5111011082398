import React from 'react'

import {content} from 'permissions/panel/page'
import tabs from 'permissions/panel/navigation'
import {list as panelUsersList} from 'permissions/panel/customers/actions'

import usePanelPage from 'hooks/pages/usePanelPage'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'
import useFeatures from 'hooks/pages/useFeatures'
import {PanelPageLoader} from './PanelPageLoader'

export default function PanelPage(props) {
    const permissions = usePermission({
        isAllowed: content,
        allowedItems: {
            ...tabs,
        },
        isPanelUsersPagePermitted: panelUsersList,
    })
    const panel = usePanel()
    const features = useFeatures(panel.panelId)
    const pageProps = {...props, ...panel, ...features, ...permissions}
    const panelPageProps = usePanelPage(pageProps)

    return <PanelPageLoader {...{...pageProps, ...panelPageProps}} />
}
