import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {ReactComponent as IconHistory} from 'ipmp-react-ui/icons/history.svg'

import panelInfo from 'permissions/panel/page'
import assignedUnitUsers from 'permissions/interactiveUsers/recentHistoryAssignedUnitUsersList'
import __ from 'utils/i18n'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import withRoute from 'containers/withRoute'
import {showRecentHistoryNavBlind} from 'modules/navBlind/actions'
import {NAV_BLIND_RECENT_HISTORY} from 'modules/navBlind/constants'
import {selectRecentPanel} from 'modules/panels/recent/selectors'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'
import classnames from 'classnames'

const UserEmailComponent = ({recentUserMail, emailWidth}) => {
    const margin = 20
    const userEmail = () => {
        return isEmpty(recentUserMail) ? (
            __('Unassigned')
        ) : (
            <OverflowTextTooltip
                tooltip={recentUserMail}
                style={{maxWidth: emailWidth + margin + 'px'}}
            >
                {recentUserMail}
            </OverflowTextTooltip>
        )
    }
    return <div className="nav-link-content recentHistoryWidget-email">{userEmail()}</div>
}

const UserEmail = compose(
    withPermission({isVisible: assignedUnitUsers}),
    withVisibility()
)(UserEmailComponent)

const RecentWidget = ({
    path,
    recentPanelSerial,
    isRecentEmpty,
    recentUserMail,
    account,
    match,
    location,
}) => {
    if (isRecentEmpty) {
        return null
    }
    const active = match && match.path === location.pathname
    const serialElement = useRef(null)
    const accountElement = useRef(null)
    const [emailWidth, setEmailWidth] = useState(0)
    const minWidth = 160
    useEffect(() => {
        const titleWidth =
            serialElement.current.offsetWidth + accountElement.current.offsetWidth
        setEmailWidth(minWidth)

        if (titleWidth > minWidth) {
            setEmailWidth(titleWidth)
        }
    }, [account, recentPanelSerial])

    return (
        <Link
            to={path}
            className={classnames('nav-link-wrapper recentHistoryWidget', {
                'recentHistoryWidget-active': active,
            })}
        >
            <div className="nav-link-content iconHistory">
                <IconHistory className="nav-link-icon" />
            </div>
            <div className="recentHistoryWidget-title">
                <div
                    className="nav-link-content recentHistoryWidget-serial"
                    ref={serialElement}
                >
                    {recentPanelSerial}
                </div>
                {account && (
                    <div className="nav-link-content recentHistoryWidget-separator">
                        |
                    </div>
                )}
                <div
                    className="nav-link-content recentHistoryWidget-account"
                    ref={accountElement}
                >
                    {account}
                </div>
            </div>
            <UserEmail recentUserMail={recentUserMail} emailWidth={emailWidth} />
        </Link>
    )
}

RecentWidget.propTypes = {
    isRecentEmpty: PropTypes.bool,
    isRecentListOpened: PropTypes.bool.isRequired,
    showRecentHistoryNavBlind: PropTypes.func.isRequired,
    recentUserMail: PropTypes.string,
    recentPanelSerial: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
}

export default compose(
    withRouter,
    withPermission({isVisible: panelInfo}),
    withVisibility(),
    connect(
        ({navBlind: {type}, ...state}) => {
            const recentPanel = selectRecentPanel(state)
            return {
                recentUserMail: get(recentPanel, 'interactiveUser.email', ''),
                recentPanelSerial: get(recentPanel, 'panel.serial', ''),
                path: get(recentPanel, 'path', '/'),
                isRecentListOpened: type === NAV_BLIND_RECENT_HISTORY,
                isRecentEmpty: !recentPanel,
                account: get(recentPanel, 'panel.account', ''),
            }
        },
        (dispatcher) => bindActionCreators({showRecentHistoryNavBlind}, dispatcher)
    ),
    withRoute()
)(RecentWidget)
