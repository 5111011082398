import {post} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapProcess} from '../processes'
import {getEntityNumber} from 'constants/panelUsers'

export function setUserPin(data, batchId) {
    return post(`/paneluser/${data.panelId}/setpin`, {
        new_pin: data.code,
        user_id: getEntityNumber(data.id),
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch((e) => mapValidationErrors(e))
}

export function setPanelUserLabel({panelId, panelUserId, label}, batchId) {
    return post(`/paneluser/${panelId}/setlabel`, {
        user_id: getEntityNumber(panelUserId),
        name: label,
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch((e) => mapValidationErrors(e))
}

export function setPanelUserPartitions({panelId, panelUserId, partitions}, batchId) {
    return post(`/paneluser/${panelId}/setpartitions`, {
        user_id: getEntityNumber(panelUserId),
        partitions: partitions.sort((a, b) => Number(a) - Number(b)),
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch((e) => mapValidationErrors(e))
}
