import createListReducer, {
    generalDefaultState,
} from 'modules/higherOrder/createListReducer'
import * as actions from './actions'

const defaultState = {
    installerId: null,
}

export default createListReducer(
    actions,
    {
        [actions.fetch]: (state, {payload: {installerId}}) => {
            return {
                ...state,
                installerId: installerId || state.installerId,
            }
        },
        [actions.unassignPanel]: (state, {payload: installerId}) => {
            return {
                ...state,
                oldPage: state.page,
                oldTotal: state.total,
                total: state.total && state.total - 1,
                page: state.page.filter((id) => id !== installerId),
            }
        },
        [actions.assignPanel]: (state, {payload: panelId}) => {
            return {
                ...state,
                oldPage: state.page,
                oldTotal: state.total,
                total: state.total && state.total + 1,
                page: [...state.page, panelId],
            }
        },
        [actions.resetWithOutFetch]: () => {
            return {
                ...generalDefaultState,
                ...defaultState,
            }
        },
    },
    defaultState
)
