import React from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import withForm from 'containers/withForm'
import * as PropTypes from 'prop-types'
import Input from 'ipmp-react-ui/Input'

import {editHadLabel} from 'modules/forms/handlers'

const getRules = () => {
    return {
        label: {
            length: {
                maximum: 28,
            },
        },
    }
}

const EditLabel = ({handle, panelId, hadId, deviceType, defaultValue, ...props}) => {
    const onSubmit = ({label}) => {
        const payload = {panelId, hadId, deviceType, label}
        handle(payload)
    }
    return (
        <ModalCardForm
            onSubmit={onSubmit}
            confirmOnDismiss
            rules={getRules()}
            header={__('Edit output label')}
            {...props}
        >
            <Input label={__('Label')} name="label" defaultValue={defaultValue} />
        </ModalCardForm>
    )
}

EditLabel.propTypes = {
    handle: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
    hadId: PropTypes.number.isRequired,
    deviceType: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
}

export default withForm(editHadLabel)(EditLabel)
