import {createSelector} from 'reselect'
export const defaultPerPage = 50

export function selectPerPage(store, key) {
    return store.settings.perPage[key] || defaultPerPage
}

export const selectTemperatureScale = createSelector(
    (state) => state?.settings?.temperature,
    (temperature) => temperature
)

export const selectTheme = createSelector(
    (state) => state?.settings?.theme,
    (theme) => theme
)
