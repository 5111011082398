import {useSelector} from 'react-redux'

import {PROTOCOL_DLS} from 'constants/connections'
import {isConnectButtonAllowed, isDisconnectButtonAllowed} from 'utils/dlsConnection'
import usePermission from 'hooks/usePermission'
import {connect, disconnect} from 'permissions/panel/devices/dls/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {dlsDisconnect} from 'modules/devices/list/actions'
import {
    showDscConnectionFormModal,
    showSmartCommunicatorConnectionFormModal,
} from 'modules/modals/actions'
import {VENDOR_NEO, VENDOR_SMART_COMMUNICATOR} from 'constants/panelVendorType'

export function useDlsConnection(name, connection, panelId) {
    if (name !== PROTOCOL_DLS) {
        return {}
    }

    const {dlsPathThrough} = useSelector(({features}) => features.store.byIds[panelId])
    const {vendor} = useSelector(({panels}) => panels.store.byIds[panelId])
    const {isGrantedConnect, isGrantedDisconnect} = usePermission({
        isGrantedConnect: connect,
        isGrantedDisconnect: disconnect,
    })

    const {disconnectHandler} = useActions(
        {
            disconnectHandler: () => dlsDisconnect(panelId),
        },
        [panelId]
    )

    const getDlsConnectAction = () => {
        const showModals = useActions({
            dscModal: showDscConnectionFormModal,
            smartCommunicatorModal: showSmartCommunicatorConnectionFormModal,
        })
        switch (vendor) {
            case VENDOR_NEO:
                return () => showModals.dscModal(panelId)
            case VENDOR_SMART_COMMUNICATOR:
                return () => showModals.smartCommunicatorModal(panelId)
            default:
                return null
        }
    }

    const connectHandler = getDlsConnectAction()

    const isConnectAllow =
        isGrantedConnect && isConnectButtonAllowed(connection, dlsPathThrough)
    const isDisconnectAllow =
        isGrantedDisconnect && isDisconnectButtonAllowed(connection, dlsPathThrough)

    return {
        isConnectAllow,
        isDisconnectAllow,
        connectHandler,
        disconnectHandler,
    }
}
