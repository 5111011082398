import React from 'react'
import {QUAZAR_5_3} from 'constants/groups'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'
import {__} from 'utils/i18n'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import _get from 'lodash-es/get'
import Input from 'ipmp-react-ui/Input'
import {TabContentWrapper} from './TabContentWrapper'
import classes from 'classnames'

const Quazar53 = ({activeTab, handleFieldChanged, quazar53Communications, errors}) => {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={QUAZAR_5_3}
            styleName={'communications-content'}
        >
            <FormSection
                className={classes('col', {
                    'group-form-section-with-error':
                        errors?.quazar53GprsSupervision || errors?.quazar53GprsTimeout,
                })}
                title={__('Cellular')}
            >
                <Togglebox
                    name="quazar53GprsIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="quazar53-gprs-is-enabled"
                    defaultChecked={_get(quazar53Communications, 'gprs.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.quazar53.gprs.isEnabled',
                            e.target.checked
                        )
                    }}
                />

                <FormRow>
                    <Input
                        name="quazar53GprsSupervision"
                        label={__('Supervision Period')}
                        dataTestId="quazar53-gprs-supervision"
                        type="number"
                        defaultValue={_get(
                            quazar53Communications,
                            'gprs.supervision',
                            false
                        )}
                        disabled={!_get(quazar53Communications, 'gprs.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.quazar53.gprs.supervision',
                                Number(e.target.value)
                            )
                        }}
                    />
                    <Input
                        name="quazar53GprsTimeout"
                        label={__('Offline Timer')}
                        dataTestId="quazar53-gprs-timeout"
                        type="number"
                        defaultValue={_get(quazar53Communications, 'gprs.timeout', false)}
                        disabled={!_get(quazar53Communications, 'gprs.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.quazar53.gprs.timeout',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
            <FormSection className="col" title={__('Broadband')}>
                <Togglebox
                    name="quazar53BbaIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="quazar53-bba-is-enabled"
                    defaultChecked={_get(quazar53Communications, 'bba.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.quazar53.bba.isEnabled',
                            e.target.checked
                        )
                    }}
                />
                <FormRow>
                    <Input
                        name="quazar53BbaSupervision"
                        label={__('Supervision Period')}
                        dataTestId="quazar53-bba-supervision"
                        type="number"
                        defaultValue={_get(
                            quazar53Communications,
                            'bba.supervision',
                            false
                        )}
                        disabled={!_get(quazar53Communications, 'bba.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.quazar53.bba.supervision',
                                Number(e.target.value)
                            )
                        }}
                    />

                    <Input
                        name="quazar53BbaTimeout"
                        label={__('Offline Timer')}
                        dataTestId="quazar53-bba-timeout"
                        type="number"
                        defaultValue={_get(quazar53Communications, 'bba.timeout', false)}
                        disabled={!_get(quazar53Communications, 'bba.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.quazar53.bba.timeout',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
        </TabContentWrapper>
    )
}

export default Quazar53
