import React from 'react'
import {connect} from 'react-redux'

import Select, {Option} from 'ipmp-react-ui/Select'
import Button from 'ipmp-react-ui/Button'
import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Portal from 'ipmp-react-ui/Portal'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import __ from 'utils/i18n'

import AddEventType from 'modals/EventType/AddEventType'
import ContentTemplate from 'modals/EventRule/Fieldset/Content/ContentTemplate'
import {EVENT_TYPE_NAME_RESTORE} from 'constants/eventRules'
import get from 'lodash-es/get'
import {selectAllEventTypes} from 'modules/eventTypes/store/selectors'

class EventType extends ContentTemplate {
    labelDataKey = 'description'

    renderModal = () => (
        <Portal>
            <AddEventType hide={this.onHideModal} />
        </Portal>
    )

    get checkBoxValue() {
        const {allData} = this.context

        if (allData[EVENT_TYPE_NAME_RESTORE]) {
            return allData[EVENT_TYPE_NAME_RESTORE]
        }

        return null
    }

    get checkBoxDefaultChecked() {
        return this.checkBoxValue === 'on'
    }

    render() {
        const {data} = this.props
        const {isModalOpened} = this.state

        return (
            <div className="eventRules-master-content">
                <Disclaimer className="eventRules-master-content-disclaimer">
                    {__(
                        'SIA or CID code that will be generated by Virtual Panel and reported to Central Station, if any.'
                    )}
                </Disclaimer>
                <Select
                    name={this.selectName}
                    defaultValue={this.value}
                    defaultLabel={this.label}
                    label={__('Event')}
                >
                    {data &&
                        data.length &&
                        data.map(({id, description}) => (
                            <Option key={id} label={description} value={id} />
                        ))}
                </Select>
                <Bar orientation={RIGHT} className="eventRules-master-content-bar">
                    <Checkbox
                        className="eventRules-master-content-checkbox"
                        name={EVENT_TYPE_NAME_RESTORE}
                        label={__('Event Restore')}
                        defaultChecked={this.checkBoxDefaultChecked}
                    />
                    <Button
                        className="eventRules-master-content-addButton"
                        Icon={IconPlus}
                        borderless
                        onClick={this.onOpenModal}
                    >
                        {__('Add Event')}
                    </Button>
                </Bar>
                {isModalOpened && this.renderModal()}
            </div>
        )
    }
}

export default connect((store) => ({
    ...selectAllEventTypes(store),
    isFormSuccess: get(store, ['forms', 'addEventType', 'success'], false),
}))(EventType)
