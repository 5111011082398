import {PARTITION_ALL} from 'constants/partitions'
import React, {useCallback, useState} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Tabs from 'ipmp-react-ui/Tabs'
import {SideBar} from 'ipmp-react-ui/Layout'
import Card, {CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'

import {__} from 'utils/i18n'
import {withPermission, withRejection} from 'containers/withPermission'
import sidebar from 'permissions/panel/state/sidebar'
import list from 'permissions/panel/devices/list'
import {
    selectBypassableDevices,
    selectBypassableDevicesByPartition,
} from 'modules/devices/list/selectors'
import withLoader from 'containers/withLoader'
import {bypass} from 'permissions/panel/devices/actions'
import PanelStateBypassDevices from 'pages/Panel/PanelState/SideBar/PanelStateBybassDevices'
import ByPassButtonBlock from 'pages/Panel/PanelState/SideBar/ByPassButtonBlock'
import PanelStateSidebarTitle from 'pages/Panel/PanelState/SideBar/PanelStateSidebarTitle'
import PanelStateSidebarBypassAll from 'pages/Panel/PanelState/SideBar/PanelStateSidebarBypassAll'
import {openedBypassableDeviceFilter} from 'constants/device'
import {MOTION_TROUBLE} from 'constants/troubleType'

const filterBypassState = (deviceBypassStates: Map, deviceId) => {
    const newDeviceBypassStates = new Map(deviceBypassStates)
    newDeviceBypassStates.delete(deviceId)
    return newDeviceBypassStates
}

const PanelStateSideBar = ({
    onClose,
    devices,
    isBypassAllowed,
    panelId,
    partition,
    partitions,
    onSelectPartition,
}) => {
    const [deviceBypassStates, setDeviceBypassStates] = useState(new Map())
    const currentIndex = partitions.findIndex(
        (partitionPassed) => partitionPassed.id === partition.id
    )
    const prevIndex = currentIndex - 1
    const nextIndex = currentIndex + 1

    const prevId = partitions[prevIndex]?.id
    const nextId = partitions[nextIndex]?.id

    const onBypassChange = useCallback((deviceId, enabled) => {
        if (!deviceBypassStates.has(deviceId)) {
            const newDeviceBypassStates = new Map(deviceBypassStates)
            newDeviceBypassStates.set(deviceId, enabled)
            setDeviceBypassStates(newDeviceBypassStates)
        } else if (enabled !== deviceBypassStates.get(deviceId)) {
            setDeviceBypassStates(filterBypassState(deviceBypassStates, deviceId))
        }
    })

    const onBypassAll = (devices, checked) => {
        const newDeviceBypassStates = new Map()

        if (checked) {
            devices.forEach((device) => {
                if (!device?.traits?.bypass?.enabled) {
                    newDeviceBypassStates.set(device.id, true)
                }
            })
        }

        setDeviceBypassStates(newDeviceBypassStates)
    }

    const selectPrevItem = () => {
        onSelectPartition({id: prevId})
    }
    const selectNextItem = () => {
        onSelectPartition({id: nextId})
    }

    const openedDevices = devices.filter(openedBypassableDeviceFilter)
    const troubledDevices = devices.filter(
        (device) =>
            device.hasTroubles &&
            device.warnings.find((warning) => warning.type !== MOTION_TROUBLE)
    )

    const selectedToEnableCount = Array.from(deviceBypassStates.values()).filter(
        Boolean
    ).length
    const selectedToDisableCount = Array.from(deviceBypassStates.values()).filter(
        (enabled) => !enabled
    ).length

    return (
        <SideBar className="partitionDevices-sidebar" filled>
            <Card>
                <CardHeader>
                    <Buttons flatShadowed>
                        <Button
                            disabled={currentIndex === 0}
                            onClick={selectPrevItem}
                            Icon={IconPrev}
                        />
                        <Button
                            disabled={currentIndex === partitions.length - 1}
                            onClick={selectNextItem}
                            Icon={IconNext}
                        />
                    </Buttons>
                    <PanelStateSidebarTitle partition={partition} panelId={panelId} />
                    <CardClose onClick={onClose} />
                </CardHeader>

                <CardContent>
                    <Tabs>
                        <PanelStateBypassDevices
                            name={__('All Bypassable Devices')}
                            errorTitle={__('No Bypassable Devices')}
                            devices={devices}
                            deviceBypassStates={deviceBypassStates}
                            isBypassAllowed={isBypassAllowed}
                            onBypassChange={onBypassChange}
                            ByPassAll={
                                <PanelStateSidebarBypassAll
                                    selectedToEnableCount={selectedToEnableCount}
                                    selectedToDisableCount={selectedToDisableCount}
                                    isBypassAllowed={isBypassAllowed}
                                    onBypassChange={(e) =>
                                        onBypassAll(troubledDevices, e.target.checked)
                                    }
                                    deviceBypassStates={deviceBypassStates}
                                    devices={troubledDevices}
                                    label={__('Bypass with troubles')}
                                />
                            }
                        />
                        <PanelStateBypassDevices
                            name={__('Opened Bypassable Devices')}
                            errorTitle={__('No opened bypassable devices')}
                            className="partitionDevices-sidebar-openedDevices"
                            devices={openedDevices}
                            deviceBypassStates={deviceBypassStates}
                            isBypassAllowed={isBypassAllowed}
                            onBypassChange={onBypassChange}
                            ByPassAll={
                                <PanelStateSidebarBypassAll
                                    selectedToEnableCount={selectedToEnableCount}
                                    selectedToDisableCount={selectedToDisableCount}
                                    isBypassAllowed={isBypassAllowed}
                                    onBypassChange={(e) =>
                                        onBypassAll(openedDevices, e.target.checked)
                                    }
                                    deviceBypassStates={deviceBypassStates}
                                    devices={openedDevices}
                                />
                            }
                        />
                    </Tabs>
                </CardContent>

                {list &&
                    (Boolean(selectedToEnableCount) ||
                        Boolean(selectedToDisableCount)) && (
                        <ByPassButtonBlock
                            panelId={panelId}
                            deviceBypassStates={deviceBypassStates}
                            resetSelection={() => setDeviceBypassStates(new Map())}
                        />
                    )}
            </Card>
        </SideBar>
    )
}

PanelStateSideBar.propTypes = {
    partition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    isAbstract: PropTypes.bool,
    devices: PropTypes.array.isRequired,
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func,
}

export default compose(
    withPermission({isAllowed: sidebar, isBypassAllowed: bypass}),
    withRejection(),
    connect((state, {panelId, partition}) => {
        const devices =
            partition.id === PARTITION_ALL
                ? selectBypassableDevices(state, panelId)
                : selectBypassableDevicesByPartition(state, panelId, partition.id)

        return {
            devices,
            isLoading: !devices,
        }
    }),
    withLoader()
)(PanelStateSideBar)
