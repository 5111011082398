import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {stations} from 'permissions/group/navigation'

import {showEditCentralStationLinksModal} from 'modules/modals/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as EditIcon} from 'ipmp-react-ui/icons/edit.svg'
import Button from 'ipmp-react-ui/Button'

export default compose(
    withPermission({isVisible: stations}),
    withVisibility(),
    connect(null, (dispatch, {groupId, stationId}) => (dispatch) => {
        return bindActionCreators(
            {
                onClick: () => showEditCentralStationLinksModal(groupId, stationId),
            },
            dispatch
        )
    }),
    withProps(() => ({
        Icon: EditIcon,
        flat: true,
        borderless: true,
    }))
)(Button)
