import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import {edit} from 'permissions/centralStations/actions'
import list from 'permissions/centralStations/list'
import selection from 'permissions/centralStations/selection'

import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import {withRejection} from 'containers/withPermission'

import EditCell from './TableCell/EditCell'
import CommunicationCell from './TableCell/CommunicationCell'

import {__} from 'utils/i18n'
import path from 'utils/path'
import CentralStationStatus from 'components/CentralStations/CentralStationStatus'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/pages/useCentralStations'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

const allColumns = [
    {
        name: () => __('Name'),
        render: ({name}) => {
            return name
        },
    },
    {
        name: () => __('Status'),
        render: ({status}) => <CentralStationStatus status={status} />,
    },
    {
        name: () => __('Protocol'),
        width: 100,
        maxWidth: 220,
        render: ({protocol}) => protocol,
    },
    {
        name: () => __('Communication'),
        width: 220,
        component: CommunicationCell,
    },
    {
        tooltip: false,
        permission: edit,
        width: 50,
        fixed: true,
        component: EditCell,
    },
]

const ConnectedTable = compose(
    withRejection(),
    withSelection(),
    withLoader(({init}) => init())
)(Table)

export default function CentralStationsTable({history}) {
    const permissions = usePermission({
        isAllowed: list,
        hasSelection: selection,
    })
    const centralStations = useCentralStations()
    const {columns} = useTableColumnsPermission(allColumns)
    const props = {...permissions, ...centralStations}

    return (
        <ConnectedTable
            {...props}
            fullHeight
            columns={columns}
            emptyMessage={() => __('No stations found')}
            onRowClick={({id}) => history.push(path('system.centralStation', {id}))}
            className="central-stations-table"
        />
    )
}
