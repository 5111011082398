import React, {Component} from 'react'
import PropTypes from 'prop-types'

import ConfigurationList from 'components/Configuration/ConfigurationList'
import ConfigurationTableOfContents from 'components/Configuration/ConfigurationTableOfContents'
import {dropRight} from 'lodash'
import Layout, {Content, ScrollView, SideBar} from 'ipmp-react-ui/Layout'
import {__} from 'utils/i18n'
import {CONFIGURATION_SEPARATOR} from 'constants/panelConfiguration'

export default class ConfigurationLayout extends Component {
    static propTypes = {
        sections: PropTypes.array.isRequired,
        header: PropTypes.element,
        filters: PropTypes.element,
    }

    state = {}

    handleBindMoveTo = (handler) => (this.moveTo = handler)

    handleSelect = (selectedKey) => {
        let key = selectedKey
        if (key === this.state.key) {
            const selectedPath = selectedKey.split(CONFIGURATION_SEPARATOR)
            key = dropRight(selectedPath).join(CONFIGURATION_SEPARATOR)
        }
        this.setState({key})
        this.moveTo && this.moveTo(key)
    }

    handleMove = (key) => {
        if (this.state.key !== key) {
            this.setState({key})
        }
    }

    renderEmptyMessage = () => {
        const {isCompareWithBackup, showChanged, showExportable} = this.props

        switch (true) {
            case isCompareWithBackup:
                return __(
                    'There are no configuration elements that are different in comparison with backup'
                )
            case showChanged:
                return __('There are no changed configuration elements')
            case showExportable:
                return __('There ara no exportable configuration elements')
            default:
                return __('Configuration elements not found')
        }
    }

    render() {
        const {header, filters, sections, ...props} = this.props

        return (
            <Layout className="configuration">
                <Layout vertical>
                    {header && <div className="configuration-header">{header}</div>}

                    {sections.length ? (
                        <ConfigurationList
                            onMove={this.handleMove}
                            bindMoveTo={this.handleBindMoveTo}
                            sections={sections}
                            {...props}
                        />
                    ) : (
                        <div className="empty-message">{this.renderEmptyMessage()}</div>
                    )}
                </Layout>

                <SideBar>
                    <Content>{filters}</Content>

                    {sections.length > 0 && (
                        <div className="hint">{__('Table of contents')}</div>
                    )}

                    {sections.length > 0 && (
                        <ScrollView>
                            <ConfigurationTableOfContents
                                onSelect={this.handleSelect}
                                nodes={sections}
                                highlightKey={this.state.key}
                            />
                        </ScrollView>
                    )}
                </SideBar>
            </Layout>
        )
    }
}
