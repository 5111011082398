import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class RemoteInspection extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            doSendEmailOnSuccess: PropTypes.bool,
            doGenerateResultEvent: PropTypes.bool,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <>
                {this.renderListItem(
                    __('Send Email to the Customer of Succeed RI'),
                    data.doSendEmailOnSuccess
                )}
                {this.renderListItem(
                    __("Generate 'Succeed/Failed RI' Event"),
                    data.doGenerateResultEvent
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {remoteInspection},
            },
        }) => ({...remoteInspection})
    )
)(RemoteInspection)
