import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {PARTITION_ALL} from 'constants/partitions'
import __ from 'utils/i18n'
import SetLabelButton from 'pages/Panel/PanelState/Buttons/SetLabelButton'

const PanelStateSidebarTitle = ({partition, panelId}) => {
    if (partition.id === PARTITION_ALL) {
        return <h3 className="partitionDevices-sidebar-title">{__('All')}</h3>
    }

    return (
        <>
            <h3 className="partitionDevices-sidebar-title">{partition.name}</h3>
            <SetLabelButton
                flatShadowed
                Icon={IconEdit}
                className="partitionDevices-sidebar-setLabelBtn"
                partition={partition}
                panelId={panelId}
            >
                {__('Rename')}
            </SetLabelButton>
        </>
    )
}

PanelStateSidebarTitle.propTypes = {
    partition: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    panelId: PropTypes.number.isRequired,
}

export default PanelStateSidebarTitle
