import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import withNamedError from 'ipmp-react-ui/withNamedError'
import formatError from 'ipmp-react-ui/_utils/formatError'

import {hadConfigurableIcons} from 'constants/had'

import EditIconListItem from './EditIconListItem'

function EditIconList({defaultValue, onChange, error}) {
    const [selectedIcon, setSelectedIcon] = useState(defaultValue)

    function onClickHandler(icon) {
        setSelectedIcon(icon)
        onChange && onChange(icon)
    }

    return (
        <div
            className={classnames('modalFormEditHadIcon-list', {
                'form-field--error': !!error,
            })}
        >
            {Object.keys(hadConfigurableIcons).map((key) => (
                <EditIconListItem
                    key={key}
                    icon={key}
                    isActive={selectedIcon === key}
                    onClick={useCallback(onClickHandler, [])}
                />
            ))}
            <input hidden name="icon" value={selectedIcon} onChange={() => {}} />
            {error && <span className="form-field-error">{formatError(error)}</span>}
        </div>
    )
}

EditIconList.propTypes = {
    defaultValue: PropTypes.string,
    error: PropTypes.any,
    onChange: PropTypes.func,
}

export default withNamedError(EditIconList)
