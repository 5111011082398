import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {Partitions} from 'components/Partitions'

import SetPartitionsButton from './SetPartitionsButton'
import Row from '../Row'

export default function PartitionsRow({user: {id, isPinSet, partitions, role}}) {
    const panelId = useRouterPanelId()

    return (
        <Row
            value={<Partitions partitions={partitions || []} />}
            title={__('Partitions')}
            missingTitle={__('No Partitions')}
            EditButton={
                <SetPartitionsButton
                    panelId={panelId}
                    panelUserId={id}
                    partitions={partitions}
                    isPinSet={isPinSet}
                    role={role}
                />
            }
        />
    )
}

PartitionsRow.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isPinSet: PropTypes.bool.isRequired,
        role: PropTypes.string,
        partitions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    }),
}
