import usePerPage from '../usePerPage'
import {useDispatch, useSelector} from 'react-redux'
import {
    selectEventsListRows,
    selectEventsOverBoundRows,
} from 'modules/events/list/selectors'
import {get} from 'lodash-es'
import {bindActionCreators} from 'redux'
import {
    fetch,
    fetchNewer,
    fetchOverbound,
    init,
    nextOverbound,
    prevOverbound,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/events/list/actions'
import {setFirstPageStreamable} from 'modules/events/store/actions'
import {markAsViewed} from 'modules/events/one/actions'

export default function useEvents({match} = {}) {
    const id = match && match.params && match.params.id
    const centralStations = useSelector((state) => state.centralStations.store.byIds)
    const dispatch = useDispatch()
    const perPage = usePerPage('events')
    const {store, list} = useSelector(({events}) => events)

    const {start, total, error, isLoading, newerCount} = list
    const {byIds} = store

    return {
        ...perPage,
        ...bindActionCreators(
            {
                init,
                reset,
                fetch,
                fetchNewer,
                fetchOverbound,
                nextOverbound,
                prevOverbound,
                onPageChange: setStart,
                setFirstPageStreamable,
                markAsViewed,
                startPoll,
                stopPoll,
            },
            dispatch
        ),
        start,
        total,
        error,
        newerCount,
        centralStations,
        isFirstPageStreamable: store.isFirstPageStreamable,
        isLoading,
        rows: useSelector(selectEventsListRows),
        row: get(byIds, [id], {}),
        overbound: useSelector(selectEventsOverBoundRows),
    }
}
