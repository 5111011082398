import React from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import withForm from 'containers/withForm'
import * as PropTypes from 'prop-types'

import {setPanelUserPartitions} from 'modules/forms/handlers'
import PartitionsMultiSelect from 'utils/panel/PartitionsMultiSelect'
import {useSelector} from 'react-redux'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {DEFAULT_PARTITION_VALUE, preparePartitionName} from 'constants/partitions'

const rules = {
    partitions: {
        presence: {
            message: () => __('You should define partitions'),
        },
    },
}

const SetUserPartitions = ({handle, panelId, panelUserId, partitions, ...props}) => {
    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))
    const maxPartitions = features?.partitions?.maxPartitions || 1
    const onHandle = ({partitions}) => {
        handle({panelId, panelUserId, partitions})
    }

    const panelPartitions = useSelector(
        ({panels: {state}}) => state.byIds[panelId].partitions
    )

    let defaultPartitions = Object.keys(panelPartitions)
        .filter((panelPartition) => {
            return partitions.includes(panelPartitions[panelPartition].id.toString())
        })
        .map((partition) => preparePartitionName(panelPartitions[partition]?.id))
    if (defaultPartitions.length === 0) {
        defaultPartitions = [preparePartitionName(DEFAULT_PARTITION_VALUE)]
    }

    return (
        <ModalCardForm
            onSubmit={onHandle}
            confirmOnDismiss
            rules={rules}
            header={__('Set user partitions')}
            {...props}
        >
            <PartitionsMultiSelect
                label={__('Partitions')}
                name="partitions"
                panelId={panelId}
                hasSelectAll
                maxSelectOptions={maxPartitions}
                defaultValues={defaultPartitions}
            />
        </ModalCardForm>
    )
}

SetUserPartitions.propTypes = {
    handle: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}

export default withForm(setPanelUserPartitions)(SetUserPartitions)
