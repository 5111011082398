import {all} from 'redux-saga/effects'
import list from './list/saga'
import one from './one/saga'
import store from './store/saga'
import licensing from './licensing/saga'
import groupsList from './groupsList/saga'

export default function* () {
    yield all([list(), one(), store(), licensing(), groupsList()])
}
