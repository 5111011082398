import {compose} from 'redux'

import list from 'permissions/groups/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'

export default compose(withPermission({isVisible: list}), withVisibility())(Search)
