import moment from 'moment'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Modal from 'ipmp-react-ui/Modal'

import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import ChartComponent from 'components/Devices/Chart'
import Error from 'ipmp-react-ui/Error'
import InputDatePicker from 'ipmp-react-ui/InputDatePicker'
import Spinner from 'ipmp-react-ui/Spinner'

import {__} from 'utils/i18n'
import getTitle from 'constants/meteoTypes'
import useDeviceMeteo from 'hooks/pages/useDeviceMeteo'

const Chart = (props) => {
    const meteo = useDeviceMeteo(props)
    const chartProps = {...props, ...meteo}

    return <ChartComponent {...chartProps} />
}

class ShowDeviceMeteoChart extends Component {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    }

    state = {
        range: {
            from: moment().subtract(1, 'week').startOf('day'),
            to: moment(),
        },
        left: 0,
    }

    constructor(props) {
        super(props)

        this.refreshPresets(props)
    }

    refreshPresets({isLoading, data}) {
        if (isLoading || !data || !data.length) {
            return
        }

        const min = data.reduce(
            (min, {timestamp}) => Math.min(min, timestamp),
            moment().unix()
        )

        this.presets = [
            {
                from: moment().subtract(1, 'day').startOf('day'),
                to: moment(),
                label: __('Last day'),
            },
            {
                from: moment().subtract(1, 'week').startOf('day'),
                to: moment(),
                label: __('Last week'),
            },
            {
                from: moment().subtract(1, 'month').startOf('day'),
                to: moment(),
                label: __('Last month'),
            },
            {
                from: moment.unix(min),
                to: moment(),
                label: __('All'),
            },
        ]
    }

    getTitle() {
        const {type} = this.props

        return getTitle(type)
    }

    handleRangeChange = (from, to) => {
        if (!from) {
            return
        }

        this.setState({
            range: {
                from,
                to,
            },
        })
    }

    handlePreset = (from, to) => {
        this.setState({
            range: {
                from,
                to,
            },
        })
    }

    renderContent() {
        const {isLoading, data} = this.props
        const {range} = this.state

        if (isLoading) {
            return <Spinner />
        }

        if (!data || !data.length) {
            return <Error title={__('No data')} />
        }

        return (
            <div>
                <Bar>
                    <InputDatePicker
                        range
                        clearable={false}
                        value={range}
                        onChange={this.handleRangeChange}
                    />

                    <BarSpace />

                    <Buttons flat>
                        {this.presets.map(({from, to, label}) => (
                            <Button
                                key={from.unix() + '$' + to.unix()}
                                active={
                                    from.isSame(range.from, 'day') &&
                                    to.isSame(range.to, 'day')
                                }
                                onClick={() => this.handlePreset(from, to)}
                            >
                                {label}
                            </Button>
                        ))}
                    </Buttons>
                </Bar>

                <div className="chart">
                    <Chart range={range} {...this.props} />
                </div>
            </div>
        )
    }

    render() {
        const {hide} = this.props
        this.refreshPresets(this.props)

        return (
            <Modal onClose={hide}>
                <Card className="card--wide-3 chartModal">
                    <CardClose onClick={hide} />

                    <CardHeader>{this.getTitle()}</CardHeader>

                    <CardContent>{this.renderContent()}</CardContent>

                    <CardActions>
                        <Button borderless onClick={hide}>
                            {__('Close')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}

export default connect((state, {panelId, deviceId, type}) =>
    get(state.devices.meteo, `${panelId}.${deviceId}.${type}`, {
        isLoading: true,
    })
)(ShowDeviceMeteoChart)
