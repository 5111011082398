import React from 'react'

import {initiate} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'

import InitRRIBase from 'components/Cell/RemoteInspections/Actions/InitRRI'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const InitRRIVisibility = withVisibility()(InitRRIBase)

export default function Initiate(props) {
    const {isPermitted} = usePermission(initiate)
    const remoteInspections = useRemoteInspections()
    const componentProps = {...props, ...remoteInspections}

    return <InitRRIVisibility {...componentProps} isVisible={isPermitted} />
}
