import React from 'react'
import PropTypes from 'prop-types'

import DoubleBlindWithPagination from 'ui/DoubleBlindModalWithPagination'

import FirstBlindContent, {FirstBlindContentInPanel} from './FirstBlindContent'
import SecondBlindContent, {SecondBlindContentInPanel} from './SecondBlindContent'

export default function Blind({rows, current, onChange, onClose, isPanelMode = false}) {
    return (
        <DoubleBlindWithPagination
            rows={rows}
            current={current}
            onChange={onChange}
            closeBlind={onClose}
            FirstBlindContent={isPanelMode ? FirstBlindContentInPanel : FirstBlindContent}
            SecondBlindContent={
                isPanelMode ? SecondBlindContentInPanel : SecondBlindContent
            }
        />
    )
}

Blind.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    current: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isPanelMode: PropTypes.bool,
}
