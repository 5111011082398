import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {showExportConfigurationModal} from 'modules/modals/actions'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'

import {__} from 'utils/i18n'

class ExportButton extends Component {
    static propTypes = {
        configuration: PropTypes.object,
        configurationId: PropTypes.number,
        showModal: PropTypes.func.isRequired,
    }

    render() {
        return (
            <Button Icon={IconDownload} flat onClick={this.props.showModal}>
                {__('Export')}
            </Button>
        )
    }
}

export default compose(
    connect(null, (dispatch, {configurationId, panelId, configuration}) =>
        bindActionCreators(
            {
                showModal: () =>
                    showExportConfigurationModal(configurationId, panelId, configuration),
            },
            dispatch
        )
    )
)(ExportButton)
