import dataMapper from 'utils/dataMapper'
import {
    INSTALLER_TYPE_MASTER,
    INSTALLER_TYPE_INSTALLER,
    INSTALLER_TYPE_DEALER,
    MASTER_INSTALLER,
    INSTALLER,
    DEALER,
    getInstallerRoleTitle,
} from 'constants/installersRoles'
import {USER_FEATURE_CODE_LENGTH, USER_FEATURE_EDITABLE} from 'constants/features'

export const getInstallersTypes = (types) => {
    const installerTypes = []
    types.map((type) => {
        switch (type) {
            case INSTALLER_TYPE_MASTER:
                installerTypes.push({
                    id: type,
                    role: getInstallerRoleTitle(MASTER_INSTALLER),
                })
                break
            case INSTALLER_TYPE_INSTALLER:
                installerTypes.push({id: type, role: getInstallerRoleTitle(INSTALLER)})
                break
            case INSTALLER_TYPE_DEALER:
                installerTypes.push({id: type, role: getInstallerRoleTitle(DEALER)})
                break
        }
    })
    return installerTypes
}

const mapUserFeatures = (user) => ({
    [USER_FEATURE_EDITABLE]: user ? user.editable_features : [],
    [USER_FEATURE_CODE_LENGTH]: user ? user.code_lengths : [],
})

export const mapFeatures = dataMapper((features) => {
    const {installer, user} = features
    const installerTypes = getInstallersTypes(installer.installer_types)
    return {
        ...features,
        installer: {
            installerTypes,
            codeLengths: installer.code_lengths,
            editableFeatures: installer.editable_features,
        },
        user: mapUserFeatures(user),
    }
})
