import {put, call} from 'redux-saga/effects'
import {getGoogleSettings} from 'api/system/settings'

import * as actions from 'modules/system/settings/actions'
import * as serverSettingsActions from 'modules/serverSettings/actions'
import {snackShow} from 'modules/snacks'

export default function* watchGoogleSettings() {
    try {
        const settings = yield call(getGoogleSettings)
        yield put(serverSettingsActions.update({google: settings}))
        yield put(actions.updateGoogleSettings(settings))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
