import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import withPanelInfo from 'containers/withPanelInfo'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

export const EditPanelInfoButtonWithText = compose(
    withPanelInfo(),
    withProps(({editPanelInfo}) => ({
        onClick: editPanelInfo,
        primary: true,
        label: __('No Panel Info'),
        Icon: IconPlus,
    }))
)(Button)

export default compose(
    withPanelInfo(),
    withProps(({editPanelInfo}) => ({
        onClick: editPanelInfo,
        borderless: true,
        Icon: IconEdit,
    }))
)(Button)
