import {__} from 'utils/i18n'

export default class {
    constructor(lines) {
        this.rows = []
        this.errors = []

        const serials = {}

        lines.map((line, idx) => {
            const row = new Row(line)

            if (row.validate()) {
                if (serials[row.serial.toLowerCase()]) {
                    this.errors.push(
                        __('Duplicate panel id %s at line %s', row.serial, idx + 1)
                    )
                } else {
                    this.rows.push(row)
                    serials[row.serial.toLowerCase()] = true
                }
            } else {
                this.errors.push(__('%s at line %s', row.getValidationMessage(), idx + 1))
            }
        })
    }

    hash() {
        return this.rows.map((row) => row.hash())
    }
}

class Row {
    /**
     * @var string
     */
    serial = ''

    /**
     * @var int
     */
    repetition = 0

    /**
     * @var Date
     */
    nextDate = null

    __acceptedRepetitionValues = [0, 1, 3, 6, 9]

    __validationMessage = null

    constructor(raw) {
        const fields = raw.split(',')

        this.serial = fields[0] ? fields[0].replace(/[^a-zA-Z0-9]/g, '') : ''
        this.nextDate = fields[1]
            ? new Date(fields[1].replace(/[^0-9-]/g, ''))
            : new Date().setTime(0)
        this.repetition = fields[2]
            ? window.parseInt(fields[2].replace(/[^0-9]/g, ''))
            : 0
    }

    validate() {
        return (
            this.__validateSerial() &&
            this.__validateRepetiton() &&
            this.__validateNextDate()
        )
    }

    /**
     * @returns {String}
     */
    getValidationMessage() {
        return this.__validationMessage
    }

    /**
     * @returns {{serial: String, repetition: Number, next: Number}}
     */
    hash() {
        return {
            serial: this.serial,
            repetition: this.repetition,
            next: this.nextDate.getTime(),
        }
    }

    /**
     * @returns {boolean}
     * @private
     */
    __validateSerial() {
        if (
            !/(?:^[0-9a-fA-F]{6}$|^[0-9a-fA-F]{10}$|^[0-9a-fA-F]{12}$|^[0-9a-fA-F]{15}$)/.test(
                this.serial
            )
        ) {
            this.__validationMessage = __('Invalid serial')
            return false
        }

        return true
    }

    /**
     * @returns {boolean}
     * @private
     */
    __validateRepetiton() {
        if (this.__acceptedRepetitionValues.indexOf(this.repetition) === -1) {
            this.__validationMessage = __('Invalid Repetition')
            return false
        }

        return true
    }

    /**
     * @returns {boolean}
     * @private
     */
    __validateNextDate() {
        if (
            isNaN(this.nextDate.getTime()) ||
            this.nextDate.getTime() < this.__getToday().getTime()
        ) {
            this.__validationMessage = __('Invalid Next RI Date')
            return false
        }

        return true
    }

    __getToday() {
        const date = new Date()
        date.setUTCHours(0, 0, 0, 0)

        return date
    }
}
