import React from 'react'
import classes from 'classnames'

export const TabContentWrapper = ({activeTab, tabName, styleName, children}) => {
    return (
        <div
            className={classes(styleName, {
                'group-hidden-tab-content': activeTab !== tabName,
            })}
        >
            {children}
        </div>
    )
}
