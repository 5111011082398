import React from 'react'

import Layout from 'ipmp-react-ui/Layout'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import {withPermission} from 'containers/withPermission'
import permissionActions from 'permissions/panel/panelInstallers/actions'
import TableWithBlind from 'components/Table/TableWithBlind'

import AssignInstallerButton from './GridBar/AssignInstallerButton'
import ChangeCodeButton from './GridBar/ChangeCodeButton'
import PanelInstallersBlind from './PanelInstallersBlind'
import PanelInstallersTable from './PanelInstallersTable'

function PanelInstallers({match}) {
    return (
        <Layout vertical className="panelPage-content">
            <Bar className="panel-page-installers-tab">
                <BarSpace />
                <ChangeCodeButton panelId={match.params.id} />
                <AssignInstallerButton />
            </Bar>
            <TableWithBlind>
                {(props) => <PanelInstallersBlind {...props} />}
                {(props) => <PanelInstallersTable {...props} />}
            </TableWithBlind>
        </Layout>
    )
}

export default withPermission({isAllowed: permissionActions})(PanelInstallers)
