import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {fetch as fetchEventProfiles} from 'modules/centralStations/profiles/actions'

import ModalCardForm from 'ui/ModalCardForm'

import withModalLoader from 'containers/withModalLoader'
import withForm from 'containers/withForm'
import {editGroupCsLinks} from 'modules/forms/handlers'
import csProfileType, {CS_PROFILE_SMART_SENSORS} from 'constants/csProfileType'

import {__} from 'utils/i18n'
import ToggleBox from 'ipmp-react-ui/ToggleBox'
import Definition from 'ipmp-react-ui/Definition'

class EditLinks extends Component {
    static propTypes = {
        groupId: PropTypes.number.isRequired,
        centralStation: PropTypes.object.isRequired,
        profiles: PropTypes.object.isRequired,
        links: PropTypes.array,
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func.isRequired,
    }

    state = {}

    constructor(props, context) {
        super(props, context)
        this.state.active = props.links || []
    }

    handle = () => {
        const {handle, centralStation, groupId} = this.props
        handle(groupId, centralStation.id, this.state.active)
    }

    handleChange = (key, value) => {
        let active = this.state.active
        let includes = active.includes(key)

        if (value && !includes) {
            active = [...active, key]
        }

        if (!value && includes) {
            active = active.filter((id) => id !== key)
        }

        this.setState({active})
    }

    handleSelectAll = (e) => {
        const {profiles} = this.props

        this.setState({
            active: e.target.checked ? Object.keys(profiles).map(Number) : [],
        })
    }

    isProfileAvaible = (key) => {
        const {centralStation, profiles} = this.props

        if (profiles[key].name === CS_PROFILE_SMART_SENSORS) {
            // Smart sensors avaible only with 'VIS NAP V3' protocol
            return /VIS NAP V3/.test(centralStation.protocol)
        }

        return true
    }

    renderList() {
        const {profiles} = this.props
        const links = this.state.active

        return Object.keys(profiles)
            .map(Number)
            .filter(this.isProfileAvaible)
            .map((key) => (
                <ToggleBox
                    key={key}
                    value={key}
                    onChange={(e) => {
                        this.handleChange(key, e.target.checked)
                    }}
                    checked={links.includes(key)}
                    defaultChecked={links.includes(key)}
                    name={`profile[${key}]`}
                    label={csProfileType(profiles[key].name)}
                />
            ))
    }

    render() {
        const {centralStation, profiles, ...props} = this.props
        const {active} = this.state
        const header = (
            <Definition
                className="central-stations-edit-links-header"
                title={centralStation.name}
                detail={centralStation.protocol}
            />
        )
        const enabledAll = active.length >= Object.keys(profiles).map(Number).length

        return (
            <ModalCardForm
                header={header}
                onSubmit={this.handle}
                {...props}
                wide
                className="central-stations-edit-links-form"
            >
                <div className={'notifications-profile-header'}>
                    <ToggleBox
                        name="allProfiles"
                        label={__('All-Profiles')}
                        checked={enabledAll}
                        defaultChecked={enabledAll}
                        onChange={this.handleSelectAll}
                    />
                </div>

                <div className="cols--stretch groupForm-grid-notifications">
                    {this.renderList()}
                </div>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({centralStations, groups}, {groupId, centralStationId, links}) => ({
            isLoading: centralStations.profiles.isLoading, // used by withModalLoader
            error: centralStations.profiles.error, // used by withModalLoader
            profiles: centralStations.profiles.byId,
            links: links ?? groups?.links[groupId][centralStationId],
            centralStation:
                centralStations.store.byIds[centralStationId] ||
                groups.centralStations.store.byIds[centralStationId],
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    fetchEventProfiles,
                },
                dispatch
            )
    ),

    withModalLoader(({fetchEventProfiles}) => fetchEventProfiles()),

    withForm(editGroupCsLinks)
)(EditLinks)
