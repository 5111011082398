import React from 'react'

import {TableGrouped} from 'ipmp-react-ui/Table'
import {humanDate} from 'ipmp-react-ui/humanTime'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconDownloadOutline} from 'ipmp-react-ui/icons/download.svg'
import Time from 'ipmp-react-ui/Time'

import {__} from 'utils/i18n'
import RemoveButton from './RemoveButton'
import {
    remove as removePermission,
    download as downloadPermission,
} from 'permissions/panel/systemLogs/actions'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

export const allColumns = [
    {
        render: ({timestamp, filename, filesize, fileSizeUnit}) => (
            <div>
                <Tooltip>{filename}</Tooltip>
                <p className="log-additional-ifo">
                    <Time datetime={timestamp} />
                    &nbsp;{'|'}&nbsp;
                    <span className="log-filesize">
                        {filesize} {fileSizeUnit}
                    </span>
                </p>
            </div>
        ),
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        permission: downloadPermission,
        tooltip: () => __('Download'),
        render: ({id}, {panelId}) => (
            <a href={`/api/unit/${panelId}/system-logs/download/${id}`}>
                <IconDownloadOutline className="smartCommunicator-log-download-icon" />
            </a>
        ),
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        permission: removePermission,
        tooltip: () => __('Remove'),
        render: ({id}) => <RemoveButton id={id} />,
    },
]

export default function LogsTable({rows, panelId}) {
    const {columns} = useTableColumnsPermission(allColumns)
    const separatorCallback = (row) => humanDate(row.timestamp)

    return (
        <TableGrouped
            panelId={panelId}
            rows={rows}
            columns={columns}
            emptyMessage={__('No entries')}
            separatorCallback={separatorCallback}
        />
    )
}
