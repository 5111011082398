import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
    activeFaultsTest,
    bypassedTest,
    frequentlyUsedTest,
    reportedFaultsTest,
    totalSystemUsageTest,
    soakTest,
    clockTest,
} from 'api/remoteInspectionResult'

import FrequentlyUsed from './Detailed/FrequentlyUsed'
import TimeFaults from './Detailed/TimeFaults'
import Bypassed from './Detailed/Bypassed'
import Soak from './Detailed/Soak'

const map = {
    [activeFaultsTest]: TimeFaults,
    [frequentlyUsedTest]: FrequentlyUsed,
    [reportedFaultsTest]: TimeFaults,
    [bypassedTest]: Bypassed,
    [totalSystemUsageTest]: TimeFaults,
    [soakTest]: Soak,
}

const resultFromInfoKeys = [clockTest]

export default class extends Component {
    static propTypes = {
        test: PropTypes.string,
        state: PropTypes.string,
        range: PropTypes.string,
        result: PropTypes.string,
    }

    renderHeader() {
        const {test, range} = this.props

        return (
            <div className="rri-result-header">
                <h3 className="rri-result-title">{test}</h3>
                {range && <span className="rri-result-range">({range})</span>}
            </div>
        )
    }

    renderTestResultList() {
        const {detailed, testTypeId} = this.props

        const Formatted = map[testTypeId]
        return Formatted ? <Formatted rows={detailed} /> : null
    }

    renderResult() {
        const {detailed} = this.props

        return (
            <div className="rri-result-content">
                {detailed && detailed.length
                    ? this.renderTestResultList()
                    : this.getResultValue()}
            </div>
        )
    }

    getResultValue() {
        const {testTypeId, result, info} = this.props

        return (
            <table className="rri-result-details">
                <thead>
                    <tr>
                        <th>
                            {resultFromInfoKeys.includes(testTypeId) && info
                                ? info
                                : result}
                        </th>
                    </tr>
                </thead>
            </table>
        )
    }

    render() {
        return (
            <div className="rri-result">
                {this.renderHeader()}
                {this.renderResult()}
            </div>
        )
    }
}
