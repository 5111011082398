import React from 'react'

import Ava, {AvaIcon} from 'ipmp-react-ui/Ava'

import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as IconEmail} from 'ipmp-react-ui/icons/email.svg'
import {ReactComponent as IconPhone} from 'ipmp-react-ui/icons/phone.svg'
import {ReactComponent as IconLocation} from 'ipmp-react-ui/icons/location.svg'

import {NotSetEmpty} from 'ui/Empty'

export default function CustomerGeneralInfo({name, email, phone, address}) {
    return (
        <div className="customerGeneralInfo">
            {name ? (
                <Ava
                    className="customerGeneralInfo-item customerGeneralInfo-item--ava"
                    large
                    name={name}
                />
            ) : (
                <AvaIcon
                    className="customerGeneralInfo-item customerGeneralInfo-item--ava"
                    Icon={IconUser}
                />
            )}
            <span className="customerGeneralInfo-item customerGeneralInfo-item--name">
                {name || NotSetEmpty}
            </span>
            <span className="customerGeneralInfo-item customerGeneralInfo-item--email">
                <IconEmail />
                {email ? (
                    <a className="link" href={`mailto:${email}`}>
                        {email}
                    </a>
                ) : (
                    NotSetEmpty
                )}
            </span>
            <span className="customerGeneralInfo-item customerGeneralInfo-item--phone">
                <IconPhone />
                {phone || NotSetEmpty}
            </span>
            <span className="customerGeneralInfo-item customerGeneralInfo-item--address">
                <IconLocation />
                {address || NotSetEmpty}
            </span>
        </div>
    )
}
