export function wrapValue(val) {
    return '"' + String(val).trim() + '"'
}

export default function exportCsv(csvContent, fileName) {
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    link.click()
    link.remove()
}
