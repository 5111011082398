import get from 'lodash-es/get'
import MultiSelect from 'ipmp-react-ui/MultiSelect'

import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchPartitions} from 'modules/panels/state/actions'
import {DEFAULT_PARTITION_VALUE, preparePartitionName} from 'constants/partitions'

const PartitionsMultiSelect = withSelectLoader(
    (prefix, maxOptionsToShow, {panelId}) => fetchPartitions(panelId),
    ({panels: {state}}, {panelId}) => !get(state, ['byIds', panelId, 'partitions'], true),
    ({panels: {state}}, {isLoading, panelId}) => {
        const partitionsList = Object.values(state.byIds[panelId].partitionStates)
            .filter(({id}) => id > 0)
            .map(({id}) => {
                return preparePartitionName(id)
            })
        const isPartitionsEnabled = partitionsList.length !== 0
        const partitions = isPartitionsEnabled
            ? partitionsList
            : [preparePartitionName(DEFAULT_PARTITION_VALUE)]

        return !isLoading && partitions ? partitions : []
    }
)(MultiSelect)

export default PartitionsMultiSelect
