import React from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import Button from 'ipmp-react-ui/Button'

import {cancelSchedule} from 'permissions/remoteInspections/selection'

import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {ifSelection, withSelectionHandler} from 'containers/withSelection'

import {ReactComponent as IconStop} from 'ipmp-react-ui/icons/stop.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ButtonConnected = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation(),
    ifSelection(
        (id, {remoteInspections}) => !!get(remoteInspections, ['store', id, 'next'])
    )
)(Button)

export default function CancelRemoteInspectionButton() {
    const {isPermitted} = usePermission(cancelSchedule)
    const {cancel} = useRemoteInspections()

    return (
        <ButtonConnected
            isVisible={isPermitted}
            onClick={cancel}
            message={__('Do you really want to cancel schedule?')}
            positive={__('Cancel')}
            title={__('Schedule cancellation')}
            label={__('Cancel')}
            Icon={IconStop}
        />
    )
}
