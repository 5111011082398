import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import {showChangeInstallerCodeModal} from 'modules/modals/actions'
import {changeCode} from 'permissions/panel/panelInstallers/actions'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {useSelector} from 'react-redux'
import {selectFeaturesInstaller} from 'modules/features/store/selectors'
import {hasIefCode, hasIefType} from 'constants/installersRoles'

const ConnectedButton = compose(withVisibility())(Button)

export default function ChangeCodeButton({panelId}) {
    const featuresInstaller = useSelector(({features}) =>
        selectFeaturesInstaller(features, panelId)
    )
    const {isPermitted} = usePermission(changeCode)
    const {showForm} = useActions({
        showForm: () => showChangeInstallerCodeModal(panelId, featuresInstaller),
    })
    const isVisible = () => {
        const hasSomeTypes = featuresInstaller.installerTypes.length > 1
        const hasCode = hasIefCode(featuresInstaller.editableFeatures)
        const hasType = hasIefType(featuresInstaller.editableFeatures)

        return (isPermitted && hasCode) || (isPermitted && hasSomeTypes && hasType)
    }

    return (
        <ConnectedButton
            label={__('Change code')}
            Icon={IconPlus}
            onClick={showForm}
            isVisible={isVisible()}
        />
    )
}

ChangeCodeButton.propTypes = {
    panelId: PropTypes.string.isRequired,
}
