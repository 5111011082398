import React, {useCallback, useState} from 'react'
import {compose} from 'redux'
import page from 'permissions/panel/events/page'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import Spinner from 'ipmp-react-ui/Spinner'
import Button from 'ipmp-react-ui/Button'
import Table from './EventsTable'
import EventContext from '../../Events/EventContext'
import {__} from 'utils/i18n'

import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withRejection} from 'containers/withPermission'
import EventsBlindModal from 'pages/Events/EventsBlindModal'
import {POLL_EVENTS_PANEL} from 'configs/pollers'
import usePermission from 'hooks/usePermission'
import usePanelEvents from 'hooks/pages/usePanelEvents'

const EventsBase = (props) => {
    const {
        panelId,
        hasOlder,
        fetchOlder,
        fetchNewer,
        isOlderLoading,
        isNewerLoading,
        newerCount,
    } = props

    const [selectedEventId, setEventId] = useState(null)

    const handleEventSelect = (event) => {
        setEventId(event?.id)
    }

    const handleEventDeliverance = useCallback(handleEventSelect)

    return (
        <Layout className="eventsBySerial eventsBySerial--panel">
            <EventContext.Provider
                value={{
                    handleEventSelect: handleEventDeliverance,
                    selectedEventId,
                }}
            >
                <ScrollView>
                    {newerCount > 0 && (
                        <div className="btnHolder">
                            {isNewerLoading ? (
                                <Spinner />
                            ) : (
                                <Button onClick={fetchNewer}>
                                    {__('Load %d new events', newerCount)}
                                </Button>
                            )}
                        </div>
                    )}

                    <Table onRowClick={handleEventSelect} selected={selectedEventId} />

                    {hasOlder && (
                        <div className="btnHolder">
                            {isOlderLoading ? (
                                <Spinner />
                            ) : (
                                <Button onClick={fetchOlder}>{__('Load older')}</Button>
                            )}
                        </div>
                    )}
                </ScrollView>
                <EventsBlindModal
                    {...props}
                    selectedEventId={selectedEventId}
                    panelId={panelId}
                />
            </EventContext.Provider>
        </Layout>
    )
}

const ConnectedEvents = compose(
    withRejection(),
    withLoader(
        ({fetch}) => fetch(),
        ({reset}) => reset()
    ),
    withPoller(POLL_EVENTS_PANEL, ({checkNewer}) => checkNewer())
)(EventsBase)

export default function Events(props) {
    const {isPermitted} = usePermission(page)
    const panelEvents = usePanelEvents()
    const eventsProps = {...props, ...panelEvents}

    return <ConnectedEvents {...eventsProps} isAllowed={isPermitted} />
}
