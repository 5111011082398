import React from 'react'
import PropTypes from 'prop-types'

import Info from './Info'
import Status from './Status'
import PanelUserInfo from './PanelUserInfo'

function Top({user, isPanelMode = false}) {
    return (
        <>
            {isPanelMode ? (
                <PanelUserInfo user={user} />
            ) : (
                <>
                    <Info user={user} />
                    <Status user={user} />
                </>
            )}
        </>
    )
}

Top.propTypes = {
    user: PropTypes.object.isRequired,
    isPanelMode: PropTypes.bool,
}

export default Top
