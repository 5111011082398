import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import processType from 'constants/processType'
import ProcessCounters from 'components/Processes/ProcessCounters'
import {selectWidgetBatches} from 'modules/batches/widget/selectors'
import {NotificationsBox} from './NotificationsBox'

export default function JobNotifications({onClick}) {
    const batches = useSelector(selectWidgetBatches)

    const notifications = batches.map(
        ({id, type, stats, started, finished, panels, panelSerial}) => {
            const processTypeName = processType(type)
            const panelInfo =
                panels === 1 ? __('for %s', panelSerial) : __('for %s panels', panels)
            const isFinished = !((stats.pending || 0) + stats.start + stats.notStarted)

            return {
                dateTime: finished || started,
                hash: `${id}-${isFinished ? 'finished' : 'started'}`,
                title: isFinished ? __('Job completed') : __('Job started'),
                description: `${processTypeName} ${panelInfo}`,
                Slot: isFinished && <ProcessCounters stats={stats} />,
                onClick: () => onClick(id),
            }
        }
    )

    return <NotificationsBox notifications={notifications} />
}
