import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import withNamedError from 'ipmp-react-ui/withNamedError'
import thermostatTypeStateTitle, {
    getThermostatModeIcon,
    HAD_DEVICE_THERMOSTAT_MODE_AUTO,
    HAD_DEVICE_THERMOSTAT_MODE_AUX_HEAT,
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
    HAD_DEVICE_THERMOSTAT_MODE_OFF,
} from 'constants/had/thermostat'
import formatError from 'ipmp-react-ui/_utils/formatError'

import __ from 'utils/i18n'

const ThermostatModeChooseItem = ({mode, active, onClick}) => {
    return (
        <div
            onClick={() => onClick(mode)}
            className={classnames('thermostatModeChoose-item', {
                'thermostatModeChoose-item--active': active,
            })}
        >
            {getThermostatModeIcon(mode)} {thermostatTypeStateTitle(mode)}
        </div>
    )
}

const ThermostatModeChoose = ({
    defaultValue,
    name,
    options,
    error,
    className,
    onChange,
}) => {
    const [{activeMode, isFirstRender}, setState] = useState({
        activeMode: defaultValue,
        isFirstRender: true,
    })
    const containerRef = useRef(null)

    const onClickHandler = useCallback(
        (mode) => {
            setState({isFirstRender, activeMode: mode})
            onChange(mode)
        },
        [isFirstRender]
    )

    const activeModeIndex = options.findIndex((mode) => mode === activeMode)
    const activeElement = containerRef.current?.children[activeModeIndex]

    useEffect(() => {
        if (!activeElement) {
            setState({
                activeMode,
                isFirstRender: false,
            })
        }
    }, [containerRef.current])

    const getDirectionResult = () => {
        const rtlOffset = 428
        const rtl = document.dir === 'rtl'
        return rtl ? rtlOffset : 2
    }

    const getPadXValue = (activeElement, container) => {
        const {x: containerX} = container.getBoundingClientRect()
        const {x: activeElementX} = activeElement.getBoundingClientRect()
        const resultX = Math.round(activeElementX - containerX) - getDirectionResult() // container paddings

        return `${resultX}px`
    }

    const getPadWidth = (activeElement) => {
        return `${activeElement.clientWidth}px`
    }

    return (
        <section
            className={classnames('form-field', 'thermostatModeChoose', className, {
                'form-field--error': error,
            })}
        >
            <h3 className="form-field-label">{__('Modes')}</h3>
            <div
                className={classnames('thermostatModeChoose-item-container', {
                    'thermostatModeChoose-item-container--hidden': isFirstRender,
                    'thermostatModeChoose-item-container--off':
                        activeMode === HAD_DEVICE_THERMOSTAT_MODE_OFF,
                    'thermostatModeChoose-item-container--heat':
                        activeMode === HAD_DEVICE_THERMOSTAT_MODE_HEAT,
                    'thermostatModeChoose-item-container--cool':
                        activeMode === HAD_DEVICE_THERMOSTAT_MODE_COOL,
                    'thermostatModeChoose-item-container--auto':
                        activeMode === HAD_DEVICE_THERMOSTAT_MODE_AUTO,
                    'thermostatModeChoose-item-container--auxHeat':
                        activeMode === HAD_DEVICE_THERMOSTAT_MODE_AUX_HEAT,
                })}
                ref={containerRef}
            >
                {options.map((mode) => (
                    <ThermostatModeChooseItem
                        active={activeMode === mode}
                        mode={mode}
                        key={mode}
                        onClick={onClickHandler}
                    />
                ))}
                {!isFirstRender && activeElement && (
                    <div
                        style={{
                            transform: `translateX(${getPadXValue(
                                activeElement,
                                containerRef.current
                            )})`,
                            width: getPadWidth(activeElement),
                        }}
                        className={classnames('thermostatModeChoose-item-pad')}
                    />
                )}
            </div>

            <input name={name} type="hidden" value={activeMode} />
            {error && <span className="form-field-error">{formatError(error)}</span>}
        </section>
    )
}

ThermostatModeChoose.propTypes = {
    defaultValue: PropTypes.number,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    error: PropTypes.object,
    className: PropTypes.string,
    onChange: PropTypes.func,
}

export default withNamedError(ThermostatModeChoose)
