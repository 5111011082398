import React from 'react'
import {Route, Switch} from 'react-router-dom'
import tabs, {
    CENTRAL_STATION_TAB_COMMUNICATION,
    CENTRAL_STATION_TAB_GENERAL,
} from './CentralStationRouting'
import General from './General/General'
import Communication from './Communication/Communication'
import path from 'utils/path'

const componentsMap = new Map([
    [CENTRAL_STATION_TAB_GENERAL, General],
    [CENTRAL_STATION_TAB_COMMUNICATION, Communication],
])

const CentralStationContent = () => {
    return (
        <Switch>
            {[...tabs.entries()].map(([key, {path: pathName, exact}]) => (
                <Route
                    path={path(pathName)}
                    exact={exact}
                    component={componentsMap.get(key)}
                    key={key}
                />
            ))}
        </Switch>
    )
}

export default CentralStationContent
