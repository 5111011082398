import React from 'react'

import {edit} from 'permissions/panel/actions'

import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'

const MenuItemVisibility = withVisibility()(MenuItem)

export default function EditPanelItem() {
    const {isPermitted} = usePermission(edit)
    const {showEditPanelInfo} = usePanel()

    return (
        <MenuItemVisibility
            onClick={showEditPanelInfo}
            isVisible={isPermitted}
            Icon={IconEdit}
            children={__('Edit')}
        />
    )
}
