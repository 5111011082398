import React from 'react'

import processType from 'constants/processType'

import {stop} from 'permissions/processes/actions'

import ProcessStatus from 'components/Processes/ProcessStatus'
import ProcessStopButton from 'components/Processes/ProcessStopButton.js'

import Definition from 'ipmp-react-ui/Definition'
import Duration from 'ipmp-react-ui/Duration'
import {TableGrouped} from 'ipmp-react-ui/Table'
import Time from 'ipmp-react-ui/Time'

import {humanDate} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

export const allColumns = [
    {
        maxWidth: 100,
        render: ({started}) => <Time datetime={started} withSeconds />,
    },
    {
        render: ({type, description}) => (
            <Definition title={processType(type)} detail={description} />
        ),
    },
    {
        render: ProcessStatus,
    },
    {
        render: ({user}) => user,
    },
    {
        render: ({email}) => email,
    },
    {
        maxWidth: 150,
        render: ({started, finished}) => <Duration from={started} to={finished} />,
    },
    {
        align: 'right',
        permission: stop,
        width: 50,
        fixed: true,
        render: ({isStoppable, id}, {stop}) => (
            <ProcessStopButton onClick={() => stop(id)} disabled={!isStoppable} />
        ),
    },
]

export default function ProcessesTable(props) {
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <TableGrouped
            {...props}
            columns={columns}
            emptyMessage={() => __('No entries')}
            separatorCallback={(row) => humanDate(row.started)}
        />
    )
}
