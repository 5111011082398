import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'

import SetLabelButton from './SetLabelButton'
import Row from '../Row'

export default function LabelRow({user: {id, label, isPinSet}}) {
    const panelId = useRouterPanelId()

    return (
        <Row
            value={label}
            title={__('Label')}
            missingTitle={__('No Label')}
            EditButton={
                <SetLabelButton
                    userLabel={label}
                    panelId={panelId}
                    panelUserId={id}
                    isPinSet={isPinSet}
                />
            }
        />
    )
}

LabelRow.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isPinSet: PropTypes.bool.isRequired,
        label: PropTypes.string,
    }).isRequired,
}
