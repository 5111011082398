import React from 'react'
import PropTypes from 'prop-types'

import Account from './Field/Account'
import Group from './Field/Group'
import Serial from './Field/Serial'

export function Fieldset({isNew, data}) {
    return (
        <div className="form-field">
            <Serial isNew={isNew} serial={data.serial} />
            <Account isNew={isNew} account={data.account} />
            <Group groupName={data.group} groupId={data.groupId} />
        </div>
    )
}

Fieldset.propTypes = {
    isNew: PropTypes.bool,
    data: PropTypes.shape({
        serial: PropTypes.string,
        account: PropTypes.string,
        group: PropTypes.string,
        groupId: PropTypes.number,
    }),
}

export const rules = {
    groupId: {
        presence: true,
    },
}
