import {__} from 'utils/i18n'

import {EDS_FAILED, EDS_PROCESSING, EDS_RETRY, EDS_SUCCESS} from 'constants/eventDelivery'

export default function useDeliveranceStatus(status) {
    switch (status) {
        case EDS_PROCESSING:
            return __('Processing')
        case EDS_SUCCESS:
            return __('Success')
        case EDS_RETRY:
            return __('Not Delivered')
        case EDS_FAILED:
            return __('Failed')
        default:
            return __('Queued')
    }
}
