import {BRIGHTNESS, GSM_RSSI, TEMPERATURE} from 'constants/meteoTypes'
import {
    selectDeviceBrightness,
    selectDeviceGsmRssi,
    selectDeviceTemperature,
} from 'modules/devices/meteo/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch} from 'modules/devices/meteo/actions'
import {useSelector} from 'react-redux'

export default function useDeviceMeteo({type, panelId, deviceId, range: {from, to}}) {
    const props = {
        panelId,
        deviceId,
        from,
        to,
    }

    const selector = resolveSelectorByType(type)

    return {
        ...useSelector((state) => (selector ? selector(state, props) : {})),
        ...useActions({
            fetch: () => fetch(panelId, deviceId, type),
        }),
    }
}

const resolveSelectorByType = (type) => {
    switch (type) {
        case TEMPERATURE:
            return selectDeviceTemperature
        case BRIGHTNESS:
            return selectDeviceBrightness
        case GSM_RSSI:
            return selectDeviceGsmRssi
        default:
            return null
    }
}
