import {handleActions} from 'redux-actions'

import {fetch, receive, setLoading, update} from './actions'

const defaultState = {
    isLoading: false,
    byIds: {},
    error: null,
}

export default handleActions(
    {
        [setLoading](state, {payload}) {
            return {
                ...state,
                isLoading: payload,
            }
        },

        [fetch](state) {
            return {
                ...state,
                isLoading: true,
            }
        },

        [update](state, {payload}) {
            const byIds = payload.reduce((acc, row) => {
                const old = acc[row.id]

                return {
                    ...acc,
                    [row.id]: {
                        ...old,
                        ...row,
                    },
                }
            }, state.byIds || {})

            return {
                ...state,
                byIds,
            }
        },

        [receive](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            const {id, notifications} = payload

            return {
                ...state,
                isLoading: false,
                byIds: {
                    ...state.byIds,
                    [id]: notifications,
                },
            }
        },
    },
    defaultState
)
