import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'

const defaultState = {
    isLoading: false,
    error: null,
    current: null,
}

export default handleActions(
    {
        [fetch]() {
            return {
                isLoading: true,
                error: null,
                current: null,
            }
        },
        [receive](state, {error, payload}) {
            if (error) {
                return {
                    isLoading: false,
                    error,
                    current: null,
                }
            }

            return {
                isLoading: false,
                error: null,
                current: payload,
            }
        },
    },
    defaultState
)
