import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import withLoader from 'containers/withLoader'
import {BlindModalCardTable} from 'ui/BlindModal'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

import AssignPanelCell from './AssignPanelCell'

const columns = [
    {
        name: () => __('Panel'),
        render: ({id, serial, account}) => (
            <PanelCell to="installers" {...{id, serial, account}} />
        ),
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        tooltip: false,
        fixed: true,
        width: 66,
        component: AssignPanelCell,
    },
]

const TableComponent = ({rows}) => (
    <BlindModalCardTable columns={columns} title={__('Panels')} rows={rows} />
)

TableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
}

const TableContainer = compose(withLoader(({fetch}) => fetch()))(TableComponent)

TableContainer.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            serial: PropTypes.string,
            account: PropTypes.string,
            group: PropTypes.string,
        })
    ).isRequired,
    fetch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
}

export default TableContainer
