// Configurations is very huge,
// so take performance into account
import {MENU_TYPE} from 'constants/panelConfiguration'

function fillHash(val, hash = {}) {
    val.forEach((item) => {
        if (item.type === MENU_TYPE) {
            fillHash(item.val, hash)
        } else {
            hash[item.key] = item.val
        }
    })

    return hash
}

export default function configurationToHash({val}) {
    return fillHash(val)
}
