import React from 'react'
import list from 'permissions/events/list'

import Pager from 'ipmp-react-ui/Pager'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import useEvents from 'hooks/pages/useEvents'

const PagerVisibility = withVisibility()(Pager)

export default function EventPager() {
    const eventsProps = useEvents()
    const {isPermitted} = usePermission(list)

    return (
        <PagerVisibility
            isVisible={isPermitted}
            {...eventsProps}
            disabled={eventsProps.isDataLoading}
            sort={[]}
        />
    )
}
