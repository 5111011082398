import React from 'react'

import Pager from 'ipmp-react-ui/Pager'

import {useInstallerPanelsPager} from 'hooks/installer'

export default function FooterContent() {
    const {start, total, perPage, onPageChange, onPerPageChange} =
        useInstallerPanelsPager()

    return (
        <div className="blindModal-card-actions-pager">
            <Pager
                start={start}
                total={total}
                perPage={perPage}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
            />
        </div>
    )
}
