import React from 'react'
import PropTypes from 'prop-types'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconChat} from 'ipmp-react-ui/icons/baseline-chat.svg'

import PanelStatus from 'components/PanelStatus'
import PanelCell from 'components/TableCells/PanelCell'
import __ from 'utils/i18n'
import PanelStatusLine from './PanelStatusLine'
import RefreshPanelButton from './Buttons/RefreshPanelButton'
import FaultsDropDown from './DropDowns/FaultsDropDown'
import EmergenciesDropDown from './DropDowns/EmergenciessDropDown'
import ActionsDropDown from './DropDowns/ActionsDropDown'
import {PanelStatusLineStubber} from './PanelStatusLineStubber'

function PanelPageTop({panel, openRemarks, emergencyFeatures, isContentHidden}) {
    const {serial, account, connected} = panel

    return (
        <Bar>
            <PanelStatus panel={panel} />
            <PanelCell
                multiLine
                className="title title--large"
                serial={serial}
                account={account}
            />
            <PanelStatusLine panel={panel} />
            <PanelStatusLineStubber panel={panel} />

            <BarSpace />

            {!isContentHidden && emergencyFeatures?.isEnabled && <EmergenciesDropDown />}
            <FaultsDropDown />
            <ActionsDropDown isContentHidden={isContentHidden} connected={connected} />
            {!isContentHidden && (
                <Buttons>
                    <RefreshPanelButton panel={panel} />
                </Buttons>
            )}
            <Buttons dataTestId="open-panel-remarks">
                <Tooltip tooltip={__('Open Panel Remarks')}>
                    <Button onClick={openRemarks} Icon={IconChat} />
                </Tooltip>
            </Buttons>
        </Bar>
    )
}

PanelPageTop.propTypes = {
    panel: PropTypes.shape({
        id: PropTypes.number,
        serial: PropTypes.string,
        account: PropTypes.string,
    }).isRequired,
    openRemarks: PropTypes.func.isRequired,
    emergencyFeatures: PropTypes.shape({
        isEnabled: PropTypes.bool,
    }),
}

export default PanelPageTop
