import moment from 'moment'

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'

export const parseScopeValue = (value) => {
    if (!value) {
        return value
    }

    if (typeof value === 'object') {
        const {from, to} = value

        if (typeof from === 'string' && typeof to === 'string') {
            return {from: parseScopeValue(from), to: parseScopeValue(to)}
        }
    }

    if (typeof value !== 'string') {
        return value
    }

    const date = moment(value, DATE_TIME_FORMAT, true)

    if (date.isValid()) {
        return date
    }

    return value
}
