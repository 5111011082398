import {useDispatch, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'

import {selectInteractiveUserPanelsList} from 'modules/interactiveUserPanels/list/selectors'
import * as actions from 'modules/interactiveUserPanels/list/actions'

export default function useInteractiveUserPanelsTable(id) {
    const dispatch = useDispatch()

    const {rows, isLoading} = useSelector((state) =>
        selectInteractiveUserPanelsList(state, id)
    )
    const {fetch, resetWithOutFetch} = bindActionCreators(
        {
            fetch: actions.fetch,
            resetWithOutFetch: actions.resetWithOutFetch,
        },
        dispatch
    )

    return {
        rows,
        isLoading,
        fetch,
        resetWithOutFetch,
    }
}
