import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {selectIsPanelAssigned} from 'modules/interactiveUserPanels/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {showAssignUserToPanelModal} from 'modules/modals/actions'
import usePermission from 'hooks/usePermission'
import {assign} from 'permissions/interactiveUsers/actions'

export default function AssignPanelCell({row: {id}, interactiveUserId}) {
    const {onClick} = useActions({
        onClick: () => showAssignUserToPanelModal({panelId: id, interactiveUserId}),
    })
    const {isAssigned} = useSelector((state) => selectIsPanelAssigned(state, id))
    const {isAllowedAssignInteractiveUsers} = usePermission({
        isAllowedAssignInteractiveUsers: assign,
    })

    if (isAssigned) {
        return <IconCheck />
    }

    return (
        isAllowedAssignInteractiveUsers && (
            <Button flat small Icon={IconPlus} onClick={onClick} />
        )
    )
}

AssignPanelCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
        serial: PropTypes.string.isRequired,
    }).isRequired,
}
