import {put, fork} from 'redux-saga/effects'

import {remove} from 'modules/interactiveUsers/list/actions'
import {
    disconnectInteractiveUserWithoutFetch,
    revertDisconnectInteractiveUser,
} from 'modules/panelInteractiveUsers/store/actions'
import updateRecentWidget from 'modules/panelInteractiveUsers/list/watchers/updateRecentWidget'
import {snackShow} from 'modules/snacks'

export default function* watchRemoveInteractiveUser({
    payload: {panelId, interactiveUserId, panelUserId},
}) {
    try {
        yield put(remove([interactiveUserId]))
        yield put(disconnectInteractiveUserWithoutFetch(panelUserId, panelId))
        yield fork(updateRecentWidget)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(revertDisconnectInteractiveUser(panelUserId, panelId))
    }
}
