import {useSelector} from 'react-redux'

import usePerPage from 'hooks/usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {setStart} from 'modules/installerPanels/accessiblePanels/list/actions'
import {selectInstallerAccessiblePanelsPager} from 'modules/installerPanels/accessiblePanels/list/selectors'

export default function useInstallerPanelsPager() {
    const {perPage, onPerPageChange} = usePerPage('installerAccessiblePanels')
    const {start, total} = useSelector(selectInstallerAccessiblePanelsPager)
    const {onPageChange} = useActions({onPageChange: setStart})

    return {
        start,
        total,
        perPage,
        onPageChange,
        onPerPageChange,
    }
}
