import {PureComponent} from 'react'
import React, {Fragment} from 'react'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Tabs, {Tab} from 'ipmp-react-ui/Tabs'
import CameraList from 'components/CameraList'
import {__} from 'utils/i18n'
import get from 'lodash-es/get'

const Layout = ({nav, tab}) => {
    return (
        <Fragment>
            <div className="sidebar-header">{nav}</div>

            <ScrollView className="sidebar-content">{tab}</ScrollView>
        </Fragment>
    )
}

export class VideoTabs extends PureComponent {
    render() {
        const {
            device,
            video,
            startRecordVideo,
            children,
            isRequestAllowed,
            isExportAllowed,
            fallbackTrait,
            features,
            hasLive,
            statusTab,
        } = this.props
        const feature = get(features, 'vod', {})

        if (!hasLive) {
            return (
                <Tabs Layout={Layout}>
                    {statusTab}
                    <Tab name={__('EVENT VIDEO')}>
                        <CameraList
                            device={device}
                            cameras={video.preloaded}
                            isExportAllowed={isExportAllowed}
                            feature={feature}
                        />
                        {children}
                    </Tab>
                </Tabs>
            )
        }

        return (
            <Tabs Layout={Layout}>
                <Tab name={__('EVENT VIDEO')}>
                    <CameraList
                        device={device}
                        cameras={video.preloaded}
                        isExportAllowed={isExportAllowed}
                        feature={feature}
                    />
                    {children}
                </Tab>
                <Tab name={__('LIVE VIDEO')}>
                    <CameraList
                        device={device}
                        cameras={video.live}
                        onStartRecord={startRecordVideo}
                        isRequestAllowed={isRequestAllowed}
                        isExportAllowed={isExportAllowed}
                        fallbackTrait={fallbackTrait}
                        feature={feature}
                        isLiveVideo
                    />
                    {children}
                </Tab>
                {statusTab}
            </Tabs>
        )
    }
}

export default VideoTabs
