import React from 'react'

import {__} from 'utils/i18n'
import BrokersTableDeleteButton from './BrokersTableDeleteButton'

export const messageBrokersColumns = [
    {
        maxWidth: 100,
        name: () => __('ID'),
        render: ({id}) => id,
    },
    {
        maxWidth: 200,
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        width: 150,
        name: () => __('Description'),
        render: ({description}) => description,
    },
    {
        align: 'right',
        width: 50,
        fixed: true,
        render: (broker, {onRemove}) => {
            return (
                <BrokersTableDeleteButton
                    isUsedByMfa={broker.isUsedByMfa}
                    onClick={() => onRemove(broker)}
                />
            )
        },
    },
]
