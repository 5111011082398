import React from 'react'
import {compose} from 'redux'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'
import {withSelectionHandler} from 'containers/withSelection'
import {remove as removePermission} from 'permissions/interactiveUsers/actions'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import * as actions from 'modules/interactiveUsers/list/actions'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export default function InteractiveUserRemoveButton() {
    const {isPermitted} = usePermission(removePermission)
    const {remove} = useActions({remove: actions.remove})

    return (
        <ConnectedButton
            isVisible={isPermitted}
            onClick={remove}
            Icon={IconRemove}
            label={__('Remove')}
            title={__('Remove Customers')}
            message={__('Do you want to remove customers?')}
        />
    )
}
