import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'
import __ from 'utils/i18n'

import withForm from 'containers/withForm'
import {setLoginScreenForm} from 'modules/auth/sign/actions'
import {remindPassword} from 'modules/forms/handlers'
import {LOGIN_SCREEN} from 'constants/loginScreen'

const rules = {
    email: {
        presence: true,
        email: true,
    },
}

class ForgotPasswordForm extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        setLoginScreenForm: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    }

    handleSubmit = ({email}) => {
        this.props.handle({email})
    }

    handleBack = () => {
        this.props.setLoginScreenForm(LOGIN_SCREEN)
    }

    render() {
        const {isLoading, errors} = this.props

        return (
            <Form
                className="card login-form login-form-forgot-form"
                isLoading={isLoading}
                rules={rules}
                errors={errors}
                onSubmit={this.handleSubmit}
            >
                <CardHeader className={'login-form-header'}>
                    {__('Forgot Password?')}
                </CardHeader>

                <CardContent>
                    <span className={'login-form-content-label'}>
                        {__('Enter your details to receive a reset link')}
                    </span>
                    <Input
                        name="email"
                        label={__('Email')}
                        autoComplete={'off'}
                        dataTestId="forgot-password-email-input"
                    />
                </CardContent>

                <CardActions>
                    <Button
                        primary
                        className={'login-form-button'}
                        disabled={isLoading}
                        type="submit"
                    >
                        {__('Remind')}
                    </Button>
                    <Button
                        borderless
                        className={'login-form-back'}
                        onClick={this.handleBack}
                    >
                        <IconPrev />
                        {__('Back to Sign In')}
                    </Button>
                </CardActions>
            </Form>
        )
    }
}

export default compose(
    connect(
        ({auth: {sign}}) => ({...sign}),
        (dispatch) =>
            bindActionCreators(
                {
                    setLoginScreenForm,
                    remindPassword,
                },
                dispatch
            )
    ),
    withForm(remindPassword)
)(ForgotPasswordForm)
