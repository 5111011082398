import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectPanelInstallersList = createSelector(
    (state) => state.panelInstallers.list,
    (state, {panelId}) => get(state, ['panelInstallers', 'store', panelId], {}),
    ({page, isLoading}, store) => ({
        rows: page.reduce((acc, id) => (id in store ? [...acc, store[id]] : acc), []),
        isLoading,
    })
)
