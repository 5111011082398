import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {init, reset, setStart, remove, fetch} from 'modules/groups/list/actions'
import {showAddGroup} from 'modules/modals/actions'

const mapStateToProps =
    () =>
    ({groups}) => {
        const {isLoading, page, error, total, start} = groups.list

        return {
            rows: page.map((id) => groups.store.byIds[id]),
            isLoading,
            error,
            total,
            start,
        }
    }

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            init,
            fetch,
            reset,
            remove,
            showAddGroup,
            onPageChange: setStart,
        },
        dispatch
    )

export default function withGroups() {
    return compose(withPerPage('groups'), connect(mapStateToProps, mapDispatchToProps))
}
