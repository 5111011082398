import {get} from 'api/http'
import mapValidationErrors from '../base/mapValidationErrors'
import {assignInstallerKeyMap} from '../installers.map'
import {mapFeatures} from './features.map'

export function fetch(panelId) {
    return get('unit_diagnostic/features', {unt_id: panelId})
        .then((data) => mapFeatures(data))
        .catch((e) => mapValidationErrors(e, assignInstallerKeyMap))
}
