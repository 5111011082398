import React from 'react'
import classes from 'classnames'

import panelVendorType from 'constants/panelVendorType'
import {VENDOR_FIBRO_TRANSMITTER} from 'constants/panelVendorType'

export const PanelStatusLineStubber = ({panel: {vendor}}) => {
    if (vendor !== VENDOR_FIBRO_TRANSMITTER) {
        return null
    }

    return (
        <div className={classes('panelStatusLine')}>
            {panelVendorType(VENDOR_FIBRO_TRANSMITTER)}
        </div>
    )
}
