import {useEffect, useRef} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const useObserver = (callback, element, deps = []) => {
    const current = element && element.current

    const observer = useRef(null)

    useEffect(() => {
        if (observer && observer.current && current) {
            observer.current.unobserve(current)
        }
        const resizeObserverOrPolyfill = ResizeObserver
        // eslint-disable-next-line new-cap
        observer.current = new resizeObserverOrPolyfill(callback)
        observe()

        return () => {
            if (observer && observer.current && element && element.current) {
                observer.current.unobserve(element.current)
            }
        }
    }, [current, ...deps])

    const observe = () => {
        if (element && element.current && observer.current) {
            observer.current.observe(element.current)
        }
    }

    return {observe}
}

export default useObserver
