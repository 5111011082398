import React from 'react'

import Togglebox from 'ipmp-react-ui/ToggleBox'
import __ from 'utils/i18n'
import get from 'lodash-es/get'
import useEvents from 'hooks/pages/useEvents'

export default function StreamNewEventsButton() {
    const {isFirstPageStreamable, setFirstPageStreamable} = useEvents()

    const handleChange = (e) => {
        setFirstPageStreamable(e.target.checked)
    }

    return (
        <div className="bar-item streamNewEvents">
            <Togglebox
                label={__('Stream new events')}
                checked={get(isFirstPageStreamable, 'state', true)}
                onChange={handleChange}
            />
        </div>
    )
}
