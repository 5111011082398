import useRouterPanelId from '../useRouterPanelId'
import {useDispatch, useSelector} from 'react-redux'
import {selectDeviceByCategories, selectPanelTraits} from 'modules/devices/list/selectors'
import {bindActionCreators} from 'redux'
import {
    fetch as fetchDevices,
    refreshGSM,
    refreshRssi,
    removeDevice,
} from 'modules/devices/list/actions'
import {
    showAddDeviceModal,
    showDisablePlinkLogging,
    showEnablePlinkLogging,
    showRebootPlink,
    showRenameDeviceModal,
} from 'modules/modals/actions'
import {
    fetchWalktest,
    startWalktest,
    stopWalktest,
    toggleWalktest,
} from 'modules/devices/walktest/actions'
import {selectDeviceVOD} from 'modules/devices/vod/selectors'
import {erase, fetch as fetchVideo, start} from 'modules/devices/vod/actions'
import {disablePlinkSSH, enablePlinkSSH, sendPlinkLog} from 'modules/panels/plink/actions'
import get from 'lodash-es/get'
import {fetch as fetchReference} from 'modules/devices/reference/actions'
import {VENDOR_NEO, VENDOR_POWER_MASTER} from 'constants/panelVendorType'
import {selectVod} from 'modules/features/store/selectors'
import usePermission from '../usePermission'
import {exports, request} from 'permissions/panel/devices/video/actions'

export default function useDevices() {
    const dispatch = useDispatch()
    const panelId = useRouterPanelId()

    const {devices, panels} = useSelector(({devices, panels}) => ({devices, panels}))
    const byCategories = useSelector((state) => selectDeviceByCategories(state, panelId))
    const panelTraits = useSelector((state) => selectPanelTraits(state, panelId))

    const {isLoading, error, byIds} = devices.list[panelId] || {
        isLoading: true,
    }

    const panel = panels.store.byIds[panelId] || {}
    const walktest = devices.walktest[panelId] || {}
    const {current, changes} = panels.configuration[panelId] || {}

    return {
        panelId,
        panel: {...panel, traits: panelTraits},
        configuration: current,
        changes,
        isLoading,
        error,
        devices: byCategories,
        byId: byIds,
        showWalktest: !!walktest.isActive,
        walktest,
        ...bindActionCreators(
            {
                fetch: () => fetchDevices(panelId),
                showAddDevice: () => showAddDeviceModal(panelId),
                refreshRssi: () => refreshRssi(panelId),
                refreshGSM: () => refreshGSM(panelId),
                toggleWalktest: () => toggleWalktest(panelId),
                fetchWalktest: () => fetchWalktest(panelId),
                startWalktest: () => startWalktest(panelId),
                stopWalktest: () => stopWalktest(panelId),
            },
            dispatch
        ),
    }
}

export function useDeviceVideoOnDemand({panelId, device: {zone: zoneId}}) {
    const dispatch = useDispatch()

    const permission = usePermission({
        isRequestAllowed: request,
        isExportAllowed: exports,
    })

    return {
        ...permission,
        ...useSelector((state) =>
            selectDeviceVOD(state, {panelId, device: {zone: zoneId}})
        ),
        ...useSelector((state) => selectVod(state, {panelId})),
        ...bindActionCreators(
            {
                fetch: () => fetchVideo(panelId, zoneId),
                erase: () => erase(panelId, zoneId),
                onStartRecord: () => start(panelId, zoneId),
            },
            dispatch
        ),
    }
}

export function usePlinkDebugCommands(panelId) {
    const dispatch = useDispatch()

    return bindActionCreators(
        {
            reboot: () => showRebootPlink(panelId),
            enableLogging: () => showEnablePlinkLogging(panelId),
            disableLogging: () => showDisablePlinkLogging(panelId),
            sendLog: () => sendPlinkLog(panelId),
            enableSSH: () => enablePlinkSSH(panelId),
            disableSSH: () => disablePlinkSSH(panelId),
        },
        dispatch
    )
}

export function useDeviceActions(panelId, device) {
    const dispatch = useDispatch()

    return bindActionCreators(
        {
            removeDevice: () => removeDevice(panelId, device.id),
            renameDevice: () => showRenameDeviceModal(panelId, device),
        },
        dispatch
    )
}

export function useDeviceReference(panelId) {
    const dispatch = useDispatch()
    const reference = useSelector(({devices}) => devices.reference)
    const panels = useSelector(({panels}) => panels)

    const {isNeo} = panels.store.byIds[panelId] || {}
    const {hasPartitions} = panels.state.byIds[panelId] || {}
    const vendor = isNeo ? VENDOR_NEO : VENDOR_POWER_MASTER

    const ref = get(reference, vendor, {})

    return {
        hasPartitions,
        isNeo,
        vendor,
        isLoading: !Object.keys(ref).length,
        reference: ref,
        ...bindActionCreators(
            {
                fetchReference: () => fetchReference(vendor),
            },
            dispatch
        ),
    }
}
