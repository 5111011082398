import {useDispatch, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'

import * as actions from 'modules/panelInstallers/list/actions'
import {selectPanelInstallersList} from 'modules/panelInstallers/list/selectors'

export default function usePanelInstallers(panelId) {
    const dispatch = useDispatch()

    const {rows, isLoading} = useSelector((state) =>
        selectPanelInstallersList(state, {panelId})
    )
    const {init, fetch, reset, startPoll, stopPoll, purgeList} = bindActionCreators(
        {
            init: actions.init,
            fetch: actions.fetch,
            reset: actions.reset,
            startPoll: actions.startPoll,
            stopPoll: actions.stopPoll,
            purgeList: actions.purgeList,
        },
        dispatch
    )

    return {
        rows,
        isLoading,
        init,
        fetch,
        reset,
        startPoll,
        stopPoll,
        purgeList,
    }
}
