import {takeEvery, all, put, call} from 'redux-saga/effects'

import {panelInteractiveUsers} from 'api/interactiveUsers'
import * as actions from './actions'
import {snackShow} from 'modules/snacks'
import {setUserCode} from 'modules/forms/handlers'

// We use this saga for PanelUserSelect in src/modals/InteractiveUsers/AssignUserToPanel.js:24
export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(setUserCode.FAILURE, watchSetUserPin),
    ])
}

function* watchFetch({payload: panelId}) {
    try {
        const {rows} = yield call(panelInteractiveUsers, {panelId})
        yield put(
            actions.update({
                panelId,
                data: rows.filter(({interactiveUserId}) => interactiveUserId === null),
            })
        )

        yield put(actions.setLoading(false))
    } catch (error) {
        yield put(actions.setLoading(false))
        yield put(snackShow(error.message))
    }
}

function* watchSetUserPin({payload: {error}}) {
    if (error) {
        yield put(snackShow(error))
    }
}
