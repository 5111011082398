import React from 'react'
import Pager from 'ipmp-react-ui/Pager'

import list from 'permissions/pushBasicConfiguration/list'

import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import usePushBasicConfigurations from 'hooks/pages/usePushBasicConfigurations'

const PagerVisibility = withVisibility()(Pager)

export default function PushBasicConfigurationPager() {
    const {isPermitted} = usePermission(list)
    const pushBasicConfigurations = usePushBasicConfigurations()

    return <PagerVisibility {...pushBasicConfigurations} isVisible={isPermitted} />
}
