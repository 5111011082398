import {all, call, takeEvery, put} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/configuration'
import filterConfiguration, {
    createDependencyFilter,
} from 'utils/configuration/filterConfiguration'
import configurationToHash from 'utils/configuration/configurationToHash'

export default function* () {
    yield all([takeEvery(actions.fetch, watchFetch)])
}

function* watchFetch({payload: {panelId, configurationId}}) {
    try {
        const allConfiguration = yield call(api.one, panelId, configurationId)
        const configuration = filterConfiguration(
            allConfiguration,
            createDependencyFilter(configurationToHash(allConfiguration))
        )

        yield put(actions.receive(configuration, panelId, configurationId))
    } catch (error) {
        yield put(actions.receive(error))
    }
}
