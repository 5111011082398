import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import __ from 'utils/i18n'
import RecentHistory from 'components/Nav/NavBlinds/RecentHistory'
import {untrack, untrackFinished} from 'modules/batches/widget/actions'
import ProcessBatches from 'components/Processes/ProcessBatches'
import {selectWidgetBatches} from 'modules/batches/widget/selectors'
import {showBatchInfoNavBlind, hide as navBlindHide} from 'modules/navBlind/actions'

import Tabs, {Tab} from 'ipmp-react-ui/Tabs'
import {ReactComponent as PinnedIcon} from 'ipmp-react-ui/icons/pinned.svg'
import {ReactComponent as UnPinnedIcon} from 'ipmp-react-ui/icons/unpinned.svg'

const Processes = compose(
    connect(
        (state) => ({
            batches: selectWidgetBatches(state),
        }),
        (dispatcher) =>
            bindActionCreators(
                {
                    untrack,
                    untrackFinished,
                    showBatchInfoNavBlind,
                    navBlindHide,
                },
                dispatcher
            )
    )
)(ProcessBatches)

const Sidebar = (props) => {
    return (
        <div className="main-sidebar" ref={props.sidebarRef}>
            <Tabs>
                <Tab name={__('My Jobs')}>
                    <Processes
                        hideSidebar={props.hide}
                        processRefHandler={props.processRefHandler}
                        selectedBatchId={props.selectedBatchId}
                    />
                </Tab>
                <Tab name={__('History')}>
                    <div className="recentHistory-wrapper">
                        <RecentHistory hide={props.hide} />
                    </div>
                </Tab>
            </Tabs>
            {props.isPinned ? (
                <PinnedIcon className="pin-btn" onClick={props.pin} />
            ) : (
                <UnPinnedIcon className="pin-btn" onClick={props.pin} />
            )}
            {props.children}
        </div>
    )
}

export default Sidebar
