import React from 'react'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__} from 'utils/i18n'

const PROFILES_COUNT_PER_LINE = 5

const getEventStatusClassName = (isEnabled) =>
    isEnabled ? 'notification--enabled' : 'notification--disabled'

export default function EventProfileList({profiles, availableProfiles}) {
    return (
        <List className="card-content groupForm-info">
            {Object.keys(availableProfiles).map((profile, index) => {
                return (
                    <React.Fragment key={profile}>
                        {index > 0 && index % PROFILES_COUNT_PER_LINE === 0 && (
                            <hr className={'notice-item-border'} />
                        )}
                        <ListHintItem
                            key={profile}
                            name={availableProfiles[profile]}
                            text={profiles[profile] ? __('Enabled') : __('Disabled')}
                            className={getEventStatusClassName(profiles[profile])}
                        />
                    </React.Fragment>
                )
            })}
        </List>
    )
}
