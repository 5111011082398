import {createSelector} from 'reselect'

import {selectInstallerList} from '../list/selectors'

export const selectInstallersByPage = createSelector(
    selectInstallerList,
    (state) => state.installers.store.byIds,
    ({isLoading, page, error, total, start}, store) => ({
        rows: page.reduce((acc, id) => (id in store ? [...acc, store[id]] : acc), []),
        isLoading,
        error,
        total,
        start,
    })
)
