import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    receive,
    reset,
    setStart,
    setQuery,
    clearFilters,
    fetch,
    startPoll,
    stopPoll,
    purgeList,
} = createListActions('CENTRAL_STATIONS/GROUP_LIST')
