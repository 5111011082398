import {bindActionCreators, compose} from 'redux'

import {showAddUserModal} from 'modules/modals/actions'

import {create} from 'permissions/users/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import {ReactComponent as AddIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {connect} from 'react-redux'

export default compose(
    withPermission({isVisible: create}),
    withVisibility(),
    withProps(() => ({
        Icon: AddIcon,
        label: __('Add user'),
        primary: true,
    })),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: showAddUserModal,
            },
            dispatch
        )
    )
)(Button)
