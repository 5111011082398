import {__} from 'utils/i18n'
import StringValue from 'components/Configuration/Row/StringValue'

const regex =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

const empty = '0.0.0.0'

export default class IpValue extends StringValue {
    getMaxLength() {
        return '000.000.000.000'.length
    }

    sanitize(value) {
        return value || empty
    }

    getValue() {
        const {value} = this.props

        if (value === empty) {
            return ''
        }

        return value
    }

    validate(value) {
        if (value && !regex.test(value)) {
            return __('Not valid IPv4 address')
        }
    }
}
