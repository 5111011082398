import React from 'react'
import PropTypes from 'prop-types'

import PageCard from 'ipmp-react-ui/PageCard'
import Error from 'ipmp-react-ui/Error'

export default function EmptyPageCard({title, Icon, message, Button}) {
    return (
        <PageCard className="emptyPageCard" title={title}>
            {Icon}
            <Error title={message} />
            {Button}
        </PageCard>
    )
}

EmptyPageCard.propTypes = {
    title: PropTypes.string,
    Icon: PropTypes.node,
    message: PropTypes.string,
    Button: PropTypes.node,
}
