import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Bar from 'ipmp-react-ui/Bar'
import BlindModalCardContentHeaderWrapper from 'ipmp-react-ui/BlindModalCardContentHeaderWrapper'

import usePermission from 'hooks/usePermission'
import {any} from 'permissions/createPermission'
import {reset, remove} from 'permissions/installers/actions'

import Info from './Info'
import Status from './Status'
import Panels from './Panels'
import CardActions from './CardActions'

export default function FirstBlindContent({
    row,
    openSecondBlind,
    setFooterContent,
    closeBlind,
}) {
    const {isPermitted} = usePermission(any(reset, remove))

    useEffect(() => {
        setFooterContent(
            isPermitted
                ? () => <CardActions installer={row} onClose={closeBlind} />
                : null
        )
    }, [row])

    return (
        <>
            <BlindModalCardContentHeaderWrapper>
                <Info installer={row} />
            </BlindModalCardContentHeaderWrapper>
            <Status installer={row} />
            <Bar divider />
            <Panels installer={row} addPanel={openSecondBlind} />
        </>
    )
}

FirstBlindContent.propTypes = {
    row: PropTypes.object.isRequired,
    openSecondBlind: PropTypes.func.isRequired,
    setFooterContent: PropTypes.func.isRequired,
    closeBlind: PropTypes.func.isRequired,
}
