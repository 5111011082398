import React from 'react'

import {__} from 'utils/i18n'

import {
    CS_STATUS_HEARTBEAT,
    CS_STATUS_NOT_APPLICABLE,
    CS_STATUS_OFFLINE,
    CS_STATUS_ONLINE,
} from 'constants/eventDelivery'

export default function useCSStatus(status) {
    switch (status) {
        case CS_STATUS_ONLINE:
            return (
                <div className="col event-centralstation-status event-centralstation-status-online">
                    {__('Online')}
                </div>
            )
        case CS_STATUS_OFFLINE:
            return (
                <div className="col event-centralstation-status event-centralstation-status-offline">
                    {__('Offline')}
                </div>
            )
        case CS_STATUS_HEARTBEAT:
            return (
                <div className="col event-centralstation-status event-centralstation-status-heartbeat">
                    {__('Heartbeat')}
                </div>
            )
        case CS_STATUS_NOT_APPLICABLE:
            return (
                <div className="col event-centralstation-status event-centralstation-status-notapplicable">
                    {__('Not Applicable')}
                </div>
            )
        default:
            return <div className="col">{}</div>
    }
}
