import React, {Component} from 'react'

import {InputComponent as Input} from 'ipmp-react-ui/Input'
import Radiobox from 'ipmp-react-ui/Radiobox'
import InputDatePicker from 'ipmp-react-ui/InputDatePicker'

import {__} from 'utils/i18n'
import {FormRow, FormSection, FormValidationGroup} from 'ipmp-react-ui/Form'
import timezones from 'utils/timezones'

const MODE_RECUR_CONSTANTLY = 'constantly'
const MODE_RECUR_END_AFTER = 'endAfter'
const MODE_RECUR_END_DATE = 'endDate'

export default class extends Component {
    state = {
        mode: MODE_RECUR_CONSTANTLY,
    }

    setMode = (mode) => {
        this.setState({
            mode,
        })
    }

    render() {
        const {mode} = this.state

        return (
            <FormSection title={__('Range of recurrence')}>
                <Radiobox
                    {...{
                        label: __('Recur constantly'),
                        name: '_rangeRecurConstantly',
                        checked: mode === MODE_RECUR_CONSTANTLY,
                        onChange: () => this.setMode(MODE_RECUR_CONSTANTLY),
                    }}
                />

                <FormValidationGroup name="range.times">
                    <FormRow>
                        <Radiobox
                            {...{
                                label: __('End after'),
                                checked: mode === MODE_RECUR_END_AFTER,
                                onChange: () => this.setMode(MODE_RECUR_END_AFTER),
                                name: '_rangeEndAfter',
                            }}
                        />

                        <Input
                            {...{
                                type: 'number',
                                defaultValue: 10,
                                name: 'range.times',
                                max: mode === MODE_RECUR_END_AFTER ? 300 : undefined,
                                onFocus: () => this.setMode(MODE_RECUR_END_AFTER),
                                dataTestId: 'end-after-input',
                            }}
                        />
                    </FormRow>
                </FormValidationGroup>

                <FormRow>
                    <Radiobox
                        {...{
                            label: __('End date'),
                            checked: mode === MODE_RECUR_END_DATE,
                            onChange: () => this.setMode(MODE_RECUR_END_DATE),
                            name: '_rangeEndDateEnabled',
                        }}
                    />

                    <InputDatePicker
                        {...{
                            onFocus: () => this.setMode(MODE_RECUR_END_DATE),
                            min: timezones.server().startOf('day'),
                            name: 'range.end_date',
                            dataTestId: 'end-date-input',
                        }}
                    />
                </FormRow>
            </FormSection>
        )
    }
}
