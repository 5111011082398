import {createAction} from 'redux-actions'

export const fetchAll = createAction('GROUPS/APP_CUSTOMIZATIONS/ALL/FETCH')

export const receiveAll = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ALL/RECEIVE',
    (customizations) => customizations
)

export const fetchOne = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ONE/FETCH',
    (groupId) => groupId
)

export const receiveOne = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ONE/RECEIVE',
    (groupId, customization) => ({customization, groupId})
)

export const selectOne = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ONE/SELECT',
    (groupId, customizationId) => ({groupId, customizationId})
)

export const persistOne = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ONE/PERSIST',
    (groupId, customizationId) => ({groupId, customizationId})
)

export const updatePersisted = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ONE/UPDATE_PERSIST',
    (groupId, customizationId) => ({groupId, customizationId})
)

export const clearUncommitted = createAction(
    'GROUPS/APP_CUSTOMIZATIONS/ONE/CANCEL_SELECT',
    (groupId) => groupId
)
