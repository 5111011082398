import {ScrollView} from 'ipmp-react-ui/Layout'
import React from 'react'

import BatchesTable from './BatchesTable'
import BatchesSearch from './BatchesSearch'
import BatchesGridBar from './BatchesGridBar'
import RunnersPage from './Runners/RunnersPage'

import Page from 'ipmp-react-ui/Page'
import Layout from 'ipmp-react-ui/Layout'

class BatchesPage extends Page {
    renderTop() {
        return <BatchesSearch />
    }

    renderBar() {
        return <BatchesGridBar />
    }

    renderContent() {
        return (
            <Layout className="batches-page">
                <ScrollView>
                    <BatchesTable />
                </ScrollView>
            </Layout>
        )
    }
}

const Batches = (props) => {
    if (props.match.params.id) {
        return <RunnersPage {...props} />
    }

    return <BatchesPage {...props} />
}

export default Batches
