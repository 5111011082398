import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Line} from 'react-chartjs-2'

import __ from 'utils/i18n'
import {timeTitleCallback, timeXAxes} from 'utils/chartsOption'

export default class ConnectedPanels extends Component {
    static propTypes = {
        data: PropTypes.array,
        onlineData: PropTypes.array,
        from: PropTypes.instanceOf(Date),
        to: PropTypes.instanceOf(Date),
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    get data() {
        const {data, onlineData} = this.props

        return {
            datasets: [
                {
                    label: __('Online, units'),
                    data: onlineData,
                    borderColor: 'rgba(77, 186, 37, 1)',
                    backgroundColor: 'rgba(77, 186, 37, .65)',
                    spanGaps: true,
                    fill: true,
                    order: 1,
                },
                {
                    label: __('Connected, units'),
                    data: data,
                    borderColor: 'rgba(45,114,186,1)',
                    backgroundColor: 'rgba(45,114,186,.65)',
                    spanGaps: true,
                    fill: true,
                    order: 0,
                },
            ],
        }
    }

    get options() {
        const {from, to} = this.props

        return {
            animation: false,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: timeTitleCallback,
                },
            },
            scales: {
                y: {
                    min: 0,
                },
                x: timeXAxes(from, to),
            },
        }
    }

    render() {
        return (
            <div className="card dashboard-secondary">
                <h3 className="dashboard-chartTitle">
                    {__('Online (supervision) / Connected panels')}
                </h3>
                <Line data={this.data} options={this.options} />
            </div>
        )
    }
}
