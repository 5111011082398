import createPermission, {any, every} from 'permissions/createPermission'
import centralStationsList from 'permissions/centralStations/list'
import {edit} from 'permissions/centralStations/actions'

export const general = createPermission('groups.list')
export const notificationsListEventsProfiles = createPermission(
    'groups.notifications.listEventsProfiles'
)
export const notificationsListUserNotifications = createPermission(
    'groups.notifications.listUserNotifications'
)
export const notificationsList = createPermission(
    any(notificationsListEventsProfiles, notificationsListUserNotifications)
)
export const notificationsEdit = createPermission('groups.notifications.edit')
export const stations = createPermission(centralStationsList, edit)
export const customizations = createPermission('groups.customization')

export default createPermission(
    every(
        general,
        any(
            stations,
            customizations,
            notificationsListEventsProfiles,
            notificationsListUserNotifications
        )
    )
)
