import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Form from 'ipmp-react-ui/Form'
import ToggleBox from 'ipmp-react-ui/ToggleBox'

import PgmStateParametersControl from 'components/Had/PgmStateParametersControl'

import __ from 'utils/i18n'
import {
    HAD_STATE_OFF,
    HAD_STATE_ON,
    isOff,
    isOn,
    isStateless,
} from 'constants/had/hadState'
import {
    getPgmStateParamTimestamp,
    HAD_STATE_PARAMETER_CUSTOM,
    HAD_STATE_PARAMETER_CUSTOM_MAXIMUM_TIMEOUT,
} from 'constants/had'
import {resolvePgmCommands} from 'constants/had/command'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {withPermission, withRejection} from 'containers/withPermission'
import {pgmControl, genericEnable, genericDisable} from 'permissions/panel/output/actions'
import Empty from 'ui/Empty'
import {switcherTypeStateTitleByPgmState} from 'constants/had/switcher'
import SetHadPGMStatelessButton from 'pages/Panel/Had/SetHadPGMStatelessButton'

const PgmControlFormContent = ({had, pgmState, handleChange, isDisabled}) => {
    if ((had?.supportedCommands || []).length === 0) {
        return null
    }

    const isStatelessPgm = isStateless(had?.state?.pgm)

    if (isStatelessPgm) {
        return (
            <div className="pgmControlForm__stateless">
                <span className="checkbox-label">
                    {__('Device control')}{' '}
                    <Empty>({switcherTypeStateTitleByPgmState(pgmState)})</Empty>
                </span>

                <SetHadPGMStatelessButton id={had?.id} deviceType={had?.deviceType} />
            </div>
        )
    }

    return (
        <ToggleBox
            value={had?.state?.pgm}
            onChange={(e) => {
                handleChange(e.target.checked)
            }}
            checked={isOn(pgmState)}
            defaultChecked={isOn(pgmState)}
            name="state"
            label={
                <>
                    {__('Device control')}{' '}
                    <Empty>({switcherTypeStateTitleByPgmState(pgmState)})</Empty>
                </>
            }
            disabled={isDisabled}
        />
    )
}

const PgmControlForm = ({
    errors,
    isLoading,
    handle,
    had,
    panelId,
    genericEnable,
    genericDisable,
}) => {
    const rules = {
        customTime: {
            numericality: {
                lessThanOrEqualTo: HAD_STATE_PARAMETER_CUSTOM_MAXIMUM_TIMEOUT,
                greaterThanOrEqualTo: 1,
            },
            presence: (attr, value) => {
                return value.parameters === HAD_STATE_PARAMETER_CUSTOM
            },
        },
    }
    const [pgmState, setPgmState] = useState(had?.state?.pgm)

    const onSubmitHandler = ({parameters, customTime}) => {
        parameters = isNaN(Number(parameters)) ? parameters : Number(parameters)
        handle &&
            handle(
                new HadControlForm({
                    commands: resolvePgmCommands({
                        mode: Number(pgmState),
                        supportedCommands: had?.supportedCommands,
                        timestamp: getPgmStateParamTimestamp({parameters, customTime}),
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }

    const handleChange = (checked) => {
        setPgmState(checked ? HAD_STATE_ON : HAD_STATE_OFF)
    }
    const isDisabled =
        (isOn(had?.state?.pgm) && !genericDisable) ||
        (isOff(had?.state?.pgm) && !genericEnable)
    const isParametersControl = had.hasTimer && isOn(pgmState) && genericEnable

    return (
        <Form
            className="pgmControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
            rules={rules}
        >
            <PgmControlFormContent
                had={had}
                pgmState={pgmState}
                handleChange={handleChange}
                isDisabled={isDisabled}
            />

            {isParametersControl && (
                <PgmStateParametersControl parametersLabel={__('Parameters')} />
            )}
        </Form>
    )
}

PgmControlForm.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.object,
        type: PropTypes.number,
    }),
}

export default compose(
    withPermission({
        isAllowed: pgmControl,
        genericEnable,
        genericDisable,
    }),
    withRejection()
)(PgmControlForm)
