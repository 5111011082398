import React from 'react'
import PropTypes from 'prop-types'

import {HAD_TYPE_THERMOSTAT, hadStateTitleByType} from 'constants/had'
import {getThermostatModeIcon} from 'constants/had/thermostat'

const HadDeviceState = ({type, state}) => {
    if (!state) {
        return null
    }

    return (
        <div className="deviceHad-state">
            {type === HAD_TYPE_THERMOSTAT
                ? getThermostatModeIcon(state?.thermostat?.mode)
                : null}
            {hadStateTitleByType({hadType: type, state})}
        </div>
    )
}

HadDeviceState.propTypes = {
    type: PropTypes.number,
    state: PropTypes.object,
}

export default HadDeviceState
