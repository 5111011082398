import React, {Component} from 'react'
import PropTypes from 'prop-types'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__, __n} from 'utils/i18n'

export default class PowerMasterCommunications extends Component {
    static propTypes = {
        settings: PropTypes.objectOf(
            PropTypes.shape({
                isEnabled: PropTypes.bool,
                supervision: PropTypes.number,
                timeout: PropTypes.number,
            })
        ),
        isEncryptionEnabled: PropTypes.bool,
        pscConnection: PropTypes.bool,
    }

    render() {
        const {
            settings: {bba, gprs},
            isEncryptionEnabled,
            upgradeMethod,
        } = this.props

        return (
            <List className="card-content groupForm-info">
                {gprs.isEnabled && (
                    <ListHintItem
                        name={__('Cellular Supervision period')}
                        text={__n('%d second', '%d seconds', gprs.supervision)}
                    />
                )}
                {gprs.isEnabled && (
                    <ListHintItem
                        name={__('Cellular Offline Timer')}
                        text={__n('%d second', '%d seconds', gprs.timeout)}
                    />
                )}
                {gprs.isEnabled && <hr className={'notice-item-border'} />}
                <ListHintItem
                    name={__('Broadband Supervision period')}
                    text={__n('%d second', '%d seconds', bba.supervision)}
                />

                <ListHintItem
                    name={__('Broadband Offline Timer')}
                    text={`${bba.timeout} seconds`}
                />
                <hr className={'notice-item-border'} />
                <ListHintItem
                    name={__('Broadband Encryption')}
                    text={isEncryptionEnabled ? __('Enabled') : __('Disabled')}
                />
                {!gprs.isEnabled && (
                    <ListHintItem
                        name={__('Cellular Supervision')}
                        text={__('Disabled')}
                    />
                )}
                {!gprs.isEnabled && <hr className={'notice-item-border'} />}
                <ListHintItem
                    name={__('Upgrade Method')}
                    text={upgradeMethod === 'gprs' ? __('GPRS') : __('Broadband')}
                />
            </List>
        )
    }
}
