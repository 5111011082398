import React from 'react'
import Pager from 'ipmp-react-ui/Pager'
import useEventRules from 'hooks/pages/useEventRules'

export default function EventRulesPager() {
    const {start, total, perPage, setFrontEndStart, onPerPageChange} = useEventRules()

    return (
        <Pager
            start={start}
            total={total}
            perPage={perPage}
            onPageChange={setFrontEndStart}
            onPerPageChange={onPerPageChange}
        />
    )
}
