import {createSelector} from 'reselect'
import find from 'lodash-es/find'

export const selectEventsListRows = createSelector(
    (state) => state.events.list.page,
    (state) => state.events.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)
export const selectEventsListRowsByPanel = createSelector(
    (state) => state.panels.events.rows,
    (state) => state.events.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

const selectEventList = createSelector(
    (state) => state.events.store.byIds,
    (eventList) => eventList
)

export const selectEventsOverBoundRows = createSelector(
    (state) => state.events.list.overbound,
    (state) => state.events.store.byIds,
    (overbound, store) => {
        const result = {}

        if (overbound.next && overbound.next.length) {
            result.next = store[overbound.next.slice(0, 1).pop()]
        }

        if (overbound.prev && overbound.prev.length) {
            result.prev = store[overbound.prev.slice(-1).pop()]
        }

        return result
    }
)

export const selectEventById = createSelector(
    selectEventList,
    (state, eventId) => eventId,
    (eventsList, eventId) => find(eventsList, {id: eventId})
)

const getEventPrevNext = (eventList, eventId) => {
    const currentIndex = eventList.findIndex((event) => event.id === eventId)

    const prev = eventList[currentIndex - 1] ?? null
    const next = eventList[currentIndex + 1] ?? null

    return [prev, next]
}

export const selectEventBlindModal = createSelector(
    selectEventsListRows,
    selectEventById,
    (state, eventId) => eventId,
    (eventsList, event, eventId) => ({
        eventBlindModalPrevNext: getEventPrevNext(eventsList, eventId),
        event,
    })
)

export const selectEventBlindModalByPanel = createSelector(
    selectEventsListRowsByPanel,
    selectEventById,
    (state, eventId) => eventId,
    (eventsList, event, eventId) => ({
        eventBlindModalPrevNext: getEventPrevNext(eventsList, eventId),
        event,
    })
)
