export const HAD_STATE_ON = 0
export const HAD_STATE_OFF = 1
export const HAD_STATE_STATELESS = 2

export const hadStates = [HAD_STATE_ON, HAD_STATE_OFF, HAD_STATE_STATELESS]

export const isOn = (state) => state === HAD_STATE_ON
export const isOff = (state) => state === HAD_STATE_OFF
export const isStateless = (state) => state === HAD_STATE_STATELESS

export const reverseHadStateMap = new Map([
    [HAD_STATE_ON, HAD_STATE_OFF],
    [HAD_STATE_OFF, HAD_STATE_ON],
])
