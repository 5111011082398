import React from 'react'

import {run} from 'permissions/panel/devices/walktest'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'

const ButtonVisible = withVisibility()(Button)

export default function StopWalktestButton() {
    const {isPermitted} = usePermission(run)
    const {
        stopWalktest,
        walktest: {isStopping},
    } = useDevices()

    return (
        <ButtonVisible
            isVisible={isPermitted}
            onClick={stopWalktest}
            disabled={isStopping}
            Icon={isStopping ? Spinner : null}
            label={__('Stop Walktest')}
        />
    )
}
