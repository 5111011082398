import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import withForm from 'containers/withForm'
import ModalCardForm from 'ui/ModalCardForm'
import {activateMasterGUIPanel} from 'modules/forms/handlers'

const rules = {
    masterUserCode: {
        presence: true,
        format: {
            pattern: /^(\d{4}|\d{6}|\d{8})$/,
            flags: 'i',
            message: __('The activation code should be 4/6/8 digits long'),
        },
    },
}

function ActivateMasterPanelGUI(props) {
    const handle = ({masterUserCode}) => {
        const {unitId, handle} = props

        handle(unitId, masterUserCode)
    }

    return (
        <ModalCardForm
            onSubmit={handle}
            header={__('Master User Activation')}
            rules={rules}
            submitLabel={__('Activate')}
            {...props}
        >
            <Input
                autoFocus
                name="masterUserCode"
                label={__('Master User Code')}
                maxLength="8"
                minLength="4"
                dataTestId="masterUserCode"
            />
        </ModalCardForm>
    )
}

ActivateMasterPanelGUI.propTypes = {
    handle: PropTypes.func.isRequired,
    unitId: PropTypes.number.isRequired,
}

export default withForm(activateMasterGUIPanel)(ActivateMasterPanelGUI)
