import React from 'react'
import {compose} from 'redux'

import {refreshGsmRssi} from 'permissions/panel/devices/actions'

import {withVisibility} from 'containers/withVisibility'

import RefreshButton from 'ipmp-react-ui/RefreshButton'

import {__} from 'utils/i18n'
import {withFeatureRejection} from 'containers/withFeature'
import {selectRSSIGsm} from 'modules/features/store/selectors'
import Void from 'components/Void'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'

const ButtonVisibleWithFeature = compose(
    withVisibility(),
    withFeatureRejection(selectRSSIGsm, Void)
)(RefreshButton)

export default function RefreshGSMButton() {
    const {isPermitted} = usePermission(refreshGsmRssi)
    const devicesProps = useDevices()
    const {
        refreshGSM,
        panel: {GSMRefreshProcess},
    } = devicesProps

    return (
        <ButtonVisibleWithFeature
            {...devicesProps}
            isVisible={isPermitted}
            label={__('Refresh GSM')}
            onClick={refreshGSM}
            isRefreshing={!!GSMRefreshProcess}
        />
    )
}
