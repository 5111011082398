import generateFilterString from 'api/base/generateFilterString'
import axios from 'axios'
import {stringify} from 'query-string'

import apiFormat from 'api/interceptors/apiFormat'
import acceptLanguage from 'api/interceptors/acceptLanguage'
import translatedErrors from 'api/interceptors/translatedErrors'
import mapValidationErrors from 'api/base/mapValidationErrors'

import {map as userMap, keysMap as userKeysMap} from 'api/auth.map'
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {keysMap, mapPanel as panelMap} from 'api/panels.map'

class CrossHttp {
    constructor(baseurl) {
        this.axios = axios.create({
            baseURL: baseurl + '/api/',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
            },
            timeout: 30000,
            withCredentials: true,
        })

        apiFormat(this.axios)
        acceptLanguage(this.axios)
        translatedErrors(this.axios)
    }

    whoami() {
        return this.axios.get('login/whoami').then(userMap)
    }

    login(email, password) {
        return this.axios
            .post('login/sign', {
                usr_email: email,
                usr_password: password,
            })
            .then(userMap, (e) => mapValidationErrors(e, userKeysMap))
    }

    getPanels(filters, query = '') {
        const data = {
            filter: generateFilterString(filters, keysMap),
            query,
            count: 10,
            start: 0,
        }

        return this.axios
            .get('units_all/getallunits' + '?' + stringify(data))
            .then(createRowsResponseParser(panelMap))
    }
}

export default CrossHttp
