import React from 'react'
import {bindActionCreators} from 'redux'
import {useDispatch} from 'react-redux'

import {InitiateRemoteInspectionButton as InitiateRemoteInspectionButtonBase} from 'pages/RemoteInspection/Bar/InitiateRemoteInspectionButton'
import {initiateNow} from 'modules/runners/store/actions'
import {__} from 'utils/i18n'
import {withSelectionFeature} from 'containers/withFeature'
import {REMOTE_INSPECTION} from 'constants/features'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const InitiateRemoteInspectionButtonSelection = withSelectionFeature(
    REMOTE_INSPECTION,
    selectSelectedRunnersFeature
)(InitiateRemoteInspectionButtonBase)

export default function InitiateRemoteInspectionButton() {
    const dispatch = useDispatch()

    const {initiateNowProxy} = bindActionCreators(
        {
            initiateNowProxy: initiateNow,
        },
        dispatch
    )

    const remoteInspections = useRemoteInspections()

    return (
        <InitiateRemoteInspectionButtonSelection
            {...remoteInspections}
            label={__('Run inspection')}
            onClick={initiateNowProxy}
        />
    )
}
