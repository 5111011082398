import {timestampToDate} from 'api/base/parseDate'
import mapper from 'utils/dataMapper'
import {convertBytes, fileSizeUnit} from 'utils/memoryUtils'

export const mapFetchResponse = mapper((data) => ({
    rows: Array.from(data.rows, mapSystemLog),
    refreshProcess: data.refresh_process,
}))

export const mapSystemLog = mapper((data) => ({
    id: parseInt(data.id),
    filename: data.filename,
    timestamp: timestampToDate(data.created_timestamp),
    filesize: convertBytes(data.file_size),
    fileSizeUnit: fileSizeUnit(data.file_size),
}))
