import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {notificationsList} from 'permissions/group/navigation'

import {withRejection} from 'containers/withPermission'

import NotificationProfile from './NotificationProfile'
import EventProfile from './EventProfile'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import useGroupNotifications from 'hooks/pages/useGroupNotifications'

export function NotificationsBase({settings, groupId}) {
    return (
        <div className="groupInfo notifications-list">
            <div className="card">
                <EventProfile settings={settings} groupId={groupId} />
            </div>
            <div className="card">
                <NotificationProfile settings={settings} groupId={groupId} />
            </div>
        </div>
    )
}

NotificationsBase.propTypes = {
    settings: PropTypes.shape({
        eventProfile: PropTypes.object,
        notificationProfile: PropTypes.object,
        availableEventProfiles: PropTypes.shape({
            eventProfile: PropTypes.object,
            notificationProfile: PropTypes.object,
        }),
    }),
    groupId: PropTypes.number.isRequired,
}

const ConnectedNotifications = compose(
    withRejection(),
    withLoader(({fetch}) => fetch())
)(NotificationsBase)

export default function Notifications({group: {id: groupId}}) {
    const {isPermitted} = usePermission(notificationsList)
    const settings = useGroupNotifications(groupId)
    const props = {groupId, ...settings}

    return <ConnectedNotifications isAllowed={isPermitted} {...props} />
}
