import withLifeCycle from 'containers/withLifeCycle'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import withLoader from 'containers/withLoader'
import withProps from 'containers/withProps'
import {__} from 'utils/i18n'
import withNotificationTypes from 'containers/withNotificationTypes'
import RemoveNotificationType from './TableCell/RemoveNotificationType'
import EditNotificationType from './TableCell/EditNotificationType'

const columns = [
    {
        name: () => __('Notification Type'),
        render: ({name}) => name,
    },
    {
        tooltip: false,
        width: 40,
        fixed: true,
        component: EditNotificationType,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveNotificationType,
    },
]

export default compose(
    withNotificationTypes(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No notification types found'),
    })
)(Table)
