import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import Notification from './Notification'

const CLOSE_TIMEOUT = 5000

export function NotificationsBox({notifications}) {
    const [fromDateTime] = useState(new Date().getTime())
    const [hashes, setHashes] = useState([])
    const [localNotifications, setLocalNotifications] = useState([])

    useEffect(() => {
        const newHashes = []
        const localHashes = []
        const tempNotifications = [...localNotifications]

        notifications.forEach((notification) => {
            if (notification.dateTime.getTime() < fromDateTime) {
                return
            }

            if (!hashes.includes(notification.hash)) {
                newHashes.push(notification.hash)
                tempNotifications.unshift(notification)
            }

            localHashes.push(notification.hash)
        })

        if (newHashes.length > 0) {
            setTimeout(() => {
                closeNotifications(newHashes)
            }, CLOSE_TIMEOUT)
        }

        setHashes(localHashes)
        setLocalNotifications(tempNotifications)
    }, [notifications])

    const localNotificationsRef = useRef(localNotifications)
    localNotificationsRef.current = localNotifications

    const closeNotifications = (hashes) => {
        setLocalNotifications(
            localNotificationsRef.current.filter(({hash}) => !hashes.includes(hash))
        )
    }

    const closeNotification = (hash) => closeNotifications([hash])

    return (
        <div className="c-notifications-box">
            {localNotifications.map((notification) => (
                <Notification
                    key={notification.hash}
                    onClose={closeNotification}
                    notification={notification}
                />
            ))}
        </div>
    )
}

NotificationsBox.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            hash: PropTypes.string.isRequired,
            title: PropTypes.node.isRequired,
            description: PropTypes.node.isRequired,
            dateTime: PropTypes.objectOf(Date).isRequired,
            Slot: PropTypes.node,
            onClick: PropTypes.func,
        })
    ),
}
