import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {AppToggle} from 'components/DropDown/ApplicationInteractiveDropdown'

import __ from 'utils/i18n'
import {user} from 'permissions/panels/apps'
import {withFeatureRejection} from 'containers/withFeature'
import {selectAppUser} from 'modules/features/store/selectors'
import Void from 'components/Void'
import {useActions} from 'modules/higherOrder/useActions'
import usePanel from 'hooks/pages/usePanel'
import {setUserAppState} from 'modules/panels/one/actions'

class ToggleUserAppItemBase extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            configuratorApp: PropTypes.bool,
        }),
    }

    handleUserAppChange = (e) => {
        const {setUserAppState, panel} = this.props
        setUserAppState(panel, e.target.checked)
    }

    render() {
        const {panel} = this.props

        return (
            <div className="menu-item menu-item--toggle">
                <AppToggle
                    permission={user}
                    label={__('Customer App')}
                    onChange={this.handleUserAppChange}
                    checked={panel.userApp}
                />
            </div>
        )
    }
}

const ToggleUserAppItemFeatureRejection = withFeatureRejection(
    selectAppUser,
    Void
)(ToggleUserAppItemBase)

export default function ToggleUserAppItem() {
    const panel = usePanel()

    const {setUserAppState: setUserAppStateAction} = useActions({
        setUserAppState,
    })

    return (
        <ToggleUserAppItemFeatureRejection
            {...panel}
            setUserAppState={setUserAppStateAction}
        />
    )
}
