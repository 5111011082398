import React from 'react'

import {markAsViewed} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'

import ViewedRRIBase from 'components/Cell/RemoteInspections/Actions/ViewedRRI'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const ViewedRRIVisibility = withVisibility()(ViewedRRIBase)

export default function Review(props) {
    const {isPermitted} = usePermission(markAsViewed)
    const remoteInspections = useRemoteInspections()
    const componentProps = {...props, ...remoteInspections}

    return <ViewedRRIVisibility {...componentProps} isVisible={isPermitted} />
}
