import React, {Component} from 'react'
import {compose} from 'redux'

import {backup} from 'permissions/panel/configuration/actions'

import withLifeCycle from 'containers/withLifeCycle'
import {withPermission} from 'containers/withPermission'

import BackupCell from './BackupCell'
import CompareCell from './CompareCell'
import ExportCell from './ExportCell'
import RefreshConfigurationButton from './RefreshConfigurationButton'

import path from 'utils/path'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import DateTime from 'ipmp-react-ui/DateTime'
import {TableGrouped} from 'ipmp-react-ui/Table'
import {__} from 'utils/i18n'

const configurationsColumns = [
    {
        render: ({verified}) => <DateTime datetime={verified} />,
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        component: CompareCell,
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        component: ExportCell,
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        component: BackupCell,
    },
]

class PanelConfigurationsList extends Component {
    handleRowClick = ({current, id}) => {
        const {panelId, history} = this.props

        if (current) {
            history.push(path('panel.configuration.current', {id: panelId}))
        } else {
            history.push(path('panel.configuration.view', {id: panelId, configId: id}))
        }
    }

    handleCompareClick = ({id}) => {
        const {panelId, history} = this.props
        history.push(path('panel.configuration.compare', {id: panelId, configId: id}))
    }

    handleBackupClick = ({id}) => {
        const {markAsBackup} = this.props
        markAsBackup(id)
    }

    separatorCallback = (row) => {
        if (row.current) {
            return __('Current')
        }

        return __('Previous')
    }

    render() {
        const {refresh, rows, allowBackup, panelId} = this.props

        return (
            <div className="configurations">
                <Bar orientation={RIGHT}>
                    <RefreshConfigurationButton onClick={refresh} />
                </Bar>

                <TableGrouped
                    rows={rows}
                    columns={configurationsColumns}
                    separatorCallback={this.separatorCallback}
                    onRowClick={this.handleRowClick}
                    compare={this.handleCompareClick}
                    markAsBackup={this.handleBackupClick}
                    allowBackup={allowBackup}
                    panelId={panelId}
                />
            </div>
        )
    }
}

export default compose(
    withPermission({
        allowBackup: backup,
    }),
    withLifeCycle({
        onMount({startPoll}) {
            //startPoll()
        },
        onUnmount({startPoll}) {
            //startPoll()
        },
    })
)(PanelConfigurationsList)
