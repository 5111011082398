import React from 'react'

import {showResumeFaultsModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {resumeFaults} from 'permissions/panels/selection'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

const MenuItemVisibility = withVisibility()(MenuItem)

export default function ResumeFaultsItem() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(resumeFaults)
    const {onClick} = useActions({
        onClick: () => showResumeFaultsModal([panelId]),
    })

    return (
        <MenuItemVisibility
            onClick={onClick}
            isVisible={isPermitted}
            children={__('Resume Faults')}
        />
    )
}
