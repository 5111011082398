import createPermission, {any} from 'permissions/createPermission'

export const genericEnable = createPermission('unit.outputs.generic.enable')
export const genericDisable = createPermission('unit.outputs.generic.disable')
export const genericEdit = createPermission('unit.outputs.generic.edit')

export const dimmerLevel = createPermission('unit.outputs.dimmer.level')
export const thermostatMode = createPermission('unit.outputs.thermostat.mode')
export const thermostatTarget = createPermission('unit.outputs.thermostat.target')

export const pgmControl = createPermission(any(genericEnable, genericDisable))
export const thermostatControl = createPermission(any(thermostatMode, thermostatTarget))
