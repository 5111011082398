import React from 'react'
import PropTypes from 'prop-types'
import {isOff, isOn, isStateless} from 'constants/had/hadState'
import {genericDisable, genericEnable, pgmControl} from 'permissions/panel/output/actions'
import usePermission from 'hooks/usePermission'
import SetHadOffButton from 'pages/Panel/Had/List/SetHadOffButton'
import SetHadOnButton from 'pages/Panel/Had/List/SetHadOnButton'
import SetHadPGMOnButton from 'pages/Panel/Had/List/SetHadPGMOnButton'
import {SUPPORTED_COMMAND_HAD_OUTPUT} from 'constants/had'
import SetHadPGMStatelessButton from '../SetHadPGMStatelessButton'

const HadDevicePgmControl = ({had, disabled}) => {
    const permissions = usePermission({
        genericEnable: genericEnable,
        genericDisable: genericDisable,
        pgmControlEnable: pgmControl,
    })

    const isSupportCommands = (had?.supportedCommands || []).length > 0
    const isHadOutput = (had?.supportedCommands || []).includes(
        SUPPORTED_COMMAND_HAD_OUTPUT
    )
    const isStatelessEnable =
        permissions?.pgmControlEnable && isStateless(had?.state?.pgm)
    const isShowDisable =
        permissions?.genericDisable && isSupportCommands && isOn(had?.state?.pgm)
    const isShowEnable =
        permissions?.genericEnable &&
        isHadOutput &&
        !had?.hasTimer &&
        isOff(had?.state?.pgm)
    const isShowWithOptionsEnable =
        permissions?.genericEnable &&
        isHadOutput &&
        Boolean(had?.hasTimer) &&
        isOff(had?.state?.pgm)
    return (
        <>
            {isShowDisable && (
                <SetHadOffButton
                    deviceType={had.deviceType}
                    id={had.id}
                    disabled={disabled}
                />
            )}
            {isShowEnable && (
                <SetHadOnButton
                    deviceType={had.deviceType}
                    id={had.id}
                    disabled={disabled}
                />
            )}
            {isShowWithOptionsEnable && (
                <SetHadPGMOnButton
                    deviceType={had.deviceType}
                    id={had.id}
                    disabled={disabled}
                />
            )}
            {isStatelessEnable && (
                <SetHadPGMStatelessButton
                    deviceType={had.deviceType}
                    id={had.id}
                    disabled={disabled}
                />
            )}
        </>
    )
}

HadDevicePgmControl.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        state: PropTypes.object,
    }).isRequired,
    disabled: PropTypes.bool,
}

export default HadDevicePgmControl
