import {
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
} from 'constants/had/thermostat'
import {DimmerLevelCommand, DimmerModeCommand} from 'constants/had/command/dimmer'
import {
    ThermostatModeCommand,
    ThermostatTargetCommand,
} from 'constants/had/command/thermostat'
import {PgmCommand} from 'constants/had/command/pgm'
import isNil from 'lodash-es/isNil'

export const HAD_COMMAND_OUTPUT = 0
export const HAD_COMMAND_SET_THERMOSTAT_TARGET = 1
export const HAD_COMMAND_SET_THERMOSTAT_MODE = 2
export const HAD_COMMAND_SET_DIM = 3

export const isThermostatModeChangeSupported = (supportedCommands: Array = []) => {
    return supportedCommands.includes(HAD_COMMAND_SET_THERMOSTAT_MODE)
}

export const isThermostatTargetChangeSupported = (supportedCommands: Array = []) => {
    return (supportedCommands || []).includes(HAD_COMMAND_SET_THERMOSTAT_TARGET)
}

export const resolvePgmCommands = ({supportedCommands, mode, timestamp}) => {
    const result = []
    const isCommandChangeModeSupported = supportedCommands?.includes(HAD_COMMAND_OUTPUT)

    if (isCommandChangeModeSupported) {
        result.push(
            new PgmCommand({
                command: HAD_COMMAND_OUTPUT,
                mode,
                timestamp,
            })
        )
    }

    return result
}

export const resolveThermostatCommands = ({
    thermostatMode,
    targetHeat,
    targetCool,
    supportedCommands,
}) => {
    const result = []
    const isCommandChangeModeSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_THERMOSTAT_MODE
    )
    const isCommandChangeTargetSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_THERMOSTAT_TARGET
    )

    if (!isNil(thermostatMode) && isCommandChangeModeSupported) {
        result.push(
            new ThermostatModeCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_MODE,
                mode: thermostatMode,
            })
        )
    }
    if (!isNil(targetHeat) && !isNaN(targetHeat) && isCommandChangeTargetSupported) {
        result.push(
            new ThermostatTargetCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_TARGET,
                target: targetHeat,
                mode: HAD_DEVICE_THERMOSTAT_MODE_HEAT,
            })
        )
    }
    if (!isNil(targetCool) && !isNaN(targetCool) && isCommandChangeTargetSupported) {
        result.push(
            new ThermostatTargetCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_TARGET,
                target: targetCool,
                mode: HAD_DEVICE_THERMOSTAT_MODE_COOL,
            })
        )
    }

    return result
}

export const resolveDimmerCommands = ({dimmerMode, dimmerLevel, supportedCommands}) => {
    const isCommandLevelSupported = supportedCommands?.includes(HAD_COMMAND_SET_DIM)
    const isCommandChangeModeSupported = supportedCommands?.includes(HAD_COMMAND_OUTPUT)
    const isChangedLevel = !isNil(dimmerLevel)
    const isChangedMode = !isNil(dimmerMode)
    const result = []

    if (isChangedLevel && isCommandLevelSupported) {
        result.push(
            new DimmerLevelCommand({command: HAD_COMMAND_SET_DIM, level: dimmerLevel})
        )
    }
    if (isChangedMode && isCommandChangeModeSupported) {
        result.push(
            new DimmerModeCommand({command: HAD_COMMAND_OUTPUT, mode: dimmerMode})
        )
    }

    return result
}
