import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import has from 'lodash-es/has'

import Tabs from 'ipmp-react-ui/Tabs'
import DiagnosticInfo from 'components/Diagnostic/DiagnosticInfo'
import FirmwareTab from 'pages/Panel/Devices/Sidebar/Tabs/FirmwareTab'
import configuration from 'permissions/panel/configuration/page'
import diagnostic from 'permissions/panel/devices/diagnostic'

import ConfigurationTab from './Tabs/ConfigurationTab'
import ConnectionStatusTab from './Tabs/ConnectionStatusTab'

import {withPermission} from 'containers/withPermission'

import {__} from 'utils/i18n'

import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {selectPanelFirmwareByDeviceType} from 'modules/panels/firmware/selectors'
import SidebarTabsLayout from 'ui/SidebarTabsLayout'
import {VENDOR_DUAL_PATH} from 'constants/panelVendorType'
import {useSectionName} from 'hooks/panel/configuration'

const PanelSidebar = ({panel, firmware, isConfigurationAllowed, isDiagnosticAllowed}) => {
    const hasConfiguration = () => {
        return isConfigurationAllowed && panel.vendor !== VENDOR_DUAL_PATH
    }

    const hasDiagnostic = () => {
        return isDiagnosticAllowed && panel && panel.traits && panel.traits.diagnostic
    }

    const hasFirmware = () => {
        return firmware && has(firmware, 'packages')
    }

    const hasConnectionStatus = () => {
        return has(panel, 'connectionStatus.status')
    }

    const configurationSectionName = useSectionName(panel)
    const {id: panelId} = panel
    return (
        <Tabs Layout={SidebarTabsLayout}>
            {hasConnectionStatus() && (
                <ConnectionStatusTab
                    panelId={panelId}
                    name={__('Connection Status')}
                    connectionStatus={panel.connectionStatus}
                />
            )}

            {hasDiagnostic() && (
                <DiagnosticInfo name={__('Diagnostic')} {...panel.traits.diagnostic} />
            )}

            {hasConfiguration() && configurationSectionName && (
                <ConfigurationTab
                    name={__('Configuration')}
                    sectionName={configurationSectionName}
                    panelId={panel.id}
                />
            )}

            {hasFirmware() && (
                <FirmwareTab name={__('Firmware')} panelId={panel.id} {...firmware} />
            )}
        </Tabs>
    )
}

export default compose(
    withPermission({
        isConfigurationAllowed: configuration,
        isDiagnosticAllowed: diagnostic,
    }),
    connect((state, {panel}) =>
        selectPanelFirmwareByDeviceType(state, {
            panelId: panel.id,
            device: {deviceType: DEVICE_TYPE_CONTROL_PANEL},
        })
    )
)(PanelSidebar)
