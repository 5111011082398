import noop from 'lodash-es/noop'
import isDevMode from './isDevMode'

const messages = []

const createLogger =
    (level) =>
    (msg, ...rest) => {
        if (messages[level + '.' + msg]) {
            return
        }
        messages[level + '.' + msg] = true
        console[level](msg, ...rest)
    }

export const err = createLogger('error')
export const warn = isDevMode() ? createLogger('warn') : noop
export const info = createLogger('info')
export const trace = isDevMode() ? createLogger('trace') : noop

export function devFatal(msg) {
    console.error(msg)
    throw Error(msg)
}
