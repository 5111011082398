import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import Blind from 'components/Installer/Blind'
import {selectInstallersByPage} from 'modules/installers/store/selectors'

const InstallersBlind = ({row, setRow, onClose}) => {
    const {rows} = useSelector(selectInstallersByPage)
    const current = rows && rows.find(({id}) => row && id === row.id)

    return (
        <>
            {current && (
                <Blind
                    rows={rows}
                    current={current}
                    onChange={setRow}
                    onClose={onClose}
                />
            )}
        </>
    )
}

InstallersBlind.propTypes = {
    row: PropTypes.object,
    setRow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default InstallersBlind
