import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import useRouterPanelId from 'hooks/useRouterPanelId'
import Blind from 'components/Installer/Blind'
import {selectPanelInstallersList} from 'modules/panelInstallers/list/selectors'

export default function PanelInstallersBlind({row, setRow, onClose}) {
    const panelId = useRouterPanelId()
    const {rows} = useSelector((state) => selectPanelInstallersList(state, {panelId}))
    const current = rows && rows.find(({id}) => row && id === row.id)

    if (!current) {
        setRow(null)

        return null
    }

    return <Blind rows={rows} current={current} onChange={setRow} onClose={onClose} />
}

PanelInstallersBlind.propTypes = {
    row: PropTypes.object,
    setRow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
