import React from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import page, {ownerInfo, panelInfo} from 'permissions/panel/info/page'
import {withPermission, withRejection} from 'containers/withPermission'

import PanelInfo from './PanelInfo'
import CustomerInfo from './CustomerInfo'
import {selectPanelInfo} from 'modules/panels/one/selectors'

const Info = ({isAllowPanelInfo, isAllowCustomerInfo}) => {
    const {params} = useRouteMatch()
    const {id} = params
    const {fotaAotaVersions, panel} = useSelector((state) => selectPanelInfo(state, id))
    const isLoading = useSelector((state) => state.panels.one.isLoading)

    return (
        <Layout className="panelInfo">
            <ScrollView>
                {isAllowCustomerInfo && (
                    <CustomerInfo isLoading={isLoading} panel={panel} />
                )}
                {isAllowPanelInfo && (
                    <PanelInfo
                        isLoading={isLoading || !panel.firmware}
                        fotaAotaVersions={fotaAotaVersions}
                        panel={panel}
                    />
                )}
            </ScrollView>
        </Layout>
    )
}

export default compose(
    withPermission({
        isAllowed: page,
        isAllowPanelInfo: panelInfo,
        isAllowCustomerInfo: ownerInfo,
    }),
    withRejection()
)(Info)
