import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'

import {selectIsPanelAssigned} from 'modules/installerPanels/list/selectors'

import AssignButton from './AssignPanelButton'

export default function AssignPanelCell({row: {id, serial}}) {
    const {isAssigned} = useSelector((state) => selectIsPanelAssigned(state, id))

    if (isAssigned) {
        return <IconCheck />
    }

    return <AssignButton id={id} serial={serial} />
}

AssignPanelCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
        serial: PropTypes.string.isRequired,
    }).isRequired,
}
