import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import Transmission from './Content/Transmission'
import Format from './Content/Format'
import CheckboxGroup from 'ipmp-react-ui/CheckboxGroup'

export default class extends Component {
    static propTypes = {
        contentOptions: PropTypes.object.isRequired,
        meteoMetrics: PropTypes.object.isRequired,
        pmaxIndexes: PropTypes.object.isRequired,
        transportEmails: PropTypes.array.isRequired,
        errors: PropTypes.object,
    }

    render() {
        const {
            contentOptions,
            meteoMetrics,
            pmaxIndexes,
            transportEmails,
            panelIds,
            errors,
        } = this.props

        return (
            <CheckboxGroup
                dataTestId="create-report-format-checkbox-group"
                title={__('Format Options')}
                className="col"
                name="export"
                isRequired
            >
                <div>
                    <Format
                        {...{contentOptions, meteoMetrics, pmaxIndexes, panelIds, errors}}
                    />
                    <Transmission {...{transportEmails}} />
                </div>
            </CheckboxGroup>
        )
    }
}
