import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import withProps from 'containers/withProps'
import withForm from 'containers/withForm'

import {renameDevice, renamePartition} from 'modules/forms/handlers'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import {connect} from 'react-redux'
import {isNeoSerial} from 'utils/panelType'
import get from 'lodash-es/get'
import {getDeviceNameLength} from 'constants/deviceCategory'

const rules = (maximum, presence = true) => ({
    name: {
        presence,
        length: {maximum},
    },
})

class RenameModal extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        defaultName: PropTypes.string.isRequired,
    }

    handle = ({name}) => {
        const {handle} = this.props

        handle(name)
    }

    onClose = () => {
        const {hide} = this.props

        hide()
    }

    render() {
        const {
            defaultName,
            header,
            isLoading,
            maxLength,
            presence = true,
            ...props
        } = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={rules(maxLength, presence)}
                hide={this.onClose}
                isLoading={isLoading}
                submitLabel={__('Rename')}
                header={header}
                {...props}
            >
                <Input
                    autoFocus
                    label={__('Name')}
                    name="name"
                    defaultValue={defaultName}
                    maxLength={maxLength}
                    dataTestId="name"
                />
            </ModalCardForm>
        )
    }
}

export const RenameDevice = compose(
    withForm(renameDevice),
    withProps(
        ({
            handle,
            panelId,
            device: {
                zone: entityId,
                deviceType: entityClass,
                name: defaultName,
                category,
            },
        }) => ({
            defaultName,
            header: __('Rename Device'),
            handle: (name) => handle(panelId, entityClass, entityId, name),
            maxLength: getDeviceNameLength(category),
        })
    )
)(RenameModal)

export const RenamePartition = compose(
    withForm(renamePartition),
    connect((state, {panelId}) => ({
        isNeo: isNeoSerial(
            get(state, ['panels', 'store', 'byIds', panelId, 'serial'], '')
        ),
    })),
    withProps(({handle, panelId, isNeo, partition: {id: entityId, name}}) => ({
        defaultName: name,
        header: __('Rename Partition'),
        handle: (name) => handle(panelId, entityId, name),
        maxLength: 14,
        presence: !isNeo,
    }))
)(RenameModal)
