import React from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'

import Tooltip from 'ipmp-react-ui/Tooltip'

import {getUserStatus, isUserBlocked} from 'constants/userStatuses'

const StatusCell = ({row: {isEnabled, isBlocked, isOnline}}) => {
    const status = getUserStatus(isEnabled, isBlocked, isOnline)
    const userBlocked = isUserBlocked(isBlocked)
    return (
        <Tooltip tooltip={status.title}>
            <span
                className={classes('user-status-cell user-status-icon', {
                    'user-status-enabled': isEnabled && !isOnline && !userBlocked,
                    'user-status-suspended': !isEnabled && !userBlocked,
                    'user-status-online': isOnline && !userBlocked,
                    'user-status-blocked': userBlocked,
                })}
            >
                {status.icon}
            </span>
        </Tooltip>
    )
}

StatusCell.propTypes = {
    isEnabled: PropTypes.bool,
    isBlocked: PropTypes.bool,
    isOnline: PropTypes.bool,
}

export default StatusCell
