import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {SettingsPageBlock} from '../SettingsPageBlock'

import {__} from 'utils/i18n'

class Supervision extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            doSendEmailOnOnlineOffline: PropTypes.bool,
            doSendSmsOnOnlineOffline: PropTypes.bool,
            doSendOneChannelPanelOnlineOffline: PropTypes.bool,
            doSendTwoChannelPanelOnlineOffline: PropTypes.bool,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <>
                {this.renderListItem(
                    __('Send Notification Email on Online/Offline Event'),
                    data.doSendEmailOnOnlineOffline,
                    true
                )}
                {this.renderListItem(
                    __('Send Notification SMS on Online/Offline Event'),
                    data.doSendSmsOnOnlineOffline,
                    true
                )}
                {this.renderListItem(
                    __('Generate `SYSTEM OFFLINE/ONLINE` Events for One-Channel Panels'),
                    data.doSendOneChannelPanelOnlineOffline
                )}
                {this.renderListItem(
                    __('Generate `SYSTEM OFFLINE/ONLINE` Events for Two-Channel Panels'),
                    data.doSendTwoChannelPanelOnlineOffline
                )}
            </>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {supervision},
            },
        }) => ({...supervision})
    )
)(Supervision)
