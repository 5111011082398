import React from 'react'

import PageCard from 'ipmp-react-ui/PageCard'

import {__} from 'utils/i18n'

import SimpleGrid, {SimpleGridItem} from 'ipmp-react-ui/SimpleGrid'
import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'

import EditPanelInfoButton, {
    EditPanelInfoButtonWithText,
} from './Buttons/EditPanelInfoButton'

import EmptyPageCard from 'ui/EmptyPageCard'
import withLoader from 'containers/withLoader'
import {isEmptyPanelInfo} from 'utils/panel/IsEmptyPanelInfo'
import {preparePanelInfoGridItems} from './PreparePanelInfoGridItems'

const PanelInfo = ({fotaAotaVersions, panel}) => {
    if (isEmptyPanelInfo(panel, fotaAotaVersions)) {
        return (
            <EmptyPageCard
                title={__('Panel Info')}
                message={__('No Panel Info')}
                Icon={<IconPanel />}
                Button={<EditPanelInfoButtonWithText />}
            />
        )
    }

    const gridItems = preparePanelInfoGridItems(panel, fotaAotaVersions)

    return (
        <PageCard title={__('Panel Info')} HeaderAction={<EditPanelInfoButton />}>
            <SimpleGrid cols={2}>
                {gridItems.map(({title, text}) => (
                    <SimpleGridItem key={title} title={title}>
                        {text}
                    </SimpleGridItem>
                ))}
            </SimpleGrid>
        </PageCard>
    )
}

export default withLoader()(PanelInfo)
