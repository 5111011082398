import {createAction} from 'redux-actions'
import toIds from 'utils/toIds'

export const fetch = createAction('PANELS/ONE/FETCH', (panelId) => ({panelId}))
export const refreshState = createAction('PANELS/ONE/REFRESH_STATE', (panelId) => ({
    panelId,
}))
export const setError = createAction('PANELS/ONE/SET_ERROR')
export const setLoading = createAction('PANELS/ONE/SET_LOADING')
export const remove = createAction('PANELS/ONE/REMOVE', (panels) => toIds(panels))
export const fetchUsers = createAction('PANELS/ONE/FETCH_USERS', (panelId) => ({panelId}))

export const setUserAppState = createAction(
    'PANELS/ONE/SET_USER_APP_STATE',
    (rows, state) => ({
        ids: toIds(rows),
        userApp: state,
    })
)

export const setConfigurationAppState = createAction(
    'PANELS/ONE/SET_CONFIGURATOR_APP_STATE',
    (rows, state) => ({
        ids: toIds(rows),
        configuratorApp: state,
    })
)

export const startPollPanelData = createAction(
    'PANELS/ONE/START_POLL_DATA',
    (panelId) => ({panelId})
)
export const stopPollPanelData = createAction('PANELS/ONE/STOP_POLL_DATA')
export const purgeStore = createAction('PANELS/ONE/PURGE')
