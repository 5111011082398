import dataMapper from 'utils/dataMapper'

import * as CSConstants from 'constants/centralStation'

export const keysMap = {
    id: 'cls_id',
    name: 'name',
    protocol: 'csp_name',
    protocolId: 'csp_id',
    heartBeat: 'heartbeat',
    retryTime: 'retry_time',
    retryCount: 'retry_count',
    host: 'host',
    port: 'port',
    ssl: 'encryption_mode',
    receiver: 'receiver',
    line: 'line',
    serialPortId: 'dev_id',
}

const getResponseConnectionType = (transport) => {
    switch (transport) {
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_SERIAL:
            return CSConstants.CS_CONNECTION_TYPE_SERIAL
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_TCP:
            return CSConstants.CS_CONNECTION_TYPE_TCP
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_HTTP:
            return CSConstants.CS_CONNECTION_TYPE_HTTP
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_FIBRO:
            return CSConstants.CS_CONNECTION_TYPE_FIBRO
    }
}

const getRequestConnectionType = (transport) => {
    switch (transport) {
        case CSConstants.CS_CONNECTION_TYPE_SERIAL:
            return CSConstants.CS_TRANSPORT_CONNECTION_TYPE_SERIAL
        case CSConstants.CS_CONNECTION_TYPE_TCP:
            return CSConstants.CS_TRANSPORT_CONNECTION_TYPE_TCP
        case CSConstants.CS_CONNECTION_TYPE_HTTP:
            return CSConstants.CS_TRANSPORT_CONNECTION_TYPE_HTTP
        case CSConstants.CS_CONNECTION_TYPE_FIBRO:
            return CSConstants.CS_TRANSPORT_CONNECTION_TYPE_FIBRO
    }
}

const getCommunicationStatus = (status) => {
    switch (status) {
        case CSConstants.CS_STATUS_ID_ONLINE:
            return CSConstants.CS_STATUS_ONLINE
        case CSConstants.CS_STATUS_ID_OFFLINE:
            return CSConstants.CS_STATUS_OFFLINE
        case CSConstants.CS_STATUS_ID_HEARTBEAT:
            return CSConstants.CS_STATUS_HEARTBEAT
        case CSConstants.CS_STATUS_ID_NOT_APPLICABLE:
            return CSConstants.CS_STATUS_NOT_APPLICABLE
    }
}

const getEncryptionName = (encryptionMode) => {
    switch (encryptionMode) {
        case CSConstants.CS_CONNECTION_SSL_ID_TLS_1_2:
            return CSConstants.CS_CONNECTION_SSL_TLS_1_2
        case CSConstants.CS_CONNECTION_SSL_ID_TLS_1_3:
            return CSConstants.CS_CONNECTION_SSL_TLS_1_3
        case CSConstants.CS_CONNECTION_SSL_ID_NONE:
            return CSConstants.CS_CONNECTION_SSL_NONE
    }
}

const getConnectionSettings = (data) => {
    switch (data.transport) {
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_SERIAL:
            return {
                serialPortId: data.serial_settings.dev_id,
                serialPortName: data.serial_settings.serial_port_name,
            }
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_TCP:
            return {
                ssl: getEncryptionName(data.tcp_settings.encryption_mode),
                host: data.tcp_settings.host,
                port: parseInt(data.tcp_settings.port),
            }
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_HTTP:
            return {
                ssl: getEncryptionName(data.http_settings.tcp_settings.encryption_mode),
                host: data.http_settings.tcp_settings.host,
                port: parseInt(data.http_settings.tcp_settings.port),
                path: data.http_settings.path,
                user: data.http_settings.auth?.user,
                password: data.http_settings.auth?.password,
            }
        case CSConstants.CS_TRANSPORT_CONNECTION_TYPE_FIBRO:
            return {
                host: data.fibro_settings.host,
                port: parseInt(data.fibro_settings.port),
            }
    }
}

export const centralStationMapper = (data) => ({
    id: parseInt(data.cls_id),
    name: data.name,
    device: data.spa_name,
    isHeartBeat: parseInt(data.heartbeat) !== 0,
    heartBeat: parseInt(data.heartbeat),
    retryTime: parseInt(data.retry_time),
    retryCount: parseInt(data.retry_count),
    receiver: parseInt(data.receiver),
    line: parseInt(data.line),
    status: getCommunicationStatus(data.communication_status.state),
    protocol: data.csp_name,
    protocolId: data.csp_id,
    connectionType: getResponseConnectionType(data.transport),
    ...getConnectionSettings(data),
})

export const mapCentralStation = dataMapper(centralStationMapper)

//eslint-disable-next-line camelcase
export const mapProfile = ({etp_id, etp_name, etp_prio}) => ({
    id: parseInt(etp_id),
    name: etp_name,
    priority: parseInt(etp_prio),
})

const getEncryptionMode = (encryptionMode) => {
    switch (encryptionMode) {
        case CSConstants.CS_CONNECTION_SSL_TLS_1_2:
            return 0
        case CSConstants.CS_CONNECTION_SSL_TLS_1_3:
            return 1
        case CSConstants.CS_CONNECTION_SSL_NONE:
            return 2
    }
}

const getConnectionTypeSettings = (data) => {
    switch (data.connectionType) {
        case CSConstants.CS_CONNECTION_TYPE_SERIAL:
            return {
                serial_settings: {
                    dev_id: parseInt(data.serialPortId),
                },
            }
        case CSConstants.CS_CONNECTION_TYPE_TCP:
            return {
                tcp_settings: {
                    host: data.host || null,
                    port: parseInt(data.port),
                    encryption_mode: getEncryptionMode(data.ssl),
                },
            }
        case CSConstants.CS_CONNECTION_TYPE_HTTP:
            return {
                http_settings: {
                    tcp_settings: {
                        host: data.host || CSConstants.defaultHostValue,
                        port: parseInt(data.port),
                        encryption_mode: getEncryptionMode(data.ssl),
                    },
                    path: data.path,
                    auth: {
                        user: data.user,
                        password: data.password,
                    },
                },
            }
        case CSConstants.CS_CONNECTION_TYPE_FIBRO:
            return {
                fibro_settings: {
                    host: data.host || null,
                    port: parseInt(data.port),
                },
            }
    }
}

export const mapFormData = (data) => ({
    name: data.name,
    heartbeat: data.heartBeat
        ? parseInt(data.heartBeat)
        : CSConstants.defaultHeartbeatValue,
    retry_time: data.retryTime
        ? parseInt(data.retryTime)
        : CSConstants.defaultRetryTimeValue,
    retry_count: data.retryCount
        ? parseInt(data.retryCount)
        : CSConstants.defaultRetryCountValue,
    cls_id: data.id ? parseInt(data.id) : CSConstants.defaultClsIdValue,
    receiver: data.receiver ? parseInt(data.receiver) : CSConstants.defaultReceiverValue,
    line: data.line ? parseInt(data.line) : CSConstants.defaultLineValue,
    transport: data.connectionType && getRequestConnectionType(data.connectionType),
    csp_id: parseInt(data.protocolId),
    ...getConnectionTypeSettings(data),
})

export const mapProtocols = (row) => ({
    id: parseInt(row.csp_id),
    name: row.csp_name,
})

export const mapSerialPorts = (row) => ({
    id: parseInt(row.spa_id),
    name: row.spa_device,
})

const mapCentralStationLinksMapper = (data) => ({
    linkId: parseInt(data.csl_id),
    centralStationId: parseInt(data.cls_id),
    notificationProfileId: parseInt(data.etp_id),
    groupId: parseInt(data.utg_id),
    centralStationLabel: data.csl_label,
    centralStationTest: data.cls_name,
    notificationProfileName: data.etp_name,
})

const mapCentralStationLinks = dataMapper(mapCentralStationLinksMapper)

export const centralStationGroupsMapper = (data) => ({
    id: parseInt(data.id),
    name: data.name,
    description: data.description,
    links: data.links.map(mapCentralStationLinks),
})

export const mapCentralStationGroups = dataMapper(centralStationGroupsMapper)
