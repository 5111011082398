import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'

import withLifeCycle from 'containers/withLifeCycle'

import {fetchServerVersion} from 'modules/system/settings/actions'
import {selectServerVersion} from 'modules/system/settings/selectors'

import {showAboutReleaseNotesModal} from 'modules/modals/actions'

import Void from 'components/Void'

class ReleaseNotes extends Component {
    static propTypes = {
        serverVersion: PropTypes.object,
    }

    render() {
        if (!this.props.serverVersion) {
            return <Void />
        }
        const {showAboutReleaseNotesModal} = this.props
        return (
            <span onClick={showAboutReleaseNotesModal} className="link">
                {__('Show release notes')}
            </span>
        )
    }
}

export default compose(
    connect(selectServerVersion, (dispatch) =>
        bindActionCreators(
            {
                fetchServerVersion,
                showAboutReleaseNotesModal,
            },
            dispatch
        )
    ),
    withLifeCycle({
        onMount({fetchServerVersion, serverVersion}) {
            if (!serverVersion) {
                fetchServerVersion()
            }
        },
    })
)(ReleaseNotes)
