import React from 'react'

import {run} from 'permissions/panel/devices/walktest'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'

const ButtonVisible = withVisibility()(Button)

export default function StartWalktestButton() {
    const {isPermitted} = usePermission(run)
    const {startWalktest} = useDevices()

    return (
        <ButtonVisible
            isVisible={isPermitted}
            onClick={startWalktest}
            label={__('Start Walktest')}
        />
    )
}
