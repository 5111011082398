import React from 'react'
import {Route, Switch} from 'react-router-dom'

import path from 'utils/path'

import StationsGridBar from './Stations/StationsGridBar'

export default function GroupBar() {
    return (
        <Switch>
            <Route
                path={path('system.group.stations')}
                exact
                component={() => <StationsGridBar />}
            />
        </Switch>
    )
}
