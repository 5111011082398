import {createSelector} from 'reselect'
import {get} from 'lodash-es'

export const getByGroupId = createSelector(
    (state, groupId) => get(state, `groups.notifications.byIds.${groupId}`, {}),
    (settings) => settings
)

export const getIsLoading = createSelector(
    (state) => get(state, 'groups.notifications.isLoading', false),
    (isLoading) => isLoading
)
