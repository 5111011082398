import React from 'react'
import PropTypes from 'prop-types'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import {BlindModalCardTable} from 'ui/BlindModal'
import __ from 'utils/i18n'

import UnassignPanelCell from './UnassignPanelCell'
import {RoleCell} from './RoleCell'

export const columns = [
    {
        name: () => __('Panel'),
        width: 130,
        render: ({id, serial, account}) => (
            <PanelCell to="installers" {...{id, serial, account}} multiLine />
        ),
    },
    {
        name: () => __('Group'),
        maxWidth: 220,
        render: ({groupId: id, group: name}) => {
            if (id) {
                return <GroupCell {...{row: {id, name}}} />
            }
        },
    },
    {
        name: () => __('Role'),
        maxWidth: 140,
        render: ({role}) => {
            if (role) {
                return <RoleCell role={role} />
            }
        },
    },
    {
        tooltip: false,
        fixed: true,
        width: 66,
        component: UnassignPanelCell,
    },
]

export default function Table({rows}) {
    return (
        <BlindModalCardTable
            columns={columns}
            title={__('Panels')}
            rows={rows}
            className="customer-page-blind-table"
        />
    )
}

Table.propTypes = {
    rows: PropTypes.array.isRequired,
}
