import __ from 'utils/i18n'
import one from 'permissions/centralStations/one'
import groupList from 'permissions/groups/list'

export const CENTRAL_STATION_TAB_GENERAL = 'general'
export const CENTRAL_STATION_TAB_COMMUNICATION = 'communication'

const tabs = new Map([
    [
        CENTRAL_STATION_TAB_GENERAL,
        {
            label: () => __('General'),
            path: 'system.centralStation.general',
            exact: true,
            isVisiblePermission: one,
        },
    ],
    [
        CENTRAL_STATION_TAB_COMMUNICATION,
        {
            label: () => __('Communication'),
            path: 'system.centralStation.communication',
            isVisiblePermission: groupList,
        },
    ],
])

export default tabs
