import React, {useContext, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import BlindModalWithPagination from 'ui/BlindModalWithPagination'

import EventContext from './EventContext'
import {
    selectEventBlindModal,
    selectEventBlindModalByPanel,
} from 'modules/events/list/selectors'
import EventGeneralInfo from 'components/Events/EventGeneralInfo'
import {fetch as fetchCs} from 'modules/centralStations/list/actions'
import {EventDeliveranceContent} from 'eventInterface/pages/EventDeliveranceContent'
import {
    EDS_FAILED,
    EDS_PROCESSING,
    EDS_QUEUED,
    EDS_RETRY,
    EDS_SUCCESS,
} from 'constants/eventDelivery'

import {useCountDeliveranceStatus} from 'hooks/events/deliverance'
import EventsDeviceInfo from 'components/Events/EventDeviceInfo'

const EventsBlindModal = ({selectedEventId, centralStations, panelId}) => {
    const {handleEventSelect} = useContext(EventContext)
    const eventId = selectedEventId
    const {
        eventBlindModalPrevNext: [prevId, nextId],
        event,
    } = panelId
        ? useSelector((state) => selectEventBlindModalByPanel(state, eventId))
        : useSelector((state) => selectEventBlindModal(state, eventId))
    const isLast = nextId === null
    const isFirst = prevId === null
    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedEventId) {
            dispatch(fetchCs())
        }
    }, [selectedEventId])

    const queued = useCountDeliveranceStatus(event?.deliverance, EDS_QUEUED)
    const processing = useCountDeliveranceStatus(event?.deliverance, EDS_PROCESSING)
    const delivered = useCountDeliveranceStatus(event?.deliverance, EDS_SUCCESS)
    const retry = useCountDeliveranceStatus(event?.deliverance, EDS_RETRY)
    const failed = useCountDeliveranceStatus(event?.deliverance, EDS_FAILED)

    return (
        <BlindModalWithPagination
            prev={prevId}
            next={nextId}
            onNextClick={(next) => handleEventSelect(next)}
            onClose={() => handleEventSelect(null)}
            opened={Boolean(selectedEventId)}
            onPrevClick={(prev) => handleEventSelect(prev)}
            isNextDisabled={isLast}
            isPrevDisabled={isFirst}
            className="eventBlindModal"
        >
            <EventGeneralInfo
                account={event?.account}
                panel={event?.serial}
                date={event?.datetime}
                appointment={event?.appointment}
                description={event?.description}
                severity={event?.severity}
                zone={event?.zone}
                partitions={event?.partitions}
            />
            <EventsDeviceInfo eventId={event?.id} panelId={event?.panelId} />
            <EventDeliveranceContent
                key={selectedEventId}
                eventId={selectedEventId}
                centralStations={centralStations}
                panelId={event?.panelId}
                {...{queued, processing, delivered, retry, failed}}
            />
        </BlindModalWithPagination>
    )
}

export default EventsBlindModal
