import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    fetch,
    reset,
    setStart,
    startPoll,
    stopPoll,
    remove,
} from 'modules/installers/list/actions'
import {accept, reject, resetPassword} from 'modules/installers/store/actions'
import {selectInstallersByPage} from 'modules/installers/store/selectors'

export default function withInstallers() {
    return compose(
        withPerPage('installers'),
        connect(selectInstallersByPage, (dispatch) =>
            bindActionCreators(
                {
                    init,
                    fetch,
                    reset,
                    accept,
                    reject,
                    resetPassword,
                    onPageChange: setStart,
                    startPoll,
                    stopPoll,
                    remove,
                },
                dispatch
            )
        )
    )
}
