// libraries
import React from 'react'
import {compose} from 'redux'

import {withRejection} from 'containers/withPermission'
import withSelection from 'containers/withSelection'

// application
import Table from 'ipmp-react-ui/Table'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

// utils
import {__} from 'utils/i18n'

import list from 'permissions/firmware/list'
import selection from 'permissions/firmware/selection'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import useUpgradablePanels from 'hooks/pages/useUpgradablePanels'

const columns = [
    {
        name: () => __('Panel'),
        render: ({id, serial, account}) => <PanelCell {...{id, serial, account}} />,
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        name: () => __('Model'),
        render: ({model}) => model,
    },
]

const ConnectedTable = compose(
    withRejection(),
    withSelection(),
    withLoader(({fetch, init, deselectAll}) => {
        init()
        deselectAll()
        fetch()
    })
)(Table)

export default function FirmwareUpgradeTable(props) {
    const permissions = usePermission({
        isAllowed: list,
        hasSelection: selection,
    })
    const upgrade = useUpgradablePanels()
    const tableProps = {...props, ...permissions, ...upgrade}

    return (
        <ConnectedTable
            {...tableProps}
            fullHeight
            columns={columns}
            emptyMessage={() => __('No equipment')}
        />
    )
}
