import {post, get} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {map, keysMap} from './auth.map'

export function login(email, password) {
    return post('login/sign', {
        usr_email: email,
        usr_password: password,
    })
        .then(map)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function sendCode(phone) {
    return post('login/send', {
        phone,
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function resendCode(phone) {
    return post('login/send', {
        phone,
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function confirmCode(code) {
    return post('login/send/confirm', {
        code,
    })
        .then(map)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function remindPassword(email) {
    return post('login/forgot', {usr_email: email}).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}

export function logout() {
    return post('logout/out')
}

export function whoami(options) {
    return get('login/whoami', null, options).then(map)
}

export function setSettings(settings) {
    return post('settings/local', settings)
}
