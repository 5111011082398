import React from 'react'
import {compose} from 'redux'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'

import map from 'lodash-es/map'
import head from 'lodash-es/head'

import {content} from 'permissions/panel/page'

import withProps from 'containers/withProps'
import {withPermission, withRejection} from 'containers/withPermission'

import Logs from '../Logs/Logs'
import State from '../PanelState/PanelState'
import Reports from '../Reports/Reports'
import Devices from '../Devices/Devices'
import Info from '../Info/Info'
import Processes from '../Processes/Processes'
import PanelInteractiveUsers from '../PanelInteractiveUsers/PanelInteractiveUsers'
import PanelInstallers from '../PanelInstallers/PanelInstallers'
import Locations from '../Locations/Locations'
import Configuration from '../Configuration/ConfigurationsPage'
import RemoteInspections from '../RemoteInspections/RemoteInspections'
import Events from '../Events/Events'
import Firmware from '../Firmware/Firmware'
import Keypad from '../Keypad/Keypad'
import Had from 'pages/Panel/Had/Had'

import path from 'utils/path'
import {PANEL_TAB} from 'pages/Panel/PanelPageRouting'

const componentsMap = {
    [PANEL_TAB.DEVICES]: Devices,
    [PANEL_TAB.INFO]: Info,
    [PANEL_TAB.STATE]: State,
    [PANEL_TAB.LOG]: Logs,
    [PANEL_TAB.PROCESSES]: Processes,
    [PANEL_TAB.INTERACTIVE_USERS]: PanelInteractiveUsers,
    [PANEL_TAB.INSTALLERS]: PanelInstallers,
    [PANEL_TAB.REMOTE_INSPECTIONS]: RemoteInspections,
    [PANEL_TAB.EVENTS]: Events,
    [PANEL_TAB.FIRMWARE]: Firmware,
    [PANEL_TAB.CONFIGURATION]: Configuration,
    [PANEL_TAB.LOCATIONS]: Locations,
    [PANEL_TAB.REPORTS]: Reports,
    [PANEL_TAB.KEYPAD]: Keypad,
    [PANEL_TAB.HAD]: Had,
}

export default compose(
    withRouter,
    withPermission({isAllowed: content}),
    withRejection(),
    withProps(({routes, ...props}) => {
        const children = map(routes, ({path: pathName, exact}, route) => (
            <Route
                exact={exact}
                path={path(pathName)}
                component={componentsMap[route]}
                key={route}
            />
        ))

        const {path: pathName} = head(Object.values(routes))
        children.push(
            <Redirect to={path(pathName, {...props.match.params})} key={pathName} />
        )
        return {
            children,
        }
    })
)(Switch)
