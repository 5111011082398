import React from 'react'

import selection from 'permissions/remoteInspections/selection'

import withSelection from 'containers/withSelection'

import {TableGrouped} from 'ipmp-react-ui/Table'
import ResultsCell from 'components/Cell/RemoteInspections/ResultsCell'
import NextRICell from 'components/Cell/RemoteInspections/NextRICell'
import PanelCell from 'components/TableCells/PanelCell'
import ActionsCell from './Cells/ActionsCell'

import {__} from 'utils/i18n'
import {humanDate} from 'ipmp-react-ui/humanTime'
import usePermission from 'hooks/usePermission'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const columns = [
    {
        tooltip: false,
        render: ({
            id,
            serial,
            account,
            latest,
            result,
            progress,
            next,
            repetition,
            isSupported,
        }) => {
            const results = latest ? latest.result : []

            return (
                <div>
                    <PanelCell {...{id, serial, account}} to="remoteInspections" />
                    {isSupported && <ResultsCell {...{results, progress, result}} />}
                    {isSupported && <NextRICell {...{next, repetition, isSupported}} />}
                    {!isSupported && (
                        <div>{__('Remote inspection is not supported')}</div>
                    )}
                </div>
            )
        },
    },
    {
        tooltip: false,
        width: 150,
        fixed: true,
        render: ActionsCell,
    },
]

const ConnectedTable = withSelection()(TableGrouped)

const separatorCallback = (row) => {
    let group = humanDate(row.last)

    return group || __('Remote Inspections never initiated')
}

export default function RemoteInspectionsTable() {
    const {isPermitted} = usePermission(selection)
    const remoteInspections = useRemoteInspections()
    const remoteInspectionsResult = useRemoteInspectionsResult()

    const componentProps = {...remoteInspections, ...remoteInspectionsResult}

    const handleSelected = (remoteInspections, index) => {
        return remoteInspectionsResult.select(index)
    }
    return (
        <ConnectedTable
            {...componentProps}
            hasSelection={isPermitted}
            onRowClick={handleSelected}
            columns={columns}
            emptyMessage={__('No Panels')}
            selectionBar={false}
            separatorCallback={separatorCallback}
            active={{id: remoteInspectionsResult.selectedId}}
        />
    )
}
