import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import {getUserRoleTitle} from 'constants/accountRoles'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

export default function NotAllowRoleDisclaimer({role}) {
    return (
        <Disclaimer className="panelInteractiveUsers-blindModal-disclaimer">
            <div>
                <p>{__('Please pay attention!')}</p>
                <b>&quot;{getUserRoleTitle(role)}&quot;</b>{' '}
                {__('role is not applicable to work with the User application.')}
            </div>
        </Disclaimer>
    )
}

NotAllowRoleDisclaimer.propTypes = {
    role: PropTypes.string.isRequired,
}
