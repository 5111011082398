import React from 'react'
import PropTypes from 'prop-types'
import PageCard from 'ipmp-react-ui/PageCard'
import SimpleGrid, {SimpleGridItem} from 'ipmp-react-ui/SimpleGrid'
import csProfileType, {getCsProfileStatusTitle} from 'constants/csProfileType'
import EditCommunicationButton from './EditCommunicationButton'
import {useParams} from 'react-router'

export const GroupCardWrapper = ({children}) => (
    <section className="centralStation-communication-pageCard-grid">{children}</section>
)

const GroupCard = ({id, links, profiles, title}) => {
    const {id: centralStationId} = useParams()

    return (
        <PageCard
            title={title}
            HeaderAction={
                <EditCommunicationButton
                    stationId={Number(centralStationId)}
                    groupId={id}
                    links={links}
                />
            }
        >
            <SimpleGrid cols={4}>
                {Object.values(profiles).map((profile) => {
                    const isEnabled = links.includes(profile.id)

                    return (
                        <SimpleGridItem
                            key={profile.name}
                            title={csProfileType(profile.name)}
                            className={
                                isEnabled
                                    ? 'notification--enabled'
                                    : 'notification--disabled'
                            }
                        >
                            {getCsProfileStatusTitle(isEnabled)}
                        </SimpleGridItem>
                    )
                })}
            </SimpleGrid>
        </PageCard>
    )
}

GroupCard.propTypes = {
    id: PropTypes.number.isRequired,
    links: PropTypes.array,
    profiles: PropTypes.object,
    title: PropTypes.string,
}

export default GroupCard
