import React from 'react'

import ReviewButton from './Footer/ReviewButton'
import SendEmailButton from './Footer/SendEmailButton'
import InitiateButton from './Footer/InitiateButton'

export default function RemoteInspectionsResultFooter() {
    return (
        <div className="rri-footer">
            <div className="rri-footer-btns">
                <ReviewButton />
                <SendEmailButton />
            </div>
            <InitiateButton />
        </div>
    )
}
