import React from 'react'
import {compose} from 'redux'

import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'

import selection from 'permissions/pushBasicConfiguration/selection'

import SelectionDropDown from 'components/SelectionDropDown'
import usePermission from 'hooks/usePermission'
import usePushBasicConfigurations from 'hooks/pages/usePushBasicConfigurations'

const ConnectedSelectionDropDown = compose(
    withVisibility(),
    withSelection()
)(SelectionDropDown)

export default function PushBasicConfigurationDropDown() {
    const {isPermitted} = usePermission(selection)
    const pushBasicConfigurations = usePushBasicConfigurations()

    return (
        <ConnectedSelectionDropDown
            {...pushBasicConfigurations}
            isVisible={isPermitted}
        />
    )
}
