import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import DateTime from 'ipmp-react-ui/DateTime'
import {EventAppointmentCell} from 'ui/TableCells'
import {getSeverityCssClass, getSeverityIcon} from 'constants/eventSeverity'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {Partitions} from '../Partitions'

const EventGeneralInfo = ({
    account,
    panel,
    date,
    appointment,
    description,
    severity,
    zone,
    partitions,
}) => {
    const Icon = getSeverityIcon(severity)
    return (
        <div className="event-general-info">
            <div className="event-panel event-general-info-block">
                <IconPanel /> {panel} <span>{account}</span>
            </div>
            <div className="event-date event-general-info-block">
                <DateTime datetime={date} timeFirst />
            </div>
            <div className="event-severity event-general-info-block">
                <span className={'severity severity--' + getSeverityCssClass(severity)}>
                    <Icon className="severity-icon" />
                    {description}
                </span>
            </div>
            <div className="event-appointment event-general-info-block">
                <EventAppointmentCell
                    zone={zone}
                    appointment={appointment}
                    description={description}
                />
            </div>
            {partitions && (
                <Tooltip tooltip={partitions} inline={true}>
                    <Partitions
                        partitions={partitions.map((str) => Number(str.replace('P', '')))}
                    />
                </Tooltip>
            )}
        </div>
    )
}

EventGeneralInfo.propTypes = {
    account: PropTypes.string,
    panel: PropTypes.string,
    date: PropTypes.string,
    appointment: PropTypes.string,
    description: PropTypes.string,
    severity: PropTypes.string,
    zone: PropTypes.number,
    partitions: PropTypes.array,
}

export default EventGeneralInfo
