import createPermission, {any} from 'permissions/createPermission'

export const add = createPermission('system.installers.add')
export const accept = createPermission('system.installers.accept')
export const reject = createPermission('system.installers.accept')
export const assign = createPermission('system.installers.assign')
export const unassign = createPermission('system.installers.unassign')
export const remove = createPermission('system.installers.remove')
export const reset = createPermission('system.installers.reset')

export default createPermission(any(accept, reject))
