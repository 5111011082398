import {__} from 'utils/i18n'
import {warn} from 'utils/log'

const TEMPERATURE = 'temperature'
const BRIGHTNESS = 'light'
const GSM_RSSI = 'gsm_rssi'

const TEMPERATURE_FEATURE = 'CO_TEMPERATURE'
const GSM_RSSI_FEATURE = 'CO_GSM_RSSI'
const LIGHT_FEATURE = 'CO_BRIGHTNESS'

const TYPES_FEATURES = new Map([
    [TEMPERATURE, TEMPERATURE_FEATURE],
    [BRIGHTNESS, LIGHT_FEATURE],
    [GSM_RSSI, GSM_RSSI_FEATURE],
])

export default function getMeteoName(type) {
    switch (type) {
        case TEMPERATURE:
            return __('Temperature')
        case BRIGHTNESS:
            return __('Brightness')
        case GSM_RSSI:
            return __('GSM RSSI')
    }

    warn(`Unknown meteo metric ${type}`)

    return type
}

export function getFeatureByType(type) {
    if (TYPES_FEATURES.has(type)) {
        return TYPES_FEATURES.get(type)
    }

    warn(`Unknown feature meteo metric ${type}`)

    return type
}
