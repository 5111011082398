import {isNil} from 'lodash-es'
import {all, takeEvery, select, put} from 'redux-saga/effects'

import * as api from 'api/installers'
import * as actions from './actions'

import {selectPerPage} from 'modules/settings/selectors'
import {fetch} from 'modules/installerPanels/list/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import {assignInstallerToPanel} from 'modules/forms/handlers'

import {update} from '../store/actions'
import {selectInstallerAccessiblePanelsFetch} from './selectors'

const selector = (state) => ({
    ...selectInstallerAccessiblePanelsFetch(state),
    perPage: selectPerPage(state, 'installerAccessiblePanels'),
    listRoute: 'installers',
})

export default function* () {
    yield all([
        listSaga({fetch: api.accessiblePanels}, actions, update, selector),
        takeEvery(assignInstallerToPanel.SUCCESS, watchAssignInstallerToPanel),
    ])
}

function* watchAssignInstallerToPanel() {
    const installerId = yield select((state) => state.installerPanels.list.installerId)

    // On system/installers page
    if (isNil(installerId)) {
        return
    }

    yield put(fetch(installerId))
}
