import React from 'react'
import ChartBase from 'components/Devices/Chart'

import withLoader from 'containers/withLoader'
import useDeviceMeteo from 'hooks/pages/useDeviceMeteo'

const ChartWithLoader = withLoader(({fetch}) => fetch())(ChartBase)

export default function Chart(props) {
    const meteo = useDeviceMeteo(props)
    const chartProps = {...props, ...meteo}

    return <ChartWithLoader {...chartProps} />
}
