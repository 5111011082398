import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import {FormSection} from 'ipmp-react-ui/Form'
import getContentName from 'constants/reportContentOption'
import getMeteoMetricName, {getFeatureByType} from 'constants/reportMeteoMetric'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {METEO_REPORT_DAY} from 'constants/reportMeteoPeriods'
import omit from 'lodash-es/omit'
import {useSelector} from 'react-redux'
import {selectReportsContentOptionsFeature} from 'modules/features/store/selectors'
import keyBy from 'lodash-es/keyBy'
import {
    EXPORT_TYPE_CSV_EXTENDED_ID,
    EXPORT_TYPE_CSV_ID,
    EXPORT_TYPE_PDF_ID,
} from 'constants/reportTypes'

const BaseFormat = ({contentOptions, meteoMetrics, panelIds}) => {
    const [csvEnabled, setCsvEnabled] = useState(true)
    const [extendedCsvEnabled, setExtendedCsvEnabled] = useState(false)
    const [pdfEnabled, setPdfEnabled] = useState(false)
    const [checkedMeteoMetrics, setCheckedMeteoMetrics] = useState({})

    const isPdfAvailable = !!panelIds

    const meteoPeriods = {
        day: __('Day'),
        week: __('Week'),
        month: __('Month'),
        all: __('All'),
    }

    const changeFormatPdf = () => {
        setPdfEnabled(!pdfEnabled)
    }

    const changeFormatCsv = () => {
        setCsvEnabled(!csvEnabled)
    }

    const changeFormatExtendedCsv = () => {
        setExtendedCsvEnabled(!extendedCsvEnabled)
    }

    const changeMeteo = ({target: {checked}}, name) => {
        if (checked) {
            setCheckedMeteoMetrics({...checkedMeteoMetrics, [name]: checked})
        } else {
            const items = {...checkedMeteoMetrics}
            setCheckedMeteoMetrics(omit(items, [name]))
        }
    }

    return (
        <div>
            <Checkbox
                {...{
                    checked: csvEnabled,
                    onChange: changeFormatCsv,
                    value: EXPORT_TYPE_CSV_ID,
                    name: 'export',
                    className: 'required-group-checkbox',
                    dataTestId: 'csv-enabled',
                }}
            >
                {__('CSV')}
            </Checkbox>

            <Checkbox
                {...{
                    checked: extendedCsvEnabled,
                    onChange: changeFormatExtendedCsv,
                    value: EXPORT_TYPE_CSV_EXTENDED_ID,
                    name: 'export',
                    className: 'required-group-checkbox',
                    dataTestId: 'extended-csv-enabled',
                }}
            >
                {__('Extended CSV')}
            </Checkbox>

            <Checkbox
                {...{
                    disabled: !isPdfAvailable,
                    checked: pdfEnabled,
                    onChange: changeFormatPdf,
                    value: EXPORT_TYPE_PDF_ID,
                    name: 'export',
                    className: 'required-group-checkbox',
                    dataTestId: 'pdf-enabled',
                }}
            >
                {!panelIds ? (
                    <Fragment>
                        {__('PDF')}{' '}
                        <small>{__('It is not possible to use for all panels')}</small>
                    </Fragment>
                ) : (
                    __('PDF')
                )}
            </Checkbox>

            {pdfEnabled && (
                <FormSection title={__('Content Options')}>
                    {Object.keys(contentOptions).map((key) => (
                        <Checkbox {...{key, value: key, name: 'contentOptions'}}>
                            {getContentName(contentOptions[key].name)}
                        </Checkbox>
                    ))}
                </FormSection>
            )}
            {pdfEnabled && !!Object.keys(meteoMetrics).length && (
                <FormSection title={__('Meteo Options')}>
                    {Object.keys(meteoMetrics).map((key) => (
                        <Checkbox
                            {...{
                                key,
                                value: key,
                                name: 'meteoMetrics',
                                onChange: (event) =>
                                    changeMeteo(event, meteoMetrics[key].name),
                            }}
                        >
                            {getMeteoMetricName(meteoMetrics[key].name)}
                        </Checkbox>
                    ))}
                    {!!Object.keys(checkedMeteoMetrics).length && (
                        <Select
                            {...{
                                label: __('Period'),
                                name: 'meteoMetricsPeriod',
                                defaultValue: METEO_REPORT_DAY,
                            }}
                        >
                            {Object.keys(meteoPeriods).map((key) => (
                                <Option
                                    {...{
                                        key,
                                        value: key,
                                        label: meteoPeriods[key],
                                    }}
                                />
                            ))}
                        </Select>
                    )}
                </FormSection>
            )}
        </div>
    )
}

BaseFormat.propTypes = {
    contentOptions: PropTypes.object,
    meteoMetrics: PropTypes.object,
}

export default function ContentFormat(props) {
    const {panelIds, meteoMetrics} = props
    const panels = useSelector(({panels}) => panels.store.byIds)

    const allowedFeatureMeteos = (panelIds || []).reduce((allowed, panelId) => {
        let contentOptions = panels[panelId]?.features?.reports?.contentOptions

        if (!Array.isArray(contentOptions)) {
            contentOptions = useSelector((state) =>
                selectReportsContentOptionsFeature(state, {panelId})
            )?.contentOptions
        }

        for (const option of contentOptions || []) {
            allowed[option] = true
        }

        return allowed
    }, {})

    const allowedMeteo = Object.values(meteoMetrics).filter(
        (meteoMetric) => allowedFeatureMeteos[getFeatureByType(meteoMetric.name)]
    )

    return <BaseFormat {...{...props, meteoMetrics: keyBy(allowedMeteo, 'id')}} />
}
