import React from 'react'
import PropTypes from 'prop-types'

import navigation, {
    customizations,
    notificationsList,
    stations,
} from 'permissions/group/navigation'

import {withVisibility} from 'containers/withVisibility'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import path from 'utils/path'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'

function GroupBarNavBase(props) {
    const {
        group: {id},
        isStationsTabPermitted,
        isCustomizationsTabPermitted,
        isNotificationsTabPermitted,
    } = props

    return (
        <NavTabs
            className="group-nav"
            title={props.group.name}
            goBack={path('system.groups')}
        >
            <NavTabLink exact to={path('system.group.general', {id})}>
                {__('General')}
            </NavTabLink>
            {isStationsTabPermitted && (
                <NavTabLink exact to={path('system.group.stations', {id})}>
                    {__('CS Communicating')}
                </NavTabLink>
            )}
            {isNotificationsTabPermitted && (
                <NavTabLink exact to={path('system.group.notifications', {id})}>
                    {__('Notifications Settings')}
                </NavTabLink>
            )}
            {isCustomizationsTabPermitted && (
                <NavTabLink exact to={path('system.group.customizations', {id})}>
                    {__('App customization')}
                </NavTabLink>
            )}
        </NavTabs>
    )
}

GroupBarNav.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number,
    }),
}

const GroupBarNavWithVisibility = withVisibility()(GroupBarNavBase)

export default function GroupBarNav(props) {
    const permissions = usePermission({
        isVisible: navigation,
        isStationsTabPermitted: stations,
        isCustomizationsTabPermitted: customizations,
        isNotificationsTabPermitted: notificationsList,
    })

    const nabBarProps = {...props, ...permissions}

    return <GroupBarNavWithVisibility {...nabBarProps} />
}
