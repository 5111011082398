import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {messageByError, CUSTOM_ERROR} from 'constants/errorType'
import {
    ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED,
    ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED,
    ACTIVATION_STATUS_MASTER_USER_ACTIVATION_REQUIRED,
    ACTIVATION_STATUS_NOT_PROVISIONED,
} from 'constants/panelActivationStatus'
import {__} from 'utils/i18n'
import {selectPanel} from 'modules/panels/store/selectors'

export default function GetActivatePanelMessage({panelId}) {
    const {
        isActivating,
        isFailed,
        activationError: error,
        activationStatus,
        activatingStarted,
    } = useSelector((state) => selectPanel(state, {panelId}))

    if (isActivating) {
        return __('Panel activating...')
    }

    if (isFailed) {
        return messageByError(CUSTOM_ERROR, {
            message: __('Panel activation failed: %s', error),
        })
    }
    const installerActivationRequired =
        !activatingStarted &&
        !isActivating &&
        ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED

    switch (activationStatus) {
        case installerActivationRequired:
            return __(
                'This panel has not been activated with installer code yet and cannot be correctly managed from PowerManage.'
            )
        case ACTIVATION_STATUS_MASTER_USER_ACTIVATION_REQUIRED:
            return __(
                'This panel has not been activated with master user code yet and cannot be correctly managed from PowerManage.'
            )
        case ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED:
            return __(
                'This panel has not been activated with master user code yet and can be managed from PowerManage.'
            )
        case ACTIVATION_STATUS_NOT_PROVISIONED:
            return __(
                'This panel has not been provisioned yet and cannot be correctly managed from PowerManage.'
            )
        default:
            return __(
                'This panel has not been discovered yet and cannot be correctly managed from PowerManage.'
            )
    }
}

GetActivatePanelMessage.propTypes = {
    panelId: PropTypes.number.isRequired,
}
