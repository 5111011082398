import React from 'react'
import withProps from 'containers/withProps'

import Page from 'ipmp-react-ui/Page'
import TableWithBlind from 'components/Table/TableWithBlind'

import InstallersBar from './InstallersBar'
import InstallersTable from './InstallersTable'
import InstallersSearch from './InstallersSearch'
import InstallersBlind from './InstallersBlind'

function InstallersContent() {
    return (
        <TableWithBlind>
            {(props) => <InstallersBlind {...props} />}
            {(props) => <InstallersTable {...props} />}
        </TableWithBlind>
    )
}

export default withProps({
    Bar: InstallersBar,
    Top: InstallersSearch,
    Content: InstallersContent,
})(Page)
