import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import {connect} from 'react-redux'
import {SettingsPageBlock} from 'pages/Settings/SettingsPageBlock'
import {selectMfa} from 'modules/system/settings/selectors'

class MFAParameters extends SettingsPageBlock {
    static propTypes = {
        otpTtl: PropTypes.number.isRequired,
        overallBlockTimeout: PropTypes.number.isRequired,
        resendTimeout: PropTypes.number.isRequired,
        otpLength: PropTypes.number.isRequired,
        resendCount: PropTypes.number.isRequired,
        verificationSessionTimes: PropTypes.number.isRequired,
        maxAttempts: PropTypes.number.isRequired,
        blockTimeout: PropTypes.number.isRequired,
    }

    render() {
        const {
            otpTtl,
            overallBlockTimeout,
            resendTimeout,
            otpLength,
            resendCount,
            verificationSessionTimes,
            maxAttempts,
            blockTimeout,
        } = this.props
        return (
            <>
                {this.renderListItem(__('OTP TTL (Seconds)'), otpTtl, true)}
                {this.renderListItem(__('OTP Length (Symbols)'), otpLength, true)}
                {this.renderListItem(__('Resend Timeout (Seconds)'), resendTimeout, true)}
                {this.renderListItem(__('Resend Count (Times)'), resendCount, true)}
                {this.renderListItem(
                    __('Verification Sessions Times (Times)'),
                    verificationSessionTimes,
                    true
                )}
                {this.renderListItem(
                    __('Failed Attempts Count Blocker (Times)'),
                    maxAttempts,
                    true
                )}
                {this.renderListItem(__('Blocking timeout (Seconds)'), blockTimeout)}
                {this.renderListItem(
                    __('Overall Blocks Timeout (Seconds)'),
                    overallBlockTimeout
                )}
            </>
        )
    }
}

export default connect(selectMfa)(MFAParameters)
