import React from 'react'
import PropTypes from 'prop-types'

import ConnectedDevice from './Device'
import DeviceSidebar from './Sidebar/DeviceSidebar'
import PanelSidebar from './Sidebar/PanelSidebar'

import ConnectedPanel from 'components/Devices/Panel'
import useDevices from 'hooks/pages/useDevices'
import useDevicesNavigation from 'hooks/pages/useDevicesNavigation'
import BlindModalWithPagination from 'ui/BlindModalWithPagination'
import {groups as renderDevicesOrder} from 'pages/Panel/Devices/List/Diagnostic'
import getPrevNext from 'utils/getPrevNext'

const PanelContent = ({panel}) => {
    return (
        <>
            <ConnectedPanel className="blindModalDevices-device" panel={panel} />
            <PanelSidebar panel={panel} />
        </>
    )
}

PanelContent.propTypes = {
    panel: PropTypes.object.isRequired,
}

const DeviceContent = ({
    deviceId,
    panelId,
    isNeo,
    startWithVideoOnDemandTab,
    startWithMeteoTab,
    onSelectDevice,
}) => {
    return (
        <>
            <ConnectedDevice deviceId={deviceId} panelId={panelId} />

            <DeviceSidebar
                key={deviceId}
                deviceId={deviceId}
                panelId={panelId}
                isNeo={isNeo}
                startWithVideoOnDemandTab={startWithVideoOnDemandTab}
                startWithMeteoTab={startWithMeteoTab}
                onSelectDevice={onSelectDevice}
            />
        </>
    )
}

DeviceContent.propTypes = {
    deviceId: PropTypes.number,
    panelId: PropTypes.number.isRequired,
    isNeo: PropTypes.bool.isRequired,
    startWithVideoOnDemandTab: PropTypes.bool.isRequired,
    startWithMeteoTab: PropTypes.bool.isRequired,
    onSelectDevice: PropTypes.func.isRequired,
}

const DevicesBlindModalBase = ({
    onSelectDevice,
    onSelectPanel,
    onClose,
    isDeviceInfoOpened,
    isPanelSelected,
    devices,
    selectedDeviceId,
    panel,
    startWithVideoOnDemandTab,
    startWithMeteoTab,
}) => {
    const selectPrevDevice = (prevDeviceId) => {
        if (prevDeviceId === null) {
            onSelectPanel()
        } else {
            onSelectDevice(prevDeviceId)
        }
    }

    const selectNextDevice = (nextDeviceId) => {
        onSelectDevice(nextDeviceId)
    }

    const [prevDeviceId, nextDeviceId] = getPrevNext(
        selectedDeviceId,
        devices,
        renderDevicesOrder
    )

    return (
        <BlindModalWithPagination
            onClose={onClose}
            opened={isDeviceInfoOpened}
            className="blindModalDevices"
            isPrevDisabled={isPanelSelected}
            isNextDisabled={nextDeviceId === null}
            onPrevClick={selectPrevDevice}
            onNextClick={selectNextDevice}
            prev={prevDeviceId}
            next={nextDeviceId}
        >
            {isPanelSelected ? (
                <PanelContent panel={panel} />
            ) : (
                selectedDeviceId && (
                    <DeviceContent
                        deviceId={selectedDeviceId}
                        panelId={panel.id}
                        isNeo={panel.isNeo}
                        startWithVideoOnDemandTab={startWithVideoOnDemandTab}
                        startWithMeteoTab={startWithMeteoTab}
                        onSelectDevice={onSelectDevice}
                    />
                )
            )}
        </BlindModalWithPagination>
    )
}

DevicesBlindModalBase.propTypes = {
    selectedDeviceId: PropTypes.number,
    startWithVideoOnDemandTab: PropTypes.bool.isRequired,
    startWithMeteoTab: PropTypes.bool.isRequired,
    panel: PropTypes.shape({
        id: PropTypes.number,
        isNeo: PropTypes.bool,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onSelectDevice: PropTypes.func.isRequired,
    prevDeviceId: PropTypes.number,
    nextDeviceId: PropTypes.number,
    isPanelSelected: PropTypes.bool,
    onSelectPanel: PropTypes.func.isRequired,
}

export default function DevicesBlindModal(props) {
    const devicesProps = useDevices()
    const devicesNavigationProps = useDevicesNavigation(
        props.selectedDeviceId,
        devicesProps.devices
    )

    const modalProps = {...props, ...devicesProps, ...devicesNavigationProps}

    return <DevicesBlindModalBase {...modalProps} />
}
