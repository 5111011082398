import {useActions} from 'modules/higherOrder/useActions'
import {
    checkNewer,
    fetch as fetchEvents,
    fetchNewer,
    fetchOlder,
    reset,
} from 'modules/panels/events/actions'
import {useSelector} from 'react-redux'
import useRouterPanelId from '../useRouterPanelId'

export default function usePanelEvents() {
    const panelId = useRouterPanelId()
    const centralStations = useSelector((state) => state.centralStations.store.byIds)
    const {
        rows,
        isLoading,
        error,
        hasOlder,
        newerCount,
        isNewerLoading,
        isOlderLoading,
        isPristine,
    } = useSelector((state) => state.panels.events)

    return {
        panelId,
        error,
        rows,
        isLoading,
        isOlderLoading,
        isNewerLoading,
        hasOlder,
        newerCount,
        isPristine,
        centralStations,
        ...useActions({
            fetch: () => fetchEvents(panelId),
            reset,
            fetchOlder,
            fetchNewer,
            checkNewer,
        }),
    }
}
