import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {
    BlindModalCard,
    CardHeaderBlindModal,
    CardHeaderBack,
    BlindModalCardContent,
    BlindModalCardActions,
} from 'ipmp-react-ui/Card'

export default function SecondBlind({row, closeBlind, Content}) {
    const [headerContent, setHeaderContent] = useState(null)
    const [footerContent, setFooterContent] = useState(null)

    return (
        <BlindModalCard>
            <CardHeaderBlindModal className="second-blind-modal-header">
                <CardHeaderBack onClick={closeBlind} />
                {headerContent}
            </CardHeaderBlindModal>
            <BlindModalCardContent>
                <Content
                    row={row}
                    setHeaderContent={setHeaderContent}
                    setFooterContent={setFooterContent}
                    closeBlind={closeBlind}
                />
            </BlindModalCardContent>
            {footerContent && (
                <BlindModalCardActions>{footerContent}</BlindModalCardActions>
            )}
        </BlindModalCard>
    )
}

SecondBlind.propTypes = {
    row: PropTypes.object.isRequired,
    closeBlind: PropTypes.func.isRequired,
    Content: PropTypes.func.isRequired,
}
