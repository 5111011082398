import React from 'react'
import * as PropTypes from 'prop-types'

import ModalCardForm from 'ui/ModalCardForm'
import __ from 'utils/i18n'

import withForm from 'containers/withForm'
import {editHadIcon} from 'modules/forms/handlers'
import {validConfigurableIconValue} from 'constants/had'

import EditIconList from './EditIconList'

function EditIcon({handle, hide, panelId, hadId, deviceType, defaultValue, ...props}) {
    defaultValue = validConfigurableIconValue(defaultValue)

    function onSubmit({icon}) {
        if (icon !== defaultValue) {
            handle({panelId, hadId, deviceType, icon})
        } else {
            hide()
        }
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={onSubmit}
            hide={hide}
            header={__('Edit output icon')}
            className="modalFormEditHadIcon"
            {...props}
        >
            <EditIconList defaultValue={defaultValue} />
        </ModalCardForm>
    )
}

EditIcon.propTypes = {
    handle: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
    hadId: PropTypes.number.isRequired,
    deviceType: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
}

export default withForm(editHadIcon)(EditIcon)
