import React from 'react'
import ButtonComponent from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import useEvents from 'hooks/pages/useEvents'

export default function LoadNewEventsButton() {
    const {newerCount, fetchNewer} = useEvents()

    return (
        !!newerCount && (
            <ButtonComponent onClick={fetchNewer}>
                {__('Load %d new events', newerCount)}
            </ButtonComponent>
        )
    )
}
