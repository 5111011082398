import ProcessStatus from 'components/Processes/ProcessStatus'
import PanelCell from 'components/TableCells/PanelCell'
import React from 'react'
import {compose} from 'redux'

import {stop} from 'permissions/processes/actions'
import selection from 'permissions/processes/selection'

import withProcesses from 'containers/withProcesses'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import {__} from 'utils/i18n'
import processType from 'constants/processType'
import ProcessStopButton from 'components/Processes/ProcessStopButton.js'

import Definition from 'ipmp-react-ui/Definition'
import Table from 'ipmp-react-ui/Table'
import Duration from 'ipmp-react-ui/Duration'
import DateTime from 'ipmp-react-ui/DateTime'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import usePermission from 'hooks/usePermission'

const empty = <span className="empty">—</span>

export const allColumns = [
    {
        maxWidth: 150,
        name: () => __('Started'),
        render: ({started}) => <DateTime datetime={started} timeFirst />,
    },
    {
        maxWidth: 150,
        name: () => __('Panel'),
        render: ({panelId, serial}) =>
            panelId ? <PanelCell id={panelId} serial={serial} /> : empty,
    },
    {
        width: 150,
        name: () => __('Process'),
        render: ({type, description}) => (
            <Definition title={processType(type)} detail={description} />
        ),
    },
    {
        width: 100,
        name: () => __('Status'),
        render: ProcessStatus,
    },
    {
        width: 50,
        maxWidth: 200,
        name: () => __('User'),
        render: ({user}) => user,
    },
    {
        width: 50,
        maxWidth: 200,
        name: () => __('Email'),
        render: ({email}) => email,
    },
    {
        tooltip: false,
        width: 30,
        maxWidth: 150,
        name: () => __('Duration'),
        render: ({started, finished}) => <Duration from={started} to={finished} />,
    },
    {
        tooltip: false,
        align: 'right',
        permission: stop,
        width: 50,
        fixed: true,
        render: ({isStoppable, id}, {stop}) => (
            <ProcessStopButton onClick={() => stop(id)} disabled={!isStoppable} />
        ),
    },
]

const ConnectedTable = compose(
    withSelection(),
    withProcesses(),
    withLoader(
        ({init, startPoll}) => {
            init()
            startPoll()
        },
        ({stopPoll}) => stopPoll()
    )
)(Table)

export default function ProcessesTable(props) {
    const permissions = usePermission({
        hasSelection: selection,
    })
    const {columns} = useTableColumnsPermission(allColumns)

    const tableProps = {...props, ...permissions}

    return (
        <ConnectedTable
            {...tableProps}
            columns={columns}
            fullHeight
            emptyMessage={() => __('No processes')}
        />
    )
}
