import {handleActions} from 'redux-actions'

import {fetch, setError, setLoading} from './actions'
import {set} from 'immutable-modify'

const defaultState = {
    isLoading: false,
    error: null,
}

export default handleActions(
    {
        [fetch](state) {
            return {
                ...state,
                error: null,
                isLoading: true,
            }
        },
        [setLoading](state, {payload}) {
            return set(state, 'isLoading', payload)
        },
        [setError](state, {payload}) {
            return {
                ...state,
                error: payload,
                isLoading: false,
            }
        },
    },
    defaultState
)
