import {all} from 'redux-saga/effects'

import store from './store/saga'
import links from './links/saga'
import list from './list/saga'
import one from './one/saga'
import select from './select/saga'
import customizations from './customizations/saga'
import notifications from './notifications/saga'
import centralStations from './centralStations/saga'

export default function* () {
    yield all([
        store(),
        links(),
        list(),
        one(),
        select(),
        notifications(),
        customizations(),
        centralStations(),
    ])
}
