import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {clear as interactiveUsersClear} from 'modules/interactiveUsers/autocomplete/actions'
import __ from 'utils/i18n'
import isNil from 'lodash-es/isNil'
import {InteractiveUsersSelect} from './InteractiveUsersSelect'

export const InteractiveUsersAutocomplete = ({interactiveUser, isRegisterNew}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        !interactiveUser && dispatch(interactiveUsersClear())
    }, [interactiveUser])

    if (!isNil(interactiveUser)) {
        return (
            <Autocomplete
                label={__('Email')}
                name="email"
                disabled
                defaultValue={interactiveUser.email}
                defaultLabel={interactiveUser.email}
            />
        )
    }

    return (
        <InteractiveUsersSelect
            label={__('Email')}
            name="email"
            isCreatable={isRegisterNew}
        />
    )
}
