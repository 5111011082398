import React from 'react'

import {edit} from 'permissions/basicConfiguration/actions'

import {withVisibility} from 'containers/withVisibility'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Definition from 'ipmp-react-ui/Definition'
import path from 'utils/path'
import SaveBasicConfiguration from './GridBar/SaveBasicConfiguration'
import {useHistory} from 'react-router-dom'
import usePermission from 'hooks/usePermission'
import useBasicConfiguration from 'hooks/pages/useBasicConfiguration'
import IconBack from 'ui/IconBack'

function BasicConfigurationGridBarBase() {
    const history = useHistory()
    const {name, vendor, changes} = useBasicConfiguration()

    const handleGoBack = () => {
        history.push(path('system.basicConfigurations', {}))
    }

    const isChanged = changes && Object.keys(changes).length > 0

    return (
        <Bar>
            <Button onClick={handleGoBack} className="btn--goBack">
                <IconBack />
            </Button>

            <Definition
                multiLine
                className="title title--large"
                title={name}
                detail={vendor}
            />

            <BarSpace />

            <SaveBasicConfiguration disabled={!isChanged} />
        </Bar>
    )
}

const ConnectedBasicConfigurationGridBar = withVisibility()(BasicConfigurationGridBarBase)

export default function BasicConfigurationGridBar() {
    const {isPermitted} = usePermission(edit)

    return <ConnectedBasicConfigurationGridBar isVisible={isPermitted} />
}
