import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from 'api/base/mapSuggests'
import generateSuggestString from 'api/base/generateSuggestString'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {get, post} from 'api/http'
import {
    keysMap,
    mapCentralStation,
    centralStationMapper,
    mapFormData,
    mapProfile,
    mapProtocols,
    mapSerialPorts,
    mapCentralStationGroups,
} from 'api/centralStations.map'

export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/centralstation/getallstations', {start, count, filter, query}).then(
        createRowsResponseParser(mapCentralStation)
    )
}

export function fetchEventProfiles() {
    return get('/centralstation/getprofiles').then(({rows, count}) =>
        rows.map(mapProfile)
    )
}

export function create(data) {
    return post('/centralstation/add', mapFormData(data))
        .then(centralStationMapper)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function edit(data) {
    return post('/centralstation/edit', mapFormData(data)).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}

export function getSerialPorts() {
    return get('/centralstation/serials').then(({rows, count}) =>
        rows.map(mapSerialPorts)
    )
}

export function getProtocols() {
    return get('/centralstation/protocols').then(({rows, count}) =>
        rows.map(mapProtocols)
    )
}

export function getCentralStation(id) {
    return get('/centralstation/one', {cls_id: parseInt(id)}).then(mapCentralStation)
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('centralstation/suggeststations', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}

export function remove(ids) {
    return post('/centralstation/remove', {cls_id: ids})
}

export function canAdd() {
    return get('/centralstation/canadd')
}

export function getGroups({start, query, perPage: count, centralStationId}) {
    return get('/centralstation/getgroups', {
        start,
        query,
        count,
        cls_id: centralStationId,
    }).then(createRowsResponseParser(mapCentralStationGroups))
}
