import {get, post} from 'api/http'
import {mapProcess} from 'api/processes'

import {mapFetchResponse} from './systemLogs.map'

export function fetch(panelId) {
    return get(`/unit/${panelId}/system-logs`).then(mapFetchResponse)
}

export function refresh(panelId, batchId) {
    return post(`/unit/${panelId}/system-logs/refresh`, {
        batch_id: batchId,
    }).then(mapProcess)
}

export function remove(panelId, id) {
    return post(`/unit/${panelId}/system-logs/remove/${id}`)
}
