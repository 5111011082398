import {createSelector} from 'reselect'
import has from 'lodash-es/has'
import processType from 'constants/processType'

export const selectRunnersListRows = createSelector(
    (state) => state.runners.list.page,
    (state) => state.runners.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectRunnersList = createSelector(
    selectRunnersListRows,
    (state) => state.runners.list,
    (state) => state.panels.store.byIds,
    (state) => state.runners.store.stoppingIds,
    (rows, {isLoading, error, total, start}, panelsByIds, stoppingIds) => ({
        rows,
        isLoading,
        error,
        total,
        start,
        disabledRowsIds: rows
            .filter((runner) => !has(panelsByIds, runner.panelId))
            .map((runner) => runner.id),
        stoppingIds,
    })
)

export const selectRunnersGridBar = createSelector(
    (state, {id}) => state.batches.store.byIds[id],
    (batch) => ({
        batchInfo: batch
            ? {
                  started: batch.started,
                  batchType: processType(batch.type),
              }
            : null,
    })
)
