import {put} from 'redux-saga/effects'
import {revertUpdateHad, updateHad} from 'modules/panels/had/actions'
import {takeProcessCompleteFailed} from 'modules/processes/manager/takeProcess'
import {PROCESS_TYPE_HAD_COMMAND} from 'constants/processTypes'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'

export default function* watchSubmitHadDeviceControlForm({
    payload: {result},
    meta: {
        commands,
        params: {hadId, deviceType, panelId},
    },
}) {
    yield ensureProcesses(result)
    yield put(updateHad({commands, id: hadId, deviceType}))

    while (true) {
        const resultPrs = yield takeProcessCompleteFailed(
            PROCESS_TYPE_HAD_COMMAND,
            panelId
        )
        if (resultPrs) {
            yield put(revertUpdateHad({id: hadId, deviceType}))
        }
    }
}
