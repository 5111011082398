import React from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'

import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'
import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import {ReactComponent as IconCalendarTime} from 'ipmp-react-ui/icons/calendar-time.svg'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'
import {__, __n} from 'utils/i18n'
import moment from 'moment/moment'
import Definition from 'ipmp-react-ui/Definition'
import {buildDownloadReportResultHref} from 'api/reports'
import getContentName from 'constants/reportContentOption'
import {getPatternLabel, getTypeLabel, EXPORT_TYPES} from 'constants/reportTypes'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

const Report = ({report, isDownloadActive}) => {
    const renderSection = (title, content, map) => {
        if (!content || content.length === 0) {
            return
        }

        return (
            <div>
                <div className="hint">{title}</div>
                <ul className="report-section-list">
                    {content &&
                        content.map((text, key) => (
                            <li key={key}>{map ? map(text) : text}</li>
                        ))}
                </ul>
            </div>
        )
    }

    const {
        id,
        unitId,
        title,
        contents,
        exportTypes,
        configurations,
        emails,
        isLast,
        ftps,
        schedule,
        units,
    } = report

    const reportDate = schedule.timestamp
        ? moment.unix(schedule.timestamp).format('D MMM YYYY, h:mm a')
        : null

    return (
        <div className="report">
            <div>
                <OverflowTextTooltip className="report-title-tooltip" tooltip={title}>
                    <Definition multiLine className="title title--large" title={title} />
                </OverflowTextTooltip>
                <div className="title-hint">
                    {units && (
                        <span>
                            <IconPanel />
                            {__n('%d unit involved', '%d units involved', units)}
                        </span>
                    )}
                    {reportDate && (
                        <span>
                            <IconCalendarTime />
                            {reportDate}
                        </span>
                    )}
                    <span>
                        <IconDiscovery /> {getPatternLabel(schedule.pattern)}
                    </span>
                </div>
            </div>

            {exportTypes && isLast && isDownloadActive && (
                <div>
                    <div className="hint">{__('EXPORT')}</div>
                    <div className="report-download">
                        {EXPORT_TYPES.map(
                            (type) =>
                                exportTypes.includes(type) && (
                                    <Button
                                        key={type}
                                        small
                                        flat
                                        currentTab
                                        href={buildDownloadReportResultHref(
                                            unitId,
                                            id,
                                            type
                                        )}
                                        Icon={IconDownload}
                                        children={getTypeLabel(type)}
                                    />
                                )
                        )}
                    </div>
                </div>
            )}

            {renderSection(__('Sent to'), emails)}
            {renderSection(__('Sent to ftp'), ftps)}
            {renderSection(__('Report Contains'), contents, getContentName)}
            {renderSection(__('Selected configurations'), configurations)}
        </div>
    )
}

Report.propTypes = {
    report: PropTypes.shape({
        title: PropTypes.string.isRequired,
        exportTypes: PropTypes.arrayOf(PropTypes.oneOf(EXPORT_TYPES)),
        emails: PropTypes.arrayOf(PropTypes.string),
        units: PropTypes.string,
        ftps: PropTypes.arrayOf(PropTypes.string),
        contents: PropTypes.arrayOf(
            PropTypes.oneOf(['Events', 'Faults', 'Processes', 'RI Tests'])
        ),
        configurations: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    isDownloadActive: PropTypes.bool,
}

Report.defaultProps = {
    isDownloadActive: true,
}

export default Report
