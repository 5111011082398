import {connect} from 'react-redux'
import {compose} from 'redux'
import {stop} from 'permissions/processes/actions'

import {withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'
import withRunners from 'containers/withRunners'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: stop}),
    withVisibility(),
    withRunners(),
    connect(
        ({
            runners: {
                store: {byIds},
            },
        }) => ({runnersByIds: byIds})
    ),
    withProps(({stop, runnersByIds}) => ({
        onClick: (ids) => {
            return stop(ids)
        },
        label: __('Stop'),
    })),
    withSelectionHandler(
        (id, {runners}) => runners.store.byIds[id] && runners.store.byIds[id].isStoppable
    )
)(Button)
