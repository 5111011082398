import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {disconnectInteractiveUser} from 'modules/panelInteractiveUsers/store/actions'
import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'
import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

const ButtonVisibility = compose(withVisibility(), withConfirmation())(Button)

export default function DisconnectUserCell({row}) {
    const {id, isAssign, label, email} = row
    const panelId = useRouterPanelId()
    const {onDisconnect} = useActions({
        onDisconnect: () => disconnectInteractiveUser(id, panelId),
    })

    return (
        <ButtonVisibility
            isVisible={isAssign}
            flat
            small
            Icon={IconMinus}
            onClick={onDisconnect}
            message={__('Do you really want to unassign %s?', label || email)}
            title={__('Unassign Owner')}
            children={false}
        />
    )
}
