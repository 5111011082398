import PropTypes from 'prop-types'
import React from 'react'

import {__} from 'utils/i18n'

import EditNotificationsButton from './EditNotificationsButton'
import EventProfileList from './EventProfileList'
import usePermission from 'hooks/usePermission'
import {
    notificationsEdit,
    notificationsListUserNotifications,
} from 'permissions/group/navigation'

export default function NotificationProfile({settings, groupId}) {
    const isEditPermitted = usePermission(notificationsEdit)
    const {isPermitted} = usePermission(notificationsListUserNotifications)
    if (!settings.notificationProfile) {
        return null
    }
    if (isPermitted) {
        return (
            <>
                <div className="card-header">
                    <h3> {__('End-User notifications')}</h3>
                    {isEditPermitted.isPermitted && (
                        <EditNotificationsButton groupId={groupId} />
                    )}
                </div>
                <EventProfileList
                    profiles={settings.notificationProfile}
                    availableProfiles={settings.availableProfiles.notificationProfile}
                />
            </>
        )
    }
    return null
}

NotificationProfile.propTypes = {
    settings: PropTypes.shape({
        notificationProfile: PropTypes.object,
        availableProfiles: PropTypes.shape({
            notificationProfile: PropTypes.object.isRequired,
        }),
    }),
    groupId: PropTypes.number.isRequired,
}
