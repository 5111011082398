import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import processErrorMessage from 'constants/processError'

import ProgressBar from 'ipmp-react-ui/ProgressBar'
import Definition from 'ipmp-react-ui/Definition'
import Spinner from 'ipmp-react-ui/Spinner'
import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/events/failed.svg'
import {ReactComponent as IconCompleted} from 'ipmp-react-ui/icons/events/delivered.svg'
import {ReactComponent as IconProcessing} from 'ipmp-react-ui/icons/events/processing.svg'

import {ProcessStopFab} from './ProcessStopButton'

export default class BatchRunnerStatus extends PureComponent {
    static propTypes = {
        row: PropTypes.shape({
            isFailedToStart: PropTypes.bool,
            errorMessage: PropTypes.string,
            error: PropTypes.string,
            details: PropTypes.string,
            percentage: PropTypes.number,
            id: PropTypes.number,
            status: PropTypes.string,
            isRunning: PropTypes.bool,
            isFailed: PropTypes.bool,
            isSuccessful: PropTypes.bool,
        }),
        onStop: PropTypes.func,
        isStopPermitted: PropTypes.bool,
    }

    stopProcess = () => this.props.onStop(this.props.row.id)

    renderNotStarted() {
        const {isFailedToStart, errorMessage} = this.props.row
        if (isFailedToStart) {
            return (
                <>
                    <Definition
                        className="danger"
                        Icon={IconAlert}
                        title={errorMessage || __('Failed to start')}
                    />
                    <ProgressBar className="batch-runner--failed" max={100} value={100} />
                </>
            )
        }

        return (
            <>
                <Definition Icon={Spinner} title={__('Starting…')} />
                <ProgressBar className="batch-runner--failed" max={100} value={100} />
            </>
        )
    }

    renderFailed() {
        const {error, errorMessage} = this.props.row

        return (
            <>
                <Definition
                    className="danger"
                    Icon={IconAlert}
                    title={processErrorMessage(error, errorMessage)}
                />
                <ProgressBar className="batch-runner--failed" max={100} value={100} />
            </>
        )
    }

    renderRunning = () => {
        const {percentage, isStoppable} = this.props.row
        const {isStopPermitted} = this.props

        return (
            <>
                <ProcessStopFab
                    className="batchInfo-list-item-stopProcess"
                    onClick={this.stopProcess}
                    disabled={!isStoppable || !isStopPermitted}
                />
                <Definition
                    className="running"
                    Icon={IconProcessing}
                    title={__('In process ...')}
                />
                <ProgressBar
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                />
            </>
        )
    }

    renderSuccessful() {
        return (
            <>
                <Definition
                    className="success"
                    Icon={IconCompleted}
                    title={__('Succeeded')}
                />
                <ProgressBar max={100} value={100} />
            </>
        )
    }

    renderStopping() {
        return (
            <Definition className="danger" Icon={IconAlert} title={__('Stopping...')} />
        )
    }

    renderStatus = () => this.props.row.status

    render() {
        const {
            isStopping,
            row: {isNotRunned, isRunning, isFailed, isSuccessful},
        } = this.props

        switch (true) {
            case isStopping:
                return this.renderStopping()

            case isNotRunned:
                return this.renderNotStarted()

            case isFailed:
                return this.renderFailed()

            case isRunning:
                return this.renderRunning()

            case isSuccessful:
                return this.renderSuccessful()

            default:
                return this.renderStatus()
        }
    }
}
