import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconSimCard} from 'ipmp-react-ui/icons/sim-card.svg'
import {ReactComponent as IconEthernet} from 'ipmp-react-ui/icons/ethernet.svg'
import {ReactComponent as IconHttp} from 'ipmp-react-ui/icons/HTTP.svg'
import {ReactComponent as IconUdp} from 'ipmp-react-ui/icons/udp.svg'

import Definition from 'ipmp-react-ui/Definition'
import {
    CS_CONNECTION_TYPE_HTTP,
    CS_CONNECTION_TYPE_SERIAL,
    CS_CONNECTION_TYPE_TCP,
    CS_CONNECTION_TYPE_FIBRO,
} from 'constants/centralStation'

export default class CommunicationCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            host: PropTypes.string,
            port: PropTypes.number,
            device: PropTypes.string,
        }),
    }

    renderSerial() {
        const {
            row: {device},
        } = this.props

        return (
            <Definition
                Icon={IconSimCard}
                title={device}
                className="central-stations-table-definition-icon"
            />
        )
    }

    getIconProtocol = (connectionType) => {
        switch (connectionType) {
            case CS_CONNECTION_TYPE_FIBRO:
                return IconUdp
            case CS_CONNECTION_TYPE_TCP:
                return IconEthernet
            case CS_CONNECTION_TYPE_HTTP:
                return IconHttp
        }
    }

    renderProtocol(connectionType) {
        const {
            row: {host, port},
        } = this.props

        const icon = this.getIconProtocol(connectionType)

        return (
            <Definition
                Icon={icon}
                title={host}
                detail={port}
                className="central-stations-table-definition-icon"
            />
        )
    }

    render() {
        const {
            row: {connectionType},
        } = this.props
        if (connectionType === CS_CONNECTION_TYPE_SERIAL) {
            return this.renderSerial()
        }
        return this.renderProtocol(connectionType)
    }
}
