import {handleActions} from 'redux-actions'

import {updateRefreshProcess} from './actions'

export default handleActions(
    {
        [updateRefreshProcess](state, {payload: {panelId, process}}) {
            // eslint-disable-next-line no-unused-vars
            const {[panelId]: current, ...rest} = state

            return {
                ...rest,
                ...(process !== null ? {[panelId]: process} : {}),
            }
        },
    },
    {}
)
