import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {showRenamePartitionModal} from 'modules/modals/actions'
import {setLabel} from 'permissions/panel/labels'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withFeatureRejection} from 'containers/withFeature'
import {selectPartitionLabelsEditable} from 'modules/features/store/selectors'
import Void from 'components/Void'

export default compose(
    withFeatureRejection(selectPartitionLabelsEditable, Void),
    withPermission({isVisible: setLabel}),
    withVisibility(),
    connect(
        () => ({}),
        (dispatch, {partition, panelId}) =>
            bindActionCreators(
                {
                    onClick: () => showRenamePartitionModal(panelId, partition),
                },
                dispatch
            )
    )
)(Button)
