import mapper from 'utils/dataMapper'
import set from 'lodash-es/set'

export const map = mapper((data) => {
    if (data.user) {
        const {user, permissions, settings, serverSettings} = data

        return {
            user: {
                id: parseInt(user.usr_id),
                email: user.usr_email,
                name: user.usr_name,
                perPage: user.usr_per_page,
                phone: user.usr_phone,
                status: user.usr_status,
                countryId: user.coy_id,
                countryName: user.coy_name,
                roleName: user.roe_name,
            },
            permissions: Object.keys(permissions).reduce(
                (mapped, resource) => set(mapped, resource, permissions[resource]),
                {}
            ),
            settings,
            serverSettings,
        }
    }

    return {
        phone: data.phone,
        userConfirmed: data.user_confirmed,
        otpLength: data.otpLength,
    }
})

export const keysMap = {
    email: 'usr_email',
    password: 'usr_password',
}
