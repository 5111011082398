import React from 'react'
import {useSelector} from 'react-redux'
import get from 'lodash-es/get'
import {compose} from 'redux'

import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'

import {setEnabled} from 'modules/users/store/actions'
import {suspend as suspendPermission} from 'permissions/users/selection'
import withSelection, {ifSelection} from 'containers/withSelection'
import {__} from 'utils/i18n'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {selectUsersListRows} from 'modules/users/list/selectors'

const selectedUsers = (selection) => {
    const {rows} = useSelector(selectUsersListRows)
    const selected = selection.toJS()

    return rows.filter((user) => {
        return selected.includes(user.id) && user.isEnabled
    })
}

function SuspendUserButton({selection}) {
    const {isPermitted} = usePermission(suspendPermission)
    const suspend = useActions(setEnabled)
    const selected = selectedUsers(selection)
    const isMassiveAction = selected.length > 1
    const userSelected = !isMassiveAction && selected[0]

    const getMessage = () => {
        return isMassiveAction
            ? __('Do you really want to suspend %d selected users?', selected.length)
            : __('Do you really want to suspend the user: %s?', userSelected.name)
    }
    const onEnabled = () => {
        suspend(selection.toJS(), false)
    }

    if (!isPermitted) {
        return null
    }

    return (
        <WithConfirmationButton
            onClick={onEnabled}
            message={getMessage()}
            title={__('Suspend users')}
            Icon={PauseIcon}
            label={__('Suspend')}
        />
    )
}

export default compose(
    ifSelection((id, {users}) => get(users, ['store', 'byIds', id, 'isEnabled'], false)),
    withSelection()
)(SuspendUserButton)
