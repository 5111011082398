import {createSelector} from 'reselect'

export const selectInteractiveUserPanelsList = createSelector(
    (state) => state.interactiveUserPanels.list,
    (state) => state.interactiveUserPanels.store.byIds,
    ({page, isLoading}, store) => {
        return {
            rows: page.map((id) => store[id] || {}),
            isLoading,
        }
    }
)

export const selectIsPanelAssigned = createSelector(
    (state) => state.interactiveUserPanels.list,
    (state, id) => id,
    (list, id) => {
        return {
            isAssigned: list.page.includes(id),
        }
    }
)
