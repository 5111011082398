import React, {useEffect} from 'react'

import Spinner from 'ipmp-react-ui/Spinner'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export default function withLoader(
    onMount: ?(Object) => any,
    onUnmount: ?(Object) => any
) {
    return (WrappedComponent) =>
        function WithLoader(loaderProps) {
            useEffect(() => {
                onMount && onMount(loaderProps)

                return () => {
                    onUnmount && onUnmount(loaderProps)
                }
            }, [])

            const handleClick = () => {
                onMount && onMount(loaderProps)
            }

            const {isLoading, error, ...props} = loaderProps

            if (isLoading) {
                return <Spinner dataTestId="loading-spinner" />
            }

            if (error) {
                return (
                    <Error error={error}>
                        {onMount && <Button onClick={handleClick}>{__('Retry')}</Button>}
                    </Error>
                )
            }

            return <WrappedComponent {...props} />
        }
}
