import React, {useState} from 'react'
import {compose} from 'redux'

import {AppToggle} from 'components/DropDown/ApplicationInteractiveDropdown'

import {withVisibility} from 'containers/withVisibility'

import {encryption} from 'permissions/panel/actions'
import {setEncryptionState} from 'modules/panels/store/actions'
import {MODULE_TYPE_BBA, moduleTypeTitle} from 'constants/moduleTypes'
import {showConfirmationModal} from 'modules/modals/actions'

import {withFeatureRejection} from 'containers/withFeature'
import {selectEncryption} from 'modules/features/store/selectors'
import Void from 'components/Void'
import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'
import {useActions} from 'modules/higherOrder/useActions'

const ToggleEncryptionItemBase = (props) => {
    const {
        handle,
        panelId,
        type,
        showConfirmationModal,
        setPreventHideOnOuterClick,
        encrypted,
    } = props

    const [changeValue, setChangeValue] = useState(encrypted)

    const handleEncryptionChange = () => {
        const modalHandle = () => {
            handle(panelId, type, !changeValue)
        }

        showConfirmationModal(
            [modalHandle, () => setChangeValue(!changeValue)],
            {
                message: __('Do you really want to change encryption settings?'),
                title: __('Toggle encryption'),
            },
            setPreventHideOnOuterClick
        )
    }

    return (
        <div className="menu-item menu-item--toggle">
            <AppToggle
                permission={encryption}
                label={props.label}
                onChange={handleEncryptionChange}
                checked={changeValue}
                disabled={props.connected}
            />
        </div>
    )
}

const ToggleEncryptionItemVisibility = compose(
    withVisibility(),
    withFeatureRejection(selectEncryption, Void)
)(ToggleEncryptionItemBase)

export default function ToggleEncryptionItem(props) {
    const panelProps = usePanel()
    const {isPermitted} = usePermission(encryption)
    const actions = useActions({
        handle: setEncryptionState,
        showConfirmationModal,
    })
    const toggleProps = {...props, ...panelProps, ...actions}
    const {panel, type} = toggleProps
    const {modules} = panel || {}
    const {bba, gprs} = modules || {}

    return (
        <ToggleEncryptionItemVisibility
            {...toggleProps}
            isVisible={isPermitted}
            label={moduleTypeTitle(type)}
            encrypted={
                type === MODULE_TYPE_BBA ? bba && bba.encrypted : gprs && gprs.encrypted
            }
        />
    )
}
