import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'

import {
    showEditPanelCustomerInfoModal,
    showEditPanelInfoModal,
} from 'modules/modals/actions'
import {selectFotaAotaVersions} from 'modules/devices/list/selectors'

export default function withPanelInfo() {
    return compose(
        withRouter,
        connect(
            ({panels, ...state}, {match}) => {
                const id = parseInt(match.params.id)
                const panel = panels.store.byIds[id]
                const fotaAotaVersions = selectFotaAotaVersions(state, id)

                return {
                    panel,
                    fotaAotaVersions,
                    isLoading: panels.one.isLoading,
                }
            },
            (dispatch, {match, googleApiError}) => {
                const id = parseInt(match.params.id)

                return bindActionCreators(
                    {
                        editPanelInfo: () => showEditPanelInfoModal(id),
                        editCustomerInfo: () =>
                            showEditPanelCustomerInfoModal(id, googleApiError),
                    },
                    dispatch
                )
            }
        )
    )
}
