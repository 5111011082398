// possible levels - 99 (1..99)
// where 1 - 1%, 99 - 100%
import {HAD_STATE_OFF, HAD_STATE_ON, isOff, isOn} from 'constants/had/hadState'
import {switcherTypeActionTitleMap} from 'constants/had/switcher'

export const LEVEL_COEFFICIENT = 100 / 99
export const dimmerLevelToPercent = (level) => Math.round((level - 1) * LEVEL_COEFFICIENT)
export const dimmerPercentToLevel = (percent) =>
    percent ? Math.round(percent / LEVEL_COEFFICIENT) + 1 : undefined

export const resolveDimmerButtonLabel = (state) => {
    switch (true) {
        case isOn(state):
            return switcherTypeActionTitleMap.get(HAD_STATE_OFF)()
        case isOff(state):
            return switcherTypeActionTitleMap.get(HAD_STATE_ON)()
        default:
            return switcherTypeActionTitleMap.get(HAD_STATE_ON)()
    }
}
