import React, {Component} from 'react'
import PropTypes from 'prop-types'

import DropDownButton from 'ipmp-react-ui/DropDownButton'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {IdSetSubstractive} from 'modules/selection/store'

export default class SelectionDropDown extends Component {
    static propTypes = {
        rows: PropTypes.arrayOf(PropTypes.object).isRequired,
        selection: PropTypes.shape({
            has: PropTypes.func.isRequired,
            hasAny: PropTypes.func.isRequired,
            isEmpty: PropTypes.func.isRequired,
        }).isRequired,
        select: PropTypes.func,
        deselect: PropTypes.func,
        deselectAll: PropTypes.func,
        selectAll: PropTypes.func,
        total: PropTypes.number,
        isSelectEverythingAvailabel: PropTypes.bool,
        disabledRowsIds: PropTypes.array,
    }

    static defaultProps = {
        isSelectEverythingAvailabel: false,
    }

    toggleSelection = () => {
        if (this.isAnyRowSelected()) {
            this.deselectAll()
        } else {
            this.select(this.availableRows)
        }
    }

    get availableRows() {
        const {disabledRowsIds, rows} = this.props

        if (disabledRowsIds && disabledRowsIds.length) {
            return rows.filter((row) => !disabledRowsIds.includes(row.id))
        }

        return rows
    }

    select = (rows) => {
        this.props.select && this.props.select(rows)
    }

    selectAll = () => {
        const {selection} = this.props

        if (selection instanceof IdSetSubstractive) {
            this.props.reverse && this.props.reverse({idSet: selection})
        }

        this.select(this.availableRows)
    }

    deselectAll = () => {
        this.props.deselect && this.props.deselect(this.props.rows)
    }

    clearSelection = () => {
        this.props.deselectAll && this.props.deselectAll()
    }

    isAllRowsSelected() {
        const {rows, selection} = this.props
        return selection && rows && rows.length && selection.has(rows)
    }

    isAnyRowSelected() {
        const {rows, selection} = this.props
        return selection && rows && selection.hasAny(rows)
    }

    selectEverything = () => {
        const {selectAll, total, disabledRowsIds, deselect} = this.props
        selectAll && total && selectAll(total)

        if (disabledRowsIds) {
            deselect && deselect(disabledRowsIds)
        }
    }

    get isSelectEverythingAvailabel() {
        return this.props.isSelectEverythingAvailabel
    }

    reverseSelection = () => {
        const {total, selection} = this.props

        this.props.reverse && this.props.reverse({idSet: selection, total})
    }

    get isReverseVisible() {
        return false
    }

    render() {
        const {selection, ...props} = this.props

        delete props.select
        delete props.close
        delete props.deselectAll
        delete props.onUnmount
        delete props.rows

        return (
            <DropDownButton
                {...props}
                checkbox
                checked={!!this.isAllRowsSelected()}
                indeterminate={!selection.isEmpty()}
                shortcut="a"
                onChange={this.toggleSelection}
                dataTestId="selection-drop-down"
            >
                <Menu>
                    {this.isSelectEverythingAvailabel ? (
                        <MenuItem onClick={this.selectEverything}>
                            {__('Everything')}
                        </MenuItem>
                    ) : null}
                    <MenuItem
                        onClick={this.selectAll}
                        disabled={false && this.isAllRowsSelected()}
                    >
                        {__('All')}
                    </MenuItem>
                    <MenuItem onClick={this.deselectAll}>{__('None')}</MenuItem>
                    <MenuItem
                        onClick={this.clearSelection}
                        disabled={false && selection.isEmpty()}
                    >
                        {__('Clear')}
                    </MenuItem>
                    {this.isReverseVisible && (
                        <MenuItem onClick={this.reverseSelection}>
                            {__('Reverse')}
                        </MenuItem>
                    )}
                </Menu>
            </DropDownButton>
        )
    }
}
