import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import {showActivatePanel} from 'modules/modals/actions'
import {selectPanel} from 'modules/panels/store/selectors'
import {activate} from 'permissions/panel/actions'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withFeatureRejection} from 'containers/withFeature'
import {selectActivation} from 'modules/features/store/selectors'
import Void from 'components/Void'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'
import {ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED} from 'constants/panelActivationStatus'

export default compose(
    withPermission({isVisible: activate}),
    connect(
        (state, {panelId, isVisible}) => {
            const {isActivating, activationStatus, activatingStarted} = selectPanel(
                state,
                {panelId}
            )

            return {
                isVisible:
                    isVisible &&
                    !isActivating &&
                    !activatingStarted &&
                    activationStatus === ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED,
            }
        },
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    showActivatePanel: () => showActivatePanel(panelId),
                },
                dispatch
            )
    ),
    withVisibility(),
    withFeatureRejection(selectActivation, Void),
    withProps(({showActivatePanel}) => ({
        label: __('Activate'),
        onClick: showActivatePanel,
    }))
)(Button)
