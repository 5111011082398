import {__} from 'utils/i18n'

export const TEMP_UNIT_F = 'F'
export const TEMP_UNIT_C = 'C'
export const DEFAULT_TEMP_UNIT = TEMP_UNIT_C

const list = [
    {key: TEMP_UNIT_C, name: () => __('Celsius'), label: '℃'},
    {key: TEMP_UNIT_F, name: () => __('Fahrenheit'), label: '°F'},
]

export function getTemperatureUnits() {
    return list
}

const convertCelsiusToFahrenheit = (celsius) => (celsius * 9) / 5 + 32
const convertFahrenheitToCelsius = (fahrenheit) => ((fahrenheit - 32) * 5) / 9

export const convertTemperatureMap = new Map([
    [TEMP_UNIT_F, convertCelsiusToFahrenheit],
    [TEMP_UNIT_C, convertFahrenheitToCelsius],
])

export function temperatureDeviceConverter(celsius, unit) {
    const isNeedToConvert = unit !== TEMP_UNIT_C

    if (isNeedToConvert) {
        const convertor = convertTemperatureMap.get(unit)

        if (!convertor) {
            return convertTemperatureMap.get(TEMP_UNIT_F)(celsius)
        }

        return convertor(celsius)
    }

    return celsius
}

export function temperatureKeyToLabel(key) {
    const unit = list.find((unit) => unit.key == key)
    if (!unit) {
        return ''
    }
    return unit.label
}
