import {useSelector} from 'react-redux'
import {selectEventTypesListRows} from 'modules/eventTypes/list/selectors'
import {init, reset, startPoll, stopPoll} from 'modules/eventTypes/list/actions'
import {useActions} from 'modules/higherOrder/useActions'

export default function useEventTypes() {
    return {
        ...useSelector(selectEventTypesListRows),
        ...useActions({
            init,
            reset,
            startPoll,
            stopPoll,
        }),
    }
}
