import {bindActionCreators} from 'redux'
import {init, setStart, startPoll, stopPoll, reset} from 'modules/actionLog/list/actions'
import usePerPage from '../usePerPage'
import {useDispatch, useSelector} from 'react-redux'

export default function useActionLog() {
    const dispatch = useDispatch()
    const perPage = usePerPage('actionLog')

    const {
        list: {page, ...list},
        store: {byIds},
    } = useSelector(({actionLog}) => actionLog)

    return {
        ...bindActionCreators(
            {
                init,
                setStart,
                startPoll,
                stopPoll,
                reset,
            },
            dispatch
        ),
        ...list,
        ...perPage,
        rows: page.map((id) => byIds[id]),
    }
}
