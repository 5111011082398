import React from 'react'

import {compose} from 'redux'

import selection from 'permissions/reports/selection'

import {withVisibility} from 'containers/withVisibility'
import SelectionDropDownBase from 'components/SelectionDropDown'
import withSelection from 'containers/withSelection'
import usePermission from 'hooks/usePermission'
import useReports from 'hooks/pages/useReports'

const ConnectedSelectionDropDown = compose(
    withVisibility(),
    withSelection()
)(SelectionDropDownBase)

export default function ReportsSelectionDropDown() {
    const reportProps = useReports()
    const {isPermitted} = usePermission(selection)

    return <ConnectedSelectionDropDown {...reportProps} isVisible={isPermitted} />
}
