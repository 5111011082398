import {createSelector} from 'reselect'
import remove from 'lodash-es/remove'
import find from 'lodash-es/find'

import {categorySections} from 'constants/had'
import {categorySections as renderPgmOrder} from 'constants/had'
import getPrevNext from 'utils/getPrevNext'

const prepareHadCategorizedList = (hadList: Array) => {
    hadList = [...hadList]
    return categorySections.reduce((acc, category) => {
        const filtered = remove(
            hadList,
            (had) => had.homeAutomationDeviceClass === category
        )

        if (filtered.length > 0) {
            acc.set(category, filtered)
        }

        return acc
    }, new Map())
}

const selectHadList = createSelector(
    (state) => state.panels.had.hadList,
    (hadList) => hadList
)

export const selectHadIsSwitchable = createSelector(
    (state) => state.panels.had,
    ({isSwitchable, timeSlot, isTimeSlotEnabled}) => ({
        isSwitchable,
        timeSlot,
        isTimeSlotEnabled,
    })
)

export const selectHadTimeSlot = createSelector(
    (state) => state.panels.had,
    ({timeSlot}) => ({timeSlot})
)

export const selectCategorizedList = createSelector(selectHadList, (hadList) =>
    prepareHadCategorizedList(hadList || [])
)

export const selectPanelHadList = createSelector(
    (state) => state.panels.had.isLoading,
    selectCategorizedList,
    (isLoading, categorizedList) => ({
        isLoading,
        categorizedList,
    })
)

export const selectHadByIdAndDeviceType = createSelector(
    selectHadList,
    (state, had) => had,
    (hadList, idWithDeviceType) => find(hadList, {idWithDeviceType})
)

export const selectHadBlindModal = createSelector(
    selectCategorizedList,
    selectHadByIdAndDeviceType,
    (state, hadId) => hadId,
    (categorizedList, had, hadId) => ({
        hadBlindModalPrevNext: getPrevNext(
            hadId,
            categorizedList,
            renderPgmOrder,
            'idWithDeviceType'
        ),
        had,
    })
)
