import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {edit} from 'permissions/group/actions'

import {showEditGroup} from 'modules/modals/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as EditIcon} from 'ipmp-react-ui/icons/edit.svg'
import Button from 'ipmp-react-ui/Button'

export default compose(
    withPermission({isVisible: edit}),
    withVisibility(),
    connect(null, (dispatch, {group: {id: groupId}, activeTab}) =>
        bindActionCreators(
            {
                onClick: () => showEditGroup(groupId, activeTab),
            },
            dispatch
        )
    ),
    withProps(() => ({
        Icon: EditIcon,
        borderless: true,
    }))
)(Button)
