import usePerPage from '../usePerPage'
import {selectRemoteInspectionsListRows} from 'modules/remoteInspections/list/selectors'
import {bindActionCreators} from 'redux'
import {
    fetch,
    init,
    markAsViewed,
    reset,
    setStart,
} from 'modules/remoteInspections/list/actions'
import {
    showRemoteInspectionValuesModal,
    showScheduleRri,
    showScheduleRriByCsvModal,
} from 'modules/modals/actions'
import {
    cancel,
    initiateNow,
    initiateNowBatch,
} from 'modules/remoteInspections/store/actions'
import {sendEmail} from 'modules/remoteInspections/results/actions'
import {useDispatch, useSelector} from 'react-redux'

export default function useRemoteInspections() {
    const dispatch = useDispatch()
    const perPage = usePerPage('remoteInspections')

    const {isLoading, error, start, total} = useSelector(
        ({remoteInspections}) => remoteInspections.list
    )

    const rows = useSelector(selectRemoteInspectionsListRows)
    const disabledRowsIds = rows.filter(({isSupported}) => !isSupported).map(({id}) => id)

    return {
        ...perPage,
        ...bindActionCreators(
            {
                init,
                reset,
                fetch,
                onPageChange: setStart,
                markAsViewed,
                showScheduleRri,
                showScheduleRriByCsvModal,
                showRemoteInspectionValuesModal,
                initiateNow,
                cancel,
                sendEmail,
                // Workaround for use runners
                initiateNowBatch,
            },
            dispatch
        ),
        start,
        total,
        isLoading,
        error,
        rows,
        disabledRowsIds,
    }
}
