export class DimmerModeCommand {
    constructor({command, mode}) {
        this.command = command
        this.mode = mode
    }
}

export class DimmerLevelCommand {
    constructor({command, level}) {
        this.command = command
        this.level = level
    }
}
