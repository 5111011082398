import React from 'react'
import PropTypes from 'prop-types'

import Card, {CardHeader, CardContent} from 'ipmp-react-ui/Card'
import List from 'ipmp-react-ui/List'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

function SettingsCard({title, onEditAction, children, disclaimer}) {
    return (
        <Card className="settings-card">
            <CardHeader>
                {title}
                {onEditAction && (
                    <Button onClick={onEditAction} borderless Icon={IconEdit} />
                )}
            </CardHeader>
            <CardContent>
                <List twoCols>{children}</List>
                {disclaimer && (
                    <Disclaimer
                        className="settings-card-disclaimer"
                        children={disclaimer}
                    />
                )}
            </CardContent>
        </Card>
    )
}

SettingsCard.propTypes = {
    title: PropTypes.string.isRequired,
    onEditAction: PropTypes.func,
    disclaimer: PropTypes.string,
}

export default SettingsCard
