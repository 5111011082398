import usePerPage from '../usePerPage'
import {bindActionCreators} from 'redux'
import {selectReportsListRows} from 'modules/reports/list/selectors'
import {
    init,
    remove,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/reports/list/actions'
import {review, stop} from 'modules/reports/store/actions'
import {showCreateReportModal} from 'modules/modals/actions'
import {useDispatch, useSelector} from 'react-redux'

export default function useReports() {
    const dispatch = useDispatch()
    const perPage = usePerPage('reports')

    const {isLoading, error, start, total} = useSelector(({reports}) => reports.list)

    return {
        ...perPage,
        ...bindActionCreators(
            {
                init,
                onPageChange: setStart,
                remove,
                reset,
                stop,
                review,
                showCreateReportModal,
                startPoll,
                stopPoll,
            },
            dispatch
        ),
        isLoading,
        error,
        start,
        total,
        rows: useSelector(selectReportsListRows),
    }
}
