import {createSelector} from 'reselect'
import {
    EVENT_RULE_STATE_TYPE_WILD_CARD,
    eventRuleWildCardTitle,
    getStateTypeName,
} from 'constants/eventRules'

export const selectStateTypeNameById = createSelector(
    (state) => state.stateTypes.store.byIds,
    (_, {id}) => id,
    (storeByIds, id) => ({name: getStateTypeName(storeByIds, id)})
)

export const selectAllStateTypes = createSelector(
    (state) => state.stateTypes.store.byIds,
    (stateTypesByIds) => ({
        data: [
            {
                id: EVENT_RULE_STATE_TYPE_WILD_CARD,
                name: eventRuleWildCardTitle(),
            },
            ...Object.values(stateTypesByIds),
        ],
    })
)

export const selectStateTypeSuggests = createSelector(
    (state) => state.stateTypes.store.byIds,
    (storeByIds) => {
        const data = new Set(Object.values(storeByIds).map((item) => item.name))
        return {
            count: data.size,
            values: [...data],
        }
    }
)

export const selectStateTypeFilter = createSelector(
    (state) => state.stateTypes.store.byIds,
    (_, query) => query,
    (storeByIds, query) =>
        Object.values(storeByIds)
            .filter((item) => item.name === query)
            .map((item) => item.id)
)
