import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {matchPath, withRouter} from 'react-router'

import * as permissions from 'permissions/navigation'

import NavLink from 'components/Nav/NavLink'

import __ from 'utils/i18n'
import path from 'utils/path'
import {withPermission} from 'containers/withPermission'
import {removeSearchScope} from 'modules/settings/actions'

import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/menu/settings.svg'

import NavItemsResizeble from './NavItemsResizeble'

class NavMain extends PureComponent {
    static propTypes = {
        onSearchScopeRemove: PropTypes.func.isRequired,
        scopes: PropTypes.objectOf(
            PropTypes.objectOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                })
            )
        ),
        navRefHandler: PropTypes.func.isRequired,
    }

    handleSearchScopeRemove = (page, scope) => {
        const {onSearchScopeRemove, history, location} = this.props

        const match = matchPath(location.pathname, {
            path: path(page, {scope}),
        })

        if (match) {
            history.push(path(page, {}))
        }

        onSearchScopeRemove(page, scope)
    }

    render() {
        const {permissions, navRefHandler} = this.props

        return (
            <nav className="nav" ref={navRefHandler}>
                <div className="menu-wrapper">
                    <div
                        className="main-links"
                        ref={(divElement) => {
                            this.menuElement = divElement
                        }}
                    >
                        <NavItemsResizeble
                            {...this.props}
                            handleSearchScopeRemove={this.handleSearchScopeRemove}
                        />
                    </div>
                    <div className="bottom-links">
                        <NavLink
                            Icon={IconSettings}
                            to={path('system.settings', {})}
                            name={__('Settings')}
                            isPermitted={permissions.settings}
                        />
                    </div>
                </div>
            </nav>
        )
    }
}

export default compose(
    withRouter,
    connect(
        ({settings, serverSettings, panels}) => ({
            recentPanel: panels.recent || undefined,
            scopes: settings.scopes,
            isVirtualPanelEnabled: serverSettings.isVirtualPanelEnabled,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    onSearchScopeRemove: removeSearchScope,
                },
                dispatch
            )
    ),
    withPermission({permissions})
)(NavMain)
