import React from 'react'
import {useSelector} from 'react-redux'

import StateButton from 'components/Button/State'

import {selectStateCanGet, selectStateCanSet} from 'modules/features/store/selectors'
import useRouterPanelId from 'hooks/useRouterPanelId'

export default function PartitionStateButton(props) {
    const panelId = useRouterPanelId()
    const selectorProps = {...props, panelId}
    const canGet = useSelector((state) => selectStateCanGet(state, selectorProps))
    const canSet = useSelector((state) => selectStateCanSet(state, selectorProps))

    const buttonProps = {...props, ...canGet, ...canSet}
    const {isCanGetAvailable, isCanSetAvailable, active, disabled} = buttonProps

    return (
        <StateButton
            {...buttonProps}
            panelId={panelId}
            active={isCanGetAvailable && active}
            disabled={disabled || !isCanSetAvailable}
        />
    )
}
