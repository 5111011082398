import React from 'react'
import PropTypes from 'prop-types'

import Error from 'ipmp-react-ui/Error'

import {__} from 'utils/i18n'

export default function EmptyPage({Icon, message, Button}) {
    return (
        <div className="emptyPage">
            {Icon}
            <Error title={message || __('No entries')} />
            {Button}
        </div>
    )
}

EmptyPage.propTypes = {
    Icon: PropTypes.node,
    message: PropTypes.string,
    Button: PropTypes.node,
}
