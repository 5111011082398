import React, {useEffect} from 'react'
import {compose} from 'redux'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'

import withForm from 'containers/withForm'
import {useActions} from 'modules/higherOrder/useActions'
import {clear} from 'modules/forms/actions'
import {panelTypes} from 'modals/Panel/DlsConnection/SmartCommunicator/SelectPanelTypes'
import ConnectSmartCommunicatorFormFields from 'modals/Panel/DlsConnection/ConnectSmartCommunicatorFormFields'
import {speeds} from 'modals/Panel/DlsConnection/SmartCommunicator/SelectConnectionSpeed'
import {dlsConnectSmartCommunicator} from 'modules/forms/handlers'

const ConnectSmartCommunicator = ({panelId, hide, errors}) => {
    const clearForm = useActions(clear)
    useEffect(() => {
        return () => clearForm()
    }, [])

    const submit = useActions(dlsConnectSmartCommunicator)

    const handleOnSubmit = ({
        host,
        port,
        password,
        isEncrypted,
        timeout,
        panelType,
        connectionSpeed,
    }) => {
        submit({
            panelId,
            host,
            port,
            password,
            isEncrypted,
            timeout,
            panelType,
            connectionSpeed,
        })
    }

    const header = __('Smart communicator connect DLS')
    const rules = {
        host: {
            presence: true,
            host: true,
        },
        port: {
            presence: true,
            numericality: {
                greaterThanOrEqualTo: 80,
                lessThanOrEqualTo: 65535,
            },
        },
        password: {
            presence: true,
            format: {
                pattern: `^[0-9]{6}$`,
                flags: 'i',
                message: () => __('Must be 6 digits'),
            },
        },
        timeout: {
            presence: true,
            numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 3600,
            },
        },
        panelType: {
            presence: true,
            inclusion: panelTypes,
        },
        connectionSpeed: {
            presence: true,
            numericality: false,
            inclusion: speeds,
        },
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={handleOnSubmit}
            header={header}
            rules={rules}
            hide={hide}
            submitLabel={__('Connect')}
            className="dls-smart-communicator-main-form"
        >
            <ConnectSmartCommunicatorFormFields errors={errors} />
        </ModalCardForm>
    )
}

export default compose(withForm(dlsConnectSmartCommunicator))(ConnectSmartCommunicator)
