import React, {useRef, useState} from 'react'
import {compose} from 'redux'
import classes from 'classnames'

import Layout from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'
import {ReactComponent as IconSystemLogs} from 'ipmp-react-ui/icons/system-log.svg'

import withLoader from 'containers/withLoader'
import withProcessLoader from 'containers/withProcessLoader'
import {MARGIN} from 'constants/themes'
import {__} from 'utils/i18n'

import LogBar from './LogBar'
import LogsTable from './LogsTable'
import EmptyPage from 'ui/EmptyPage'
import NewSystemLogButton from 'components/SystemLogs/NewSystemLogButton'

export const Log = ({panel, rows, hasMore, more, ...props}) => {
    const element = useRef()
    const [isScrolledBottom, setIsScrolledBottom] = useState(false)

    const handleScroll = () => {
        let scrollTop
        let offsetHeight
        let scrollHeight

        if (element) {
            scrollTop = element.current.scrollTop
            offsetHeight = element.current.offsetHeight
            scrollHeight = element.current.scrollHeight
        }
        setIsScrolledBottom(scrollTop + offsetHeight >= scrollHeight - MARGIN)
    }

    if (!rows?.length) {
        return (
            <EmptyPage
                Icon={<IconSystemLogs />}
                message={__('No system logs')}
                Button={<NewSystemLogButton />}
            />
        )
    }

    return (
        <Layout vertical className="smartCommunicator-logs">
            <LogBar {...props} />

            <div
                className={classes('configuration-content', {
                    'bottom-list-scrolled': isScrolledBottom,
                })}
                ref={element}
                onScroll={handleScroll}
            >
                <LogsTable rows={rows} panelId={panel.id} />

                {hasMore && (
                    <div className="btnHolder">
                        {hasMore.isLoading ? (
                            <Spinner />
                        ) : (
                            <Button onClick={more}>{__('More')}</Button>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default compose(
    withLoader(({fetch}) => fetch()),
    withProcessLoader(() => __('Downloading log'))
)(Log)
