import React from 'react'
import Layout from 'ipmp-react-ui/Layout'
import {
    Chart,
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Tooltip,
    Title,
    Legend,
    Filler,
} from 'chart.js'
import 'chartjs-adapter-moment'

// According to new `tree-shakable` behaviour we need register elements, that we use
Chart.register([
    CategoryScale,
    LinearScale,
    TimeScale,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    Title,
    Legend,
    Filler,
])

export default function Charts({children}) {
    return <Layout className="dashboard">{children}</Layout>
}
