import {useSelector} from 'react-redux'
import {selectZoneTypesListRows} from 'modules/zoneTypes/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {init, reset, startPoll, stopPoll} from 'modules/zoneTypes/list/actions'

export default function useZoneTypes() {
    return {
        ...useSelector(selectZoneTypesListRows),
        ...useActions({
            init,
            reset,
            startPoll,
            stopPoll,
        }),
    }
}
