import React from 'react'
import {QUAZAR} from 'constants/groups'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'
import {__} from 'utils/i18n'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import _get from 'lodash-es/get'
import Input from 'ipmp-react-ui/Input'
import {TabContentWrapper} from './TabContentWrapper'
import classes from 'classnames'

const Quazar = ({
    activeTab,
    handleFieldChanged,
    handleQuazarSupervison,
    quazarCommunications,
    errors,
}) => {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={QUAZAR}
            styleName={'communications-content quazar'}
        >
            <FormSection
                className={classes('col', {
                    'group-form-section-with-error':
                        errors?.quazarGprsTimeout || errors?.quazarGprsSupervision,
                })}
                title={__('General Parameter')}
            >
                <FormRow>
                    <Input
                        name="quazarGprsSupervision"
                        label={__('Supervision Period')}
                        dataTestId="quazar-gprs-supervision"
                        type="number"
                        defaultValue={_get(
                            quazarCommunications,
                            'gprs.supervision',
                            false
                        )}
                        disabled={
                            !_get(quazarCommunications, 'gprs.isEnabled', false) &
                            !_get(quazarCommunications, 'bba.isEnabled', false)
                        }
                        onChange={(e) => {
                            handleQuazarSupervison(Number(e.target.value))
                        }}
                    />
                </FormRow>
            </FormSection>
            <FormSection
                className={classes('col', {
                    'group-form-section-with-error':
                        errors?.quazarGprsTimeout || errors?.quazarGprsSupervision,
                })}
                title={__('Cellular')}
            >
                <Togglebox
                    name="quazarGprsIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="quazar-gprs-is-enabled"
                    defaultChecked={_get(quazarCommunications, 'gprs.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.quazar.gprs.isEnabled',
                            e.target.checked
                        )
                    }}
                />
                <FormRow>
                    <Input
                        name="quazarGprsTimeout"
                        label={__('Offline Timer')}
                        dataTestId="quazar-gprs-timeout"
                        type="number"
                        defaultValue={_get(quazarCommunications, 'gprs.timeout', false)}
                        disabled={!_get(quazarCommunications, 'gprs.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.quazar.gprs.timeout',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
            <FormSection className="col" title={__('Broadband')}>
                <Togglebox
                    name="quazarBbaIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="quazar-bba-is-enabled"
                    defaultChecked={_get(quazarCommunications, 'bba.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.quazar.bba.isEnabled',
                            e.target.checked
                        )
                    }}
                />
                <FormRow>
                    <Input
                        name="quazarBbaTimeout"
                        label={__('Offline Timer')}
                        dataTestId="quazar-bba-timeout"
                        type="number"
                        defaultValue={_get(quazarCommunications, 'bba.timeout', false)}
                        disabled={!_get(quazarCommunications, 'bba.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.quazar.bba.timeout',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
        </TabContentWrapper>
    )
}

export default Quazar
