import React from 'react'
import {compose} from 'redux'

import {remove} from 'permissions/centralStations/selection'

import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/pages/useCentralStations'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export default function RemoveCentralStations() {
    const centralStations = useCentralStations()
    const {isPermitted} = usePermission(remove)

    return (
        <ConnectedButton
            {...centralStations}
            isVisible={isPermitted}
            onClick={centralStations.remove}
            Icon={IconRemove}
            label={__('Remove')}
            title={__('Removing central stations')}
            message={__('Do you really want to remove selected central stations?')}
        />
    )
}
