import {combineReducers} from 'redux'

import accessiblePanels from './accessiblePanels/reducer'
import list from './list/reducer'
import store from './store/reducer'

export default combineReducers({
    accessiblePanels,
    list,
    store,
})
