import React from 'react'
import Void from 'components/Void'
import {compose} from 'redux'

import {discovery as permission} from 'permissions/panels/selection'

import {withVisibility} from 'containers/withVisibility'

import {withFeatureRejection} from 'containers/withFeature'
import {selectForceDiscovery} from 'modules/features/store/selectors'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'

import {__} from 'utils/i18n'
import {showTriggerDiscoveryModal} from 'modules/modals/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import usePermission from 'hooks/usePermission'

const MenuItemVisibility = compose(
    withVisibility(),
    withFeatureRejection(selectForceDiscovery, Void)
)(MenuItem)

export default function TriggerDiscoveryItem() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(permission)
    const {onClick} = useActions({
        onClick: () => showTriggerDiscoveryModal([panelId]),
    })

    return (
        <MenuItemVisibility
            panelId={panelId}
            isVisible={isPermitted}
            onClick={onClick}
            Icon={IconDiscovery}
            title={__('Trigger panel discovery')}
            children={__('Trigger Discovery')}
        />
    )
}
