import Filter from 'components/Search/Filters/Filter'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import startsWith from 'lodash-es/startsWith'
import keyBy from 'lodash-es/keyBy'

import Menu from 'ipmp-react-ui/Menu'
import {MenuItem} from 'ipmp-react-ui/Menu'
import Spinner from 'ipmp-react-ui/Spinner'
import {__} from 'utils/i18n'
import {SEARCH_SUGGESTS_COUNT} from 'constants/search'

export default class FilterItemsMenu extends Component {
    static propTypes = {
        filter: PropTypes.instanceOf(Filter),
        suggest: PropTypes.shape({
            prefix: PropTypes.string,
            count: PropTypes.number,
            values: PropTypes.array,
            isLoading: PropTypes.bool,
        }),
        prefix: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        onSuggest: PropTypes.func.isRequired,
    }

    componentDidMount() {
        const {prefix, filter, onSuggest} = this.props
        filter.hasSuggests && onSuggest(filter.name, prefix)
    }

    componentDidUpdate(prevProps, prevState) {
        const {prefix, filter, suggest, onSuggest} = this.props

        if (prefix !== prevProps.prefix) {
            this.forceUpdate(() => this.menu && this.menu.select(0))

            if (!filter.hasSuggests || suggest.isFull) {
                return
            }

            if (startsWith(prefix, suggest.prefix) && !suggest.hasMore) {
                return
            }

            onSuggest(filter.name, prefix)
        }
    }

    handleRef = (menu) => (this.menu = menu)

    isTypeMore = () => {
        const {suggest, filter} = this.props

        return (suggest && suggest.hasMore) || filter.isTypeMore(suggest)
    }

    render() {
        const {filter, suggest, prefix, onSelect} = this.props

        if (suggest && suggest.isLoading) {
            return (
                <Menu>
                    <Spinner />
                </Menu>
            )
        }

        const selected = keyBy(this.props.selected, '$')

        const items = filter
            .getItems(suggest && suggest.values)
            .filter((item) =>
                startsWith(
                    (item.label + '').toLowerCase().trim(),
                    prefix.toLowerCase().trim()
                )
            )
            .slice(0, SEARCH_SUGGESTS_COUNT)
            .filter(({$}) => !selected[$])
            .map((item) => (
                <MenuItem onClick={() => onSelect(item)} key={item.$}>
                    {item.label || <span className="empty">{__('Empty')}</span>}
                </MenuItem>
            ))

        const prefixItem = prefix && filter.createItemByPrefix(prefix)

        if (prefixItem) {
            items.push(
                <MenuItem onClick={() => onSelect(prefixItem)} key={prefixItem.$}>
                    {prefixItem.label}
                </MenuItem>
            )
        }

        if (items.length === 0) {
            items.push(
                <MenuItem disabled key="">
                    {__('Not found')}
                </MenuItem>
            )
        } else if (this.isTypeMore()) {
            items.push(
                <MenuItem key="" disabled>
                    {__('... type for more elements')}
                </MenuItem>
            )
        }

        return (
            <Menu scope="input" ref={this.handleRef}>
                {items}
            </Menu>
        )
    }
}
