import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {showAssignUserToPanelModal} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import usePermission from 'hooks/usePermission'
import {assign} from 'permissions/interactiveUsers/actions'
import __ from 'utils/i18n'

export default function InteractiveUserAssignButton({panelUserId}) {
    const {showAssignUserToPanelModal: showAssignUserToPanelModalAction} = useActions({
        showAssignUserToPanelModal,
    })
    const panelId = useRouterPanelId()
    const panelsByIds = useSelector((state) => state.panelInteractiveUsers.store)
    const {isAllowedAssignInteractiveUsers} = usePermission({
        isAllowedAssignInteractiveUsers: assign,
    })

    return (
        isAllowedAssignInteractiveUsers && (
            <Button
                Icon={IconPlus}
                flat
                primary
                label={__('Assign Customer')}
                onClick={() =>
                    showAssignUserToPanelModalAction({
                        panelId,
                        hasPanelUser: Object.values(panelsByIds.byIds[panelId]).some(
                            ({isAssign, isPinSet}) => !isAssign && isPinSet
                        ),
                        panelUserId,
                    })
                }
            />
        )
    )
}

InteractiveUserAssignButton.propTypes = {
    panelUserId: PropTypes.number,
}
