import React from 'react'

import {showFirmwareUpgradeModal} from 'modules/modals/actions'
import {upgrade as upgradePermission} from 'permissions/panel/firmware/actions'

import Button from 'ipmp-react-ui/Button'

import {withVisibility} from 'containers/withVisibility'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'

const ButtonVisibility = withVisibility()(Button)

export default function UpgradeButton({packages}) {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(upgradePermission)
    const dispatch = useDispatch()
    const {onClick} = bindActionCreators(
        {
            onClick: () => showFirmwareUpgradeModal({panelId, packages}),
        },
        dispatch
    )

    return (
        <ButtonVisibility
            onClick={onClick}
            panelId={panelId}
            isVisible={isPermitted}
            children={__('Upgrade')}
            disabled={!packages.length}
        />
    )
}
