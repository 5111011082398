import {PANEL_TYPE_QOLSYS} from 'constants/panelType'
import get from 'lodash-es/get'

export const isEmptyPanelInfo = (panel, fotaAotaVersions) => {
    const firmware = panel.firmware || {}
    const isPanelSimNumber = Boolean(panel.simNumber)

    const getFirmwareValue = (key, panel) => {
        return get(panel, ['firmware', key])
    }

    const isFirmWare = Object.keys(firmware).some((key) =>
        Boolean(getFirmwareValue(key, panel))
    )
    const isQolsys = (panel) => panel.model === PANEL_TYPE_QOLSYS

    if (!isQolsys(panel)) {
        const isFotaAotaVersions = Object.keys(fotaAotaVersions).some((key) =>
            Boolean(fotaAotaVersions[key])
        )

        return !isPanelSimNumber && !isFirmWare && !isFotaAotaVersions
    }

    return !isPanelSimNumber && !isFirmWare
}
