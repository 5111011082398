import {editGroup} from 'modules/forms/handlers'
import withForm from 'containers/withForm'
import {compose} from 'redux'
import {connect} from 'react-redux'

import GroupForm from './GroupForm'
import {__} from 'utils/i18n'

export default compose(
    withForm(editGroup),
    connect(
        ({groups}, {groupId}) => ({
            data: groups.store.byIds[groupId],
            header: __('Edit Group'),
        }),
        (dispatcher, {groupId, handle}) => ({
            handle: (data) => handle(groupId, data),
        })
    )
)(GroupForm)
