import {compose} from 'redux'

import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {__} from 'utils/i18n'

export default compose(
    withProps(({isUsedByMfa}) => ({
        small: true,
        flat: true,
        Icon: IconMinus,
        title: __('Remove broker'),
        message: __('Do you want to remove broker?'),
        isConfirmationEnabled: !isUsedByMfa,
    })),
    withConfirmation()
)(Button)
