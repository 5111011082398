import React from 'react'
import {schedule as schedulePermission} from 'permissions/panel/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as CalendarIcon} from 'ipmp-react-ui/icons/calendar.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import usePanelRemoteInspection from 'hooks/pages/usePanelRemoteInspection'

const ButtonVisible = withVisibility()(Button)

export default function ScheduleRemoteInspectionButton() {
    const {isPermitted} = usePermission(schedulePermission)
    const {schedule} = usePanelRemoteInspection()

    return (
        <ButtonVisible
            isVisible={isPermitted}
            onClick={schedule}
            Icon={CalendarIcon}
            label={__('Schedule Inspection')}
        />
    )
}
