import React from 'react'

import {ReactComponent as IconMarkViewed} from 'ipmp-react-ui/icons/viewed.svg'
import {ActionCell as ActionCellBase} from 'ui/TableCells'
import useReports from 'hooks/pages/useReports'

export default function ActionCell({row: {id, status, isReviewed}}) {
    const reportProps = useReports()

    return (
        <ActionCellBase
            {...reportProps}
            isExported={status && isReviewed}
            disabled={!status}
            onClick={() => reportProps.review(id)}
            Icon={IconMarkViewed}
            isChecked={isReviewed}
        />
    )
}
