import React from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import DateTime from 'ipmp-react-ui/DateTime'

import {BlindModalDefinition, BlindModalDefinitionItem} from 'ui/BlindModal'
import getInteractiveUserStatusTitle from 'constants/interactiveUserStatus'
import __ from 'utils/i18n'

export default function Status({user: {createdAt, status}}) {
    return (
        <BlindModalDefinition>
            <BlindModalDefinitionItem name={__('Created')}>
                <DateTime datetime={createdAt} />
            </BlindModalDefinitionItem>
            <BlindModalDefinitionItem name={__('Status')}>
                <Definition title={getInteractiveUserStatusTitle(status)} />
            </BlindModalDefinitionItem>
        </BlindModalDefinition>
    )
}

Status.propTypes = {
    user: PropTypes.shape({
        status: PropTypes.string,
        createdAt: PropTypes.object,
    }),
}
