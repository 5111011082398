import {post} from './http'
import {mapUser} from './users'
import {get} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'

export const mapFormData = (formData) => {
    const mappedData = {
        usr_name: formData.name,
        usr_email: formData.email,
        usr_phone: formData.phone,
        roe_id: parseInt(formData.roleId),
        coy_id: parseInt(formData.countryId),
    }
    if (formData.password !== null) {
        mappedData.usr_password = formData.password
    }
    return mappedData
}

export const mapCountry = (country) => ({
    id: country.coy_id,
    name: country.coy_name,
})

export const mapAssignableRole = (role) => ({
    id: role.roe_id,
    name: role.roe_name,
})

export const keysMap = {
    name: 'usr_name',
    email: 'usr_email',
    phone: 'usr_phone',
    roleId: 'roe_id',
    countryId: 'coy_id',
    password: 'usr_password',
}

export function edit(id, data) {
    return post(`/user/${id}/edit`, mapFormData(data))
        .then(mapUser)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function add(data) {
    return post('/user/add', mapFormData(data)).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}

export function fetchUserCountries() {
    return get('/user/countries').then((data) =>
        data.rows.map((country) => mapCountry(country))
    )
}

export function fetchUserAssignableRoles() {
    return get('/user/assignableroles').then((data) =>
        data.rows.map((role) => mapAssignableRole(role))
    )
}
