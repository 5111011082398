import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {ReactComponent as EnableIcon} from 'ipmp-react-ui/icons/run.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {enable} from 'permissions/users/selection'
import {setEnabled} from 'modules/users/store/actions'
import {useActions} from 'modules/higherOrder/useActions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import {withVisibility} from 'containers/withVisibility'

const ConnectedButton = compose(withVisibility())(WithConfirmationButton)

export default function EnableUserButton({id, name}) {
    const {isPermitted} = usePermission(enable)
    const setEnable = useActions(setEnabled)
    const onClick = () => setEnable([id], true)

    return (
        <ConnectedButton
            flat
            isVisible={isPermitted}
            onClick={onClick}
            Icon={EnableIcon}
            title={__('Enable user')}
            message={__('Do you really want to enable the user: %s?', name)}
            label={__('Enable')}
            className="user-status-button-enable"
        />
    )
}

EnableUserButton.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
}
