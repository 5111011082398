import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'

import timezones, {getTz} from 'utils/timezones'
import {selectServerTime} from 'modules/system/serverTime/selectors'
import {fetch} from 'modules/system/serverTime/actions'
import withPoller from 'containers/withPoller'
import __ from 'utils/i18n'

class TimeInfo extends PureComponent {
    static propTypes = {
        timestamp: PropTypes.string,
    }

    render() {
        const {timestamp} = this.props

        return (
            <>
                <div className="about-info">
                    <p className="hint hint--gray">{__('Server time')}</p>
                    {timezones.server(timestamp).format('LL – LT')}
                </div>

                <div className="about-info timezone">
                    <p className="hint hint--gray">{__('Server timezone')}</p>
                    {getTz()} {timezones.server().format('Z z')}
                </div>
            </>
        )
    }
}
export default compose(
    connect(selectServerTime, (dispatch) =>
        bindActionCreators(
            {
                fetch,
            },
            dispatch
        )
    ),
    withPoller(1000, ({fetch}) => fetch())
)(TimeInfo)
