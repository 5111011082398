import React from 'react'

import Select, {Option} from 'ipmp-react-ui/Select'

import __ from 'utils/i18n'
import {
    dlsApplicationProtocols,
    getDlsApplicationProtocolLabel,
} from 'constants/protocols'

export const SelectDlsApplicationProtocols = () => {
    const options = dlsApplicationProtocols.map((protocol) => (
        <Option
            value={protocol}
            label={getDlsApplicationProtocolLabel(protocol)}
            key={protocol}
        />
    ))
    return (
        <Select name="applicationProtocol" label={__('Application protocol')}>
            {options}
        </Select>
    )
}
