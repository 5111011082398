import {compose} from 'redux'
import {connect} from 'react-redux'

import withPanelInfo from 'containers/withPanelInfo'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

export const EditCustomerInfoButton = compose(
    withPanelInfo(),
    withProps(({editCustomerInfo}) => ({
        onClick: editCustomerInfo,
        primary: true,
        label: __('Add Owner Info'),
        Icon: IconPlus,
    }))
)(Button)

export default compose(
    connect((_, {googleApiError}) => ({googleApiError})),
    withPanelInfo(),
    withProps(({editCustomerInfo}) => ({
        onClick: editCustomerInfo,
        borderless: true,
        Icon: IconEdit,
    }))
)(Button)
