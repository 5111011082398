import React from 'react'
import {__} from 'utils/i18n'
import {useSelector} from 'react-redux'

import ModalCardForm from 'ui/ModalCardForm'
import withForm from 'containers/withForm'
import * as PropTypes from 'prop-types'
import Input from 'ipmp-react-ui/Input'

import {setUserCode} from 'modules/forms/handlers'

const getRules = (panelId) => {
    const allowedCodeLength = useSelector(
        ({features}) => features.store.byIds[panelId].user.codeLengths
    )
    return {
        code: {
            presence: true,
            format: {
                pattern: `[0-9]{${allowedCodeLength}}`,
                message: () => __('Must be %d digit', allowedCodeLength),
            },
        },
    }
}

const SetUserCode = ({handle, panelId, panelUserId, ...props}) => {
    const onHandle = ({code}) => {
        const id = parseInt(panelUserId)
        const payload = {id, code, panelId}
        handle(payload)
    }

    return (
        <ModalCardForm
            onSubmit={onHandle}
            confirmOnDismiss
            rules={getRules(panelId)}
            header={__('Change code')}
            {...props}
        >
            <Input label={__('Code')} name="code" />
        </ModalCardForm>
    )
}

SetUserCode.propTypes = {
    handle: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}

export default withForm(setUserCode)(SetUserCode)
