import React from 'react'
import withConfirmation from 'containers/withConfirmation'
import {compose} from 'redux'
import has from 'lodash-es/has'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {
    useConfigurationActions,
    useCurrentConfiguration,
} from 'hooks/pages/useConfiguration'

const ConnectedButton = compose(withVisibility(), withConfirmation())(Button)

export function RestoreConfigurationButton({panelId, backup}) {
    const {isPermitted} = usePermission(makeBasic)
    const {restoreBackup} = useConfigurationActions(panelId)
    const {values, changes} = useCurrentConfiguration({panelId})

    return (
        <ConnectedButton
            onClick={() => restoreBackup(backup)}
            label={__('Restore')}
            title={__('Not submitted changes')}
            message={__('You will lose all configuration changes')}
            isConfirmationEnabled={!!changes && Object.keys(changes).length > 0}
            isVisible={
                isPermitted &&
                values &&
                Object.keys(values).some((key) =>
                    has(changes, key)
                        ? changes[key] !== backup[key]
                        : values[key] !== backup[key]
                )
            }
        />
    )
}
