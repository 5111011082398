import React from 'react'

import Definition from 'ipmp-react-ui/Definition'
import {ReactComponent as IconEthernet} from 'ipmp-react-ui/icons/ethernet.svg'

import __ from 'utils/i18n'

export default function EventNoCs() {
    return (
        <div className="event-no-cs-wrapper">
            <div className="event-no-cs">
                <Definition Icon={IconEthernet} />
                <h4 className="deliverance-heading">{__('No central stations list')}</h4>
            </div>
        </div>
    )
}
