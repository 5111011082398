import React from 'react'
import PropTypes from 'prop-types'

import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'
import Modal from 'ipmp-react-ui/Modal'

import __ from 'utils/i18n'

export default function Alert({hide, title, content, close}) {
    return (
        <Modal onClose={hide}>
            <Card>
                <CardClose onClick={hide} />

                <CardHeader>{title}</CardHeader>

                <CardContent>{content}</CardContent>

                <CardActions>
                    <Button primary onClick={hide}>
                        {close || __('Close')}
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

Alert.propTypes = {
    hide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    close: PropTypes.string,
}
