import {withVisibility} from 'containers/withVisibility'
import {selectDeviceByZoneAndType} from 'modules/devices/list/selectors'
import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import devicesList from 'permissions/panel/devices/list'

import {fetch as fetchDevices} from 'modules/devices/list/actions'
import {setBypass} from 'modules/devices/list/actions'
import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import {withPermission} from 'containers/withPermission'

import EventDevice from 'components/Devices/EventDevice'
import Panel from 'components/Devices/Panel'
import {get} from 'lodash-es'
import {POLL_EVENTS_PANEL} from 'configs/pollers'

const DeviceCard = compose(
    withPermission({isVisible: devicesList}),
    withVisibility(),
    connect(
        (state, {panelId, event: {zone, deviceType}}) => {
            const device = selectDeviceByZoneAndType(state, {panelId, deviceType, zone})
            return {
                isLoading: !device,
                device,
            }
        },
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    fetchDevices: () => fetchDevices(panelId),
                    setBypass: (deviceId, state) =>
                        setBypass(panelId, new Map([[deviceId, state]])),
                },
                dispatch
            )
    ),
    withLifeCycle({onMount: ({fetchDevices}) => fetchDevices()}),
    withPoller(POLL_EVENTS_PANEL, ({fetchDevices}) => fetchDevices())
)(EventDevice)

const PanelCard = compose(
    withPermission({isVisible: devicesList}),
    withVisibility(),
    connect(
        ({panels}, {panelId}) => ({
            panel: panels.store.byIds[panelId],
            isLoading: !panels.store.byIds[panelId],
        }),
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    fetchDevices: () => fetchDevices(panelId),
                },
                dispatch
            )
    ),
    withLoader(({fetchDevices}) => fetchDevices())
)(Panel)

class EventsDeviceInfo extends Component {
    render() {
        const {event, panelId} = this.props
        const {hasDevice, hasPanel} = event

        if (hasDevice) {
            return <DeviceCard {...{event, panelId}} />
        }

        if (hasPanel) {
            return <PanelCard panelId={panelId} />
        }

        return null
    }
}

export default compose(
    connect(({events}, {eventId}) => ({
        event: get(events, ['store', 'byIds', eventId], {}),
    })),
    withPermission()
)(EventsDeviceInfo)
