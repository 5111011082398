import React from 'react'

import list from 'permissions/roles/list'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import useRoles from 'hooks/pages/useRoles'
import usePermission from 'hooks/usePermission'

const PagerVisibility = withVisibility()(Pager)

export default function RolesPager() {
    const roles = useRoles()
    const {isPermitted} = usePermission(list)

    return (
        <PagerVisibility
            {...roles}
            isVisible={isPermitted}
            disabled={roles.isDataLoading}
        />
    )
}
