import {isNil} from 'lodash-es'
import {all, takeEvery, select, put} from 'redux-saga/effects'

import * as api from 'api/interactiveUsers'
import * as actions from './actions'

import {selectPerPage} from 'modules/settings/selectors'
import {fetch} from 'modules/interactiveUserPanels/list/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import {assignUserToPanel} from 'modules/forms/handlers'
import {
    fetchInteractiveUserPanels,
    fetchPanelsInteractiveUser,
} from 'modules/panels/recent/actions'

import {update} from '../store/actions'

const selector = (state) => ({
    ...state.interactiveUserPanels.accessiblePanels.list,
    perPage: selectPerPage(state, 'userAccessiblePanels'),
    listRoute: 'interactiveUsers',
})

export default function* () {
    yield all([
        listSaga({fetch: api.accessiblePanels}, actions, update, selector),
        takeEvery(assignUserToPanel.SUCCESS, watchAssignUserToPanel),
    ])
}

function* watchAssignUserToPanel() {
    const interactiveUserId = yield select(
        (state) => state.interactiveUserPanels.list.interactiveUserId
    )
    // On interactive users page
    if (isNil(interactiveUserId)) {
        yield put(fetchPanelsInteractiveUser())
        return
    }

    yield put(fetch(interactiveUserId))
    yield put(fetchInteractiveUserPanels(interactiveUserId))
    yield put(fetchPanelsInteractiveUser())
}
