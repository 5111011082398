import {useSelector} from 'react-redux'
import usePerPage from '../usePerPage'
import {init, reset, setStart, startPoll, stopPoll} from 'modules/roles/list/actions'
import {showAddRole, showRemoveRole} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'

export default function useRoles() {
    const perPage = usePerPage('roles')
    const roles = useSelector(({roles}) => roles)
    const {start, total, page, error, isLoading} = roles.list

    return {
        ...perPage,
        error,
        isLoading,
        start,
        total,
        rows: page.map((id) => roles.store.byIds[id]),
        ...useActions({
            init,
            reset,
            onPageChange: setStart,
            startPoll,
            stopPoll,
            showAddRole,
            showRemoveRole,
        }),
    }
}
