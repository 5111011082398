import {compose} from 'redux'

import {withLocationChange} from 'containers/withLocationChange'

import withLoader from 'containers/withLoader'
import {withRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import {PanelPageBase} from './PanelPageBase'

export const PanelPageLoader = compose(
    withRejection(),
    withLoader(
        ({
            fetch,
            fetchFeatures,
            fetchFirmware,
            fetchPanelInteractiveUsers,
            panelId,
            isPanelFeatures,
            isInteractiveUsers,
            isPanelUsersPagePermitted,
        }) => {
            fetch()
            !isPanelFeatures && fetchFeatures(panelId)
            if (isPanelUsersPagePermitted) {
                !isInteractiveUsers && fetchPanelInteractiveUsers()
            }
            fetchFirmware()
        }
    ),
    withLocationChange(({location, match}, {remember, masterUserToRemember}) => {
        remember({path: location.pathname, masterUserToRemember})
    }),
    withLifeCycle({
        onMount({startPollPanelData}) {
            startPollPanelData()
        },

        onUnmount({stopPollPanelData}) {
            stopPollPanelData()
        },

        onUpdate({error, panelId, dispatch, forgetPanel}, {error: prevError}) {
            if (error && error !== prevError) {
                forgetPanel()
            }
        },
    })
)(PanelPageBase)
