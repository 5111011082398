import React from 'react'

import {showMarkForServiceModal} from 'modules/modals/actions'

import {withVisibility} from 'containers/withVisibility'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {markForService} from 'permissions/panels/selection'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

const MenuItemVisible = withVisibility()(MenuItem)

export default function MarkForServiceItem() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(markForService)
    const {onClick} = useActions({
        onClick: () => showMarkForServiceModal([panelId]),
    })

    return (
        <MenuItemVisible
            onClick={onClick}
            isVisible={isPermitted}
            children={__('Mark For Service')}
        />
    )
}
