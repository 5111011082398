import {select, all, call, put, takeEvery, delay} from 'redux-saga/effects'
import get from 'lodash-es/get'

import {
    PROCESS_TYPE_DISCONNECT_DLS,
    PROCESS_TYPE_PMAXCANCELSOAKZONE,
    PROCESS_TYPE_PMAXCLEARBYPASSZONE,
    PROCESS_TYPE_PMAXSETBYPASSZONE,
    PROCESS_TYPE_PMAXSETSOAKZONE,
    PROCESS_TYPE_PMAXZONEREMOVE,
    PROCESS_TYPE_REFRESH_GSM,
} from 'constants/processTypes'
import {PROCESS_TYPE_PMAXZONERSSI} from 'constants/processTypes'

import {selectZonesByDeviceIds} from 'modules/devices/list/selectors'
import {snackShow} from 'modules/snacks'
import {addDevice, renameDevice} from 'modules/forms/handlers'
import {update as updatePanel} from 'modules/panels/store/actions'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import processErrorMessage from 'constants/processError'
import {
    generateBatchForPanel,
    generateBatchForOneProcess,
} from 'modules/batches/manager/generateBatch'
import generateProcess from 'modules/processes/manager/generateProcess'
import {selectBatchInProgress} from 'modules/batches/widget/selectors'

import * as actions from './actions'
import * as api from 'api/devices'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.refreshRssi, watchRefreshRssi),
        takeEvery(actions.refreshGSM, watchRefreshGSM),
        takeEvery(addDevice.SUCCESS, watchAddDevice),
        takeEvery(actions.removeDevice, watchRemoveDevice),
        takeEvery(actions.setSoak, watchSetSoakDevice),
        takeEveryProcessComplete(
            [PROCESS_TYPE_PMAXSETSOAKZONE, PROCESS_TYPE_PMAXCANCELSOAKZONE],
            watchProcessSoakCompleteSuccessful
        ),
        takeEvery(actions.setBypass, watchSetBypassDevice),
        takeEvery(actions.setRarelyUsed, watchSetRarelyUsed),
        takeEvery(actions.dlsDisconnect, watchDlsDisconnect),
        takeEvery(renameDevice.SUCCESS, watchRenameDevice),
    ])
}

export function* watchFetch({payload: {panelId}}) {
    try {
        const {panel, devices, rssiRefreshProcess, GSMRefreshProcess, rssi} = yield call(
            api.retrieveDevices,
            panelId
        )

        yield put(
            updatePanel({
                id: panelId,
                rssiRefreshProcess: yield ensureProcess(rssiRefreshProcess),
                GSMRefreshProcess: yield ensureProcess(GSMRefreshProcess),
                rssi: rssi,
                ...panel,
            })
        )

        yield put(actions.receive(devices, panelId))
    } catch (error) {
        yield put(actions.receive(error, panelId))
    }
}

function* watchRemoveDevice({payload: {panelId, deviceId}}) {
    const {batchId} = yield generateBatchForOneProcess(
        PROCESS_TYPE_PMAXZONEREMOVE,
        panelId
    )
    const {execute} = yield generateProcess(PROCESS_TYPE_PMAXZONEREMOVE, panelId)

    try {
        yield execute(api.removeDevice, deviceId, batchId)
    } catch (error) {
        yield put(
            actions.update(
                {
                    id: deviceId,
                    removing: false,
                },
                panelId
            )
        )

        if (error.status === 403) {
            yield put(snackShow(error.details))
        } else {
            yield put(snackShow(error.message))
        }
    }
}

function* watchRefreshRssi({payload: {panelId}}) {
    const {batchId} = yield generateBatchForOneProcess(PROCESS_TYPE_PMAXZONERSSI, panelId)
    const {execute, process} = yield generateProcess(PROCESS_TYPE_PMAXZONERSSI, panelId)

    yield put(
        updatePanel({
            id: panelId,
            rssiRefreshProcess: process,
        })
    )

    try {
        yield execute(api.refreshZoneRssi, panelId, batchId)
    } catch (error) {
        yield put(
            updatePanel({
                id: panelId,
                rssiRefreshProcess: null,
            })
        )
        yield put(snackShow(error.message))
    }
}

function* watchRefreshGSM({payload: {panelId}}) {
    const {batchId} = yield generateBatchForOneProcess(PROCESS_TYPE_REFRESH_GSM, panelId)
    const {execute, process} = yield generateProcess(PROCESS_TYPE_REFRESH_GSM, panelId)

    yield put(
        updatePanel({
            id: panelId,
            GSMRefreshProcess: process,
        })
    )

    try {
        yield execute(api.refreshGSM, panelId, batchId)
    } catch (error) {
        yield put(
            updatePanel({
                id: panelId,
                GSMRefreshProcess: null,
            })
        )
        yield put(snackShow(error.message))
    }
}

function* watchDlsDisconnect({payload: {panelId}}) {
    const {batchId} = yield generateBatchForOneProcess(
        PROCESS_TYPE_DISCONNECT_DLS,
        panelId
    )
    const {execute} = yield generateProcess(PROCESS_TYPE_DISCONNECT_DLS, panelId)

    try {
        yield execute(api.dlsDisconnect, panelId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchAddDevice({meta: {panelId}, payload: {result}}) {
    try {
        const {process, device} = result
        yield ensureProcess(process)
        yield put(actions.update(device, panelId))
    } catch (error) {
        yield put(snackShow(processErrorMessage(error.details)))
    }
}

function* watchSetRarelyUsed({payload: {panelId, deviceId, enabled}}) {
    try {
        yield call(api.setRarelyUsedZone, panelId, deviceId, enabled)
    } catch (error) {
        const device = yield select((state) =>
            get(state.devices.list, [panelId, 'byIds', deviceId])
        )

        yield put(
            actions.update(
                {
                    id: deviceId,
                    traits: {
                        ...device.traits,
                        rarely_used: {
                            enabled: !enabled,
                        },
                    },
                },
                panelId
            )
        )

        yield put(snackShow(error.message))
    }
}

function* watchSetSoakDevice({payload: {panelId, deviceId, enabled}}) {
    const processType = enabled
        ? PROCESS_TYPE_PMAXSETSOAKZONE
        : PROCESS_TYPE_PMAXCANCELSOAKZONE
    const {batchId} = yield generateBatchForOneProcess(processType, panelId)
    const {execute, process} = yield generateProcess(processType, panelId)

    try {
        yield execute(api.setSoakZone, deviceId, enabled, batchId)
        yield put(actions.confirmProgress(panelId, deviceId, 'soak', process))
    } catch (error) {
        yield put(actions.revertProgress(panelId, deviceId, 'soak'))
        yield put(snackShow(error.message))
    }
}

function* watchProcessSoakCompleteSuccessful({panelId, key}) {
    yield put(actions.finishProgress(panelId, key, 'soak'))
}

function* watchSetBypassDevice({payload: {panelId, states}}) {
    const enabledIds = Array.from(states.keys()).filter((deviceId) =>
        states.get(deviceId)
    )
    const disabledIds = Array.from(states.keys()).filter(
        (deviceId) => !states.get(deviceId)
    )

    const calls = []

    if (enabledIds.length) {
        calls.push(bypassDevices(panelId, enabledIds, true))
    }

    if (disabledIds.length) {
        calls.push(bypassDevices(panelId, disabledIds, false))
    }

    if (calls.length > 0) {
        yield all(calls)
    }
}

function* bypassDevices(panelId, deviceIds, enabled) {
    const {batchId} = yield generateBatchForPanel(
        enabled ? PROCESS_TYPE_PMAXSETBYPASSZONE : PROCESS_TYPE_PMAXCLEARBYPASSZONE,
        panelId,
        deviceIds.length
    )

    const zones = yield select((state) =>
        selectZonesByDeviceIds(state, {panelId, deviceIds})
    )

    try {
        yield call(api.setBypassZones, panelId, zones, enabled, batchId)

        yield put(actions.confirmProgress(panelId, deviceIds, 'bypass'))

        while (true) {
            const inProgress = yield select((store) =>
                selectBatchInProgress(store, batchId)
            )

            if (!inProgress) {
                const {devices} = yield call(api.retrieveDevices, panelId)

                for (let device of devices) {
                    const {id, traits} = device

                    if (deviceIds.includes(id)) {
                        yield put(actions.update({id, traits}, panelId))
                        yield put(actions.revertProgress(panelId, [id], 'bypass'))
                    }
                }

                break
            }

            yield delay(1000)
        }
    } catch (error) {
        yield put(actions.revertProgress(panelId, deviceIds, 'bypass'))
        yield put(snackShow(error.message))
    }
}

function* watchRenameDevice({
    meta: {panelId, entityClass: deviceType, entityId: zone, name},
    payload: {result: process},
}) {
    yield ensureProcess(process)

    if (!process.isFailed) {
        yield put(actions.renameDevice(panelId, deviceType, zone, name))
    } else {
        yield put(snackShow(process.errorMessage))
    }
}
