import {useSelector} from 'react-redux'

import * as actions from 'modules/installerPanels/accessiblePanels/list/actions'
import {selectInstallerAccessiblePanelsList} from 'modules/installerPanels/accessiblePanels/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'

export default function useInstallerAccessiblePanels(id) {
    const {rows, isLoading} = useSelector(selectInstallerAccessiblePanelsList)
    const {fetch, setQuery, reset} = useActions({
        fetch: () => actions.fetch(id),
        setQuery: (value) => actions.setQuery(value),
        reset: actions.reset,
    })

    return {
        rows,
        isLoading,
        fetch,
        setQuery,
        reset,
    }
}
