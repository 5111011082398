import {UNKNOWN_DEVICE} from 'constants/device'
import {SEVERITY_TROUBLE} from 'constants/severityType'
import {set} from 'immutable-modify'
import {createSelector} from 'reselect'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import groupBy from 'lodash-es/groupBy'
import mapValues from 'lodash-es/mapValues'
import find from 'lodash-es/find'
import has from 'lodash-es/has'

import {PARTITION_ALL} from 'constants/partitions'
import {DEVICE_TYPE_CONTROL_PANEL, DEVICE_TYPE_LTE_MODULE} from 'constants/deviceType'
import {groups as renderDevicesOrder} from 'pages/Panel/Devices/List/Diagnostic'
import {LTE_MODULE_VERSION} from 'constants/panelInfo'
import findIndex from 'lodash-es/findIndex'

export const selectDevicesByIds = createSelector(
    (state, panelId) => state.devices.list[panelId],
    (state) => {
        const progress = (state && state.progress && state.progress) || {}

        return mapValues((state && state.byIds) || {}, (device) => {
            Object.keys(progress).forEach((type) => {
                if (has(progress[type], device.id)) {
                    device = set(device, ['traits', type], {
                        enabled: progress[type][device.id],
                        progress: true,
                    })
                }
            })

            return device
        })
    }
)

export const selectDevice = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (state, {deviceId}) => deviceId,
    (devicesByIds, deviceId) => devicesByIds[deviceId]
)

export const selectPanelAsDevice = createSelector(selectDevicesByIds, (devicesByIds) =>
    Object.values(devicesByIds).find(
        (device) => device.deviceType === DEVICE_TYPE_CONTROL_PANEL
    )
)

export const selectPanelTraits = createSelector(selectPanelAsDevice, (device) =>
    device && has(device, 'traits') ? device.traits : {}
)

export const selectDeviceInfo = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (state, {deviceId}) => deviceId,
    selectDevice,
    (devicesByIds, deviceId, device) => {
        const parent = device?.traits?.parent?.id && devicesByIds[device.traits.parent.id]
        const childDevices = Object.values(devicesByIds).filter(
            (d) => get(d, 'traits.parent.id') === device?.id
        )

        return {
            device,
            parent,
            childDevices,
        }
    }
)

export const selectDeviceByCategories = createSelector(
    selectDevicesByIds,
    (devicesByIds) => {
        const devices = sortBy(Object.values(devicesByIds), ({zone}) => zone || Infinity)

        return groupBy(
            devices.filter(({deviceType}) => deviceType !== DEVICE_TYPE_CONTROL_PANEL),
            'category'
        )
    }
)

export const selectPanelDevicesByType = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (_, {type}) => type,
    (devicesByIds, deviceType) => {
        return {
            devices: Object.values(devicesByIds).filter(
                (device) => device.deviceType === deviceType
            ),
        }
    }
)

export const selectDeviceByZoneAndType = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (state, {panelId}) => get(state, ['devices', 'list', panelId, 'isLoading'], true),
    (state, {deviceType}) => deviceType,
    (state, {zone}) => zone,
    (devicesByIds, isLoading, deviceType, zone) => {
        const device = Object.values(devicesByIds).find(
            (device) => device.deviceType === deviceType && device.zone === zone
        )

        if (device) {
            return device
        }

        return isLoading ? null : UNKNOWN_DEVICE
    }
)

export const selectBypassableDevices = createSelector(selectDevicesByIds, (devicesById) =>
    Object.values(devicesById).filter(({traits}) => traits && has(traits, 'bypass'))
)

export const selectBypassableDevicesByPartition = createSelector(
    selectBypassableDevices,
    (_, __, partitionId) => partitionId,
    (devices, partitionId) =>
        devices.filter(
            ({partitions}) =>
                partitions.includes(partitionId) || partitions.includes(PARTITION_ALL)
        )
)

export const selectWarningsByPanelId = createSelector(
    selectDevicesByIds,
    (state, panelId) => get(state, ['panels', 'store', 'byIds', panelId], {warnings: []}),
    (devices, {warnings}) => {
        const combined = [
            ...formatDeviceWarnings(devices),
            ...formatPanelWarnings(warnings),
        ]

        return combined.filter((value, index, self) => self.indexOf(value) === index)
    }
)

function formatDeviceWarnings(devices) {
    return Object.values(devices)
        .filter((device) => device.deviceType !== DEVICE_TYPE_CONTROL_PANEL)
        .reduce((lines, {warnings}) => {
            if (warnings.length) {
                return [...lines, ...warnings]
            }

            return lines
        }, [])
}

function formatPanelWarnings(warnings) {
    if (!warnings) {
        return []
    }

    return warnings.filter(({severity}) => severity === SEVERITY_TROUBLE)
}

export const selectPrevNextDeviceId = createSelector(
    (state, {selectedDeviceId, devices}) => ({
        prevDeviceId: getPrevDeviceId(selectedDeviceId, devices),
        nextDeviceId: getNextDeviceId(selectedDeviceId, devices),
    }),
    (result) => result
)

const getPrevDeviceId = (deviceId, devices) => {
    const deviceInCategory = findInCategory(deviceId, devices)

    if (!deviceInCategory) {
        return null
    }

    const {categoryName, index} = deviceInCategory
    const isFirstDeviceInCategory = index === 0

    if (isFirstDeviceInCategory) {
        return prevCategoryLastDeviceId(categoryName, devices)
    }

    return devices[categoryName][index - 1].id
}

const getFirstDeviceId = (devices) => {
    const category = renderDevicesOrder.find((category) => {
        return devices[category] && devices[category].length
    })
    return category ? devices[category][0].id : null
}

const getNextDeviceId = (deviceId, devices) => {
    if (deviceId === null) {
        return getFirstDeviceId(devices)
    }

    const deviceInCategory = findInCategory(deviceId, devices)

    if (!deviceInCategory) {
        return null
    }

    const {categoryName, index} = deviceInCategory
    const isLastDeviceInCategory = index === devices[categoryName].length - 1

    if (isLastDeviceInCategory) {
        return nextCategoryFirstDeviceId(categoryName, devices)
    }

    return devices[categoryName][index + 1].id
}

const findInCategory = (deviceId, devices) => {
    const result = Object.keys(devices).reduce(
        (acc, categoryName) => {
            const deviceIndex = findIndex(devices[categoryName], {id: deviceId})

            if (deviceIndex === -1) {
                return acc
            }

            acc.categoryName = categoryName
            acc.index = deviceIndex
            return acc
        },
        {categoryName: null, index: null}
    )

    if (result.categoryName === null || result.index === null) {
        return null
    }

    return result
}

const prevCategoryLastDeviceId = (currentCategory, devices) => {
    const categories = renderDevicesOrder.filter((group) => has(devices, group))
    const curentCategoryIndex = categories.indexOf(currentCategory)

    if (curentCategoryIndex === 0) {
        return null
    }

    const prevCategoryName = categories[curentCategoryIndex - 1]
    const prevCategoryLastDeviceIndex = devices[prevCategoryName].length - 1

    return devices[prevCategoryName][prevCategoryLastDeviceIndex].id
}

const nextCategoryFirstDeviceId = (currentCategory, devices) => {
    const categories = renderDevicesOrder.filter((group) => has(devices, group))
    const curentCategoryIndex = categories.indexOf(currentCategory)

    if (curentCategoryIndex === categories.length - 1) {
        return null
    }

    const nextCategoryName = categories[curentCategoryIndex + 1]

    return devices[nextCategoryName][0].id
}

export const selectFotaAotaVersions = createSelector(
    (state, panelId) =>
        state.devices.list[panelId] ? state.devices.list[panelId].byIds : {},
    (byIds) => {
        const lte = find(byIds, {deviceType: DEVICE_TYPE_LTE_MODULE})

        return has(lte, 'traits.firmware.version')
            ? {
                  [LTE_MODULE_VERSION]: get(lte, 'traits.firmware.version'),
              }
            : {}
    }
)

export const selectZonesByDeviceIds = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (state, {deviceIds}) => deviceIds,
    (devicesByIds, deviceIds) =>
        Object.keys(devicesByIds).reduce(
            (zones, id) => [
                ...zones,
                ...(deviceIds.includes(+id) ? [devicesByIds[id].zone] : []),
            ],
            []
        )
)
