import {COMMAND_UPDATED} from 'modules/auth/commands'

const isServiceWorker = () => !!navigator?.serviceWorker?.controller
const prepareMessage = (message) => JSON.parse(JSON.stringify(message))

export function addServiceWorkerMessageListener(callback) {
    if (isServiceWorker()) {
        navigator.serviceWorker.addEventListener('message', callback)
    }
}

export function removeServiceWorkerMessageListener(callback) {
    if (isServiceWorker()) {
        navigator.serviceWorker.removeEventListener('message', callback)
    }
}

export function addServiceWorkerErrorMessageListener(callback) {
    if (isServiceWorker()) {
        navigator.serviceWorker.addEventListener('messageerror', callback)
    }
}

export function removeServiceWorkerErrorMessageListener(callback) {
    if (isServiceWorker()) {
        navigator.serviceWorker.addEventListener('messageerror', callback)
    }
}

export function sendSWMessage(message) {
    if (isServiceWorker()) {
        navigator.serviceWorker.controller.postMessage(prepareMessage(message))
    }
}

export async function resentMessageToOtherClients(event, data) {
    const clientList = await self.clients.matchAll()
    const senderID = event.source.id
    const preparedMessage = prepareMessage(data)

    clientList.forEach((client) => {
        if (client.id === senderID && data.command !== COMMAND_UPDATED) {
            return
        }

        client.postMessage(preparedMessage)
    })
}

export async function resentMessageToAllClients(event, data) {
    const clientList = await self.clients.matchAll()
    const preparedMessage = prepareMessage(data)

    clientList.forEach((client) => {
        client.postMessage(preparedMessage)
    })
}
