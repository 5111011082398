import React from 'react'
import PropTypes from 'prop-types'

import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__, __n} from 'utils/i18n'

const SmartCommunicator = ({settings: {isEnabled, supervision}}) => {
    return (
        <List className="card-content" twoCols>
            {!isEnabled && (
                <ListHintItem name={__('Supervision')} text={__('Disabled')} />
            )}

            {isEnabled && (
                <ListHintItem
                    name={__('Supervision Period')}
                    text={__n('%d second', '%d seconds', supervision)}
                />
            )}
        </List>
    )
}

SmartCommunicator.propTypes = {
    isEnabled: PropTypes.bool,
    supervision: PropTypes.number,
}

export default SmartCommunicator
