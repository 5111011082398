import React from 'react'
import PropTypes from 'prop-types'

export const BlindModalDefinitionItem = ({name, children}) => (
    <div className="blindModalDefinition-item">
        <div className="blindModalDefinition-item-title">{name}</div>
        <div className="blindModalDefinition-item-definition">{children}</div>
    </div>
)

BlindModalDefinitionItem.propTypes = {
    name: PropTypes.string.isRequired,
}

export const BlindModalDefinition = ({children}) => (
    <div className="blindModalDefinition">{children}</div>
)

export default BlindModalDefinition
