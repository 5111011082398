import React from 'react'
import PropTypes from 'prop-types'

import BlindModalCardContentHeaderWrapper from 'ipmp-react-ui/BlindModalCardContentHeaderWrapper'
import {Chip} from 'ipmp-react-ui/Chips'
import {ReactComponent as IconLabel} from 'ipmp-react-ui/icons/label.svg'

import usePermission from 'hooks/usePermission'
import interactiveUsersList from 'permissions/interactiveUsers/list'
import {getRoleClassname, getUserRoleTitle} from 'constants/accountRoles'
import {normalizePanelUserIndex} from 'utils/panelUser'

import ApplicationUser from './ApplicationUser'

const PanelUserInfo = ({user: {id, role, email, label, index}}) => {
    const {isAllowedInteractiveUsers} = usePermission({
        isAllowedInteractiveUsers: interactiveUsersList,
    })

    return (
        <BlindModalCardContentHeaderWrapper className="panelUserCardInfo">
            <IconLabel className="panelUserCardInfo-label" />
            <Chip className={getRoleClassname(role)}>{getUserRoleTitle(role)}</Chip>
            <Chip className="panelUserCardInfo-index">
                {normalizePanelUserIndex(index)}
            </Chip>
            {isAllowedInteractiveUsers && (
                <ApplicationUser
                    label={label}
                    userEmail={email}
                    panelUserId={id}
                    role={role}
                />
            )}
        </BlindModalCardContentHeaderWrapper>
    )
}

PanelUserInfo.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        role: PropTypes.string,
        email: PropTypes.string,
        index: PropTypes.number,
        label: PropTypes.string,
    }),
}

export default PanelUserInfo
