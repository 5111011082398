import {handleActions} from 'redux-actions'

import {update, updateFromServiceWorker} from './actions'

const defaultState = {
    isVirtualPanelEnabled: null,
    google: {
        token: null,
    },
}

export default handleActions(
    {
        [update](state, {payload}) {
            return {
                ...state,
                ...payload,
            }
        },

        [updateFromServiceWorker](state, {payload}) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    defaultState
)
