import React, {PureComponent} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {fetch} from 'modules/panels/configuration/export/actions'

import {SEPARATORS, getSeparatorTitle, getSeparator} from 'constants/exportCsv'

import withModalLoader from 'containers/withModalLoader'

import ModalCardForm from 'ui/ModalCardForm'
import Select, {Option} from 'ipmp-react-ui/Select'
import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import saveCSV from 'utils/configuration/saveCSV'

class ExportConfiguration extends PureComponent {
    static propTypes = {
        configurationId: PropTypes.number.isRequired,
        panelId: PropTypes.number.isRequired,
        configuration: PropTypes.object,
        exportConfiguration: PropTypes.object,
        panelSerial: PropTypes.string,
        hide: PropTypes.func.isRequired,
    }

    rules = {
        filename: {
            presence: true,
        },
        separator: {
            presence: true,
        },
    }

    constructor(props, context) {
        super(props, context)

        const dateTime = moment(props?.exportConfiguration?.timestamp).format(
            'YYYY-MM-DD-hh-mm-ss'
        )

        this.state = {filename: `${props.panelSerial}_${dateTime}.csv`}
    }

    submit = ({filename, separator}) => {
        saveCSV(this.props.exportConfiguration, filename, getSeparator(separator))
        this.props.hide()
    }

    render() {
        return (
            <ModalCardForm
                rules={this.rules}
                showDismiss
                hide={this.props.hide}
                onSubmit={this.submit}
                header={__('Export file')}
                submitLabel={__('Export')}
            >
                <Select
                    label={__('Separator Selection')}
                    name="separator"
                    dataTestId="separator"
                >
                    {Object.keys(SEPARATORS).map((separator) => (
                        <Option
                            key={separator}
                            label={getSeparatorTitle(separator)}
                            value={separator}
                        />
                    ))}
                </Select>
                <Input
                    label={__('File Name')}
                    name="filename"
                    defaultValue={this.state.filename}
                    dataTestId="file-name"
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({panels}, {panelId, configuration}) => {
            const panelSerial = panels.store.byIds[panelId]?.serial || ''

            if (configuration) {
                return {
                    exportConfiguration: configuration,
                    panelSerial,
                    isLoading: false,
                }
            }

            const {current, isLoading} = panels.configurationExport

            return {
                panelSerial,
                exportConfiguration: current,
                isLoading,
            }
        },
        (dispatch, {panelId, configurationId}) =>
            bindActionCreators(
                {
                    fetch: () => fetch({panelId, configurationId}),
                },
                dispatch
            )
    ),
    withModalLoader(({fetch, configurationId}) => {
        if (Number.isInteger(configurationId)) {
            fetch()
        }
    })
)(ExportConfiguration)
