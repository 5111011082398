import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {bindActionCreators, compose} from 'redux'

import {connect} from 'react-redux'

import {fetch as fetchContentOptions} from 'modules/reports/contentOptions/actions'
import {fetch as fetchMeteoMetrics} from 'modules/reports/meteoMetrics/actions'

import {fetch as fetchPmaxIndexes} from 'modules/reports/pmaxIndexes/actions'

import {fetch as fetchTransportEmails} from 'modules/reports/transportEmails/actions'

import {createReport} from 'modules/forms/handlers'

import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {FormSection} from 'ipmp-react-ui/Form'
import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'

import Content from './Create/Content'
import Schedule from './Create/Schedule'
import Recurrence from './Create/Recurrence'
import RangeOfRecurrence from './Create/RangeOfRecurrence'

import rules from './Create/utils/rules'
import prepareParams from './Create/utils/prepareParams'

import withModalLoader from 'containers/withModalLoader'
import withForm from 'containers/withForm'

import {__, __n} from 'utils/i18n'
import {clear} from 'modules/forms/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {
    getPatternLabel,
    REPORT_DAILY,
    REPORT_MONTHLY,
    REPORT_ONE_TIME,
    REPORT_WEEKLY,
} from 'constants/reportTypes'

const CreateReport = ({
    contentOptions,
    meteoMetrics,
    pmaxIndexes,
    transportEmails,
    panelIds,
    onSuccess,
    handle,
    ...props
}) => {
    const reportTypes = {
        onetime: REPORT_ONE_TIME,
        daily: REPORT_DAILY,
        weekly: REPORT_WEEKLY,
        monthly: REPORT_MONTHLY,
    }

    const [reportType, setReportType] = useState('onetime')

    const changeReportType = (e, reportType) => {
        setReportType(reportType)
    }

    const onSubmit = async (formData) => {
        const params = prepareParams({
            ...formData,
            panelIds,
        })

        try {
            await handle(params)
            onSuccess && onSuccess()
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    const header = () => {
        if (Array.isArray(panelIds)) {
            return __n(
                'Create report for %s panel',
                'Create report for %s panels',
                panelIds.length,
                [panelIds.length]
            )
        }

        return __('Create Report for all panels')
    }

    const {clear: clearForm} = useActions({clear})
    useEffect(() => {
        return () => clearForm()
    }, [])

    return (
        <ModalCardForm
            confirmOnDismiss
            wide={2}
            rules={rules}
            header={header()}
            onSubmit={onSubmit}
            className="create-report-modal-form"
            {...props}
        >
            <Input
                autoFocus
                label={__('Report Name')}
                name="name"
                dataTestId="report-name"
            />

            <div className="cols cols--stretch">
                <FormSection className="col" title={__('Report Options')}>
                    <Select
                        {...{
                            onChange: changeReportType,
                            value: reportType,
                            label: __('Report Type'),
                            name: 'schedule.pattern',
                            dataTestId: 'report-type',
                        }}
                    >
                        {Object.keys(reportTypes).map((key) => (
                            <Option
                                {...{
                                    key,
                                    value: key,
                                    label: getPatternLabel(reportTypes[key]),
                                }}
                            />
                        ))}
                    </Select>

                    <Recurrence {...{reportType}} />

                    {reportType !== 'onetime' && <RangeOfRecurrence />}

                    <Schedule />
                </FormSection>

                <Content
                    {...{
                        contentOptions,
                        meteoMetrics,
                        pmaxIndexes,
                        transportEmails,
                        panelIds,
                    }}
                />
            </div>
        </ModalCardForm>
    )
}

CreateReport.propTypes = {
    reportTypes: PropTypes.object,
    contentOptions: PropTypes.object,
    meteoMetrics: PropTypes.object,
    pmaxIndexes: PropTypes.object,
    transportEmails: PropTypes.array,
    handle: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    panelIds: PropTypes.array,
}

export default compose(
    connect(
        ({
            reports,
            auth: {
                sign: {
                    user: {email},
                },
            },
        }) => ({
            isLoading:
                reports.contentOptions.isLoading ||
                reports.meteoMetrics.isLoading ||
                reports.pmaxIndexes.isLoading ||
                reports.transportEmails.isLoading,
            contentOptions: reports.contentOptions.options,
            meteoMetrics: reports.meteoMetrics.options,
            pmaxIndexes: reports.pmaxIndexes.indexes,
            transportEmails: reports.transportEmails.emails.includes(email)
                ? reports.transportEmails.emails
                : [email, ...reports.transportEmails.emails],
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    fetchContentOptions,
                    fetchMeteoMetrics,
                    fetchPmaxIndexes,
                    fetchTransportEmails,
                },
                dispatch
            )
    ),

    withModalLoader(
        ({
            fetchContentOptions,
            fetchMeteoMetrics,
            fetchPmaxIndexes,
            fetchTransportEmails,
        }) => {
            fetchPmaxIndexes()
            fetchContentOptions()
            fetchMeteoMetrics()
            fetchTransportEmails()
        }
    ),

    withForm(createReport)
)(CreateReport)
